import {createRef, FC, forwardRef, useContext, useImperativeHandle, useState} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import useOnclickOutside from 'react-cool-onclickoutside';
import {FaTimes} from 'react-icons/fa';
import {HiLocationMarker} from 'react-icons/hi';
import GoogleMapReact from 'google-map-react';

import {Property} from '../../../../api';

import {OverlayContent, OverlayProvider} from '../../context';

import {MapListItem} from './map-list-item';

import {Popover} from 'antd';

type MarkerProps = {
  property: Property;
  lat: number;
  lng: number;
};

export type MarkerInnerRef = {
  openPopOver: () => void;
  closePopOver: () => void;
};

const Marker = forwardRef<MarkerInnerRef, MarkerProps>(({property}, ref) => {
  const history = useHistory();
  const {pathname, search} = useLocation();
  const [, category] = pathname.split("/").filter((param) => param !== "");

  const {setOnOverlay, setIsOverlay} = useContext(OverlayProvider);

  const [visible, setVisible] = useState<boolean>(false);

  const popoverRef = useOnclickOutside(() => setVisible(false));

  useImperativeHandle(ref, () => ({
    openPopOver: () => {
      setVisible(true);
    },
    closePopOver: () => {
      setVisible(false);
    },
  }));

  const openProperty = () => {
    console.log(`Proceeding: /property/${category}/${property.id}${search}`);
    history.push(`/property/${category}/${property.id}${search}`);

    setIsOverlay && setIsOverlay(false);
    setOnOverlay && setOnOverlay(null);
  };

  return (
    <Popover
      title={property.title}
      visible={visible}
      content={(
        <div ref={popoverRef}>
          <div
            className="bg-center bg-cover bg-no-repeat w-full h-28 rounded-sm"
            style={{
              backgroundImage: `url(${property.gallery[3].url})`
            }}
          />
          <div className="pt-2 flex flex-row items-center justify-center">
            <div
              className={
                "cursor-pointer bg-gradient-to-tr from-start " +
                "to-safari-gold hover:from-error text-white " +
                "rounded-lg px-3 py-1 shadow"
              }
              onClick={openProperty}
            >
              View Property
            </div>
          </div>
        </div>
      )}
    >
      <HiLocationMarker
        className={visible ? "text-secondary" : "text-red-600"}
        size={visible ? 40 : 24}
        onClick={() => setVisible(true)}
        style={{
          translate: `-${visible ? 40 : 24}px -${visible ? 40 : 24}px`,
          transition: "translate 150ms linear 100ms",
        }}
      />
    </Popover>
  );
});

interface MapOverlayProps extends OverlayContent {
  properties: Property[],
} 

export const MapOverlay: FC<MapOverlayProps> = ({
  properties, removeOverlay,
}) => {
  const mapOverlayRef = useOnclickOutside(() => removeOverlay());
  const [refs] = useState(properties.map(
    () => createRef<MarkerInnerRef>()),
  );

  return (
    <div
      ref={mapOverlayRef}
      className={
        "flex flex-col lg:flex-row p-2 " +
        "w-full h-full sm:max-w-xl sm:h-3/5 lg:w-8/12 lg:h-4/6 lg:max-w-5xl " +
        "bg-white"
      }
    >
      <div className="flex-grow bg-gray-500">
        <div
          className={
            "sm:hidden h-14 flex flex-row items-center bg-white z-30 " +
            "font-semibold text-xl"
          }
        >
          <FaTimes size={24} className="mr-8" onClick={removeOverlay} />
          Map view of items
        </div>
        <GoogleMapReact
          yesIWantToUseGoogleMapApiInternals
          bootstrapURLKeys={{
            key: "AIzaSyDxrJtbT4XhPoiNlXoOsNZ77j38ai9ZwAo",
          }}
          defaultCenter={{lat: -1.234, lng: 38.432}}
          defaultZoom={8}
        >
          {properties.map((property, idx) => (
            <Marker
              ref={refs[idx]}
              key={property.id}
              lat={property.coords.lat}
              lng={property.coords.lng}
              property={property}
            />
          ))}
          {/* {sharedState.searchResults
            .slice((0, 1))
            .map((data) => {
              let center = {
                lat: parseFloat(data.hotel_lat),
                lng: parseFloat(data.hotel_lng),
              };

              switch (sharedState.category) {
                case "hotel":
                  return (
                    <Marker
                      lat={center.lat}
                      lng={center.lng}
                      text={"yup"}
                      shared={data}
                      selectedMarkerName={selectedMarkerName}
                      setSelectedMarker={_setSelectedMarker}
                      urlParameters={sharedState.urlParameters}
                    />
                  );
              }
            })} */}
        </GoogleMapReact>
      </div>
      <div
        className={
          "flex-grow-0 py-3 px-2 w-full lg:w-auto lg:h-full " +
          "bg-opacity-70 lg:overflow-y-scoll overflow-x-scroll " +
          "lg:overflow-x-hidden lg:block lg:place-items-center"
        }
      >
        {properties.map((property, idx) => (
          <MapListItem
            markerRef={refs[idx]}
            key={property.id}
            id={property.id}
            cover={property.gallery[0].url}
            title={property.title}
            totalCost={0}
            location={property.location}
          />
        ))}

      </div>
    </div>
  );
};
