import {FC, useEffect, useState} from 'react';
import { RiHotelLine } from 'react-icons/ri';
import {RouteComponentProps} from 'react-router';

import {Rental as RentalType, fetchRentals} from '../../../../api';
import { PartnerHeader } from '../../components';

import {PropertiesSummary} from '../../components/properties-summary';

export const Rental: FC<RouteComponentProps> = () => {
  const [rentals, setRentals] = useState<RentalType[] | null>(null);

  useEffect(() => {
    fetchRentals((err, results) => {
      if (err) {
        console.error(err);

        return;
      }

      setRentals(results);
    });
  }, []);

  return (
    <>
      {rentals && (
        <>
          <PartnerHeader HeaderIcon={RiHotelLine} title="All Rentals" />
          <PropertiesSummary
            category="rental"
            properties={rentals}
          />
        </>
      )}
    </>
  );
};
