import {FC, useEffect, useState} from "react";
import { RiHotelBedLine } from "react-icons/ri";
import type {RouteComponentProps} from "react-router";

import type {Hotel as HotelType} from "../../../../api";
import {fetchHotels} from "../../../../api/hotel";
import { PartnerHeader } from "../../components";

import {PropertiesSummary} from "../../components/properties-summary";

export const Hotel: FC<RouteComponentProps> = () => {
  const [hotels, setHotels] = useState<HotelType[] | null>([]) 

  useEffect(() => {
    fetchHotels((err, results) => {
      if (err) {
        console.error(err);
        return;
      }

      setHotels(results);
    });
  }, []);

  return (
    <>
      {hotels && (
        <>
          <PartnerHeader HeaderIcon={RiHotelBedLine} title="All Hotels" />
          <PropertiesSummary
            category="hotel"
            properties={hotels}
          />
        </>
      )}
    </>
  );
};
