import {FC, useState} from "react";
import {Formik} from "formik";
import {FaTimes} from "react-icons/fa";
import {Input, TextArea} from "../../../../../components/input";

import {
  EditSubPropertyFacilities,
  EditSubPropertyFeatures,
  PropertyLocationAutoComplete,
  SubPropertyAddImage,
  SubPropertyEditExceptions,
  SubPropertyEditPolicies,
} from "../../../components";
import {Coordinates, Facility, Feature, ItemException, Picture, Policy} from "../../../../../api";
import {TourStop} from "../../../../../api/tour/types";
import { validateTourOverlayForm } from "./validate-form";
import { generateId } from "../../../../../util";

type NewTourStopOverlayProps = {
  removeOverlay: () => void;
  onSave: (tourStop: TourStop) => void;
};

export const NewTourStopOverlay: FC<NewTourStopOverlayProps> = ({
  removeOverlay, onSave,
}) => {
  const [coords, setCoords] = useState<Coordinates>({lat: 0, lng: 0});
  const [images, setImages] = useState<Picture[]>([]);
  const [policies, setPolicies] = useState<Policy[]>([]);
  const [exceptions, setExceptions] = useState<ItemException[]>([]);
  const [facilities, setFacilities] = useState<Facility[]>([]);
  const [features, setFeatures] = useState<Feature[]>([]);

  const initialValues = {
    title: "",
    location: "",
    description: "",
    short_description: "",
    days: "",
    hours: "",
    mins: "",
    end_days: "",
    end_hours: "",
    end_mins: "",
    gallery: "",
    facilities: "",
    features: "",
    policies: "",
    exceptions: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => validateTourOverlayForm({
        days: values.days,
        hours: values.hours,
        mins: values.mins,
        end_days: values.end_days,
        end_mins: values.end_mins,
        end_hours: values.end_hours,
        description: values.description,
        exceptions,
        facilities,
        features,
        gallery: images,
        location: values.location,
        coords,
        policies,
        short_description: values.short_description,
        title: values.title,
      })}
      onSubmit={(values) => {
        // create the new room
        onSave({
          id: generateId(10),
          arrival: `${values.days}:${values.hours}:${values.mins}`,
          departure: `${values.end_days}:${values.end_hours}:${values.end_mins}`,
          coords,
          description: values.description,
          facilities,
          features,
          gallery: images,
          location: values.location,
          policy: policies,
          title: values.title,
          type: "",
          exceptions,
        });
      }}
    >
      {({
        values, errors, handleChange, handleSubmit, validateForm,
        setErrors,
      }) => (
        <form onSubmit={handleSubmit}>
          <div
            className={
              "bg-white absolute top-0 right-0 bottom-0 w-full h-full " +
              "sm:max-w-lg sm:shadow-md overflow-scroll"
            }
          >
            {/** App bar */}
            <div
              className={
                "sticky top-0 bg-white z-10 h-14 flex flex-row " +
                "justify-between items-center overflow-hidden px-2 " +
                "sm:col-start-1 sm:col-end-4 shadow-md " +
                "bg-safari-gold text-white"
              }
            >
              {/** Header Container */}
              <div
                className={
                  "overflow-hidden flex-grow-0 flex flex-row items-center"
                }
              >
                {/** Close Overlay icon */}
                <FaTimes size={24} onClick={removeOverlay} />
                <div className="ml-3">
                  <h2
                    className={
                      "text-xl font-semibold overflow-ellipsis whitespace-nowrap"
                    }
                  >
                    New Stop
                  </h2>
                  <p
                    className="text-xs italic overflow-ellipsis whitespace-nowrap"
                  >
                    Enter stop details
                  </p>
                </div>
              </div>
              {/** End of Header container */}

              {/** Edit Button */}
              <button
                className={
                  "h-9 px-3 border border-black rounded-md shadow-md " +
                  " text-black font-semibold text-sm flex items-center"
                }
              >
                Save
              </button>
              {/** End of edit Button */}
            </div>
            {/** End of app bar */}

            <div className="p-4">
              {/** start of text input container */}
              <div className="">
                <Input
                  helper="Name of the tour stop"
                  id="tour-stop-name-input"
                  inputLabel="Stop name"
                  name="title"
                  onChange={handleChange}
                  value={values.title}
                  error={errors.title}
                />
              </div>
              {/** End of text input container */}

              {/** start of text input container */}
              <div className="mt-3">
                <PropertyLocationAutoComplete
                  inputLabel="Location"
                  name="location"
                  value={values.location}
                  error={errors.location}
                  selectAddress={(place, coords) => {
                    setCoords(coords);
                  }}
                  onChange={handleChange}
                />
              </div>
              {/** End of text input container */}

              {/** start of text area container */}
              <div className="mt-3">
                <TextArea
                  inputLabel="Stop description (long)"
                  helper="What will be done at the stop"
                  id="tour-stop-description-input"
                  name="description"
                  value={values.description}
                  error={errors.description}
                  onChange={handleChange}
                />
              </div>
              {/** End of text area container */}

              {/** start of text area container */}
              <div className="mt-3">
                <TextArea
                  inputLabel="Stop description (short)"
                  helper="What will be done at the stop (short)"
                  id="short-tour-stop-description"
                  name="short_description"
                  value={values.short_description}
                  error={errors.short_description}
                  onChange={handleChange}
                />
              </div>
              {/** End of text area container */}

              {/** Arrival and departue container */}
              <div className="px-4 py-2 my-2 shadow-md border rounded-md">
                <h6 className="text-xl font-semibold">Arrival and departure</h6>
                <p className="pb-2 border-b-2 text-xs">
                  Enter arrival and departure relative to the first date and we
                  will handle the rest for you
                </p>
                {/** Arrival Time container */}
                <div>
                  <h6 className="text-base font-semibold">Arrival</h6>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <p className="text-xs">days from start date of tour</p>
                      {/** start of text input container */}
                      <div className="mt-3">
                        <Input
                          inputLabel="days"
                          helper="days from start date of tour"
                          id="days-input"
                          name="days"
                          onChange={handleChange}
                          value={values.days}
                          error={errors.days}
                        />
                      </div>
                      {/** End of text input container */}
                    </div>

                    <div>
                      <p className="text-xs">Time of day</p>
                      <div className="grid grid-cols-2 gap-4">
                        {/** start of text input container */}
                        <div className="mt-3">
                          <Input
                            inputLabel="HH*"
                            helper="hours"
                            id="hours-input"
                            name="hours"
                            value={values.hours}
                            error={errors.hours}
                            onChange={handleChange}
                          />
                        </div>
                        {/** End of text input container */}

                        {/** start of text input container */}
                        <div className="mt-3">
                          <Input
                            inputLabel="MM*"
                            helper="min"
                            id="min-input"
                            name="mins"
                            value={values.mins}
                            error={errors.mins}
                            onChange={handleChange}
                          />
                        </div>
                        {/** End of text input container */}
                      </div>

                      <p className="text-xs font-semibold">
                        * Time in 24-hour system
                      </p>
                    </div>
                  </div>
                </div>
                {/** End of arrival time container */}

                {/** Departue Time container */}
                <div>
                  <h6 className="text-base font-semibold">Departure</h6>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <p className="text-xs">days from start date of tour</p>
                      {/** start of text input container */}
                      <div className="mt-3">
                        <Input
                          helper="Days from start date of tour"
                          id="start-date-input"
                          inputLabel="Days"
                          name="end_days"
                          value={values.end_days}
                          error={errors.end_days}
                          onChange={handleChange}
                        />
                      </div>
                      {/** End of text input container */}
                    </div>

                    <div>
                      <p className="text-xs">Time of day</p>
                      <div className="grid grid-cols-2 gap-4">
                        {/** start of text input container */}
                        <div className="mt-3">
                          <Input
                            helper="Hours"
                            id="end-date-input"
                            inputLabel="HH*"
                            name="end_hours"
                            value={values.end_hours}
                            error={errors.end_hours}
                            onChange={handleChange}
                          />
                        </div>
                        {/** End of text input container */}

                        {/** start of text input container */}
                        <div className="mt-3">
                          <Input
                            helper="Mins"
                            id="end-mins-input"
                            inputLabel="MM*"
                            name="end_mins"
                            onChange={handleChange}
                            value={values.end_mins}
                            error={errors.end_mins}
                          />
                        </div>
                        {/** End of text input container */}
                      </div>

                      <p className="text-xs font-semibold">
                        * Time in 24-hour system
                      </p>
                    </div>
                  </div>
                </div>
                {/** End of arrival time container */}
              </div>
              {/** End of Room Capacity Container */}

              {/** Image Addition container */}
              <SubPropertyAddImage
                threshold={6}
                images={images}
                setImages={async (updatedImages) => {
                  setImages(updatedImages);

                  setErrors(await validateForm());
                }}
              />
              {/** End of image add container */}

              {/** Add Facilities Container */}
              <EditSubPropertyFacilities
                category="tour"
                facilities={facilities}
                setFacilities={async (updatedFacilities) => {
                  setFacilities(updatedFacilities);

                  setErrors(await validateForm());
                }}
                headerSubtitle="Add, remove and edit facilities as contained in your tour stop"
              />
              {/* End of add facilities cotnainer */}

              {/** Features container */}
              <EditSubPropertyFeatures
                features={features}
                setFeatures={async (updatedFeatures) => {
                  setFeatures(updatedFeatures);

                  setErrors(await validateForm());
                }}
                category="tour"
              />

              {/** End of Policy container */}
              <SubPropertyEditPolicies
                policies={policies}
                setPolicies={async (updatedPolicies) => {
                  setPolicies(updatedPolicies);

                  setErrors(await validateForm());
                }}
              />
              {/** End of policy edit container */}

              {/** End of Policy container */}
              <SubPropertyEditExceptions
                exceptions={exceptions}
                setExceptions={async (updatedExceptions) => {
                  setExceptions(updatedExceptions);

                  setErrors(await validateForm());
                }}
              />
              {/** End of policy edit container */}
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};
