import { Checkbox, CheckboxOptionType } from 'antd';
import {FC} from 'react';
import {SectionHeader} from '../../../components/section-header';

export interface FilterGroupProps {
  groupData: {
    title: string;
    data: Array<{title: string; description: string}>;
    onChange(values: string[]): void;
  }
}

export const FilterGroup: FC<FilterGroupProps> = ({groupData}) => {
  return (
    <div
      className="border px-2 border-l-0 border-r-0 border-b-0 mb-3"
      style={{borderColor: "#BDBDBD"}}
    >
      <SectionHeader 
        style={{
          border: 0,
          margin: 0,
          fontSize: 14,
        }}
      >
        {groupData.title}
      </SectionHeader>
      <Checkbox.Group
        options={groupData.data.map((data) => ({
          label: data.title,
          value: data.title,
        } as CheckboxOptionType))}
        onChange={(vals) => groupData.onChange(vals as unknown as string[])}
      />
    </div>
  );
};
