// @ts-check
import {Component} from "react";
import {
  FaChevronRight,
  FaMapMarkerAlt,
} from "react-icons/fa";
import {Rating, SectionHeader, NewSearch} from "./components";

import RangeSlider from "./RangeSlider";

class App extends Component {
  constructor(props) {
    super(props);
    const url = new URL(location.href);
    const rating = (url.searchParams.has("star_rating")) ?
      Number(url.searchParams.get("star_rating")): 5;
    const activeFacilityFilters = url.searchParams.getAll("feature");
    const activeFeatureFilters = url.searchParams.getAll("theme");
    // based on the rating, we perform the first filter of hotels
    this.state = {
      activeFilters: [],
      rating,
      activeFacilityFilters,
      activeFeatureFilters,
      url, // helps monitor all the filters to enable sharability.
    };
  }

  _onPriceRangeChange = (value) => {
    console.log(value);
    const minPrice = Math.floor(
      (value[0] / 100) * this.props.sharedState.maxPrice
    );
    const maxPrice = Math.floor(
      (value[1] / 100) * this.props.sharedState.maxPrice
    );
    console.log("minPrice", this.props.sharedState.rangeMin);
    console.log("minPriceCalc", minPrice);
    // the minimum and maximum price are only set if they are not equal to their
    // original maximum value.
    if (minPrice !== this.props.sharedState.minPrice) {
      if (this.state.url.searchParams.has("price_lowest")) {
        if (
          Number(this.state.url.searchParams.get("price_lowest")) !== minPrice
        ) {
          this.state.url.searchParams.set("price_lowest", `${minPrice}`);
        }
      } else {
        this.state.url.searchParams.append("price_lowest", `${minPrice}`)
      }
    }

    if (maxPrice !== this.props.sharedState.maxPrice) {
      if (this.state.url.searchParams.has("price_highest")) {
        if (
            Number(this.state.url.searchParams.get("price_highest")) !== 
              maxPrice
          ) {
          this.state.url.searchParams.set("price_highest", `${maxPrice}`);
        }
      } else {
        this.state.url.searchParams.append("price_highest", `${maxPrice}`);
      }
    }

    history.replaceState(null, "", this.state.url.toString());
    
    this.props.updateState({
      rangeMin: minPrice,
      rangeMax: maxPrice,
      priceChanged: true,
    });
  };

  _filterByStars = (rating) => {
    this.setState({rating});
    // in order to make sure that there are not several copies of star_rating,
    // we first check for its existence before attempting to append to the
    // URLSearchParams, if present, we set the star_rating to a new value
    // else, we use the append function to create it.
    if (this.state.url.searchParams.has("star_rating")) {
      this.state.url.searchParams.set("star_rating", rating);
    } else {
      this.state.url.searchParams.append("star_rating", rating);
    }
    // create the new URL
    history.replaceState(null, "", this.state.url.toString());
    // since updating will happen in the main component, there is no need to
    // pass any parameters to update state.
    this.props.updateState({});
  };

  _filterByTheme = (feature) => {
    const category = this.props.sharedState.category;
    const results = this.props.sharedState.rawResults;
    let newResults = [];
    //handle filter removals
    let activeFilters = this.state.activeFeatureFilters;
    if (activeFilters.includes(feature)) {
      activeFilters.splice(activeFilters.indexOf(feature), 1);
    } else {
      activeFilters.push(feature);
    }

    this.state.url.searchParams.delete("theme");
    // append all themes
    activeFilters.forEach((theme) => {
      this.state.url.searchParams.append("theme", theme);
    });

    // ammend url with all changes
    history.replaceState(null, "", this.state.url.toString());

    this.setState({ activeFeatureFilters: activeFilters });

    if (activeFilters.length == 0) {
      newResults = results;
      this.props.updateState({
        searchResults: newResults,
      });
      return;
    }

    //handle removal
    results.map((item) => {
      let feature_list = [];
      const features = item["room_features"];
      switch (category) {
        case "hotel":
          if (features) {
            feature_list = features;
          }
          if (feature_list.includes(feature)) {
            newResults.push(item);
          }

          break;
      }
    });
    console.log("newResults", newResults);
    this.props.updateState({
      searchResults: newResults,
    });
  };

  _filterByFeatures = (feature) => {
    let category = this.props.sharedState.category;
    let results = this.props.sharedState.rawResults;
    let newResults = [];
    //handle filter removals
    let activeFilters = this.state.activeFacilityFilters;
    if (activeFilters.includes(feature)) {
      activeFilters.splice(activeFilters.indexOf(feature), 1);
    } else {
      activeFilters.push(feature);
    }
    // update the url to contain all the filters as features before proceeding
    // remove all features first
    this.state.url.searchParams.delete("feature");
    activeFilters.forEach((feature) => {
      this.state.url.searchParams.append("feature", feature);
    });

    // update document url
    history.replaceState(null, "", this.state.url.toString());

    this.setState({ activeFacilityFilters: activeFilters });
    if (activeFilters.length == 0) {
      newResults = results;
      this.props.updateState({
        searchResults: newResults,
      });
      return;
    }

    //handle removal
    results.map((item) => {
      let feature_list = [];
      let features = item["hotel_features"];

      switch (category) {
        case "hotel":
          if (features) {
            feature_list = features;
          }
          if (feature_list.includes(feature)) {
            newResults.push(item);
          }

          break;
      }
    });
    console.log("newResults", newResults);
    this.props.updateState({
      searchResults: newResults,
    });
  };

  /**
   * This function makes sure that any update to state from a new search is
   * applied to the component to prevent reverting to an old search when
   * clicking the share option.
   *
   * @param {{searchDone: boolean, stale: boolean, url: URL}} state
   */
  _updateState = (state) => {
    this.setState({url: state.url});
    this.props.updateState(state);
  };

  render() {
    return (
      <div className="left-menu-parent">
        <NewSearch
          updateState={this._updateState}
        />
        {/* Top right marker for the map, onPress shows the map */}
        {this.props.origin != "map" && (
          <div
            className="elevate-xs map-background"
            onClick={() => {
              window.scrollTo({left: 0, top: 0, behavior: "smooth"});
              this.props.updateState({
                displayMode: "map",
              });
            }}
            style={{
              backgroundColor: "#fff",
              display: "flex",
              flexDirection: "column",
              position: "relative",
              marginBottom: 20,
            }}
          >
            <div
              style={{
                background: "#ffffff6b",
                backgroundPosition: "center",
                height: "100%",
                width: "100%",
                position: "absolute",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "100%",
                position: "absolute",
                paddingTop: 15,
              }}
            >
              <FaMapMarkerAlt
                color="#ff6000"
                style={{ alignSelf: "center", fontSize: 30 }}
              />
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: 16,
                  padding: 10,
                  color: "#000",
                  textAlign: "center",
                }}
              >
                Show on Map
              </div>
            </div>
          </div>
        )}
        {/* Price Range Seletor */}
        <div
          className="elevate-xs"
          style={{ backgroundColor: "#fff", marginBottom: 20 }}
        >
          <SectionHeader
            style={{borderTop: 0}}
          >
            Price Range
          </SectionHeader>
          <div
            style={{
              fontSize: 12,
              marginBottom: 10,
              marginTop: 8,
              padding: 10,
              marginRight: 30,
              marginLeft: 30,
              background: "#ffe9e9",
            }}
          >
            Filter hotels based on their total price for{" "}
            {this.props.sharedState.numberOfDays} day(s)
          </div>
          <div
            style={{
              marginBottom: 10,
              padding: 28,
              paddingTop: 10,
              borderRadius: 0,
              backgroundColor: "#fff",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <RangeSlider
              onRangeChange={this._onPriceRangeChange}
              rangeMin={this.props.sharedState.rangeMin}
              rangeMax={this.props.sharedState.rangeMax}
              maxPrice={this.props.sharedState.maxPrice}
            />
            <div style={{ display: "flex", padding: 5 }}>
              <div style={{ fontSize: 12 }}>
                USD {this.props.sharedState.rangeMin}
              </div>
              <div
                style={{
                  flex: 1,
                  fontSize: 11,
                  textAlign: "center",
                  paddingTop: 3,
                }}
              >
                <FaChevronRight />
              </div>
              <div style={{ fontSize: 12, textAlign: "right" }}>
                {" "}
                USD {this.props.sharedState.rangeMax}
              </div>
            </div>
            {/* <div
              className="button but-orange"
              style={{ flex: 1, margin: 0, borderRadius: 10, padding: 5 }}
            >
              UPDATE SEARCH{" "}
            </div> */}
          </div>
        </div>
        {/* End of Price Range selector */}
        {/* Star selector */}
        <div
          className="elevate-xs"
          style={{ backgroundColor: "#fff", marginBottom: 20 }}
        >
          <SectionHeader
            style={{borderTop: 0}}
          >
            Star Rating
          </SectionHeader>
          <div style={{ padding: 20, paddingTop: 0 }}>
            <Rating
              checked={this.state.rating === 5}
              rating={5}
              onChange={() => this._filterByStars(5)}
            />
            <Rating
              checked={this.state.rating === 4}
              rating={4}
              onChange={() => this._filterByStars(4)}
            />
            <Rating
              checked={this.state.rating === 3}
              rating={3}
              onChange={() => this._filterByStars(3)}
            />
            <Rating
              checked={this.state.rating === 2}
              rating={2}
              onChange={() => this._filterByStars(2)}
            />
            <Rating
              checked={this.state.rating === 1}
              rating={1}
              onChange={() => this._filterByStars(1)}
            />
        </div>
        {/* End of filter by stars */}
        {/* Filter by facilities */}
        <div
          className="elevate-xs"
          style={{ backgroundColor: "#fff", marginBottom: 20 }}
        >
          <SectionHeader
            style={{borderTop: 0}}
          >
            Facilities
          </SectionHeader>
          <div style={{ padding: 20, paddingTop: 10 }}>
            {this.props.sharedState.facilities.map((item) => {
              return (
                <div
                  style={{ display: "flex", paddingTop: 7, paddingBottom: 7 }}
                >
                  <div style={{ flex: 1, textAlign: "left", display: "flex" }}>
                    <input
                      type="checkbox"
                      id={item.title}
                      name={item.title}
                      value={item.title}
                      onChange={(event) => {
                        this._filterByFeatures(event.target.value);
                      }}
                      style={{ width: 30 }}
                      checked={
                        this.state.activeFacilityFilters.includes(item.title)
                      }
                    />
                    <label
                      htmlFor={item.title}
                      style={{ height: 0, paddingTop: 3, fontSize: 12 }}
                    >
                      {" "}
                      {item.title}{" "}
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
          <SectionHeader
            style={{borderTop: 0}}
          >
            Theme
          </SectionHeader>
          <div style={{ padding: 20, paddingTop: 10 }}>
            {this.props.sharedState.themes.map((item) => {
              return (
                <div
                  style={{ display: "flex", paddingTop: 7, paddingBottom: 7 }}
                >
                  <div style={{ flex: 1, textAlign: "left", display: "flex" }}>
                    <input
                      type="checkbox"
                      id={item.title}
                      name={item.title}
                      value={item.title}
                      onChange={(event) => {
                        this._filterByTheme(event.target.value);
                      }}
                      style={{ width: 30 }}
                      checked={
                        this.state.activeFeatureFilters.includes(item.title)
                      }
                    />
                    <label
                      htmlFor={item.title}
                      style={{ height: 0, paddingTop: 3, fontSize: 12 }}
                    >
                      {" "}
                      {item.title}{" "}
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* End of facilities */}
      </div>
      </div>
    );
  }
}

export default App;
