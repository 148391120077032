import {FC, useState} from 'react';
import {DatePicker, TimePicker} from 'antd';

import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import {Input} from '../../../../components/input';
import {Button} from '../../../../components';
import {postReservation} from '../../../../api';
import {
  ConferenceReservation as ConferenceReservationType,
} from '../../../../api/reservation/types';

type ConferenceReservationProps = {
  propertyId: string;
  removeOverlay: () => void;
};

type Time = {
  hour: number;
  minute: number;
  second: number;
};

export const ConferenceReservation: FC<ConferenceReservationProps> = ({
  propertyId, removeOverlay,
}) => {
  const todaysDate =  new Date();
  todaysDate.setHours(0, 0, 0, 0);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [startTime, setStartTime] = useState<Time | null>(null);

  const [endDate, setEndDate] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Time | null>(null);

  // input control for Number of rooms
  const [numberOfRooms, setNumberOfRooms] = useState<string>("");
  const [numberOfRoomsError, setNumberOfRoomsError] = useState<string>("");

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  return (
    <>
      <div className="p-4 bg-white">
        <div>
          <h6 className="text-xs mb-3">
            Start date
          </h6>
          <div className="flex flex-row justify-between">
            <DatePicker
              className="flex-shrink-0 flex-grow-0"
              disabled={isSubmitting}
              allowClear={false}
              disabledDate={(date) => {
                return (
                  Number(date.format("x")) < todaysDate.getTime()
                );
              }}
              onChange={(date) => {
                if (date) {
                  setStartDate(date.toDate());
                }
              }}
            />
            <TimePicker
              disabled={isSubmitting}
              allowClear={false}
              onChange={(val) => {
                if (val) {
                  setStartTime({
                    hour: val.hour(),
                    minute: val.minute(),
                    second: val.second(),
                  });
                }
              }}
            />
          </div>
        </div>

        <div className="mt-3">
          <h6 className="text-xs mb-3">
            End date
          </h6>
          <div className="flex flex-row justify-between">
            <DatePicker
              className="flex-shrink-0 flex-grow-0"
              disabled={isSubmitting}
              allowClear={false}
              disabledDate={(date) => {
                if (!startDate) {
                  return (
                    Number(date.format("x")) < todaysDate.getTime()
                  );
                } else {
                  // we should make sure that the date is more later that the
                  // start date
                  return (
                    Number(date.format("x")) < startDate.getTime()
                  );
                }
              }}
              onChange={(date) => {
                if (date) {
                  setEndDate(date.toDate());
                }
              }}
            />
            <TimePicker
              disabled={isSubmitting}
              allowClear={false}
              onChange={(val) => {
                if (val) {
                  setEndTime({
                    hour: val.hour(),
                    minute: val.minute(),
                    second: val.second(),
                  });
                }
              }}
            />
          </div>
        </div>

        <Input
          className="mt-3"
          helper="Number of rooms to be made unavailable"
          id="no-of-rooms"
          inputLabel="Number of unavailable rooms"
          name="no-of-rooms"
          value={`${numberOfRooms}`}
          error={numberOfRoomsError}
          onChange={(e) => {
            const newNumberOfRooms = Number(e.target.value);

            setNumberOfRooms(e.target.value);
            // look for an error
            if (isNaN(newNumberOfRooms)) {
              setNumberOfRoomsError("Field should contain valid number");
            } else {
              setNumberOfRoomsError("");
            }
          }}
        />

        <div className="flex flex-row pt-3 items-center justify-center">
          <Button
            disabled={
              startDate === null || endDate === null || startTime === null || 
                endTime === null || numberOfRoomsError !== "" || isSubmitting
            }
            type="button"
            onClick={() => {
              if (
                startDate === null || endDate === null || startTime === null || 
                  endTime === null || numberOfRoomsError !== ""
              ) {
                return;
              }
              setIsSubmitting(true);

              startDate.setHours(
                startTime.hour, startTime.minute, startTime.second,
              );
              endDate.setHours(endTime.hour, endTime.minute, endTime.second);

              postReservation(
                ({
                  amount_received: 0,
                  category: "conference",
                  checkin: startDate.getTime(),
                  checkout: endDate.getTime(),
                  number_of_rooms: Number(numberOfRooms),
                  due_date: 0,
                  expected_amount: 0,
                  people: 0,
                  property_id: propertyId,
                  reservation_date: Date.now(),
                  reservation_type: "full_amount",
                  status: "external",
                } as ConferenceReservationType),
                (err, reservation) => {
                  setIsSubmitting(true);

                  if (err) {
                    console.error(err);

                    return;
                  }

                  removeOverlay();
                },
              );
            }}
          >
            Make Unavailable
          </Button>
        </div>
      </div>
    </>
  );
};