import {FC, useEffect, useState} from 'react';
import { RiCarLine } from 'react-icons/ri';
import {RouteComponentProps} from 'react-router';

import {Car as CarType, fetchCars} from '../../../../api';
import { PartnerHeader } from '../../components';

import {PropertiesSummary} from '../../components/properties-summary';

export const Car: FC<RouteComponentProps> = () => {
  const [cars, setCars] = useState<CarType[] | null>(null);

  useEffect(() => {
    fetchCars((err, results) => {
      if (err) {
        console.error(err);

        return;
      }

      setCars(results);
    });
  }, []);

  return (
    <>
      {cars && (
        <>
          <PartnerHeader HeaderIcon={RiCarLine} title="All Cars" />
          <PropertiesSummary
            category="car"
            properties={cars.map((car) => ({
              coords: car.coords,
              cover: car.gallery[0].url,
              description: car.model,
              exceptions: car.exceptions,
              faqs: car.faq,
              gallery: car.gallery,
              id: car.id,
              location: car.location,
              rating: car.rating,
              refund_policy: car.refund_policy,
              reservation_policy: car.reservation_policy,
              reviews: car.reviews,
              short_description: car.model,
              title: car.reg_no,
              offers: car.offers,
            }))}
          />
        </>
      )}
    </>
  );
};
