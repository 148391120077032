import {Dispatch, FC, SetStateAction} from "react";
import GoogleMapReact from "google-map-react";

import {Nearby} from "../../../../api/attraction/types";
import {ReservationCategory} from "../../../../api";

import {NearbyPropertyRefTree} from "./nearby-properties";
import {MapMarker} from "./map-marker";
import { Tag } from "antd";
import { BiArrowBack, BiFilterAlt } from "react-icons/bi";

interface NearbyPropertiesMapViewProps {
  filterTabVisible: boolean;
  setFilterTabVisible: Dispatch<SetStateAction<boolean>>;
  properties?: Nearby[];
  refs: NearbyPropertyRefTree;
  activeCategories: {[K in ReservationCategory]: boolean}
  toggleCategory: (category: ReservationCategory) => void;
  setActiveTab?: Dispatch<SetStateAction<"1" | "2" | "3">>;
}

export const NearbyPropertiesMapView: FC<NearbyPropertiesMapViewProps> = ({
  activeCategories , filterTabVisible, properties, refs, setFilterTabVisible,
  setActiveTab, toggleCategory,
}) => {
  return (
    <div className="h-full w-full flex-1 flex-shrink-0 flex-grow bg-indigo-200 sm:relative">
      <div className="absolute top-0 left-0 right-0 z-10">
        <div className="sm:hidden flex flex-row px-4 py-2 bg-white">
          <div className="flex flex-row items-center">
            <BiArrowBack
              className="mr-3"
              size={24}
              onClick={() => {
                setActiveTab && setActiveTab("1");
              }}
            />
            Back to search
          </div>
        </div>
        <div className="px-4 pb-2 flex flex-row flex-wrap z-10 bg-white shadow-md">
          {!filterTabVisible && (
            <Tag
              className="xl:hidden flex flex-row items-center flex-grow-0 flex-shrink-0 mt-2 cursor-pointer"
              icon={<BiFilterAlt className="mr-2" />}
              onClick={() => {
                setFilterTabVisible(true);
                  setActiveTab && setActiveTab("3");
              }}
            >
              Show filters
            </Tag>
          )}
          {["activity", "car", "conference", "eatery", "hotel", "rental", "tour"]
            .map((category) => (
              <Tag
                key={`${category}_tag`}
                className="mt-2 cursor-pointer"
                color={
                  activeCategories[category as ReservationCategory] ?
                    "#FF5900" :
                    "default"
                }
                onClick={() => {
                  toggleCategory(category as ReservationCategory);
                }}
              >
                {category}
              </Tag>
            ))
          }
        </div>
      </div>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyDxrJtbT4XhPoiNlXoOsNZ77j38ai9ZwAo",
        }}
        defaultCenter={{ lat: -1.2920659, lng: 36.8219462 }}
        defaultZoom={16}
        yesIWantToUseGoogleMapApiInternals={true}
      >
        {properties &&
          properties
            .filter((nearby) => activeCategories[nearby.category])
            .map((nearbyProperty) => {
              return nearbyProperty.properties.map((property, idx) => (
                <MapMarker
                  ref={refs[nearbyProperty.category][idx].marker}
                  key={property.id}
                  refs={refs[nearbyProperty.category][idx]}
                  lat={property.coords.lat}
                  lng={property.coords.lng}
                />
              ));
            })}
      </GoogleMapReact>
    </div>
  );
};
