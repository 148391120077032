import {Component} from "react";
import {
  RiHotelLine,
  RiFlightTakeoffLine,
  RiHotelBedLine,
  RiShipLine,
  RiRestaurantLine,
  RiBuildingLine,
  RiCarLine,
  RiRoadMapLine,
  RiApps2Line,
} from "react-icons/ri";

import Flights from "../../../../../index/components/Flights";
import Hotels from "../../../../../index/components/Hotels";
import Rentals from "../../../../../index/components/Rentals";
import Cruises from "../../../../../index/components/Cruises";
import Eateries from "../../../../../index/components/Eateries";
import Conferencing from "../../../../../index/components/Conferencing";
import Cars from "../../../../../index/components/Cars";
import Tours from "../../../../../index/components/Tours";
import Activities from "../../../../../index/components/Activities";


////////////////////////////////////////////////////////////////////////////////
// JSDoc Types
////////////////////////////////////////////////////////////////////////////////
/**
 * @typedef {"0" | "1" | "2" | "4" | "5"} TabView
 */

//animate css
const openedStyle = {
  right: "0",
  transition: "right 0.5s",
};
const closedStyle = {
  right: "-110%",
};

class App extends Component {
  
  constructor(props) {
    super(props);

    // Based on the props.tabView, we have a different array of options of which
    // tabs will be switchable to by the user at any given point as such, the
    // props.tabView options available are:
    // 0 - Flights, Car and Cruises
    // 1 - Hotels and Rentals
    // 2 - Catering - Eateries
    // 3 - Activities - activities and tours
    // 4 - All Tabs are rendered
    
    // Due to the nature of this dynamic method of rendering, we will also have
    // to keep an object that monitors the index of each tab if they are indeed
    // available for use.
    let tabIndices = {
      Flights: null,
      Cruises: null,
      Cars: null,
      Hotels: null,
      Rentals: null,
      Eateries: null,
      Tours: null,
      Activities: null,
      Conferencing: null,
    };

    let tabs;

    switch(props.tabView) {
      case "0":
        tabs = [
          {
            title: "Flights",
            pathname: "navigator-view-flights",
            key: 1,
            active: /flights/.test(location.href),
            icon: <RiFlightTakeoffLine />,
          },
          {
            title: "Cruises",
            pathname: "navigator-view-cruises",
            key: 3,
            active: /cruises/.test(location.href),
            icon: <RiShipLine />,
          },
          {
            title: "Cars",
            key: 7,
            pathname: "navigator-view-cars",
            active: /cars/.test(location.href),
            icon: <RiCarLine />,
          },
        ];
        tabIndices.Flights = 0;
        tabIndices.Cruises = 1;
        tabIndices.Cars = 2;
        break;
      case "1":
        tabs = [
          {
            title: "Hotels",
            pathname: "navigator-view-hotels",
            key: 0,
            active: /hotel/.test(location.href),
            icon: <RiHotelBedLine />,
          },
          {
            title: "Rentals",
            pathname: "navigator-view-rentals",
            key: 2,
            active: /rentals/.test(location.href),
            icon: <RiHotelLine />,
          },
        ];
        tabIndices.Hotels = 0;
        tabIndices.Rentals = 1;
        break;
      case "2":
        tabs = [
          { 
            title: "Eateries",
            pathname: "navigator-view-eateries",
            key: 4,
            active: /eateries/.test(location.href),
            icon: <RiRestaurantLine />,
          },
        ];
        tabIndices.Eateries = 0;
        break;
      case "3":
        tabs = [
          {
            title: "Tours",
            key: 8,
            pathname: "navigator-view-tours",
            active: /tours/.test(location.href),
            icon: <RiRoadMapLine />,
          },
          {
            title: "Activities",
            pathname: "navigator-view-activities",
            key: 9,
            active: /activities/.test(location.href),
            icon: <RiApps2Line />,
          },
        ];
        tabIndices.Tours = 0;
        tabIndices.Activities = 1;
        break;
      default:
        tabs = [
          {
            title: "Hotels",
            pathname: "navigator-view-hotels",
            key: 0,
            active: /hotel/.test(location.href),
            icon: <RiHotelBedLine />,
          },
          {
            title: "Flights",
            pathname: "navigator-view-flights",
            key: 1,
            active: /flights/.test(location.href),
            icon: <RiFlightTakeoffLine />,
          },
          {
            title: "Rentals",
            pathname: "navigator-view-rentals",
            key: 2,
            active: /rentals/.test(location.href),
            icon: <RiHotelLine />,
          },
          {
            title: "Cruises",
            pathname: "navigator-view-cruises",
            key: 3,
            active: /cruises/.test(location.href),
            icon: <RiShipLine />,
          },
          {
            title: "Eateries",
            pathname: "navigator-view-eateries",
            key: 4,
            active: /eateries/.test(location.href),
            icon: <RiRestaurantLine />,
          },
          {
            title: "Conferencing",
            pathname: "navigator-view-venues",
            key: 5,
            active: /venues/.test(location.href),
            icon: <RiBuildingLine />,
          },
          {
            title: "Cars",
            key: 7,
            pathname: "navigator-view-cars",
            active: /cars/.test(location.href),
            icon: <RiCarLine />,
          },
          {
            title: "Tours",
            key: 8,
            pathname: "navigator-view-tours",
            active: /tours/.test(location.href),
            icon: <RiRoadMapLine />,
          },
          {
            title: "Activities",
            pathname: "navigator-view-activities",
            key: 9,
            active: /activities/.test(location.href),
            icon: <RiApps2Line />,
          },
        ];

        tabIndices.Hotels = 0;
        tabIndices.Flights = 1;
        tabIndices.Rentals = 2;
        tabIndices.Cruises = 3;
        tabIndices.Eateries = 4;
        tabIndices.Conferencing = 5;
        tabIndices.Cars = 6;
        tabIndices.Tours = 7;
        tabIndices.Activities = 8;
    }

    this.state = {
      tabs,
      tabIndices,
    };
  }

  /**
   * Listents for the change in the document hash and updates the active tab
   * accordingly.
   *
   * @param {} e event.
   */ // eslint-disable-next-line no-unused-vars
  _onHashChange = (e) => {
    let windowHash = window.location.hash;
    if (!windowHash) {
      windowHash = "#navigator-view-hotels";
    }
    console.log("hashchange2", windowHash);
    const tabs = [];
    this.state.tabs.map((item) => {
      if (`#${item.pathname}` == windowHash) {
        const newItem = item;
        newItem["active"] = true;
        tabs.push(newItem);
      } else {
        const newItem = item;
        newItem["active"] = false;
        tabs.push(newItem);
      }
    });
    this.setState({ tabs });
  };

  componentDidMount() {
    window.addEventListener("hashchange", this._onHashChange);
  }

  componentWillUnmount() {
    window.removeEventListener("hashchange", this._onHashChange);
  }

  render() {
    // NOTE: We explicitly test for null when deciding what tabs to render in
    // the search parameter as using truthy values will bring about lack of
    // render of the 0 position index tab since the value 0 is a falsy value.
    return (
      <div className="tab-parent">
        <div className="flex-div flex-row selector">
          <div style={{ flex: 1, display: "flex" }}>
            {this.state.tabs.map((item) => {
              if (item.active) {
                return (
                  <div className="flex-div selector-container" key={item.key}>
                    <div className="flex-div flex-row active">
                      {item.icon}
                      <span className="flex-div selector-text">
                        {item.title}
                      </span>
                    </div>
                    <div className="flex-div selector-indicator" />
                  </div>
                );
              }

              return (
                <a href={`#${item.pathname}`} key={item.key}>
                  <div className="flex-div selector-container">
                    <div className="flex-div flex-row">
                      {item.icon}
                      <span className="selector-text">{item.title}</span>
                    </div>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
        {this.state.tabIndices.Hotels !== null && (
          <div
            id="Hotels"
            className="tab"
            style={
              this.state.tabs[this.state.tabIndices.Hotels].active ?
                openedStyle :
                closedStyle
            }
          >
            <Hotels
              origin="trip-planner"
              sharedState={this.props.sharedState}
              setSharedState={this.props.setSharedState}
            />
          </div>
        )}

        {this.state.tabIndices.Flights !== null && (
          <div
            id="Flights"
            className="tab"
            style={
              this.state.tabs[this.state.tabIndices.Flights].active ?
                openedStyle :
                closedStyle
            }
          >
            <Flights
              origin="trip-planner"
              sharedState={this.props.sharedState}
              setSharedState={this.props.setSharedState}
            />
          </div>
        )}

        {this.state.tabIndices.Rentals !== null && (
          <div
            id="Rentals"
            className="tab"
            style={
              this.state.tabs[this.state.tabIndices.Rentals].active ?
                openedStyle :
                closedStyle
            }
          >
            <Rentals
              origin="trip-planner"
              sharedState={this.props.sharedState}
              setSharedState={this.props.setSharedState}
            />
          </div>
        )}

        {this.state.tabIndices.Cruises !== null && (
          <div
            id="Cruises"
            className="tab"
            style={
              this.state.tabs[this.state.tabIndices.Cruises].active ?
                openedStyle :
                closedStyle
            }
          >
            <Cruises
              origin="trip-planner"
              sharedState={this.props.sharedState}
              setSharedState={this.props.setSharedState}
            />
          </div>
        )}

        {this.state.tabIndices.Eateries !== null && (
          <div
            id="Eateries"
            className="tab"
            style={
              this.state.tabs[this.state.tabIndices.Eateries].active ?
              openedStyle :
              closedStyle
            }
          >
            <Eateries
              origin="trip-planner"
              sharedState={this.props.sharedState}
              setSharedState={this.props.setSharedState}
            />
          </div>
        )}

        {this.state.tabIndices.Conferencing !== null && (
          <div
            id="Conferencing"
            className="tab"
            style={
              this.state.tabs[this.state.tabIndices.Conferencing].active ?
                openedStyle :
                closedStyle
            }
          >
            <Conferencing
              origin="trip-planner"
              sharedState={this.props.sharedState}
              setSharedState={this.props.setSharedState}
            />
          </div>
        )}

        {this.state.tabIndices.Cars !== null && (
          <div
            id="Cars"
            className="tab"
            style={
              this.state.tabs[this.state.tabIndices.Cars].active ?
                openedStyle :
                closedStyle
            }
          >
            <Cars
              origin="trip-planner"
              sharedState={this.props.sharedState}
              setSharedState={this.props.setSharedState}
            />
          </div>
        )}

        {this.state.tabIndices.Tours !== null && (
          <div
            id="Tours"
            className="tab"
            style={
              this.state.tabs[this.state.tabIndices.Tours].active ?
                openedStyle :
                closedStyle
            }
          >
            <Tours
              origin="trip-planner"
              sharedState={this.props.sharedState}
              setSharedState={this.props.setSharedState}
            />
          </div>
        )}

        {this.state.tabIndices.Activities !== null && (
          <div
            id="Activities"
            className="tab"
            style={
              this.state.tabs[this.state.tabIndices.Activities].active ?
                openedStyle :
                closedStyle
            }
          >
            <Activities
              origin="trip-planner"
              sharedState={this.props.sharedState}
              setSharedState={this.props.setSharedState}
            />
          </div>
        )}
      </div>
    );
  }
}

export default App;
