// @ts-check

////////////////////////////////////////////////////////////////////////////////
// JSDoc Type
////////////////////////////////////////////////////////////////////////////////
/**
 * @typedef {{
 * _id: string,
 * title: string,
 * description: string,
 * long_description: string,
 * origin: string,
 * destination: string,
 * start: number,
 * end: number,
 * duration: string,
 * price: number,
 * activities: string[],
 * features: string[]
 * exclusions: string[],
 * gallery: string[],
 * }} Cruise
 */

/**@type {Cruise[]} */
export const cruises = [
  {
    title: "Cruise from Malindi to Watamu",
    origin: "Malindi, Kenya",
    destination: "Watamu, Kenya",
    description: "Integer eleifend ante ut nunc condimentum, sed bibendum " +
      "magna feugiat. Vivamus magna nulla, scelerisque ut nisl vitae, tempor " +
      " tempus urna. Phasellus condimentum tempus ultrices. Ut sed lectus" +
      " rhoncus, laoreet leo.",
    exclusions: [],
    start: Date.now(),
    end: Date.now() + Math.floor(Math.random() * 1000 * 60 * 60 * 24),
    duration: "2 Days",
    price: 299,
    gallery: [],
    _id: "erty-7890-wert-2345",
    activities: [],
    features: [],
    long_description: "Lorem ipsum dolor sit amet, consectetur adipiscing" +
      " elit. Praesent molestie dui purus, in blandit eros euismod in. Fusce" +
      " faucibus felis ante, eget porttitor diam congue quis. Sed posuere" +
      " eget nisi at viverra. Aliquam non sem eu nulla finibus suscipit nec" +
      " et libero. Orci varius natoque penatibus et magnis dis parturient" +
      " montes, nascetur ridiculus mus. Curabitur sed eros pharetra, aliquet" +
      " mi at, porta dui. Mauris eget viverra neque, vitae placerat magna",
  },
  {
    title: "Cruise from Mombasa to Zanzibar",
    origin: "Mombasa, Kenya",
    destination: "Zanzibar, Tanzania",
    description: "Integer eleifend ante ut nunc condimentum, sed bibendum " +
      "magna feugiat. Vivamus magna nulla, scelerisque ut nisl vitae, tempor " +
      " tempus urna. Phasellus condimentum tempus ultrices. Ut sed lectus" +
      " rhoncus, laoreet leo.",
    exclusions: [],
    start: Date.now(),
    end: Date.now() + Math.floor(Math.random() * 1000 * 60 * 60 * 24),
    duration: "7 Days",
    price: 799,
    gallery: [],
    _id: "momb-zanz-0028-0005",
    activities: [],
    features: [],
    long_description: "Lorem ipsum dolor sit amet, consectetur adipiscing" +
      " elit. Praesent molestie dui purus, in blandit eros euismod in. Fusce" +
      " faucibus felis ante, eget porttitor diam congue quis. Sed posuere" +
      " eget nisi at viverra. Aliquam non sem eu nulla finibus suscipit nec" +
      " et libero. Orci varius natoque penatibus et magnis dis parturient" +
      " montes, nascetur ridiculus mus. Curabitur sed eros pharetra, aliquet" +
      " mi at, porta dui. Mauris eget viverra neque, vitae placerat magna",
  },
  {
    title: "Cruise from Lamu to Pretoria",
    origin: "Lamu, Kenya",
    destination: "Pretoria, South Africa",
    description: "Integer eleifend ante ut nunc condimentum, sed bibendum " +
      "magna feugiat. Vivamus magna nulla, scelerisque ut nisl vitae, tempor " +
      " tempus urna. Phasellus condimentum tempus ultrices. Ut sed lectus" +
      " rhoncus, laoreet leo.",
    exclusions: [],
    start: Date.now(),
    end: Date.now() + Math.floor(Math.random() * 1000 * 60 * 60 * 24),
    duration: "20 Days",
    price: 2099,
    gallery: [],
    _id: "lamu-pret-0028-0017",
    activities: [],
    features: [],
    long_description: "Lorem ipsum dolor sit amet, consectetur adipiscing" +
      " elit. Praesent molestie dui purus, in blandit eros euismod in. Fusce" +
      " faucibus felis ante, eget porttitor diam congue quis. Sed posuere" +
      " eget nisi at viverra. Aliquam non sem eu nulla finibus suscipit nec" +
      " et libero. Orci varius natoque penatibus et magnis dis parturient" +
      " montes, nascetur ridiculus mus. Curabitur sed eros pharetra, aliquet" +
      " mi at, porta dui. Mauris eget viverra neque, vitae placerat magna",
  },
  {
    title: "Cruise from Lamu to Seychelles",
    origin: "Lamu, Kenya",
    destination: "Seychelles, Seychelles",
    description: "Integer eleifend ante ut nunc condimentum, sed bibendum " +
      "magna feugiat. Vivamus magna nulla, scelerisque ut nisl vitae, tempor " +
      " tempus urna. Phasellus condimentum tempus ultrices. Ut sed lectus" +
      " rhoncus, laoreet leo.",
    exclusions: [],
    start: Date.now(),
    end: Date.now() + Math.floor(Math.random() * 1000 * 60 * 60 * 24),
    duration: "11 Days",
    price: 1199,
    gallery: [],
    _id: "lamu-seyc-0028-0009",
    activities: [],
    features: [],
    long_description: "Lorem ipsum dolor sit amet, consectetur adipiscing" +
      " elit. Praesent molestie dui purus, in blandit eros euismod in. Fusce" +
      " faucibus felis ante, eget porttitor diam congue quis. Sed posuere" +
      " eget nisi at viverra. Aliquam non sem eu nulla finibus suscipit nec" +
      " et libero. Orci varius natoque penatibus et magnis dis parturient" +
      " montes, nascetur ridiculus mus. Curabitur sed eros pharetra, aliquet" +
      " mi at, porta dui. Mauris eget viverra neque, vitae placerat magna",
  },
  {
    title: "Cruise from Mombasa to Mauritius",
    origin: "Mombasa, Kenya",
    destination: "Mauriitius, Mauritius",
    description: "Integer eleifend ante ut nunc condimentum, sed bibendum " +
      "magna feugiat. Vivamus magna nulla, scelerisque ut nisl vitae, tempor " +
      " tempus urna. Phasellus condimentum tempus ultrices. Ut sed lectus" +
      " rhoncus, laoreet leo.",
    exclusions: [],
    start: Date.now(),
    end: Date.now() + Math.floor(Math.random() * 1000 * 60 * 60 * 24),
    duration: "7 Days",
    price: 799,
    gallery: [],
    _id: "momb-maur-0028-0004",
    activities: [],
    features: [],
    long_description: "Lorem ipsum dolor sit amet, consectetur adipiscing" +
      " elit. Praesent molestie dui purus, in blandit eros euismod in. Fusce" +
      " faucibus felis ante, eget porttitor diam congue quis. Sed posuere" +
      " eget nisi at viverra. Aliquam non sem eu nulla finibus suscipit nec" +
      " et libero. Orci varius natoque penatibus et magnis dis parturient" +
      " montes, nascetur ridiculus mus. Curabitur sed eros pharetra, aliquet" +
      " mi at, porta dui. Mauris eget viverra neque, vitae placerat magna",
  },
];
