import type {FC} from 'react';

import {
  Card, CardProps,
} from "../../../../components/card/property-card";

type SubPropertyContainerProps = {
  sectionTitle: string;
  subProperties: CardProps[];
};

export const SubPropertyContainer: FC<SubPropertyContainerProps> = ({
  sectionTitle, subProperties,
}) => {
  return (
    <div className="sm:col-start-1 sm:col-end-4">
      <h3 className="text-xl font-semibold">{`${sectionTitle} available`}</h3>
      <p className="text-xs opacity-70">
        {`Types available`}
      </p>
      <hr className="my-2" />
      {/** Room Cards Section */}
      <div
        className={
          "sm:flex sm:flex-row sm:justify-between sm:flex-wrap " +
          "lg:justify-evenly"
        }
      >
        {subProperties.map((subProperty, idx) => (
          <Card
            key={`sub_property_${idx}`}
            {...subProperty}
          />
        ))}
    
      </div>
      {/** End of room section */}
    </div>
  );
};
