import {FC} from 'react';
import {RouteComponentProps,  Switch, Route} from 'react-router-dom';

import {ViewAttractionDetails} from './routes/details';
import {ViewAttractionMap} from './routes/map';

export const ViewAttraction: FC<RouteComponentProps> = ({
  match,
}) => {
  console.log("Rendering ViewAttraction");

  return (
    <Switch>
      <Route path={`${match.path}/:id/map`} component={ViewAttractionMap} />
      <Route
        path={`${match.path}/:id/details`}
        component={ViewAttractionDetails}
      />
    </Switch>
  );
};
