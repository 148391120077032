import {Dispatch, FC, SetStateAction, useState} from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

import {Feature} from '../../../../api';
import { Button } from '../../../../components';
import {Chip} from '../../../../components/chip';
import { Input, TextArea } from '../../../../components/input';

import {activityFeatures} from './activity';
import {carFeatures} from './car';
import {conferenceFeatures} from './conference';
import {cruiseFeatures} from './cruise';
import {eateryFeatures} from './eatery';
import {flightFeatures} from './flight';
import {hotelFeatures} from './hotel';
import {rentalFeatures} from './rental';
import {tourFeatures} from './tour';

type EditSubPropertyFeaturesProps = {
  category: "hotel" | "flight" | "rental" | "cruise" | "eatery" | "conference" |
    "car" | "tour" | "activity";
  features: Feature[];
  setFeatures: Dispatch<SetStateAction<Feature[]>>;
};

export const EditSubPropertyFeatures: FC<EditSubPropertyFeaturesProps> = ({
  category, features, setFeatures,
}) => {
  let allFeatures: Feature[] = [];
  // match the category
  switch (category) {
    case "activity":
      allFeatures = activityFeatures;
      break;
    case "car":
      allFeatures = carFeatures;
      break;
    case "conference":
      allFeatures = conferenceFeatures;
      break;
    case "cruise":
      allFeatures = cruiseFeatures;
      break;
    case "eatery":
      allFeatures = eateryFeatures;
      break;
    case "flight":
      allFeatures = flightFeatures;
      break;
    case "hotel":
      allFeatures = hotelFeatures;
      break;
    case "rental":
      allFeatures = rentalFeatures;
      break;
    case "tour":
      allFeatures = tourFeatures;
      break;
    default:
      break;
  }

  const [inactiveFeatures, setInactiveFeatures] = useState<Feature[]>(
    allFeatures.filter((feature) => {
      // travaers through the active features to gather whether the feature is
      // inactive
      let isActive = false;
      for (let i=0; i<features.length; i++) {
        isActive = feature.title === features[i].title;

        if (isActive) {
          break;
        }
      }

      return !isActive;
    })
  );

  const [isMaximized, setIsMaximized] = useState<boolean>(false);

  const [featureName, setFeatureName] = useState<string>("");
  const [featureDescription, setFeatureDescription] = useState<string>("");

  const addFeature = (feature: Feature) => {
    setFeatures([...features, feature]);

    // look for the facility in the inactive facilities, splice it out and set
    // the inactive facilities.
    // TODO Note this might be irrelevant as a change to props may lead to a
    // refresh of the whole component.
    let facilityIndex = -1;

    for (let i=0; i<inactiveFeatures.length; i++) {
      if (inactiveFeatures[i].title === feature.title && inactiveFeatures[i].description === feature.description) {
        facilityIndex = i;
        break;
      }
    }

    if (facilityIndex !== -1) {
      const newInactiveFeatures = [...inactiveFeatures];
      newInactiveFeatures.splice(facilityIndex, 1);

      setInactiveFeatures(newInactiveFeatures);
    }
  };

  const removeFeature = (removableFacility: Feature) => {
    let activeFacilityIndex = -1;

    for (let i=0; i<features.length; i++) {
      if (features[i].description === removableFacility.description && removableFacility.title === features[i].title) {
        activeFacilityIndex = i;
        break;
      }
    }

    if (activeFacilityIndex !== -1) {
      const newFeatures = [...features];
      newFeatures.splice(activeFacilityIndex, 1);

      setFeatures(newFeatures);
      setInactiveFeatures([...inactiveFeatures, removableFacility]);
    }
  };

  return (
    <div
      className={
        `bg-white w-full border mt-3 rounded-md overflow-hidden ${
          !isMaximized ? "h-14" : ""
        }`
      }
    >
      {/* Edit Facilities App Bar */}
      <div
        className="bg-white h-14 px-4 border-b flex flex-row justify-between items-center"
        onClick={() => setIsMaximized(!isMaximized)}
      >
        {/* Title and back action */}
        <div>
          <h6 className="text-xl">Edit Features</h6>
          <p className="text-xs">Pick features present in your property</p>
        </div>
        {/* Title and back action end */}

        {/* Expand or minimize icon to add and remove images */}
        {isMaximized && (
          <FaChevronUp size={24} color="#FF5900" />
        )}
        {!isMaximized && (
          <FaChevronDown size={24} color="#FF5900" />
        )}
        {/* Replace with minimize or maximize icon */}
      </div>
      {/* End of facilities app bar */}

      {/* Active Facilities */}
      <div className="p-4">
        <h5 className="text-xl">
          Active Features
        </h5>
        <p className="text-xs opacity-70">Features present in your property</p>
        <hr className="my-2" />

        {/* Chips of active facilities */}
        <div className="flex flex-row justify-between flex-wrap">

          {features.map((feature) => (
            <Chip
              key={`af_${feature.title}`}
              active={true}
              label={feature.title}
              onClick={() => removeFeature(feature)}
            />
          ))}
        </div>

      </div>
      {/* End of active facilities */}

      {/* Inactive Facilities */}
      <div className="p-4">
        <h5 className="text-xl">
          Inactive Features
        </h5>
        <p className="text-xs opacity-70">Press on a feature to add it to your property</p>
        <hr className="my-2" />

        {/* Chips of active facilities */}
        <div className="flex flex-row justify-between flex-wrap">

          {inactiveFeatures.map((feature) => (
            <Chip
              key={`if_${feature.title}`}
              active={false}
              label={feature.title}
              onClick={() => addFeature(feature)}
            />
          ))}

        </div>
        {/* End of chip of active facilties */}
      </div>
      {/* End of inactive facilities */}
      <div className="p-4">
        <h5 className="text-2xl font-semibold">
          New feature
        </h5>
        <p className="text-sm opacity-70">
          Add feature below if its not in the options
        </p>
        <Input
          className="my-3"
          helper="Name of the feature you'd like to add"
          id="facility-title"
          inputLabel="New feature name"
          name="facility_name"
          value={featureName}
          onChange={(e) => {
            setFeatureName(e.target.value);
          }}
        />

        <TextArea
          helper="Small description of the feature"
          id="facility-description"
          inputLabel="Feature description"
          name="facility_description"
          value={featureDescription}
          onChange={(e) => {
            setFeatureDescription(e.target.value);
          }}
        />

        <div className="flex flex-row justify-center mt-3">
          <Button
            disabled={featureDescription === "" || featureName === ""}
            type="button"
            onClick={() => {
              const newActiveFacilities = [...features];
              newActiveFacilities.push({
                description: featureDescription,
                title: featureName,
              });

              // clearForm
              setFeatureName("");
              setFeatureDescription("");
              setFeatures(newActiveFacilities);
            }}
          >
            Add Feature
          </Button>
        </div>
      </div>
    </div>
  );
};
