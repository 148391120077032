import {FC, useEffect, useState} from "react"
import {BsPencil} from "react-icons/bs";
import {RiCarLine} from "react-icons/ri";
import {RouteComponentProps, useHistory} from "react-router";

import {Car, fetchCar} from "../../../../api";
import {PartnerHeader} from "../../components";

import {PartnerProperty} from "../../components/property";

export const CarView: FC<RouteComponentProps<{id: string}>> = ({
  match,
}) => {
  const history = useHistory();

  const [car, setCar] = useState<null | Car>(null);

  useEffect(() => {
    fetchCar(match.params.id, (err, data) => {
      if (err) {
        console.error(err);

        return;
      }

      setCar(data);
    })
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {car && (
        <>
          <PartnerHeader
            HeaderIcon={RiCarLine}
            title={car.reg_no}
            actions={[
              {
                ActionIcon: BsPencil,
                alwaysVisible: true,
                label: "Edit Property contact information",
                type: "button",
                onClick: () => {
                  // migrate to the property edit tab
                  history.push(
                    `/property-owner/edit/car/${car.id}/details`
                  );
                },
              },
            ]}
          />
          <PartnerProperty
            property={{
              cover: car.gallery[0].url,
              coords: car.coords,
              description: car.model,
              faqs: car.faq,
              gallery: car.gallery,
              id: car.id,
              location: car.location,
              rating: car.rating,
              refund_policy: car.refund_policy,
              reservation_policy: car.reservation_policy,
              reviews: car.reviews,
              short_description: car.model,
              title: car.reg_no,
              exceptions: car.exceptions,
              offers: car.offers,
            }}
            propertyType="car"
          />
        </>
      )}
    </>
  );
}
