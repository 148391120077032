import {FC} from 'react';
import { IconType } from 'react-icons';

type SummaryCardProps = {
  CardIcon: IconType
  title: string;
  className?: string;
};

export const SummaryCard: FC<SummaryCardProps> = ({
  CardIcon, title, className = "", children,
}) => {
  return (
    <div
      className={`elevate-xs w-full bg-white ${className}`}
    >
      <div style={{ marginBottom: 15, padding: 20 }}>
        <div
          style={{
            fontSize: 15,
            fontWeight: "bold",
            marginBottom: 5,
            display: "flex",
          }}
        >
          <div>
            <CardIcon
              className="mr-4"
              style={{ fontSize: 20 }}
            />
          </div>{" "}
          <div>{title}</div>
        </div>
        <div
          style={{
            width: 72,
            background: "#ff735c",
            padding: 3,
            borderRadius: 100,
            marginTop: 5,
          }}
        ></div>
        <div className="pt-2">
          {children}
        </div>
      </div>
    </div>
  );
};
