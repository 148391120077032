import {FC, useContext} from 'react';
import {FaUserFriends} from 'react-icons/fa';

import {AppStateContext} from '../../../../context/app-state-provider';

type ConferencePriceColumnProps = {
  price: {day: number, hour: number};
  capacity: number;
};

export const ConferencePriceColumn: FC<ConferencePriceColumnProps> = ({
  price, capacity,
}) => {
  const {state} = useContext(AppStateContext);

  const {ACTIVE_CURRENCY} = state;
  const exchangeRate = ACTIVE_CURRENCY !== "USD" ?
    state.CONVERSION_RATES.rates[ACTIVE_CURRENCY] :
    1;

  return (
    <div className="flex flex-row items-center justify-between px-4 h-14">
      <div className="flex flex-row">
        <FaUserFriends size={24} />
        <div className="ml-2">
          <h6 className="text-xl">{capacity}</h6>
          <p className="text-sm">Capacity</p>
        </div>
      </div>
      <div>
        <p>
          {`${(price.day * exchangeRate).toFixed(2)} ${ACTIVE_CURRENCY}/day`}
        </p>
        <p>
          {`${(price.hour * exchangeRate).toFixed(2)} ${ACTIVE_CURRENCY}/hr`}
        </p>
      </div>
    </div>
  );
};
