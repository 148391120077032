import {useState} from 'react';
import type {FC, FormEventHandler} from 'react';
import {FaWindowClose} from 'react-icons/fa';
import {useHistory} from 'react-router-dom';

import {Dropdown} from '../../../../components';
import {fetchCompulsoryCarFilters} from '../../../../reducers';
import {DateUtil} from '../../../../util';

import { MobileFilterContext } from '../../context';

import {SectionHeader} from "../section-header";

import {AutoCompletePlaces} from './auto-complete-places';
import {SubmitButton} from './submit-button';
import {DateInput} from './date-input';

type CarsProps = {
  setRawResultsStale?: (rawResultsStale: boolean) => void;
};

export const Cars: FC<CarsProps> = ({setRawResultsStale}) => {
  const compulsoryFilters = fetchCompulsoryCarFilters();
  const [coords, setCoords] = useState({
    lat: compulsoryFilters.lat, lng: compulsoryFilters.lng,
  });
  const history = useHistory();

  const selectAddress = (
    place: string, newCoords: {lat: number, lng: number},
  ) => {
    setCoords(newCoords);
  };
  const url = new URL(location.href);

  // to prevent the checkin and checkout pointing to null, perform a null check
  // and then create based on the date based on today as a default value if null
  const defaultCheckin = (compulsoryFilters.date === null) ?
    DateUtil.changeDateFormatFromDate(new Date(), "DD-MM-YYYY") :
    compulsoryFilters.date;

  // the search values used in the search fields
  const [place, setPlace] = useState(compulsoryFilters.place);
  const [date, setDate] = useState(defaultCheckin);
  const [passengers, setPassengers] = useState(
    Number(compulsoryFilters.passengers)
  );

  // handle form submission
  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    console.log({place, checkin: date, adults: passengers, coords});
    if (place === null || date === null) {
      return;
    }
    // set all the compulsory values in the url and then start linking
    url.searchParams.set("place", place);
    url.searchParams.set("lat", `${coords.lat}`);
    url.searchParams.set("lng", `${coords.lng}`);
    url.searchParams.set("date", date);
    url.searchParams.set("passengers", `${passengers}`);

    // remove all optionalFilters
    url.searchParams.delete("feature");
    url.searchParams.delete("star_rating");
    url.searchParams.delete("theme");
    url.searchParams.delete("price_lowest");
    url.searchParams.delete("price_highest");

    history.push(`${url.pathname}${url.search}`);
    setRawResultsStale && setRawResultsStale(true);
  };

  return (
    <div
      className={
        "elevate-xs small-search-bar z-10 relative transition-all ease-linear" +
        " duration-300 bg-gradient-to-tr from-start to-end rounded-md " +
        "mb-5 lg:sticky lg:top-0"
      }
    >
      <SectionHeader
        style={{
          borderTop: 0,
          color: "white",
          borderBottomColor: "white",
        }}
      >
        Edit Search
      </SectionHeader>
      <MobileFilterContext.Consumer>
        {({ newSearchFilter, setNewSearchFilterVisible }) => (
          <div
            className="absolute top-2 right-2 md:hidden"
            onClick={() => {
              // makes sure that this imstance is never null to prevent crashing
              // during production.
              // It can however be ascertained that it is never null.
              setNewSearchFilterVisible && setNewSearchFilterVisible(false);
            }}
          >
            {newSearchFilter && <FaWindowClose size={28} color="white" />}
          </div>
        )}
      </MobileFilterContext.Consumer>
      <div style={{ padding: 12 }}>
        <form onSubmit={handleSubmit}>
          <label htmlFor="place">Current Location</label>
          <AutoCompletePlaces
            name="place"
            setPlace={setPlace}
            placeholder="Where are you?"
            value={place === null ? "" : place}
            selectAddress={selectAddress}
          />
          <DateInput
            defaultValue={date}
            label="Date"
            name="date"
            setDate={setDate}
          />
          <Dropdown
            defaultValue={passengers === null ? 1 : passengers}
            label="Passengers"
            name="adults"
            options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((adultNumber) => ({
              title: adultNumber === 1 ? `1 adult` : `${adultNumber} adults`,
              value: adultNumber,
            }))}
            onChange={(e) => setPassengers(Number(e.target.value))}
          />

          <SubmitButton
            disabled={
              place === compulsoryFilters.place &&
              date === compulsoryFilters.date &&
              passengers === Number(compulsoryFilters.passengers)
            }
          />
        </form>
      </div>
    </div>
  );
}
