import { FC } from "react";
import Lottie from "react-lottie";
import animation from "../assets/anim/loading_1.json";

type ActivityIndicatorProps = {
  caption: string;
};

/**
 * Loading screen component for the Signin screen.
 */
const ActivityIndicator: FC<ActivityIndicatorProps> = ({caption}) => {
  return (
    <div>
      <div>
        <div>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animation,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={100}
            width={100}
          />
          <h2 className="mt-4" style={{ marginBottom: 15, textAlign: "center" }}>
            {caption}...
          </h2>
        </div>
      </div>
    </div>
  );
}
export default ActivityIndicator;
