import {FC, useContext} from 'react';
import { useHistory } from 'react-router';

import {Reservation} from '../../../api/reservation/types';
import {OverlayProvider, ReservationManager} from '../../../context';
import { AppStateContext } from '../../../context/app-state-provider';

export type ProceedToCheckoutProps = {
  isAvailable?: boolean;
  onProceedToCheckout: () => Reservation;
  price: number;
};

/**
 * This is the container used to wrap the details of the final transaction in
 * terms of dates and price that will be charged to the user if they proceed to
 * checkout.
 * This Component allows for the specific route to provide its own
 * implementation of The details that should be displayed as because different
 * categories have different needs in terms of inputs do display.
 * There is provided a `Proceed To Checkout` button that allows for users to
 * move on to the next bit which is paying.
 * This this requires each component that uses this to provide the equivalent
 * action to be carried out respective to the category.
 *
 * NOTE
 * ----
 * Before we proceed to checkout we check the App State store to make sure that
 * if there is an affiliate link stored that links to this particular property
 * being reserved, we include it in the reservation object.
 *
 * @param props ProceedToCheckout props
 *
 * @returns {JSX.Element}
 */
export const ProceedToCheckout: FC<ProceedToCheckoutProps> = ({
  children, price, isAvailable, onProceedToCheckout,
}) => {
  const history = useHistory();

  const {
    setIsOverlay, setOnOverlay,
  } = useContext(OverlayProvider);
  const {state} = useContext(AppStateContext);

  const ACTIVE_CURRENCY = state.ACTIVE_CURRENCY;
  const exchangeRate = ACTIVE_CURRENCY !== "USD" ?
    state.CONVERSION_RATES.rates[ACTIVE_CURRENCY] :
    1;

  return (
    <ReservationManager.Consumer>
      {({addReservation}) => (
        <div className="checkout-form">
          <h5
            className="text-2xl pb-2 border-b-2 border-white my-2"
          >
            Checkout
          </h5>
          <div className="checkout-form__input-container">
            {children}
          </div>
          <div className="my-2">
            <h5
              className="text-sm pb-2"
            >
              Total
            </h5>
            <h3 className="text-3xl">
              {`${(exchangeRate * price).toFixed(2)} ${ACTIVE_CURRENCY}`}
            </h3>
          </div>
          <hr className="mt-2 bg-white" />
          <div className="flex flex-row items-center justify-center mt-2">
            <button
              disabled={isAvailable}
              style={{backgroundColor: "#e1ad01"}}
              className={
                "outline-none h-9 flex-row items-center px-3 shadow-md " +
                "font-semibold text-white rounded-md"
              }
              onClick={() => {
                // this fetched the reservation from the onProceedToCheckout then
                // proceeds to checkout
                const reservation = onProceedToCheckout();
                // the first part of the property id to get the id without
                // sub property id if present.
                const reservedPropertyId = reservation.property_id.split(":")[0];
                if (state.AFFILIATE) {
                  if (
                    state.AFFILIATE.propertyId === reservedPropertyId &&
                    state.AFFILIATE.category === reservation.category
                  ) {
                    // record the affiliate id that brought the user to this
                    // property
                    reservation.affiliate = state.AFFILIATE.affiliateId;
                  }
                }

                // set the reservation to allow the checkout modules to spot the
                // reservation
                addReservation && addReservation(reservation);
                setIsOverlay && setIsOverlay(false);
                setOnOverlay && setOnOverlay(null);
                history.push("/property-checkout");
              }}
            >
              Proceed to checkout
            </button>
          </div>
        </div>
      )}
    </ReservationManager.Consumer>
  );
};
