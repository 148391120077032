import {FC, useContext, useRef, useState} from "react";
import {RouteComponentProps, useHistory} from "react-router-dom";
import {Formik} from "formik";
import {RiCarLine} from "react-icons/ri";

import {
  Coordinates, FAQ, Picture, postCar, postCarDraft, RefundType,
  ReservationType,
} from "../../../../../api";
import {Input} from "../../../../../components/input";

import {OverlayProvider} from "../../../../search-property/context";
import {SnackbarProvider} from "../../../../../context/snackbar-provider";

import {
  EditReservationPolicyListItem, EditRefundPolicyListItem,
  PropertyLocationAutoComplete, PickImageListItem, PartnerHeader,
} from "../../../components";
import {EditFaqListItem} from "../../../components/edit-faq-list-item";
import {UploadingOverlay} from "../../../components/uploading-overlay";

import {validateCarForm} from "./validate-form";
import { FaPlus, FaSave } from "react-icons/fa";
import ActivityIndicator from "../../../../../components/loading";
import { ConfirmPropertyLocation } from "../../../components/confirm-property-location";

export const NewCar: FC<RouteComponentProps> = () => {
  const history = useHistory();
  const {
    setIsVisible: setSnackbarVisible,
    dismissSnackbar,
  } = useContext(SnackbarProvider);
  const {
    setIsOverlay, setOnOverlay, setIsCancellable,
  } = useContext(OverlayProvider);

  const [reservationPolicy, setReservationPolicy] =
    useState<ReservationType>("full_amount");
  const [refundPolicy, setRefundPolicy] =
    useState<RefundType>("partial_refund");
  const [coords, setCoords] = useState<Coordinates>({ lat: 0, lng: 0 });
  const [faqs, setFaqs] = useState<FAQ[]>([]);
  const [gallery, setGallery] = useState<Picture[]>([]);

  // handle to the input that is supposed to contain the driver image
  const driverImagePickerRef = useRef<HTMLInputElement>(null);

  const [driverImageURL, setDriverImageURL] = useState<string>("");

  const [isUploading, setIsUploading] = useState<boolean>(false);

  const [isSaving, setIsSaving] = useState<boolean>(false);

  // in order to make the validation before submitting easier, even values that
  // will not be got from the for but from overlays are also detailed in the
  // initial values to allow for enforcing of these values inside the formik
  // container to prevent submission if the values are not present.
  const initialValues = {
    reg_no: "",
    model: "",
    drivers_picture: "",
    drivers_name: "",
    drivers_phone: "",
    drivers_email: "",
    location: "",
    coords: "",
    exceptions: "",
    passengers: "",
    reservation_policy: "",
    refund_policy: "",
    gallery: "",
    faqs: "",
  };

  return (
    <>
      {isUploading && (
        <OverlayProvider.Consumer>
          {({setIsCancellable, setIsOverlay, setOnOverlay}) => (
            <UploadingOverlay
              caption="Uploading car details"
              setIsCancellable={setIsCancellable}
              setIsOverlay={setIsOverlay}
              setOnOverlay={setOnOverlay}
            />
          )}
        </OverlayProvider.Consumer>
      )}

      <Formik
        initialValues={initialValues}
        validate={(values) => {
          return validateCarForm({
            coords,
            drivers_email: values.drivers_email,
            drivers_name: values.drivers_name,
            drivers_phone: values.drivers_phone,
            drivers_picture: values.drivers_picture,
            faqs: faqs,
            gallery,
            location: values.location,
            model: values.model,
            passengers: values.passengers,
            refund_policy: refundPolicy,
            reservation_policy: reservationPolicy,
            reg_no: values.reg_no,
          });
        }}
        onSubmit={(values) => {
          // create the new car entry
          let driversPictureUrl = "";
          if (driverImagePickerRef.current?.files) {
            driversPictureUrl = URL.createObjectURL(
              driverImagePickerRef.current.files[0]
            );
          }

          setIsUploading(true);
          // gameloop until the saving of the last draft is done
          postCar(
            {
              coords,
              driver: {
                avatar: {
                  caption: `${values.drivers_name}`,
                  url: driversPictureUrl,
                  filename:
                    driverImagePickerRef.current?.files ?
                    driverImagePickerRef.current.files[0].name :
                    "",
                },
                name: values.drivers_name,
                phone_number: values.drivers_phone,
                email: values.drivers_email,
              },
              exceptions: [],
              faq: faqs,
              gallery,
              id: values.reg_no,
              location: values.location,
              model: values.model,
              passengers: Number(values.passengers),
              rating: 10,
              refund_policy: refundPolicy,
              reservation_policy: reservationPolicy,
              reg_no: values.reg_no,
              reviews: [],
              offers: [],
            },
            (err, result) => {
              setIsUploading(false);
              if (err) {
                console.error(err);
                setSnackbarVisible && setSnackbarVisible({
                  fabPresent: false,
                  isError: true,
                  navRailPresent: true,
                  title: "Unable to publish car details",
                });
                return;
              }
  
              if (result) {
                setSnackbarVisible && setSnackbarVisible({
                  fabPresent: false,
                  isError: false,
                  navRailPresent: true,
                  title: `Car, ${result.reg_no}, published`,
                });
                history.replace(`/property-owner/new/car/${result.reg_no}`);
              } else {
                setSnackbarVisible && setSnackbarVisible({
                  fabPresent: false,
                  isError: true,
                  navRailPresent: true,
                  title: "Unable to publish car details",
                });
              }
            },
          );
        }}
      >
        {({
          values, errors, handleChange, handleSubmit, handleBlur,
          setErrors, validateForm,
        }) => (
          <form onSubmit={handleSubmit} onBlur={handleBlur}>
            <PartnerHeader
              HeaderIcon={RiCarLine}
              title={values.reg_no ? values.reg_no : "New Car"}
              actions={[
                {
                  ActionIcon: FaSave,
                  alwaysVisible: false,
                  label: "Save Draft",
                  onClick: () => {
                    const driverPicture: Picture = {
                      caption: `${values.drivers_name}`,
                      url: "",
                      filename: "",
                    };

                    if (driverImagePickerRef.current?.files) {
                      driverPicture.url = URL.createObjectURL(
                        driverImagePickerRef.current.files[0]
                      );

                      driverPicture.file = driverImagePickerRef
                        .current
                        .files[0];
                      driverPicture.filename = driverImagePickerRef
                        .current
                        .files[0]
                        .name;
                    }

                    setIsSaving(true);
                    setOnOverlay && setOnOverlay(() => () => (
                      <ActivityIndicator
                        caption=""
                      />
                    ));
                    setIsCancellable && setIsCancellable(false);
                    setIsOverlay && setIsOverlay(true);

                    postCarDraft(
                      null,
                      {
                        coords,
                        driver: {
                          avatar: driverPicture,
                          name: values.drivers_name,
                          phone_number: values.drivers_phone,
                          email: values.drivers_email,
                        },
                        exceptions: [],
                        faq: faqs,
                        gallery,
                        id: values.reg_no,
                        location: values.location,
                        model: values.model,
                        passengers: Number(values.passengers),
                        rating: 10,
                        refund_policy: refundPolicy,
                        reservation_policy: reservationPolicy,
                        reg_no: values.reg_no,
                        reviews: [],
                        offers: [],
                      },
                      (err, result) => {
                        setIsSaving(false);
                        setIsOverlay && setIsOverlay(false);
                        setOnOverlay && setOnOverlay(null);
                        setIsCancellable && setIsCancellable(true);

                        if (err) {
                          console.error(err);
                          setSnackbarVisible && setSnackbarVisible({
                            fabPresent: false,
                            isError: true,
                            navRailPresent: true,
                            title: "Unable to publish draft details",
                          });
                          return;
                        }
            
                        if (result) {
                          setSnackbarVisible && setSnackbarVisible({
                            fabPresent: false,
                            navRailPresent: true,
                            isError: false,
                            title: "Draft of the property details saved",
                            action: {
                              label: "DISMISS",
                              onClick: () => {
                                dismissSnackbar && dismissSnackbar();
                              },
                            },
                          });

                          history.replace("/property-owner/car");
                        } else {
                          setSnackbarVisible && setSnackbarVisible({
                            fabPresent: false,
                            isError: true,
                            navRailPresent: true,
                            title: "Unable to save draft",
                          });
                        }
                      },
                    );
                  },
                  type: "button",
                  disabled: isSaving,
                },
                {
                  label: "PUBLISH CAR",
                  onClick: () => {
                    console.log("Saving new car");
                  },
                  disabled: Object.keys(errors).length > 0,
                  type: "submit",
                  ActionIcon: FaPlus,
                  alwaysVisible: true,
                },
              ]}
            />
            <div className="p-4 sm:grid sm:grid-cols-2 gap-4 lg:grid-cols-3 lg:max-w-5xl">
              {/** Input Title */}
              <div>
                <Input
                  id="car-reg-no"
                  helper="Registration number of the car"
                  inputLabel="Car Reg No."
                  name="reg_no"
                  value={values.reg_no}
                  error={errors.reg_no}
                  onChange={handleChange}
                />
              </div>
              {/** End of input Title */}

              {/** Input Title */}
              <div className="mt-3 sm:mt-0">
                {/** Text input */}
                <Input
                  value={values.model}
                  helper="The model of the car"
                  id="car-model"
                  inputLabel="Vehicle model"
                  name="model"
                  error={errors.model}
                  onChange={handleChange}
                />
                {/** End of text input */}
              </div>
              {/** End of input Title */}

              {/** Input Title */}
              <div className="mt-3 sm:mt-0">
                {/** Text input */}
                <Input
                  helper="Maximum number of passengers the car can hold"
                  id="car-max-passengers"
                  inputLabel="Passengers"
                  name="passengers"
                  value={values.passengers}
                  error={errors.passengers}
                  onChange={handleChange}
                />
                {/** End of text input */}
              </div>
              {/** End of input Title */}

              {/** Input Title */}
              <div className="mt-3 sm:mt-0">
                {/** Text input */}
                <PropertyLocationAutoComplete
                  inputLabel="Primary location"
                  name="location"
                  selectAddress={(place, coords) => {
                    setIsCancellable && setIsCancellable(false);
                    // setCoords(coords);
                    setOnOverlay && setOnOverlay(() => () => (
                      <ConfirmPropertyLocation
                        initialCoords={coords}
                        onConfirm={(coords) => {
                          setCoords(coords);
                        }}
                        removeOverlay={() => {
                          setIsOverlay && setIsOverlay(false);
                          setIsCancellable && setIsCancellable(true);
                          setOnOverlay(null);
                        }}
                      />
                    ));

                    setIsOverlay && setIsOverlay(true);
                  }}
                  value={values.location}
                  error={errors.location}
                  onChange={handleChange}
                />
                {/** End of text input */}
              </div>
              {/** End of input Title */}

              {/** Reservation policy card */}
              <EditReservationPolicyListItem
                onSave={async (reservationPolicy) => {
                  setReservationPolicy(reservationPolicy);
                  setErrors(await validateForm());
                }}
                reservationPolicy={reservationPolicy}
              />
              {/** End of Reservation policy card */}

              {/** Refund policy card */}
              <EditRefundPolicyListItem
                onSave={async (refundPolicy) => {
                  setRefundPolicy(refundPolicy);
                  setErrors(await validateForm());
                }}
                refundPolicy={refundPolicy}
              />
              {/** End of Refund policy card */}

              {/** Pick images overlay */}
              <PickImageListItem
                gallery={gallery}
                minImageCount={3}
                onOverlaySave={async (gallery) => {
                  setGallery(gallery);
                  setErrors(await validateForm());
                }}
              />
              {/* End of pick images overlay */}

              {/** Things liks prices and sub properties are found here */}
              <hr className="my-4 sm:col-start-1 sm:col-end-3 h-auto lg:hidden" />
              {/** Special Section */}
              <div className="shadow-md border sm:col-start-1 sm:col-end-3">
                <div className="h-14 flex flex-row justify-between items-center px-4 border-b">
                  <div>
                    <h6 className="text-xl font-semibold">Driver details</h6>
                    <p className="text-xs">
                      these are the details of the driver that will be handling
                      the car
                    </p>
                  </div>
                </div>
                <div className="p-4 flex flex-col items-center sm:flex-row">
                  <div className="sm:mr-2">
                    <label
                      className="sm:flex-shrink-0 sm:mr-4"
                      htmlFor="driver-profile-picture"
                    >
                      <input
                        ref={driverImagePickerRef}
                        className="hidden"
                        id="driver-profile-picture"
                        name="drivers_picture"
                        type="file"
                        onChange={(e) => {
                          if (driverImagePickerRef.current?.files) {
                            setDriverImageURL(
                              URL.createObjectURL(driverImagePickerRef.current?.files[0])
                            );
                          }
                        }}
                      />
                      <div
                        className="mb-2 bg-cover bg-no-repeat bg-center h-36 w-36 rounded-full border"
                        // style="background-image: url(/ATLAS-OF-PLACES.jpg);"
                        style={{
                          backgroundImage: `url(${driverImageURL})`
                        }}
                      ></div>
                    </label>
                    <p className="text-xs text-center mb-2">
                      press to pick picture
                    </p>
                  </div>

                  <div className="w-full sm:flex sm:flex-row sm:flex-wrap">
                    {/** Input Title */}
                    <div className="flex flex-col justify-between sm:mr-3">
                      {/** Text input */}
                      <Input
                        name="drivers_name"
                        value={values.drivers_name}
                        error={errors.drivers_name}
                        helper="This is the driver manning the car"
                        id="car-driver-name"
                        inputLabel="Driver's Name"
                        onChange={handleChange}
                      />
                      {/** End of text input */}
                    </div>
                    {/** End of input Title */}

                    {/** Input Title */}
                    <div className="mt-3 sm:mt-2">
                      {/** Text input */}
                      <Input
                        name="drivers_phone"
                        value={values.drivers_phone}
                        error={errors.drivers_phone}
                        helper="This is the phone number driver manning the car"
                        id="car-driver-phone"
                        inputLabel="Driver's Phone"
                        onChange={handleChange}
                      />
                      {/** End of text input */}
                    </div>
                    {/** End of input Title */}

                    {/** Input Title */}
                    <div className="mt-3">
                      {/** Text input */}
                      <Input
                        name="drivers_email"
                        value={values.drivers_email}
                        error={errors.drivers_email}
                        helper="This is the email of driver manning the car"
                        id="car-driver-phone"
                        inputLabel="Driver's email"
                        onChange={handleChange}
                      />
                      {/** End of text input */}
                    </div>
                    {/** End of input Title */}
                  </div>
                </div>
              </div>
              {/** Special Section */}

              <div className="">
                <h3 className="text-2xl font-semibold">Aditional Information</h3>
                {/** Add FAQs */}
                <EditFaqListItem
                  faqs={faqs}
                  error={errors.faqs}
                  onSave={async (newFaqs) => {
                    setFaqs(newFaqs);
                    setErrors(await validateForm());
                  }}
                />
                {/** End of FAQ */}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};
