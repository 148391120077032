import type {ChangeEventHandler, FC, FocusEventHandler} from 'react';

export type InputProps = {
  id: string;
  name: string;
  className?: string;
  inputLabel: string;
  helper: string;
  error?: string;
  value: string;
  disabled?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
};

interface TimePickerProps {
  name: string,
  onChange: ChangeEventHandler<HTMLInputElement>;
  value: string;
  label?: string;
  error?: string;
  className?: string;
}

const TimePicker: FC<TimePickerProps> = (props) => {
  const {name, label, onChange, value, error, className=""} = props;

  return (
    <div>
      <div
        className={
          "relative border-b-2 focus-within:border-blue-500 " +
          "focus-within:text-blue-500 ml-2 mt-6 " + className
        }
      >
        <p
          className="-translate-y-6 text-xs"
          style={{
            zIndex: -1,
          }}>
          {label}
        </p>
        <input
          className="focus:outline-none"
          name={name}
          type="time"
          value={value}
          onChange={onChange} />
      </div>
      {error && <p className="text-xs text-red-600">{error}</p>}
    </div>
  );

};

export {TextInput as Input} from './text-input';
export {TextArea} from './text-area';

export {TimePicker};
