import {FC} from 'react';
import {RouteComponentProps} from 'react-router-dom';

export const Wallet: FC<RouteComponentProps> = () => {
  return (
    <div>
      Wallet
    </div>
  );
};
