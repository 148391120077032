import {Card, Tabs} from 'antd';
import {FC, useContext, useEffect, useState} from 'react';
import {FaArrowLeft, FaGlobe, FaGlobeAfrica} from 'react-icons/fa';
import {useHistory, useLocation} from 'react-router';
import AutoSizer from 'react-virtualized-auto-sizer';
import {FixedSizeList as List}  from 'react-window';

import {fetchNearbyProperties, Property} from '../../../api';
import {AttractionSite, Nearby} from '../../../api/attraction/types';
import {SnackbarProvider} from '../../../context/snackbar-provider';
import {DateUtil} from '../../../util';

import {NearbyPropertyCard, NearbyPropertyCardProps} from './nearby-property-card';
import { Row } from './row';

type NearbyPropertiesProps = {
  backToAttractionSite?: () => void;
  attractionId: string;
  attraction: AttractionSite;
};

export type DataCell<Y = unknown> = {
  type: "section-title" | "list-item" | "item";
  data: Y;
};

export const NearbyProperties: FC<NearbyPropertiesProps> = ({
  backToAttractionSite, attraction, attractionId,
}) => {
  const {
    dismissSnackbar, setIsVisible,
  } = useContext(SnackbarProvider);

  const history = useHistory();
  const urlParams = new URLSearchParams(useLocation().search);

  const [nearbyProperties, setProperties] = useState<Nearby[]>();
  const [isFetching, setIsFetching] = useState<boolean>(true);

  useEffect(() => {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1)
    const dayAfter = new Date();
    tomorrow.setDate(today.getDate() + 2);

    // since we know that there is the date parameter and the adults parameter
    // and the children parameter, we will use that information to get details
    // from the url
    const [pathname] = location.pathname.split("?");
    const searchParams = new URLSearchParams(location.href.replace(pathname, ""));
    const date = searchParams.get("date");
    const checkout = searchParams.get("checkout");
    // set the checkout
    // if the user has interacted with the DatePicker.RangePicker from the search
    // column, there is the checkout parameter set by the search, however if that
    // is not the case, we will need to set a checkout date that is relative to
    // the date parameter (if present), or the dayAfter variable, if the date
    // parameter is null
    const checkinDate: number = date ? 
      new Date(DateUtil.changeDateFormat(date, "DD-MM-YYYY", "YYYY-MM-DD")).getTime() :
        tomorrow.getTime();
    const checkoutDate: number = checkout ? 
      new Date(DateUtil.changeDateFormat(checkout, "DD-MM-YYYY", "YYYY-MM-DD")).getTime() :
        dayAfter.getTime();
    fetchNearbyProperties(
      attractionId, checkinDate, checkoutDate,
      (err, results) => {
      setIsFetching(false);

      if (err) {
        console.error(err);
  
        setIsVisible && setIsVisible({
          fabPresent: false,
          isError: true,
          navRailPresent: false,
          title: "Unable to fetch nearby properties",
          action: {
            label: "DISMISS",
            onClick: () => dismissSnackbar && dismissSnackbar(),
          },
        });
  
        return;
      }
  
      if (results) {
        setProperties(results);
      } else {
        setIsVisible && setIsVisible({
          fabPresent: false,
          isError: true,
          navRailPresent: false,
          title: "No prooperties found nearby",
          action: {
            label: "DISMISS",
            onClick: () => dismissSnackbar && dismissSnackbar(),
          },
        });
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="w-full max-h-screen sm:w-96 sm:flex-shrink-0">
      <div className="sm:hidden flex flex-row h-12 items-center px-4 border-b">
        <FaArrowLeft
          className="mr-3"
          size={20}
          onClick={() => {
            backToAttractionSite && backToAttractionSite();
          }}
        />
        <h6 className="text-lg" style={{ fontFamily: "Lato" }}>
          Nearby properties available
        </h6>
      </div>

      {/* Nearby properties list container */}
      <div className="px-4 h-full sm:pt-0">
        {/* Section container */}
        {isFetching && (
          <>
            {[1, 2, 3].map((i) => (
              <Card key={`loading_card_${i}`} loading={true} className="mt-2" />
            ))}
          </>
        )}
        {nearbyProperties && (
          <Tabs
            className="w-full"
            tabBarExtraContent={{
              right: (
                <div
                  className={
                    "bg-safari-gold px-3 border rounded-md text-white h-9 " +
                    "flex flex-row items-center"
                  }
                  onClick={() => {
                    const mapUrl = `/view-attraction/${
                      attractionId
                    }/map?${urlParams.toString()}`;
                    history.push(mapUrl);
                  }}
                >
                  <FaGlobeAfrica className="mr-3" />
                  View on map
                </div>
              ),
            }}
          >
            {nearbyProperties &&
              nearbyProperties.map(
                (nearby) =>
                  nearby.properties.length > 0 && (
                    <Tabs.TabPane
                      key={`${nearby.category}`}
                      className="h-full"
                      tab={nearby.category}
                    >
                      <div className="h-full bg-red-50"></div>
                        <List<NearbyPropertyCardProps[]>
                          height={156 * 3}
                          width="100%"
                          itemCount={nearby.properties.length}
                          itemSize={156}
                          itemData={nearby.properties.map((property) => ({
                            attraction,
                            category: nearby.category,
                            property,
                          }))}
                        >
                          {Row}
                        </List>
                    </Tabs.TabPane>
                  )
              )}
          </Tabs>
        )}

      </div>
      {/* End of nearby property ist container */}
    </div>
  );
};

