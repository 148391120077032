import {FaChevronLeft, FaChevronRight} from "react-icons/fa";
import {IoIosBed} from "react-icons/io";

import {FilterSort} from "./filter-sort";
import {ShareButton} from "./share-button";

export function Hotel({
  category, sharedState, orderByFilter, state, updateState,
}) {
  return (
    <div className="sticky top-0 z-20">
      <div
        className="elevate-xs "
        style={{ display: "flex", padding: 10, background: "#fff" }}
      >
        <div className="selector-heading items-center">
          <div style={{ marginRight: 10 }}>
            <IoIosBed />
          </div>{" "}
          Found {sharedState.searchResults.length} Hotels in{" "}
          {/* <span style={{ textTransform: "capitalize", paddingLeft: 5 }}> */}
            {" "}
            {sharedState.city}
          {/* </span> */}
        </div>
        <FaChevronLeft
          style={{
            marginRight: 4,
            marginLeft: 4,
            color: (sharedState.pagination - 1 > 0)  ? "red": "#424242",
          }}
          onClick={() => {
            if (sharedState.pagination - 1 > 0) {
              updateState({pagination: sharedState.pagination - 1})
            }
          }}
        />
        <div className="selector-number-of-results">
          <div>
            Showing{" "}
            {!sharedState.searchResults.length
              ? "0"
              : sharedState.pagination * 10 - 9}
            -
            {!sharedState.searchResults.length
              ? "0"
              : sharedState.searchResults.length > 10 &&
                sharedState.pagination * 10 <
                  sharedState.searchResults.length
              ? sharedState.pagination * 10
              : sharedState.searchResults.length}{" "} 
            of{" "}
            {!sharedState.searchResults.length
              ? "0"
              : sharedState.searchResults.length}
          </div>
        </div>
        <FaChevronRight
          style={{
            marginRight: 4,
            marginLeft: 4,
          }}
          color={
            (sharedState.pagination <
              Math.ceil(sharedState.searchResults.length / 10)
            ) ? "red": "#424242"
          }
          onClick={() => {
            if (sharedState.pagination <
              Math.ceil(sharedState.searchResults.length / 10)
            ) {
              updateState({pagination: sharedState.pagination + 1})
            }
          }}
        />
        <ShareButton category={category} />
      </div>
      <div
        className="elevate-xs"
        style={{
          borderTop: "1px solid #ddd",
          marginTop: 0,
          background: "#fff",
        }}
      >
        <div className="selector-menu">
          <div
            className="selector-menu-item"
            onClick={() => {
              orderByFilter("hotel_review_score");
            }}
          >
            Review Score
          </div>
          <div
            className="selector-menu-item"
            onClick={() => {
              orderByFilter("hotel_number_of_reviews");
            }}
          >
            Most Reviews
          </div>
          <div
            className="selector-menu-item"
            onClick={() => {
              orderByFilter("price", state.pricePolarity);
            }}
          >
            Price {state.pricePolarity ? "(highest)" : "(lowest)"}
          </div>
          <div
            className="selector-menu-item"
            onClick={() => {
              orderByFilter("hotel_star");
            }}
          >
            Star Rating
          </div>
          <div
            className="selector-menu-item"
            onClick={() => {
              orderByFilter(
                "room_footage",
                state.roomFootagePolarity
              );
            }}
          >
            Room{" "}
            {state.roomFootagePolarity ? "(smallest)" : "(largest)"}
          </div>
          <div
            className="selector-menu-item"
            onClick={() => {
              orderByFilter("distance");
            }}
          >
            Dist. from city
          </div>
          {/* <div className="selector-menu-item">Clear Filters</div> */}
        </div>
      </div>
      <FilterSort
        orderByFilter={orderByFilter}
        state={state}
        updateState={updateState}
      />
    </div>
  );
}
