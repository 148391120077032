import { UPDATE_USER, UPDATE_SIGN_STATUS, UPDATE_SELECTED_HOTEL, UPDATE_TRIP_PLANNER, UPDATE_WEBSITE_ACTIVE,UPDATE_SELECTED_CURRENCY,UPDATE_SELECTED_MULTIPLEX } from './types';

const initialState = {
    //1. general
    user: {},
    isSignedIn: false,
    authLevel: 'PARTNER',
    //2. hotel
    /* hotel editor */
    /* booking editor */
    selectedHotel: { name: '', date: null },
    tripPlanner: false,
    websiteActive: true,
    selected_currency: 'USD',
    selected_multiplex: 1,
    // multiplex_usd: 1,
    // multiplex_kes: 109.65,
    // multiplex_pound: 0.72,
    // multiplex_euro: 0.84,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_USER:
            return {
                ...state,
                user: action.data
            };
        case UPDATE_SIGN_STATUS:
            return {
                ...state,
                isSignedIn: action.data
            };
        case UPDATE_SELECTED_HOTEL:
            return {
                ...state,
                selectedHotel: action.data
            };
        case UPDATE_TRIP_PLANNER:
            return {
                ...state,
                tripPlanner: action.data
            };
        case UPDATE_WEBSITE_ACTIVE:
            return {
                ...state,
                websiteActive: action.data
            }
        case UPDATE_SELECTED_CURRENCY:
            return {
                ...state,
                selected_currency: action.data
            }
        case UPDATE_SELECTED_MULTIPLEX:
            return {
                ...state,
                selected_multiplex: action.data
            }
        default:
            return state;
    }
}
