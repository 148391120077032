import {OnCompleteCallback, Policy} from ".";

export type ReservationPolicies = {
  full_amount: Policy,
  no_deposit: Policy;
  partial_deposit: Policy;
};

export type RefundPolicies = {
  no_refund: Policy;
  partial_refund: Policy;
  full_refund: Policy;
};

export const getRefundPolicy = (onFetch: OnCompleteCallback<RefundPolicies>): void => {
  const refundPolicies: RefundPolicies = {
    full_refund: {
      title: "Full Refund",
      description: [
        "Upon Request, the user will be able to get back 100% of the money " +
        "that they have currently deposited to the property owner for the " +
        "reservation",
        "The property owner has a grace period of 14 days to ensure that the " +
        "user has gotten back all the money",
      ],
    },
    no_refund: {
      title: "No refund offered",
      description: [
        "The amount deposited by the user for the reservation cannot be got " +
        "back after the reservation on the property is cancelled",
        "This includes the reservation fee and any other money deposited " +
        "towards booking of the property in question",
      ],
    },
    partial_refund: {
      title: "Partial refund",
      description: [
        "Part of the money deposited by the user is eligible for refunding " +
        "(automatically after the user cancels the reservation)",
        "The amount refundable back to the user is the total amount that they " +
        "had put into reservation minus the charge for cancellation."
      ],
    },
  };

  onFetch(null, refundPolicies);
};


export const getReservationPolicies = (onFetch: OnCompleteCallback<ReservationPolicies>): void => {
  const reservationPolicies: ReservationPolicies = {
    full_amount: {
      title: "Full amount expected",
      description: [
        "During reservation, the user is expected to deposit the full amount to " +
        "in order to reserve the property over the desired period",
        "Failure to transact the full amount upon reservation leads to an " +
        "unsuccessful reservation",
      ],
    },
    no_deposit: {
      title: "No deposit",
      description: [
        "This policy means that upon reseration, no amount is expected after " +
        "the fact.",
        "The amount will however be expected on a certain time period as "+
        "specified by the property owner after reservation. Failure to " +
        "comply does lead to cancellation of the reservation."
      ],
    },
    partial_deposit: {
      title: "Partial amount",
      description: [
        "This policy dictates that the user is expected to pay a certain " +
        "amount upon reservation (amount made visible to the user upon " +
        "reservation).",
        "This initially deposited amount may not be liable for refund in the " +
        "case that the user wants to cancel the reservation",
      ],
    },
  };

  onFetch(null, reservationPolicies);
};
