import {FC, useState} from 'react';
import {DatePicker, TimePicker} from 'antd';

import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import {Button} from '../../../../components';
import {postReservation} from '../../../../api';
import {
  EateryReservation as EateryReservationType,
} from '../../../../api/reservation/types';
import { Input } from '../../../../components/input';

type EateryReservationProps = {
  propertyId: string;
  removeOverlay: () => void;
};

type Time = {
  hour: number;
  minute: number;
  second: number;
};

export const EateryReservation: FC<EateryReservationProps> = ({
  propertyId, removeOverlay,
}) => {
  const todaysDate =  new Date();
  todaysDate.setHours(0, 0, 0, 0);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [startTime, setStartTime] = useState<Time | null>(null);
  const [endTime, setEndTime] = useState<Time | null>(null);

  // input control for Number of rooms
  const [numberOfTables, setNumberOfRooms] = useState<string>("");
  const [numberOfTablesError, setNumberOfRoomsError] = useState<string>("");

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  return (
    <>
      <div className="p-4 bg-white">
        <h6 className="text-xs mb-3">
          Date
        </h6>
        <DatePicker
          className="flex-shrink-0 flex-grow-0 w-full"
          disabled={isSubmitting}
          allowClear={false}
          disabledDate={(date) => {
            return (
              Number(date.format("x")) < todaysDate.getTime()
            );
          }}
          onChange={(date) => {
            if (date) {
              setStartDate(date.toDate());
            }
          }}
        />

        <div className="mt-3 flex flex-row justify-between">
          <div className="mr-2">
            <p className="text-xs mb-2">
              Start time
            </p>
            <TimePicker
              disabled={isSubmitting}
              allowClear={false}
              onChange={(val) => {
                if (val) {
                  setStartTime({
                    hour: val.hour(),
                    minute: val.minute(),
                    second: val.second(),
                  });
                }
              }}
            />
          </div>

          <div>
            <p className="text-xs mb-2">
              End time
            </p>
            <TimePicker
              disabled={isSubmitting}
              allowClear={false}
              onChange={(val) => {
                if (val) {
                  setEndTime({
                    hour: val.hour(),
                    minute: val.minute(),
                    second: val.second(),
                  });
                }
              }}
            />
          </div>
        </div>

        <Input
          className="mt-3"
          helper="Number of tables to be made unavailable"
          id="no-of-tables"
          inputLabel="Number of unavailable rooms"
          name="no-of-tables"
          value={`${numberOfTables}`}
          error={numberOfTablesError}
          onChange={(e) => {
            const newNumberOfRooms = Number(e.target.value);

            setNumberOfRooms(e.target.value);
            // look for an error
            if (isNaN(newNumberOfRooms)) {
              setNumberOfRoomsError("Field should contain valid number");
            } else {
              setNumberOfRoomsError("");
            }
          }}
        />
        <div className="flex flex-row pt-3 items-center justify-center">
          <Button
            disabled={
              startDate === null || endTime === null || startTime === null ||
                isSubmitting || isNaN(Number(numberOfTables))
            }
            type="button"
            onClick={() => {
              if (
                startDate === null || endTime === null || startTime === null
              ) {
                return;
              }
              setIsSubmitting(true);

              startDate.setHours(
                startTime.hour, startTime.minute, startTime.second,
              );
              const endDate = new Date(startDate);
              endDate.setHours(endTime.hour, endTime.minute, endTime.second);

              postReservation(
                ({
                  amount_received: 0,
                  category: "eatery",
                  checkin: startDate.getTime(),
                  checkout: endDate.getDate(),
                  due_date: 0,
                  expected_amount: 0,
                  number_of_tables: Number(numberOfTables),
                  people: 0,
                  property_id: propertyId,
                  reservation_date: Date.now(),
                  reservation_type: "full_amount",
                  status: "external",
                } as EateryReservationType),
                (err, reservation) => {
                  setIsSubmitting(false);

                  if (err) {
                    console.error(err);
                    return;
                  }

                  removeOverlay();
                },
              );
            }}
          >
            Make Unavailable
          </Button>
        </div>
      </div>
    </>
  );
};
