import {FC, MouseEventHandler, useContext, useState} from 'react';
import type {IconType} from "react-icons";
import {FaBars} from "react-icons/fa";

import {OverlayProvider} from '../../search-property/context';

import {NavigationRail} from '.';
import {FiMoreVertical} from 'react-icons/fi';
import useOnclickOutside from 'react-cool-onclickoutside';

type PartnerHeaderProps = {
  title: string;
  HeaderIcon: IconType;
  actions?: {
    label: string;
    onClick: MouseEventHandler<HTMLButtonElement> | MouseEventHandler<HTMLDivElement>;
    disabled?: boolean;
    ActionIcon: IconType;
    alwaysVisible: boolean;
    type: "button" | "submit" | "reset";
  }[];
};

/**
 * This is a reusable header component that displays the content of each unique
 * route in the `partner` route. At the core, it is made re-usable by the
 * presence of a title and HeaderIcon that are controllable from the root
 * container of the `Route`. thus making it easier for children of this route to
 * manipulate the header and the Icon.
 *
 * @param props the props taken in by the Header
 * @returns Re-usable header component
 */
export const PartnerHeader: FC<PartnerHeaderProps> = ({
  title, actions, HeaderIcon,
}) => {
  const {setIsOverlay, setOnOverlay} = useContext(OverlayProvider);

  const [overflowMenuVisible, setOverflowMenuVisible] = useState<boolean>(false);

  const overflowMenuRef = useOnclickOutside(() => {
    setOverflowMenuVisible(false);
  });

  const provideNavigationDrawer = () => {
    return (
      <NavigationRail
        vosd={true}
        removeOverlay={() => {
          setIsOverlay && setIsOverlay(false);
          setOnOverlay && setOnOverlay(null);
        }}
      />
    );
  };

  return (
    <OverlayProvider.Consumer>
      {({setIsOverlay, setOnOverlay}) => (
        <div
          className={
            "w-full bg-safari-gold text-white sticky top-0 h-14 " +
            "flex items-center justify-between px-4 shadow-md z-20"
          }
          style={{
            top: "0px !important",
          }}
        >
          {/* <div className="flex flex-row justify-between w-full overflow-x-hidden"> */}
            <div className="flex flex-row items-center w-full h-full overflow-hidden">
              <FaBars
                className="drawer-icon flex-shrink-0"
                size={24}
                onClick={() => {
                  setOnOverlay && setOnOverlay(() => provideNavigationDrawer);
                  setIsOverlay && setIsOverlay(true);
                }}
              />
              <HeaderIcon className="alternative-drawer-icon" size={24} />
              <h2 className="ml-8 text-xl font-semibold overflow-x-hidden whitespace-nowrap overflow-ellipsis">
                {title}
              </h2>
            </div>

          {/* </div> */}

          {/* Actions */}
          {actions && (
            <div className="flex flex-row justify-between items-center">
              {/* The ones that are not visible are only shown on mobile*/}
              {actions
                .filter(action => !action.alwaysVisible)
                .map(({disabled, type, label, onClick, ActionIcon}, idx) => (
                  <button
                    disabled={disabled}
                    key={`visible_when_room_${idx}`}
                    type={type}
                    className={
                      "hidden py-2 px-3 mr-4 rounded-md " +
                      "sm:flex sm:flex-row sm:items-center " +
                      "font-semibold text-sm whitespace-nowrap " +
                      "sm:block " +
                      `${
                        (disabled) ?
                          "text-gray-700 border-gray-700 cursor-not-allowed" :
                          "text-black border-black"
                      }`
                    }
                    onClick={onClick as MouseEventHandler<HTMLButtonElement>}
                  >
                    <ActionIcon
                      className="sm:mr-4"
                      size={24}
                    />
                    <p className="text-sm font-semibold">{label}</p>
                  </button>
                ))}
              {/* Only show the ones that are always visible */}
              {actions
                .filter(action => action.alwaysVisible)
                .map(({
                  ActionIcon, label, onClick, type, disabled,
                }, idx) => (
                  <button
                    disabled={disabled}
                    key={`pah_${idx}`}
                    type={type}
                    className={
                      "py-2 px-3 rounded-md " +
                      "flex flex-row items-center " +
                      "font-semibold text-sm whitespace-nowrap " +
                      `${
                        (disabled) ?
                          "text-gray-700 cursor-not-allowed" :
                          "text-black "
                      }`
                    }
                    onClick={onClick as MouseEventHandler<HTMLButtonElement>}
                  >
                    <ActionIcon
                      size={24}
                      className={
                        `hidden sm:block sm:mr-4 ${
                          disabled ? "text-gray-700" : "text-black"
                        }`
                      }
                    />
                    <p className="text-sm font-semibold">{label}</p>
                  </button>
                ))}
                {/* First checks if there are any overflow actions first */}
                {actions.filter(action => action.alwaysVisible).length > 0 && (
                  <FiMoreVertical
                    size={24}
                    className="sm:hidden"
                    onClick={() => {
                      setOverflowMenuVisible(true);
                    }}
                  />
                )}
                {overflowMenuVisible && (
                  <div
                    ref={overflowMenuRef}
                    className="absolute py-2 bg-white text-black top-full right-4 mt-2 w-32 shadow-md"
                  >
                    {actions
                      .filter(action => !action.alwaysVisible)
                      .map((action, idx) => (
                        <div
                          key={`overflow_action_${idx}`}
                          className={
                            "w-full h-12 flex flex-row items-center px-4 " +
                            "hover:bg-black hover:bg-opacity-30"
                          }
                          onClick={action.onClick as MouseEventHandler<HTMLDivElement>}
                        >
                          {action.label}
                        </div>
                      ))}
                  </div>
                )}
            </div>
          )}
        </div>
      )}
    </OverlayProvider.Consumer>
  );
};
