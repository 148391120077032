import { FC, useContext, useEffect, useState } from "react";
import { FaClipboardList } from "react-icons/fa";
import { PayPalButton } from "react-paypal-button-v2";

import { fetchPopulatedReservations, postReservations } from "../../api/reservation";
import { PopulatedReservation, Reservation } from "../../api/reservation/types";
import { Input } from "../../components/input";
import { DateUtil } from "../../util";
import {
  deleteDraftReservation,
  postMPesaTransaction,
  postPayPalTransaction,
  postReservationDraft,
  updateReservationDraft,
  verifyReCAPTCHAToken,
} from "../../api";
import ActivityIndicator from "../../components/loading";
import { ReservationManager } from "../../context";

import { OverlayProvider } from "../search-property/context";

import { SuccessDialog } from "./success-dialog";
import { ConfirmationDialog } from "./confirmation-dialog";

import mpesaLogo from "../../assets/mpesa.png";
import { useLocation } from "react-router";
import { FailureDialog } from "./failure-dialog";
import { AppStateContext } from "../../context/app-state-provider";
import ReCAPTCHA from "react-google-recaptcha";
import { Button } from "../../components";

type SummaryDialogProps = {
  reservations: Reservation[];
  removeReservation?: () => void;
};

export const SummaryDialog: FC<SummaryDialogProps> = () => {
  const { setIsCancellable, setIsOverlay, setOnOverlay } =
    useContext(OverlayProvider);
  const { state } = useContext(AppStateContext);

  const searchParams = new URLSearchParams(useLocation().search);
  const { reservations } = useContext(ReservationManager);
  const [isPopulating, setIsPopulating] = useState<boolean>(true);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [recCAPTCHAVerified, setReCAPTCHAVerified] = useState<boolean>(false);

  const [reservationDraftId, setReservationDraftId] = useState<string | null>(
    searchParams.get("t_id")
  );

  const [populatedReservations, setPopulatedReservations] = useState<
    PopulatedReservation[]
  >([]);

  let price = 0;

  reservations.forEach((reservation) => {
    price += reservation.expected_amount;
  });

  useEffect(() => {
    if (reservationDraftId) {
      // update the stored draft with the new parameters the user has entered on
      // the properties screen
      // TODO: This might need updating once the trip planner is re-added into
      // the platform
      updateReservationDraft(
        reservationDraftId,
        { draft: reservations[0] },
        (err) => {
          if (err) {
            console.error(err);

            return;
          }
        }
      );
    } else {
      // since there is no draft created, we create the new draft to make sure
      // that the user can recover from this if they cancel in-between
      postReservationDraft(
        reservations[0].category,
        reservations[0],
        (err, result) => {
          if (err) {
            console.error(err);

            return;
          }

          if (result) {
            result.id && setReservationDraftId(result.id);
          }
        }
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchPopulatedReservations(reservations, (err, results) => {
      if (err) {
        console.error(err);

        return;
      }

      if (results) {
        setIsPopulating(false);
        setPopulatedReservations(results);
      }
    });
  }, [reservations]);

  const calculateTotalPriceWithTax = (price: number) => {
    return (price * 1.16).toFixed(2);
  };

  const { ACTIVE_CURRENCY } = state;
  const exchangeRate =
    ACTIVE_CURRENCY !== "USD"
      ? state.CONVERSION_RATES.rates[ACTIVE_CURRENCY]
      : 1;

  return (
    <div className="p-4 sm:flex sm:flex-row sm:justify-center">
      <div className="border rounded-md overflow-hidden sm:w-96">
        {/* Booking summary Header Start */}
        <div
          className={
            "px-4 py-3 bg-safari-gold text-xl text-white font-semibold shadow-md"
          }
        >
          <div className="flex flex-row items-center text-white">
            <FaClipboardList className="mr-3" size={24} />
            Booking Summary
          </div>
        </div>
        {/* Booking Summary header end */}

        <div className="p-4">
          {isPopulating && (
            <div
              className={
                "w-full h-full bg-white p-4 rounded-md shadow-md border " +
                "flex items-center justify-center"
              }
            >
              <ActivityIndicator caption="Fetching reservations" />
            </div>
          )}
          {populatedReservations.map((reservation, idx) => {
            return (
              <div key={`reservation_${idx}`} className="p-4">
                <div
                  className={
                    "h-12 w-full grid grid-cols-2 place-content-center " +
                    "place-items-start border-b"
                  }
                >
                  <h6 className="text-xl font-semibold">
                    {/* Capitalizes the Category name */}
                    {`${reservation.category[0].toUpperCase()}${reservation.category.substr(
                      1
                    )} Name`}
                  </h6>
                  <p>{reservation.property.title}</p>
                </div>

                {!reservation.checkout && (
                  <div
                    className={
                      "h-12 w-full grid grid-cols-2 place-content-center " +
                      "place-items-start border-b"
                    }
                  >
                    <h6 className="text-xl font-semibold">Checkin</h6>
                    <p>
                      {DateUtil.changeDateFormatFromDate(
                        new Date(reservation.checkin),
                        "DD-MM-YYYY"
                      )}
                    </p>
                  </div>
                )}

                {reservation.checkout && (
                  <div
                    className={
                      "h-12 w-full grid grid-cols-3 place-content-center " +
                      "place-items-start border-b"
                    }
                  >
                    <h6 className="text-xl font-semibold">Stay</h6>
                    <p>
                      {DateUtil.changeDateFormatFromDate(
                        new Date(reservation.checkin),
                        "DD-MM-YYYY"
                      )}
                    </p>
                    <p>
                      {DateUtil.changeDateFormatFromDate(
                        new Date(reservation.checkout),
                        "DD-MM-YYYY"
                      )}
                    </p>
                  </div>
                )}
              </div>
            );
          })}
        </div>

        <div className="border-t border-black p-4">
          <div
            className={
              "h-12 w-full grid grid-cols-2 place-content-center " +
              "place-items-start border-b"
            }
          >
            <h6 className="text-xl font-semibold">Sub total</h6>
            <p>{`${(price * exchangeRate).toFixed(2)} ${ACTIVE_CURRENCY}`}</p>
          </div>

          <div
            className={
              "h-12 w-full grid grid-cols-2 place-content-center " +
              "place-items-start border-b"
            }
          >
            <h6 className="text-xl font-semibold">Payable total</h6>
            <p>
              {`${(
                Number(calculateTotalPriceWithTax(price)) * exchangeRate
              ).toFixed(2)} ${ACTIVE_CURRENCY}`}
            </p>
          </div>
        </div>
      </div>

      {/* Payment Options */}
      <div className="mt-3 border rounded-md overflow-hidden sm:mt-0 sm:w-80">
        {/* Booking summary Header Start */}
        <div
          className={
            "px-4 py-3 bg-safari-gold text-xl text-white font-semibold " +
            "shadow-md"
          }
        >
          <div className="flex flex-row items-center text-white">
            <FaClipboardList className="mr-3" size={24} />
            Payment Options
          </div>
        </div>
        {populatedReservations.length > 0 &&
          populatedReservations[0].property.reservation_policy ===
            "no_deposit" && (
            <div className="flex flex-col p-4 items-center">
              <ReCAPTCHA
                sitekey="6LcAQzMeAAAAAIApRCJDPdnx1QevegCX_HMW5bcO"
                onChange={async (token) => {
                  if (!token) return;

                  const recaptchaVerfied = await verifyReCAPTCHAToken(token);

                  console.log("ReCAPTCHA result: ", recaptchaVerfied);

                  setReCAPTCHAVerified(recaptchaVerfied);
                }}
              />
              <Button
                className="my-3"
                type="submit"
                disabled={!recCAPTCHAVerified}
                onClick={() => {
                  setOnOverlay &&
                    setOnOverlay(() => () => (
                      <ConfirmationDialog
                        setIsCancellable={setIsCancellable}
                        setIsOverlay={setIsOverlay}
                        setOnOverlay={setOnOverlay}
                      />

                      // upload each reservation
                    ));

                  setIsCancellable && setIsCancellable(false);
                  setIsOverlay && setIsOverlay(true);

                  postReservations(reservations, (err, res) => {
                    if (err) {
                      console.error(err);
                      setIsOverlay && setIsOverlay(false);
                      setIsCancellable && setIsCancellable(true);
                      setOnOverlay &&
                        setOnOverlay(() => () => (
                          <FailureDialog
                            onRetry={() => {
                              setIsOverlay && setIsOverlay(false);
                              setOnOverlay && setOnOverlay(null);
                            }}
                            setOnOverlay={setOnOverlay}
                            setIsCancellable={setIsCancellable}
                            setIsOverlay={setIsOverlay}
                            removeOverlay={() => {
                              setOnOverlay(null);
                              setIsOverlay && setIsOverlay(false);
                              setIsCancellable && setIsCancellable(true);
                            }}
                          />
                        ));

                      setIsOverlay && setIsOverlay(true);
                      return;
                    }

                    // inspect the reservations and whether they have
                    // been updated
                    if (res) {
                      console.info("Reservations made successfully");

                      setIsOverlay && setIsOverlay(false);

                      setTimeout(() => {
                        setOnOverlay &&
                          setOnOverlay(() => () => (
                            <SuccessDialog
                              setIsOverlay={setIsOverlay}
                              setIsCancellable={setIsCancellable}
                              setOnOverlay={setOnOverlay}
                            />
                          ));

                        setIsCancellable && setIsCancellable(false);
                        setIsOverlay && setIsOverlay(true);
                      }, 700);

                      if (reservationDraftId) {
                        deleteDraftReservation(reservationDraftId);
                      }

                      return;
                    }
                  });
                }}
              >
                Make Reservation
              </Button>
            </div>
          )}
        {/* Booking Summary header end */}
        {populatedReservations.length > 0 &&
          populatedReservations[0].property.reservation_policy !==
            "no_deposit" && (
            <div className="flex flex-col items-center p-4">
              <div
                aria-label="M-Pesa"
                className="h-16 w-full bg-contain bg-center bg-no-repeat"
                style={{
                  backgroundImage: `url(${mpesaLogo})`,
                }}
              />

              <div className="w-full flex-shrink-0">
                <Input
                  helper="Phone number to be billed"
                  id="checkout-phone-number"
                  inputLabel="Phone Number 254XXXXXXXXX"
                  name="phone_number"
                  value={phoneNumber}
                  error={
                    !/\+\d{12}|\d{10}/.test(phoneNumber)
                      ? "This must be a valid phone number"
                      : ""
                  }
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                  }}
                />

                <button
                  disabled={!/\+\d{12}|\d{10}/.test(phoneNumber)}
                  className={`h-9 my-3 shadow-md w-full text-white ${
                    !/(\+\d{12})|(\d{10})/.test(phoneNumber)
                      ? "bg-gray-700 "
                      : "bg-green-600 "
                  } `}
                  onClick={() => {
                    setOnOverlay &&
                      setOnOverlay(() => () => (
                        <ConfirmationDialog
                          setIsCancellable={setIsCancellable}
                          setIsOverlay={setIsOverlay}
                          setOnOverlay={setOnOverlay}
                        />

                        // upload each reservation
                      ));

                    // // all the reservations are detailed into the system
                    // proceed to with mpesa
                    postMPesaTransaction(
                      price,
                      phoneNumber,
                      reservations,
                      (err, res) => {
                        if (err) {
                          console.error(err);
                          setIsOverlay && setIsOverlay(false);
                          setIsCancellable && setIsCancellable(true);
                          setOnOverlay &&
                            setOnOverlay(() => () => (
                              <FailureDialog
                                onRetry={() => {
                                  setIsOverlay && setIsOverlay(false);
                                  setOnOverlay && setOnOverlay(null);
                                }}
                                setOnOverlay={setOnOverlay}
                                setIsCancellable={setIsCancellable}
                                setIsOverlay={setIsOverlay}
                                removeOverlay={() => {
                                  setOnOverlay(null);
                                  setIsOverlay && setIsOverlay(false);
                                  setIsCancellable && setIsCancellable(true);
                                }}
                              />
                            ));

                          setIsOverlay && setIsOverlay(true);
                          return;
                        }

                        // inspect the reservations and whether they have
                        // been updated
                        if (res) {
                          console.info("Reservations made successfully");

                          setIsOverlay && setIsOverlay(false);

                          setTimeout(() => {
                            setOnOverlay &&
                              setOnOverlay(() => () => (
                                <SuccessDialog
                                  setIsOverlay={setIsOverlay}
                                  setIsCancellable={setIsCancellable}
                                  setOnOverlay={setOnOverlay}
                                />
                              ));

                            setIsCancellable && setIsCancellable(false);
                            setIsOverlay && setIsOverlay(true);
                          }, 700);

                          if (reservationDraftId) {
                            deleteDraftReservation(reservationDraftId);
                          }

                          return;
                        }
                      }
                    );

                    setIsCancellable && setIsCancellable(false);

                    setIsOverlay && setIsOverlay(true);
                  }}
                >
                  Pay with Mpesa
                </button>

                <hr className="my-2" />

                {/*
                This class paypal-button-container is used to revert the button
                zIndex to prevent the button being pressable on an overlay
              */}
                <div
                  className="paypal-button-container relative"
                  style={{ zIndex: 1 }}
                >
                  <PayPalButton
                    amount="0.01"
                    shippingPreference="NO_SHIPPING"
                    // eslint-disable-next-line no-unused-vars
                    onSuccess={(
                      details: Record<string, unknown>,
                      data: Record<string, unknown>
                    ) => {
                      alert(`Transaction completed`);
                      postPayPalTransaction(
                        price,
                        data.orderID as string,
                        reservations,
                        (err, res) => {
                          if (err) {
                            console.error(err);

                            return;
                          }

                          if (res) {
                            setIsOverlay && setIsOverlay(false);

                            setOnOverlay &&
                              setOnOverlay(() => () => (
                                <SuccessDialog
                                  setIsOverlay={setIsOverlay}
                                  setIsCancellable={setIsCancellable}
                                  setOnOverlay={setOnOverlay}
                                />
                              ));

                            setIsCancellable && setIsCancellable(false);
                            setIsOverlay && setIsOverlay(true);

                            if (reservationDraftId) {
                              deleteDraftReservation(reservationDraftId);
                            }
                          }
                        }
                      );
                      // // OPTIONAL: Call your server to save the transaction
                      // return fetch("/paypal-transaction-complete", {
                      //     method: "post",
                      //     body: JSON.stringify({
                      //         orderID: data.orderID
                      //     })
                      // });
                    }}
                  />
                </div>
              </div>
            </div>
          )}
      </div>
      {/* End of payment options */}
    </div>
  );
};
