import {FC, useEffect, useState} from 'react';
import {Card} from 'antd';
import {FaTimes} from 'react-icons/fa';

import {Draft, fetchPopulatedReservationDrafts} from '../api';
import {Reservation} from '../api/reservation/types';

import {DraftReservationCard} from './draft-reservation-card';
import useOnclickOutside from 'react-cool-onclickoutside';

type ReservationSheetProps = {
  reservations: Draft<Record<string, unknown>>[];
  closeSheet: () => void;
  removeReservation: (draftedReservation: Draft<Record<string, unknown>>) => void;
};

export const ReservationSheet: FC<ReservationSheetProps> = ({
  reservations, closeSheet, removeReservation,
}) => {
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [populatedDraftReservations, setPopulatedDraftReservations] =
    useState<Draft<Record<string, unknown>>[]>([]);
  
  const reservationSheetRef = useOnclickOutside(() => closeSheet());

  const removeDraftReservation = (idx: number): void => {
    const newPopulaterdDraftReservations = [...populatedDraftReservations];
    const [deletedReservation] = newPopulaterdDraftReservations.splice(idx, 1);

    removeReservation(deletedReservation);
  };

  useEffect(() => {
    fetchPopulatedReservationDrafts(
      reservations as Draft<Reservation>[],
      (err, populatedReservations) => {
        if (err) {
          console.error(err);
          return;
        }

        setIsFetching(false);

        if (populatedReservations) {
          setPopulatedDraftReservations(populatedReservations);
        }
      },
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  return (
    <div
      ref={reservationSheetRef}
      className={
        "bg-white absolute top-0 bottom-0 right-0 left-16 shadow-md " +
        "sm:left-auto sm:w-80 overflow-y-scroll"
      }
    >
      <div className="h-10 border-b shadow bg-safari-gold text-white flex flex-row justify-between items-center px-4 sm:px-5">
        <h6 className="text-xl font-semibold text-white">
          Finish reservation
        </h6>
        <FaTimes
          className="cursor-pointer"
          size={24}
          onClick={closeSheet}
        />
      </div>
      <div className="px-4">
        {isFetching && (
          [1, 2, 3, 4].map((idx) => (
            <Card
              key={`loading_card_${idx}`}
              loading={true}
            />
          ))
        )}

        {populatedDraftReservations.map((draftReservation, idx) => (
          <div
            key={`${draftReservation.id}`}
            className="my-2"
          >
            <DraftReservationCard
              idx={idx}
              draftReservationId={`${draftReservation.id}`}
              reservation={draftReservation.draft}
              closeSheet={closeSheet}
              removeDraftReservation={removeDraftReservation}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
