import {FC, useContext, useState} from 'react';
import {Formik} from 'formik';
import {RouteComponentProps, useHistory} from 'react-router-dom';
import {FaPlus, FaPlusCircle, FaSave} from 'react-icons/fa';
import {RiBuildingLine} from 'react-icons/ri';

import {
  Coordinates, Facility, FAQ, Feature, Picture, postConference,
  postConferenceDraft, RefundType, ReservationType,
} from '../../../../../api';
import {ConferenceRoom} from '../../../../../api/conference/types';
import {Card} from '../../../../../components';
import {Input, TextArea} from '../../../../../components/input';
import {SnackbarProvider} from '../../../../../context/snackbar-provider';

import {OverlayProvider} from '../../../../search-property/context';

import {
  EditConferenceRoomOverlay,
  EditFacilitiesListItem, EditFeaturesListItem, EditRefundPolicyListItem,
  EditReservationPolicyListItem, PartnerHeader, PickImageListItem,
  PropertyLocationAutoComplete,
} from '../../../components';
import {EditFaqListItem} from '../../../components/edit-faq-list-item';
import {UploadingOverlay} from '../../../components/uploading-overlay';

import {validateConferenceForm} from './validate-form';
import {NewConferenceRoomOverlay} from './new-conference-room-overlay';
import ActivityIndicator from '../../../../../components/loading';
import { ConfirmPropertyLocation } from '../../../components/confirm-property-location';

export const NewConference: FC<RouteComponentProps> = () => {
  const history = useHistory();
  const {
    setIsVisible: setSnackbarVisible, dismissSnackbar,
  } = useContext(SnackbarProvider);
  const {
    setIsCancellable, setIsOverlay, setOnOverlay,
  } = useContext(OverlayProvider);

  const [facilities, setFacilities] = useState<Facility[]>([]);
  const [features, setFeatures] = useState<Feature[]>([]);
  const [faqs, setFaqs] = useState<FAQ[]>([]);
  const [gallery, setGallery] = useState<Picture[]>([]);
  const [coords, setCoords] = useState<Coordinates>({lat: 0, lng: 0});
  const [rooms, setRooms] = useState<ConferenceRoom[]>([]);
  const [refundPolicy, setRefundPolicy] = useState<RefundType>("partial_refund");
  const [reservationPolicy, setReservationPolicy] =
    useState<ReservationType>("full_amount");
  
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  
  const initialValues = {
    title: "",
    location: "",
    description: "",
    short_description: "",
    reservation_policy: "",
    refund_policy: "",
    facilities: "",
    features: "",
    rooms: "",
    faqs: "",
  };

  const addNewConferenceRoom = (newConferenceRoom: ConferenceRoom) => {
    setRooms([...rooms, newConferenceRoom]);
  };

  return (
    <>
    {isUploading && (
      <OverlayProvider.Consumer>
        {({setIsCancellable, setIsOverlay, setOnOverlay}) => (
          <UploadingOverlay
            caption="Uploading conference details"
            setIsCancellable={setIsCancellable}
            setIsOverlay={setIsOverlay}
            setOnOverlay={setOnOverlay}
          />
        )}
      </OverlayProvider.Consumer>
    )}

      <Formik
        initialValues={initialValues}
        validate={(values) => {
          // handle validation
          return validateConferenceForm({
            coords,
            description: values.description,
            gallery,
            location: values.location,
            refund_policy: refundPolicy,
            reservation_policy: reservationPolicy,
            rooms,
            short_description: values.short_description,
            title: values.title,
          });
        }}
        onSubmit={(values) => {
          setIsUploading(true);
            postConference(
              {
                coords,
                description: values.description,
                short_description: values.short_description,
                exceptions: [],
                facilities,
                features,
                faqs,
                gallery,
                location: values.location,
                offer: [],
                policies: [],
                rating: 10,
                refund_policy: refundPolicy,
                reservation_policy: reservationPolicy,
                reviews: [],
                rooms: rooms,
                title: values.title,
                offers: [],
              },
              (err, result) => {
                setIsUploading(false);
                if (err) {
                  console.error(err);
                  setSnackbarVisible && setSnackbarVisible({
                    fabPresent: false,
                    isError: true,
                    navRailPresent: true,
                    title: "Unable to publish conference details",
                  });
  
                  return;
                }
  
                if (result) {
                  setSnackbarVisible && setSnackbarVisible({
                    fabPresent: false,
                    isError: false,
                    navRailPresent: true,
                    title: `Published ${result.title} details`,
                  });

                  history.replace(`/property-owner/new/conference/${result.id}`);
                } else {
                  setSnackbarVisible && setSnackbarVisible({
                    fabPresent: false,
                    isError: true,
                    navRailPresent: true,
                    title: "Unable to publish conference details",
                  });
                }
              },
            );
        }}
      >
        {({
          values, errors, handleChange, handleSubmit, handleBlur,
          setErrors, validateForm,
        }) => (
          <form onSubmit={handleSubmit} onBlur={handleBlur}>
            <PartnerHeader
              title={values.title ? values.title : "New Conferencing Facility"}
              HeaderIcon={RiBuildingLine}
              actions={[
                {
                  ActionIcon: FaSave,
                  alwaysVisible: false,
                  label: "Save Draft",
                  type: "button",
                  disabled: isSaving,
                  onClick: () => {
                    setIsSaving(true);
                    setOnOverlay && setOnOverlay(() => () => (
                      <ActivityIndicator
                        caption=""
                      />
                    ));
                    setIsCancellable && setIsCancellable(false);
                    setIsOverlay && setIsOverlay(true);

                    postConferenceDraft(
                      null,
                      {
                        coords,
                        description: values.description,
                        short_description: values.short_description,
                        exceptions: [],
                        facilities,
                        features,
                        faqs,
                        gallery,
                        location: values.location,
                        offer: [],
                        policies: [],
                        rating: 10,
                        refund_policy: refundPolicy,
                        reservation_policy: reservationPolicy,
                        reviews: [],
                        rooms: rooms,
                        title: values.title,
                        offers: [],
                      },
                      (err, result) => {
                        setIsSaving(false);
                        setOnOverlay && setOnOverlay(null);
                        setIsCancellable && setIsCancellable(true);
                        setIsOverlay && setIsOverlay(false);

                        if (err) {
                          console.error(err);
                          setSnackbarVisible && setSnackbarVisible({
                            fabPresent: false,
                            isError: true,
                            navRailPresent: true,
                            title: "Unable save conference facility details in draft",
                          });
          
                          return;
                        }
          
                        if (result) {
                          setSnackbarVisible && setSnackbarVisible({
                            fabPresent: false,
                            isError: false,
                            navRailPresent: true,
                            title: "Saved facility details in draft",
                            action: {
                              label: "DISMISS",
                              onClick: () => {
                                dismissSnackbar && dismissSnackbar();
                              },
                            },
                          });

                          history.replace("/property-owner/conference");
                        } else {
                          setSnackbarVisible && setSnackbarVisible({
                            fabPresent: false,
                            isError: true,
                            navRailPresent: true,
                            title: "Unable save conference facility details in draft",
                          });
                        }
                      },
                    );
                  },
                },
                {
                  label: "ADD CONFERENCE FACILITY",
                  onClick: () => {
                    console.log("Adding conference Facility");
                  },
                  disabled: Object.keys(errors).length > 0,
                  ActionIcon: FaPlus,
                  alwaysVisible: true,
                  type: "submit",
                }
              ]}
            />

            <div className="p-4 sm:grid sm:grid-cols-2 gap-4 lg:grid-cols-3 lg:grid-flow-col-dense lg:max-w-5xl">
              {/** Input Title */}
              <div>
                <Input
                  inputLabel="Property's name"
                  name="title"
                  value={values.title}
                  error={errors.title}
                  helper="Name of the conferencing facility"
                  id="conferenct-name"
                  onChange={handleChange}
                />
              </div>
              {/** End of input Title */}
              {/** Input Title */}
              <div className="mt-3 sm:mt-0 lg:col-start-1 col-start-2">
                {/** Text input */}
                <PropertyLocationAutoComplete
                  name="location"
                  inputLabel="Conference facility location"
                  onChange={handleChange}
                  selectAddress={(place, coords) => {
                    setIsCancellable && setIsCancellable(false);
                    // setCoords(coords);
                    setOnOverlay && setOnOverlay(() => () => (
                      <ConfirmPropertyLocation
                        initialCoords={coords}
                        onConfirm={(coords) => {
                          setCoords(coords);
                        }}
                        removeOverlay={() => {
                          setIsOverlay && setIsOverlay(false);
                          setIsCancellable && setIsCancellable(true);
                          setOnOverlay(null);
                        }}
                      />
                    ));
                  }}
                  value={values.location}
                  error={errors.location}
                />
                {/** End of text input */}
              </div>
              {/** End of input Title */}
          
              {/** Start of Text Area */}
              <div className="mt-3 lg:col-start-1 lg:col-end-3">
                <TextArea
                  name="description"
                  value={values.description}
                  error={errors.description}
                  helper="Longform description of the conferencing facility"
                  id="conference-description"
                  inputLabel="Conference Facility description (long)"
                  onChange={handleChange}
                />
              </div>
              {/** End of Text Area */}
          
              {/** Start of Text Area */}
              <div className="mt-3 lg:col-start-1 lg:col-end-3">
                <TextArea
                  name="short_description"
                  value={values.short_description}
                  error={errors.short_description}
                  helper="Brief description of the conferencing facility"
                  id="conference-short-description"
                  inputLabel="Conference Facility description (short)"
                  onChange={handleChange}
                />
              </div>
              {/** End of Text Area */}
          
              {/** Reservation policy card */}
              <EditReservationPolicyListItem
                reservationPolicy={reservationPolicy}
                onSave={(reservationPolicy) => {
                  setReservationPolicy(reservationPolicy);
                }}
              />
              {/** End of Reservation policy card */}
          
              {/** Refund policy card */}
              <EditRefundPolicyListItem
                refundPolicy={refundPolicy}
                onSave={(refundPolicy) => setRefundPolicy(refundPolicy)}
              />
              {/** End of Refund policy card */}
          
              {/** Pick images overlay */}
              <PickImageListItem
                gallery={gallery}
                minImageCount={6}
                onOverlaySave={async (gallery) => {
                  setGallery(gallery);
                  setErrors(await validateForm());
                }}
              />
              {/* End of Pick images overlay */}
          
              {/** Things liks prices and sub properties are found here */}
              <hr className="my-4 sm:col-start-1 sm:col-end-3 h-auto lg:hidden" />
              {/** Special Section */}
              <div className="shadow-md border sm:col-start-1 sm:col-end-3">
                {/** Header for special section */}
                <div className="h-14 flex flex-row justify-between items-center px-4 border-b">
                  <div>
                    <h6 className="text-xl font-semibold">Conference Rooms</h6>
                    <p className="text-xs">Add room type</p>
                  </div>

                  {/** Edit Button */}
                  <OverlayProvider.Consumer>
                    {({setIsOverlay, setOnOverlay}) => (
                      <button
                        type="button"
                        className="h-9 px-3 border border-red-500 rounded-md shadow-md text-red-500 font-semibold text-sm flex items-center"
                        onClick={(e) => {
                          e.preventDefault();
                          setOnOverlay && setOnOverlay(() => () => (
                            <NewConferenceRoomOverlay
                              onSave={async (conferenceRoom) => {
                                addNewConferenceRoom(conferenceRoom);
                                setErrors(await validateForm());
                              }}
                              removeOverlay={() => {
                                setIsOverlay && setIsOverlay(false);
                                setOnOverlay && setOnOverlay(null);
                              }}
                            />
                          ));

                          setIsOverlay && setIsOverlay(true);
                        }}
                      >
                        Add conference Room
                      </button>
                    )}
                  </OverlayProvider.Consumer>
                  {/** End of edit Button */}
                </div>

                <div className="p-4 flex flex-row justify-evenly overflow-x-scroll">
                  {/** All Added Sub Properties go here */}
                  {rooms.map((room, idx) => (
                      <Card
                        key={room.title}
                        description={room.description}
                        cover={room.gallery[0].url}
                        subtitle={`${room.price.hour}/hr or ${room.price.day}/day`}
                        title={room.title}
                        action={{
                          label: "Edit Room",
                          onClick: () => {
                            setOnOverlay && setOnOverlay(() => () => (
                              <EditConferenceRoomOverlay
                                conferenceRoom={room}
                                idx={idx}
                                rooms={rooms}
                                removeOverlay={() => {
                                  setIsOverlay && setIsOverlay(false);
                                  setOnOverlay(null);
                                }}
                                setRooms={setRooms}
                              />
                            ));
                            setIsOverlay && setIsOverlay(true);
                          }
                        }}
                      />
                  ))}
                </div>
              </div>
              {/** Special Section */}
          
              <div className="sm:col-span-2 lg:col-start-1 lg:col-end-4">
                <h3 className="text-2xl font-semibold sm:col-start-1 sm:col-end-3">Aditional Information</h3>
                <div className="sm:grid sm:grid-cols-2 sm:gap-4 lg:grid-cols-3">
                  {/** Add FAQs */}
                  <EditFaqListItem
                    error={errors.faqs}
                    faqs={faqs}
                    onSave={async (faqs) => {
                      setFaqs(faqs);
                      setErrors(await validateForm());
                    }}
                  />
                  {/** End of FAQ */}
          
                  {/** Add Facilities */}
                  <EditFacilitiesListItem
                    category="conference"
                    facilities={facilities}
                    onSave={async (newFacilities) => {
                      setFacilities(newFacilities);
                      setErrors(await validateForm());
                    }}
                    error={errors.facilities}
                  />
                  {/** End of Add Facilities */}
          
                  {/** Add Features */}
                  <EditFeaturesListItem
                    category="conference"
                    features={features}
                    onSave={async (newFeatures) => {
                      setFeatures(newFeatures);
                      setErrors(await validateForm());
                    }}
                    error={errors.features}
                  />
                  {/** End of features */}

                  {/** Add Features */}
                  <div className="py-2">
                    <div className="border flex flex-row justify-between shadow-md px-4 py-2 items-center">
                      <div>
                        <h6 className="text-xl font-semibold">Accessibiity Features</h6>
                        <p className="text-xs opacity-70">Press to edit the accessible features in the conference</p>
                      </div>
                      {/* <div className="h-6 w-6 bg-indigo-300"></div> */}
                      <FaPlusCircle size={24} color="#FF5900" />
                    </div>
                    {/* <p className="mt-1 text-xs text-red-500 pl-2">Must have at least one feature</p> */}
                  </div>
                  {/** End of features */}
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};
