/**
 * This component showcases the images linked to a hotel in a column format
 *
 * @returns {JSX.Element}
 */
function App({sharedState, setSharedState}) {
  return (
    <>
      <div
        onClick={() =>
          setSharedState({ galleryModalActive: true })
        }
        className="view-gallery-parent"
      >
        <div
          style={{
            flex: 2,
            border: "1px solid #ccc",
            height: 420,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundColor: "#ccc",
            marginRight: 5,
            backgroundImage: sharedState.picture_galery[0]
              ? `url(${sharedState.picture_galery[0]})`
              : "",
            pointerEvents: (sharedState.galleryModalActive) ?
              "none" :
              "unset", // prevent it being clickable when the overlay is visible
          }}
        />
        {/* <div style={{ flex: 1, padding: 4, paddingTop: 0 }}>
                      <div className="view-gallery-grid-container">
                      </div>
                  </div> */}
        <div
          style={{
            flex: 1,
            padding: 4,
            paddingTop: 0,
            flexDirection: "column",
          }}
          className="mobile-hidden"
        >
          <div
            style={{
              flex: 1,
              padding: 4,
              paddingTop: 0,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", flex: 1, marginBottom: 10 }}>
              <div
                style={{
                  flex: 1,
                  border: "1px solid #ccc",
                  height: 130,
                  backgroundColor: "#ccc",
                  marginRight: 5,
                  backgroundImage: sharedState.picture_galery[1]
                    ? `url(${sharedState.picture_galery[1]})`
                    : "",
                }}
              />
              <div
                style={{
                  flex: 1,
                  border: "1px solid #ccc",
                  height: 130,
                  backgroundColor: "#ccc",
                  marginLeft: 5,
                  backgroundImage: sharedState.picture_galery[2]
                    ? `url(${sharedState.picture_galery[2]})`
                    : "",
                }}
              />
            </div>
            <div style={{ display: "flex", flex: 1, marginBottom: 10 }}>
              <div
                style={{
                  flex: 1,
                  border: "1px solid #ccc",
                  height: 130,
                  backgroundColor: "#ccc",
                  marginRight: 5,
                  backgroundImage: sharedState.picture_galery[3]
                    ? `url(${sharedState.picture_galery[3]})`
                    : "",
                }}
              />
              <div
                style={{
                  flex: 1,
                  border: "1px solid #ccc",
                  height: 130,
                  backgroundColor: "#ccc",
                  marginLeft: 5,
                  backgroundImage: sharedState.picture_galery[4]
                    ? `url(${sharedState.picture_galery[4]})`
                    : "",
                }}
              />
            </div>
            <div style={{ display: "flex", flex: 1 }}>
              <div
                style={{
                  flex: 1,
                  border: "1px solid #ccc",
                  height: 135,
                  backgroundColor: "#ccc",
                  marginRight: 5,
                  backgroundImage: sharedState.picture_galery[5]
                    ? `url(${sharedState.picture_galery[5]})`
                    : "",
                }}
              />
              <div
                style={{
                  flex: 1,
                  border: "1px solid #ccc",
                  height: 135,
                  backgroundColor: "#ccc",
                  marginLeft: 5,
                  backgroundImage: sharedState.picture_galery[6]
                    ? `url(${sharedState.picture_galery[6]})`
                    : "",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}


export default App;
