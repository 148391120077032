import {database, firestore} from "firebase";
import {Draft} from "..";
import {OnCompleteCallback, Property, ReservationCategory} from "../..";
import {PopulatedReservation, Reservation} from "../../reservation/types";
import {getUser, NoUserFoundError} from "../../user";

export const fetchReservationDrafts = async (
  onComplete: OnCompleteCallback<Draft<Record<string, unknown>>[]>,
): Promise<void> => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);

    return;
  }

  try {
    const snapshot = await firestore()
      .collection(`draft/reservation/${user.uid}`)
      .get();
    const {docs} = snapshot;

    const reservationDrafts = docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
    }));

    onComplete(null, reservationDrafts as Draft<Record<string, unknown>>[]);
  } catch (error) {
    console.error(error);

    onComplete(new Error("Unable to fetch reservations"), null);
  }
};

export const fetchReservationDraft = async (
  draftId: string,
  onComplete: OnCompleteCallback<Draft<Record<string, unknown>>>,
): Promise<void> => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);

    return;
  }

  try {
    const docSnapshot = await firestore()
      .collection(`draft/reservation/${user.uid}`)
      .doc(draftId)
      .get();
    const draft = {
      ...docSnapshot.data(), id: docSnapshot.id,
    } as Draft<Record<string, unknown>>;

    // make sure that the user fetching it is the owner of the reservation
    if (draft.uid !== user.uid) {
      onComplete(new Error("Not allowed"), null);
      return;
    }

    onComplete(null, draft);
  } catch (error) {
    console.error(error);

    onComplete(new Error("Unable to fetch reservation"), null);
  }
};

export const fetchPopulatedReservationDrafts = (
  reservationDrafts: Draft<Reservation>[], onComplete: OnCompleteCallback<Draft<PopulatedReservation>[]>,
): void => {
  const populatedReservations: Draft<PopulatedReservation>[] = [];
  let error: Error | null = null;

  const reservations = reservationDrafts.map((reservationDraft) =>
    reservationDraft.draft);

  reservations.map(async (reservation, idx) => {
    try {
      const snapshot = await firestore()
        .collection(reservation.category)
        .doc(reservation.property_id.split(":")[0])
        .get();
      const property = ({id: snapshot.id, ...snapshot.data()} as Property);
      // add the property to make it easier to fetch the property
      if (!reservation.user) {
        populatedReservations.push({
          ...reservationDrafts[idx],
          draft: {...reservation, property},
        } as Draft<PopulatedReservation>);
      } else {
        const userSnapshot =  await database()
          .ref(`Users_Master/${reservation.user}`)
          .once("value");
  
        let username = "Anonymous";
        
        if (userSnapshot.val()) {
          username = userSnapshot.val().fNLName
        }

        populatedReservations.push({
          ...reservationDrafts[idx],
          draft: {...reservation, property, user: username},
        });
      }


      if (idx + 1) {
        // the loop is complete
        console.log(populatedReservations);
        onComplete(null, populatedReservations);
      }
    } catch (err) {
      error = new Error("An error occured populating the reservations"); 
      error && onComplete(error, null);
      !error && onComplete(null, populatedReservations);
    }
  });
};

export const postReservationDraft = async (
  category: ReservationCategory, reservation: Reservation,
  onComplete: OnCompleteCallback<Draft<Record<string, unknown>>>,
): Promise<void> => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);

    return;
  }

  console.log("Making reservation");

  try {
    const docRef = await firestore()
      .collection(`draft/reservation/${user.uid}`)
      .add({category, draft: reservation, uid: user.uid} as Draft<Reservation>);
    const docSnapshot = await docRef.get();

    onComplete(null, ({
      ...docSnapshot.data(), id: docSnapshot.id,
    } as Draft<Record<string, unknown>>));
  } catch (err) {
    console.error(err);
    onComplete(
      new Error("Unable to save draft reservation"), null,
    );
  }
};

export const updateReservationDraft = async (
  reservationDraftId: string, updatedData: Record<string, unknown>,
  onComplete: OnCompleteCallback<Record<string, unknown>>,
): Promise<void> => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);

    return;
  }

  try {
    const docRef = await firestore()
      .collection(`draft/reservation/${user.uid}`)
      .doc(reservationDraftId);
    
    await docRef.update(updatedData);
  
    const docSnapshot = await docRef.get();
    onComplete(null, {...docSnapshot.data(), id: docSnapshot.id});
  } catch (err) {
    console.error(err);
    onComplete(new Error("Unable to update reservation draft"), null);
  }
};

export const deleteDraftReservation = async (
  reservationId: string,
): Promise<boolean> => {
  const user = getUser();

  if (!user) {
    return false;
  }

  try {
    await firestore()
      .collection(`draft/reservation/${user.uid}`)
      .doc(reservationId)
      .delete();

    return true;
  } catch (err) {
    console.error(err);

    return false;
  }
};
