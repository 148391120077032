import {FC, useEffect, useState} from 'react';
import {DatePicker, Select, Switch, InputNumber} from 'antd';

import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import {Input} from '../../../../components/input';
import {Button} from '../../../../components';
import {Activity} from '../../../../api';

import {EditableActivityPriceChange} from './activity-price-change';

type TourPriceChangeProps = {
  offer: EditableActivityPriceChange;
  propertyId: string;
  property: Record<string, unknown>;
  removeOverlay: () => void;
  addToCalendar: (newOffer: EditableActivityPriceChange) => void;
};


export const TourPriceChange: FC<TourPriceChangeProps> = ({
  propertyId, property, offer, removeOverlay, addToCalendar,
}) => {
  const todaysDate =  new Date();
  todaysDate.setHours(0, 0, 0, 0);

  const [startDate, setStartDate] = useState<Date | null>(null);

  const [endDate, setEndDate] = useState<Date | null>(null);

  // used to make sure there can be no double additions
  const hotel = property as Activity;

  const [rate, setRate] = useState<number>(0);
  const [rateEnabled, setRateEnabled] = useState<boolean>(false);

  const [priceType, setPriceType] = useState<"discount" | "hike">("discount");
  const [newPrice, setNewPrice] = useState<{adult: number; children: number}>({adult: 0, children :0});

  useEffect(() => {
    // anytime the rateEnabled state changes, we make sure to reset newPrice
    setNewPrice({adult: 0, children :0});
  }, [rateEnabled]);

  return (
    <>
      <h6 className="text-xl font-semibold">{hotel.title}</h6>
      <div className="p-4 bg-white">
        <div className="flex flex-row justify-between">
          <div>
            <h6 className="text-xs mb-1">
              Start date
            </h6>
            <div className="flex flex-row justify-between">
              <DatePicker
                className="flex-shrink-0 flex-grow-0"
                allowClear={false}
                disabledDate={(date) => {
                  return (
                    Number(date.format("x")) < todaysDate.getTime()
                  );
                }}
                onChange={(date) => {
                  if (date) {
                    setStartDate(date.toDate());
                  }
                }}
              />
            </div>
          </div>

          <div className="">
            <h6 className="text-xs mb-1">
              End date
            </h6>
            <div className="flex flex-row justify-between">
              <DatePicker
                className="flex-shrink-0 flex-grow-0"
                allowClear={false}
                disabledDate={(date) => {
                  if (!startDate) {
                    return (
                      Number(date.format("x")) < todaysDate.getTime()
                    );
                  } else {
                    // we should make sure that the date is more later that the
                    // start date
                    return (
                      Number(date.format("x")) < startDate.getTime()
                    );
                  }
                }}
                onChange={(date) => {
                  if (date) {
                    setEndDate(date.toDate());
                  }
                }}
              />
            </div>
          </div>
        </div>

        <Input
          className="mt-3"
          disabled={true}
          helper="Normal adult price"
          id="no-of-rooms"
          inputLabel="Standard room price"
          name="no-of-rooms"
          value={`$ ${hotel.price.adult}`}
          onChange={(e) => {
            console.log(Number(e.target.value));
          }}
        />

        <Input
          className="mt-3"
          disabled={true}
          helper="Normal child price"
          id="no-of-rooms"
          inputLabel="Standard room price"
          name="no-of-rooms"
          value={`$ ${hotel.price.children}`}
          onChange={(e) => {
            console.log(Number(e.target.value));
          }}
        />

        <p className="text-xs mt-3 mb-1">
          Select price change type
        </p>
        <Select
          className="w-full"
          defaultValue="discount"
          onChange={(val) => {
            setPriceType(val);
          }}
        >
          <Select.Option value="hike">Hike price</Select.Option>
          <Select.Option value="discount">Discount price</Select.Option>
        </Select>

        <div className="mt-3">
          <p className="text-xs">Use rate calculator</p>
          <div className="flex flex-row items-center">
            <div className="h-auto">
              <Switch
                checked={rateEnabled}
                defaultChecked={false}
                onChange={(val) => {
                  setRateEnabled(val)
                }}
              />
            </div>

            <div className="ml-3">
              <div>
                <InputNumber
                  className="w-full flex-grow"
                  disabled={!rateEnabled}
                  defaultValue={rate}
                  value={rate}
                  onChange={(val) => {
                    const newerPrice = hotel.price;

                    if (priceType === "discount") {
                      newerPrice.adult *= ((100 - val) / 100);
                      newerPrice.children *= ((100 - val) / 100);
                    } else {
                      newerPrice.adult *= ((100 + val) / 100);
                      newerPrice.children *= ((100 + val) / 100);
                    }
                    setRate(val);
                    setNewPrice(newerPrice);
                  }}
                />
              </div>
            </div>

          </div>
        </div>

        <p className="text-xs mt-3 mb-1">New adult price</p>
        <InputNumber
          className="w-full"
          disabled={rateEnabled}
          defaultValue={newPrice.adult}
          value={newPrice.adult}
          onChange={(val) => {
            const newerPrice = {...newPrice};
            newerPrice.adult = val;
            setNewPrice(newerPrice);
          }}
        />

        <p className="text-xs mt-3 mb-1">New child price</p>
        <InputNumber
          className="w-full"
          disabled={rateEnabled}
          defaultValue={newPrice.children}
          value={newPrice.children}
          onChange={(val) => {
            const newerPrice = {...newPrice};
            newerPrice.children = val;
            setNewPrice(newerPrice);
          }}
        />

        <div className="flex flex-row pt-3 items-center justify-center">
          <Button
            disabled={
              startDate === null || endDate === null ||
                newPrice.adult === 0 || newPrice.children === 0
            }
            type="button"
            onClick={() => {
              // just as an insurance, mae an early exit if any of the variables
              // is null
              if (
                !startDate || !endDate || newPrice.adult === 0 ||
                  newPrice.children === 0
              ) {
                return;
              }
              // we post the reservation, and then we remove the overlay
              startDate.setHours(0, 0, 0, 0);
              endDate.setHours(23, 59, 59, 999);

              addToCalendar({
                ...offer,
                endDate: endDate.getTime(),
                newPrice,
                property_id: `${hotel.id}`,
                startDate: startDate.getTime(),
                type: priceType,
              });

              removeOverlay();
            }}
          >
            Update on calendar
          </Button>
        </div>
      </div>
    </>
  );
};
