import {FC, useContext, useEffect, useState} from 'react';
import {auth} from 'firebase';
import { FaPlus } from 'react-icons/fa';
import {useHistory} from 'react-router';

import {
  fetchCategoryReservations, fetchDrafts, fetchPopulatedReservations, Property, ReservationCategory,
} from '../../../api';
import { PopulatedReservation} from '../../../api/reservation/types';
import ActivityIndicator from '../../../components/loading';
import {Card, CardOverflowAction} from '../../../components/card/property-card';

import {OverlayProvider} from '../../search-property/context';

import {DraftDialog} from './draft-dialog';
import {ExternalReservationOverlay} from './external-reservation-overlay';
import { Button } from '../../../components';
import { ReservationOverlay } from '.';

type PropertiesSummaryProps = {
  category: ReservationCategory;
  properties: Property[];
};

export const PropertiesSummary: FC<PropertiesSummaryProps> = ({
  category, properties,
}) => {
  const history = useHistory();

  const {
    setIsCancellable, setIsOverlay, setOnOverlay,
  } = useContext(OverlayProvider);

  const [coverImage, setCoverImage] = useState<string>("");
  const [categoryReservations, setCategoryReservations] =
    useState<PopulatedReservation[]>([]);
  const [reservationsRevenue, setReservationRevenue] = useState<number>(0);

  const importImage = async () => {
    let coverIcon: string;
  
    switch (category) {
      case "activity":
        coverIcon = (await import('../../../assets/service_activity.png')).default;
        break;
      case "car":
        coverIcon = (await import('../../../assets/service_car.png')).default;
        break;
      case "conference":
        coverIcon = (await import('../../../assets/service_conferencing.png')).default;
        break;
      case "eatery":
        coverIcon = (await import('../../../assets/service_restaurant.png')).default;
        break;
      case "hotel":
        coverIcon = (await import('../../../assets/service_hotel.png')).default;
        break;
      case "rental":
        coverIcon = (await import('../../../assets/service_rental.png')).default;
        break;
      case "tour":
        coverIcon = (await import('../../../assets/service_tour.png')).default;
        break;
      default:
        coverIcon = (await import('../../../assets/service_hotel.png')).default;
        break;
    }

    setCoverImage(coverIcon)
  };

  // import images on cdm
  useEffect(() => {
    importImage();
    const user = auth().currentUser;

    if (user) {
      const [, category] = location.pathname
        .split("/")
        .filter((substr) => substr !== "");
      // TODO: Refactor to a global
      fetchCategoryReservations(user.uid, category as ReservationCategory, (err, res) => {
        if (err) {
          console.error(err);

          return;
        }

        if (res) {
          let totalRevenue = 0;

          res.forEach((reservation) => {
            // only counts it as revenue if the stay is completed
            if (reservation.status === "complete") {
              totalRevenue += reservation.expected_amount;
            }
          });
          setReservationRevenue(totalRevenue);

          fetchPopulatedReservations(res, (err, populatedReservations) => {
            if (err) {
              console.log(err);

              return;
            }

            if (populatedReservations) {
              setCategoryReservations(populatedReservations);
            }
          });
        }
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const addNewProperty = () => {
    const [, category] = location.pathname
      .split("/")
      .filter((substr) => substr !== "");

    setIsCancellable && setIsCancellable(false);
    setOnOverlay && setOnOverlay(() => () => (
      <ActivityIndicator
        caption=""
      />
    ))

    setIsOverlay && setIsOverlay(true);

    // look for past drafts
    fetchDrafts(category, (err, res) => {
      if (err) {
        history.push(`/property-owner/new/${category}`);

        return;
      }

      if (res) {
        if (res.length > 0) {
          setIsOverlay && setIsOverlay(false);
          setOnOverlay && setOnOverlay(() => () => (
            <DraftDialog
              category={category as ReservationCategory}
              drafts={res}
              removeOverlay={() => {
                setOnOverlay(null);

                setIsOverlay && setIsOverlay(false);
              }}
              setIsCancellable={setIsCancellable}
              setIsOverlay={setIsOverlay}
              setOnOverlay={setOnOverlay}
            />
          ));
        
          setIsOverlay && setIsOverlay(true);
          return;
        }
      }

      setIsCancellable && setIsCancellable(true);
      setOnOverlay && setOnOverlay(null);
      setIsOverlay && setIsOverlay(false);

      history.push(`/property-owner/new/${category}`);
    });
  };

  return (
    <div className="lg:flex lg:max-w-5xl">

      {/** Summary Column */}
      <div className="p-4 min-w-max">
        <div className="w-full flex flex-row justify-center">
          <img
            className="h-36 w-36"
            src={coverImage}
            alt="Cover Icon"
          />
        </div>
        <h2 className="text-xl font-semibold pt-2">
          Summary
        </h2>
        <p className="text-xs">Summary of all your {category} properties</p>
        <hr className="my-2" />
        {/** Contains the summary cards */}

        {/** Booking Summary Card */}
        <div className=" self-stretch pt-4">
          <div className="w-full mx-2 shadow-sm border p-4 rounded-md">
            <h6 className="text-base font-semibold">Booking Summary</h6>
            <p className="text-xs opacity-70">
              Summary of all bookings this month
            </p>
            <hr className="my-2" />
            <div className="grid grid-cols-2 border-b ">
              <h4
                className="text-sm font-semibold pr-2 pb-2 my-auto"
              >
                Booked
              </h4>
              <h4 className="text-sm ml-4 font-semibold place-self-center">
                {/*
                  Gets the count of only the reservations that are either
                  "pending" or "stayed"
                */}
                {categoryReservations.filter(
                  (reservation) => (
                    reservation.status === "pending" ||
                      reservation.status === "complete"
                  )).length
                }
              </h4>
            </div>
            <div className="grid grid-cols-2 border-b">
              <h4 className="text-sm font-semibold pr-2 pb-2 my-auto">
                Cancelled
              </h4>
              <h4 className="text-sm ml-4 font-semibold place-self-center">
                {categoryReservations.filter(
                  (reservation) => (
                    reservation.status === "cancelled"
                  )).length
                }
              </h4>
            </div>
          </div>
        {/**End of Booking Summary Card */}

          {/** Revenue summary card */}
          <div className="w-full mx-2 shadow-sm border p-4 rounded-md mt-4">
            <h6 className="text-base font-semibold">Revenue Summary</h6>
            <p className="text-xs opacity-70">
              Summary of all revenue this month
            </p>
            <hr className="my-2" />
            <div className="grid grid-cols-2 border-b">
              <h4
                className="text-sm font-semibold pr-2 pb-2 my-auto"
              >
                Reservations
              </h4>
              <h4 className="text-sm ml-4 font-semibold place-self-center">
                {`$ ${reservationsRevenue}`}
              </h4>
            </div>
            <div className="grid grid-cols-2 border-b">
              <h4
                className="text-sm font-semibold pr-2 pb-2 my-auto "
              >
                Cancelled
              </h4>
              <h4
                className="text-sm ml-4 font-semibold place-self-center"
              >
                {/*
                  This will be changed when the metric for calculating refunds
                  is updated
                */}
                $ 0
              </h4>
            </div>
            <div className="grid grid-cols-2 border-b">
              <h4
                className="text-sm font-semibold pr-2 pb-2 my-auto "
              >
                Stays
              </h4>
              <h4
                className="text-sm ml-4 font-semibold place-self-center"
              >
                {`$ ${reservationsRevenue}`}
              </h4>
            </div>

            <div className="flex flex-row justify-end pt-3">
              <Button
                type="button"
                onClick={() => {
                  setOnOverlay && setOnOverlay(() => () => (
                    <ReservationOverlay
                      reservations={categoryReservations}
                      removeOverlay={() => {
                        setIsOverlay && setIsOverlay(false);
                        setOnOverlay(null);
                      }}
                    />
                  ));
                }}
              >
                View all reservations
              </Button>
            </div>
          </div>
          {/** End of revenue summary card */}

          {/** Review Summary Card */}
          <div className="w-full mx-2 shadow-sm border p-4 rounded-md mt-4">
            <h6 className="text-base font-semibold">Review Summary</h6>
            <p className="text-xs opacity-70">
              Summary of all reviews this month
            </p>
            <hr className="my-2" />

            {properties.map((property) => (
              <div key={property.id} className="grid grid-cols-2 my-2 border-b">
                <h4
                  className={
                    "text-sm font-semibold pr-2 pb-2 my-auto " +
                    "overflow-hidden whitespace-nowrap overflow-ellipsis"
                  }
                >
                  {property.title}
                </h4>
                <div className="grid grid-cols-3">
                  <div className="flex flex-col items-center ">
                    <h6 className="text-base font-semibold">
                      {property.reviews.filter(
                        (review) => review.rating >= 7).length}
                    </h6>
                    <p className="text-xs">7+</p>
                  </div>
                  <div className="flex flex-col items-center ">
                    <h6 className="text-base font-semibold">
                      {property.reviews.filter(
                        (review) => review.rating < 7 && review.rating > 3
                      ).length}
                    </h6>
                    <p className="text-xs">4 - 6</p>
                  </div>
                  <div className="flex flex-col items-center">
                    <h6 className="text-base font-semibold">
                      {property.reviews.filter(
                        (review) => review.rating < 4).length}
                    </h6>
                    <p className="text-xs">1 - 3</p>
                  </div>
                </div>
              </div>
            ))}

          </div>
          {/** End of review Summary card */}
        </div>
      </div>
      {/** End of Summary Column */}

      {/** Property Column */}
      <div className="px-2 lg:pt-4 lg:flex-grow">
        <h2 className="text-xl font-semibold border-black">
          Properties
        </h2>
        <p className="text-xs">List of all your properties</p>
        <hr className="my-2" />

        {/** Start of Property cards container */}
        <div className="sm:flex sm:flex-row sm:flex-wrap sm:justify-evenly">

          {properties.map((property) => {
            const overflowActions: CardOverflowAction[] = [];
            // in the interest of still keeping this re-usable, we check for
            // categories that have bookable sub properties here, this is where
            // is a property is either a hotel or a confernce, we do not add the
            // overflow action of amking property available here.
            // This is because reservations are only made on subproperties (i.e.
            // the rooms are bookable and not the property itself)
            // The other rooms have theoverflow actions set to make the property
            // unavailable after reservation.

            if (category !== "hotel" && category !== "conference") {
              overflowActions.push(
                {
                  label: "Make property unavailable",
                  onClick: () => {
                    // start the external reservation process here
                    setOnOverlay && setOnOverlay(() => () => (
                      <ExternalReservationOverlay
                        category={category}
                        cover={property.gallery[0]}
                        propertyId={`${property.id}`}
                        title={property.title}
                        removeOverlay={() => {
                          setIsOverlay && setIsOverlay(false);
                          setOnOverlay(null);
                        }}
                      />
                    ));

                    setIsOverlay && setIsOverlay(true);
                  },
                },
               );
            }

            if (category !== "car") {
              overflowActions.push({
                label: "Manage seasonal price change",
                onClick: () => {
                  history.push(
                    `/property-owner/price-manager/${category}/${property.id}`,
                  );
                },
              });
            }

            return (
              <Card
                key={property.id}
                cover={property.gallery[0].url}
                description={property.description}
                subtitle={property.location}
                title={property.title}
                action={{
                  label: `View ${category}`,
                  onClick: () => {
                    const pathname = `/property-owner/view/${category}/${property.id}`;
                    // navigate to the specific property
                    history.push(pathname);
                  },
                }}
                overflowActions={overflowActions}
              />
            )
          })}

        </div>
      </div>
      {/** End of property column */}

      <div
        className={
          "fixed bottom-4 right-4 h-12 rounded-full bg-secondary " +
          "text-white flex flex-row items-center pl-4 shadow-xl " +
          "cursor-pointer"
        }
        onClick={addNewProperty}
      >
        <FaPlus size={24} />
        <h6 className="pl-3 pr-5 font-semibold">
          NEW PROPERTY
        </h6>
      </div>
    </div>
  );
};
