import {Dispatch, FC, SetStateAction, useEffect} from 'react';
import {useState} from 'react';
import {IoIosBed} from 'react-icons/io';
import {useHistory} from 'react-router';

import {
  Facility, Feature, filterHotels, Hotel as HotelProperty, Property,
} from '../../../../api';
import { hotelFacilities, hotelFeatures } from '../../../../api/features';
import {SearchPropertyCard} from '../../../../components/search-property-card';
import {fetchCompulsoryFilters} from '../../../../reducers/search/hotel-filters';
import {DateUtil, DistanceUtil} from '../../../../util';
import {calculateHotelPrice} from '../../../../util/price-calculator';
import {rankFilters} from '../../../../util/rank-filters';

import {ContainerTwo} from '../../components/container-two';
import {FilterableProperty, FilterContext} from '../../context';
import {filter} from '../../util';

export const Hotel: FC = () => {
  const history = useHistory();
  const [results, setResults] = useState<FilterableProperty<HotelProperty>[]>([]);

  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [hotelResults, setHotelResults] = useState<HotelProperty[]>([])
  const [viewableResults, setViewableResults] = useState<HotelProperty[]>([]);
  
  const [filterResultsStale, setFilterResultsStale] = useState<boolean>(false);
  const [rawResultsStale, setRawResultsStale] = useState<boolean>(false);

  const [activeFacilities, setActiveFacilities] = useState<Facility[]>([]);
  const [activeFeatures, setActiveFeatures] = useState<Feature[]>([]);

  const addFacility = (facility: Facility) => {
    const newFacilities = [...activeFacilities, facility];

    setActiveFacilities(newFacilities);
  };

  const removeFacility = (facility: Facility) => {
    const newFacilities = [...activeFacilities];
    let facilityIdx = -1;

    // find the facility
    for (let i=0; i<newFacilities.length; i++) {
      if (newFacilities[i].title === facility.title) {
        facilityIdx = i;
        break;
      }
    }

    if (facilityIdx > -1) {
      newFacilities.splice(facilityIdx, 1);
      setActiveFacilities(newFacilities);
    }
  };

  const today = new Date();

  const compulsoryFilters = fetchCompulsoryFilters();
  const place = compulsoryFilters.place ? compulsoryFilters.place : "";
  today.setDate(today.setDate(today.getDate() + 1));
  const checkin = (compulsoryFilters.checkin) ?
    compulsoryFilters.checkin :
    DateUtil.changeDateFormatFromDate(today, "DD-MM-YYYY");
  today.setDate(today.setDate(today.getDate() + 1));
  const checkout = (compulsoryFilters.checkout) ?
    compulsoryFilters.checkout :
    DateUtil.changeDateFormatFromDate(today, "DD-MM-YYYY");

  const coords = {
    lat: compulsoryFilters.lat ? compulsoryFilters.lat: 0,
    lng: compulsoryFilters.lng ? compulsoryFilters.lng: 0,
  };

  useEffect(() => {
    const coords = {
      lat: compulsoryFilters.lat ? compulsoryFilters.lat: 0,
      lng: compulsoryFilters.lng ? compulsoryFilters.lng: 0,
    };
    const checkinDate = new Date(
      DateUtil.changeDateFormat(checkin, "DD-MM-YYYY", "MM-DD-YYYY")
    );

    checkinDate.setHours(10, 0, 0, 0);
    filterHotels(place, coords, checkinDate.getTime(), (err, hotels) => {
      if (err) {
        console.error(err);

        return;
      }

      if (hotels) {
        setIsFetching(false);
        setHotelResults(hotels);
        setViewableResults(hotels);
        const hotelFilters: FilterableProperty<HotelProperty>[] = hotels.map((hotel) => ({
          ...hotel,
          price:
            calculateHotelPrice(
              hotel.rooms[0].price, 1, checkin, checkout
            ),
        }));
        // set all the results
        setResults && setResults(hotelFilters);
      }
    });
  }, [rawResultsStale]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const newResults = rankFilters<HotelProperty>(
      results,
      {
        active: activeFacilities.map((facility) => facility.title),
        get: (hotel) => hotel.facilities.map((facility) => facility.title),
      },
      {
        active: activeFeatures.map((feature) => feature.title),
        get: (property) => property.features.map((feature) => feature.title),
      },
    );

    setViewableResults(newResults);
    setFilterResultsStale(false);
  }, [filterResultsStale]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FilterContext.Provider
      value={{
        filterResultsStale,
        rawResults: hotelResults,
        rawResultsStale,
        setFilterResultsStale,
        setRawResultsStale,
        results,
        setResults: setHotelResults as Dispatch<SetStateAction<Property[]>>,
        setViewableResults: setViewableResults as (results: Property[]) => void,
        addFacility,
        removeFacility,
      }}
    >
      <ContainerTwo<HotelProperty>
        HeaderIcon={IoIosBed}
        columnData={[
          {
            title: "Hotel Facilities",
            data: hotelFacilities,
            onChange: (activeFacilities) => {
              setActiveFacilities(filter(activeFacilities, hotelFacilities));
              setFilterResultsStale(true);
            },
          },
          {
            title: "Hotel Features",
            data: hotelFeatures,
            onChange: (activeFeatures) => {
              setActiveFeatures(filter(activeFeatures, hotelFeatures));
              console.log(filter(activeFeatures, hotelFeatures));
              console.log("Setting filter results stale")
              setFilterResultsStale(true);
            },
          },
        ]}
        filterOptions={[
          {
            idx: 0,
            onClick: () => console.log("Selecting top solo filters"),
            title: "Top solo picks",
            onFilter: (results) => results,
          },
          {
            idx: 1,
            onClick: () => console.log("Selecting Stars (Highest first)"),
            title: "Stars (Highest first)",
            onFilter: (results) => {
              results.sort((result, nextResult) => {
                return nextResult.rating - result.rating;
              });

              return results;
            },
          },
          {
            idx: 2,
            onClick: () => console.log("Selecting stars (Lowest First)"),
            title: "Stars (Lowest first)",
            onFilter: (results) => {
              results.sort((result, nextResult) => {
                return result.rating - nextResult.rating;
              });

              return results;
            },
          },
          {
            idx: 3,
            onClick: () => console.log("Selecting by Price (Highest)"),
            title: "Price (Highest)",
            onFilter: (results) => {
              (results as unknown as HotelProperty[]).sort((result, nextResult) => {
                return nextResult.rooms[0].price - result.rooms[0].price;
              });

              return results;
            },
          },
          {
            idx: 4,
            onClick: () => console.log("Select by price (lowest)"),
            title: "Price (Lowest)",
            onFilter: (results) => {
              (results as unknown as HotelProperty[]).sort((result, nextResult) => {
                return result.rooms[0].price - nextResult.rooms[0].price;
              });

              return results;
            },
          },
          {
            idx: 5,
            onClick: () => console.log("Selecting top Reviewed"),
            title: "Top Reviewed",
            onFilter: (results) => {
              results.sort((result, nextResult) => {
                return nextResult.reviews.length - result.reviews.length;
              });

              return results;
            },
          }
        ]}
        headerTitle={`Found ${hotelResults.length} Results in: ${place}`}
        loading={isFetching}
        results={viewableResults}
      >
        {(viewableResults) => viewableResults.map((hotel, idx) => (
          <SearchPropertyCard
            key={hotel.id}
            action={{
              label: "View Availability",
              onClick: () => {
                history.push(`/property/hotel/${hotel.id}?${
                  new URL(location.href).searchParams.toString()
                }`);
              },
            }}
            comments={hotel.reviews}
            cover={hotel.gallery[0].url}
            description={hotel.description}
            duration={"2 days"}
            idx={idx}
            item={hotel}
            location={hotel.location}
            origin="search"
            price={hotel.rooms[0].price}
            style={{}}
            rating={hotel.rating}
            title={hotel.title}
            subtitle={
              `${
                DistanceUtil.haversineDistance("km", coords, hotel.coords)
                  .toFixed(2)
              } KM from ${compulsoryFilters.place}`
            }
          />
        ))}
      </ContainerTwo>
    </FilterContext.Provider>
  );
};
