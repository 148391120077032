// @ts-check
////////////////////////////////////////////////////////////////////////////////
// JSDoc Types
////////////////////////////////////////////////////////////////////////////////

/**
 * @typedef {import('.').Review} Review
 *
 * @typedef {{
 * title: string,
 * price: number,
 * }} EateryItem
 *
 * @typedef {{
 * title: string,
 * description: string,
 * long_description: string,
 * location: string,
 * coords: {lat: number, lng: number},
 * email: string,
 * phone_number: string,
 * menu: EateryItem[],
 * gallery: string[],
 * review: Review[],
 * rating: 0 | 1 | 2 | 3 | 4 | 5,
 * }} Eatery
 *
 */

/**@type {Eatery[]} */
export const eateries = [
  {
    title: "Chicken inn",
    location: "Moi Avenue, Nairobi",
    coords: {lat: 0, lng: 0},
    description: "Integer eleifend ante ut nunc condimentum, sed bibendum " +
      "magna feugiat. Vivamus magna nulla, scelerisque ut nisl vitae, tempor " +
      " tempus urna. Phasellus condimentum tempus ultrices. Ut sed lectus" +
      " rhoncus, laoreet leo.",
    long_description: "Lorem ipsum dolor sit amet, consectetur adipiscing" +
      " elit. Praesent molestie dui purus, in blandit eros euismod in. Fusce" +
      " faucibus felis ante, eget porttitor diam congue quis. Sed posuere" +
      " eget nisi at viverra. Aliquam non sem eu nulla finibus suscipit nec" +
      " et libero. Orci varius natoque penatibus et magnis dis parturient" +
      " montes, nascetur ridiculus mus. Curabitur sed eros pharetra, aliquet" +
      " mi at, porta dui. Mauris eget viverra neque, vitae placerat magna",
    email: "support@chicken-inn.com",
    phone_number: "0733679329",
    menu: [],
    gallery: [],
    review: [],
    rating: 4,
  },
  {
    title: "Gallitos",
    location: "Jivanjee, Nairobi",
    coords: {lat: 0, lng: 0},
    description: "Integer eleifend ante ut nunc condimentum, sed bibendum " +
      "magna feugiat. Vivamus magna nulla, scelerisque ut nisl vitae, tempor " +
      " tempus urna. Phasellus condimentum tempus ultrices. Ut sed lectus" +
      " rhoncus, laoreet leo.",
    long_description: "Lorem ipsum dolor sit amet, consectetur adipiscing" +
      " elit. Praesent molestie dui purus, in blandit eros euismod in. Fusce" +
      " faucibus felis ante, eget porttitor diam congue quis. Sed posuere" +
      " eget nisi at viverra. Aliquam non sem eu nulla finibus suscipit nec" +
      " et libero. Orci varius natoque penatibus et magnis dis parturient" +
      " montes, nascetur ridiculus mus. Curabitur sed eros pharetra, aliquet" +
      " mi at, porta dui. Mauris eget viverra neque, vitae placerat magna",
    email: "support@galitos.com",
    phone_number: "0786857954",
    menu: [],
    gallery: [],
    review: [],
    rating: 4,
  },
  {
    title: "Debonairs",
    location: "University Way, Nairobi",
    coords: {lat: 0, lng: 0},
    description: "Integer eleifend ante ut nunc condimentum, sed bibendum " +
      "magna feugiat. Vivamus magna nulla, scelerisque ut nisl vitae, tempor " +
      " tempus urna. Phasellus condimentum tempus ultrices. Ut sed lectus" +
      " rhoncus, laoreet leo.",
    long_description: "Lorem ipsum dolor sit amet, consectetur adipiscing" +
      " elit. Praesent molestie dui purus, in blandit eros euismod in. Fusce" +
      " faucibus felis ante, eget porttitor diam congue quis. Sed posuere" +
      " eget nisi at viverra. Aliquam non sem eu nulla finibus suscipit nec" +
      " et libero. Orci varius natoque penatibus et magnis dis parturient" +
      " montes, nascetur ridiculus mus. Curabitur sed eros pharetra, aliquet" +
      " mi at, porta dui. Mauris eget viverra neque, vitae placerat magna",
    email: "debo@debo.com",
    phone_number: "0779305916",
    menu: [],
    gallery: [],
    review: [],
    rating: 5,
  },
  {
    title: "CJs",
    location: "Kimathi Street, Nairobi",
    coords: {lat: 0, lng: 0},
    description: "Integer eleifend ante ut nunc condimentum, sed bibendum " +
      "magna feugiat. Vivamus magna nulla, scelerisque ut nisl vitae, tempor " +
      " tempus urna. Phasellus condimentum tempus ultrices. Ut sed lectus" +
      " rhoncus, laoreet leo.",
    long_description: "Lorem ipsum dolor sit amet, consectetur adipiscing" +
      " elit. Praesent molestie dui purus, in blandit eros euismod in. Fusce" +
      " faucibus felis ante, eget porttitor diam congue quis. Sed posuere" +
      " eget nisi at viverra. Aliquam non sem eu nulla finibus suscipit nec" +
      " et libero. Orci varius natoque penatibus et magnis dis parturient" +
      " montes, nascetur ridiculus mus. Curabitur sed eros pharetra, aliquet" +
      " mi at, porta dui. Mauris eget viverra neque, vitae placerat magna",
    email: "cjs@cjs.com",
    phone_number: "0745857954",
    menu: [],
    gallery: [],
    review: [],
    rating: 3,
  },
  {
    title: "Manhattan",
    location: "Moi Avenue, Nairobi",
    coords: {lat: 0, lng: 0},
    description: "Integer eleifend ante ut nunc condimentum, sed bibendum " +
      "magna feugiat. Vivamus magna nulla, scelerisque ut nisl vitae, tempor " +
      " tempus urna. Phasellus condimentum tempus ultrices. Ut sed lectus" +
      " rhoncus, laoreet leo.",
    long_description: "Lorem ipsum dolor sit amet, consectetur adipiscing" +
      " elit. Praesent molestie dui purus, in blandit eros euismod in. Fusce" +
      " faucibus felis ante, eget porttitor diam congue quis. Sed posuere" +
      " eget nisi at viverra. Aliquam non sem eu nulla finibus suscipit nec" +
      " et libero. Orci varius natoque penatibus et magnis dis parturient" +
      " montes, nascetur ridiculus mus. Curabitur sed eros pharetra, aliquet" +
      " mi at, porta dui. Mauris eget viverra neque, vitae placerat magna",
    email: "manhattan@manhattan.com",
    phone_number: "0799857954",
    menu: [],
    gallery: [],
    review: [],
    rating: 4,
  },
];
