import {Activity, Conference, Eatery, Hotel, Rental, Tour} from "../api";
import {PriceChange} from "../api/hotel/Offer";

type PriceChangeTypes = {
  activity: PriceChange<{adult: number; children: number}>;
  car: PriceChange;
  conference: PriceChange<{day: number; hour: number}[]>;
  cruise: PriceChange;
  eatery: PriceChange<number[]>;
  flight: PriceChange;
  hotel: PriceChange<number[]>;
  rental:  PriceChange;
  tour: PriceChange<{adult: number; children: number}>;
};

const offerGetter = {
  activity: (offer: Record<string, unknown>) => {
    return (offer as PriceChangeTypes["activity"]).newPrice.adult;
  },
  car: (offer: Record<string, unknown>) => {
    return (offer as PriceChangeTypes["car"]).newPrice;
  },
  conference: (offer: Record<string, unknown>) => {
    return (offer as PriceChangeTypes["conference"]).newPrice[0].day;
  },
  cruise: () => {
    return 0;
  },
  eatery: (offer: Record<string, unknown>) => {
    return (offer as PriceChangeTypes["eatery"]).newPrice[0];
  },
  flight: () => {
    return 0;
  },
  hotel: (offer: Record<string, unknown>) => {
    return (offer as PriceChangeTypes["hotel"]).newPrice[0];
  },
  rental: (offer: Record<string, unknown>) => {
    return (offer as PriceChangeTypes["rental"]).newPrice;
  },
  tour: (offer: Record<string, unknown>) => {
    return (offer as PriceChangeTypes["tour"]).newPrice.adult;
  },
}

export const getOfferPrice = <T extends keyof PriceChangeTypes>(
  category: keyof PriceChangeTypes, offer: PriceChangeTypes[T],
): number => {
  return offerGetter[category](offer);
};

const priceGetter = {
  activity: (property: Record<string, unknown>) => {
    return (property as Activity).price.adult;
  },
  car: (price: Record<string, unknown>) => {
    return 35;
  },
  conference: (property: Record<string, unknown>) => {
    return (property as Conference).rooms[0].price.day;
  },
  cruise: () => {
    return 0;
  },
  eatery: (property: Record<string, unknown>) => {
    return (property as Eatery).table[0].price;
  },
  flight: () => {
    return 0;
  },
  hotel: (property: Record<string, unknown>) => {
    return (property as Hotel).rooms[0].price;
  },
  rental: (property: Record<string, unknown>) => {
    return (property as Rental).price;
  },
  tour: (property: Record<string, unknown>) => {
    return (property as Tour).price.adult;
  },
}

export const getPropertyPrice = (
  category: keyof typeof priceGetter, property: Record<string, unknown>,
): number => {
  return priceGetter[category](property);
};
