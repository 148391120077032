import type {FC} from 'react';
import { getReservedPropertyTitle, ReservationRow } from '..';

import {PopulatedHotelReservation} from '../../../../../api/reservation/types';
import {DateUtil} from '../../../../../util';

type HotelReservationRowsProps = {
  reservation: PopulatedHotelReservation;
};

export const HotelReservationRows: FC<HotelReservationRowsProps> = ({
  reservation,
}) => {
  return (
    <>
      <ReservationRow
        title="Checkin"
        description={
          DateUtil.changeDateFormatFromDate(
            new Date(reservation.checkin), "DD-MM-YYYY",
          )
        }
      />

      <ReservationRow
        title="Checkout"
        description={
          DateUtil.changeDateFormatFromDate(
            new Date(reservation.checkout), "DD-MM-YYYY",
          )
        }
      />

      <ReservationRow
        title="Adults"
        description={`${reservation.adults}`}
      />

      <ReservationRow
        title="Children"
        description={`${reservation.children}`}
      />

      <ReservationRow
        title="Room type"
        description={getReservedPropertyTitle("hotel", reservation)}
      />


      <ReservationRow
        title="Number of Rooms"
        description={`${reservation.number_of_rooms}`}
      />
    </>
  );
};
