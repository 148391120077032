import type {FC} from 'react';
import {Route, RouteComponentProps, Switch} from "react-router";
import { ActivityView } from './activity-view';
import { CarView } from './car-view';
import { ConferenceView } from './conference-view';

import {HotelView} from './hotel-view';
import {RentalView} from './rental-view';
import { TourView } from './tour-view';

export const PartnerModuleView: FC<RouteComponentProps> = ({match}) => {
  return (
    <Switch>
      <Route path={`${match.path}/hotel/:id`} component={HotelView} />
      <Route path={`${match.path}/rental/:id`} component={RentalView} />
      {/* <Route path={`${match.path}/eatery/:id`} component={EateryView} /> */}
      <Route path={`${match.path}/conference/:id`} component={ConferenceView} />
      <Route path={`${match.path}/car/:id`} component={CarView} />
      <Route path={`${match.path}/tour/:id`} component={TourView} />
      <Route path={`${match.path}/activity/:id`} component={ActivityView} />
    </Switch>
  );
};
