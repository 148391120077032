import {FC} from "react";
import {ListChildComponentProps} from "react-window";
import {
  MapPropertyCard, MapPropertyCardProps,
} from "../../components/map-property-card";

export const MapListItem: FC<ListChildComponentProps<MapPropertyCardProps[]>> = ({
  data, index, style, 
}) => {
  const props = data[index];

  return (
    <div key={props.property.id} style={{...style, marginTop: 8, marginBottom: 8}}>
      <MapPropertyCard
        {...props}
      />
    </div>
  );
};
