// @ts-check
import {useState} from "react";
import {GrClose} from "react-icons/gr";
import {FaChevronLeft, FaChevronRight} from "react-icons/fa";
import useOnclickOutside from "react-cool-onclickoutside";

// eslint-disable-next-line no-unused-vars
const GalleryModal = ({sharedState, module, hideFunc}) => {
  const numberOfPictures =
    sharedState.picture_galery ?
      sharedState.picture_galery.length :
      0;
  const [currentPicture, setCurrentPicture] = useState(0);

  // on click outside, hide the modal
  const slideshowRef = useOnclickOutside(hideFunc);

  const _nextPicture = () => {
    // creates a round slider to go through all the images without going out of
    // bounds
    setCurrentPicture((currentPicture + 1) % numberOfPictures);
  };

  const _prevPicture = () => {
    // We perform a complete iteration through numberOfPictures - 1 images,
    // this brings us back to the image before this one.
    setCurrentPicture(
      (currentPicture + numberOfPictures - 1) % numberOfPictures,
    );
  };

  return (
    <div
      className={
        "absolute top-0 left-0 w-screen h-screen xl:w-full xl:h-full " +
        "flex content-center items-center justify-center justify-items-center" +
        " bg-scrim"
      }
      style={{
        zIndex: 5000,
        // paddingTop: 65,
      }}
    >
        <div
          onClick={hideFunc}
          className={
            "absolute top-16 left-4 shadow-md py-2 px-4 bg-gradient-to-tr " +
            "bg-white text-black text-sm flex flex-row " +
            "items-center place-items-center rounded-md md:hidden"
          }
          style={{zIndex: 5001}}
        >
          <GrClose
            className="mr-1 text-white"
            size={20}
            color="white"
            style={{
              color: "white",
            }}
          />
          Close
        </div>
      <div
        ref={slideshowRef}
        className={
          "elevate-xs relative flex bg-white view-inner-width h-1/2 " +
          "w-full-mx-2 lg:h-3/4 sm:w-7/12"
        }
        style={{
          // height: 700,
        }}
      >
        <div
          className="h-full relative"
          style={{ flex: 1 }}>
          <div
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              border: "1px solid #ccc",
              backgroundColor: "#ccc",
              backgroundImage: sharedState.picture_galery[0]
                ? `url(${ 
                  sharedState.picture_galery[
                    currentPicture
                  ] 
                  })`
                : "",
              backgroundSize: "cover",
            }}
           />
          <div style={{ position: "absolute" }} />
          <div
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "flex" }}>
              <div
                onClick={_nextPicture}
                style={{ padding: 10, background: "#eee", cursor: "pointer" }}
              >
                <FaChevronLeft style={{ fontSize: "30px" }} />
              </div>
              <div style={{ flex: 1 }} />
              <div
                onClick={_prevPicture}
                style={{ padding: 10, background: "#eee" }}
              >
                <FaChevronRight style={{ fontSize: "30px" }} />
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            "elevate h-12 w-12 rounded-full bg-white absolute top-0 right-0" +
            " -mt-6 -mr-6 hidden sm:flex items-center justify-center"
          }
          onClick={() => {
            hideFunc();
          }}
          style={{
            // height: 50,
            // width: 50,
            // background: "#fff",
            // position: "absolute",
            // right: 0,
            // top: 0,
            // marginTop: -34,
            // marginRight: -20,
            // borderRadius: "100%",
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
          }}
        >
          <GrClose />
        </div>
      </div>
    </div>
  );
  
}

export default GalleryModal;
