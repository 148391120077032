import {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import {RiHotelLine} from 'react-icons/ri';
import {useHistory} from 'react-router';

import {
  Coordinates, Facility, Feature, filterRentals, Property, Rental as RentalProperty,
} from '../../../../api';
import { rentalFacilities, rentalFeatures } from '../../../../api/features';
import {SearchPropertyCard} from '../../../../components/search-property-card';
import {fetchCompulsoryRentalFilters} from '../../../../reducers';
import {DateUtil, DistanceUtil} from '../../../../util';
import {calculateRentalPrice} from '../../../../util/price-calculator';
import { rankFilters } from '../../../../util/rank-filters';

import {ContainerTwo} from '../../components/container-two';
import {FilterableProperty, FilterContext} from '../../context';
import {addFacility, filter} from '../../util';

export const Rental: FC = () => {
  const history = useHistory();

  const [filterableProperties, setFilterableProperties] =
    useState<FilterableProperty<RentalProperty>[]>([]);
  const [rentals, setRentals] = useState<RentalProperty[]>([]);
  const [viewableResults, setViewableResults] = useState<RentalProperty[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [activeFacilities, setActiveFacilities] = useState<Facility[]>([]);
  const [activeFeatures, setActiveFeatures] = useState<Feature[]>([]);

  const [rawResultsStale, setRawResultsStale] = useState<boolean>(false);
  const [filterResultsStale, setFilterResultsStale] = useState<boolean>(false);

  const compulsoryFilters = fetchCompulsoryRentalFilters();
  const {place} = compulsoryFilters;

  const date = new Date();
  date.setDate(date.getDate() + 1);
  const checkin = compulsoryFilters.checkin !== null ? compulsoryFilters.checkin :
    DateUtil.changeDateFormatFromDate(date, "DD-MM-YYYY");

  date.setDate(date.getDate() + 1);
  const checkout = compulsoryFilters.checkout !== null ? compulsoryFilters.checkout :
    DateUtil.changeDateFormatFromDate(date, "DD-MM-YYYY");
  const coords: Coordinates = {
    lat: compulsoryFilters.lat ? compulsoryFilters.lat : 0,
    lng: compulsoryFilters.lng ? compulsoryFilters.lng : 0,
  };

  useEffect(() => {
    const location = place ? place : "";
    const coords: Coordinates = {
      lat: compulsoryFilters.lat ? compulsoryFilters.lat : 0,
      lng: compulsoryFilters.lng ? compulsoryFilters.lng : 0,
    };
    const checkinDate = new Date(
      DateUtil.changeDateFormat(checkin, "DD-MM-YYYY", "MM-DD-YYYY"),
    );

    filterRentals(location, coords, checkinDate.getTime(), (err, results) => {
      if (err) {
        console.error(err);

        return;
      }

      if (results) {
        setIsFetching(false);
        setRentals(results);
        setViewableResults(results);
        const filterableProps = results.map((result) => ({
          ...result,
          price: calculateRentalPrice(result, checkin, checkout),
        }));
        setFilterableProperties(filterableProps);
        setRawResultsStale(false);
      }
    });
  }, [rawResultsStale]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const rankedResults = rankFilters<RentalProperty>(
      rentals,
      {
        active: activeFacilities.map((facility) => facility.title),
        get: (rental) => rental.facilities.map((facility) => facility.title),
      },
      {
        active: activeFeatures.map((feature) => feature.title),
        get: (rental) => rental.features.map((feature) => feature.title),
      },
    );

    setViewableResults(rankedResults);
    setFilterResultsStale(false);
  }, [filterResultsStale]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FilterContext.Provider
      value={{
        filterResultsStale,
        rawResults: rentals,
        rawResultsStale,
        results: filterableProperties,
        setFilterResultsStale,
        setRawResultsStale,
        setResults: setFilterableProperties as Dispatch<SetStateAction<Property[]>>,
        setViewableResults: setViewableResults as (results: Property[]) => void,
        addFacility: (facility) => {
          addFacility(facility, activeFacilities, setActiveFacilities);
        },
      }}
    >
      <ContainerTwo<RentalProperty>
        HeaderIcon={RiHotelLine}
        columnData={[
          {
            title: "Rental facilities",
            data: rentalFacilities,
            onChange: (activeFacilities) => {
              setActiveFacilities(filter(activeFacilities, rentalFacilities));
              setFilterResultsStale(true);
            },
          },
          {
            title: "Rental features",
            data: rentalFeatures,
            onChange: (activeFeatures) => {
              setActiveFeatures(filter(activeFeatures, rentalFeatures));
              setFilterResultsStale(true);
            },
          },
        ]}
        filterOptions={[
          {
            idx: 0,
            onClick: () => console.log("Selecting top solo filters"),
            title: "Top solo picks",
            onFilter: (results) => results,
          },
          {
            idx: 1,
            onClick: () => console.log("Selecting Stars (Highest first)"),
            title: "Stars (Highest first)",
            onFilter: (results) => {
              const parsedResults = (results as Property[]).sort((result, nextResult) => {
                return nextResult.rating - result.rating;
              });

              return parsedResults;
            },
          },
          {
            idx: 2,
            onClick: () => console.log("Selecting stars (Lowest First)"),
            title: "Stars (Lowest first)",
            onFilter: (results) => {
              const parsedResults = (results as Property[]).sort((result, nextResult) => {
                return result.rating - nextResult.rating;
              });

              return parsedResults;
            },
          },
          {
            idx: 3,
            onClick: () => console.log("Selecting by Price (Highest)"),
            title: "Price (Highest)",
            onFilter: (results) => {
              const parsedResults = (results as unknown as RentalProperty[]).sort((result, nextResult) => {
                return nextResult.price - result.price;
              });

              return parsedResults;
            },
          },
          {
            idx: 4,
            onClick: () => console.log("Select by price (lowest)"),
            title: "Price (Lowest)",
            onFilter: (results) => {
              const parsedResults = (results as unknown as RentalProperty[]).sort((result, nextResult) => {
                return result.price - nextResult.price;
              });

              return parsedResults;
            },
          },
          {
            idx: 5,
            onClick: () => console.log("Selecting top Reviewed"),
            title: "Top Reviewed",
            onFilter: (results) => {
              const parsedResults = (results as Property[]).sort((result, nextResult) => {
                return nextResult.reviews.length - result.reviews.length;
              });

              return parsedResults;
            },
          }
        ]}
        headerTitle={`Showing results for ${place}`}
        loading={isFetching}
        results={viewableResults}
      >
        {(viewableResults) => viewableResults.map((rental, idx) => (
          <SearchPropertyCard
            key={rental.id}
            action={{
              label: "View Rental",
              onClick: () => {
                history.push(`/property/rental/${rental.id}?${
                  new URL(location.href).searchParams.toString()
                }`)
              },
            }}
            comments={rental.reviews}
            cover={rental.gallery[0].url}
            description={rental.description}
            duration={`2 days`}
            idx={idx}
            item={rental}
            location={rental.location}
            origin="search"
            price={rental.price}
            rating={rental.rating}
            style={{}}
            title={rental.title}
            subtitle={
              `${
                DistanceUtil.haversineDistance("km", coords, rental.coords)
                  .toFixed(2)
              } KM from ${compulsoryFilters.place}`
            }
          />
        ))}
      </ContainerTwo>
    </FilterContext.Provider>
  );
};
