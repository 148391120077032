import type {FC} from 'react';
import {FaTimes} from 'react-icons/fa';

import {Facility, Feature, Picture, Policy} from '../../../api';
import { Chip } from '../../../components/chip';
import NewGallery from './new-gallery';

import {PolicyCard} from './policy-card';

export type NBOProps = {
  title: string;
  description: string;
  location: string;
  policies: Policy[];
  facilities: Facility[];
  features: Feature[];
  gallery: Picture[];
  removeOverlay: () => void;
};

/**
 * This property shows the features of a sub property that is not bookable by
 * itself. This may include, a room in a rental, the Rental is bookable as a
 * whole property however, the sub-property (room) is not bookable but has
 * displayable details that can be used to convince a user to either lease the
 * property or not. As such, such sub-properties are displayed as
 * non-bookable components in an overlay.
 * @param props NonBookablePropertyOverlay props
 * @returns Component.
 */
export const NonBookablePropertyOverlay: FC<NBOProps> = ({
  title, description, location, policies, facilities, features,
  gallery, removeOverlay,
}) => {
  return (
    <div className="nbo-overlay">
      <div className="property-container__main__app-bar"> 
      <div className="flex flex-row items-center w-full mr-4">
          <FaTimes className="-ml-4 mr-4" size={32} onClick={removeOverlay} />
          <div>
            <h2
              className={
                "text-2xl font-bold w-full overflow-hidden overflow-ellipsis " +
                "whitespace-nowrap"
              }
            >
              {title}
            </h2>
            <p
              className={
                "text-sm overflow-hidden w-full overflow-ellipsis " +
                "whitespace-nowrap"
              }
            >
              {location}
            </p>
          </div>
        </div>
      </div>
      <div className="overlay__gallery">
        <NewGallery gallery={gallery} />
      </div>
      <div className="overlay__description">
        {description}
        <div className="overlay__extras">
          {facilities.length && (
            <>
              <h5 className="overlay__extras__title">Facilities</h5>
              <div className="overlay__extras__content">
                {/* Render Facilities here */}
                {facilities.map((facility, idx) => (
                  <Chip
                    key={`bpo_facility_${idx}`}
                    active={false}
                    label={facility.title}
                  />
                ))}
              </div>
            </>
          )}
          {features.length && (
            <>
              <h5 className="overlay__extras__title">Features</h5>
              <div className="overlay__extras__content">
                {/* Render Facilities here */}
                {features.map((feature, idx) => (
                  <Chip
                    key={`bpo_facility_${idx}`}
                    active={false}
                    label={feature.title}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="overlay__policies">
        {policies.length && (
          <>
            <div className="policy__section__title">Policies</div>
            {policies.map((policy, idx) => (
              <PolicyCard key={`nbo_overlay_${idx}`} policy={policy} />
            ))}
          </>
        )}
      </div>
    </div>
  );
};
