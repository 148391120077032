import type {FC} from 'react';
import type {IconType} from 'react-icons';

import {ColumnData} from '../left-column';

import {FilterOption, ResultsHeader} from './header';
import {Loading} from './loading';

export interface ResultsColumnProps {
  headerTitle: string;
  resultsLength: number;
  HeaderIcon: IconType;
  filterOptions: FilterOption[];
  columnData: ColumnData;
  loading: boolean;
}

export const ResultsColumn: FC<ResultsColumnProps> = ({
  headerTitle, HeaderIcon, resultsLength, filterOptions,
  columnData, loading, children,
}) => {
  return (
    <div
      className="results-column col-start-2 col-end-5 h-full"
    >
      <ResultsHeader
        title={headerTitle}
        HeaderIcon={HeaderIcon}
        resultsLength={resultsLength}
        filterOptions={filterOptions}
        columnData={columnData}
        onPageChange={(page) => console.log(page)}
        onFilterOption={(idx) => console.log("Filter changed to: ", idx)}
      />
      {loading && (
        <Loading />
      )}
      {children}
    </div>
  );
}
