import {eateries} from "../../../../dummy-data";
import {fetchCompulsoryEateriesFilters, fetchOptionalEateriesFilters} from "../../../../reducers";

export default function fetchRestaurants(state, updateState) {
  const {place} = fetchCompulsoryEateriesFilters();
  const optionalFilters = fetchOptionalEateriesFilters();

  const rawResults = eateries;

  updateState({
    rawResults,
    searchResults: rawResults,
    searchItemsLength: 0,
    searchDone: true,
    category: "eateries",
    city: place,
    minPrice: (optionalFilters.price_lowest) ? optionalFilters.price_lowest : 0,
  });
}
