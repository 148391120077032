/**
 * Generates strings of length `length` consisting of case sensitive letters and
 * numbers.
 *
 * @param length the length of the screen that is to be created.
 *
 * @returns a string of length `length` of random letters.
 */
export function generateId(length: number): string {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

