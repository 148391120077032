import {firestore} from "firebase";

import {OnCompleteCallback} from "../..";
import {Activity} from "../../activity";
import {uploadPictures} from "../../cdn";
import {getUser, NoUserFoundError} from "../../user";

import {Draft} from "..";

export const postActivityDraft = (
  draftId: string | null,
  newActivity: Activity,
  onComplete: OnCompleteCallback<Draft<Activity>>,
): void => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);

    return;
  }

  if (draftId) {
    updateActivityDraft(draftId, newActivity, onComplete);
    return;
  }

  const activityPictures = newActivity.gallery;

  // upload all the pictures and then get back the feedback
  uploadPictures("activity", activityPictures, (err, uploadedPictures) => {
    if (err) {
      onComplete(err, null);
      return;
    }

    if (uploadedPictures !== null) {
      newActivity.gallery = uploadedPictures;

      // after the pictures are uploaded, upload the activity to the firestore
      firestore()
        .collection("draft")
        .add(newActivity)
        .then(async (val) => {
          const snapshot = await val.get();
          const activity =
            ({
              id: snapshot.id,
              ...snapshot.data(),
            } as Draft<Activity>);
          onComplete(null, activity);
        })
        .catch((err) => {
          onComplete(err, null);
        });
    } else {
      // something went wrong as there is no error yet the puctures are still
      // null
      onComplete(
        new Error("No error. Pictures however not uploaded"),
        null,
      );
    }
  });
  
};

export const updateActivityDraft = async (
  id: string, newActivity: Activity,
  onComplete: OnCompleteCallback<Draft<Activity>>,
): Promise<void> => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);

    return;
  }

  const activityPictures = newActivity.gallery;

  // upload all the pictures and then get back the feedback
  uploadPictures("activity", activityPictures, async (err, uploadedPictures) => {
    if (err) {
      onComplete(err, null);
      return;
    }

    if (uploadedPictures !== null) {
      newActivity.gallery = uploadedPictures;

      // after the pictures are uploaded, upload the activity to the firestore
      await firestore().collection("draft")
        .doc(id)
        .update({draft: newActivity});
      const docSnapshot = await firestore().collection("draft")
        .doc(id)
        .get();
      
      onComplete(
        null,
        ({id: docSnapshot.id, ...docSnapshot.data()} as Draft<Activity>)
      );
    } else {
      // something went wrong as there is no error yet the puctures are still
      // null
      onComplete(
        new Error("No error. Pictures however not uploaded"),
        null,
      );
    }
  });
  
};
