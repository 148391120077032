import {FC, RefObject, useState} from "react";
import {
  RiApps2Line, RiBuildingLine, RiCarLine, RiFlightTakeoffLine, RiHotelBedLine,
  RiHotelLine, RiRestaurantLine, RiRoadMapLine, RiShipLine,
} from "react-icons/ri";

import Flights from "./Flights";
import Hotels from "./Hotels";
import Rentals from "./Rentals";
import Cruises from "./Cruises";
import Eateries from "./Eateries";
import Conferencing from "./Conferencing";
import Cars from "./Cars";
import Tours from "./Tours";
import Activities from "./Activities";
import {Attraction} from "./attraction";
import { Tab } from "./tab";

/** applied when the drawer is opened */
const openedStyle = {
  right: "0" /* max-with is 100% when the drawer is opened */,
  /* Upon transitioning to Open,
       animate `max-width' for 0.5s*/
  transition: "right 0.5s",
};

/** applied when the drawer is closed */
const closedStyle = {
  right: "-110%" /* max-width is 0 in the closed drawer */,
  /* Upon transitioning to Closed,
       animate `max-width' for 0.5s */
  // transition: '0.5s'
};

type CategoryTabsProps = {
  tabContainerRef: RefObject<HTMLDivElement>;
};

export const CategoryTabs: FC<CategoryTabsProps> = ({
  tabContainerRef,
}) => {
  const [tabs, setTabs] = useState(
    [
      {
        title: "Hotels",
        pathname: "/s/hotels",
        key: 0,
        active: location.pathname === "/s/hotels" || location.pathname === "/",
        icon: <RiHotelBedLine />,
        tab: Hotels,
      },
      {
        title: "Rentals",
        pathname: "/s/rentals",
        key: 1,
        active: location.pathname === "/s/rentals",
        icon: <RiHotelLine />,
        tab: Rentals,
      },
      {
        title: "Attractions",
        pathname: "/s/attractions",
        key: 2,
        active: location.pathname === "/s/attractions",
        icon: <RiApps2Line />,
        tab: Attraction,
      },
      {
        title: "Tour packages",
        key: 3,
        pathname: "/s/tours",
        active: location.pathname === "/s/tours",
        icon: <RiRoadMapLine />,
        tab: Tours,
      },
      {
        title: "Activities",
        pathname: "/s/activities",
        key: 4,
        active: location.pathname === "/s/activities",
        icon: <RiApps2Line />,
        tab: Activities,
      },
      {
        title: "Conferencing",
        pathname: "/s/venues",
        key: 5,
        active: location.pathname === "/s/venues",
        icon: <RiBuildingLine />,
        tab: Conferencing,
      },
      {
        title: "Eateries",
        pathname: "/s/eateries",
        key: 6,
        active: location.pathname === "/s/eateries",
        icon: <RiRestaurantLine />,
        tab: Eateries,
      },
      {
        title: "Flights",
        pathname: "/s/flights",
        key: 7,
        active: location.pathname === "/s/flights",
        icon: <RiFlightTakeoffLine />,
        tab: Flights,
      },
      {
        title: "Cruises",
        pathname: "/s/cruises",
        key: 8,
        active: location.pathname === "/s/cruises",
        icon: <RiShipLine />,
        tab: Cruises,
      },
      {
        title: "Cars",
        key: 9,
        pathname: "/s/cars",
        active: location.pathname === "/s/cars",
        icon: <RiCarLine />,
        tab: Cars,
      },
    ],
  );

  const [{pathname}] = tabs.filter((tab) => tab.active);

  const setActiveTab = (idx: number) => {
    const newTabs = tabs.map((tab, tabIdx) => ({...tab, active: idx === tabIdx}));

    setTabs(newTabs);
  };

  return (
    <div
      className="px-4 max-w-5xl tab-parent home-tab xl:px-0"
    >
      <Tab tabs={tabs} active={pathname} setActive={setActiveTab} />
      <div
        ref={tabContainerRef}
        className="overflow-x-hidden w-full"
      >
        <div
          className="tab"
          style={tabs[0].active ? openedStyle : closedStyle}
        >
          <Hotels />
        </div>

        <div
          className="absolute w-full -right-full"
          style={tabs[1].active ? openedStyle : closedStyle}
        >
          <Rentals />
        </div>

        <div
          className="absolute w-full -right-full"
          style={tabs[2].active ? openedStyle : closedStyle}
        >
          <Attraction />
        </div>
        <div
          className="absolute w-full -right-full"
          style={tabs[3].active ? openedStyle : closedStyle}
        >
          <Tours />
        </div>

        <div
          className="absolute w-full -right-full"
          style={tabs[4].active ? openedStyle : closedStyle}
        >
          <Activities />
        </div>

        <div
          className="absolute w-full -right-full"
          style={tabs[5].active ? openedStyle : closedStyle}
        >
          <Conferencing />
        </div>
        <div
          className="absolute w-full -right-full"
          style={tabs[6].active ? openedStyle : closedStyle}
        >
          <Eateries />
        </div>

        <div
          className="absolute w-full -right-full"
          style={tabs[7].active ? openedStyle : closedStyle}
        >
          <Flights />
        </div>

        <div
          className="absolute w-full -right-full"
          style={tabs[8].active ? openedStyle : closedStyle}
        >
          <Cruises />
        </div>
        <div
          className="absolute w-full -right-full"
          style={tabs[9].active ? openedStyle : closedStyle}
        >
          <Cars />
        </div>
      </div>
    </div>
  );
};
