import {Facility, Feature} from "..";

export const hotelFeatures: Feature[] = [
  {
    title: "24/7 reception",
    description: "ever-present attendants at the reception",
  },
  {
    title: "Communal spaces",
    description: "Places for all to convene",
  },
  {
    title: "Online Booking",
    description: "Book for hotel services online",
  },
  {
    title: "Ideal location",
    description: "Situated next to a landmark",
  },
];

export const hotelFacilities: Facility[] = [
  {title: "Spa", description: "Spa and Sauna"},
  {
    title: "Semi open & outdoor restaurant",
    description: "Walk-in restaurant with the best meals",
  },
  {
    title: "Poolside bar",
    description: "Bar at the poolside",
  },
  {
    title: "Car parking",
    description: "Free car parking for clients",
  },
  {
    title: "Swimming pool/ Jacuzzi",
    description: "Swimming pool present",
  },
  {
    title: "Public computer",
    description: "Computers available for use all over",
  },
  {
    title: "Disable rooms & Interconnecting rooms",
    description: "Accessible rooms to cater for people with special needs",
  },
  {
    title: "24 Hour security",
    description: "Constant surveillance and 24-hour on-site guards",
  },
];

export const hotelRoomFacilities: Facility[] = [
  {
    title: "Wireless Connectivity (Wi-Fi)",
    description: "Free and fast wifi connectivity available in the room",
  },
  {
    title: "USB charger ports & universal plug in power",
    description: "Charge you phone and appliances from everywhere",
  },
  {
    title: "Smart TV",
    description: "The room has a TV",
  },
  {
    title: "Mini-bar service",
    description: "A small self-service bar is present in the room",
  },
  {
    title: "Air conditioning",
    description: "Ever present air-conditioning to keep the room fresh",
  },
  {
    title: "Coffee/Tea maker",
    description: "Make coffee and tea in the comfort of your own room",
  },
  {
    title: "Non smoking rooms",
    description: "No smoking is allowed in this room",
  },
  {
    title: "Hair Dryer",
    description: "Hair dryer present in this room",
  },
  {
    title: "In room electronic safe",
    description: "Safe to leave you personal electronics in the room",
  },
  {
    title: "Bathrobe & Slipper",
    description: "Bathrobe and slippers offered together with fresh towels",
  },
];

export const hotelRoomFeatures: Feature[] = [
  {
    title: "Balcony",
    description: "The room has ab=n accessible balcony",
  },
  {
    title: "Patio",
    description: "The room has a patio",
  },
];
