import {FC, useEffect, useState} from 'react';
import {FaUtensils} from 'react-icons/fa';
import {useHistory} from 'react-router';

import {Car as CarProperty, Coordinates, Facility, Feature, filterCars} from '../../../../api';
import { carFacilities, carFeatures } from '../../../../api/features';
import {SearchPropertyCard} from '../../../../components/search-property-card';
import {fetchCompulsoryCarFilters} from '../../../../reducers';
import { DateUtil } from '../../../../util';
import {calculateCarPrice} from '../../../../util/price-calculator';

import {Container} from '../../components/container';
import { filter } from '../../util';

export const Car: FC = () => {
  const history = useHistory();

  const [cars, setCars] = useState<CarProperty[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(true);

  const [activeFacilities, setActiveFacilities] = useState<Facility[]>([]);
  const [activeFeatures, setActiveFeatures] = useState<Feature[]>([]);

  const {place, lat, lng, date} = fetchCompulsoryCarFilters();

  useEffect(() => {
    const location = (place) ? place : "";
    const coords: Coordinates = {
      lat: (lat) ? lat : 0,
      lng: (lng) ? lng :0,
    };
    const checkinDate = date ? new Date(
      DateUtil.changeDateFormat(date, "DD-MM-YYYY", "MM-DD-YYYY"),
    ) : new Date();

    checkinDate.setHours(10, 0);

    filterCars(location, coords, checkinDate.getTime(), (err, results) => {
      if (err) {
        console.error(err);

        return;
      }

      if (results) {
        setIsFetching(false);
        setCars(results);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container
      HeaderIcon={FaUtensils}
      columnData={[
        {
          title: "Car facilities",
          data: carFacilities,
          onChange: (activeFacilities) => {
            setActiveFacilities(filter(activeFacilities, carFacilities));
            // TODO: Set filter results stale
          },
        },
        {
          title: "Car features",
          data: carFeatures,
          onChange: (activeFeatures) => {
            setActiveFeatures(filter(activeFeatures, carFeatures));
            // TODO: Set filter results stale
          },
        },
      ]}
      filterOptions={[
          {
            idx: 0,
            onClick: () => console.log("Selecting top solo filters"),
            title: "Top solo picks",
            onFilter: (results) => results,
          },
          {
            idx: 1,
            onClick: () => console.log("Selecting Stars (Highest first)"),
            title: "Stars (Highest first)",
            onFilter: (results) => {
              console.log("Applying filters");

              return results;
            },
          },
          {
            idx: 2,
            onClick: () => console.log("Selecting stars (Lowest First)"),
            title: "Stars (Lowest first)",
            onFilter: (results) => {
              console.log("Applying filters");

              return results;
            },
          },
          {
            idx: 3,
            onClick: () => console.log("Selecting by Price (Highest)"),
            title: "Price (Highest)",
            onFilter: (results) => {
              console.log("Applying filters");

              return results;
            },
          },
          {
            idx: 4,
            onClick: () => console.log("Select by price (lowest)"),
            title: "Price (Lowest)",
            onFilter: (results) => {
              console.log("Applying filters");

              return results;
            },
          },
          {
            idx: 5,
            onClick: () => console.log("Selecting top Reviewed"),
            title: "Top Reviewed",
            onFilter: (results) => {
              console.log("Applying filters");

              return results;
            },
          }
      ]}
      headerTitle={`Showing results for ${place}`}
      loading={isFetching}
      resultsLength={cars.length}
    >
      {cars.map((car, idx) => (
        <SearchPropertyCard
          key={car.id}
          action={{
            label: "View Rental",
            onClick: () => {
              history.push(`/property/car/${car.id}?${
                new URL(location.href).searchParams.toString()
              }`)
            },
          }}
          comments={[]}
          cover={car.gallery[0].url}
          description={car.driver.name}
          duration={`2 days`}
          idx={idx}
          item={car}
          location={car.location}
          origin="search"
          price={calculateCarPrice(car)}
          rating={car.rating}
          style={{}}
          title={car.reg_no}
        />
      ))}
    </Container>
  );
};
