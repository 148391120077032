import {FC, useContext, useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {DatePicker, InputNumber} from 'antd';
import Lottie from 'react-lottie';

import {fetchAvailableHotelRooms} from '../../../../api';
import {Hotel} from '../../../../api/hotel/types';
import animation from '../../../../assets/anim/loading-hotel.json';
import {HotelReservation} from '../../../../api/reservation/types';
import {calculateHotelPrice} from '../../../../util/price-calculator';
import {fetchCompulsoryFilters} from '../../../../reducers/search/hotel-filters';
import {DateUtil} from '../../../../util';

import {OverlayProvider} from '../../../search-property/context';

import Container, {SubPropertySection} from '../../components/container';
import {Card} from '../../../../components/card/property-card';
import {BookablePropertyOverlay} from '../../components/bookable-property-overlay';

import {HotelCheckout} from './hotel-checkout';
import {HotelPriceColumn} from './hotel-price-column';
import {TextInput} from '../../../../components/input/text-input';
import {Button} from '../../../../components';
import ActivityIndicator from '../../../../components/loading';
import { AppStateContext } from '../../../../context/app-state-provider';

export type HotelProps = RouteComponentProps<{id: string}>;

const {RangePicker} = DatePicker;

export const HotelProperty: FC<HotelProps> = ({match}) => {
  const {state} = useContext(AppStateContext);

  const [activeSubProperty, setActiveSubProperty] = useState(0);
  const [hotel, setHotel] = useState<Hotel | null>(null);
  const [numberOfRooms, setNumberOfRooms] = useState<number>(1);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorOccured, setErrorOccured] = useState<boolean>(false);

  const date = new Date();

  const compulsoryFilters = fetchCompulsoryFilters();

  const [adults, setAdults] = useState<number>(
    compulsoryFilters.adults ? compulsoryFilters.adults : 1,
  );
  const [children, setChildren] = useState<number>(
    compulsoryFilters.children ? compulsoryFilters.children : 0,
  );
  date.setDate(date.getDate() + 1);
  const [checkin, setCheckin] = useState<string>(
    compulsoryFilters.checkin ?
      compulsoryFilters.checkin :
      DateUtil.changeDateFormatFromDate(date, "DD-MM-YYYY"),
  );

  date.setDate(date.getDate() + 1);
  const [checkout, setCheckout] = useState<string>(
    compulsoryFilters.checkout ?
      compulsoryFilters.checkout :
      DateUtil.changeDateFormatFromDate(date, "DD-MM-YYYY"),
  );
  
  useEffect(() => {
    const checkinDate = new Date(
      DateUtil.changeDateFormat(checkin, "DD-MM-YYYY", "MM-DD-YYYY"),
    );
    checkinDate.setHours(10, 0, 0, 0);

    // fetch the Hotels and after fetching, carry out the on success function
    fetchAvailableHotelRooms(match.params.id, checkinDate.getTime(), (err, data) => {
      setIsLoading(false);

      if (err) {
        setErrorOccured(true);
        return;
      }

      // handle the hotel data that has been retrieved by the system
      setHotel(data);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const calculatePrice = (): number => {
    if (!hotel) return 0;

    return calculateHotelPrice(
      hotel.rooms[activeSubProperty].price, numberOfRooms, checkin, checkout,
    );
  };

  const checkinDate = new Date(
    DateUtil.changeDateFormat(checkin, "DD-MM-YYYY", "MM-DD-YYYY"),
  );
  checkinDate.setHours(14, 0, 0, 0);

  const checkoutDate = new Date(
    DateUtil.changeDateFormat(checkout, "DD-MM-YYYY", "MM-DD-YYYY"),
  );
  checkoutDate.setHours(10, 0, 0, 0);

  const onProceedToCheckout = (): HotelReservation => {
    // creates the reservation card and then proceeds to checkout
    const reservation: HotelReservation = {
      amount_received: 0,
      category: "hotel",
      checkin: checkinDate.getTime(),
      checkout: checkoutDate.getTime(),
      due_date: checkinDate.getTime(),
      expected_amount: calculatePrice(),
      property_id: `${hotel?.id}:${hotel?.rooms[activeSubProperty].id}`,
      reservation_type: hotel ? hotel.reservation_policy : "full_amount",
      reservation_date: Date.now(),
      status: "pending",
      adults,
      children,
      number_of_rooms: numberOfRooms,
    };

    return reservation;
  };

  // get the active currency and the amount exchange rate based on the active
  // currency
  const {ACTIVE_CURRENCY} = state;
  const exchangeRate = ACTIVE_CURRENCY !== "USD" ?
    state.CONVERSION_RATES.rates[ACTIVE_CURRENCY] :
    1;

  const renderCheckoutColumn = () => {
    return (
      <>
        {hotel && (
          <HotelCheckout
            numberOfRooms={numberOfRooms}
            setNumberOfRooms={setNumberOfRooms}
            price={calculateHotelPrice(
              hotel.rooms[activeSubProperty].price,
              numberOfRooms,
              checkin,
              checkout,
            )}
            room={hotel.rooms[activeSubProperty]}
            onProceedToCheckout={onProceedToCheckout}
          />
        )}
      </>
    );
  };

  const renderPriceColumn = () => {
    return (
      <>
        {hotel && (
          <HotelPriceColumn
            price={hotel.rooms[activeSubProperty]?.price}
            capacity={`${hotel.rooms[activeSubProperty]?.capacity?.adults_only}`}
          />
        )}
      </>
    );
  };

  return (
    <>
      {isLoading && (
        <div className="w-full h-96 flex flex-col items-center justify-center">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animation,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            style={{}}
            height={200}
            width={200}
          />
          <p className="text-base font-semibold">Fetching Hotel Details</p>
        </div>
      )}
      {!isLoading && !errorOccured && hotel && (
        <OverlayProvider.Consumer>
          {({setIsOverlay, setOnOverlay, setIsCancellable}) => (
            <Container
              property={hotel}
              showDirections={true}
              renderSearchSection={() => {
                return (
                  <>
                    <TextInput
                      disabled={true}
                      value={hotel.title}
                      helper="Name of the hotel"
                      id="hotel-name"
                      inputLabel="Hotel Name"
                      name="hotel_name"
                      onChange={(e) => console.log("Should not change")}
                      className="mb-2"
                    />
                    <p className="text-xs mb-1">
                      Choose new check in and checkout dates
                    </p>
                    <RangePicker
                      disabledDate={(date) => {
                        const timestamp = Number(date.format("x"));
                        
                        const today = new Date();
                        today.setHours(0, 0, 0, 0);

                        return today.getTime() > timestamp;
                      }}
                      onChange={(values) => {
                        if (values) {
                          if (values[0]) {
                            setCheckin(
                              DateUtil.stringifyDate(values[0].toDate()));
                          }

                          if (values[1]) {
                            setCheckout(
                              DateUtil.stringifyDate(values[1].toDate()));
                          }
                        }
                      }}
                    />
                    <p className="text-xs mb-1">Number of adults</p>
                    <InputNumber
                      className="mb-2 w-full"
                      defaultValue={adults}
                      value={adults}
                      onChange={(e) => {
                        setAdults(e);
                      }}
                    />
                    <p className="text-xs mb-1">Number of children</p>
                    <InputNumber
                      className="mb-2 w-full"
                      defaultValue={children}
                      value={children}
                      onChange={(e) => {
                        setChildren(e);
                      }}
                    />
                    <div className="flex flex-row justify-center">
                      <Button
                        disabled={
                          DateUtil.changeDateFormatFromDate(
                            checkinDate, "DD-MM-YYYY",
                          ) === compulsoryFilters.checkin &&
                          DateUtil.changeDateFormatFromDate(
                            checkoutDate, "DD-MM-YYYY",
                          ) === compulsoryFilters.checkout
                        }
                        type="button"
                        onClick={() => {
                          setIsCancellable && setIsCancellable(false);
                          setOnOverlay && setOnOverlay(() => () => (
                            <ActivityIndicator
                              caption=""
                            />
                          ));
                          setIsOverlay && setIsOverlay(true);

                          const checkinDate = new Date(
                            DateUtil.changeDateFormat(
                              checkin, "DD-MM-YYYY", "MM-DD-YYYY",
                            )
                          );
                          checkinDate.setHours(10, 0, 0, 0);

                          fetchAvailableHotelRooms(
                            match.params.id,
                            checkinDate.getTime(),
                            (err, hotel) => {
                              setIsCancellable && setIsCancellable(true);
                              setIsOverlay && setIsOverlay(false);
                              setOnOverlay && setOnOverlay(null);
                              if (err) {
                                console.error(err);

                                return;
                              }

                              // set the hotel details after search
                              setHotel(hotel);
                            },
                          )
                        }}
                      >
                        Check Availability
                      </Button>
                    </div>
                  </>
                );
              }}
            >
              <SubPropertySection title="Rooms">
                {hotel.rooms.map((hotelRoom, idx) => (
                  <Card
                    key={`hotel_room_${idx}`}
                    cover={hotelRoom.gallery[0].url}
                    description={hotelRoom.description}
                    subtitle={`${
                      (hotelRoom.price * exchangeRate).toFixed(2)
                    } ${ACTIVE_CURRENCY}`}
                    title={hotelRoom.title}
                    action={{
                      label: "View Room",
                      onClick: async () => {
                        await setActiveSubProperty(idx);
                        window.scrollTo({
                          behavior: "smooth",
                          left: 0,
                          top: 0,
                        });
                        setOnOverlay && setOnOverlay(() => () => (
                          <BookablePropertyOverlay
                            gallery={hotelRoom.gallery}
                            renderCheckoutColumn={renderCheckoutColumn}
                            renderPriceColumn={renderPriceColumn}
                            description={hotelRoom.description}
                            facilities={hotelRoom.facilities}
                            features={hotelRoom.features}
                            location={hotel.location}
                            policies={hotelRoom.room_policy}
                            removeOverlay={() => {
                              setIsOverlay && setIsOverlay(false);
                              setOnOverlay && setOnOverlay(null);
                            }}
                            title={hotelRoom.title}
                          />
                        ))
                        setIsOverlay && setIsOverlay(true);
                      },
                    }}
                  />
                ))}
              </SubPropertySection>
            </Container>
          )}
        </OverlayProvider.Consumer>
      )}
      {!isLoading && !errorOccured && !hotel && (
        <></>
      )}
      {!isLoading && errorOccured && (
        <></>
      )}
    </>
  );
};
