import {firestore} from 'firebase';
import {ApiFunc, Coordinates, OnCompleteCallback, Picture} from '..';
import { uploadPictures } from '../cdn';
import { getUser, NoUserFoundError } from '../user';
import type {Eatery} from './types';
export type {Eatery} from './types';

export const fetchEatery = async (
  id: string, onFetch: (err: Error | null, data: Eatery | null) => void,
): Promise<void> => {
  console.log(`Fetching eatery with id: ${id}`)

  try {
    const snapshot = await firestore().collection("eatery").doc(id).get();
    const eatery = {id: snapshot.id, ...snapshot.data()} as Eatery;

    onFetch(null, eatery);
  } catch (err) {
    const fetchError = new Error("An error occured while fetching eatery");
    onFetch(fetchError, null);
  }
};

export const fetchAvailableEateryTables = async (
  eateryId: string, checkin: number,
  onComplete: OnCompleteCallback<Eatery>,
): Promise<void> => {
  try {
    const response = await fetch(
      "https://us-central1-safarixpertz.cloudfunctions.net/fetch_available_eatery_tables",
      {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          eateryId,
          checkin,
        }),
      },
    );

    if (response.status === 200) {
      const eatery = (await response.json()) as Eatery;
      onComplete(null, eatery);
    } else {
      const fetchError = new Error("Error occured fetching eatery");
      onComplete(fetchError, null);
    }
  } catch (err) {
    const fetchErr = new Error("An error occured while fetching");
    onComplete(fetchErr, null);
  }
};

export const fetchEateries: ApiFunc<Eatery[]> = async (onComplete) => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);

    return;
  }

  try {
    const snapshot = await firestore().collection("eatery")
      .where("owner", "==", user.uid)  
      .get();
    const {docs} = snapshot;

    const eaterys: Eatery[] = [];
    docs.forEach((doc) => {
      eaterys.push({id: doc.id, ...doc.data()} as Eatery);
    });

    onComplete(null, eaterys);
  } catch (err) {
    const fetchError = new Error("An error occured while fetching eaterys");
    onComplete(fetchError, null);
  }
};

export const filterEateries = async (
  location: string, coords: Coordinates, checkin: number,
  onComplete: OnCompleteCallback<Eatery[]>,
): Promise<void> => {
  try {
    const response = await fetch(
      "https://us-central1-safarixpertz.cloudfunctions.net/fetch_eateries",
      {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          location,
          coords,
          checkin,
        }),
      },
    );

    if (response.status === 200) {
      const data = (await response.json()) as Eatery[];

      onComplete(null, data);
    } else {
      const fetchError = new Error("Error fetching eateries");
      onComplete(fetchError, null);
    }
  } catch (err) {
    const fetchErr = new Error("An error occured while fetching");
    onComplete(fetchErr, null);
  }
};

export const postEatery = (
  eatery: Eatery, onComplete: OnCompleteCallback<Eatery>,
): void => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);

    return;
  }

  // we fetch all the pictures from the eatery facility itself, and also all
  // the pictues from the rooms themselves.
  // We then create an array (Buffer) that records how many pictures we are
  // uploading for each, this will be used to get back all the pictures in order
  // of so as to re-order them back to their respective places without a hassle.
  //
  // E.g if we have a eatery facility with 6 pictures of its own and three
  // eatery rooms with pictures of their own, then we can create the buffer
  // as follows:
  // buffer = [6, 6, 7, 8];
  // then an array of pictures to upload is created with a total of
  // (6 + 6 + 7 + 8) images, where:
  // they are ordered based on:
  // eatery facility first, rooms based on the index of the room,
  // As such, these pictures can be gotten back by splicing and then pushing
  // back in order of index starting with the eatery facility as the first
  // index (0)
  const bufferSizes: number[] = [];
  const imageBuffer: Picture[] = [];

  bufferSizes.push(eatery.gallery.length);
  imageBuffer.push(...eatery.gallery);

  // push all the pictures for each menuItems
  eatery.menu.forEach((menuItem) => {
    bufferSizes.push(1);
    imageBuffer.push(menuItem.picture);
  });

  // push all the pictures of the tables
  eatery.table.forEach((tableType) => {
    bufferSizes.push(1);
    imageBuffer.push(tableType.picture);
  });

  // after all the images are pushed we can upload them all and then replace
  // after successful upload
  uploadPictures("eatery", imageBuffer, async (err, uploadedImages) => {
    if (err) {
      onComplete(err, null);
      return;
    }

    if (uploadedImages) {
      eatery.gallery = uploadedImages.splice(0, bufferSizes[0]);

      let menuItemCount = 0;
      let tableCount = 0;
      // starting from the second index, we start assigning the rooms all their
      // images back
      for (let i=1; i<bufferSizes.length; i++) {
        // since menu items only contain one picture (we extract only one pic)
        if (menuItemCount < eatery.menu.length) {
          eatery.menu[menuItemCount].picture = uploadedImages.splice(0, 1)[0];
          menuItemCount++;
          continue;
        }

        // after the menu item count is complete, we need to push to tables
        if (tableCount < eatery.table.length) {
          eatery.table[tableCount].picture = uploadedImages.splice(0, 1)[0];
          tableCount++;
        }
      }

      // after all the images are uploaded we can now get to uploading the
      // confernce data
      try {
        const doc =  await firestore()
          .collection("eatery")
          .add({...eatery, owner: user.uid} as Eatery);

        onComplete(null, (await doc.get()).data() as Eatery);
      } catch (err) {
        console.log(err);
        const eateryUploadError =
          new Error("Error uploading eatery facility details");
        onComplete(eateryUploadError, null);
      }
      
    } else {
      // these images may be null
      const uploadErr = new Error("The images to be uploaded returned null");
      onComplete(uploadErr, null);
    }
  });
};

export const updateEatery = (
  eateryId: string, eatery: Eatery, onComplete: OnCompleteCallback<Eatery>,
): void => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);

    return;
  }

  // we fetch all the pictures from the eatery facility itself, and also all
  // the pictues from the rooms themselves.
  // We then create an array (Buffer) that records how many pictures we are
  // uploading for each, this will be used to get back all the pictures in order
  // of so as to re-order them back to their respective places without a hassle.
  //
  // E.g if we have a eatery facility with 6 pictures of its own and three
  // eatery rooms with pictures of their own, then we can create the buffer
  // as follows:
  // buffer = [6, 6, 7, 8];
  // then an array of pictures to upload is created with a total of
  // (6 + 6 + 7 + 8) images, where:
  // they are ordered based on:
  // eatery facility first, rooms based on the index of the room,
  // As such, these pictures can be gotten back by splicing and then pushing
  // back in order of index starting with the eatery facility as the first
  // index (0)
  const bufferSizes: number[] = [];
  const imageBuffer: Picture[] = [];

  bufferSizes.push(eatery.gallery.length);
  imageBuffer.push(...eatery.gallery);

  // push all the pictures for each menuItems
  eatery.menu.forEach((menuItem) => {
    bufferSizes.push(1);
    imageBuffer.push(menuItem.picture);
  });

  // push all the pictures of the tables
  eatery.table.forEach((tableType) => {
    bufferSizes.push(1);
    imageBuffer.push(tableType.picture);
  });

  // after all the images are pushed we can upload them all and then replace
  // after successful upload
  uploadPictures("eatery", imageBuffer, async (err, uploadedImages) => {
    if (err) {
      onComplete(err, null);
      return;
    }

    if (uploadedImages) {
      eatery.gallery = uploadedImages.splice(0, bufferSizes[0]);

      let menuItemCount = 0;
      let tableCount = 0;
      // starting from the second index, we start assigning the rooms all their
      // images back
      for (let i=1; i<bufferSizes.length; i++) {
        // since menu items only contain one picture (we extract only one pic)
        if (menuItemCount < eatery.menu.length) {
          eatery.menu[menuItemCount].picture = uploadedImages.splice(0, 1)[0];
          menuItemCount++;
          continue;
        }

        // after the menu item count is complete, we need to push to tables
        if (tableCount < eatery.table.length) {
          eatery.table[tableCount].picture = uploadedImages.splice(0, 1)[0];
          tableCount++;
        }
      }

      // after all the images are uploaded we can now get to uploading the
      // confernce data
      try {
        await firestore()
          .collection("eatery")
          .doc(eateryId)
          .update({...eatery} as Eatery);
        const doc = firestore().collection("eatery").doc(eateryId);

        onComplete(null, (await doc.get()).data() as Eatery);
      } catch (err) {
        const eateryUploadError =
          new Error("Error uploading eatery facility details");
        onComplete(eateryUploadError, null);
      }
      
    } else {
      // these images may be null
      const uploadErr = new Error("The images to be uploaded returned null");
      onComplete(uploadErr, null);
    }
  });
};
