import {FC, useEffect, useState} from 'react';
import { FaUtensils } from 'react-icons/fa';
import {RouteComponentProps} from 'react-router';

import {Eatery as EateryType, fetchEateries} from '../../../../api';
import { PartnerHeader } from '../../components';

import {PropertiesSummary} from '../../components/properties-summary';

export const Eatery: FC<RouteComponentProps> = () => {
  const [eateries, setEateries] = useState<EateryType[] | null>(null);

  useEffect(() => {
    fetchEateries((err, results) => {
      if (err) {
        console.error(err);

        return;
      }

      setEateries(results);
    });
  }, []);

  return (
    <>
      {eateries && (
        <>
          <PartnerHeader HeaderIcon={FaUtensils} title="All Eateries" />
          <PropertiesSummary
            category="eatery"
            properties={eateries}
          />
        </>
      )}
    </>
  );
};
