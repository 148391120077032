import type {FC} from 'react';
import { ReservationRow } from '..';

import {PopulatedHotelReservation} from '../../../../../api/reservation/types';
import {DateUtil} from '../../../../../util';

type HotelReservationRowsProps = {
  reservation: PopulatedHotelReservation;
};

export const HotelReservationRows: FC<HotelReservationRowsProps> = ({
  reservation,
}) => {
  return (
    <>
      <ReservationRow
        title="Start date"
        description={
          DateUtil.getHumanReadableFormat(
            new Date(reservation.checkin),
          )
        }
      />

      {reservation.checkout && (
        <ReservationRow
          title="End date"
          description={
            DateUtil.getHumanReadableFormat(
              new Date(reservation.checkout),
            )
          }
        />
      )}

      <ReservationRow
        title="Number of Rooms"
        description={`${reservation.number_of_rooms}`}
      />
    </>
  );
};
