import {useState} from "react";
import type {FC, ChangeEventHandler} from "react";
import {FaChevronDown} from "react-icons/fa";

export interface DropDownProps {
  defaultValue: string | number;
  label: string;
  name: string;
  options: Array<{value: string | number, title: string}>;
  onChange: ChangeEventHandler<HTMLSelectElement>;
}

/**
 * Dropdown Element.
 */
export const Dropdown: FC<DropDownProps> = ({
  name, defaultValue, label, options, onChange,
}) => {
  const [selected, setSelected] = useState(defaultValue);

  const handleChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    setSelected(e.target.value);
    onChange(e);
  };

  return (
    <div>
      <label htmlFor={name}>{label}</label>
      <div
        style={{
          position: "relative",
          backgroundColor: "#fff",
          borderRadius: 2,
          cursor: "pointer",
        }}
      >
        <select
          name={name}
          value={selected}
          onChange={handleChange}
          style={{
            width: "100%",
            padding: "8px 16px 8px 16px",
            borderWidth: 0,
            fontSize: 16,
            borderRadius: 2,
            cursor: "pointer",
          }}
        >
          {options.map((option, idx) => (
            <option
              key={`${option}_${idx}`}
              value={option.value}
            >
              {option.title}
            </option>
          ))}
        </select>
        <span
          style={{
            display: "flex",
            height: "100%",
            pointerEvents: "none",
            paddingRight: 12,
            paddingLeft: 12,
            position: "absolute",
            top: 0,
            right: 0,
            backgroundColor: "white",
            alignItems: "center",
          }}
        >
          <FaChevronDown color="rgb(7, 160, 220)" />
        </span>
      </div>
    </div>
  );
}
