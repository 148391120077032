import {FC, useContext} from 'react';
import {FaUserFriends} from 'react-icons/fa';
import { AppStateContext } from '../../../../context/app-state-provider';

export const HotelPriceColumn: FC<{capacity: string, price: number}> = ({
  capacity, price,
}) => {
  const {state} = useContext(AppStateContext);

  const {ACTIVE_CURRENCY} = state;
  const exchangeRate = ACTIVE_CURRENCY !== "USD" ?
    state.CONVERSION_RATES.rates[ACTIVE_CURRENCY] :
    1;

  return (
    <div
      className={
        "flex flex-col sm:flex-row sm:items-center justify-between px-4 py-2"
      }
    >
      <div className="flex flex-row items-center">
        <FaUserFriends size={24} />
        <div className="ml-8">
          <h6 className="text-xl">{capacity}</h6>
          <p className="text-xs">Room capacity</p>
        </div>
      </div>
      <h3 className="text-2xl font-semibold">
        {`${(price * exchangeRate).toFixed(2)} ${ACTIVE_CURRENCY}`}
      </h3>
    </div>
  );
};
