// @ts-check

////////////////////////////////////////////////////////////////////////////////
// JSDoc Types
////////////////////////////////////////////////////////////////////////////////
/**
 * @typedef {{
 * from: string,
 * to: string,
 * departure: string,
 * return: string,
 * type: string,
 * seat: string,
 * adults: ?number,
 * children: ?number,
 * }} FlightCompulsoryFilters
 *
 * @typedef {{
 * price_lowest: ?number,
 * price_highest: ?number,
 * star_rating: ?number,
 * feature: ?string[],
 * theme: ?string,
 * }} OptionalFilters
 *
 * @typedef {{
 * compulsoryFilters: FlightCompulsoryFilters,
 * optionalFilters: OptionalFilters,
 * }} SearchState
 *
 * @typedef {{
 * type: "UPDATE_All_FILTERS" | "UPDATE_OPTIONAL_FILTERS" |
 * "UPDATE_COMPULSORY_FILTERS",
 * value: any,
 * }} SearchStoreAction
 */

/**
 * This function fetches all the compulsory Filters from the current
 * `window.location` Url.
 * 
 * @returns {FlightCompulsoryFilters}
 */
export const fetchCompulsoryFlightFilters = () => {
  const url = new URL(location.href);
  /**@type {FlightCompulsoryFilters} */
  const compulsoryFilters = {};

  const [loc, ] = url.searchParams.get("from").split(",");
  const [to, ] = url.searchParams.get("to").split(",");
  compulsoryFilters.from = loc;
  compulsoryFilters.to = to;
  
  if (url.searchParams.has("adults")) {
    compulsoryFilters.adults = Number(url.searchParams.get("adults"));
  } else {
    compulsoryFilters.adults = 1;
  }

  if (url.searchParams.has("children")) {
    compulsoryFilters.children = Number(url.searchParams.get("children"));
  } else {
    compulsoryFilters.children = 0;
  }

  compulsoryFilters.departure = url.searchParams.get("departure");
  compulsoryFilters.return = url.searchParams.get("return");
  compulsoryFilters.seat = url.searchParams.get("seat");
  compulsoryFilters.type = url.searchParams.get("type");

  return compulsoryFilters;
};

/**
 * This function fetches all the optional features encoded in the URL.
 *
 * @returns {OptionalFilters} the optional filters that have been captured.
 */
export const fetchOptionalFlightFilters = () => {
  const url = new URL(location.href);

  /**@type {OptionalFilters} */
  const optionalFilters = {};

  optionalFilters.feature = url.searchParams.getAll("feature");
  if (url.searchParams.has("price_highest")) {
    optionalFilters.price_highest = Number(url.searchParams.get("price_highest"));
  }

  if (url.searchParams.has("price_lowest")) {
    optionalFilters.price_lowest = Number(url.searchParams.get("price_lowest"));
  }

  optionalFilters.star_rating = Number(url.searchParams.get("star_rating"));
  optionalFilters.theme = url.searchParams.get("theme");

  return optionalFilters;
};
