import {FC, useContext, useState} from 'react';

import {SectionHeader} from '../../../../components/section-header';

import {OnOverlayCallback} from '../../../../context/overlay-provider-context';

import {FilterContext} from '../../context';

import {NewSearch} from '../new-search';
import {FilterGroup} from '../filter-group';

import {PriceFilter} from './price-filter';
import { ShowOnMap } from '../show-on-map';

export type ColumnData = {
  title: string,
  data: {
    title: string,
    description: string,
  }[];
  onChange: (values: string[]) => void;
}[];

export type LeftColumnProps = {
  columnData: {
    title: string,
    data: {
      title: string,
      description: string,
      onFilterChange?: () => void,
    }[];
    onChange: (values: string[]) => void;
  }[];
  setOnOverlay?: OnOverlayCallback;
  setIsOverlay?: (isOverlay: boolean) => void;
};

export const LeftColumn: FC<LeftColumnProps> = ({
  columnData, setIsOverlay, setOnOverlay,
}) => {
  const {
    results, rawResults, setViewableResults, setRawResultsStale,
  } = useContext(FilterContext);
  let maximumPrice = 0;
  const minimumPrice = 0;

  results.forEach((result) => {
    if (result.price > maximumPrice) {
      maximumPrice = result.price;
    }
  });

  const [rangeMin, setRangeMin] = useState<number>(0);
  const [rangeMax, setRangeMax] = useState<number>(100);

  return (
    <div
      className="hidden lg:block filter-column col-start-1 col-end-2"
    >
      <NewSearch setRawResultsStale={setRawResultsStale} />
      <ShowOnMap />
      <div className="my-4 border" style={{borderColor: "#BDBDBD"}}>
        <SectionHeader
          style={{
            border: 0,
            marginLeft: 8,
          }}
        >
          Filter Search By:
        </SectionHeader>
        <div
          className="border border-l-0 border-r-0 border-b-0"
          style={{borderColor: "#BDBDBD"}}
        >
          <SectionHeader style={{marginLeft: 8, border: 0}}>
            Price Range
          </SectionHeader>
          <PriceFilter
            rangeMax={rangeMax}
            rangeMin={rangeMin}
            maximumPrice={maximumPrice}
            minimumPrice={minimumPrice}
            updateState={({rangeMin, rangeMax, priceChanged}) => {
              setRangeMax(rangeMax);
              setRangeMin(rangeMin);

              if (priceChanged) {
                // filter the results
                const minPrice = rangeMin * maximumPrice;
                const maxPrice = rangeMax * maximumPrice;

                const viewableResults = rawResults.filter((result, idx) => {
                  return (results[idx].price >= minPrice && results[idx].price <= maxPrice);
                });

                setViewableResults && setViewableResults(viewableResults);
              }
            }}
          />
        </div>
        {columnData.map(filterGroup => (
          <FilterGroup
            key={filterGroup.title}
            groupData={filterGroup}
          />
        ))}
      </div>
    </div>
  );
};
