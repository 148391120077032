import {auth, database, User, messaging} from "firebase";

import {OnCompleteCallback} from "..";
import {Auth} from "../../util";
import {SafariUser} from "../su";

/**
 * Fetches the user object of the currently signed in user.
 *
 * @returns Returns the currently signed in user, and null if there is no
 * user signed in
 */
export const getUser = (): User | null => {
  const user = auth().currentUser;

  return user;
};

export class NoUserFoundError extends Error {
  constructor() {
    super("No user has been found");
    this.name = "NoUserFoundError";
  }
}

export const fetchUserDetails = async (
  userId: string, onComplete: OnCompleteCallback<SafariUser>,
): Promise<void> => {
  try {
    const userData = await database().ref(`Users_Master/${userId}`)
      .once("value");
    const safariUser = userData.val() as SafariUser;

    onComplete(null, safariUser);

    safariUser && updateMessagingToken(userId);
  } catch (err) {
    onComplete(new NoUserFoundError(), null);
  }
};

export const setUserAuthLevel = async (
  userId: string, authLevel: SafariUser["authLevel"],
): Promise<SafariUser | null> => {
  try {
    const userData = await database().ref(`Users_Master/${userId}`)
      .once("value");
    // if the user type does not exist, prematurely exit to prevent any
    // NullPonterException errors
    if (!userData.exists()) return null;

    const safariUser = userData.val() as SafariUser;
    // since this was introduced later on, we use a default permission incase
    // the user.permissions field does not exist.
    const userPermissions = safariUser.permissions ? safariUser.permissions : 1;

    const newPermissions = Auth.setAuthFlag(userPermissions, authLevel);

    await database().ref(`Users_Master/${userId}`).update({
      permissions: newPermissions,
    } as Partial<SafariUser>);

    return {...safariUser, permissions: newPermissions};
  } catch (err) {
    console.error(err);

    return null;
  }
};

export const removeUserAuthLevel = async (
  userId: string, authLevel: SafariUser["authLevel"],
): Promise<SafariUser | null> => {
  try {
    const userSnapshot = await database().ref(`Users_Master/${userId}`)
      .once("value");

    // if the user does not exist, prematurely exit
    if (!userSnapshot.exists()) return null;

    const safariUser = userSnapshot.val() as SafariUser;

    // some user objects may not contain the user details, as such, we
    // set minimum user privilleges in this case using the 1 flag.
    const userPermissions = safariUser.permissions ? safariUser.permissions : 1;

    const newPermissions = Auth.removeAuthFlag(userPermissions, authLevel);

    await database().ref(`Users_Master/${userId}`).update({
      permissions: newPermissions,
    } as Partial<SafariUser>);

    return {...safariUser, permissions: newPermissions};
  } catch (err) {
    console.error(err);

    return null;
  }
};

export const updateMessagingToken = async (userId: string): Promise<void> => {
  try {
    Notification.requestPermission()
      .then(async (val) => {
        if (val === "denied") {
          console.error("Notifiation permission denied");

          return;
        }

        const fireMessaging = messaging();

        const token = await fireMessaging.getToken({
          vapidKey: "BHspFgb7sk9QJF4_2YMJTUzBw8RNAWme3p3f0btMBKc6v1_V3L0o1Hw3QquOtFbw_uO7EuIKptbunBqze1t4kmk",
        });

        const db = database();
        await db.ref(`Users_Master/${userId}`).update({
          token,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  } catch (err) {
    console.error(err);
  }
};
