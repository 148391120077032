import {Card, Tabs} from 'antd';
import {FC, useEffect, useState} from 'react';
import {BiHotel, BiMapAlt} from 'react-icons/bi';
import {RouteComponentProps} from 'react-router-dom';

import {fetchAttraction} from '../../../../api';
import {AttractionSite} from '../../../../api/attraction/types';

import Header from '../../../index/components/Header';

import {AttractionDetails, NearbyProperties} from '../../components';

type ViewAttractionProps = RouteComponentProps<{id: string}>

export const ViewAttractionDetails: FC<ViewAttractionProps> = ({
  match,
}) => {
  const [activeTab, setActiveTab] = useState<"1" | "2">();
  const [attraction, setAttraction] = useState<AttractionSite>();

  useEffect(() => {
    fetchAttraction(match.params.id, (err, attractionSite) => {
      if (err) {
        console.error(err);

        return;
      }

      if (attractionSite) {
        setAttraction(attractionSite);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Header />
      {/* Content Container */}
      <div className="pt-16 flex flex-row justify-center">
        {/*
          Tabs will be visible for smaller displays they will however switch
          to grids on larger screens
        */}
        {attraction && (
          <>
            <Tabs
              defaultActiveKey="1"
              className="w-full sm:hidden"
              centered={true}
              renderTabBar={() => <></>}
              activeKey={activeTab}
            >
              <Tabs.TabPane
                key="1"
                tab={
                  <span className="flex flex-row items-center">
                    <BiMapAlt size={24} className="mr-3" />
                    Details
                  </span>
                }
              >
                <AttractionDetails
                  attraction={attraction}
                  swtichToNearbyTab={() => {
                    setActiveTab("2");
                  }}
                />
              </Tabs.TabPane>
    
              <Tabs.TabPane
                key="2"
                tab={
                  <span className="flex flex-row items-center">
                    <BiHotel size={24} className="mr-3" />
                    Nearby
                  </span>
                }
              >
                <NearbyProperties
                  attraction={attraction}
                  attractionId={match.params.id}
                  backToAttractionSite={() => {
                    setActiveTab("1");
                  }}
                />
              </Tabs.TabPane>
            </Tabs>

            {/* Displayed on larger displays */}
            <div className="hidden sm:flex sm:flex-row max-w-5xl">
              <AttractionDetails attraction={attraction} />
              <NearbyProperties
                attraction={attraction}
                attractionId={match.params.id}
              />
            </div>
          </>
        )}
        {!attraction && (
          <div className="p-4 w-full">
            {[1, 2, 3, 4].map((i) => (
              <Card className="mt-2" key={`skeleton_${i}`} loading={true} />
            ))}
          </div>
        )}
      </div>
    </>
  );
};
