import { Facility, Feature } from "..";

export const carFacilities: Facility[] = [
  {
    title: "Chils seat",
    description: "This car provides child seats for toddlers",
  },
  {
    title: "In-car wifi",
    description: "Free wireless connectivity inside the car",
  },
];

export const carFeatures: Feature[] = [
  {
    title: "Aux control",
    description: "The driver lets you take control of the music that will play throughout the ride",
  },
  {
    title: "Free-cancellation",
    description: "No charge is made upon cancellation",
  },
];
