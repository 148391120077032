import {useEffect, useState} from 'react';
import {FaWindowClose} from 'react-icons/fa';
import {useHistory} from 'react-router-dom';

import {fetchCompulsoryCruiseFilters} from '../../../../reducers';
import {Dropdown} from '../../../../components';

import {MobileFilterContext} from '../../mobile-filter-context';

import {SectionHeader} from "../section-header";
import {AutoCompletePlaces} from './auto-complete-places';
import {RangeInput} from './range-input';
import {SubmitButton} from './submit-button';

export function Cruises({updateState}) {
  const compulsoryFilters = fetchCompulsoryCruiseFilters();
  // const [coords, setCoords] = useState({
  //   lat: compulsoryFilters.lat, lng: compulsoryFilters.lng,
  // });
  // const [destinationCoords, setDestinationCoords] = useState({lat: 0, lng: 0});
  const [isDisabled, setIsDisabled] = useState(true);
  const history = useHistory();

  // eslint-disable-next-line no-unused-vars
  const selectAddress = (place, newCoords) => {
    // setCoords(newCoords);
  };

  // eslint-disable-next-line no-unused-vars
  const selectToAddress = (place, newCoords) => {
    // setDestinationCoords(newCoords);
  };

  const url = new URL(location.href);

  // the search values used in the search fields
  const [from, setFrom] = useState(compulsoryFilters.from);
  const [to, setTo] = useState(compulsoryFilters.to);
  const [departure, setDepature] = useState(compulsoryFilters.departure);
  const [returnDate, setReturnDate] = useState(compulsoryFilters.return);
  const [seat, setSeat] = useState(compulsoryFilters.seat);
  const [type, setType] = useState(compulsoryFilters.type);
  const [adults, setAdults] = useState(compulsoryFilters.adults);
  const [children, setChildren] = useState(compulsoryFilters.children);

  // monitor the all the stateful values so as to help determine what the value
  // of the disabled state should be.
  useEffect(() => {
    setIsDisabled(
      from === compulsoryFilters.place &&
      departure === compulsoryFilters.departure &&
      returnDate === compulsoryFilters.return &&
      adults === compulsoryFilters.adults &&
      children === compulsoryFilters.children
    );
  }, [from, departure, returnDate, adults, children, compulsoryFilters]);

  // handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({from, depature: departure, returnDate, adults, children});
    // set all the compulsory values in the url and then start linking
    url.searchParams.set("from", from);
    url.searchParams.set("to", to);
    url.searchParams.set("depature", departure);
    url.searchParams.set("return", returnDate);
    url.searchParams.set("type", type);
    url.searchParams.set("seat", seat);
    url.searchParams.set("adults", `${adults}`);
    url.searchParams.set("children", `${children}`);

    // remove all optionalFilters
    url.searchParams.delete("feature");
    url.searchParams.delete("star_rating");
    url.searchParams.delete("theme");
    url.searchParams.delete("price_lowest");
    url.searchParams.delete("price_highest");

    history.push(`${url.pathname}${url.search}`);
    updateState({searchDone: false, stale: true, url});
  };

  return (
    <div
      className={
        "elevate-xs small-search-bar z-10 relative transition-all ease-linear" +
        " duration-300 bg-gradient-to-tr from-start to-end rounded-md " +
        "mb-5 lg:sticky lg:top-0"
      }
    >
      <SectionHeader
        style={{
          borderTop: 0,
          color: "white",
          borderBottomColor: "white",
        }}
      >
        Edit Search
      </SectionHeader>
      <MobileFilterContext.Consumer>
        {({newSearchFilter, setNewSearchFilterVisible}) => (
          <div
            className="absolute top-2 right-2 md:hidden"
            onClick={() => setNewSearchFilterVisible(false)}
          >
            {newSearchFilter && (
              <FaWindowClose size={28} color="white" />
            )}
          </div>
        )}
      </MobileFilterContext.Consumer>
      <div
        style={{padding: 12}}
      >
        <form
          onSubmit={handleSubmit}
        >
          <label htmlFor="from">Where from?</label>
          <AutoCompletePlaces
            name="from"
            setPlace={setFrom}
            placeholder="Where are you flying from?"
            value={from}
            selectAddress={selectAddress}
          />
          <label htmlFor="to">Destination</label>
          <AutoCompletePlaces
            name="to"
            setPlace={setTo}
            placeholder="Where are you flying to?"
            value={to}
            selectToAddress={selectToAddress}
          />
          <RangeInput
            checkinLabel="Depature"
            checkinName="depature"
            checkinDefaultValue={departure}
            setCheckin={setDepature}
            checkoutLabel="Return"
            checkoutName="return"
            checkoutDefaultValue={returnDate}
            setCheckout={setReturnDate}
          />
          <Dropdown
            defaultValue={type}
            label="Flight Type"
            name="type"
            options={[
              {title: "One Way", value: "one-way"},
              {title: "Round Trip", value: "round-trip"},
            ]}
            onChange={(e) => setType(e.target.value)}
          />
          <Dropdown
            defaultValue={seat}
            label="Type of seat"
            name="seat"
            options={[
              {title: "Economy", value: "economy"},
              {title: "Business Class", value: "business"},
              {title: "First Class", value: "first-class"},
            ]}
            onChange={(e) => setSeat(e.target.value)}
          />
          <Dropdown
            defaultValue={adults}
            label="Number of Adults"
            name="adults"
            options={
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((adultNumber) => ({
                title: (adultNumber === 1) ?
                  `1 adult` : `${adultNumber} adults`,
                value: adultNumber,
              }))
            }
            onChange={(e) => setAdults(Number(e.target.value))}
          />
          <Dropdown
            defaultValue={children}
            label="Number of Children"
            name="children"
            options={
              [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
                .map((childrenNumber) => ({
                  title: childrenNumber !== 1 ?
                    `${childrenNumber} children` :
                    "1 child",
                  value: childrenNumber,
                }))
            }
            onChange={(e) => setChildren(Number(e.target.value))}
          />
          <SubmitButton disabled={isDisabled} url={url} />
        </form>
      </div>
    </div>
  );
}
