import {FC, MouseEventHandler, useState} from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { FiMoreVertical } from 'react-icons/fi';

export interface CardProps {
  cover: string;
  title: string;
  subtitle: string;
  description: string;
  action?: {
    label: string, onClick: MouseEventHandler<HTMLButtonElement>,
  };
  overflowActions?: CardOverflowAction[];
}

export type CardOverflowAction = {
    label: string;
    onClick: MouseEventHandler<HTMLDivElement>;
};

/**
 * This is a reusable element that can be used to display properties as they
 * have the same base properties allowing for reactoring to prevent rewriting
 * the same card container multiple times.
 *
 * `/styles/property.css` - styles are located here 
 * @returns A reusable card that can be used for all properties
 */
export const Card: FC<CardProps> = ({
  cover, description, title, subtitle, action, overflowActions,
}) => {
  const [overflowActionsVisible, setOverflowActionsVisible] =
    useState<boolean>(false);

  const overflowContainerRef = useOnclickOutside(() => {
    setOverflowActionsVisible(false);
  });

  return (
    <div className="relative w-4/5 sm:w-auto m-2">
      <div className="property-card border shadow-md">
        <div
          style={{
            backgroundImage: `url(${cover})`,
          }}
          className="property-card__cover"
          aria-label="Hotel"
        />
        <div
          className="property-card-content">
          <div className="">
            <h6 className="property-card-content__title">
              {title}
            </h6>
            <p className="property-card-content__subtitle">
              {subtitle}
            </p>
          </div>
          <div className="property-card-content__description">
            <p className="text-sm">
              {description}
            </p>
          </div>
          {action && (
            <div className="flex flex-row items-center bg-white w-full">
              <button
                onClick={action.onClick}
                className="property-card-content__button"
              >
                {action.label}
              </button>
            </div>
          )}
          {overflowActions && overflowActions.length > 0 && (
            <div className="absolute bottom-2 right-2 z-20 cursor-pointer">
              <FiMoreVertical
                className=""
                size={24}
                onClick={() => {
                  setOverflowActionsVisible(true);
                }}
              />
            </div>
          )}
        </div>
      </div>
      {overflowActions && overflowActionsVisible && (
        <div
          ref={overflowContainerRef}
          className={
            "absolute top-full right-0 w-32 shadow-lg py-2 bg-white z-30 " +
            "lg:w-auto lg:max-w-full"
          }
        >
          {overflowActions.map((overflowAction, idx) => (
            <div
              key={`${overflowAction.label}_${idx}`}
              className={
                "h-12 w-full flex flex-row items-center px-4 text-base " +
                "hover:bg-gray-700 hover:bg-opacity-30 text-xs cursor-pointer"
              }
              onClick={(e) => {
                overflowAction.onClick(e);
                setOverflowActionsVisible(false);
              }}
            >
              <p className="text-sm">
                {overflowAction.label}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
