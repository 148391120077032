import {FC, useEffect, useState} from "react";
import {BsPencil} from "react-icons/bs";
import {RiApps2Line} from "react-icons/ri";
import {RouteComponentProps, useHistory} from "react-router";

import {Activity, fetchActivity} from "../../../../api";
import { PartnerHeader } from "../../components";

import {PartnerProperty} from "../../components/property";

export const ActivityView: FC<RouteComponentProps<{id: string}>> = ({
  match,
}) => {
  const history = useHistory();

  const [activity, setActivity] = useState<Activity | null>();

  useEffect(() => {
    const {id} = match.params;

    fetchActivity(id, (err, data) => {
      if (err) {
        console.error(err);

        return;
      }

      setActivity(data);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {activity && (
        <>
          <PartnerHeader
            HeaderIcon={RiApps2Line}
            title={activity.title}
            actions={[
              {
                ActionIcon: BsPencil,
                alwaysVisible: true,
                label: "Edit Property contact information",
                type: "button",
                onClick: () => {
                  // migrate to the property edit tab
                  history.push(
                    `/property-owner/edit/activity/${activity.id}/details`
                  );
                },
              },
            ]}
          />
          <PartnerProperty
            property={activity}
            propertyType="activity"
          />
        </>
      )}
    </>
  );
}; 
