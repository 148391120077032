import type {ReactNode} from 'react';
import type {IconType} from 'react-icons';
import { Property } from '../../../api';

import {OverlayProvider} from '../context';

import {ColumnData, LeftColumn} from './left-column';
import {FilterOption} from './results-column/header';
import {Results} from './results-column/results';

interface ContainerProps<T> {
  columnData: ColumnData;
  headerTitle: string;
  loading: boolean;
  results: T[];
  HeaderIcon: IconType;
  filterOptions: FilterOption[];
  children(results: T[]): ReactNode[];
}

/**
 * This container is used to wrap all the search route components. This provides
 * easy access to overlay methos instead of having to rewrite each grid with
 * the same code.
 *
 * How it works
 * -------------
 * This container on larger screens displays two columns and on smaller screens
 * displays only one column (The `ResultsColumn`)
 * This Container wraps the results provided as children. There are two columns
 * that are displayed in this container:
 * - `LeftColumn` - this column contains the the Filters and allows for the
 * creation of new searches from the `/search` route. This column is only
 * visible on large screens (min-width: 1024px). This column also contains
 * quick filters such allow filtering the results wrapped by simple filters such
 * as price and features available.
 * - Results Column - this columns contains: `Header`, `FilterSection` and
 * `MobileFilterColumn` (this allows for the viewing of filters that were
 * initially contained on the left column as overlay provided through the
 * `OverlayProvider context`)
 * 
 * Results Column
 * --------------
 * This column wraps the provided children thus enabling the cards provided for
 * each section to be custom based on the category that is being displayed. It
 * does however abstract the header away as the Headers are similar through all
 * instances since they are going to contain the filters in the LeftColumn
 * anyway.
 * @param props container props
 * @returns Container to wrap all the search route components.
 */
export const ContainerTwo = <T extends Property>({
  children, columnData, headerTitle, loading, filterOptions, results,
  HeaderIcon, 
}: ContainerProps<T>): JSX.Element => { // eslint-disable-line
  return (
    <OverlayProvider.Consumer>
      {({setIsOverlay, setOnOverlay}) => (
        <div
          className={
            "search-container max-w-5xl w-full lg:grid grid-cols-4 gap-4 " +
            "mx-4 lg:mx-0"
          }
        >
          {/* Filter Column */}
          <LeftColumn
            columnData={columnData}
            setIsOverlay={setIsOverlay}
            setOnOverlay={setOnOverlay}
          />
          {/* SearchResults Column */}
          <Results<T>
            headerTitle={headerTitle}
            loading={loading}
            HeaderIcon={HeaderIcon}
            filterOptions={filterOptions}
            columnData={columnData}
            results={results}
            render={children}
          />
        </div>
      )}
    </OverlayProvider.Consumer>
  );
};
