import {FC} from 'react';

import {PopulatedActivityReservation} from '../../../../../api/reservation/types';
import {DateUtil} from '../../../../../util';

import {ReservationRow} from '..';

type ActivityReservationRowsProps = {
  reservation: PopulatedActivityReservation;
};

export const ActivityReservationRows: FC<ActivityReservationRowsProps> = ({
  reservation,
}) => {
  return (
    <>
      <ReservationRow
        title="Checkin"
        description={
          DateUtil.changeDateFormatFromDate(
            new Date(reservation.checkin), "DD-MM-YYYY",
          )
        }
      />

      <ReservationRow
        title="Adults"
        description={`${reservation.adults}`}
      />

      <ReservationRow
        title="Children"
        description={`${reservation.children}`}
      />
    </>
  );
};
