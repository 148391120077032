import {OnCompleteCallback, ReservationCategory} from "..";
import {Affiliate, AffiliateSummary} from "./types";

export type {Affiliate, AffiliateSummary};

export const fetchAllAffliateLinks = async (
  affilaiteId: string, onComplete: OnCompleteCallback<Affiliate[]>
): Promise<void> => {
  try {
    const response =  await fetch(
      `https://us-central1-safarixpertz.cloudfunctions.net/affiliate/all`,
      {
        method: "GET",
        headers: {
          Authorization: `bearer ${affilaiteId}`,
          "Access-Control-Allow-Origin": "*",
          "Accept": "application/json",
        },
      },
    );

    if (response.status === 200) {
      const affiliateLinkObjs = await response.json() as Affiliate[];

      onComplete(null, affiliateLinkObjs);
    } else {
      onComplete(new Error("Unable to fetch affiliate links"), null);
    }
  } catch (err) {
    console.error(err);

    onComplete(new Error("Unable to fetch affiliate links"), null);
  }
};

export const fetchAffiliateSummary = async (
  affiliateId: string, onComplete: OnCompleteCallback<AffiliateSummary>
): Promise<void> => {
  try {
    const response =  await fetch(
      `https://us-central1-safarixpertz.cloudfunctions.net/affiliate/all/summary`,
      {
        method: "GET",
        headers: {
          Authorization: `bearer ${affiliateId}`,
          "Access-Control-Allow-Origin": "*",
          "Accept": "application/json",
        },
      },
    );

    if (response.status === 200) {
      const affiliateSummary = await response.json() as AffiliateSummary;

      onComplete(null, affiliateSummary);
    } else {
      onComplete(new Error("Unable to fetch affiliate links"), null);
    }
  } catch(err) {
    console.error(err);

    onComplete(new Error("Unable to fetch populated links"), null);
  }
};

export const postFollowThrough = async (
  affiliateLinkId: string, reservationId: string,
): Promise<boolean> => {
  try {
    const response = await fetch(
      `https://us-central1-safarixpertz.cloudfunctions.net/affiliate/follow-through/${affiliateLinkId}`,
      {
        method: "PUT",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          reservation_id: reservationId,
        }),
      },
    );

    if (response.status !== 200) {
      console.error("Unable to post new follow through");

      return false;
    }

    // if the status is OK (200) it means that the update of the follow uo
    // was successful
    return true;
  } catch (err) {
    console.error(err);

    return false;
  }
};

export const createAffiliateLink = async (
  affliateId: string, category: ReservationCategory, propertyId: string,
): Promise<Affiliate | null> => {
  try {
    const response = await fetch(
      `https://us-central1-safarixpertz.cloudfunctions.net/affiliate/new`,
      {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `bearer ${affliateId}`,
        },
        body: JSON.stringify({
          property_id: propertyId,
          category,
        }),
      },
    );

    if (response.status !== 200) {
      console.error("Unable to create new affiliate link");

      return null;
    }

    const affiliateObj = await response.json() as Affiliate;

    return affiliateObj;
  } catch (err) {
    console.error(err);

    return null;
  }
};
