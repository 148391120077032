import type {FC} from 'react';

import {Picture} from '../api';
import {OverlayProvider} from '../routes/search-property/context';
import GalleryOverlay from '../routes/property/components/gallery-overlay';

type NewGalleryProps = {
  gallery: Picture[],
  disable?: boolean,
};

const PhotoGallery: FC<NewGalleryProps> = ({gallery, disable}) => {
  return (
    <OverlayProvider.Consumer>
      {({setIsOverlay, setOnOverlay}) => (
        <div
          className="h-full w-full grid grid-cols-3 grid-rows-3 sm:gap-1"
          onClick={() => {
            if (disable) return;
            // create the overlay
            setOnOverlay && setOnOverlay(() => () => (
              <GalleryOverlay
                gallery={gallery}
                removeOverlay={() => {
                  setIsOverlay && setIsOverlay(false);
                  setOnOverlay && setOnOverlay(null);
                }}
              />
            ));

            setIsOverlay && setIsOverlay(true);
          }}
        >
          <div
            className={
              "col-start-1 col-end-4 row-start-1 row-end-4 " +
              "sm:col-start-1 sm:col-end-3 sm:row-start-1 sm:row-end-3 " +
              "bg-cover bg-center bg-no-repeat"
            }
            style={{
              backgroundImage: `url(${gallery[0].url})`,
            }}
          >
          </div>
          <div
            className="hidden sm:block h-full w-full bg-cover bg-center bg-no-repeat"
            style={{
              backgroundImage: `url(${gallery[1]?.url})`,
            }}
          />
          <div
            className="hidden sm:block h-full w-full bg-cover bg-center bg-no-repeat"
            style={{
              backgroundImage: `url(${gallery[2]?.url})`,
            }}
          />
          <div
            className="hidden sm:block h-full w-full bg-cover bg-center bg-no-repeat"
            style={{
              backgroundImage: `url(${gallery[3]?.url})`,
            }}
          />
          <div
            className="hidden sm:block h-full w-full bg-cover bg-center bg-no-repeat"
            style={{
              backgroundImage: `url(${gallery[4]?.url})`,
            }}
          />
          <div
            className="hidden sm:block h-full w-full bg-cover bg-center bg-no-repeat"
            style={{
              backgroundImage: `url(${gallery[5]?.url})`,
            }}
          />
        </div>
      )}
    </OverlayProvider.Consumer>
  );
}


export default PhotoGallery;
