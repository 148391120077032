import { Facility, Feature } from "..";

export const conferenceFacilities: Facility[] = [
  {
    title: "Wifi connectivity",
    description: "Fast wireless connectivity available",
  },
  {
    title: "Audio-visual equipment",
    description: "Audio visual equipment offered inside the facility",
  },
  {
    title: "Break-out rooms",
    description: "Break out rooms come with the rental facilities",
  },
  {
    title: "Networking spaces",
    description: "Networking spaces after the conference is finished provided",
  },
  {
    title: "Parking",
    description: "Parking spaces provided in the conference facility",
  },
  {
    title: "Accessibility Parking space",
    description: "Accessibility parking spaces provided",
  },
  {
    title: "Overnight accomodation",
    description: "Overnight accomodation available for renting",
  },
  {
    title: "Artificial Lighting",
    description: "Artificial lighting present for leasing",
  },
  {
    title: "Mini-bar",
    description: "Mini bar present in the faciltiy",
  },
];

export const conferenceFeatures: Feature[] = [
  {
    title: "Floral Arrangement",
    description: "The conference facility offers floral arrangement services",
  },
  {
    title: "Pads and pens",
    description: "The conference facilities offers pads and pens for meeting purposes for each attendee",
  },
  {
    title: "Branding and signage",
    description: "The conference facility offers branding and signage for facility leasers",
  },
  {
    title: "In-house catering",
    description: "In house catering of meals and other services in session provided",
  },
  {
    title: "Special diatery requests (allowed)",
    description: "The catering team takes in special dietary requests",
  },
  {
    title: "Photography/Videography (allowed)",
    description: "Attendees are allowed to have photography and videography services",
  },
  {
    title: "Photography/Videography (not allowed)",
    description: "Attendees are not allowed to have photography and videography services",
  },
  {
    title: "IT Support",
    description: "Conference facility offers IT experts on the go to help with setting up and any presentation hiccups",
  },
];

export const conferenceRoomFacilities: Facility[] = [
  {
    title: "Wifi connectivity",
    description: "Wifi connectivity present in the conference room",
  },
  {
    title: "Artificial lightign",
    description: "Artificial lighting provided in the conference room",
  },
  {
    title: "Audio-visual equipment",
    description: "Audio visual equipment to aid in presentation present in conference room",
  },
  {
    title: "Food & Beverage",
    description: "Breakfast, Lunch and Snacks offered in between sessions on request (extra charge included)",
  },
  {
    title: "Mini-bar",
    description: "Mini-bar present",
  },
];

export const conferenceRoomFeatures: Feature[] = [
  {
    title: "IT support",
    description: "IT support provided for professional assistance during entire ocassion",
  },
  {
    title: "Catering services provided",
    description: "In-house catering services provided upon request",
  },
  {
    title: "Pads and pens",
    description: "Facility branded pads and pens offered for each attendee",
  },
  {
    title: "Floral arrangements",
    description: "Floral arrangements can be presented in the conference room upon request",
  },
  {
    title: "Decorations",
    description: "Decorations such as baloons can be added to conference room upon request",
  },
  {
    title: "Round the clock staff",
    description: "Round the clock staff available across the whole session",
  },
];
