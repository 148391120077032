import type {FC} from 'react';
import { FaTimes } from 'react-icons/fa';

import {Picture, ReservationCategory} from '../../../../api';

import {ActivityReservation} from './activty-reservation';
import {CarReservation} from './car-reservation';
import {ConferenceReservation} from './conference-reservation';
import {EateryReservation} from './eatery-reservation';
import {HotelReservation} from './hotel-reservation';
import {RentalReservation} from './rental-reservation';
import {TourReservation} from './tour-reservation';

type ExternalReservationOverlayProps = {
  title: string;
  cover: Picture;
  category: ReservationCategory;
  propertyId: string;
  removeOverlay: () => void;
};

export const ExternalReservationOverlay: FC<ExternalReservationOverlayProps> = ({
  category, propertyId, cover, title, removeOverlay,
}) => {
  return (
    <div
      className="h-full w-full sm:w-80 sm:h-auto sm:max-h-dialog shadow-lg bg-white overflow-y-scroll"
    >
      {/* Dialog header */}
      <div className="sticky top-0 h-14 w-full shadow-md bg-safari-gold text-white flex flex-row justify-between items-center px-4">
        <div className="flex flex-row items-center">
          <FaTimes size={24} onClick={removeOverlay} />
          <h6 className="text-base text-white font-semibold ml-3">Make property unavailable</h6>
        </div>
      </div>
      {/* End of dialog header */}
      <div
        className="bg-center bg-cover-bg-norepeat h-48 m-2 rounded-md"
        style={{
          backgroundImage: `url(${cover.url})`
        }}
      />
      <h6 className="text-xl font-semibold pl-4 border-b pb-2">{title}</h6>
      <div className="max-w-sm sm:max-w-full h-full mx-auto">
        {/* Dialog content based on the category */}
        {category === "activity" && (
          <ActivityReservation
            propertyId={propertyId}
            removeOverlay={removeOverlay}
          />
        )}
        {category === "car" && (
          <CarReservation
            propertyId={propertyId}
            removeOverlay={removeOverlay}
          />
        )}
        {category === "conference" && (
          <ConferenceReservation
            propertyId={propertyId}
            removeOverlay={removeOverlay}
          />
        )}
        {category === "eatery" && (
          <EateryReservation
            propertyId={propertyId}
            removeOverlay={removeOverlay}
          />
        )}
        {category === "hotel" && (
          <HotelReservation
            propertyId={propertyId}
            removeOverlay={removeOverlay}
          />
        )}
        {category === "rental" && (
          <RentalReservation
            propertyId={propertyId}
            removeOverlay={removeOverlay}
          />
        )}
        {category === "tour" && (
          <TourReservation
            removeOverlay={removeOverlay}
            propertyId={propertyId}
          />
        )}
      </div>
    </div>
  );
};
