import {useEffect, useState} from 'react';
import {FaWindowClose} from 'react-icons/fa';
import {useHistory} from 'react-router-dom';

import {fetchCompulsoryRentalFilters} from '../../../../reducers';
import {Dropdown} from '../../../../components';

import {SectionHeader} from "../section-header";

import {AutoCompletePlaces} from './auto-complete-places';
import {RangeInput} from './range-input';
import {SubmitButton} from './submit-button';
import { MobileFilterContext } from '../../mobile-filter-context';

export function Rental({updateState}) {
  const compulsoryFilters = fetchCompulsoryRentalFilters();
  const [coords, setCoords] = useState({
    lat: compulsoryFilters.lat, lng: compulsoryFilters.lng,
  });
  const [isDisabled, setIsDisabled] = useState(true);

  const history = useHistory();

  const selectAddress = (place, newCoords) => {
    setCoords(newCoords);
  };
  const url = new URL(location.href);

  // the search values used in the search fields
  const [place, setPlace] = useState(compulsoryFilters.place);
  const [checkin, setCheckin] = useState(compulsoryFilters.checkin);
  const [checkout, setCheckout] = useState(
    compulsoryFilters.checkout);
  const [adults, setAdults] = useState(compulsoryFilters.adults);
  const [children, setChildren] = useState(compulsoryFilters.children);

  // monitor the all the stateful values so as to help determine what the value
  // of the disabled state should be.
  useEffect(() => {
    setIsDisabled(
      place === compulsoryFilters.place &&
      checkin === compulsoryFilters.checkin &&
      checkout === compulsoryFilters.checkout &&
      adults === compulsoryFilters.adults &&
      children === compulsoryFilters.children
    );
  }, [place, checkin, checkout, adults, children, compulsoryFilters]);

  // handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({place, checkin, checkout, adults, children, coords});
    // set all the compulsory values in the url and then start linking
    url.searchParams.set("place", place);
    url.searchParams.set("lat", `${coords.lat}`);
    url.searchParams.set("lng", `${coords.lng}`);
    url.searchParams.set("checkin", checkin);
    url.searchParams.set("checkout", checkout);
    url.searchParams.set("adults", `${adults}`);
    url.searchParams.set("children", `${children}`);

    // remove all optionalFilters
    url.searchParams.delete("feature");
    url.searchParams.delete("star_rating");
    url.searchParams.delete("theme");
    url.searchParams.delete("price_lowest");
    url.searchParams.delete("price_highest");

    history.push(`${url.pathname}${url.search}`);
    updateState({searchDone: false, stale: true, url});
  };

  return (
    <div
      className={
        "elevate-xs small-search-bar z-10 relative transition-all ease-linear" +
        " duration-300 bg-gradient-to-tr from-start to-end rounded-md " +
        "mb-5 lg:sticky lg:top-0"
      }
    >
      <SectionHeader
        style={{
          borderTop: 0,
          color: "white",
          borderBottomColor: "white",
        }}
      >
        Edit Search
      </SectionHeader>
      <MobileFilterContext.Consumer>
        {({newSearchFilter, setNewSearchFilterVisible}) => (
          <div
            className="absolute top-2 right-2 md:hidden"
            onClick={() => setNewSearchFilterVisible(false)}
          >
            {newSearchFilter && (
              <FaWindowClose size={28} color="white" />
            )}
          </div>
        )}
      </MobileFilterContext.Consumer>
      <div
        style={{padding: 12}}
      >
        <form
          onSubmit={handleSubmit}
        >
          <label htmlFor="place">Place to visit</label>
          <AutoCompletePlaces
            name="place"
            setPlace={setPlace}
            placeholder="Where to go"
            value={place}
            selectAddress={selectAddress}
          />
          <RangeInput
            checkinLabel="Check in date"
            checkinName="checkin"
            checkinDefaultValue={checkin}
            setCheckin={setCheckin}
            checkoutLabel="Check out date"
            checkoutName="checkout"
            checkoutDefaultValue={checkout}
            setCheckout={setCheckout}
          />
          <Dropdown
            defaultValue={adults}
            label="Number of Adults"
            name="adults"
            options={
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((adultNumber) => ({
                title: (adultNumber === 1) ?
                  `1 adult` : `${adultNumber} adults`,
                value: adultNumber,
              }))
            }
            onChange={(e) => setAdults(Number(e.target.value))}
          />
          <Dropdown
            defaultValue={children}
            label="Number of Children"
            name="children"
            options={
              [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
                .map((childrenNumber) => ({
                  title: childrenNumber !== 1 ?
                    `${childrenNumber} children` :
                    "1 child",
                  value: childrenNumber,
                }))
            }
            onChange={(e) => setChildren(Number(e.target.value))}
          />
          <SubmitButton disabled={isDisabled} url={url} />
        </form>
      </div>
    </div>
  );
}
