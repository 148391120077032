import {FC} from "react";
import {Calendar} from "antd";

import {PriceChange} from "../../../../api/hotel/Offer";
import {ReservationCategory} from "../../../../api";

import {OfferDateCard} from "./offer-date-card";

type CalendarColumnProps = {
  offers: Record<string, unknown>[];
  category: ReservationCategory;
  property: Record<string, unknown>;
};

export const CalendarColumn: FC<CalendarColumnProps> = ({
  offers, category, property,
}) => {
  console.log("CalendarOffers", offers);
  return (
    <div className="p-4 sticky top-0">
      <Calendar
        dateCellRender={(date) => (
          <ul>
            {(offers)
              .filter(
                (offer) =>
                  (offer as PriceChange)
                    .startDate <= Number(date.format("x")) &&
                  (offer as PriceChange).endDate >= Number(date.format("x"))
              )
              .map((offer) => (
                <OfferDateCard
                  key={`${date.format("x")}_${offer.id}`}
                  offer={offer as PriceChange}
                  category={category}
                  property={property}
                />
              ))}
          </ul>
        )}
        monthCellRender={(date) => (
          <ul>
            {(offers)
              .filter(
                (offer) =>
                  (offer as PriceChange)
                    .startDate <= Number(date.format("x")) &&
                  (offer as PriceChange).endDate >= Number(date.format("x"))
              )
              .map((offer) => (
                <OfferDateCard
                  key={`${date.format("x")}_${offer.id}`}
                  offer={offer as PriceChange}
                  category={category}
                  property={property}
                />
              ))}
          </ul>
        )}
      />
    </div>
  );
};
