import { Coordinates, Facility, FAQ, Feature, Picture, RefundType, ReservationType } from "../../../../../api";
import { MenuItem, Table } from "../../../../../api/eatery/types";

type EateryForm = {
  title: string,
  location: string,
  coords: Coordinates;
  description: string,
  short_description: string,
  reservation_policy: ReservationType,
  refund_policy: RefundType,
  gallery: Picture[],
  facilities: Facility[],
  features: Feature[],
  faqs: FAQ[],
  tables: Table[],
  menu_items: MenuItem[];
};

type EateryFormErrors = {
  title?: string;
  location?: string;
  coords?: string;
  description?: string;
  short_description?: string;
  reservation_policy?: string;
  refund_policy?: string;
  gallery?: string;
  facilities?: string;
  features?: string;
  faqs?: string;
  tables?: string;
  menu_items?: string;
};

export const validateEateryForm = (form: EateryForm): EateryFormErrors => {
  const errors: EateryFormErrors = {};

  if (form.title === "") {
    errors.title = "This field should not be empty";
  }

  if (form.description === "") {
    errors.description = "This field should not be empty";
  }

  if (form.short_description === "") {
    errors.short_description ="This field cannot be empty";
  }

  if (form.coords.lat === 0 && form.coords.lng === 0) {
    errors.location = "Make sure to select the location form the dropdown that appears";
  }

  if (form.location === "") {
    errors.location = "This field cannnot be empty";
  }

  if (form.gallery.length < 6) {
    errors.gallery = "There must at total be at least 6 images of the eatery";
  }

  if (form.menu_items.length < 1) {
    errors.menu_items = "There must be at least one item from the eatery menu";
  }

  if (form.tables.length < 1) {
    errors.tables = "There must be at least one type of table entered";
  }

  if (form.features.length < 1) {
    errors.features = "Property must contain at least one of the features selected";
  }

  if (form.facilities.length < 1) {
    errors.facilities = "Property must contain at least one of the facilities selected";
  }

  return errors;
};
