import {Formik} from "formik";
import {FC, useState} from "react";
import {FaTimes} from "react-icons/fa";

import {Facility, Feature, ItemException, Picture, Policy} from "../../../../../api";
import {ConferenceRoom} from "../../../../../api/conference/types";
import {Input, TextArea} from "../../../../../components/input";
import { generateId } from "../../../../../util";
import {
  EditSubPropertyFacilities,
  EditSubPropertyFeatures,
  SubPropertyAddImage,
  SubPropertyEditExceptions,
  SubPropertyEditPolicies,
} from "../../../components";

import {validateConferenceOverlayForm} from "./validate-form";

type NewConferenceRoomOverlayProps = {
  removeOverlay: () => void;
  onSave: (conferenceRoom: ConferenceRoom) => void;
};

export const NewConferenceRoomOverlay: FC<NewConferenceRoomOverlayProps> = ({
  removeOverlay, onSave,
}) => {
  const [images, setImages] = useState<Picture[]>([]);
  const [features, setFeatures] = useState<Feature[]>([]);
  const [facilities, setFacilities] = useState<Facility[]>([]);
  const [policies, setPolicies] = useState<Policy[]>([]);
  const [exceptions, setExceptions] = useState<ItemException[]>([]);

  const initialValues = {
    title: "",
    description: "",
    short_description: "",
    cardinality: "",
    facilities: "",
    features: "",
    policies: "",
    price_day: "",
    price_hour: "",
    gallery: "",
    capacity: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        return validateConferenceOverlayForm({
          title: values.title,
          description: values.description,
          short_description: values.short_description,
          capacity: values.capacity,
          cardinality: values.cardinality,
          facilities,
          features,
          gallery: images,
          policies,
          price_day: values.price_day,
          price_hour: values.price_hour,
        });
      }}
      onSubmit={(values) => {
        // handle submission
        onSave({
          id: generateId(10),
          capacity: Number(values.capacity),
          cardinality: Number(values.cardinality),
          description: values.description,
          facilities,
          features,
          gallery: images,
          policies,
          exceptions,
          short_description: values.short_description,
          price: {
            day: Number(values.price_day),
            hour: Number(values.price_hour),
          },
          title: values.title,
        });
        // since the addition is done, we remove the overlay as it is no longer
        // needed
        removeOverlay();
      }}
    >
      {({
        values, errors, handleChange, handleSubmit, validateForm, setErrors,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="bg-white absolute top-0 right-0 bottom-0 w-full h-full sm:max-w-lg sm:shadow-md overflow-scroll">
            {/** App bar */}
            <div className="sticky top-0 bg-safari-gold text-white z-10 h-14 flex flex-row justify-between items-center overflow-hidden px-2 sm:col-start-1 sm:col-end-4 shadow-md">
              {/** Header Container */}
              <div className="overflow-hidden flex-grow-0 flex flex-row items-center">
                {/** Close Overlay icon */}
                <FaTimes size={24} onClick={removeOverlay} />
                <div className="ml-3">
                  <h2 className="text-xl font-semibold overflow-ellipsis whitespace-nowrap">
                    New Room
                  </h2>
                  <p className="text-xs italic overflow-ellipsis whitespace-nowrap">
                    Enter room details
                  </p>
                </div>
              </div>
              {/** End of Header container */}

              {/** Edit Button */}
              <button className="h-9 px-3 border border-black rounded-md shadow-md text-black font-semibold text-sm flex items-center">
                Save
              </button>
              {/** End of edit Button */}
            </div>
            {/** End of app bar */}

            <div className="p-4">
              {/** start of text input container */}
              <div className="">
                <Input
                  helper="Name of the room"
                  id="room-name-input"
                  inputLabel="Room name"
                  error={errors.title}
                  value={values.title}
                  onChange={handleChange}
                  name="title"
                />
              </div>
              {/** End of text input container */}

              {/** start of text input container */}
              <div className="mt-3">
                <Input
                  helper="Number of rooms of this type"
                  inputLabel="Number of rooms"
                  id="number-of-rooms-input"
                  name="cardinality"
                  value={values.cardinality}
                  error={errors.cardinality}
                  onChange={handleChange}
                />
              </div>
              {/** End of text input container */}

              {/** start of text area container */}
              <div className="mt-3">
                <TextArea
                  id="description-input"
                  helper="longform description of the room in question"
                  inputLabel="Room description (long)"
                  name="description"
                  value={values.description}
                  error={errors.description}
                  onChange={handleChange}
                />
              </div>
              {/** End of text area container */}

              {/** start of text area container */}
              <div className="mt-3">
                <TextArea
                  id="short-description-input"
                  helper="A brief description of the room"
                  inputLabel="Room description (short)"
                  name="short_description"
                  value={values.short_description}
                  error={errors.short_description}
                  onChange={handleChange}
                />
              </div>
              {/** End of text area container */}

              {/** Room Capacity Container */}
              <div className="px-4 py-2 my-2 shadow-md border rounded-md">
                <h6 className="text-xl font-semibold">Room Capacity Options</h6>
                <p className="pb-2 border-b-2 text-xs">
                  Enter capacity options based on the field
                </p>
                {/** start of text input container */}
                <div className="mt-3">
                  <Input
                    name="capacity"
                    value={values.capacity}
                    error={errors.capacity}
                    helper="How many people can this venue hold?"
                    id="capacity-input"
                    inputLabel="Venue capacity"
                    onChange={handleChange}
                  />
                </div>
                {/** End of text input container */}
              </div>
              {/** End of Room Capacity Container */}

              {/** Room Price Container */}
              <div className="px-4 py-2 my-2 shadow-md border rounded-md">
                <h6 className="text-xl font-semibold">Room Price Options</h6>
                <p className="pb-2 border-b-2 text-xs">
                  Enter price options for the room
                </p>
                {/** start of text input container */}
                <div className="mt-3">
                  <Input
                    name="price_hour"
                    value={values.price_hour}
                    error={errors.price_hour}
                    helper="How many much to rent this venue per hour"
                    id="price-per-hour-container"
                    inputLabel="Price per hour (in US Dollars)"
                    onChange={handleChange}
                  />

                  <div className="mt-2">
                    <Input
                      name="price_day"
                      value={values.price_day}
                      error={errors.price_day}
                      helper="How many much to rent this venue per day"
                      id="price-per-day-container"
                      inputLabel="Price per day (in US Dollars)"
                      onChange={handleChange}
                    />
                  </div>

                </div>
                {/** End of text input container */}
              </div>
              {/** End of Room Price Container */}

              {/** Image Addition container */}
              <SubPropertyAddImage
                images={images}
                setImages={async (updatedImages) => {
                  setImages(updatedImages);

                  setErrors(await validateForm());
                }}
                threshold={6}
              />
              {/** End of image addition container */}

              {/** Add Facilities Container */}
              <EditSubPropertyFacilities
                category="conference"
                headerSubtitle="Add the facilities contained in this conference room"
                facilities={facilities}
                setFacilities={async (updatedFacilities) => {
                  setFacilities(updatedFacilities);

                  setErrors(await validateForm());
                }}
              />
              {/** End of add facilities container */}

              {/** Features container */}
              <EditSubPropertyFeatures
                category="conference"
                features={features}
                setFeatures={async (updatedFeatures) => {
                  setFeatures(updatedFeatures);

                  setErrors(await validateForm());
                }}
              />
              {/* End of Features container */}

              {/** End of Policy container */}
              <SubPropertyEditPolicies
                policies={policies}
                setPolicies={async (updatedPolicies) => {
                  setPolicies(updatedPolicies);

                  setErrors(await validateForm());
                }}
              />
              {/** End of policy edit container */}

              {/** End of Policy container */}
              <SubPropertyEditExceptions
                exceptions={exceptions}
                setExceptions={async (updatedExceptions) => {
                  setExceptions(updatedExceptions);

                  setErrors(await validateForm());
                }}
              />
              {/** End of policy edit container */}
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};
