import {FC} from 'react';

import {BiBookContent} from "react-icons/bi";
import { FaInfoCircle } from 'react-icons/fa';
import {RiDashboardLine, RiShieldUserLine} from "react-icons/ri";
import {NavLink} from "react-router-dom";

export const SuNavRail:FC = () => {
  return (
    <ul
      className="leftSuperMenu h-full flex-grow-0 flex-shrink-0"
      style={{
        textDecoration: "none",
        width: 72,
        backgroundColor: "#00b8ff",
      }}
    >
      <li className="topItem">
        <NavLink
          to="/su/"
          className="flex flex-col items-center"
          activeClassName="active"
        >
          <RiDashboardLine className="leftMenuIcon" />{" "}
          <div className="leftMenuText">Overview</div>
        </NavLink>
      </li>
      <li className="topItem">
        <NavLink
          to="/su/content"
          className="flex flex-col items-center"
          activeClassName="active"
        >
          <BiBookContent className="leftMenuIcon" />{" "}
          <div className="leftMenuText">Content</div>
        </NavLink>
      </li>
      <li className="topItem">
        <NavLink
          to="/su/users"
          className="flex flex-col items-center"
          activeClassName="active"
        >
          <RiShieldUserLine className="leftMenuIcon" />{" "}
          <div className="leftMenuText">Users</div>
        </NavLink>
      </li>
      {/* <li className="topItem">
        <NavLink
          to="/su/wallet"
          className="flex flex-col items-center"
          activeClassName="active"
        >
          <BiWallet className="leftMenuIcon" />{" "}
          <div className="leftMenuText">Finance</div>
        </NavLink>
      </li> */}
      <li className="topItem">
        <NavLink
          to="/su/about"
          className="flex flex-col items-center"
          activeClassName="active"
        >
          <FaInfoCircle className="leftMenuIcon" />{" "}
          <div className="leftMenuText">Info</div>
        </NavLink>
      </li>
    </ul>
  );
};
