import {FC} from 'react';

import {Facility} from '../../../api';

import {OverlayProvider} from '../../search-property/context';

import {EditFacilities} from '.';
import { FaPlusCircle } from 'react-icons/fa';

type EditFacilitiesListItemProps = {
  category: "hotel" | "flight" | "rental" | "cruise" | "eatery" | "conference" |
    "car" | "tour" | "activity";
  facilities: Facility[];
  error?: string;
  onSave: (newFacilities: Facility[]) => void;
};

export const EditFacilitiesListItem: FC<EditFacilitiesListItemProps> = ({
  category, facilities, error, onSave,
}) => {
  return (
    <OverlayProvider.Consumer>
      {({setIsOverlay, setOnOverlay}) => (
        <div className="py-2">
          <div
            className={
              "border flex flex-row justify-between shadow-md " +
              "px-4 py-2 items-center cursor-pointer"
            }
            onClick={() => {
              // set Overlay
              setOnOverlay && setOnOverlay(() => () => (
                <EditFacilities
                  onSave={onSave}
                  category={category}
                  facilities={facilities}
                  removeOverlay={() => {
                    setOnOverlay && setOnOverlay(null);
                    setIsOverlay && setIsOverlay(false);
                  }}
                />
              ));

              setIsOverlay && setIsOverlay(true);
            }}
          >
            <div>
              <h6 className="text-xl font-semibold">
                Property Facilities
              </h6>
              <p className="text-xs opacity-70">
                {`Press to edit the facilities contained in the ${category}`}
              </p>
            </div>
            {/* <div className="h-6 w-6 bg-indigo-300"></div> */}
            <FaPlusCircle size={24} color="#FF5900" />
          </div>
          {error && (
            <p className="mt-1 text-xs text-red-500 pl-2">
              Must have at least one facility
            </p>
          )}
        </div>
      )}
    </OverlayProvider.Consumer>
  );
};
