import {firestore} from 'firebase';

import {ApiFunc, Coordinates, OnCompleteCallback, Picture} from '..';
import {uploadPictures} from '../cdn';
import {getUser, NoUserFoundError} from '../user';

import type {Conference} from './types';
export type {Conference} from './types';

export const fetchConference = async (
  id: string, onFetch: (err: Error | null, data: Conference | null) => void,
): Promise<void> => {
  console.log(`Fetching conference with id: ${id}`)

  try {
    const snapshot = await firestore().collection("conference").doc(id).get();
    onFetch(null, {id: snapshot.id, ...snapshot.data()} as Conference)
  } catch (err) {
    const fetchError = new Error("An error occured while fetching conference");
    onFetch(fetchError, null);
  }
};

export const fetchAvailabeConferenceRooms = async (
  conferenceId: string, checkin: number,
  onComplete: OnCompleteCallback<Conference>,
): Promise<void> => {
  try {
    const response = await fetch(
      "https://us-central1-safarixpertz.cloudfunctions.net/fetch_available_conference_rooms",
      {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          conferenceId,
          checkin,
        }),
      },
    );

    if (response.status === 200) {
      const data = (await response.json()) as Conference;

      onComplete(null, data);
    } else {
      const fetchError = new Error("Error fetching conference facilities");
      onComplete(fetchError, null);
    }
  } catch (err) {
    const fetchErr = new Error("An error occured while fetching");
    onComplete(fetchErr, null);
  }
};

export const fetchConferences: ApiFunc<Conference[]> = async (onComplete) => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);
    return;
  }

  try {
    const snapshot = await firestore().collection("conference")
      .where("owner", "==", user.uid)
      .get();
    const {docs} = snapshot;
    const conferences: Conference[] = [];

    docs.forEach((doc) => {
      conferences.push({id: doc.id, ...doc.data()} as Conference);
    });

    onComplete(null, conferences);
  } catch (err) {
    const fetchErr = new Error("An error occured while fetching");
    onComplete(fetchErr, null);
  }
};

export const filterConferences = async (
  location: string, coords: Coordinates, checkin: number,
  onComplete: OnCompleteCallback<Conference[]>,
): Promise<void> => {
  try {
    const response = await fetch(
      "https://us-central1-safarixpertz.cloudfunctions.net/fetch_conference",
      {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          location,
          coords,
          checkin,
        }),
      },
    );

    if (response.status === 200) {
      const conferences = (await response.json()) as Conference[];

      onComplete(null, conferences);
    } else {
      const fetchError = new Error("Error fetching conferences");
      onComplete(fetchError, null);
    }
  } catch (err) {
    const fetchErr = new Error("An error occured while fetching");
    onComplete(fetchErr, null);
  }
};

export const postConference = (
  conference: Conference, onComplete: OnCompleteCallback<Conference>,
): void => {
  const user = getUser();

  if(!user) {
    onComplete(new NoUserFoundError(), null);
    return;
  }

  // we fetch all the pictures from the conference facility itself, and also all
  // the pictues from the rooms themselves.
  // We then create an array (Buffer) that records how many things we are
  // uploading for, and this will be used to get back all the pictures in order
  // of so as to re-order them back to their respective places without a hassle.
  //
  // E.g if we have a conference facility with 6 pictures of its own and three
  // conference rooms with pictures of their own, then we can create the buffer
  // as follows:
  // buffer = [6, 6, 7, 8];
  // then an array of pictures to upload is created with a total of
  // (6 + 6 + 7 + 8) images, where:
  // they are ordered based on:
  // conference facility first, rooms based on the index of the room,
  // As such, these pictures can be gotten back by splicing and then pushing
  // back in order of index starting with the conference facility as the first
  // index (0)

  const bufferSizes: number[] = [];
  const imageBuffer: Picture[] = [];

  bufferSizes.push(conference.gallery.length);
  imageBuffer.push(...conference.gallery);

  // push all the pictures for each room
  conference.rooms.forEach((room) => {
    bufferSizes.push(room.gallery.length);
    imageBuffer.push(...room.gallery);
  });


  // after all the images are pushed we can upload them all and then replace
  // after successful upload
  uploadPictures("conference", imageBuffer, async (err, uploadedImages) => {
    if (err) {
      onComplete(err, null);
      return;
    }

    if (uploadedImages) {
      conference.gallery = uploadedImages.splice(0, bufferSizes[0]);

      // starting from the second index, we start assigning the rooms all their
      // images back
      for (let i=1; i<bufferSizes.length; i++) {
        conference.rooms[i - 1].gallery = uploadedImages.splice(0, bufferSizes[i]);
      }

      // after all the images are uploaded we can now get to uploading the
      // confernce data
      try {
        const doc =  await firestore()
          .collection("conference")
          .add({...conference, owner: user.uid} as Conference);

        onComplete(null, (await doc.get()).data() as Conference);
      } catch (err) {
        const conferenceUploadError =
          new Error("Error uploading conference facility details");
        onComplete(conferenceUploadError, null);
      }
      
    } else {
      // these images may be null
      const uploadErr = new Error("The images to be uploaded returned null");
      onComplete(uploadErr, null);
    }
  });
};

export const updateConference = (
  conferenceId: string, conference: Conference,
  onComplete: OnCompleteCallback<Conference>,
): void => {
  const user = getUser();

  if(!user) {
    onComplete(new NoUserFoundError(), null);
    return;
  }

  // we fetch all the pictures from the conference facility itself, and also all
  // the pictues from the rooms themselves.
  // We then create an array (Buffer) that records how many things we are
  // uploading for, and this will be used to get back all the pictures in order
  // of so as to re-order them back to their respective places without a hassle.
  //
  // E.g if we have a conference facility with 6 pictures of its own and three
  // conference rooms with pictures of their own, then we can create the buffer
  // as follows:
  // buffer = [6, 6, 7, 8];
  // then an array of pictures to upload is created with a total of
  // (6 + 6 + 7 + 8) images, where:
  // they are ordered based on:
  // conference facility first, rooms based on the index of the room,
  // As such, these pictures can be gotten back by splicing and then pushing
  // back in order of index starting with the conference facility as the first
  // index (0)

  const bufferSizes: number[] = [];
  const imageBuffer: Picture[] = [];

  bufferSizes.push(conference.gallery.length);
  imageBuffer.push(...conference.gallery);

  // push all the pictures for each room
  conference.rooms.forEach((room) => {
    bufferSizes.push(room.gallery.length);
    imageBuffer.push(...room.gallery);
  });


  // after all the images are pushed we can upload them all and then replace
  // after successful upload
  uploadPictures("conference", imageBuffer, async (err, uploadedImages) => {
    if (err) {
      onComplete(err, null);
      return;
    }

    if (uploadedImages) {
      conference.gallery = uploadedImages.splice(0, bufferSizes[0]);

      // starting from the second index, we start assigning the rooms all their
      // images back
      for (let i=1; i<bufferSizes.length; i++) {
        conference.rooms[i - 1].gallery = uploadedImages.splice(0, bufferSizes[i]);
      }

      // after all the images are uploaded we can now get to uploading the
      // confernce data
      try {
        await firestore()
          .collection("conference")
          .doc(conferenceId)
          .update({...conference} as Conference);
        
        const doc = firestore().collection("conference")
          .doc(conferenceId)

        onComplete(null, (await doc.get()).data() as Conference);
      } catch (err) {
        const conferenceUploadError =
          new Error("Error uploading conference facility details");
        onComplete(conferenceUploadError, null);
      }
      
    } else {
      // these images may be null
      const uploadErr = new Error("The images to be uploaded returned null");
      onComplete(uploadErr, null);
    }
  });
};
