import {Carousel} from 'antd';
import {FC, useContext, useState} from 'react';
import { Link } from 'react-router-dom';

import {createAffiliateLink, Picture, ReservationCategory} from '../../../api';
import {TextInput} from '../../../components/input/text-input';
import {Button} from '../../../components';
import {SnackbarProvider} from '../../../context/snackbar-provider';
import { OverlayProvider } from '../../../context';
import useOnclickOutside from 'react-cool-onclickoutside';

type AffiliateOverlayProps = {
  property: {gallery: Picture[]; title: string; id: string}
  category: ReservationCategory;
  affiliateId: string;
};

export const AffiliateOverlay: FC<AffiliateOverlayProps> = ({
  property, category, affiliateId,
}) => {
  const {setIsVisible, dismissSnackbar} = useContext(SnackbarProvider);
  const {
    setIsOverlay, setOnOverlay, setIsCancellable,
  } = useContext(OverlayProvider);

  const containerRef = useOnclickOutside(() => {
    setIsOverlay && setIsOverlay(false);
    setOnOverlay && setOnOverlay(null);
    setIsCancellable && setIsCancellable(true);
  });

  const [shareableLink, setShareableLink] = useState<string>("");

  return (
    <div ref={containerRef}>
      <div className="bg-white rounded-xl overflow-hidden shadow-md">
        <div className="h-48 w-96">
          <Carousel dotPosition="bottom" autoplay>
            {property.gallery.map((pic) => (
              <div
                key={pic.url}
                style={{height: 192, backgroundImage: `url(${pic.url})`}}
              >
                <div
                  className="h-48 bg-center bg-cover bg-no-repeat"
                  style={{height: 192, backgroundImage: `url(${pic.url})`}}
                >
                </div>
              </div>
            ))}
          </Carousel>
        </div>
        <div className="p-4">
          <h3 className="text-2xl font-semibold">{property.title}</h3>
          <hr className="my-3" />
          <p>Create an affiliate link for the property</p>
          <p className="mt-3">
            <Link to="/info/affiliate" className="underline text-blue-600">
              Read more
            </Link>
            {" "}about our affiliate program
          </p>
        </div>
      </div>
      <div className="bg-white p-4 mt-4 rounded-xl flex flex-row items-center justify-between">
        <TextInput
          helper="Copy this link and spread to start earning"
          id=""
          inputLabel="Shareable link"
          name="shareable-link"
          onChange={() => console.log("Should not share")}
          value={shareableLink}
          disabled={shareableLink === ""}
        />
        <Button
          className="text-white"
          type="button"
          onClick={async () => {
            // first confirm that a link has not been created first
            // if not create a link
            if (!shareableLink) {
              const affiliateObj = await createAffiliateLink(
                affiliateId, category, property.id,
              );

              if (!affiliateObj) {
                setIsVisible && setIsVisible({
                  fabPresent: false,
                  isError: true,
                  navRailPresent: false,
                  title: "Affiliate link not created",
                  action: {
                    label: "DISMISS",
                    onClick: () => dismissSnackbar && dismissSnackbar()
                  },
                });

                return;
              }

              setShareableLink(
                `https://us-central1-safarixpertz.cloudfunctions.net/affiliate/impression/${
                  affiliateObj
                }`);

              setIsVisible && setIsVisible({
                fabPresent: false,
                isError: false,
                navRailPresent: false,
                title: "Affiliate link created",
                action: {
                  label: "DISMISS",
                  onClick: () => dismissSnackbar && dismissSnackbar()
                },
              });
            } else {
              // copy to the clipboard
              navigator.clipboard.writeText(shareableLink);

              setIsVisible && setIsVisible({
                fabPresent: false,
                isError: false,
                navRailPresent: false,
                title: "Link copied to clipboard",
                action: {
                  label: "DISMISS",
                  onClick: () => dismissSnackbar && dismissSnackbar()
                },
              });
            }
          }}
        >
          {shareableLink === "" && (
            <h6 className="text-white">Create Link</h6>
          )}
          {shareableLink !== "" && (
            <h6 className="text-white">Copy Link</h6>
          )}
        </Button>
      </div>
    </div>
  );
};

