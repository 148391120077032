// @ts-check
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import { FaMapMarkerAlt } from "react-icons/fa";

/**
 * This components with aid of the search input, takes in user input and renders suggestions
 * of possible locations using the Places API provided by Google.
 *
 * @param {{
 * name: string,
 * value: string,
 * placeholder: string,
 * selectAddress: (place: any, latLng: {lat: number, lng: number}) => void,
 * setPlace: (place: string) => void,
 * }} props props taken in by the component
 *
 * @returns {JSX.Element}
 */
export function AutoCompletePlaces({
  name, placeholder, value: inputValue, setPlace, selectAddress,
}) {
  const {
    ready, value, suggestions: {status, data},
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {types: ["(cities)"]},
    defaultValue: inputValue,
    debounce: 300,
  });

  const suggestionsRef = useOnclickOutside(() => clearSuggestions());

  const handleInput = (e) => {
    setValue(e.target.value);
    setPlace(e.target.value);
  };

  const handleSelect = ({description}) => {
    setValue(description, false);
    setPlace(description);
    clearSuggestions();

    getGeocode({address: description})
    .then((results) => getLatLng(results[0]))
    .then(({lat, lng}) => {
      selectAddress(description, {lat, lng});
    })
    .catch((error) => console.error(error));
  };


  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id: id,
        structured_formatting: {main_text, secondary_text},
      } = suggestion;

      return (
        <li
          key={id}
          onClick={() => handleSelect(suggestion)}
          style={{
            listStyleType: "none",
            borderBottom: "1px solid #ccc",
            paddingLeft: 8,
            paddingRight: 8,
            cursor: "pointer",
          }}
        >
          <strong>{main_text}</strong>
          <br />
          <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div
      style={{
        // border: "1px solid #ccc",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "white",
        borderRadius: 2,
        position: "relative",
      }}
    >
      <FaMapMarkerAlt
        color="rgb(7, 160, 220)"
        style={{
          marginLeft: 12,
          marginRight: 4,
        }}
      />
      <div>
        <input
          disabled={!ready}
          name={name}
          onChange={handleInput}
          placeholder={placeholder}
          value={value}
          className="border-0 p-2 w-full"
        />
        <div
          className="elevate-xs"
          ref={suggestionsRef}
          style={{
            position: "absolute",
            backgroundColor: "white",
            zIndex: 10,
            width: "80%",
          }}
        >
          {status === "OK" && (
            <ul
              style={{
                marginLeft: 0,
                paddingInlineStart: 0,
                width: "100%",
              }}
            >
              {renderSuggestions()}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}
