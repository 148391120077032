import { Facility, Feature } from "..";

export const rentalFacilities: Facility[] = [
  {
    title: "Smart TV",
    description: "Smart TV to view whatever channel you'd want",
  },
  {
    title: "Wi-Fi",
    description: "Fast Wireless connectivity from anywhere in the rental",
  },
  {
    title: "IDD Telephone",
    description: "Telephone available to make calls",
  },
  {
    title: "Air conditioning",
    description: "Air conditioning present all over the rental",
  },
  {
    title: "Mini-bar",
    description: "Minibar present inside the rental",
  },
  {
    title: "Swimming pool",
    description: "This rental has a swimming pool present",
  },
];

export const rentalFeatures: Feature[] = [
  {
    title: "Free parking",
    description: "Parking spaces available in the rental",
  },
  {
    title: "Safety & Security",
    description: "Round the clock security available in this rental",
  },
  {
    title: "Competitive pricing",
    description: "Rental offers the most affordable rates in the market",
  },
];

export const rentalRoomFacilities: Facility[] = [
  {
    title: "Bathroom & Slippers",
    description: "Bathroom, slippers and fresh towels offered",
  },
  {
    title: "In-room bathroom & toilet",
    description: "Bathroom and toilet available inside this room",
  },
  {
    title: "Universal plug-in power sockets",
    description: "Sockets are available inside the room for your chargers and other appliances",
  },
  {
    title: "King-sized bed",
    description: "This rental room has a king-sized bed inside it",
  },
  {
    title: "Queen-sized bed",
    description: "This rental has a queen sized bed inside it",
  },
];

export const rentalRoomFeatures: Feature[] = [
  {
    title: "Balcony",
    description: "This rental room has a balcony present",
  },
  {
    title: "Wheel-chair accessible",
    description: "This rental room is wheel chair accessible",
  },
];
