// @ts-check

////////////////////////////////////////////////////////////////////////////////
// JSDoc Types
////////////////////////////////////////////////////////////////////////////////

/**
 * @typedef {import('.').Review} Review
 *
 * @typedef {{
 * }} Facility
 *
 * @typedef {{
 * }} Feature
 *
 * @typedef {{
 * title: string,
 * origin: string,
 * destination: string,
 * startDate: number,
 * endDate: number,
 * description: string,
 * long_description: string,
 * company: string
 * review: Review[],
 * facilities: Array<Facility>,
 * features: Array<Feature>,
 * price: number,
 * }} Tour
 */

/**@type {Tour[]} */
export const tours = [
  {
    title: "Trip to Maasai Mara",
    origin: "Nairobi, Kenya",
    destination: "Maasai Mara, Kenya",
    startDate: Date.now(),
    endDate: Date.now() + Math.floor(Math.random() * 1000),
    company: "Bountiful Safaris",
    description: "Integer eleifend ante ut nunc condimentum, sed bibendum " +
      "magna feugiat. Vivamus magna nulla, scelerisque ut nisl vitae, tempor " +
      " tempus urna. Phasellus condimentum tempus ultrices. Ut sed lectus" +
      " rhoncus, laoreet leo.",
    facilities: [],
    features: [],
    long_description: "Lorem ipsum dolor sit amet, consectetur adipiscing" +
      " elit. Praesent molestie dui purus, in blandit eros euismod in. Fusce" +
      " faucibus felis ante, eget porttitor diam congue quis. Sed posuere" +
      " eget nisi at viverra. Aliquam non sem eu nulla finibus suscipit nec" +
      " et libero. Orci varius natoque penatibus et magnis dis parturient" +
      " montes, nascetur ridiculus mus. Curabitur sed eros pharetra, aliquet" +
      " mi at, porta dui. Mauris eget viverra neque, vitae placerat magna",
    review: [],
    price: 800,
  },
  {
    title: "Trip to Maasai Mara",
    origin: "Nairobi, Kenya",
    destination: "Maasai Mara, Kenya",
    startDate: Date.now(),
    endDate: Date.now() + Math.floor(Math.random() * 1000),
    company: "Bountiful Safaris",
    description: "Integer eleifend ante ut nunc condimentum, sed bibendum " +
      "magna feugiat. Vivamus magna nulla, scelerisque ut nisl vitae, tempor " +
      " tempus urna. Phasellus condimentum tempus ultrices. Ut sed lectus" +
      " rhoncus, laoreet leo.",
    facilities: [],
    features: [],
    long_description: "Lorem ipsum dolor sit amet, consectetur adipiscing" +
      " elit. Praesent molestie dui purus, in blandit eros euismod in. Fusce" +
      " faucibus felis ante, eget porttitor diam congue quis. Sed posuere" +
      " eget nisi at viverra. Aliquam non sem eu nulla finibus suscipit nec" +
      " et libero. Orci varius natoque penatibus et magnis dis parturient" +
      " montes, nascetur ridiculus mus. Curabitur sed eros pharetra, aliquet" +
      " mi at, porta dui. Mauris eget viverra neque, vitae placerat magna",
    review: [],
    price: 500,
  },
  {
    title: "Trip to Maasai Mara",
    origin: "Nairobi, Kenya",
    destination: "Maasai Mara, Kenya",
    startDate: Date.now(),
    endDate: Date.now() + Math.floor(Math.random() * 1000),
    company: "Bountiful Safaris",
    description: "Integer eleifend ante ut nunc condimentum, sed bibendum " +
      "magna feugiat. Vivamus magna nulla, scelerisque ut nisl vitae, tempor " +
      " tempus urna. Phasellus condimentum tempus ultrices. Ut sed lectus" +
      " rhoncus, laoreet leo.",
    facilities: [],
    features: [],
    long_description: "Lorem ipsum dolor sit amet, consectetur adipiscing" +
      " elit. Praesent molestie dui purus, in blandit eros euismod in. Fusce" +
      " faucibus felis ante, eget porttitor diam congue quis. Sed posuere" +
      " eget nisi at viverra. Aliquam non sem eu nulla finibus suscipit nec" +
      " et libero. Orci varius natoque penatibus et magnis dis parturient" +
      " montes, nascetur ridiculus mus. Curabitur sed eros pharetra, aliquet" +
      " mi at, porta dui. Mauris eget viverra neque, vitae placerat magna",
    review: [],
    price: 399,
  },
  {
    title: "Trip to Hell's Gate",
    origin: "Nairobi, Kenya",
    destination: "Naivasha, Kenya",
    startDate: Date.now(),
    endDate: Date.now() + Math.floor(Math.random() * 1000),
    company: "Bountiful Safaris",
    description: "Integer eleifend ante ut nunc condimentum, sed bibendum " +
      "magna feugiat. Vivamus magna nulla, scelerisque ut nisl vitae, tempor " +
      " tempus urna. Phasellus condimentum tempus ultrices. Ut sed lectus" +
      " rhoncus, laoreet leo.",
    facilities: [],
    features: [],
    long_description: "Lorem ipsum dolor sit amet, consectetur adipiscing" +
      " elit. Praesent molestie dui purus, in blandit eros euismod in. Fusce" +
      " faucibus felis ante, eget porttitor diam congue quis. Sed posuere" +
      " eget nisi at viverra. Aliquam non sem eu nulla finibus suscipit nec" +
      " et libero. Orci varius natoque penatibus et magnis dis parturient" +
      " montes, nascetur ridiculus mus. Curabitur sed eros pharetra, aliquet" +
      " mi at, porta dui. Mauris eget viverra neque, vitae placerat magna",
    review: [],
    price: 233,
  },
  {
    title: "Trip to Crying Stone",
    origin: "Nairobi, Kenya",
    destination: "Illesi, Kenya",
    startDate: Date.now(),
    endDate: Date.now() + Math.floor(Math.random() * 1000),
    company: "Bountiful Safaris",
    description: "Integer eleifend ante ut nunc condimentum, sed bibendum " +
      "magna feugiat. Vivamus magna nulla, scelerisque ut nisl vitae, tempor " +
      " tempus urna. Phasellus condimentum tempus ultrices. Ut sed lectus" +
      " rhoncus, laoreet leo.",
    facilities: [],
    features: [],
    long_description: "Lorem ipsum dolor sit amet, consectetur adipiscing" +
      " elit. Praesent molestie dui purus, in blandit eros euismod in. Fusce" +
      " faucibus felis ante, eget porttitor diam congue quis. Sed posuere" +
      " eget nisi at viverra. Aliquam non sem eu nulla finibus suscipit nec" +
      " et libero. Orci varius natoque penatibus et magnis dis parturient" +
      " montes, nascetur ridiculus mus. Curabitur sed eros pharetra, aliquet" +
      " mi at, porta dui. Mauris eget viverra neque, vitae placerat magna",
    review: [],
    price: 300,
  },
];
