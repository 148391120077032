import {changeDateFormat, getNumberOfDays} from "../date-util";

/**
 * Calculates the total price for the stay in the hotel room based on the
 * details passed to the function.
 *
 * @param pricePerNight this is the cost of renting out the room for the night
 * @param numberOfRooms these are the number of rooms the user hopes to rent out
 * @param checking this the date the user wants to check in.
 * @param checkout this is the date that the user wants to checkout
 * @returns The total price of the stay
 */
export const calculateHotelPrice = (
  pricePerNight: number, numberOfRooms: number , checking: string,
  checkout: string,
): number => {
  // seperate rooms using both adults and children sharing the rooms
  const numberOfDays = getNumberOfDays(
    new Date(changeDateFormat(checking, "DD-MM-YYYY", "MM-DD-YYYY")),
    new Date(changeDateFormat(checkout, "DD-MM-YYYY", "MM-DD-YYYY")),
  );

  const price = (numberOfDays * pricePerNight * numberOfRooms);

  return price;
};
