import {FC} from 'react';
import {FaPlusCircle} from "react-icons/fa";
import { EditPolicy } from '.';

import {RefundType} from '../../../api';
import { OverlayProvider } from '../../search-property/context';

type EditRefundPolicyListItemProps = {
  refundPolicy: RefundType;
  onSave: (refundPolicy: RefundType) => void;
};

export const EditRefundPolicyListItem: FC<EditRefundPolicyListItemProps> =
  ({refundPolicy, onSave}) => {
  const refundPolicies = {
    partial_refund: "Amount refundable save for cancellation fee",
    no_refund: "Amount is not refundable",
    full_refund: "Whole amount is refundable",
  };

  return (
    <OverlayProvider.Consumer>
      {({setIsOverlay, setOnOverlay}) => (
        <div
          className={
            "mt-4 px-4 p-2 shadow-md flex flex-col justify-center " +
            "bg-secondary rounded-md text-white cursor-pointer"
          }
          onClick={() => {
            // set on Overlay
            setOnOverlay && setOnOverlay(() => () => (
              <EditPolicy
                refundPolicy={refundPolicy}
                onSave={({refundPolicy}) => {
                  refundPolicy && onSave(refundPolicy)
                }}
                removeOverlay={() => {
                  setOnOverlay(null);
                  setIsOverlay && setIsOverlay(false);
                }}
              />
            ));

            setIsOverlay && setIsOverlay(true);
          }}
        >
          <div className="flex flex-row items-center justify-between w-full">
            <div>
              <h6 className="font-semibold">Refund Policy</h6>
              <p className="text-sm opacity-70">
                {refundPolicies[refundPolicy]}
              </p>
            </div>
            <FaPlusCircle color="#FF5900" size={24} />
          </div>
          <hr className="mt-4 bg-white border-white" />
          <p className="text-xs mt-1">
            Press to pick a refund policy
          </p>
        </div>
      )}
    </OverlayProvider.Consumer>
  );
};
