import {FC} from "react";
import {NavLink} from "react-router-dom";

import {Info} from "../../../api";

type InfoNavDrawerProps = {
  routes: Info[];
};

export const InfoNavDrawer: FC<InfoNavDrawerProps> = ({
  routes,
}) => {
  console.log("routes", routes);
  return (
    <div
      className="hidden sm:block h-full w-64 py-2 sticky"
      style={{
        top: 64,
      }}
    >
      {routes.map((link) => (
        <NavLink
          key={link.id}
          className="info-nav-bar"
          activeClassName="info-nav-bar__active"
          to={`/info/${link.id}`}
        >
          <div className="w-full hover:bg-safari-gold hover:text-white transition-all duration-500 h-12 flex flex-row items-center mx-2 rounded-md px-4">
            {link.title}
          </div>
        </NavLink>
      ))}
    </div>
  );
};
