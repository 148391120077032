import {FC} from 'react';
import { ReservationRow } from '..';
import {PopulatedRentalReservation} from '../../../../../api/reservation/types';
import { DateUtil } from '../../../../../util';

type RentalReservationRowsProps = {
  reservation: PopulatedRentalReservation;
};

export const RentalReservationRows: FC<RentalReservationRowsProps> = ({
  reservation,
}) => {
  return (
    <>
      <ReservationRow
        title="Start date"
        description={
          DateUtil.getHumanReadableFormat(
            new Date(reservation.checkin),
          )
        }
      />

      {reservation.checkout && (
        <ReservationRow
          title="End date"
          description={
            DateUtil.getHumanReadableFormat(
              new Date(reservation.checkout),
            )
          }
        />
      )}
    </>
  );
};
