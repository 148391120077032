import {FC, useEffect, useState} from "react";
import {BsPencil} from "react-icons/bs";
import {RiHotelLine} from "react-icons/ri";
import {RouteComponentProps, useHistory} from "react-router";

import {fetchRental, Rental} from "../../../../api";

import {OverlayProvider} from "../../../search-property/context";

import {PartnerHeader} from "../../components";
import {PartnerProperty} from "../../components/property";
import {RoomOverlay} from "../../components/room-overlay";

export const RentalView: FC<RouteComponentProps<{id: string}>> = ({
  match,
}) => {
  const history = useHistory();

  const [rental, setRental] = useState<Rental | null>(null);

  useEffect(() => {
    fetchRental(match.params.id, (err, data) => {
      if (err) {
        console.error(err);

        return;
      }

      setRental(data);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {rental && (
        <>
          <PartnerHeader
            HeaderIcon={RiHotelLine}
            title={rental.title}
            actions={[
              {
                ActionIcon: BsPencil,
                alwaysVisible: true,
                label: "Edit Property contact information",
                type: "button",
                onClick: () => {
                  // migrate to the property edit tab
                  history.push(
                    `/property-owner/edit/rental/${rental.id}/details`
                  );
                },
              },
            ]}
          />
          <OverlayProvider.Consumer>
            {({setOnOverlay, setIsOverlay}) => (
              <PartnerProperty
                property={rental}
                propertyType="rental"
                subProperties={rental.rooms.map((room) => ({
                  cover: room.gallery[0].url,
                  description: room.description,
                  subtitle: rental.location,
                  title: room.title,
                  action: {
                    label: "View Rental",
                    onClick: () => {
                      setOnOverlay && setOnOverlay(() => () => (
                        <RoomOverlay
                          gallery={room.gallery}
                          onRoomEdit={() => console.log("Editing room")}
                          capacity={{
                            adults:
                              room.capacity.adults_only ?
                                room.capacity.adults_only :
                                1,
                            children:
                              room.capacity.children_only ?
                                room.capacity.children_only :
                                1,
                          }}
                          description={room.description}
                          facilities={room.facilities}
                          features={room.features}
                          policies={room.room_policy}
                          removeOverlay={() => {
                            setIsOverlay && setIsOverlay(false);
                            setOnOverlay(null);
                          }}
                          title={room.title}
                          coords={rental.coords}
                        />
                      ));
                    },
                  },
                }))}
                subPropertySectionTitle="Room"
              />
            )}
          </OverlayProvider.Consumer>
        </>
      )}
    </>
  );
};
