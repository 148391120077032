import {Rental} from "../../api";

import {changeDateFormat, getNumberOfDays} from "../date-util";

/**
 * Calculates the price of the whole stay based on the details that have
 * been input about the rental and the stay.
 *
 * @param rental This is the rental property he user wants to lease
 * @param checkin this is the day the user plans on checking in
 * @param checkout this is the day the user plans on checking out
 *
 * @returns The price of the entire stay based on the paremeters passed
 */
export const calculateRentalPrice = (
  rental: Rental, checkin: string, checkout: string,
): number => {
  let price = 0;

  const numberOfDays = getNumberOfDays(
    new Date(changeDateFormat(checkin, "DD-MM-YYYY", "MM-DD-YYYY")),
    new Date(changeDateFormat(checkout, "DD-MM-YYYY", "MM-DD-YYYY")),
  );

  price = numberOfDays * rental.price;

  return price;
};
