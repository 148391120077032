import {FC, useEffect, useState} from 'react';
import {Route, RouteComponentProps, Switch} from 'react-router';

import {fetchInfo, Info as InfoType} from '../../api';
import {Header} from "../../components"
import ActivityIndicator from '../../components/loading';

import {AppFooter} from "../index/components/app-footer"

import {InfoNavDrawer, Renderer} from './components';
import {NotFound} from './routes';

export const Info: FC<RouteComponentProps> = ({match}) => {
  const [infoRoutes, setInfoRoutes] = useState<InfoType[]>([]);
  const [fetching, setIsFetching] = useState<boolean>(true);

  useEffect(() => {
    fetchInfo((err, result) => {
      if (err) {
        console.error(err);

        return;
      }

      if (result) {
        setIsFetching(false);
        setInfoRoutes(result);
      }
    });
  }, []);

  return (
    <>
      <Header.LightHeader />
      {fetching && (
        <div className="flex flex-row w-full h-96 items-center justify-center">
          <ActivityIndicator
            caption="..."
          />
        </div>
      )}
      {!fetching && (
        <div
          className={
            "w-full flex flex-row mx-auto md:max-w-2xl " +
            "lg:max-w-2xl xl:max-w-5xl"
          }
        >
          <InfoNavDrawer routes={infoRoutes} />
          <Switch>
            {infoRoutes.map((route) => (
              <Route
                key={`${route.id}`}
                component={Renderer}
                path={`${match.path}/${route.id}`}
              />
            ))}
            <Route
              path={`${match.path}/`}
              component={NotFound}
            />
          </Switch>
        </div>
      )}
      <AppFooter origin="" />
    </>
  );
};
