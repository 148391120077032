// @ts-check
////////////////////////////////////////////////////////////////////////////////
// JSDoc Types
////////////////////////////////////////////////////////////////////////////////

/**
 * @typedef {{
 * _id: string,
 * title: string,
 * description: string,
 * long_description: string,
 * coords: {lat: number, lng: number},
 * gallery: string[],
 * email: string,
 * location: string,
 * phone_number: string,
 * features: string[],
 * price: {
 *  day: number,
 *  hour: number,
 * },
 * reviews: Array<import(".").Review>,
 * type: "co-working_space" | "meeting_room",
 * facilities: string[],
 * max_capacity: number,
 * }} ConferenceFacility
 */

/**@type {ConferenceFacility[]} */
export const conferences = [
  {
    _id: "conferencing_facility_one",
    description: "Integer eleifend ante ut nunc condimentum, sed bibendum " +
      "magna feugiat. Vivamus magna nulla, scelerisque ut nisl vitae, tempor " +
      " tempus urna. Phasellus condimentum tempus ultrices. Ut sed lectus" +
      " rhoncus, laoreet leo.",
    email: "conferencing_facility@gmail.com",
    facilities: [],
    features: [],
    gallery: [],
    coords: {lat: 0, lng: 0},
    location: "Machakos, Kenya",
    long_description: "Lorem ipsum dolor sit amet, consectetur adipiscing" +
      " elit. Praesent molestie dui purus, in blandit eros euismod in. Fusce" +
      " faucibus felis ante, eget porttitor diam congue quis. Sed posuere" +
      " eget nisi at viverra. Aliquam non sem eu nulla finibus suscipit nec" +
      " et libero. Orci varius natoque penatibus et magnis dis parturient" +
      " montes, nascetur ridiculus mus. Curabitur sed eros pharetra, aliquet" +
      " mi at, porta dui. Mauris eget viverra neque, vitae placerat magna",
    max_capacity: 123,
    phone_number: "0733697329",
    price: {
      day: 499,
      hour: 45,
    },
    reviews: [],
    title: "Conferencing",
    type: "co-working_space",
  },
  {
    _id: "conferencing_facility_two",
    description: "Integer eleifend ante ut nunc condimentum, sed bibendum " +
      "magna feugiat. Vivamus magna nulla, scelerisque ut nisl vitae, tempor " +
      " tempus urna. Phasellus condimentum tempus ultrices. Ut sed lectus" +
      " rhoncus, laoreet leo.",
    email: "conferencing_facility_two@gmail.com",
    facilities: [],
    features: [],
    gallery: [],
    coords: {lat: 0, lng: 0},
    location: "City Square, Nairobi, Kenya",
    long_description: "Lorem ipsum dolor sit amet, consectetur adipiscing" +
      " elit. Praesent molestie dui purus, in blandit eros euismod in. Fusce" +
      " faucibus felis ante, eget porttitor diam congue quis. Sed posuere" +
      " eget nisi at viverra. Aliquam non sem eu nulla finibus suscipit nec" +
      " et libero. Orci varius natoque penatibus et magnis dis parturient" +
      " montes, nascetur ridiculus mus. Curabitur sed eros pharetra, aliquet" +
      " mi at, porta dui. Mauris eget viverra neque, vitae placerat magna",
    max_capacity: 123,
    phone_number: "0733697329",
    price: {
      day: 799,
      hour: 46,
    },
    reviews: [],
    title: "KICC Conference Hall",
    type: "co-working_space",
  },
];
