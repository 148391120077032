import {FC, useContext} from 'react';
import {Pagination, Radio} from 'antd';
import {useState} from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import type {IconType} from 'react-icons';
import {FaMapMarkerAlt, FaSearch} from "react-icons/fa";
import {IoIosMore} from 'react-icons/io';

import {Property} from '../../../../../api';
import {OverlayProvider} from '../../../../../context/overlay-provider-context';

import {FilterContext} from '../../../context';

import {ColumnData} from '../../left-column';
import {MapOverlay} from '../../map-overlay';
import {SearchOverlay} from '../../search-overlay';

import {ShareButton} from "./share-button";

export type FilterOption = {
  title: string;
  onClick: () => void;
  idx: number;
  onFilter: <T extends Property>(results: T[]) => unknown[]
}

const PAGE_SIZE = 10;

export interface ResultsHeaderProps {
  title: string;
  resultsLength: number;
  HeaderIcon: IconType;
  filterOptions: FilterOption[];
  columnData: ColumnData;
  onPageChange(page: number): void;
  onFilterOption(idx: number): void;
}

export const ResultsHeader: FC<ResultsHeaderProps> = ({
  title, resultsLength, HeaderIcon, filterOptions, columnData, onPageChange,
  onFilterOption,
}) => {
  const filterContext = useContext(FilterContext);
  const {
    setIsOverlay, setOnOverlay
  } = useContext(OverlayProvider);
  const [isContextOpen, setIsContextOpen] = useState<boolean>(false);

  const contextRef = useOnclickOutside(() => setIsContextOpen(false));

  const filterOptionsCopy = [...filterOptions];

  const [visibleFilters, setVisibleFilters] = useState<FilterOption[]>(
    filterOptionsCopy.splice(0, 3),
  );
  const [activeFilter, setActiveFilter] = useState(-1);

  const [overflowFilters, setOverflowFilters] =
    useState<FilterOption[]>(filterOptions.slice(3));

  const setActiveQuickActionFilter = (quickFilterOption: string) => {
    // find the indexOf the quickFilterOptions
    let index = -1;

    for (let i=0; i<visibleFilters.length; i++) {
      if (visibleFilters[i].title === quickFilterOption) {
        index = i;
        break;
      }
    }

    if (index > -1) {
      setActiveFilter(visibleFilters[index].idx);
      onFilterOption(visibleFilters[index].idx);
    }
  };

  const setOverflowActionActive = (overflowAction: FilterOption) => {
    let newOverflow: FilterOption[] | null = null;
    let newVisible: FilterOption[] | null = null;

    let indexOf = -1;
    // to allow breaking from the loop, we use a standard for loop
    for (let i = 0; i< overflowFilters.length; i++) {
      if (overflowFilters[i].idx === overflowAction.idx) {
        indexOf = i;
        break;
      }
    }

    // remove the element from the actions
    overflowFilters.splice(indexOf, 1);
    // if the actions in the filter are 3, we can just push the action to the
    // end and have it visible to the user.
    // Otherwise if there are more than three actions, we have to splice off the
    // last inactive element and push it to the overflowFilters to create space
    // and then record the selected filterOption idx as the active index to
    // allow for react to render all the actions accordingly.
    if (visibleFilters.length < 4) {
      newVisible = [...visibleFilters, overflowAction];
      newOverflow = overflowFilters;
    } else {
      // if the last element is the active one, then we have to splice the
      // second last option, otherwise we splice the last filter.
      let indexOfActive = -1;
      for (let i = 0; i< visibleFilters.length; i++) {
        if (visibleFilters[i].idx === activeFilter) {
          indexOfActive = i;
          break;
        }
      }

      // concat the active index
      // if the last element was the active one, splice second last
      const spliceable = ((indexOfActive + 1) === visibleFilters.length) ?
        visibleFilters.length - 2 : visibleFilters.length - 1;
      newOverflow = overflowFilters.concat(
        visibleFilters.splice(spliceable, 1));
      // new visible filters include the overflow action
      newVisible = [...visibleFilters, overflowAction];
    }

    // set the new Active as the idx of the overflow action
    setActiveFilter(overflowAction.idx);
    setOverflowFilters(newOverflow);
    setVisibleFilters(newVisible);
  };

  const showMapOverlay = () => {
    setOnOverlay && setOnOverlay(() => () => (
      <MapOverlay
        removeOverlay={() => {
          setIsOverlay && setIsOverlay(false);
          setOnOverlay(null);
        }}
        properties={filterContext.results}
      />
    ));
    setIsOverlay && setIsOverlay(true);
  };

  const showSearchOverlay = () => {
    setOnOverlay && setOnOverlay(() => () => (
      <FilterContext.Provider value={filterContext}>
        <SearchOverlay
          columnData={columnData}
          removeOverlay={() => {
            setIsOverlay && setIsOverlay(false);
            setOnOverlay(null);
          }}
        />
      </FilterContext.Provider>
    ));

    setIsOverlay && setIsOverlay(true);
  };

  return (
    <div className="sticky top-0 z-20 bg-white">
      <div
        className="flex items-center"
        style={{ display: "flex", padding: 10, background: "#fff" }}
      >
        <div className="selector-heading items-center">
          <div style={{ marginRight: 10 }}>
            <HeaderIcon />
          </div>{" "}
          {title}
        </div>
        <ShareButton />
      </div>
      <hr />
      <div className="border-blue-700 flex flex-row border my-3 justify-items-stretch">
        <Radio.Group
          className="flex-grow flex flex-row justify-items-stretch"
          optionType="button"
          buttonStyle="solid"
          onChange={(e) => {
            // set the new values after change
            setActiveQuickActionFilter(e.target.value);
          }}
          defaultValue={null}
          value={activeFilter !== -1 ? filterOptions.filter((option) => option.idx === activeFilter)[0].title: ""}
        >
          {[visibleFilters.map((visibleFilter) => (
            <Radio.Button
              key={visibleFilter.title}
              value={visibleFilter.title}
              className="flex-1"
              onClick={() => setActiveFilter(visibleFilter.idx)}
            >
              {visibleFilter.title}
            </Radio.Button>
          ))]}
        </Radio.Group>
        <div
          onClick={() => {
            if (isContextOpen) {
              setIsContextOpen(false);
            } else {
              setIsContextOpen(true);
            }
          }}
          className={
            "flex-grow-0 flex-shrink-0 w-7 flex flex-row items-center justify-items-center " +
            "content-center relative cursor-pointer " +
            `${isContextOpen ? "bg-blue-700 " : "bg-white"}`
          }
        >
          <IoIosMore
            size={24}
            className={
              `mx-2 ${!isContextOpen ? "text-blue-700 " : "text-white"}`
            }
          />
          {/* Context Menu that contains extra options that arent visible */}
          {isContextOpen && (
            <div
              ref={contextRef}
              className="absolute shadow-md top-full right-0"
              style={{
                minWidth: 200,
              }}
            >
              {overflowFilters.map((overflowFilter) => (
                <div
                  key={`overflow_action_${overflowFilter.idx}`}
                  className="cursor-pointer py-2 pl-2 text-sm"
                  onClick={() => {
                    setOverflowActionActive(overflowFilter);
                  }}
                >
                  {overflowFilter.title}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="lg:hidden">
        <hr />
        <div className="flex flex-row items-center justify-between my-3">
          {/* <ShowOnMap /> */}
          <div
            className={
              "elevate-xs map-background flex flex-row flex-grow-0 " +
              " items-center justify-center px-3 cursor-pointer overflow-hidden"
            }
            style={{maxHeight: 32}}
          >
            <div
              className={
                "flex flex-row bg-white items-center py-2 px-3 text-sm " +
                "font-semibold"
              }
              onClick={showMapOverlay}
            >
              <FaMapMarkerAlt className="mr-2" color="#FF5900" />
              Show on Map
            </div>
          </div>
          <div
            className={
              "flex flex-row items-center bg-gradient-to-tr from-start " +
              "to-end text-sm text-white font-semibold py-2 px-3 " +
              "cursor-pointer shadow-md rounded-sm"
            }
            style={{
              maxHeight: 32,
            }}
            onClick={showSearchOverlay}
          >
            <FaSearch className="mr-3" />
            Modify Search
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-end">
        <Pagination
          className="w-full"
          total={resultsLength}
          pageSize={PAGE_SIZE}
          defaultCurrent={1}
          hideOnSinglePage
          showTotal={(total, range) => `Showing ${range[0]} - ${range[1]} of ${
            total
          } results`}
          responsive
          onChange={(page, pageSize) => {
            if (page) {
              onPageChange(page);
            }
          }}
        />
      </div>
      <hr className="my-2" />
    </div>
  );
}
