import { User } from "firebase";
import {useReducer} from "react";
import {ReservationCategory, SafariUser} from "../../api";

import {CountryDetails, CurrencyRates} from "../../App";
import {getCurrency, saveCurrency} from "../../util/currency-converter";

export type AffiliateObject = {
  affiliateId: string;
  category: ReservationCategory;
  propertyId: string;
};

export type CountryMap = Record<string, CountryDetails>;

export enum AppStoreActionType {
  REMINDER_SHOWN = "REMINDER_SHOWN",
  SET_COUNTRIES_DETAILS = "SET_COUNTRIES_DETAILS",
  SET_CONVERSION_RATES = "SET_CONVERSION_RATES",
  SET_ACTIVE_CURRENCY = "SET_ACTIVE_CURRENCY",
  SET_AFFILIATE = "SET_AFFILIATE_ID",
  SET_USER = "SET_USER",
  SET_SAFARI_USER = "SET_SAFARI_USER",
}

export type AppState = {
  REMINDER_SHOWN: boolean;
  COUNTRY_DETAILS: CountryMap;
  CONVERSION_RATES: CurrencyRates;
  ACTIVE_CURRENCY: string;
  AFFILIATE: AffiliateObject | null;
  USER: User | null;
  SAFARI_USER: SafariUser | null;
};

export type AppStoreAction = {
    type: AppStoreActionType;
    payload: unknown;
}

export const initialState: AppState = {
  REMINDER_SHOWN: false,
  COUNTRY_DETAILS: {},
  ACTIVE_CURRENCY: "USD",
  CONVERSION_RATES: {
    base: "USD",
    disclaimer: "",
    license: "",
    rates: {},
    timestamp: Date.now(),
  },
  AFFILIATE: null,
  USER: null,
  SAFARI_USER: null,
};

function appStoreReducer(state: AppState, action: AppStoreAction): AppState {
  switch (action.type) {
    case AppStoreActionType.REMINDER_SHOWN:
      return ({...state, REMINDER_SHOWN: true});
    case AppStoreActionType.SET_COUNTRIES_DETAILS:
      return ({...state, COUNTRY_DETAILS: action.payload as CountryMap});
    case AppStoreActionType.SET_ACTIVE_CURRENCY:
      // if the currency that is being changed to is not the same as the one
      // in local storage, we also make sure that we save this short code to
      // local storage for persistence.
      (action.payload !== getCurrency()) &&
        saveCurrency(action.payload as string);
      
      // persist to app state
      return ({...state, ACTIVE_CURRENCY: action.payload as string});
    case AppStoreActionType.SET_CONVERSION_RATES:
      return ({...state, CONVERSION_RATES: action.payload as CurrencyRates});
    case AppStoreActionType.SET_AFFILIATE:
      return({
        ...state,
        AFFILIATE: action.payload as (AffiliateObject | null),
      });
    case AppStoreActionType.SET_USER:
      return ({
        ...state,
        USER: action.payload as User,
      });
    case AppStoreActionType.SET_SAFARI_USER:
      return ({
        ...state,
        SAFARI_USER: action.payload as SafariUser,
      });
    default:
      return state;
  }
}

export type AppReducerActions = {
  setReminderShown: () => void;
  setCountryDetails: (payload: CountryMap) => void;
  setConversionRates: (payload: CurrencyRates) => void;
  setActiveCurrency: (payload: string) => void;
  setAffiliateId: (affiliateId: AffiliateObject | null) => void;
  setUser: (user: User) => void;
  setSafariUser: (safariUser: SafariUser) => void;
};

export const useAppReducer = (): [AppState, AppReducerActions] => {
  const [state, dispatch] = useReducer(appStoreReducer, initialState);

  const setReminderShown = () => {
    // set the reminder is shown
    dispatch({type: AppStoreActionType.REMINDER_SHOWN, payload: true});
  };

  const setCountryDetails = (payload: CountryMap) => {
    // update the country details
    dispatch({type: AppStoreActionType.SET_COUNTRIES_DETAILS, payload});
  };

  const setConversionRates = (payload: CurrencyRates) => {
    dispatch({type: AppStoreActionType.SET_CONVERSION_RATES, payload});
  };

  const setActiveCurrency = (payload: string) => {
    dispatch({type: AppStoreActionType.SET_ACTIVE_CURRENCY, payload});
  };

  // null allowed since we may be clearing the affiliate object also
  const setAffiliateId = (affiliateObj: AffiliateObject | null) => {
    dispatch({
      type: AppStoreActionType.SET_AFFILIATE,
      payload: affiliateObj,
    });
  };

  const setUser = (user: User) => {
    dispatch({
      type: AppStoreActionType.SET_USER,
      payload: user,
    });
  };

  const setSafariUser = (safariUser: SafariUser) => {
    dispatch({
      type: AppStoreActionType.SET_SAFARI_USER,
      payload: safariUser,
    });
  };

  return [
    state,
    {
      setReminderShown,
      setActiveCurrency,
      setCountryDetails,
      setConversionRates,
      setAffiliateId,
      setUser,
      setSafariUser,
    },
  ];
};
