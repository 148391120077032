import {Badge} from 'antd';
import {FC, useContext} from 'react';

import {ReservationCategory} from '../../../api';
import {AffiliateStateContext} from '../../../context/affiliate';

const categories: ReservationCategory[] = [
  "activity", "car", "conference", "cruise", "eatery", "flight", "hotel",
  "rental", "tour",
];

const categoryItem: {
  [Key in ReservationCategory]: {key: Key, title: string, description: string}
} = {
  activity: {
    key: "activity",
    description: "Activity facilities you have links for",
    title: "Affiliated Activity facilities",
  },
  car: {
    description: "Cars and taxis you have affiliate links for",
    key: "car",
    title: "Affiliate Cars",
  },
  conference: {
    description: "Conference facilities you have links for",
    key: "conference",
    title: "Affiliated Conferencing Facilities",
  },
  cruise: {
    description: "Cruises you are affiliated with",
    key: "cruise",
    title: "Affiliated Cruises",
  },
  eatery: {
    description: "Eateries and restaurants you have affiliate links for",
    key: "eatery",
    title: "Affiliated Eateries and Restaurants",
  },
  flight: {
    description: "Flights you have affiliate links for",
    key: "flight",
    title: "Affiliate Flights",
  },
  hotel: {
    description: "Hotels you have affiliate links for",
    key: "hotel",
    title: "Affiliated Hotels",
  },
  rental: {
    description: "Rental properties you have affiliations with",
    key: "rental",
    title: "Affiliated Rental Properties",
  },
  tour: {
    description: "Tours you have affiliate links for",
    key: "tour",
    title: "Affiliated tours",
  },
};

type LinkCategoriesProps = {
  activeCategory: ReservationCategory;
  onCategoryFocus: (category: ReservationCategory) => void;
};

export const LinkCategories: FC<LinkCategoriesProps> = ({
  activeCategory, onCategoryFocus,
}) => {
  const {state} = useContext(AffiliateStateContext);

  return (
    <div
      className={
        "h-full w-full sm:border-r overflow-y-scroll overflow-x-hidden " +
        "no-scrollbar"
      }
    >
      {/* List items */}
      {categories.map((category) => (
        <div
          key={categoryItem[category].key}
          className={
            `h-16 px-4 flex flex-row items-center justify-between ${
              activeCategory === category ?
                "bg-black bg-opacity-20" :
                "hover:bg-black hover:bg-opacity-20"
            } border-t border-b cursor-pointer`
          }
          style={{
            transitionProperty: "background-color",
            transitionDuration: "150ms",
            transitionTimingFunction: "linear",
          }}
          onClick={() => {
            // actions.setActiveCategory(category);
            onCategoryFocus(category);
          }}
        >
          <div className="flex flex-col">
            <h6 className="text-base font-semibold my-0">
              {categoryItem[category].title}
            </h6>
            <p className="opcity-70 text-xs">
              {categoryItem[category].description}
            </p>
          </div>

          <Badge
            count={state.affiliateSummary.affiliateLinks[category].links.length}
          />
        </div>
      ))}
    </div>
  );
};
