import {ReactNode, useState} from "react";
import {IconType} from "react-icons";

import {Property} from "../../../../api";

import {ColumnData} from "../left-column";

import {FilterOption, ResultsHeader} from "./header";
import {Loading} from "./loading";

interface ResultsProps<T> {
  headerTitle: string;
  HeaderIcon:IconType;
  results: T[];
  filterOptions: FilterOption[];
  columnData: ColumnData;
  loading: boolean;
  render(results: T[]): ReactNode[];
}

const PAGE_SIZE = 10;

export function Results<T extends Property>({
 HeaderIcon, columnData, filterOptions, headerTitle, loading, results,
 render,
}: ResultsProps<T>): JSX.Element { // eslint-disable-line
  const [page, setPage] = useState<number>(1);
  const [filterAction, setFilterAction] = useState<number>(-1);

  const paginate = (page: number) => {
    setPage(page);
  };

  const selectFilterOption = (idx: number) => {
    for (let i=0; filterOptions.length; i++) {
      if (filterOptions[i].idx === idx) {
        setFilterAction(idx);
        break;
      }
    }
  };

  const startIndex = ((page - 1) * PAGE_SIZE);
  const endIndex = PAGE_SIZE * page;

  return (
    <div
      className="results-column col-start-2 col-end-5 h-full"
    >
      <ResultsHeader
        title={headerTitle}
        HeaderIcon={HeaderIcon}
        resultsLength={results.length}
        filterOptions={filterOptions}
        columnData={columnData}
        onPageChange={paginate}
        onFilterOption={selectFilterOption}
      />
      {loading && (
        <Loading />
      )}
      {/* Cater for the presence of an activeFilter option */}
      {/*
        We create an Immediately invoked function so as to look for the
        onFilter action before rendering (this is the case as trying to
          store the callback leads to useState trying to call the callback).
      */}
      {(() => {
        // we first slice the results to make sure that we are only affecting the
        // page that is supposed to be displayed:
        // This helps to make sure that:
        //  - The computation is minimalised
        //  - The results are accurate and the quick filter options are only
        //    affecting the current page
        let displayedResults = results.slice(startIndex, endIndex);

        if (filterAction > -1) {
          for (let i=0; filterOptions.length; i++) {
            if (filterOptions[i].idx === filterAction) {
              displayedResults = filterOptions[i].onFilter(displayedResults) as T[]
              break;
            }
          }
        }
        
        // slicing makes sure we
        return render(displayedResults);
      })()}
    </div>
  );
}
