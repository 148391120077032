import {FC, useContext, useState} from 'react';
import {Formik} from 'formik';
import {RouteComponentProps, useHistory} from 'react-router-dom';
import {FaPlusCircle, FaSave} from 'react-icons/fa';
import {RiHotelBedLine} from 'react-icons/ri';

import {
  Coordinates, Facility, FAQ, Feature, Picture, postHotel, postHotelDraft,
  RefundType, ReservationType,
} from '../../../../../api';
import {HotelRoom} from '../../../../../api/hotel/types';
import {Input, TextArea} from '../../../../../components/input';

import {OverlayProvider} from '../../../../search-property/context';

import {
  EditFacilitiesListItem, EditFeaturesListItem, EditHotelRoomOverlay, EditRefundPolicyListItem,
  EditReservationPolicyListItem, HotelRatingOverlay, PartnerHeader, PickImageListItem,
  PropertyLocationAutoComplete,
} from '../../../components';
import {EditFaqListItem} from '../../../components/edit-faq-list-item';
import {Card} from '../../../../../components';
import {UploadingOverlay} from '../../../components/uploading-overlay';

import {NewHotelRoomOverlay} from './new-hotel-room-overlay';
import {validateHotelForm} from './validate-form';
import {SnackbarProvider} from '../../../../../context/snackbar-provider';
import {FiPlus} from 'react-icons/fi';
import ActivityIndicator from '../../../../../components/loading';
import { ConfirmPropertyLocation } from '../../../components/confirm-property-location';

export const NewHotel: FC<RouteComponentProps> = () => {
  const history = useHistory();
  const {dismissSnackbar, setIsVisible} = useContext(SnackbarProvider);
  const {
    setIsOverlay, setOnOverlay, setIsCancellable,
  } = useContext(OverlayProvider);

  const [facilities, setFacilities] = useState<Facility[]>([]);
  const [features, setFeatures] = useState<Feature[]>([]);
  const [faqs, setFaqs] = useState<FAQ[]>([]);
  const [gallery, setGallery] = useState<Picture[]>([]);
  const [logo, setLogo] = useState<Picture | undefined>(undefined);
  const [coords, setCoords] = useState<Coordinates>({lat: 0, lng: 0});
  const [rooms, setRooms] = useState<HotelRoom[]>([]);
  const [refundPolicy, setRefundPolicy] = useState<RefundType>("partial_refund");
  const [reservationPolicy, setReservationPolicy] =
    useState<ReservationType>("full_amount");

  const [isUploading, setIsUploading] = useState<boolean>(false);
  // used when the UI shows that the draft is being saved
  const [isSaving, setIsSaving] = useState<boolean>(false);
  
  const initialValues = {
    title: "",
    location: "",
    description: "",
    short_description: "",
    reservation_policy: reservationPolicy,
    refund_policy: refundPolicy,
    facilities: "",
    features: "",
    rooms: "",
    faqs: "",
  };

  const addHotelRoom = (newHotelRoom: HotelRoom) => {
    setRooms([...rooms, newHotelRoom]);
  }

  return (
    <>
      {isUploading && (
        <OverlayProvider.Consumer>
          {({setIsCancellable, setIsOverlay, setOnOverlay}) => (
            <UploadingOverlay
              caption="Uploading hotel details"
              setIsCancellable={setIsCancellable}
              setOnOverlay={setOnOverlay}
              setIsOverlay={setIsOverlay}
            />
          )}
        </OverlayProvider.Consumer>
      )}
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          // validate the values
          return validateHotelForm({
            title: values.title,
            coords,
            description: values.description,
            facilities,
            features,
            faqs,
            gallery,
            location: values.location,
            refund_policy: refundPolicy,
            reservation_policy: reservationPolicy,
            rooms,
            short_description: values.short_description,
          });
        }}
        onSubmit={(values) => {
          // as a last step before we start uploading, we ask for the hotel
          // rating, after it is given, we start the uploading process with the
          // rating
          setOnOverlay && setOnOverlay(() => () => (
            <HotelRatingOverlay
              onSave={(hotelRating) => {
                setIsUploading(true);
                postHotel(
                  {
                    accessibility: [],
                    coords,
                    description: values.description,
                    exceptions: [],
                    facilities,
                    features,
                    faqs,
                    gallery,
                    logo,
                    // house_policy:
                    location: values.location,
                    location_type: "",
                    offers: [],
                    pricing_model: "",
                    rating: 10,
                    refund_policy: refundPolicy,
                    reservation_policy: reservationPolicy,
                    reviews: [],
                    rooms,
                    short_description: values.short_description,
                    title: values.title,
                    house_policy: [],
                    hotelRating,
                  },
                  (err, result) => {
                    if (err) {
                      console.error(err);
                      setIsVisible && setIsVisible({
                        fabPresent: false,
                        navRailPresent: true,
                        isError: true,
                        title: "Error publishing hotel details",
                      });
        
                      return;
                    }
        
                    if (result) {
                      setIsUploading(false);
                      setIsVisible && setIsVisible({
                        fabPresent: false,
                        isError: false,
                        navRailPresent: true,
                        title: "Hotel successfully published",
                        action: {
                          label: "DISMISS",
                          onClick: () => {
                            dismissSnackbar && dismissSnackbar();
                          },
                        },
                      });
                      console.log("Finishing up updates");
                      history.replace(`/property-owner/new/hotel/${result.id}`);
                    } else {
                      setIsVisible && setIsVisible({
                        fabPresent: false,
                        navRailPresent: true,
                        isError: true,
                        title: "Error publishing hotel details",
                      });
                    }
                  },
                );
              }}
              removeOverlay={() => {
                setIsOverlay && setIsOverlay(false);
                setIsCancellable && setIsCancellable(true);
                setOnOverlay(null);
              }}
            />
          ));
          setIsCancellable && setIsCancellable(false);
          setIsOverlay && setIsOverlay(true);
        }}
      >
        {({
          values, errors, handleChange, handleSubmit, handleBlur,
          setErrors, validateForm,
        }) => (
          <form onSubmit={handleSubmit} onBlur={handleBlur}>
            <PartnerHeader
              title={values.title ? values.title : "New Hotel"}
              HeaderIcon={RiHotelBedLine}
              actions={[
                {
                  label: "SAVE DRAFT",
                  alwaysVisible: false,
                  ActionIcon: FaSave,
                  type: "button",
                  disabled: isSaving,
                  onClick: () => {
                    // saves the route as a draft
                    setIsSaving(true);
                    setIsCancellable && setIsCancellable(false);
                    setOnOverlay && setOnOverlay(() => () => (
                      <ActivityIndicator
                        caption=""
                      />
                    ));
                    setIsOverlay && setIsOverlay(true);

                    postHotelDraft(
                      null,
                      {
                        accessibility: [],
                        coords,
                        description: values.description,
                        exceptions: [],
                        facilities,
                        features,
                        faqs,
                        gallery,
                        // house_policy:
                        location: values.location,
                        location_type: "",
                        offers: [],
                        pricing_model: "",
                        rating: 10,
                        refund_policy: refundPolicy,
                        reservation_policy: reservationPolicy,
                        reviews: [],
                        rooms,
                        short_description: values.short_description,
                        title: values.title,
                        house_policy: [],
                        hotelRating: 0,
                      },
                      (err, result) => {
                        setIsSaving(false);
                        setIsOverlay && setIsOverlay(false);
                        setIsCancellable && setIsCancellable(true);
                        setOnOverlay && setOnOverlay(null);

                        if (err) {
                          console.error(err);
                          setIsVisible && setIsVisible({
                            fabPresent: false,
                            navRailPresent: true,
                            isError: true,
                            title: "Error saving draft",
                          });
          
                          return;
                        }
          
                        if (result) {
                          setIsVisible && setIsVisible({
                            fabPresent: false,
                            navRailPresent: true,
                            isError: false,
                            title: "Draft saved",
                            action: {
                              label: "DISMISS",
                              onClick: () => {
                                dismissSnackbar && dismissSnackbar();
                              },
                            },
                          });

                          history.replace("/property-owner/hotel");
                        } else {
                          setIsVisible && setIsVisible({
                            fabPresent: false,
                            navRailPresent: true,
                            isError: true,
                            title: "Error saving draft",
                          });
                        }
                      },
                    );
                  },
                },
                {
                  label: "PUBLISH HOTEL",
                  onClick: () => {
                    console.log("Adding new Hotel");
                  },
                  disabled: Object.keys(errors).length > 0,
                  alwaysVisible: true,
                  ActionIcon: FiPlus,
                  type: "submit",
                },
              ]}
            />
            <div className="p-4 sm:grid sm:grid-cols-2 gap-4 lg:grid-cols-3 lg:grid-flow-col-dense lg:max-w-5xl">
              {/** Input Title */}
              <div>
                <Input
                  inputLabel="Hotel's name"
                  name="title"
                  value={values.title}
                  error={errors.title}
                  helper="Name of the hotel"
                  id="hotel-name"
                  onChange={handleChange}
                />
              </div>
              {/** End of input Title */}
              {/** Input Title */}
              <div className="mt-3 sm:mt-0 lg:col-start-1 col-start-2">
                {/** Text input */}
                <PropertyLocationAutoComplete
                  name="location"
                  inputLabel="Hotel's location"
                  onChange={handleChange}
                  selectAddress={(place, coords) => {
                    setOnOverlay && setOnOverlay(() => () => (
                      <ConfirmPropertyLocation
                        initialCoords={coords}
                        onConfirm={(coords) => {
                          setCoords(coords);
                        }}
                        removeOverlay={() => {
                          setOnOverlay && setOnOverlay(null);
                          setIsOverlay && setIsOverlay(false);
                        }}
                      />
                    ));

                    setIsOverlay && setIsOverlay(true);
                  }}
                  value={values.location}
                  error={errors.location}
                />
                {/** End of text input */}
              </div>
              {/** End of input Title */}
          
              {/** Start of Text Area */}
              <div className="mt-3 lg:col-start-1 lg:col-end-3">
                <TextArea
                  name="description"
                  value={values.description}
                  error={errors.description}
                  helper="Longform description of the hotel"
                  id="hotel-description"
                  inputLabel="Hotel description (long)"
                  onChange={handleChange}
                />
              </div>
              {/** End of Text Area */}
          
              {/** Start of Text Area */}
              <div className="mt-3 lg:col-start-1 lg:col-end-3">
                <TextArea
                  name="short_description"
                  value={values.short_description}
                  error={errors.short_description}
                  helper="Brief description of the hotel"
                  id="hotel-short-description"
                  inputLabel="Hotel description (short)"
                  onChange={handleChange}
                />
              </div>
              {/** End of Text Area */}
          
              {/** Reservation policy card */}
              <EditReservationPolicyListItem
                reservationPolicy={reservationPolicy}
                onSave={async (reservationPolicy) => {
                  setReservationPolicy(reservationPolicy);
                  setErrors(await validateForm());
                }}
              />
              {/** End of Reservation policy card */}
          
              {/** Reservation policy card */}
              <EditRefundPolicyListItem
                refundPolicy={refundPolicy}
                onSave={async (refundPolicy) => {
                  setRefundPolicy(refundPolicy);
                  setErrors(await validateForm());
                }}
              />
              {/** End of Reservation policy card */}
          
              {/** Pick images overlay */}
              <PickImageListItem
                gallery={gallery}
                logo={logo}
                minImageCount={6}
                onOverlaySave={async (gallery) => {
                  setGallery(gallery);
                  setErrors(await validateForm());
                }}
                setLogo={setLogo}
              />
              {/* End of pick image overlay */}
          
              {/** Things liks prices and sub properties are found here */}
              <hr className="my-4 sm:col-start-1 sm:col-end-3 h-auto lg:hidden" />
              {/** Special Section */}
              <div className="shadow-md border sm:col-start-1 sm:col-end-3">
                {/** Header for special section */}
                <div className="h-14 flex flex-row justify-between items-center px-4 border-b">
                  <div>
                    <h6 className="text-xl font-semibold">Hotel Rooms</h6>
                    <p className="text-xs">Add hotel room type</p>
                  </div>

                  {/** Edit Button */}
                  <OverlayProvider.Consumer>
                    {({setIsOverlay, setOnOverlay}) => (
                      <button
                        type="button"
                        className="h-9 px-3 border border-red-500 rounded-md shadow-md text-red-500 font-semibold text-sm flex items-center"
                        onClick={() => {
                          setOnOverlay && setOnOverlay(() => () => (
                            <NewHotelRoomOverlay
                              removeOverlay={() => {
                                setIsOverlay && setIsOverlay(false);
                                setOnOverlay(null);
                              }}
                              onSave={async (newHotelRoom) => {
                                addHotelRoom(newHotelRoom);
                                setErrors(await validateForm());
                              }}
                            />
                          ));

                          setIsOverlay && setIsOverlay(true);
                        }}
                      >
                        Add Room
                      </button>
                    )}
                  </OverlayProvider.Consumer>
                  {/** End of edit Button */}
                </div>

                <div className="p-4 flex flex-row overflow-x-scroll">
                  {/** All Added Sub Properties go here */}
                  {rooms.map((room, idx) => (
                    <Card
                      key={`${room.title}`}
                      cover={room.gallery[0].url}
                      description={room.description}
                      subtitle={`${room.price} per night`}
                      title={room.title}
                      action={{
                        label: "Edit Room",
                        onClick: () => {
                          setOnOverlay && setOnOverlay(() => () => (
                            <EditHotelRoomOverlay
                              idx={idx}
                              item={room}
                              hotelRooms={rooms}
                              removeOverlay={() => {
                                setIsOverlay && setIsOverlay(false);
                                setOnOverlay(null);
                              }}
                              setHotelRooms={setRooms}
                            />
                          ));

                          setIsOverlay && setIsOverlay(true);
                        },
                      }}
                    />
                  ))}
                </div>
              </div>
              {/** Special Section */}

              <div className="sm:col-span-2 lg:col-start-1 lg:col-end-4">
                <h3 className="text-2xl font-semibold sm:col-start-1 sm:col-end-3">Aditional Information</h3>
                <div className="sm:grid sm:grid-cols-2 sm:gap-4 lg:grid-cols-3">
                  {/** Add FAQs */}
                  <div>
                    <EditFaqListItem
                      error={errors.faqs}
                      faqs={faqs}
                      onSave={async (faqs) => {
                        setFaqs(faqs);
                        setErrors(await validateForm());
                      }}
                    />
                    {errors.faqs && (
                      <p>{errors.faqs}</p>
                    )}
                  </div>
                  {/** End of FAQ */}
          
                  {/** Add Features */}
                  <EditFacilitiesListItem
                    category="hotel"
                    facilities={facilities}
                    onSave={async (newFacilities) => {
                      setFacilities(newFacilities);
                      setErrors(await validateForm());
                    }}
                    error={errors.facilities}
                  />
                  {/** End of Add Features */}
          
                  {/** Add Features */}
                  <EditFeaturesListItem
                    category="hotel"
                    features={features}
                    onSave={async (newFeatures) => {
                      setFeatures(newFeatures);
                      setErrors(await validateForm());
                    }}
                    error={errors.features}
                  />
                  {/** End of features */}

                  {/** Add Features */}
                  <div className="py-2">
                    <div className="border flex flex-row justify-between shadow-md px-4 py-2 items-center">
                      <div>
                        <h6 className="text-xl font-semibold">Accessibiity Features</h6>
                        <p className="text-xs opacity-70">Press to edit the accessible features in the hotel</p>
                      </div>
                      {/* <div className="h-6 w-6 bg-indigo-300"></div> */}
                      <FaPlusCircle size={24} color="#FF5900" />
                    </div>
                    {/* <p className="mt-1 text-xs text-red-500 pl-2">Must have at least one feature</p> */}
                  </div>
                  {/** End of features */}
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};
