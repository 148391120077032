import {firestore} from "firebase";

import {OnCompleteCallback} from "..";

import {Info} from "./types";

export type {Info, ListContent, ParagraphContent} from './types';

export const fetchInfo = async (
  onComplete: OnCompleteCallback<Info[]>,
): Promise<void> => {
  try {
    const infoSnapshot = await firestore().collection("info").get();
    const info = infoSnapshot.docs.map((doc) => ({...doc.data(), id: doc.id}) as Info);

    onComplete(null, info);
  } catch (err) {
    console.error(err);
    onComplete(new Error("Unable to fetch information"), null);
  }
};

export const fetchRoute = async (
  category: string, onComplete: OnCompleteCallback<Info>,
): Promise<void> => {
  try {
    const categoryInfoSnapshot = await firestore().collection("info")
      .doc(category)
      .get();

    if (!categoryInfoSnapshot.exists) {
      onComplete(new Error("This route does not exist"), null);

      return;
    }
    
    onComplete(null, categoryInfoSnapshot.data() as Info);
  } catch (err) {
    onComplete(new Error("Unable to fetch Info"), null);
    console.error(err);
  }
};

export const setInfo = async (
  category: string, payload: Info, onComplete: OnCompleteCallback<boolean>,
): Promise<void> => {
  try {
    await firestore().collection("info").doc(category).set(payload);
    onComplete(null, true);
  } catch (err) {
    console.log(err);

    onComplete(new Error("Unable to upload payload"), null);
  }
};

export const deleteInfo = async (category: string): Promise<boolean> => {
  try {
    await firestore().collection("info").doc(category).delete();

    return true;
  } catch (err) {
    console.log(err);

    return false;
  }
};
