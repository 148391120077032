import {useState} from "react";
import {Link} from "react-router-dom";
import GoogleMapReact from "google-map-react";
import {GrClose} from "react-icons/gr";
import {FaAngleRight} from "react-icons/fa";

import MakerImage from "../../assets/marker_1.png";

import MapItem from "./Items/MapListItem";
import useOnclickOutside from "react-cool-onclickoutside";

// TODO: Refactor as the Marker as of not only works because it uses an indirect
// reference to the parent's this object. Any attempt to use this component
// anywhere else will lead to failure as the this object may point to a null
// reference.
function Marker() {
  if (this.props.selectedMarkerName == this.props.shared.title) {
    return (
      <div
        className={
          "elevate-xs border-0 mb-5 p-0 rounded-md bg-white overflow-hidden" +
          " flex mr-3"
        }
        style={{
          flex: 1,
          width: 247,
        }}
      >
        <div style={{ display: "flex", flex: 1 }}>
          <div
            style={{
              flex: 1,
              padding: 15,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                fontSize: 15,
                color: "#0a7fb5",
                fontFamily: "Roboto",
              }}
            >
              {this.props.shared.hotel_name}
            </div>
            <div
              style={{ fontWeight: "bold", marginTop: 5, marginBottom: 5 }}
            >
              {this.props.shared.hotel_address}
            </div>
            <div style={{ paddingLeft: 5, marginTop: 0 }}>
              <Link
                to={
                  `/view/hotel/${ 
                  this.props.shared.hotelId 
                  }/${ 
                    new URL(location.href).search.replace("?", "")
                  }`
                }
              >
                <div
                  className="listYourSpaceBt"
                  style={{
                    height: 18,
                    fontSize: 12,
                    padding: 5,
                    paddingLeft: 15,
                    paddingRight: 15,
                    borderRadius: 0,
                    display: "flex",
                    fontWeight: "normal",
                  }}
                >
                  <div>
                    {!this.props.plannerActive
                      ? "See Availability"
                      : "Add to trip"}{" "}
                  </div>
                  <div style={{ fontSize: 15, paddingLeft: 3 }}>
                    <FaAngleRight />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      onClick={() => this.props.setSelectedMarker(this.props.shared.title)}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#0a7fb5",
          color: "white",
          padding: 3,
          borderRadius: 2,
        }}
      >
        <div>{this.props.shared.price}</div> <div> USD</div>
      </div>
      <img src={MakerImage} style={{ width: 50, height: 50 }} />
    </div>
  );
}

const MapApp = ({sharedState, updateState}) => {
  const state = {
    locations: [{ lat: -1.2924484, lng: 36.8104584 }],
    selectedMarkerName: "",
  };

  const [locations, setLocations] =
    useState([{ lat: -1.2924484, lng: 36.8104584 }]);
  const [selectedMarkerName, setSelectedMarkerName] = useState("");

  const _setSelectedMarker = (newSelectedMarkerName) => {
    setSelectedMarkerName(newSelectedMarkerName);
  };

  const mapRef = useOnclickOutside(() => updateState({displayMode: "normal"}));

  return (
    <div
      className={
        "absolute top-0 h-screen w-screen flex flex-col md:flex-row z-40 " +
        "justify-items-center justify-center pt-6 items-center " +
        // this is a quick patch to fix misbehaviour in large desktop screens
        "xl:h-full xl:w-full"
      }
      style={{
        background: "#000000AA",
        zIndex: 5000,
      }}
    >
      <div
        ref={mapRef}
        className={
          "elevate-xs relative flex flex-col lg:flex-row p-5 bg-white " +
          "w-full h-full md:w-9/12 md:h-3/4"
        }
      >
        <div
          onClick={() => {
            updateState({displayMode: "normal"});
          }}
          className={
            "absolute top-9 left-7 shadow-md py-2 px-4 bg-gradient-to-tr " +
            "bg-white text-black text-sm flex flex-row " +
            "items-center place-items-center rounded-md md:hidden"
          }
          style={{zIndex: 5001}}
        >
          <GrClose
            className="mr-1 text-white"
            size={20}
            color="white"
            style={{
              color: "white",
            }}
          />
          Close Map
        </div>
        <div
          className="mr-5 w-full h-3/5 lg:h-full lg:w-8/12 bg-gray-500"
          style={{ flex: 1 }}
        >
          <GoogleMapReact
            yesIWantToUseGoogleMapApiInternals
            bootstrapURLKeys={{
              key: "AIzaSyDxrJtbT4XhPoiNlXoOsNZ77j38ai9ZwAo",
            }}
            defaultCenter={sharedState.center}
            defaultZoom={8}
          >
            {sharedState.searchResults
              .slice((0, 1))
              .map((data) => {
                let center = {
                  lat: parseFloat(data.hotel_lat),
                  lng: parseFloat(data.hotel_lng),
                };

                switch (sharedState.category) {
                  case "hotel":
                    return (
                      <Marker
                        lat={center.lat}
                        lng={center.lng}
                        text={"yup"}
                        shared={data}
                        selectedMarkerName={selectedMarkerName}
                        setSelectedMarker={_setSelectedMarker}
                        urlParameters={sharedState.urlParameters}
                      />
                    );
                }
              })}
          </GoogleMapReact>
        </div>
        <div
          className={
            "overflow-x-scroll lg:overflow-x-hidden overflow-y-hidden " +
            "lg:overflow-y-scroll w-full h-2/5 lg:w-4/12 lg:h-full flex " +
            "flex-row items-center lg:block bg-autocomplete-hover"
          }
        >
          <div
            className={
              "w-auto lg:w-full h-full flex flex-row items-center " +
              "lg:block lg:place-items-center pl-3 lg:pt-3"
            }
          >
            {sharedState.searchResults.map((data) => {
              switch (sharedState.category) {
                case "hotel":
                  return (
                    <MapItem
                      data={data}
                      days={sharedState.numberOfDays}
                      urlParameters={sharedState.urlParameters}
                    />
                  );
              }
            })}
          </div>
        </div>
        <div
          className="elevate"
          onClick={() => {
            updateState({ displayMode: "normal" });
          }}
          style={{
            height: 50,
            width: 50,
            background: "#fff",
            position: "absolute",
            right: 0,
            top: 0,
            marginTop: -34,
            marginRight: -20,
            borderRadius: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <GrClose />
        </div>
      </div>
    </div>
  );
  
}

export default MapApp;
