import {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import {FaUtensils} from 'react-icons/fa';
import {useHistory} from 'react-router';

import {
  Coordinates, Eatery as EateryProperty, Facility, Feature, filterEateries, Property,
} from '../../../../api';
import {eateryFacilities, eateryFeatures} from '../../../../api/features';
import {SearchPropertyCard} from '../../../../components/search-property-card';
import {fetchCompulsoryEateriesFilters} from '../../../../reducers';
import {DateUtil, DistanceUtil} from '../../../../util';
import {calculateEateryPrice} from '../../../../util/price-calculator';

import {ContainerTwo} from '../../components/container-two';
import {FilterableProperty, FilterContext} from '../../context';
import {filter} from '../../util';

export const Eatery: FC = () => {
  const history = useHistory();

  const [filterableProperties, setFilterableProperties] = useState<FilterableProperty[]>([]);
  const [viewableResults, setViewableResults] = useState<EateryProperty[]>([]);
  const [eateries, setEateries] = useState<EateryProperty[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(true);

  const [rawResultsStale, setRawResultsStale] = useState<boolean>(false);
  const [filterResultsStale, setFilterResultsStale] = useState<boolean>(false);

  const [activeFeatures, setActiveFeatures] = useState<Feature[]>([]);
  const [activeFacilities, setActiveFacilities] = useState<Facility[]>([]);

  const {place, adults, children, date, lat, lng} = fetchCompulsoryEateriesFilters();
  const coords: Coordinates = {
    lat: (lat) ? lat : 0,
    lng: (lng) ? lng :0,
  };

  useEffect(() => {
    const location = (place) ? place : "";
    const coords: Coordinates = {
      lat: (lat) ? lat : 0,
      lng: (lng) ? lng :0,
    };
    const checkinDate = date ? new Date(
      DateUtil.changeDateFormat(date, "DD-MM-YYYY", "MM-DD-YYYY"),
    ) : new Date();

    checkinDate.setHours(10, 0);
    filterEateries(location, coords, checkinDate.getTime(), (err, results) => {
      if (err) {
        console.log(err);

        return;
      }

      if (results) {
        setIsFetching(true);
        setEateries(results);
        setViewableResults(results);
        setFilterableProperties(results.map(result => ({
          ...result,
          price: calculateEateryPrice(
            adults ? adults: 1,
            children ? children : 0,
            result,
          ),
        })));
        setRawResultsStale(false);
      }
    });
  }, [rawResultsStale]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FilterContext.Provider
      value={{
        filterResultsStale,
        rawResults: eateries,
        rawResultsStale,
        results: filterableProperties,
        setFilterResultsStale,
        setRawResultsStale,
        setResults: setEateries as Dispatch<SetStateAction<Property[]>>,
        setViewableResults: setViewableResults as (viewableResults: Property[]) => void,
      }}
    >
      <ContainerTwo<EateryProperty>
        HeaderIcon={FaUtensils}
        columnData={[
          {
            title: "Facilities",
            data: eateryFacilities,
            onChange: (activeFacilities) => {
              setActiveFacilities(filter(activeFacilities, eateryFacilities));
              setFilterResultsStale(true);
            },
          },
          {
            title: "Features",
            data: eateryFeatures,
            onChange: (activeFeatures) => {
              setActiveFeatures(filter(activeFeatures, eateryFeatures));
              setFilterResultsStale(true);
            },
          },
        ]}
        filterOptions={[
          {
            idx: 0,
            onClick: () => console.log("Selecting top solo filters"),
            title: "Top solo picks",
            onFilter: (results) => results,
          },
          {
            idx: 1,
            onClick: () => console.log("Selecting Stars (Highest first)"),
            title: "Stars (Highest first)",
            onFilter: (results) => {
              const parsedResults = (results as Property[]).sort((result, nextResult) => {
                return nextResult.rating - result.rating;
              });

              return parsedResults;
            },
          },
          {
            idx: 2,
            onClick: () => console.log("Selecting stars (Lowest First)"),
            title: "Stars (Lowest first)",
            onFilter: (results) => {
              const parsedResults = (results as Property[]).sort((result, nextResult) => {
                return result.rating - nextResult.rating;
              });

              return parsedResults;
            },
          },
          {
            idx: 3,
            onClick: () => console.log("Selecting by Price (Highest)"),
            title: "Price (Highest)",
            onFilter: (results) => {
              const parsedResults = (results as unknown as EateryProperty[]).sort((result, nextResult) => {
                return nextResult.table[0].price - result.table[0].price;
              });

              return parsedResults;
            },
          },
          {
            idx: 4,
            onClick: () => console.log("Select by price (lowest)"),
            title: "Price (Lowest)",
            onFilter: (results) => {
              const parsedResults = (results as unknown as EateryProperty[]).sort((result, nextResult) => {
                return result.table[0].price - nextResult.table[0].price;
              });

              return parsedResults;
            },
          },
          {
            idx: 5,
            onClick: () => console.log("Selecting top Reviewed"),
            title: "Top Reviewed",
            onFilter: (results) => {
              const parsedResults = (results as Property[]).sort((result, nextResult) => {
                return nextResult.reviews.length - result.reviews.length;
              });

              return parsedResults;
            },
          }
        ]}
        headerTitle={`Showing results for ${place}`}
        loading={isFetching}
        results={viewableResults}
      >
        {(visibleProperties) => viewableResults.map((eatery, idx) => (
          <SearchPropertyCard
            key={eatery.id}
            action={{
              label: "View Rental",
              onClick: () => {
                history.push(`/property/eatery/${eatery.id}?${
                  new URL(location.href).searchParams.toString()
                }`)
              },
            }}
            comments={eatery.reviews}
            cover={eatery.gallery[0].url}
            description={eatery.description}
            duration={`2 days`}
            idx={idx}
            item={eatery}
            location={eatery.location}
            origin="search"
            price={eatery.menu[0].price}
            rating={eatery.rating}
            style={{}}
            title={eatery.title}
            subtitle={
              `${
                DistanceUtil.haversineDistance("km", coords, eatery.coords)
                  .toFixed(2)
              } KM from ${place}`
            }
          />
        ))}
      </ContainerTwo>
    </FilterContext.Provider>
  );
};
