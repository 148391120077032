import {FC} from "react";

type TabProps = {
  active: string;
  setActive: (idx: number) => void;
  tabs: {
    title: string;
    pathname: string;
    key: number;
    active: boolean;
    icon: JSX.Element; // eslint-disable-line no-undef
  }[];
};

export const Tab: FC<TabProps> = ({tabs, active, setActive}) => {
  return (
    <div
      className="flex flex-row items-center w-full overflow-x-scroll lg:overflow-x-visible"
      style={{
        height: 48,
      }}
    >
      {tabs.map((tab, idx) => {
        if (tab.pathname === active) {
          return (
            <div
              key={tab.pathname}
              className="h-full flex flex-col justify-center relative cursor-pointer"
              style={{
                margin: "0px 16px 0px 0px"
              }}
            >
              <div className="flex flex-row items-center text-white">
                {tab.icon}
                <span className="font-semibold whitespace-nowrap" style={{fontSize: 15, marginLeft: 12}}>
                  {tab.title}
                </span>
              </div>
              <div className="w-full bg-white justify-self-end absolute bottom-0" style={{height: 2}} />
            </div>
          )
        }

        return (
        <div
          key={tab.pathname}
          className="flex cursor-pointer"
          style={{
            margin: "0px 16px 0px 0px"
          }}
          onClick={() => {
            setActive(idx)
          }}
        >
          <div className="flex flex-row items-center text-white">
            {tab.icon}
            <span className="font-semibold whitespace-nowrap" style={{fontSize: 15, marginLeft: 12}}>
              {tab.title}
            </span>
          </div>
        </div>
      )})}
    </div>
  );
};
