////////////////////////////////////////////////////////////////////////////////
// JSDoc Types
////////////////////////////////////////////////////////////////////////////////
/**
 * @typedef {{
 * place: ?string,
 * lat: ?number,
 * lng: ?number,
 * date: ?string,
 * passengers: ?string,
 * }} CompulsoryCarFilters
 *
 * @typedef {{
 * price_lowest: ?number,
 * price_highest: ?number,
 * star_rating: ?number,
 * feature: ?string[],
 * theme: ?string,
 * }} OptionalCarFilters
 *
 * @typedef {{
 * compulsoryFilters: CompulsoryCarFilters,
 * optionalFilters: OptionalCarFilters,
 * }} SearchState
 *
 * @typedef {{
 * type: "UPDATE_All_FILTERS" | "UPDATE_OPTIONAL_FILTERS" |
 * "UPDATE_COMPULSORY_FILTERS",
 * value: any,
 * }} SearchStoreAction
 */

/**
 * This function fetches all the compulsory Filters from the current
 * `window.location` Url.
 * 
 * @returns {CompulsoryCarFilters}
 */
export const fetchCompulsoryCarFilters = () => {
  const url = new URL(location.href);
  /**@type {CompulsoryCarFilters} */
  const compulsoryFilters = {};

  const [loc, ] = url.searchParams.get("place").split(",");
  compulsoryFilters.place = loc;
  
  if (url.searchParams.has("passengers")) {
    compulsoryFilters.adults = Number(url.searchParams.get("passengers"));
  } else {
    compulsoryFilters.passengers = 1;
  }

  compulsoryFilters.checkin = url.searchParams.get("date");
  if (url.searchParams.has("lat")) {
    compulsoryFilters.lat = Number(url.searchParams.get("lat"));
    compulsoryFilters.lng = Number(url.searchParams.get("lng"));
  }

  return compulsoryFilters;
};

/**
 * This function fetches all the optional features encoded in the URL.
 *
 * @returns {OptionalCarFilters} the optional filters that have been captured.
 */
export const fetchOptionalCarFilters = () => {
  const url = new URL(location.href);

  /**@type {OptionalCarFilters} */
  const optionalFilters = {};

  optionalFilters.feature = url.searchParams.getAll("feature");
  if (url.searchParams.has("price_highest")) {
    optionalFilters.price_highest = Number(url.searchParams.get("price_highest"));
  }

  if (url.searchParams.has("price_lowest")) {
    optionalFilters.price_lowest = Number(url.searchParams.get("price_lowest"));
  }

  optionalFilters.star_rating = Number(url.searchParams.get("star_rating"));
  optionalFilters.theme = url.searchParams.get("theme");

  return optionalFilters;
};
