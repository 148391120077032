import {
  Coordinates, Facility, FAQ, Feature, ItemException, Picture, Policy,
  RefundType, ReservationType,
} from "../../../../../api";
import {HotelRoom} from "../../../../../api/hotel/types";

type HotelForm = {
  title: string;
  description: string;
  short_description: string;
  gallery: Picture[];
  location: string;
  coords: Coordinates;
  reservation_policy: ReservationType;
  refund_policy: RefundType;
  rooms: HotelRoom[];
  facilities: Facility[];
  features: Feature[];
  faqs: FAQ[];
};

type HotelErrors = {
  title?: string;
  description?: string;
  short_description?: string;
  gallery?: string;
  location?: string;
  reservation_policy?: string;
  refund_policy?: string;
  rooms?: string;
  facilities?: string;
  features?: string;
  faqs?: string;
};

export const validateHotelForm = (form: HotelForm): HotelErrors => {
  const errors: HotelErrors = {};

  // title
  if (form.title === "") {
    errors.title = "The hotel must have a valid name";
  }

  // description
  if (form.description === "") {
    errors.description = "The hotel must have a valid description";
  }

  // short_description
  if (form.short_description === "") {
    errors.short_description = "The hotel must have a valid brief description";
  }

  // coords
  if (form.coords.lat === 0 && form.coords.lng === 0) {
    errors.location =
      "Select the location from the dropdown that comes up when typing to help get hotel coordingates";
  }

  // location
  if (form.location === "") {
    errors.location =
      "The location of the hotel cannot be empty";
  }

  // gallery
  if (form.gallery.length < 6) {
    errors.gallery =
      "The pictures potraying the hotel must be at least 6 in total";
  }

  // rooms
  if (form.rooms.length < 1) {
    errors.rooms = "The hotel must have at least one room type added";
  }

  // facilities
  if (form.facilities.length < 1) {
    errors.facilities = "The hotel must have at least one facility selected";
  }

  // features
  if (form.features.length < 1) {
    errors.features = "The hotel must have at least one feature selected";
  }

  return errors;
};

type HotelOverlayForm = {
  title: string;
  description: string;
  short_description: string;
  gallery: Picture[];
  cardinality: string;
  adults_only_capacity: string;
  children_only_capacity: string;
  adults_and_children_capacity_children?: string;
  adults_and_children_capacity_adults?: string;
  price: string;
  exceptions: ItemException[];
  room_policies: Policy[];
  features: Feature[];
  facilities: Facility[];
};

type HotelOverlayErrors = {
  title?: string;
  description?: string;
  short_description?: string;
  gallery?: string;
  cardinality?: string;
  adults_only_capacity?: string;
  children_only_capacity?: string;
  adults_and_children_capacity_children?: string;
  adults_and_children_capacity_adults?: string;
  price?: string;
  exceptions?: string;
  room_policies?: string;
  features?: string;
  facilities?: string;
};

export const validateHotelOverlayForm = (
  form: HotelOverlayForm,
): HotelOverlayErrors => {
  const errors: HotelOverlayErrors = {};
  // title validation
  if (form.title === "") {
    errors.title = "This field cannot be empty";
  }

  // description
  if (form.description === "") {
    errors.description = "The hotel room must have a valid description";
  }

  // short_description

  if (form.short_description === "") {
    errors.short_description = "There must be a brief description of the room";
  }

  // gallery
  if (form.gallery.length < 3) {
    errors.gallery ="There must be at least 3 images of the room";
  }

  // cardinality
  if (form.cardinality === "" || isNaN(Number(form.cardinality))) {
    errors.cardinality = "The number of rooms must be a valid number";
  }

  // adults_only_capacity
  if (
    form.adults_only_capacity === "" ||
    isNaN(Number(form.adults_only_capacity))
  ) {
    errors.adults_only_capacity = "This field should contain a valid number";
  }

  // adults_and_children_capacity
  if (
    form.adults_and_children_capacity_adults === "" ||
    isNaN(Number(form.adults_and_children_capacity_adults))
  ) {
    errors.adults_and_children_capacity_adults =
      "This field should contain a valid number";
  }

  // adults_and_children_capacity
  if (
    form.adults_and_children_capacity_children === "" ||
    isNaN(Number(form.adults_and_children_capacity_children))
  ) {
    errors.adults_and_children_capacity_children =
      "This field should contain a valid number";
  }

  // children_only_capacity
  if (
    form.children_only_capacity === "" ||
    isNaN(Number(form.children_only_capacity))
  ) {
    errors.children_only_capacity = "This field should contain a valid number";
  }

  // form price
  if (form.price === "" || isNaN(Number(form.price))) {
    errors.price = "This field should contain a valid number";
  }

  // features
  // if (form.features.length < 1 ) {
  //   errors.features = "The hotel should have at least one feature selected";
  // }

  // // facilities
  // if (form.facilities.length < 1) {
  //   errors.facilities = "The hotel should have at least one facility selected";
  // }

  return errors;
};
