import {FC} from "react";
import {Car, Property} from "../../api";

import {
  PopulatedActivityReservation, PopulatedCarReservation,
  PopulatedConferenceReservation, PopulatedEateryReservation,
  PopulatedHotelReservation, PopulatedRentalReservation, PopulatedReservation,
  PopulatedTourReservation,
} from "../../api/reservation/types";

import {ActivityReservationRows} from "./activity";
import {CarResevationRows} from "./car";
import {ConferenceReservationRows} from "./conference";
import {EateryReservationRows} from "./eatery";
import {HotelReservationRows} from "./hotel";
import {RentalReservationRows} from "./rental";
import {TourReservationRows} from "./tour";

type DraftReservationCardProps = {
  idx: number;
  draftReservationId: string;
  reservation: Record<string, unknown>;
  closeSheet: () => void;
  removeDraftReservation: (idx: number) => void;
};

export const DraftReservationCard: FC<DraftReservationCardProps> = ({
  idx, draftReservationId, reservation, closeSheet, removeDraftReservation,
}) => {
  const { id, user, category } =
    reservation as PopulatedReservation;
  return (
    <div key={`${id}`} className="border rounded-md p-2 my-3 shadow">
      <div
        className="bg-center bg-cover bg-no-repeat h-48"
        style={{
          backgroundImage: `url(${
            (reservation.property as Property | Car).gallery[0].url
          })`,
        }}
      />

      <div className="py-2 px-4 border-b">
        <h6 className="text-xl font-semibold">{user}</h6>
        <p className="text-xs opacity-70">
          {category === "car"
            ? `${(reservation.property as Car).reg_no}`
            : `${(reservation.property as Property).title}`}
        </p>
      </div>

      {category === "activity" && (
        <ActivityReservationRows
          idx={idx}
          draftReservationId={draftReservationId}
          reservation={reservation as PopulatedActivityReservation}
          closeSheet={closeSheet}
          removeDraftReservation={removeDraftReservation}
        />
      )}

      {category === "car" && (
        <CarResevationRows
          idx={idx}
          draftReservationId={draftReservationId}
          reservation={reservation as PopulatedCarReservation}
          closeSheet={closeSheet}
          removeDraftReservation={removeDraftReservation}
        />
      )}

      {category === "conference" && (
        <ConferenceReservationRows
          idx={idx}
          draftReservationId={draftReservationId}
          reservation={reservation as PopulatedConferenceReservation}
          closeSheet={closeSheet}
          removeDraftReservation={removeDraftReservation}
        />
      )}

      {category === "eatery" && (
        <EateryReservationRows
          idx={idx}
          draftReservationId={draftReservationId}
          reservation={reservation as PopulatedEateryReservation}
          closeSheet={closeSheet}
          removeDraftReservation={removeDraftReservation}
        />
      )}

      {category === "hotel" && (
        <HotelReservationRows
          idx={idx}
          draftReservationId={draftReservationId}
          reservation={reservation as PopulatedHotelReservation}
          closeSheet={closeSheet}
          removeDraftReservation={removeDraftReservation}
        />
      )}

      {category === "tour" && (
        <TourReservationRows
          idx={idx}
          draftReservationId={draftReservationId}
          reservation={reservation as PopulatedTourReservation}
          closeSheet={closeSheet}
          removeDraftReservation={removeDraftReservation}
        />
      )}

      {category === "rental" && (
        <RentalReservationRows
          idx={idx}
          draftReservationId={draftReservationId}
          reservation={reservation as PopulatedRentalReservation}
          closeSheet={closeSheet}
          removeDraftReservation={removeDraftReservation}
        />
      )}
    </div>
  );
};
