import {Statistic} from 'antd';
import {FC, useContext} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {
  CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis,
} from 'recharts';
import {AffiliateStateContext} from '../../../context/affiliate';

export const AffiliateDashboard: FC<RouteComponentProps> = () => {
  const {state} = useContext(AffiliateStateContext);

  return (
    <div className="overflow-y-scroll">
      <div className="py-4 px-4 border-b sm:flex sm:flex-row sm:justify-between sm:flex-wrap">
        <Statistic
          className="px-4 m-2 max-w-max sm:flex-shrink-0"
          title="Number of affiliate links"
          value={state.affiliateLinks.length}
          valueStyle={{
            fontWeight: 600,
            fontSize: 48,
          }}
          suffix="links"
        />

        <Statistic
          className="px-4 m-2 max-w-max sm:flex-shrink-0"
          title="Number of impressions (IMP)"
          value={state.affiliateSummary.impressions}
          valueStyle={{
            fontWeight: 600,
            fontSize: 48,
          }}
          suffix="IMP"
        />

        <Statistic
          className="px-4 m-2 max-w-max sm:flex-shrink-0"
          title="Number of Follow throughs (FT)"
          value={state.affiliateSummary.follow_throughs}
          valueStyle={{
            fontWeight: 600,
            fontSize: 48,
          }}
          suffix="FTs"
        />
      </div>

      <div className="p-4 my-4">
        <div>
          <div className="flex flex-col items-center mb-4">
            <h6 className="text-xl font-semibold">
              Follow throughs over the month
            </h6>
            <p className="text-sm opacity-70">
              Chart of follow throughs n each day of month
            </p>
          </div>
          <LineChart
            className="h-full w-full"
            data={state.affiliateSummary.currentMonth}
            height={400}
            width={window.innerWidth - 72 - 64}
          >
            <Tooltip />
            <CartesianGrid stroke="#B0002033" />
            <XAxis dataKey="date" label="Date of month" />
            <YAxis dataKey="impressions" />
            <Line dataKey="impressions" type="monotone" stroke="#FF5900" />
            <Line dataKey="follow_throughs" type="monotone" stroke="#0a7fb5" />
          </LineChart>
        </div>
      </div>
    </div>
  );
};
