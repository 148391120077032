import {auth, database, firestore} from "firebase";

import {OnCompleteCallback, Property, ReservationCategory} from "..";

import {PopulatedReservation, Reservation} from "./types";

export const fetchReservation = async (
  id: string, onComplete: OnCompleteCallback<Reservation>,
): Promise<void> => {
  try {
    const snapshot =
      await firestore().collection("reservation").doc(id).get();
    // make sure that the id is fetched before returning it to the front end
    const val = {id: snapshot.id, ...snapshot.data()} as Reservation;

    onComplete(null, val);
  } catch (err) {
    // error occured while fetching the doc with the specified id
    const fetchError = new Error("An Error occured while fetching the doc");
    onComplete(fetchError, null);
  }
};

export const fetchClientReservations = async (
  userId: string, onComplete: OnCompleteCallback<Reservation[]>,
): Promise<void> => {
  try {
    const snapshot = await firestore().collection("reservation")
      .where("user", "==", userId)
      .get();
    const results: Reservation[] = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }) as Reservation);

    onComplete(null, results);
  } catch (err) {
    console.error(err);
    // there was a problem fetching the documents associated with this user.
    const fetchErr = new Error("Unable to process the request");

    onComplete(fetchErr, null);
  }
};

export const fetchPartnerReservations = async (
  ownerId: string, onComplete: OnCompleteCallback<Reservation[]>,
): Promise<void> => {
  try {
    const snapshot = await firestore().collection("reservation")
      .where("owner", "==", ownerId)
      .get();
    const results: Reservation[] = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }) as Reservation);

    onComplete(null, results);
  } catch (err) {
    // error fetching the documents that contain all the property reservations
    const fetchErr = new Error(
      "Unable to fetch the documents of the specified partner");

    onComplete(fetchErr, null);
  }
};

export const fetchPopulatedReservations = (
  reservations: Reservation[], onComplete: OnCompleteCallback<PopulatedReservation[]>,
): void => {
  const populatedReservations: PopulatedReservation[] = [];
  let error: Error | null = null;

  const addReservation = (reservation: PopulatedReservation) => {
    populatedReservations.push(reservation);

    if (populatedReservations.length === reservations.length) {
      onComplete(null, populatedReservations);
    }
  };

  reservations.map(async (reservation, idx) => {
    try {
      const snapshot = await firestore()
        .collection(reservation.category)
        .doc(reservation.property_id.split(":")[0])
        .get();
      const property = ({id: snapshot.id, ...snapshot.data()} as Property);
      // add the property to make it easier to fetch the property
      if (!reservation.user) {
        addReservation({...reservation, property});
      } else {
        const userSnapshot =  await database()
          .ref(`Users_Master/${reservation.user}`)
          .once("value");
  
        let username = "Anonymous";
        
        if (userSnapshot.val()) {
          username = userSnapshot.val().fNLName
        }

        addReservation({...reservation, property, user: username});
      }
    } catch (err) {
      error = new Error("An error occured populating the reservations"); 
      error && onComplete(error, null);
      !error && onComplete(null, populatedReservations);
    }
  });
};

export const postReservation = async (
  reservation: Reservation, onComplete: OnCompleteCallback<Reservation>,
): Promise<void> => {
  const user = auth().currentUser;

  if (user !== null) {
    // fetch owner details from the property id
    const snapshot = await firestore().collection(reservation.category)
      .doc(reservation.property_id.split(":")[0])
      .get();
    const property = ({id: snapshot.id, ...snapshot.data()} as Property);

    if (property.owner) {
      reservation.owner = property.owner;
      reservation.user = user.uid;

      // populate the firebase database with the new reservation
      const docReference =
        await firestore().collection("reservation").add(reservation);
      const snapshot = await docReference.get();

      // return a successful callback to the user after successful addition
      onComplete(null, ({id: snapshot.id, ...snapshot.data()} as Reservation));
    } else {
      const ownerNotFoundError = new Error("Owner not found");
      onComplete(ownerNotFoundError, null);
    }
  } else {
    const noUserError = new Error("User details were not found");

    onComplete(noUserError, null);
  }
};

/**
 * Fetched all the property reservations that have happened since the beginning
 * of that month and uses them to make a summary.
 *
 * @param ownerId this is the id of the user who owns the properties
 * @param category this is the category of the properties
 * @param onComplete callback called on completion with either the error
 * or the response
 */
export const fetchCategoryReservations = async (
  ownerId: string,
  category: ReservationCategory,
  onComplete: OnCompleteCallback<Reservation[]>,
): Promise<void> => {
  const monthBeginning = new Date();
  monthBeginning.setDate(1);
  monthBeginning.setHours(0, 0, 0, 0);

  try {
    const snapshot = await firestore().collection(category)
      .where("owner_id", "==", ownerId)
      .where("category", "==", category)
      .where("reservation_date", ">=", monthBeginning.getTime())
      .get()
    const {docs} = snapshot;
  
    const reservations: Reservation[] = docs.map(
      (doc) => ({id: doc.id, ...doc.data()}) as Reservation);
    
    onComplete(null, reservations);
  } catch (err) {
    const fetchError = new Error("An error occured while fetching details");

    onComplete(fetchError, null);
  }
};

/**
 * This fetches the reservations of the property.
 *
 * @param category category of the property
 * @param propertyId this is the id of the property
 */
export const fetchPropertyReservations = async (
  category: ReservationCategory, propertyId: string,
  onComplete: OnCompleteCallback<Reservation[]>,
): Promise<void> => {
  // to prevent fetching all the reservations for security reasons
  // We fetch only the ones that are associated with this
  // specific user in this category to narrow down scope and also
  // needed computation.
  const user = auth().currentUser;

  if (user) {
    const snapshot = await firestore().collection("reservation")
      .where("category", "==", category)
      .where("owner", "==", user.uid)
      .get();
    const {docs} = snapshot;

    // get all as reservations but only filter out the reservations that
    // have the property id indicated in the function
    // NOTE: Since there are some properties that contain sub properties
    // that are noted by the presence of their id concatted to the property id
    // using : e.g property_id:sub_property_id.
    // By default in order to fetch the id, we splic based on the ':' in order
    // to makes sure that we have the main property id as the first index,
    // after the fact, we compare the propertyId against the value contained in
    // the first index
    const reservations: Reservation[] =
      docs
        .map((doc) => ({id: doc.id, ...doc.data()}) as Reservation)
        .filter(
          (reservation) =>
            reservation.property_id.split(":")[0] === propertyId
        );
    onComplete(null, reservations);
  } else {
    const noUserFoundError = new Error("No user found in system");

    onComplete(noUserFoundError, null);
  }
};

export const postReservations = (
  reservations: Reservation[],
  onComplete: OnCompleteCallback<Reservation[]>
): void => {
  const postedReservations: Reservation[] = [];

  const postNewReservation = (idx: number): void => {
    if (idx === reservations.length) {
      console.log('Finished posting reservations');
      onComplete(null, postedReservations);

      return;
    }

    postReservation(reservations[idx], (err, postedReservation) => {
      if (err) {
        const postError = new Error("An error occured posting the reservations");
      
        console.error(postError);
      }

      if (postedReservation) {
        postedReservations.push(postedReservation);
      }

      postNewReservation(idx + 1);
    });

  }

  postNewReservation(0);
};
