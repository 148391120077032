import {firestore} from 'firebase';
import { ApiFunc, Coordinates, OnCompleteCallback, Picture } from '..';
import { uploadLogo, uploadPictures } from '../cdn';
import { getUser, NoUserFoundError } from '../user';
import type {Hotel} from './types';
export type {Hotel} from './types';

/**
 * This fetches the hotel based on the id given to it. After attempting to fetch
 * the callback passed on to the function has the option of sending back
 * different data attributes based on the status of the fetch request from
 * firebase.
 * This function could return an error which may declare that somthing went
 * wrong during the fetch, or it could return the data about the hotel based on
 * the  id given to it.
 * @param id this is the unique identifier of the hotel in question.
 * @param onFetch this is the callback that will be called once the fetch
 * request is done.
 */
export const fetchHotel = async (
  id: string, onFetch: (err: Error | null, data: Hotel | null) => void,
): Promise<void> => {
  console.log(`Fetch hotel with ID: ${id}`);
  const user = getUser();
  if (user) {
    try {
      const snapshot = await firestore().collection("hotel").doc(id).get();
      const hotel = {id: snapshot.id, ...snapshot.data()} as Hotel;

      if (user.uid !== hotel.owner) {
        onFetch(new Error("Property owner mismatch"), null);
        return;
      }
  
      onFetch(null, hotel);
    } catch (err) {
      const fetchError = new Error("An error occured while fetching hotel");
      onFetch(fetchError, null);
    }
  } else {
    onFetch(new NoUserFoundError(), null);
  }
};

export const fetchAvailableHotelRooms = async (
  hotelId: string, checkin: number, onComplete: OnCompleteCallback<Hotel>,
): Promise<void> => {
  try {
    const response = await fetch(
      "https://us-central1-safarixpertz.cloudfunctions.net/fetch_available_hotel_rooms",
      {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          hotelId,
          checkin,
        }),
      },
    );

    if (response.status === 200) {
      const hotel = (await response.json()) as Hotel;

      onComplete(null, hotel);
    } else {
      const fetchError = new Error("Error fetching Hotel Rooms");
      onComplete(fetchError, null);
    }
  } catch (err) {
    const fetchErr = new Error("An error occured while fetching");
    onComplete(fetchErr, null);
  }
};

export const fetchHotels: ApiFunc<Hotel[]> = async (onComplete) => {
  const user = getUser();

  if (user) {
    try {
      const snapshot = await firestore().collection("hotel")
        .where("owner", "==", user.uid)  
        .get();
      const {docs} = snapshot;
  
      const hotels: Hotel[] = [];
      docs.forEach((doc) => {
        hotels.push({id: doc.id, ...doc.data()} as Hotel);
      });
  
      onComplete(null, hotels);
    } catch (err) {
      const fetchError = new Error("An error occured while fetching hotels");
      onComplete(fetchError, null);
    }
  } else {
    const userNotFoundError = new Error("User not found");
    onComplete(userNotFoundError, null);
  }
};

export const filterHotels = async (
  location: string, coords: Coordinates, checkin: number,
  onComplete: OnCompleteCallback<Hotel[]>,
): Promise<void> => {
  try {
    const response = await fetch(
      "https://us-central1-safarixpertz.cloudfunctions.net/fetch_hotels",
      {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          location,
          coords,
          checkin,
        }),
      },
    );

    if (response.status === 200) {
      const hotels = (await response.json()) as Hotel[];

      onComplete(null, hotels);
    } else {
      const fetchError =  new Error("Error fetching hotels");

      onComplete(fetchError, null);
    }
  } catch (error) {
    const fetchErr = new Error("An error occured while fetching");
    onComplete(fetchErr, null);
  }
};

export const postHotel = (
  hotel: Hotel, onComplete: OnCompleteCallback<Hotel>,
): void => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);

    return;
  }

  // we fetch all the pictures from the hotel facility itself, and also all
  // the pictues from the rooms themselves.
  // We then create an array (Buffer) that records how many pictures we are
  // uploading for each, this will be used to get back all the pictures in order
  // of so as to re-order them back to their respective places without a hassle.
  //
  // E.g if we have a hotel facility with 6 pictures of its own and three
  // hotel rooms with pictures of their own, then we can create the buffer
  // as follows:
  // buffer = [6, 6, 7, 8];
  // then an array of pictures to upload is created with a total of
  // (6 + 6 + 7 + 8) images, where:
  // they are ordered based on:
  // hotel facility first, rooms based on the index of the room,
  // As such, these pictures can be gotten back by splicing and then pushing
  // back in order of index starting with the hotel facility as the first
  // index (0)

  const bufferSizes: number[] = [];
  const imageBuffer: Picture[] = [];

  bufferSizes.push(hotel.gallery.length);
  imageBuffer.push(...hotel.gallery);

  // push all the pictures for each room
  hotel.rooms.forEach((room) => {
    bufferSizes.push(room.gallery.length);
    imageBuffer.push(...room.gallery);
  });


  // after all the images are pushed we can upload them all and then replace
  // after successful upload
  uploadPictures("hotel", imageBuffer, async (err, uploadedImages) => {
    if (err) {
      onComplete(err, null);
      return;
    }

    if (uploadedImages) {
      hotel.gallery = uploadedImages.splice(0, bufferSizes[0]);

      // starting from the second index, we start assigning the rooms all their
      // images back
      for (let i=1; i<bufferSizes.length; i++) {
        hotel.rooms[i - 1].gallery = uploadedImages.splice(0, bufferSizes[i]);
      }

      // after all the images are uploaded we can now get to uploading the
      // confernce data
      const uploadHotelDetails = async () => {
        try {
          const doc =  await firestore()
            .collection("hotel")
            .add({...hotel, owner: user.uid} as Hotel);
  
          const hotelSnapshot = await doc.get();
          const hotelData = {
            id: doc.id,
            ...hotelSnapshot.data(),
          } as Hotel;
  
          onComplete(null, hotelData);
        } catch (err) {
          const hotelUploadError =
            new Error("Error uploading hotel facility details");
          onComplete(hotelUploadError, null);
        }
      };

      // check if there is a logo to upload
      if (Object.prototype.hasOwnProperty.call(hotel, "logo")) {
        // if the hotel logo is undefined even though present, we delete it
        // from the hotel object
        if (hotel.logo === undefined) {
          delete hotel.logo;
          uploadHotelDetails();

          return;
        }

        // if the hotel logo is not undefined, we upload it
        uploadLogo(hotel.logo, (err, uploadedLogo) => {
          if (err) {
            onComplete(err, null);

            return;
          }

          if (!uploadedLogo) {
            onComplete(new Error("Unable to upload hotel details"), null);

            return;
          }

          hotel.logo = uploadedLogo;

          uploadHotelDetails();
        });
      } else {
        uploadHotelDetails();
      }
    } else {
      // these images may be null
      const uploadErr = new Error("The images to be uploaded returned null");
      onComplete(uploadErr, null);
    }
  });
}

export const updateHotel = (
  hotelId: string, hotel: Hotel, onComplete: OnCompleteCallback<Hotel>,
): void => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);

    return;
  }

  // we fetch all the pictures from the hotel facility itself, and also all
  // the pictues from the rooms themselves.
  // We then create an array (Buffer) that records how many pictures we are
  // uploading for each, this will be used to get back all the pictures in order
  // of so as to re-order them back to their respective places without a hassle.
  //
  // E.g if we have a hotel facility with 6 pictures of its own and three
  // hotel rooms with pictures of their own, then we can create the buffer
  // as follows:
  // buffer = [6, 6, 7, 8];
  // then an array of pictures to upload is created with a total of
  // (6 + 6 + 7 + 8) images, where:
  // they are ordered based on:
  // hotel facility first, rooms based on the index of the room,
  // As such, these pictures can be gotten back by splicing and then pushing
  // back in order of index starting with the hotel facility as the first
  // index (0)

  const bufferSizes: number[] = [];
  const imageBuffer: Picture[] = [];

  bufferSizes.push(hotel.gallery.length);
  imageBuffer.push(...hotel.gallery);

  // push all the pictures for each room
  hotel.rooms.forEach((room) => {
    bufferSizes.push(room.gallery.length);
    imageBuffer.push(...room.gallery);
  });


  // after all the images are pushed we can upload them all and then replace
  // after successful upload
  uploadPictures("hotel", imageBuffer, async (err, uploadedImages) => {
    if (err) {
      onComplete(err, null);
      return;
    }

    if (uploadedImages) {
      hotel.gallery = uploadedImages.splice(0, bufferSizes[0]);

      // starting from the second index, we start assigning the rooms all their
      // images back
      for (let i=1; i<bufferSizes.length; i++) {
        hotel.rooms[i - 1].gallery = uploadedImages.splice(0, bufferSizes[i]);
      }

      // after all the images are uploaded we can now get to uploading the
      // confernce data
      const uploadHotelDetails = async () => {
        try {
          await firestore()
            .collection("hotel")
            .doc(hotelId)
            .update({...hotel} as Hotel);
          
          const doc = await firestore().collection("hotel")
            .doc(hotelId);
          
          const updatedHotelData = {
            id: doc.id,
            ...(await doc.get()).data(),
          } as Hotel;
          onComplete(null, updatedHotelData);
        } catch (err) {
          const hotelUploadError =
            new Error("Error uploading hotel facility details");
          onComplete(hotelUploadError, null);
        }
      };
      
      // check if there is a logo to upload
      if (Object.prototype.hasOwnProperty.call(hotel, "logo")) {
        // if the hotel logo is undefined even though present, we delete it
        // from the hotel object
        if (hotel.logo === undefined) {
          delete hotel.logo;
          uploadHotelDetails();

          return;
        }

        // if the hotel logo is not undefined, we upload it
        uploadLogo(hotel.logo, (err, uploadedLogo) => {
          if (err) {
            onComplete(err, null);

            return;
          }

          if (!uploadedLogo) {
            onComplete(new Error("Unable to upload hotel details"), null);

            return;
          }

          hotel.logo = uploadedLogo;
          uploadHotelDetails();

        });
      } else uploadHotelDetails();
    } else {
      // these images may be null
      const uploadErr = new Error("The images to be uploaded returned null");
      onComplete(uploadErr, null);
    }
  });
}
