import {FC} from 'react';

import {FAQ} from "../../../api";

import {OverlayProvider} from '../../search-property/context';

import {EditFAQ} from '.';
import { FaPlusCircle } from 'react-icons/fa';

type EditFaqListItemProps = {
  faqs: FAQ[];
  error?: string;
  onSave: (newFaq: FAQ[]) => void;
};

export const EditFaqListItem: FC<EditFaqListItemProps> = ({
  faqs, error, onSave,
}) => {
  return (
    <OverlayProvider.Consumer>
      {({setIsOverlay, setOnOverlay}) => (
        <div className="py-2">
          <div
            className={
              "border flex flex-row justify-between shadow-md " +
              "px-4 py-2 items-center cursor-pointer"
            }
            onClick={() => {
              setOnOverlay && setOnOverlay(() => () => (
                <EditFAQ
                  faqs={faqs}
                  onSave={onSave}
                  removeOverlay={() => {
                    setOnOverlay && setOnOverlay(null);
                    setIsOverlay && setIsOverlay(false);
                  }}
                />
              ));

              // display the overlay
              setIsOverlay && setIsOverlay(true);
            }}
          >
            <div>
              <h6 className="text-xl font-semibold">
                Frequently Asked Questions
              </h6>
              <p className="text-xs opacity-70">
                Press to edit the frequently asked questions
              </p>
            </div>
            {/* <div className="h-6 w-6 bg-indigo-300"></div> */}
            <FaPlusCircle size={24} color="#FF5900" />
          </div>
          {error && (
            <p className="mt-1 text-xs text-red-500 pl-2">
              {error}
            </p>
          )}
        </div>
      )}
    </OverlayProvider.Consumer>
  );
};
