// @ts-check
import Lottie from "react-lottie";

import animation from "../../assets/anim/loading_1.json";
import empty_search from "../../assets/anim/empty_search.json";

import ItemHotel from "./Items/Item_Hotel";
import {MobileFilterContext} from "./mobile-filter-context";
import {MobileFilterColumn} from "./components/mobile-filter-column";
import {NewSearchFab} from "./components/new-serach-fab";
import {NewSearch} from "./components";
import {RatingFilter} from "./components/rating-filter";
import {PriceFilter} from "./components/price-filter";
import {FeaturesFilter} from "./components/features-filter";
import {FacilitiesFilter} from "./components/facilities-filter";
import { SearchCard } from "../../components/search-card";

/**
 * This section,
 *
 * @param {{
 * sharedState: any,
 * origin: string,
 * updateState: any,
 * }} param0 
 *
 * @returns {JSX.Element}
 */
function App({sharedState, origin, updateState}) {
  console.warn("This is the Current URL:");
  console.log(location.href);

  return (
    <div
      className="flex flex-col pt-5"
    >
      <MobileFilterContext.Consumer>
        {({newSearchFilter, visibleFilter}) => (
          <div className="w-11/12 max-w-lg self-center">
            {/* We constraint the width of the container div to make sure that
            it does not exceed 260px to make it not look abnormally large */}
            {/* Only rendered when there is a filter chosen by the user or
            when the user wants to conduct a new search */}
            {(newSearchFilter || visibleFilter !== "none") && (
              <div className="desktop-hidden">
                {newSearchFilter && (
                  <NewSearch
                    updateState={updateState}
                  />
                )}
                {/* Ensure that all search Filters are visible here */}
                {visibleFilter === "rating" && (
                  <RatingFilter updateState={updateState} />
                )}
                {visibleFilter === "price" && (
                  <PriceFilter
                    sharedState={sharedState}
                    updateState={updateState}
                  />
                )}
                {visibleFilter === "features" && (
                  <FeaturesFilter
                    sharedState={sharedState}
                    updateState={updateState}
                  />
                )}
                {visibleFilter === "facilities" && (
                  <FacilitiesFilter
                    sharedState={sharedState}
                    updateState={updateState}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </MobileFilterContext.Consumer>
      {!sharedState.searchDone ? (
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: animation,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          style={{ marginTop: "20vh", marginBottom: "35vh" }}
          height={100}
          width={100}
        />
      ) : (
        <MobileFilterContext.Consumer>
          {({visibleFilter}) => (
            <>
              {/*
                This filter can only be changed in a mobile interface, as such
                by default cannot be intefered with in desktop and laptop UIs.
                It is only meant to show filters in interfaces where the
                LeftColumn is not visible.
              */}
              {visibleFilter !== "none" && (
                <MobileFilterColumn />
              )}
              {sharedState.searchResults.length > 0 ? (
                <>
                  {sharedState.searchResults
                    .slice(
                      (sharedState.pagination - 1) * 10,
                      sharedState.pagination * 10
                    )
                    .map((data, idx) => {
                      switch (sharedState.category) {
                        case "hotel":
                          return (
                            <ItemHotel
                              data={data}
                              days={sharedState.numberOfDays}
                              urlParameters={sharedState.urlParameters}
                              origin={origin}
                              selectedCurrency={
                                sharedState.selected_currency
                              }
                              selectedMultiplex={
                                sharedState.selected_multiplex
                              }
                            />
                          );
                        case "rental":
                          return (
                            <SearchCard
                              action={{
                                label: "View Availability",
                                onClick: () => console.log("Viewing Rental"),
                              }}
                              comments={data.reviews}
                              cover={"/public/rental-0.jpg"}
                              description={data.long_description}
                              duration={"29 Days"}
                              location={data.location}
                              price={data.price}
                              rating={5}
                              title={data.title}
                              key={data.uid}
                              style={{marginBottom: 12}}
                              origin={origin}
                              item={data}
                              idx={idx}
                            />
                          );
                        case "flight":
                          return (
                            <SearchCard
                              action={{
                                label: "View Flight",
                                onClick: () => console.log("Viewing Flight"),
                              }}
                              comments={[]}
                              cover="/public/flight.jpg"
                              description=""
                              title={`Flight from ${
                                  data.airports.origin.name
                                } to ${
                                  data.airports.destination.name
                                }
                              `}
                              duration={"1 hour"}
                              location={data.cabin}
                              price={data.price}
                              rating={5}
                              style={{marginBottom: 16}}
                              origin={origin}
                              item={data}
                              idx={idx}
                            />
                          );
                        case "cruises":
                          return (
                            <SearchCard
                              action={{
                                label: "View Cruise",
                                onClick: () => console.log("Viewing Cruise")
                              }}
                              comments={[]}
                              cover="/public/cruise.jpg"
                              description={data.long_description}
                              key={data._id}
                              price={data.price}
                              duration={data.duration}
                              location={data.origin}
                              rating={5}
                              style={{marginBottom: 16}}
                              title={data.title}
                              origin={origin}
                              item={data}
                              idx={idx}
                            />
                          );
                        case "eateries":
                          return (
                            <SearchCard
                              action={{
                                label: "View Eatery",
                                onClick: () => console.log("Viewing Eatery"),
                              }}
                              comments={[]}
                              cover="/public/eatery.jpg"
                              description={data.long_description}
                              duration=""
                              location={data.location}
                              price={null}
                              rating={5}
                              style={{marginBottom: 16}}
                              title={data.title}
                              origin={origin}
                              item={data}
                              idx={idx}
                            />
                          );
                        case "conferencing":
                          return (
                            <SearchCard
                              action={{
                                label: "View Facility",
                                onClick: () => console.log("Viewing Conference")
                              }}
                              comments={[]}
                              cover="/public/conference.jpg"
                              description={data.long_description}
                              duration=""
                              location={data.location}
                              price={data.price.day}
                              rating={5}
                              style={{marginBottom: 16}}
                              title={data.title}
                              origin={origin}
                              item={data}
                              idx={idx}
                            />
                          );
                        case "tours":
                          return (
                            <SearchCard
                              action={{
                                label: "View Package",
                                onClick: () => console.log("Viewing Tour Pack"),
                              }}
                              comments={[]}
                              cover="/public/tour.jpg"
                              description={data.long_description}
                              duration="2 Days"
                              location={data.origin}
                              price={data.price}
                              rating={5}
                              style={{marginBottom: 16}}
                              title={data.title}
                              origin={origin}
                              item={data}
                              idx={idx}
                            />
                          );
                        default:
                          return null;
                      }
                    })}
                </>
              ) : (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: empty_search,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                    style={{ marginTop: "10vh", marginBottom: 50 }}
                    height={200}
                    width={200}
                  />
                  <h3 style={{ textAlign: "center", color: "#777" }}>
                    Your search did not return any results
                  </h3>
                  <p
                    style={{
                      textAlign: "center",
                      color: "#ccc",
                      maxWidth: 400,
                      alignSelf: "center",
                    }}
                  >
                    Please make sure your search parameters were rightly entered
                  </p>
                </div>
              )}
            </>
          )}
        </MobileFilterContext.Consumer>
      )}
      <NewSearchFab searchDone={sharedState.searchDone} />
    </div>
  );
}

export default App;
