import { BrowserRouter, Switch, Route } from "react-router-dom";

import Landing from './components/index'
import Session from './components/Session'
import Header from '../index/components/Header'
import {AppFooter} from '../index/components/app-footer'


/**
 * Once a user lands on this screen, they are given the option to first confirm
 * their details, this is from the AuthContainer and then after given the option
 * to create a new trip or edit the details of an existing trip.
 * Once the user creates a new trip, or selects a previous trip, they are taken
 * to the Trip planner `Session` to add and remove from the trip.
 *
 * @returns {JSX.Element}
 */
const App = () => {
  return (
    <>
    <Header origin="trip-planner" />
    <BrowserRouter>
      <Switch>
        
        <Route path="/trip-planner" component={Landing} exact />
        <Route path="/trip-planner/:session" component={Session} />
      </Switch>
    </BrowserRouter>
    <AppFooter origin="trip-planner" />
    </>
  );
}

export default App;
