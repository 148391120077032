import {FC, useContext} from "react";
import {Select} from "antd";

import Google_Play from "../../../assets/google_play.png";
import Apple_Store from "../../../assets/app_store.png";
import {AppStateContext} from "../../../context/app-state-provider";
import { Link } from "react-router-dom";

type AppFooterProps = {
  origin: string;
};

export const AppFooter: FC<AppFooterProps> = ({origin}) => {
  const {
    actions, state,
  } = useContext(AppStateContext);

  return (
    <footer className="footer-parent">
      {origin !== "trip-planner" && (
        <div
          className="app-section"
          style={{ position: "relative", overflow: "hidden", height: 200 }}
        >
          <div className="top-background safari-grad"> </div>
          <div
            className="flex-div"
            style={{ position: "absolute", width: "100%" }}
          >
            <div
              className="display-port"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="footer-download-content">
                <h1 className="text-xl font-lato font-semibold text-white">
                  Download our mobile App
                </h1>
                <p>
                  Get access to a plethora of features from our site and so much
                  more through our mobile app today.
                </p>
                <div style={{ display: "flex" }}>
                  <img
                    alt="Get it on Google Play"
                    src={Google_Play}
                    style={{
                      margin: 15,
                      marginLeft: 0,
                      height: 50,
                      width: "auto",
                    }}
                  />
                  <img
                    alt="Download on the AppStore"
                    src={Apple_Store}
                    style={{ margin: 15, height: 50, width: "auto" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div>
        <div
          className="flex-div"
          style={{ position: "absolute", width: "100%" }}
        >
          <div className="display-port footer-links">
            <div style={{ flex: 1, padding: 20 }}>
              {/* <div style={{ marginBottom: 20 }}>
                <Select
                  options={supported_languages}
                />
              </div> */}
              <div className="flex-shrink-0" style={{ marginBottom: 20 }}>
                <div style={{ color: "white", marginBottom: 15 }}>Currency</div>
                <Select
                  size="large"
                  value={state.ACTIVE_CURRENCY}
                  onChange={(val) => {
                    actions.setActiveCurrency(val);
                  }}
                >
                  {Object.keys(state.COUNTRY_DETAILS).map(
                    (country) =>
                      state.COUNTRY_DETAILS[country].currency.long &&
                      !/\w+,\w+/.test(
                        state.COUNTRY_DETAILS[country].currency.short
                      ) && (
                        <Select.Option
                          key={country}
                          value={state.COUNTRY_DETAILS[country].currency.short}
                        >
                          {`(${
                              state.COUNTRY_DETAILS[country].currency.short
                            }) ${
                              state.COUNTRY_DETAILS[country].currency.long
                            }`}
                        </Select.Option>
                      )
                  )}
                </Select>
              </div>
            </div>
            <div
              style={{
                flex: 1,
                color: "grey",
                flexDirection: "column",
                paddingLeft: 50,
                marginTop: 10,
              }}
            >
              <div style={{ marginBottom: 15, color: "white" }}>
                TRAVEL TOOLS
              </div>
              <Link to="/info/gift_cards">
                <div>Gift cards</div>
              </Link>
              <Link to="/info/customer_support">
                <div>Customer support</div>
              </Link>
              <Link to="/info/how_to_sign_up">
                <div>How to Sign Up</div>
              </Link>
              {/* <div>Site guide</div> */}
              {/* <div>Check my Booking Status</div> */}
              <Link to="/info/affiliate">
                <div>Affliate Program</div>
              </Link>
            </div>
            <div
              style={{
                flex: 1,
                color: "grey",
                flexDirection: "column",
                paddingLeft: 50,
                marginTop: 10,
              }}
            >
              <div style={{ marginBottom: 15, color: "white" }}>
                ABOUT SAFARIXPERTS
              </div>
              <Link to="/info/about_us">
                <div>About Us</div>
              </Link>
              {/* <div>Press Centre</div> */}
              <Link to="/info/careers">
                <div>Careers</div>
              </Link>
              <Link to="/info/social_responsibility">
                <div>Social Responsibility</div>
              </Link>
              <Link to="/info/advertising">
                <div>Advertising</div>
              </Link>
              <Link to="/info/testimonials">
                <div>Client Testimonials</div>
              </Link>
            </div>
            <div
              style={{
                flex: 1,
                color: "grey",
                flexDirection: "column",
                paddingLeft: 50,
                marginTop: 10,
              }}
            >
              <div style={{ marginBottom: 15, color: "white" }}>TERMS</div>
              <Link to="/info/privacy">
                <div>Privacy Policy</div>
              </Link>
              <Link to="/info/terms">
                <div>Terms and Comditions</div>
              </Link>
              <Link to="/info/service_fees">
                <div>Our Service Fees</div>
              </Link>
            </div>
          </div>
          <div
            style={{
              textAlign: "center",
              marginTop: 150,
              color: "grey",
              fontSize: 11,
            }}
          >
            Copyright © 2021 Safarixperts.com from Htolex Ltd. <br />
            All rights reserved
          </div>
        </div>
      </div>
    </footer>
  );
};
