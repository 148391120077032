import { FC } from "react";
import { Car, Property } from "../../../../../api";

import {
  PopulatedActivityReservation,
  PopulatedCarReservation,
  PopulatedConferenceReservation,
  PopulatedEateryReservation,
  PopulatedHotelReservation,
  PopulatedRentalReservation,
  PopulatedReservation,
  PopulatedTourReservation,
} from "../../../../../api/reservation/types";

import {ActivityReservationRows} from "./activity";
import {CarResevationRows} from "./car";
import {ConferenceReservationRows} from "./conference";
import {EateryReservationRows} from "./eatery";
import {HotelReservationRows} from "./hotel";
import {RentalReservationRows} from "./rental";
import {TourReservationRows} from "./tour";

type ReservationCardProps = {
  reservation: Record<string, unknown>;
};

export const ReservationCard: FC<ReservationCardProps> = ({ reservation }) => {
  const { id, user, expected_amount, amount_received, category } =
    reservation as PopulatedReservation;
  return (
    <div key={`${id}`} className="border rounded-md p-2 my-3">
      <div className="py-2 px-4 border-b">
        <h6 className="text-xl font-semibold">{user}</h6>
        <p className="text-xs opacity-70 z-0">
          {category === "car"
            ? `${(reservation.property as Car).reg_no}`
            : `${(reservation.property as Property).title}`}
        </p>
      </div>

      {category === "activity" && (
        <ActivityReservationRows
          reservation={reservation as PopulatedActivityReservation}
        />
      )}

      {category === "car" && (
        <CarResevationRows
          reservation={reservation as PopulatedCarReservation}
        />
      )}

      {category === "conference" && (
        <ConferenceReservationRows
          reservation={reservation as PopulatedConferenceReservation}
        />
      )}

      {category === "eatery" && (
        <EateryReservationRows
          reservation={reservation as PopulatedEateryReservation}
        />
      )}

      {category === "hotel" && (
        <HotelReservationRows
          reservation={reservation as PopulatedHotelReservation}
        />
      )}

      {category === "tour" && (
        <TourReservationRows
          reservation={reservation as PopulatedTourReservation}
        />
      )}

      {category === "rental" && (
        <RentalReservationRows
          reservation={reservation as PopulatedRentalReservation}
        />
      )}

      <div className="grid grid-cols-2 border-b py-2">
        <h6 className="text-xs place-self-center">Expected amount</h6>
        <p className={"text-xl font-semibold place-self-center text-green-600"}>
          {expected_amount}
        </p>
      </div>

      <div className="grid grid-cols-2 border-b border-red-900 py-2">
        <h6 className="text-xs place-self-center">Paid</h6>
        <p
          className={
            "text-base font-semibold place-self-center text-yellow-600"
          }
        >
          {amount_received}
        </p>
      </div>

      <div className="grid grid-cols-2 border-b py-2">
        <h6 className="text-xs place-self-center">Balance</h6>
        <p className={"text-xl font-semibold place-self-center text-red-700"}>
          {expected_amount - amount_received}
        </p>
      </div>
    </div>
  );
};
