import {Dispatch, FC, SetStateAction, useState} from 'react';
import {
  AutoComplete, Cascader, Input, Select, DatePicker,
} from 'antd';
import {CascaderOptionType} from 'antd/lib/cascader';
import usePlacesAutocomplete, {
  getGeocode, getLatLng,
} from 'use-places-autocomplete';
import {useHistory, useLocation} from 'react-router';
import {BsDot} from 'react-icons/bs';
import {FaMinus, FaPlus} from 'react-icons/fa';
import useOnclickOutside from 'react-cool-onclickoutside';

import regions from "../../../util/regions.json";
import {Coordinates} from '../../../api';
import {Button} from '../../../components';
import {DateUtil} from '../../../util';

import {AttractionFilterGroup} from './filter-group';

type TourSearchProps = {
  switchToResults?: () => void;
  setAttractionsStale: Dispatch<SetStateAction<boolean>>;
};

const TOMORROW = new Date();
TOMORROW.setDate(TOMORROW.getDate() + 1);

export const TourSearch: FC<TourSearchProps> = ({
  switchToResults, setAttractionsStale,
}) => {
  const history = useHistory();
  const searchParams = new URLSearchParams(useLocation().search);

  const searchedAdults = searchParams.get("adults");
  const searchedChildren = searchParams.get("children");

  const [activeCountry, setActiveCountry] = useState<string>("");
  const [searchCoords, setSearchCoords] = useState<null | Coordinates>(null);
  const {
    value, suggestions: {data},
    setValue, clearSuggestions,
  } = usePlacesAutocomplete({debounce: 300});
  const [date, setDate] = useState<Date>();
  const [adults, setAdults] = useState<number>(
    searchedAdults ? Number(searchedAdults) : 1,
  );
  const [children, setChildren] = useState<number>(
    searchedChildren ? Number(searchedChildren) : 0,
  );

  const [resultsStale, setResultsStale] = useState<boolean>(false);

  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);

  const dropdownContainerRef = useOnclickOutside(() => setDropdownVisible(false));

  const isButtonDisabled = (): boolean => {
    return (
      value === "" || activeCountry === "" || !searchCoords || resultsStale
    );
  };

  return (
    <div className="w-full px-4 pb-4">
      <h6 className="text-xl font-semibold mb-3">Search for attractions</h6>
      <p className="text-xs mb-1">Pick a country</p>
      <Cascader
        className="w-full mb-3"
        placeholder="Select country"
        changeOnSelect
        options={Object.keys(regions).map((region) => {
          const option: CascaderOptionType = {
            value: region,
            label: region,
            children: regions[
              region as "Asia" | "Europe" | "Oceania" | "Americas" | "Africa"
            ].map((country) => {
              return {
                value: country.name,
                label: country.name,
              } as CascaderOptionType;
            }),
          };

          return option;
        })}
        onChange={(val) => {
          // an array containing the values selected
          // if only one is selected it onlu contains the
          // continent,
          // otherwise it contains the continent and the county
          // i.e ["Asia"] or ["Asia", "India"]
          if (val.length === 2) {
            setActiveCountry(`${val[1]}`);
            setResultsStale(false);
          }
        }}
      />
      <p className="text-xs mb-1">Select specific location</p>
      <AutoComplete
        className="w-full mb-3"
        onSelect={(val) => {
          setValue(val, false);
          // find the item with the given val
          const [item] = data.filter((field) => (
            `${field.structured_formatting.main_text}, ${
            field.structured_formatting.secondary_text
          }` === val
          ));
          clearSuggestions();

          // get the geo coordinates since they will be used to search in the
          // backend by coordinates
          getGeocode(({address: item.description}))
            .then((results) => getLatLng(results[0]))
            .then(({lat, lng}) => {
              setSearchCoords({lat, lng});
              setResultsStale(false);
            });
        }}
        options={data.map((val) => ({
          value: `${val.structured_formatting.main_text}, ${
            val.structured_formatting.secondary_text
          }`,
        }))}
      >
        <Input
          className="w-full"
          value={value}
          onChange={(val) => {
            setValue(val.target.value);
            setResultsStale(false);
          }}
          placeholder={`Place${activeCountry && (", " + activeCountry)}`}
        />
      </AutoComplete>
      <p className="text-xs mb-1">Select tourist attraction categories</p>
      <Select
        className="w-full mb-3"
        mode="multiple"
        placeholder="select attraction category"
      >
        <Select.Option key="parks" value="National Parks">
          National Parks
        </Select.Option>

        <Select.Option key="reserves" value="Game Reserves">
          Game Reserves
        </Select.Option>
      </Select>
      <p className="text-xs mb-1">
        What date would you like to see the attraction?
      </p>
      <DatePicker
        className="w-full mb-3"
        onChange={(val) => {
          if (val) {
            setDate(val.toDate());
          }
        }}
      />
      <p className="text-xs mb-1">People attending</p>
      <div
        className={
          "flex flex-row items-center py-1 justify-center border mb-3 " +
          "dropdown relative"
        }
        onMouseEnter={() => {
          setDropdownVisible(true);
        }}
      >
        <p
          className="text-md opacity-70 cursor-pointer"
          onClick={() => setDropdownVisible(true)}
        >
          {`${adults} adult(s)`}
        </p>
        <BsDot />
        <p
          className="text-md opacity-70 cursor-pointer"
          onClick={() => setDropdownVisible(true)}
        >
          {`${children} children`}
        </p>

        {/* Dropdown used to set the children and parents */}
        <div
          ref={dropdownContainerRef}
          className={
            `w-36 z-10 bg-white border shadow-md opacity-100 bg-opacity-100 ${
              dropdownVisible ? "block" : "hidden"
            } absolute top-full px-4 py-2`
          }
        >
          <div>
            <div className="font-lato font-semibold text-center">Adults</div>
            <div className="flex flex-row justify-between items-center">
              <div
                className="p-3 rounded-full bg-red-200"
                onClick={() => {
                  setAdults((adults - 1));
                }}
              >
                <FaMinus className="text-red-500" />
              </div>
              <div className="num">
                <b>{adults}</b>
              </div>
              <div
                className="p-3 rounded-full bg-red-200"
                onClick={() => {
                  setAdults((adults + 1));
                }}
              >
                <FaPlus className="text-red-500" />
              </div>
            </div>
            <div className="font-lato font-semibold text-center">Children</div>
            <div className="flex flex-row justify-between items-center">
              <div
                className="p-3 rounded-full bg-red-200"
                onClick={() => {
                  setChildren((children - 1))
                }}
              >
                <FaMinus className="text-red-500" />
              </div>
              <div className="num">
                <b>{children}</b>
              </div>
              <div
                className="p-3 rounded-full bg-red-200"
                onClick={() => {
                  setChildren((children + 1));
                }}
              >
                <FaPlus className="text-red-500" />
              </div>
            </div>
          </div>
        </div>  
      </div>
      <div className="flex flex-row items-center justify-center mb-3">
        <Button
          type="button"
          disabled={isButtonDisabled()}
          onClick={() => {
            // update the URL and then switch to the results
            const searchParams = new URLSearchParams();

            searchParams.append("place", value);
            searchParams.append("lat", `${searchCoords?.lat}`);
            searchParams.append("lng", `${searchCoords?.lng}`);
            searchParams.append(
              "date", DateUtil.stringifyDate(date ? date : TOMORROW),
            );
            searchParams.append("adults", `${adults}`);
            searchParams.append("children", `${children}`);

            history.push(`/search-attraction?${searchParams.toString()}`);
            setResultsStale(true);
            setAttractionsStale(true);
            // screens
            switchToResults && switchToResults();
          }}
        >
          Switch
        </Button>
      </div>

      <AttractionFilterGroup
        title="Game reserve filters"
        filters={["Big 5", "Marine life", "Mountaineuos", "Tropical forest"]}
        onChange={(vals) => {
          console.log(vals);
        }}
      />
    </div>
  );
};
