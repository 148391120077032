import type {FC} from 'react';
import {FaClipboardCheck, FaTrashAlt} from 'react-icons/fa';
import {useHistory} from 'react-router';

import {PopulatedEateryReservation} from '../../api/reservation/types';
import {ReservationRow} from '../../routes/partner/components';
import {getReservedPropertyTitle} from '../../routes/partner/components/reservation-overlay';
import {DateUtil} from '../../util';

type EaterReservationRowsProps = {
  idx: number;
  draftReservationId: string;
  reservation: PopulatedEateryReservation;
  closeSheet: () => void;
  removeDraftReservation: (idx: number) => void;
};

export const EateryReservationRows: FC<EaterReservationRowsProps> = ({
  idx, draftReservationId, reservation, closeSheet, removeDraftReservation,
}) => {
  const history = useHistory();

  return (
    <>
      <ReservationRow
        title="Checkin"
        description={
          DateUtil.changeDateFormatFromDate(
            new Date(reservation.checkin), "DD-MM-YYYY",
          )
        }
      />

      <ReservationRow
        title="Attendees"
        description={`${reservation.people}`}
      />

      <ReservationRow
        title="Table type"
        description={getReservedPropertyTitle("eatery", reservation)}
      />


      <ReservationRow
        title="Number of Rooms"
        description={`${reservation.number_of_tables}`}
      />

      <div className="flex flex-row justify-between items-center justify-items-stretch">
        <div className="border-r flex-1 flex flex-row items-center justify-center">
          <div
            className="flex flex-row items-center cursor-pointer py-2"
            onClick={() => {
              removeDraftReservation(idx);
            }}
          >
            <FaTrashAlt className="text-red-600" size={18} />
            <p className="ml-3 font-semibold text-red-600">Delete</p>
          </div>
        </div>
        <div className="flex-1 flex flex-row justify-center items-center py-2">
          <div
            className="flex flex-row items-center cursor-pointer"
            onClick={() => {
              // property view for hotels url
              // /property/:property_id?
              // /property/hotel/HDEWSpGJLwTCa60OlZo6?place={}&lat={}
              // &lng={}&checkin={}&checkout={}&adults=3&children={}
              // before we continue, we close the sheet to make sure that there
              // is no overlay as we proceed to the next screen
              closeSheet();

              history.push(
                `/property/eatery/${reservation.property.id}?place=${
                  reservation.property.location
                }&lat=${reservation.property.coords.lat}&lng=${ 
                  reservation.property.coords.lng
                }&date=${ 
                  DateUtil.stringifyDate(new Date(reservation.checkin))
                }&adults=${ 
                  reservation.people
                }&children=0&t_id=${draftReservationId}`
              );
            }}
          >
            <FaClipboardCheck className="text-safari-gold" size={18} />
            <p className="ml-3 font-semibold text-safari-gold">Complete</p>
          </div>
        </div>
      </div>
    </>
  );
};
