// @ts-check
////////////////////////////////////////////////////////////////////////////////
// JSDoc Definition
////////////////////////////////////////////////////////////////////////////////

/**
 * @typedef {{
 * uid: string,
 * title: string,
 * description: string,
 * agent: {
 *  name: string,
 *  email: string,
 *  phone_number: string,
 * },
 * discount: number,
 * discount_available: boolean,
 * gallery: string[],
 * long_description: string,
 * location: string,
 * coords: {
 *  lat: number,
 *  lng: number,
 * },
 * price: number,
 * adult_number: number,
 * children_number: number,
 * reviews: import(".").Review[],
 * }} Rental
 */

/**@type {Rental[]} */
export const rentals = [
  {
    adult_number: 2,
    agent: {
      name: "John Doe",
      email: "john-doe@gmail.com",
      phone_number: "0736451163",
    },
    children_number: 3,
    description: "Integer eleifend ante ut nunc condimentum, sed bibendum " +
      "magna feugiat. Vivamus magna nulla, scelerisque ut nisl vitae, tempor " +
      " tempus urna. Phasellus condimentum tempus ultrices. Ut sed lectus" +
      " rhoncus, laoreet leo.",
    discount: 0.03,
    discount_available: true,
    gallery: [],
    location: "Membley, Kenya",
    coords: {lng: 0, lat: 0},
    long_description: "Lorem ipsum dolor sit amet, consectetur adipiscing" +
      " elit. Praesent molestie dui purus, in blandit eros euismod in. Fusce" +
      " faucibus felis ante, eget porttitor diam congue quis. Sed posuere" +
      " eget nisi at viverra. Aliquam non sem eu nulla finibus suscipit nec" +
      " et libero. Orci varius natoque penatibus et magnis dis parturient" +
      " montes, nascetur ridiculus mus. Curabitur sed eros pharetra, aliquet" +
      " mi at, porta dui. Mauris eget viverra neque, vitae placerat magna",
    price: 239,
    title: "Chiromo Rd.",
    uid: "2345-uiod-23456-tyu3",
    reviews: [],
  },
  {
    adult_number: 2,
    agent: {
      name: "Jane Doe",
      email: "jane-doe@gmail.com",
      phone_number: "0728519058",
    },
    children_number: 3,
    description: "Integer eleifend ante ut nunc condimentum, sed bibendum " +
      "magna feugiat. Vivamus magna nulla, scelerisque ut nisl vitae, tempor " +
      " tempus urna. Phasellus condimentum tempus ultrices. Ut sed lectus" +
      " rhoncus, laoreet leo.",
    discount: 0.01,
    discount_available: false,
    gallery: [],
    coords: {lng: 0, lat: 0},
    location: "Membley, Kenya",
    long_description: "Lorem ipsum dolor sit amet, consectetur adipiscing" +
      " elit. Praesent molestie dui purus, in blandit eros euismod in. Fusce" +
      " faucibus felis ante, eget porttitor diam congue quis. Sed posuere" +
      " eget nisi at viverra. Aliquam non sem eu nulla finibus suscipit nec" +
      " et libero. Orci varius natoque penatibus et magnis dis parturient" +
      " montes, nascetur ridiculus mus. Curabitur sed eros pharetra, aliquet" +
      " mi at, porta dui. Mauris eget viverra neque, vitae placerat magna",
    price: 450,
    title: "Muthiga, Upper Kabete",
    uid: "1234-uvwx-09876-abcd",
    reviews: [],
  },
  {
    adult_number: 1,
    agent: {
      name: "Irrelevant Doe",
      email: "irrelevant-doe@gmail.com",
      phone_number: "0728519058",
    },
    children_number: 2,
    description: "Integer eleifend ante ut nunc condimentum, sed bibendum " +
      "magna feugiat. Vivamus magna nulla, scelerisque ut nisl vitae, tempor " +
      " tempus urna. Phasellus condimentum tempus ultrices. Ut sed lectus" +
      " rhoncus, laoreet leo.",
    discount: 0.01,
    discount_available: false,
    gallery: [],
    coords: {lng: 0, lat: 0},
    location: "Membley, Kenya",
    long_description: "Lorem ipsum dolor sit amet, consectetur adipiscing" +
      " elit. Praesent molestie dui purus, in blandit eros euismod in. Fusce" +
      " faucibus felis ante, eget porttitor diam congue quis. Sed posuere" +
      " eget nisi at viverra. Aliquam non sem eu nulla finibus suscipit nec" +
      " et libero. Orci varius natoque penatibus et magnis dis parturient" +
      " montes, nascetur ridiculus mus. Curabitur sed eros pharetra, aliquet" +
      " mi at, porta dui. Mauris eget viverra neque, vitae placerat magna",
    price: 150,
    title: "Muthiga, Upper Kabete",
    uid: "7890-qrst-23456-opqr",
    reviews: [],
  },
  {
    adult_number: 1,
    agent: {
      name: "More Relevant Doe",
      email: "more-relevant-doe@gmail.com",
      phone_number: "0736854957",
    },
    children_number: 2,
    description: "Integer eleifend ante ut nunc condimentum, sed bibendum " +
      "magna feugiat. Vivamus magna nulla, scelerisque ut nisl vitae, tempor " +
      " tempus urna. Phasellus condimentum tempus ultrices. Ut sed lectus" +
      " rhoncus, laoreet leo.",
    discount: 0.01,
    discount_available: true,
    gallery: [],
    coords: {lng: 0, lat: 0},
    location: "Membley, Kenya",
    long_description: "Lorem ipsum dolor sit amet, consectetur adipiscing" +
      " elit. Praesent molestie dui purus, in blandit eros euismod in. Fusce" +
      " faucibus felis ante, eget porttitor diam congue quis. Sed posuere" +
      " eget nisi at viverra. Aliquam non sem eu nulla finibus suscipit nec" +
      " et libero. Orci varius natoque penatibus et magnis dis parturient" +
      " montes, nascetur ridiculus mus. Curabitur sed eros pharetra, aliquet" +
      " mi at, porta dui. Mauris eget viverra neque, vitae placerat magna",
    price: 229,
    title: "Membley, Ruiru",
    uid: "0123-fghi-45678-defg",
    reviews: [],
  },
];
