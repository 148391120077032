import { Activity } from "../../api";

/**
 * Calculates the price it will cost to attend a certain activity based on
 * user input passed to the function and the activity itself.
 *
 * @param adults The number of adults attending the activity
 * @param children The number of children attending the activity
 * @param activity The details about the activity itself
 * @returns The price ot will cost to attend the activity
 */
export const calculateActivityPrice = (
  adults: number, children: number, activity: Activity,
): number => {
  let price = 0;

  price += (adults * activity.price.adult);
  price += (children * activity.price.children);

  return price;
};
