import {FC, useState} from 'react';
import {Card, Pagination} from 'antd';
import {useHistory, useLocation} from 'react-router';
import {GiElephant} from 'react-icons/gi';

import {SafariCard} from '../../../components';
import {AttractionSite} from '../../../api/attraction/types';
import {Coordinates} from '../../../api';
import {DateUtil, DistanceUtil} from '../../../util';

type TourResultsProps = {
  results: AttractionSite[],
  loading: boolean;
};

const TOMORROW = new Date();
TOMORROW.setDate(TOMORROW.getDate() + 1);

export const TourResults: FC<TourResultsProps> = ({
  results, loading,
}) => {
  const history = useHistory();
  const [page, setPage] = useState<number>(1);
  const searchParams = new URLSearchParams(useLocation().search)

  const placeCoordinates: Coordinates = {
    lat: Number(searchParams.get("lat")),
    lng: Number(searchParams.get("lng")),
  };
  const place = searchParams.get("place") ? searchParams.get("place") : "";
  const date = searchParams.get("date");
  const adults = searchParams.get("adults");
  const children = searchParams.get("children");

  return (
    <>
      {loading && (
        [1, 2, 3, 4].map((i) => (
          <Card key={`loading_card_${i}`} loading={true} />
        ))
      )}
      {/* If there are results to show, this screen is rendered */}
      {!loading && (results?.length > 0) && (
        <div className="pb-4 px-4 h-full flex flex-col">
          <div className="py-2 flex flex-row justify-between items-center flex-grow-0 flex-shrink-0">
            <div className="flex flex-row items-center text-xl font-semibold font-lato">
              <div style={{ marginRight: 10 }}>
                <GiElephant />
              </div>
              {`Tourist attractions in: ${place}`}
            </div>
          </div>
          <Pagination
            pageSize={10}
            onChange={(pageNumber) => {
              setPage(pageNumber);
            }}
            total={results.length}
            showTotal={(total, range) => `showing ${range[0]} - ${range[1]} of ${
              total
            } results`}
            responsive={true}
            className="flex-shrink-0 flex-grow-0 self-center"
          />
          <div className="flex-grow justify-self-stretch self-stretch overflow-y-scroll">
            {/* All the results cards are injected here */}
            {results.slice((page - 1) * 10, page * 10).map((result) => (
              <SafariCard
                key={result.id}
                action={{
                  label: "View attraction",
                  onClick: () => {
                    const urlParams = new URLSearchParams();

                    urlParams.append(
                      "date", date ? date : DateUtil.stringifyDate(TOMORROW),
                    );

                    urlParams.append(
                      "adults", adults ? adults : "1",
                    );

                    urlParams.append(
                      "children", children ? children : "0",
                    );

                    history.push(
                      `/view-attraction/${
                        result.id
                      }/details?${
                        urlParams.toString()
                      }`,
                    );
                  },
                }}
                cover={result.gallery[0].url}
                description={result.description ? result.description : (result.content[0].content)}
                idx={0}
                location={result.location}
                origin=""
                title={result.name}
                item={result}
                subtitle={`${
                  DistanceUtil.haversineDistance(
                    "km", placeCoordinates,
                    result.coordinates ? result.coordinates :
                      {lat: 0, lng: 0},
                  )
                  } KM from ${place}`}
              />
            ))}
          </div>
        </div>
      )}
      {/* If there are no results i.e results.length === 0, this is shown */}
      {!loading && results && results.length === 0 && (
        <>No results to show</>
      )}
    </>
  );
};
