import {FC, useEffect, useState} from 'react';
import {Steps} from 'antd';
import {FaClipboardCheck} from 'react-icons/fa';
import {RouteComponentProps} from 'react-router-dom';

import {
  fetchAdminProperty, postPropertyContactDetails, Property, ReservationCategory,
} from '../../../../../api';
import {Button} from '../../../../../components';
import {Input} from '../../../../../components/input';
import ActivityIndicator from '../../../../../components/loading';
import {
  verifyEmail, verifyPaybillNumber, verifyPhoneNumber, verifyTillNumber,
  verifyUrl,
} from '../../../../../util/verify';

import {PartnerHeader} from '../../../components';

import { Complete } from './complete';

type DetailsProps = RouteComponentProps<{
  category: ReservationCategory, id: string
}>;

export const Details: FC<DetailsProps> = ({match}) => {
  const {id, category} = match.params;
  const [property, setProperty] = useState<Property>();

  // input fields
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [mpesaTillNumber, setMpesaTillNumber] = useState<string>("");
  const [mpesaPaybilNumber, setMpesaPaybillNumber] = useState<string>("");
  const [paybilAcc, setPaybillAcc] = useState<string>("");
  const [website, setWebsite] = useState<string>("");
  const [video, setVideo] = useState<string>("");

  // UI update to display complete
  const [posting, setPosting] = useState<boolean>(false);
  const [step, setStep] = useState<"Details" | "Complete">("Details");

  useEffect(() => {
    fetchAdminProperty(category, id, (err, result) => {
      if (err) {
        console.error(err);

        return;
      }

      if (result) {
        setProperty(result as Property);
      } else {
        // tell the user that proprety was not fetched
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isButtonDisabled = (): boolean => {
    // for the button to be usable, all input fields must pass the verification
    // process and also make sure that all the required fields have been input.
    // The required fields are:
    // 1 - Name (already input by default)
    // 2 - email
    // 3 - Phone Number
    
    // Step One: Make sure compulsory ones are input and valid
    const compulsoryValid = verifyEmail(email) &&
      verifyPhoneNumber(phoneNumber);

    // Step Two: make sure all the optional fields (if input, are valid)
    const tillValid = mpesaTillNumber ? verifyTillNumber(mpesaTillNumber) : true;
    const paybilValid = mpesaPaybilNumber ?
      verifyPaybillNumber(mpesaPaybilNumber) :
      true;
    const paybillAccValid = mpesaPaybilNumber ?
      (paybilAcc !== "") :
      true;
    const websiteVlid = website ? verifyUrl(website) : true;
    const videoValid = video ? verifyUrl(video) : true;

    return !(compulsoryValid && tillValid && paybilValid && paybillAccValid &&
      websiteVlid && videoValid);
  };

  return (
    <>
      <PartnerHeader
        HeaderIcon={FaClipboardCheck}
        title="Complete property boarding"
      />
      <div
        className={
          "w-full h-full sm:px-0 sm:flex sm:flex-row sm:justify-center " +
          "sm:items-center"
        }
      >
        {property && (
          <div className="sm:w-96 sm:border p-4 sm:py-2">
            <Steps className="pb-2 border-b mb-2">
              <Steps.Step
                title="Add"
                status="finish"
              />
              <Steps.Step
                title="Details"
                status={step === "Details" ? "process" : "finish"}
              />
              <Steps.Step
                title="Complete"
                status={step === "Complete" ? "process": "wait"}
              />
            </Steps>
            {step === "Details" && (
              <>
                <div className="mb-3 text-xs font-merriweather">
                  Property recorded! Please fill in these details to help hasten
                  the verification process.<br />
                  NOTE: Your property will not be
                  <strong>searchable/ bookable</strong>{" "}on the site until
                  the property is <strong>verified</strong>{" "}by us.<br />
                  Once verified you will receive an email
                </div>
                <h6 className="font-lato text-xl font-semibold mb-3">
                  Details
                </h6>
                <Input
                  className="mb-3"
                  helper="name of the property"
                  id="property-name"
                  inputLabel="Property name"
                  name="property_name"
                  onChange={() => console.log("Should not happen")}
                  value={property.title}
                  disabled={true}
                />
                <Input
                  disabled={posting}
                  className="mb-3"
                  helper="email that can be used by potential customers"
                  id="property-email"
                  inputLabel="mail@example.com"
                  name="property_email"
                  value={email}
                  error={email && (!verifyEmail(email) ? "Invalid email" : "")}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <Input
                  disabled={posting}
                  className="mb-3"
                  helper="Customer care phone number"
                  id="phone-number"
                  inputLabel="Phone number"
                  name="phone_number"
                  value={phoneNumber}
                  error={
                    phoneNumber &&
                      (!verifyPhoneNumber(
                        phoneNumber) ? "Invalid phone number" : ""
                      )}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <Input
                  disabled={posting}
                  className="mb-3"
                  helper="Mpesa buy goods and services Till Number"
                  id="mpesa-till"
                  inputLabel="Mpesa Till Number"
                  name="mpesa_till"
                  value={mpesaTillNumber}
                  error={
                    mpesaTillNumber && (
                      !verifyTillNumber(mpesaTillNumber) ?
                        "Invalid till number" :
                        ""
                    )}
                  onChange={(e) => setMpesaTillNumber(e.target.value)}
                />
                <Input
                  disabled={posting}
                  className="mb-3"
                  helper="Mpesa paybill number"
                  id="mpesa-paybill"
                  inputLabel="Mpesa Paybill:"
                  name="mpesa_paybill"
                  value={mpesaPaybilNumber}
                  error={mpesaPaybilNumber && (
                    !verifyPaybillNumber(mpesaPaybilNumber) ?
                      "Invalid number" :
                      ""
                  )}
                  onChange={(e) => setMpesaPaybillNumber(e.target.value)}
                />
                <Input
                  disabled={posting}
                  className="mb-3"
                  helper="Ac number to be used when using this paybill"
                  id="paybill-account-number"
                  inputLabel="Paybill account number"
                  name="paybill-account-number"
                  value={paybilAcc}
                  error={
                    mpesaPaybilNumber &&
                      (!paybilAcc ?
                        "This field cannot be empty if paybill number is input":
                          ""
                      )}
                  onChange={(e) => setPaybillAcc(e.target.value)}
                />
                <Input
                  disabled={posting}
                  className="mb-3"
                  helper="Url to your properties website"
                  id="property-website"
                  inputLabel="Property website"
                  name="property_website"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                />
                <Input
                  disabled={posting}
                  className="mb-3"
                  helper="Url to a video of the property"
                  id="property-video"
                  inputLabel="Property video"
                  name="property-video"
                  value={video}
                  onChange={(e) => setVideo(e.target.value)}
                />

                <div className="flex flex-row justify-center">
                  <Button
                    type="button"
                    disabled={isButtonDisabled() || posting}
                    onClick={() => {
                      // post details about the property to the phone book
                      setPosting(true);
                      postPropertyContactDetails(
                        category, id,
                        {
                          email,
                          paybill: {
                            number: mpesaPaybilNumber,
                            account_number: paybilAcc,
                          },
                          phone_number: phoneNumber,
                          till_number: mpesaTillNumber,
                          video,
                          website,
                        },
                        (err, posted) => {
                          if (err) {
                            // unable to post details about the property
                            console.error(err);
                            setPosting(false);

                            return;
                          }

                          if (posted) {
                            // handle successful post of results
                            setPosting(false);
                            // move to the complete tab as the previous step is
                            // now done
                            setStep("Complete");
                          } else {
                            // show that the results are posted there
                            setPosting(false);
                          }
                        },
                      )
                    }}
                  >
                    Submit details
                  </Button>
                </div>
              </>
            )}
            {step === "Complete" && (
              <Complete
                category={category}
                propertyDetails={{
                  name: property.title,
                  email,
                  paybillNumber: `${mpesaPaybilNumber} / AC: ${paybilAcc}`,
                  tillNumber: mpesaTillNumber,
                  phoneNumber,
                  video,
                  website,
                }}
              />
            )}
          </div>
        )}

        {!property && (
          <div className="">
            <ActivityIndicator caption="" />
          </div>
        )}
      </div>
    </>
  );
};
