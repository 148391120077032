import {FC} from 'react';

import {PopulatedActivityReservation} from '../../../../../api/reservation/types';
import {DateUtil} from '../../../../../util';

import {ReservationRow} from '..';

type ActivityReservationRowsProps = {
  reservation: PopulatedActivityReservation;
};

export const ActivityReservationRows: FC<ActivityReservationRowsProps> = ({
  reservation,
}) => {
  return (
    <>
      <ReservationRow
        title="Start date"
        description={
          DateUtil.getHumanReadableFormat(
            new Date(reservation.checkin),
          )
        }
      />

      {reservation.checkout && (
        <ReservationRow
          title="End date"
          description={
            DateUtil.getHumanReadableFormat(
              new Date(reservation.checkout),
            )
          }
        />
      )}
    </>
  );
};
