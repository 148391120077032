import {ConferenceRoom} from "../../api/conference/types";

/**
 * Calculates the amount to be payable based on the input parameters passed
 * to the function.
 *
 * @param room This is the conference room that the user wants to book
 * @param duration This is the period in number the user wants to book for
 * @param durationType The duration of stay can be "hours" or "days"
 * @returns The price to book the room for the specified number of days
 */
export const calculateConferencePrice = (
  room: ConferenceRoom, duration: number, durationType: "hour" | "day",
): number => {
  let price = 0;

  if (durationType === "hour") {
    price = (room.price.hour * duration);
  }

  if(durationType === "day") {
    price = (room.price.day * duration);
  }

  return price;
};
