import {Dispatch, FC, SetStateAction, useRef, useState} from "react";
import { DragDropContext, Draggable, Droppable, DropResult, ResponderProvided } from "react-beautiful-dnd";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { RiImageAddFill } from "react-icons/ri";
import {Picture} from "../../../api";
import { TextArea } from "../../../components/input";

type SubPropertyAddImageProps = {
  images: Picture[];
  threshold: number;
  setImages: Dispatch<SetStateAction<Picture[]>>
};

export const SubPropertyAddImage: FC<SubPropertyAddImageProps> = ({
  images,
  threshold,
  setImages,
}) => {
  const [isMaximized, setIsMaximized] = useState<boolean>(false);
  const [imageURL, setImageURL] = useState<string>("");
  const [newImage, setImage] = useState<string>("");
  const [newImageCaption, setNewImageCaption] = useState<string>("");

  // toggle banner visibility
  const [bannerVisible, setBannerVisible] = useState<boolean>(true);

  // image input container
  const fileInputRef = useRef<HTMLInputElement>(null);

  const addMultipleImages = (files: FileList, caption: string) => {
    const newImages: Picture[] = [];

    for (let i=0; i<files.length; i++) {
      const fileUrl = URL.createObjectURL(files[i]);
      newImages.push({
        caption, url: fileUrl, filename: files[i].name,
        file: files[i],
      });
    }

    setImages([...images, ...newImages]);
  };

  const removeImage = (removableImage: Picture) => {
    let imageIdx = -1;

    // from the list, look for the image with the correct url and caption
    for (let i=0; i<images.length; i++) {
      if (
        images[i].caption === removableImage.caption &&
        removableImage.url === images[i].url
      ) {
        imageIdx = i;
        break;
      }
    }

    // make sure we are not acrrying out an unsafe operation
    if (imageIdx !== -1) {
      images.splice(imageIdx);
    }
  };

  const reorderImage = (result: DropResult, provided: ResponderProvided) => {
    if (result.reason === "CANCEL") {
      // means that the image was not dropped within the droppable region
      return;
    }

    // to please tsc, just make sure that there is the destination in the
    // result.destination
    if (result.destination) {
      const sourceIdx = result.source.index;
      const destinationIdx = result.destination.index;

      const reorderedImages = [...images];
      const [removedImage] = reorderedImages.splice(sourceIdx, 1);
      // push in the image to the new location
      reorderedImages.splice(destinationIdx, 0, removedImage);

      // after reordering the images, set the new order
      setImages(reorderedImages);
    }
  };

  return (
    <div
      className={
        `bg-white border rounded-lg w-full mt-3 ${
          !isMaximized ? "h-14": ""
        } overflow-hidden transition-all duration-150`
      }
    >
      <div
        className={
          "h-14 flex flex-row items-center justify-between border-b px-4"
        }
        onClick={() => {
          // toggle open or closed
          setIsMaximized(!isMaximized)
        }}
      >
        <div>
          <h6 className="text-xl font-semibold">Add Images</h6>
          <p className="text-xs opacity-70">
            {`Add at least ${threshold} images of your property`}
          </p>
        </div>
        {/* Expand or minimize icon to add and remove images */}
        {isMaximized && (
          <FaChevronUp size={24} color="#FF5900" />
        )}
        {!isMaximized && (
          <FaChevronDown size={24} color="#FF5900" />
        )}
        {/* Replace with minimize or maximize icon */}
      </div>
      {/* Banner informing user that they can drag images */}
      {bannerVisible && (
        <div  
          className={
            "px-4 pb-2 pt-6 bg-white border-b transition-all "+
            "duration-150"
          }
        >
          <div className="flex flex-row">
            <AiOutlineInfoCircle
              size={40}
              className="text-safari-gold flex-shrink-0"
            />
            <p className="text-md ml-4">
              Drag images to reorder in terms of preference of how you would like
              them displayed. NOTE: The order of the images is the order that is
              used by the system when choosing images to display; Where the first
              image is used as the cover of the property.
              all other images are shown in the order that you have ordered
              them in.
            </p>
          </div>
          <div className="flex flex-row justify-end pt-3 pr-2">
            <button
              className="px-4 text-error font-semibold text-xl h-9"
              onClick={() => {
                setBannerVisible(false);
              }}
            >
              DISMISS
            </button>
          </div>
        </div>
      )}
      {/* End of banner */}

      <div className="p-4">
        <label htmlFor="add-image">
          <div
            className={
              "border h-40 lg:h-32 rounded-md bg-cover bg-no-repeat " +
              "bg-center cursor-pointer flex items-center justify-center"
            }
            style={{
              backgroundImage: `url(${imageURL})`, // no background
            }}
          >
            <div className="p-3 bg-white opacity-40">
              <RiImageAddFill size={48} className="z-40 opacity-100" />
            </div>
            <input
              ref={fileInputRef}
              className="hidden"
              type="file"
              accept="image/jpeg, image/png"
              multiple={true}
              id="add-image"
              value={newImage}
              onChange={(e) => {
                setImage(e.target.value);
                if (fileInputRef.current?.files) {
                  setImageURL(
                    URL.createObjectURL(fileInputRef.current.files[0]));
                }
              }}
            />
          </div>
        </label>

        {newImageCaption !== "" && fileInputRef.current?.files && (
          <>
            {fileInputRef.current.files.length > 1 && (
              <p>
                *Multiple images selected
              </p>
            )}
          </>
        )}

        {/* start of text area container */}
        <div className="mt-3">
          <TextArea
            helper="Image caption of what's in the picture"
            id="sub-property-new-image"
            inputLabel="Image caption"
            name="caption"
            value={newImageCaption}
            onChange={(e) => {
              setNewImageCaption(e.target.value)
            }}
          />
        </div>
        {/* End of text area container */}

        <div className="flex flex-row justify-end pt-3">
          <button
            disabled={!newImageCaption || !newImage}
            className={
              `h-9 px-3 border ${
                (!newImage || !newImageCaption)
                  ? "border-gray-700 text-gray-700"
                  : "border-red-500 text-red-500"
              } rounded-md shadow-md font-semibold text-sm flex items-center`
            }
            onClick={() => {
              // fetch image url from the image ref
              if (fileInputRef.current?.files) {
                addMultipleImages(fileInputRef.current.files, newImageCaption);

                setImage("");
                setNewImageCaption("");
                setImageURL("");
              }

              // reset the new image container
            }}
          >
            Add Image
          </button>
        </div>
      </div>
      {/* End of add image container */}

      <hr />

      {/* Added image with caption */}
      <DragDropContext onDragEnd={reorderImage}>
        <Droppable droppableId="sub-property-images-addition">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              className="p-4 pt-0"
              {...provided.droppableProps}
            >
              {images.map((image, idx) => (
                <Draggable
                  key={`subproperty_image_${idx}`}
                  draggableId={image.url}
                  index={idx}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      className={
                        "p-4 mt-4 border rounded-tl-xl overflow-hidden shadow-md " +
                        "flex flex-row h-28 bg-white"
                      }
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                      <div
                        className={
                          "-ml-4 -mb-4 -mt-4 h-28 w-28 bg-cover bg-center bg-no-repeat " +
                          "flex-shrink-0"
                        }
                        style={{ backgroundImage: `url(${image.url})` }}
                      />
                      <div className="flex flex-col ml-3 h-full w-full">
                        <p className="text-sm flex-shrink h-16 overflow-hidden">
                          {image.caption}
                        </p>
                        <div
                          className={
                            "flex flex-row w-full justify-end mt-2 -mb-2 -mr-2 " +
                            "flex-shrink-0"
                          }
                        >
                          <button
                            className={
                              "h-9 px-3 border border-red-500 rounded-md shadow-md " +
                              "text-red-500 font-semibold text-sm flex items-center"
                            }
                            onClick={() => removeImage(image)}
                          >
                            Delete Image
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>

    </div>
  );
};
