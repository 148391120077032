import {Coordinates} from "../api";

const radiusInKilometres = 6371.0710;
const radiusInMiles = 3958.8;

export const haversineDistance = (
  distanceType: "km" | "mi", mk1: Coordinates, mk2: Coordinates,
): number => {
  const R = (distanceType === "km") ? radiusInKilometres : radiusInMiles;
  const rlat1 = mk1.lat * (Math.PI / 180); // Convert degrees to radians
  const rlat2 = mk2.lat * (Math.PI / 180); // Convert degrees to radians
  const difflat = rlat2 - rlat1; // Radian difference (latitudes)
  const difflon = (mk2.lng - mk1.lng) * (Math.PI / 180); // Radian difference (longitudes)

  const d =
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) *
            Math.cos(rlat2) *
            Math.sin(difflon / 2) *
            Math.sin(difflon / 2)
      )
    );

  return d;
}
