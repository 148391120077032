import {FC, useState} from 'react';
import { FaTimes } from 'react-icons/fa';

import {Facility} from '../../../../api';
import { Button } from '../../../../components';
import {Chip} from '../../../../components/chip';
import { Input, TextArea } from '../../../../components/input';

import {activityFacilities} from './activity';
import {carFacilities} from './car';
import {conferenceFacilities} from './conference';
import {cruiseFacilities} from './cruise';
import {eateryFacilities} from './eatery';
import {flightFacilities} from './flight';
import {hotelFacilities} from './hotel';
import {rentalFacilities} from './rental';
import {tourFacilities} from './tour';

type EditFacilitiesProps = {
  category: "hotel" | "flight" | "rental" | "cruise" | "eatery" | "conference" |
    "car" | "tour" | "activity";
  facilities: Facility[];
  onSave: (activeFacilities: Facility[]) => void;
  removeOverlay: () => void;
};

export const EditFacilities: FC<EditFacilitiesProps> = ({
  category, facilities, removeOverlay, onSave,
}) => {
  let allFacilities: Facility[] = [];

  switch (category) {
    case "activity":
      allFacilities = activityFacilities;
      break;
    case "car":
      allFacilities = carFacilities;
      break;
    case "conference":
      allFacilities = conferenceFacilities;
      break;
    case "cruise":
      allFacilities = cruiseFacilities;
      break;
    case "eatery":
      allFacilities = eateryFacilities;
      break;
    case "flight":
      allFacilities = flightFacilities;
      break;
    case "hotel":
      allFacilities = hotelFacilities;
      break;
    case "rental":
      allFacilities = rentalFacilities;
      break;
    case "tour":
      allFacilities = tourFacilities;
      break;
    default:
      break;
  }

  const [activeFacilities, setActiveFacilities] =
    useState<Facility[]>(facilities);

  const [inactiveFacilities, setInactiveFacilities] =
    useState<Facility[]>(allFacilities.filter((facility) => {
      let isNotActive = true;

      for (let i=0; i<facilities.length; i++) {
        isNotActive = facilities[i].title !== facility.title;

        if (!isNotActive) break;
      }

      return isNotActive;
    }));

  const [facilityName, setFacilityName] = useState<string>("");
  const [facilityDescription, setFacilityDescription] =
    useState<string>("");

  const removeActivity = (idx: number) => {
    const newActiveFacilities = [...activeFacilities];
    const [removed] = newActiveFacilities.splice(idx, 1);
    const newInactiveFacilities = [...inactiveFacilities, removed];

    setActiveFacilities(newActiveFacilities);
    setInactiveFacilities(newInactiveFacilities);
  };

  const addActivity = (idx: number) => {
    const [newActiveFacility] = inactiveFacilities.splice(idx, 1);
    const newCurrentActiveFacilities = [...activeFacilities, newActiveFacility]; 

    setActiveFacilities(newCurrentActiveFacilities);
    setInactiveFacilities(inactiveFacilities);
  };

  return (
    <div
      className="bg-white absolute top-0 right-0 h-full w-full sm:max-w-md">
      {/** Edit Facilities App Bar */}
      <div
        className={
          "bg-safari-gold sticky top-0 h-14 px-4 shadow-md text-white " +
          "flex flex-row justify-between items-center"
        }
      >
        {/** Title and back action */}
        <div className="flex flex-row items-center">
          {/* <div className="h-6 w-6 bg-gray-400 rounded-full"></div> */}
          <FaTimes size={24} onClick={removeOverlay} />
          <div className="ml-3">
            <h6 className="text-lg">Edit Facilities</h6>
            <p className="text-xs">
              Pick facilities contained in your property
            </p>
          </div>
        </div>
        {/** Title and back action end */}

        {/** save action button */}
        <button
          className={
            "py-2 px-3 border border-black rounded-md text-black font-semibold text-sm"
          }
          onClick={() => {
            onSave(activeFacilities);
            removeOverlay();
          }}
        >
          <p className="text-sm font-semibold">
            Save
          </p>
        </button>
        {/** End save action button */}
      </div>
      {/** End of facilities app bar */}

      {/** Active Facilities */}
      <div className="p-4">
        <h5 className="text-2xl font-semibold">
          Active Facilities
        </h5>
        <p className="text-sm opacity-70">
          Facilities contained in your property
        </p>
        <hr className="my-2" />

        {/** Chips of active facilities */}
        <div className="flex flex-row justify-between flex-wrap">

        {/* Render all active Facilities */}
        {activeFacilities.map(((caf, idx) => (
          <Chip
            key={`caf_${idx}`}
            label={caf.title}
            active={true}
            onClick={() => {removeActivity(idx)}}
          />
        )))}

        </div>
        {/** End of chip of active facilties */}
      </div>
      {/** End of active facilities */}

      {/** Inactive Facilities */}
      <div className="p-4">
        <h5 className="text-2xl font-semibold">
          Inactive Facilities
        </h5>
        <p className="text-sm opacity-70">Press on a facility to add</p>
        <hr className="my-2" />

        {/** Chips of active facilities */}
        <div className="flex flex-row justify-between flex-wrap">

        {/* Render all inactive facitlities */}
        {inactiveFacilities.map((cif, idx) => (
          <Chip
            key={`cif_${idx}`}
            label={cif.title}
            onClick={() => {addActivity(idx)}}
            active={false}
          />
        ))}

        </div>
        {/** End of chip of active facilties */}
      </div>
      {/** End of inactive facilities */}
      <div className="p-4">
        <h5 className="text-2xl font-semibold">
          New facility
        </h5>
        <p className="text-sm opacity-70">
          Add facility below if its not in the options
        </p>
        <Input
          className="my-3"
          helper="Name of the facility you'd like to add"
          id="facility-title"
          inputLabel="New facility name"
          name="facility_name"
          value={facilityName}
          onChange={(e) => {
            setFacilityName(e.target.value);
          }}
        />

        <TextArea
          helper="Small description of the facility"
          id="facility-description"
          inputLabel="Facility dscription"
          name="facility_description"
          value={facilityDescription}
          onChange={(e) => {
            setFacilityDescription(e.target.value);
          }}
        />

        <div className="flex flex-row justify-center mt-3">
          <Button
            disabled={facilityDescription === "" || facilityName === ""}
            type="button"
            onClick={() => {
              const newActiveFacilities = [...activeFacilities];
              newActiveFacilities.push({
                description: facilityDescription,
                title: facilityName,
              });

              // clearForm
              setFacilityName("");
              setFacilityDescription("");
              setActiveFacilities(newActiveFacilities);
            }}
          >
            Add Facility
          </Button>
        </div>
      </div>
    </div>
  );
};
 