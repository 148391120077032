import { BiHotel } from "react-icons/bi";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

export function AccomodationItem({item, diffDays, checkinDate, checkoutDate}) {
  return (
    <div
      style={{
        border: "1px solid #eee",
        marginBottom: 10,
        borderRadius: 20,
        overflow: "hidden",
      }}
    >
      <div
        className="flex flex-col sm:flex-row"
      >
        <div>
          <div
            style={{
              padding: 15,
              paddingTop: 45,
              fontSize: 65,
              textAlign: "center",
              height: 160,
              color: "#fff",
              background: "#0a7fb5",
            }}
          >
            <BiHotel />
          </div>
        </div>
        <div style={{ padding: 15, flex: 1 }}>
          <div
            style={{
              fontWeight: "bold",
              fontSize: 15,
              color: "#0a7fb5",
              fontFamily: "Roboto",
            }}
          >
            {item.hotel_name}
          </div>
          <div
            style={{
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
          >
            {item.hotel_address}
          </div>
          <div>Booked for {diffDays} night(s)</div>
          {/* <div
            className="mobile-hidden description_text"
            style={{ marginTop: 0 }}
          >
            {item.description}
          </div> */}
          <div
            style={{
              display: "flex",
              fontSize: 12,
              marginTop: 15,
            }}
          >
            <div style={{ fontSize: 17, marginRight: 5 }}>
              <FaAngleUp />
            </div>
            <div style={{ flex: 1 }}>Checkin date:</div>
            <div style={{ fontWeight: "bold" }}>
              {checkinDate}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              fontSize: 12,
              marginTop: 5,
            }}
          >
            <div style={{ fontSize: 17, marginRight: 5 }}>
              <FaAngleDown />
            </div>
            <div style={{ flex: 1 }}>Checkout date:</div>
            <div style={{ fontWeight: "bold" }}>
              {checkoutDate}
            </div>
          </div>
        </div>
        <div
          style={{
            borderLeft: "1px solid #fbfafa",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              padding: 15,
              color: "#ff4f5a",
              fontWeight: "bold",
              fontSize: 12,
              flex: 1,
              cursor: "pointer",
            }}
            onClick={() => this._deleteFromTrip(item)}
          >
            {/* <GiCancel /> */}REMOVE
          </div>
          <div
            style={{
              padding: 15,
              color: "#6e93c1",
              fontWeight: "bold",
              fontSize: 12,
              borderLeft: "1px solid #fbfafa",
            }}
            onClick={() => this._deleteFromTrip(item)}
          >
            {/* <GiCancel /> */}
            {item.price} USD
          </div>
        </div>
      </div>
    </div>
  );
}
