import {FC, useContext, useEffect, useState} from 'react';
import {Formik} from 'formik';
import {RouteComponentProps, useHistory} from 'react-router-dom';
import {FaPlusCircle, FaSave} from 'react-icons/fa';
import {RiHotelLine} from 'react-icons/ri';

import {
  Coordinates, Facility, FAQ, Feature, fetchRental, Picture,
  RefundType, ReservationType, updateProperty,
} from '../../../../../api';
import {RentalRoom} from '../../../../../api/rental/types';
import {Card} from '../../../../../components';
import {Input, TextArea} from '../../../../../components/input';
import {TextInput} from '../../../../../components/input/text-input';

import {OverlayProvider} from '../../../../search-property/context';

import {
  EditFacilitiesListItem, EditFeaturesListItem, EditRefundPolicyListItem,
  EditRentalRoomOverlay,
  EditReservationPolicyListItem, PartnerHeader, PickImageListItem,
  PropertyLocationAutoComplete,
} from '../../../components';
import {EditFaqListItem} from '../../../components/edit-faq-list-item';
import {UploadingOverlay} from '../../../components/uploading-overlay';

import {validateRentalForm} from './validate-form';
import {NewRentalRoomOverlay} from './new-rental-room-overlay';
import ActivityIndicator from '../../../../../components/loading';
import { SnackbarProvider } from '../../../../../context/snackbar-provider';
import { ConfirmPropertyLocation } from '../../../components/confirm-property-location';
import { PriceChange } from '../../../../../api/hotel/Offer';

type EditRentalProps = RouteComponentProps<{property_id: string}>;

type InitialRentalValues = {
  title: string,
  location: string,
  description: string,
  short_description: string,
  reservation_policy: string,
  refund_policy: string,
  facilities: string,
  features: string,
  rooms: string,
  faqs: string,
  price: string,
};

export const EditRental: FC<EditRentalProps> = ({
  match,
}) => {
  const {
    setIsCancellable, setIsOverlay, setOnOverlay,
  } = useContext(OverlayProvider);
  const {
    setIsVisible: setSnackbarVisible, dismissSnackbar,
  } = useContext(SnackbarProvider);

  const history = useHistory();

  const [facilities, setFacilities] = useState<Facility[]>([]);
  const [features, setFeatures] = useState<Feature[]>([]);
  const [faqs, setFaqs] = useState<FAQ[]>([]);
  const [gallery, setGallery] = useState<Picture[]>([]);
  const [coords, setCoords] = useState<Coordinates>({lat: 0, lng: 0});
  const [rooms, setRooms] = useState<RentalRoom[]>([]);
  const [refundPolicy, setRefundPolicy] = useState<RefundType>("partial_refund");
  const [reservationPolicy, setReservationPolicy] =
    useState<ReservationType>("full_amount");
  const [offers, setOffers] = useState<PriceChange[]>([]);
  
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const propertyId = match.params.property_id;
  
  const [initialValues, setInitialValues] =
    useState<null | InitialRentalValues>(null);

  const addRentalRoom = (newRentalRoom: RentalRoom) => {
    setRooms([...rooms, newRentalRoom]);
  };

  useEffect(() => {
    setIsCancellable && setIsCancellable(false);

    setOnOverlay && setOnOverlay(() => () => (
      <ActivityIndicator caption="" />
    ));

    setIsOverlay && setIsOverlay(true);

    const removeOverlay = () => {
      setIsCancellable && setIsCancellable(true);

      setOnOverlay && setOnOverlay(null);

      setIsOverlay && setIsOverlay(false);
    };

    fetchRental(propertyId, (err, rental) => {
      if (err) {
        console.error(err);
        history.goBack();
        setSnackbarVisible && setSnackbarVisible({
          fabPresent: true,
          isError: true,
          navRailPresent: true,
          title: "An error occured while fetching property details",
        });
        history.goBack();

        removeOverlay();
        return;
      }

      if (rental) {
        setInitialValues({
          description: rental.description,
          facilities: "",
          faqs: "",
          features: "",
          location: rental.location,
          price: isNaN(Number(rental.price)) ?
            "" :
            `${rental.price}`,
          refund_policy: "",
          reservation_policy: "",
          rooms: "",
          short_description: rental.short_description,
          title: rental.title,
        });

        setFacilities(rental.facilities);
        setFaqs(rental.faqs);
        setFeatures(rental.features);
        setRefundPolicy(rental.refund_policy);
        setReservationPolicy(rental.reservation_policy);
        setRooms(rental.rooms);
        setGallery(rental.gallery);
        setCoords(rental.coords);
        setOffers(rental.offers);
      } else {
        setSnackbarVisible && setSnackbarVisible({
          fabPresent: true,
          isError: true,
          navRailPresent: true,
          title: "Could not find property. Try again",
        });
        history.goBack();
      }

      removeOverlay();
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isUploading && (
        <OverlayProvider.Consumer>
          {({setIsCancellable, setIsOverlay, setOnOverlay}) => (
            <UploadingOverlay
              caption="Uploading rental property details"
              setIsCancellable={setIsCancellable}
              setIsOverlay={setIsOverlay}
              setOnOverlay={setOnOverlay}
            />
          )}
        </OverlayProvider.Consumer>
      )}
      {initialValues !== null && (
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          // validate the values
          return validateRentalForm({
            coords,
            description: values.description,
            facilities,
            features,
            gallery,
            location: values.location,
            price: values.price,
            refund_policy: refundPolicy,
            reservation_policy: reservationPolicy,
            rooms,
            short_description: values.short_description,
            title: values.title
          });
        }}
        onSubmit={(values) => {
          setIsUploading(true);
          // gameloop until the last draft is done saving
          updateProperty(
            "rental",
            propertyId,
            {
              coords,
              description: values.description,
              exceptions: [],
              facilities,
              features,
              faqs,
              gallery,
              house_policy: [],
              location: values.location,
              offers,
              price: Number(values.price),
              rating: 10,
              refund_policy: refundPolicy,
              reservation_policy: reservationPolicy,
              reviews: [],
              rooms,
              short_description: values.short_description,
              title: values.title,
            },
            (err, result) => {
              if (err) {
                console.error(err);
                setSnackbarVisible && setSnackbarVisible({
                  fabPresent: false,
                  isError: true,
                  navRailPresent: true,
                  title: "Unable to publish rental details",
                });
  
                return;
              }
  
              setIsUploading(false);

              if (result) {
                history.goBack();

                setSnackbarVisible && setSnackbarVisible({
                  fabPresent: false,
                  isError: false,
                  navRailPresent: true,
                  title: `Published ${result.title} details`,
                  action: {
                    label: "DISMISS",
                    onClick: () => {
                      dismissSnackbar && dismissSnackbar();
                    },
                  },
                });
              } else {
                setSnackbarVisible && setSnackbarVisible({
                  fabPresent: false,
                  isError: true,
                  navRailPresent: true,
                  title: "Unable to publish rental details",
                });
              }
            },
          );
        }}
      >
          {({
            values, errors, setErrors, handleChange,
            handleSubmit, handleBlur, validateForm,
          }) => (
            <form onSubmit={handleSubmit} onBlur={handleBlur}>
              <PartnerHeader
                title={values.title ? values.title : "New Rental"}
                HeaderIcon={RiHotelLine}
                actions={[
                  {
                    label: "UPDATE RENTAL",
                    disabled: Object.keys(errors).length > 0,
                    ActionIcon: FaSave,
                    alwaysVisible: true,
                    type: "submit",
                    onClick: () => {
                      console.log("Adding new rental");
                    },
                  },
                ]}
              />
              <div
                className="p-4 sm:grid sm:grid-cols-2 gap-4 lg:grid-cols-3 lg:grid-flow-col-dense lg:max-w-5xl"
              >
                {/** Input Title */}
                <div>
                  <Input
                    inputLabel="Rental name"
                    name="title"
                    value={values.title}
                    error={errors.title}
                    helper="Name of the rental"
                    id="rental-name"
                    onChange={handleChange}
                  />
                </div>
                {/** End of input Title */}
                {/** Input Title */}
                <div className="mt-3 sm:mt-0 lg:col-start-1 col-start-2">
                  {/** Text input */}
                  <PropertyLocationAutoComplete
                    name="location"
                    inputLabel="Rental's location"
                    onChange={handleChange}
                    selectAddress={(place, coords) => {
                      setIsCancellable && setIsCancellable(false);
                      // setCoords(coords);
                      setOnOverlay && setOnOverlay(() => () => (
                        <ConfirmPropertyLocation
                          initialCoords={coords}
                          onConfirm={(coords) => {
                            setCoords(coords);
                          }}
                          removeOverlay={() => {
                            setIsOverlay && setIsOverlay(false);
                            setIsCancellable && setIsCancellable(true);
                            setOnOverlay(null);
                          }}
                        />
                      ));
                    }}
                    value={values.location}
                    error={errors.location}
                  />
                  {/** End of text input */}
                </div>
                {/** End of input Title */}
            
                {/** Start of Text Area */}
                <div className="mt-3 lg:col-start-1 lg:col-end-3">
                  <TextArea
                    name="description"
                    value={values.description}
                    error={errors.description}
                    helper="Longform description of the rental"
                    id="rental-description"
                    inputLabel="Rental description (long)"
                    onChange={handleChange}
                  />
                </div>
                {/** End of Text Area */}
            
                {/** Start of Text Area */}
                <div className="mt-3 lg:col-start-1 lg:col-end-3">
                  <TextArea
                    name="short_description"
                    value={values.short_description}
                    error={errors.short_description}
                    helper="Brief description of the rental"
                    id="rental-short-description"
                    inputLabel="Rental description (short)"
                    onChange={handleChange}
                  />
                </div>
                {/** End of Text Area */}
            
                {/** Reservation policy card */}
                <EditReservationPolicyListItem
                  reservationPolicy={reservationPolicy}
                  onSave={async (reservationPolicy) => {
                    setReservationPolicy(reservationPolicy);
                    setErrors(await validateForm());
                  }}
                />
                {/** End of Reservation policy card */}
            
                {/** Reservation policy card */}
                <EditRefundPolicyListItem
                  refundPolicy={refundPolicy}
                  onSave={async (refundPolicy) => {
                    setRefundPolicy(refundPolicy);
                    setErrors(await validateForm());
                  }}
                />
                {/** End of Reservation policy card */}
            
                {/** Pick images overlay */}
                <PickImageListItem
                  gallery={gallery}
                  minImageCount={6}
                  onOverlaySave={async (gallery) => {
                    setGallery(gallery);
                    setErrors(await validateForm());
                  }}
                />
                {/* End of pick images overlay */}
            
                {/** Things liks prices and sub properties are found here */}
                <hr className="my-4 sm:col-start-1 sm:col-end-3 h-auto lg:hidden" />
                {/** Special Section */}
                <div className="shadow-md border sm:col-start-1 sm:col-end-3">
                  {/** Header for special section */}
                  <div className="h-14 flex flex-row justify-between items-center px-4 border-b">
                    <div>
                      <h6 className="text-xl font-semibold">Rental Rooms</h6>
                      <p className="text-xs">Add rental room</p>
                    </div>

                    {/** Edit Button */}
                    <OverlayProvider.Consumer>
                      {({setIsOverlay, setOnOverlay}) => (
                        <button
                          type="button"
                          className={
                            "h-9 px-3 border border-red-500 rounded-md " +
                            "shadow-md text-red-500 font-semibold text-sm " +
                            "flex items-center"
                          }
                          onClick={() => {
                            setOnOverlay && setOnOverlay(() => () => (
                              <NewRentalRoomOverlay
                                onSave={async (newRentalRoom) => {
                                  addRentalRoom(newRentalRoom);

                                  setErrors(await validateForm());
                                }}
                                removeOverlay={() => {
                                  setIsOverlay && setIsOverlay(false);
                                  setOnOverlay && setOnOverlay(null);
                                }}
                              />
                            ));

                            setIsOverlay && setIsOverlay(true);
                          }}
                        >
                          Add room
                        </button>
                      )}
                    </OverlayProvider.Consumer>
                    {/** End of edit Button */}
                  </div>

                  <div className="p-4 flex flex-row justify-evenly overflow-x-scroll">
                    {/** All Added Sub Properties go here */}
                    {rooms.map((room, idx) => (
                      <Card
                        key={room.title}
                        cover={room.gallery[0].url}
                        description={room.description}
                        subtitle={
                          `${
                            room.capacity.adults_only
                          } adults or ${
                            room.capacity.children_only
                          } children`}
                        title={room.title}
                        action={{
                          label: "Edit Room details",
                          onClick: () => {
                            setOnOverlay && setOnOverlay(() => () => (
                              <EditRentalRoomOverlay
                                idx={idx}
                                item={room}
                                rentalRooms={rooms}
                                removeOverlay={() => {
                                  setIsOverlay && setIsOverlay(false);
                                  setOnOverlay(null);
                                }}
                                setRentalRooms={setRooms}
                              />
                            ));

                            setIsOverlay && setIsOverlay(true);
                          }
                        }}
                      />
                    ))}
                  </div>

                  {/** Header for price Section */}
                  <div className="h-14 flex flex-row justify-between items-center px-4 border-t">
                    <div>
                      <h6 className="text-xl font-semibold">Price of property</h6>
                      <p className="text-xs">Detail the cost of the property per night</p>
                    </div>

                  </div>
                  <div className="max-w-sm px-4 py-2">
                    {/** Price input container */}
                    <TextInput
                      helper="How much does it const to book this rental per night"
                      id="cost-of-rental"
                      inputLabel="Cost per night (in USD)"
                      name="price"
                      value={values.price}
                      error={errors.price}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {/** Special Section */}
            
                <div className="sm:col-span-2 lg:col-start-1 lg:col-end-4">
                  <h3 className="text-2xl font-semibold sm:col-start-1 sm:col-end-3">Aditional Information</h3>
                  <div className="sm:grid sm:grid-cols-2 sm:gap-4 lg:grid-cols-3">
                    {/** Add FAQs */}
                    <EditFaqListItem
                      error={errors.faqs}
                      faqs={faqs}
                      onSave={async (faqs) => {
                        setFaqs(faqs);

                        setErrors(await validateForm());
                      }}
                    />
                    {/** End of FAQ */}
            
                    {/** Add Facilities */}
                    <EditFacilitiesListItem
                      category="rental"
                      facilities={facilities}
                      onSave={async (newFacilities) => {
                        setFacilities(newFacilities);
                        setErrors(await validateForm());
                      }}
                      error={errors.facilities}
                    />
                    {/** End of Add Facilities */}
            
                    {/** Add Features */}
                    <EditFeaturesListItem
                      category="rental"
                      features={features}
                      onSave={async (newFeatures) => {
                        setFeatures(newFeatures);
                        setErrors(await validateForm());
                      }}
                      error={errors.features}
                    />
                    {/** End of features */}

                    {/** Add Features */}
                    <div className="py-2">
                      <div className="border flex flex-row justify-between shadow-md px-4 py-2 items-center">
                        <div>
                          <h6 className="text-xl font-semibold">Accessibiity Features</h6>
                          <p className="text-xs opacity-70">
                            Press to edit the accessible features in the rental
                          </p>
                        </div>
                        {/* <div className="h-6 w-6 bg-indigo-300"></div> */}
                        <FaPlusCircle size={24} color="#FF5900" />
                      </div>
                      <p className="mt-1 text-xs text-red-500 pl-2">
                        Must have at least one feature
                      </p>
                    </div>
                    {/** End of features */}
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}
    </>
  );
};
