import ViewProperty from "../../../../view-property";

const App = () => {
  return (
    <>
      <div style={{ display: "flex" }}>
        <ViewProperty origin="trip-planner" hideFunc={this.props.hideFunc} />
      </div>
    </>
  );
};

export default App;
