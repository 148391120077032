import {FC} from "react";
import {ListChildComponentProps} from "react-window";
import {NearbyPropertyCard, NearbyPropertyCardProps} from "./nearby-property-card";

export const Row: FC<ListChildComponentProps<NearbyPropertyCardProps[]>> = ({
  data, index, style, 
}) => {
  const props = data[index];

  return (
    <div key={props.property.id} style={{...style, marginTop: 8, marginBottom: 8}}>
      <NearbyPropertyCard
        {...props}
      />
    </div>
  );
};