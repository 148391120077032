import { FC, useState } from "react";
import { FaTimes } from "react-icons/fa";

import { Picture } from "../../../../../api";
import { MenuItem } from "../../../../../api/eatery/types";
import { Input, TextArea } from "../../../../../components/input";

import { SubPropertyAddImage } from "../../../components";

type MenuItemOverlayProps = {
  removeOverlay: () => void;
  onSave: (menuItem: MenuItem) => void;
};

export const NewEateryMenuItemOverlay: FC<MenuItemOverlayProps> = ({
  removeOverlay,
  onSave,
}) => {
  const [title, setTitle] = useState<string>("");
  const [price, setPrice] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [images, setImages] = useState<Picture[]>([]);

  return (
    <div className="bg-white absolute top-0 right-0 bottom-0 w-full h-full sm:max-w-lg sm:shadow-md overflow-y-scroll">
      {/** App bar */}
      <div className="sticky top-0 bg-safari-gold text-white z-10 h-14 flex flex-row justify-between items-center overflow-hidden px-2 sm:col-start-1 sm:col-end-4 shadow-md">
        {/** Header Container */}
        <div className="overflow-hidden flex-grow-0 flex flex-row items-center">
          {/** Close Overlay icon */}
          <FaTimes size={24} onClick={removeOverlay} />
          <div className="ml-3">
            <h2 className="text-xl font-semibold overflow-ellipsis whitespace-nowrap">
              New menu item
            </h2>
            <p className="text-xs italic overflow-ellipsis whitespace-nowrap">
              Enter menu item details
            </p>
          </div>
        </div>
        {/** End of Header container */}

        {/** Edit Button */}
        <button
          className={
            "h-9 px-3 border rounded-md shadow-md " +
            " font-semibold text-sm flex items-center " +
            `${
              title !== "" &&
              description !== "" &&
              price !== "" &&
              images.length > 0
                ? "border-black text-black"
                : "border-gray-700 text-gray-700 cursor-not-allowed"
            } `
          }
          disabled={
            title === "" ||
            description === "" ||
            price === "" ||
            images.length < 1
          }
          onClick={(e) => {
            e.preventDefault();
            onSave({
              description,
              eatery: "",
              id: "",
              picture: images[0],
              price: Number(price),
              title,
            });
            removeOverlay();
          }}
        >
          Save
        </button>
        {/** End of edit Button */}
      </div>
      {/** End of app bar */}

      <div className="p-4">
        {/** start of text input container */}
        <div className="">
          <Input
            helper=""
            id="menu-item-input"
            inputLabel="Menu item name"
            name="title"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
        </div>
        {/** End of text input container */}

        {/** start of text input container */}
        <div className="mt-3">
          <Input
            inputLabel="Item pirce (in USD)"
            helper="Price of the item in the eatery"
            value={price}
            id="menu-item-price-input"
            name="price"
            onChange={(e) => {
              setPrice(e.target.value);
            }}
          />
        </div>
        {/** End of text input container */}

        {/** start of text area container */}
        <div className="mt-3">
          <TextArea
            helper="Longform description of the menu item"
            id="menu-item-description"
            inputLabel="Description (long)"
            name="description"
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            value={description}
          />
        </div>
        {/** End of text area container */}

        {/** Image Addition container */}
        <SubPropertyAddImage
          images={images}
          threshold={1}
          setImages={setImages}
        />
        {/** End of image add container */}
      </div>
    </div>
  );
};
