import {FC, useContext, useEffect, useState} from "react";
import {Badge, List, Tag} from "antd";

import {
  ContentCategories, fetchAllContent, fetchRecommendedContent,
  Picture,
  Property, Recommended, saveRecommendations,
} from "../../../api";
import {AttractionSite} from "../../../api/attraction/types";
import {TextInput} from "../../../components/input/text-input";
import {Button, Card} from "../../../components";
import {SnackbarProvider} from "../../../context/snackbar-provider";


export const Content: FC = () => {
  // context provider for the app state
  const {
    dismissSnackbar, setIsVisible,
  } = useContext(SnackbarProvider);

  // CONTENT STATE
  const [properties, setProperties] = useState<ContentCategories>({
    activity: [],
    attraction: [],
    car: [],
    conference: [],
    eatery: [],
    hotel: [],
    rental: [],
    tour: [],
  });

  const [populatedProperties, setPopulatedProperties] = useState<
    (keyof ContentCategories)[]
  >([]);

  // UI flag to indicate app is still fetching
  const [fetching, setFetching] = useState<boolean>(true);

  const [recommendedPropertiesIds, setRecommendedPropetiesIds] =
    useState<Recommended>({
      activity: [],
      attraction: [],
      conference: [],
      eatery: [],
      hotel: [],
      rental: [],
      tour: [],
    });

  const [active, setActive] = useState("hotel");
  const [searchStr, setSearchStr] = useState<string>("");

  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {

    fetchAllContent((err, content) => {
      if (err) {
        console.error(err);

        return;
      }

      if (content) {
        setProperties(content);

        const newPopulatedCategories: typeof populatedProperties = [];

        Object.keys(content).forEach((contentKey) => {
          const key = contentKey as keyof ContentCategories;
          if (content[key].length > 0) {
            newPopulatedCategories.push(key);
          }
        });

        setPopulatedProperties(newPopulatedCategories);
        setFetching(false);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // reset the search string prevent getting the same hotel
    setSearchStr("");
  }, [recommendedPropertiesIds]);

  useEffect(() => {
    fetchRecommendedContent((err, recommendedContent) => {
      if (err) {
        console.error(err);

        return;
      }

      if (recommendedContent) {
        setRecommendedPropetiesIds(recommendedContent);
      }
    });
  }, [isSaving]);

  const activeKey = active as keyof ContentCategories;

  const recommendedProperties = (
    properties[activeKey] as Record<string, unknown>[]
  ).filter((property) => {
    return (
      recommendedPropertiesIds[active as keyof Recommended].indexOf(
        `${(property as Property | AttractionSite).id}`
      ) !== -1
    );
  });

  return (
    <div className="w-full h-full flex flex-row">
      {/* List of all content categories */}
      <div
        className="w-80 flex-grow-0 flex-shrink-0 h-full border-r overflow-y-scroll"
        style={{
          padding: 16,
          height: window.innerHeight - 56,
        }}
      >
        <List
          loading={fetching}
          dataSource={populatedProperties}
          bordered
          header={
            <h4 className="text-2xl font-lato font-semibold">
              Content Categories
            </h4>
          }
          renderItem={(item) => (
            <div className="w-full my-3 cursor-pointer hover:bg-yellow-200 relative">
              <List.Item
                className="w-full"
                onClick={() => {
                  setActive(item);
                }}
              >
                {item}
              </List.Item>
              <Badge
                count={properties[item as keyof ContentCategories].length}
                className="absolute right-2 top-2"
              />
            </div>
          )}
        />
      </div>

      {/* list of the recommended properties */}
      {/* Content */}
      <div
        className="h-full w-full overflow-scroll"
        style={{
          height: window.innerHeight - 56,
        }}
      >
        <h6 className="my-2 text-xl font-lato font-semibold ml-4">
          Recommended Properties
        </h6>
        <div className="flex flex-row flex-wrap justify-around">
          {recommendedProperties.map((prop) => (
            <Card
              key={`${prop.id}`}
              cover={(prop.gallery as Picture[])[0].url}
              description={prop.description as string}
              subtitle={prop.location as string}
              title={
                active === "attraction" ?
                  prop.name as string :
                  prop.title as string
              }
            />
          ))}
        </div>
      </div>
      {/* Content */}

      {/* Edit panel */}
      <div
        className="w-96 h-full flex-grow-0 flex-shrink-0 border-l p-4 overflow-y-scroll"
        style={{
          height: window.innerHeight - 56,
        }}
      >
        <h6
          className="font-lato font-semibold"
          style={{
            fontSize: 20,
            paddingBottom: 8,
          }}
        >
          {`Edit recommended: ${active}`}
        </h6>
        <div className="flex flex-row flex-wrap">
          {recommendedProperties.map((property) => (
            <Tag
              key={`${property.id}`}
              color="gold"
              className="m-1"
              closable={!isSaving} // only manipulatable when not saving
              onClose={() => {
                const newRecommeded = [
                  ...recommendedPropertiesIds[active as keyof Recommended],
                ];

                newRecommeded.splice(
                  newRecommeded.indexOf(`${property.id}`),
                  1
                );

                setRecommendedPropetiesIds({
                  ...recommendedPropertiesIds,
                  [active]: newRecommeded,
                });
              }}
            >
              {active === "attraction"
                ? (property.name as string)
                : (property.title as string)}
            </Tag>
          ))}
          <div className="my-2 p-3 border rounded-md w-full overflow-hidden">
            <TextInput
              disabled={
                recommendedPropertiesIds[active as keyof Recommended].length >=
                4 ||
                  isSaving
              }
              value={searchStr}
              helper="Search for property by name"
              id="property-search"
              inputLabel="Search for property"
              name="property_search"
              onChange={(e) => {
                setSearchStr(e.target.value);
              }}
            />

            {(recommendedPropertiesIds[active as keyof Recommended].length < 4) && (properties as Record<string, Record<string, unknown>[]>)[active]
              .filter((prop) =>
                new RegExp(searchStr, "gi").test(
                  active === "attraction"
                    ? (prop.name as string)
                    : (prop.title as string)
                )
              )
              .slice(0, 10)
              .map((prop) => (
                <div
                  key={`${prop.id}`}
                  className="px-4 py-2 border rounded-md my-2 overflow-x-hidden w-full"
                  onClick={() => {
                    const newRecommeded = [
                      ...recommendedPropertiesIds[active as keyof Recommended],
                    ];

                    newRecommeded.push(`${prop.id}`);

                    setRecommendedPropetiesIds({
                      ...recommendedPropertiesIds,
                      [active]: newRecommeded,
                    });
                  }}
                >
                  <h6
                    className="font-lato font-semibold"
                    style={{
                      fontSize: 15,
                    }}
                  >
                    {active === "attraction"
                      ? (prop.name as string)
                      : (prop.title as string)}
                  </h6>
                  <p
                    className="overflow-ellipsis whitespace-nowrap overflow-x-hidden w-full opacity-70 text-sm"
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {prop.location as string}
                  </p>
                </div>
              ))}

              <div className="flex flex-row justify-center mt-3">
                <Button
                  disabled={isSaving || fetching}
                  type="button"
                  className="w-20"
                  onClick={async () => {
                    setIsSaving(true);

                    const isSaved = await saveRecommendations(
                      active as keyof ContentCategories,
                      recommendedPropertiesIds[active as keyof Recommended],
                    );

                    setIsSaving(false);

                    // Show the sanckbar on the state of saving
                    if (isSaved) {
                      setIsVisible && setIsVisible({
                        fabPresent: false,
                        isError: false,
                        navRailPresent: true,
                        title: "Recommendations updates",
                        action: {
                          label: "DISMISS",
                          onClick: () => {
                            dismissSnackbar && dismissSnackbar();
                          },
                        },
                      });
                    } else {
                      setIsVisible && setIsVisible({
                        fabPresent: false,
                        isError: true,
                        navRailPresent: true,
                        title: "Recommendations were not updated",
                        action: {
                          label: "DISMISS",
                          onClick: () => {
                            dismissSnackbar && dismissSnackbar();
                          },
                        },
                      });
                    }
                  }}
                >
                  Save
                </Button>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};
