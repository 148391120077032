interface FilterCategory<T> {
  active: string[];
  get(property: T): string[];
}

/**
 * This function takes in the properties and ranks them based on how many have
 * the most features to the one that has the least nummber of features, this
 * will help make sure that even as the results are paginated, the pages contain
 * relevant results that are relative to each other based on the count of
 * filter count each property meets.
 *
 * This this gives room to help make the quick filter options only applicable
 * per page. As such, after the filtering and sorting is done based on the
 * relavance of the features, results are paginated, and on each page the user
 * can apply the quickFilter results to bundle results together on the given
 * page accordingly.
 *
 * @param properties This are the properties that are being ranked based on the
 * given filters
 * @param filters These are passed as a varaible number of arguments all
 * containing an object that is of type `FilterCategory`
 */
export const rankFilters = <T extends {id?: string}>(
  properties: T[], ...filters: FilterCategory<T>[]
): T[] => {
  const rankTree: {[key: string]: number} = {};

  properties.forEach((property) => {
    rankTree[`${property.id}`] =  0;

    // for each filter, we go through all the filters comparing them agains the
    // given propertues filters
    filters.forEach((filter) => {
      // get all the filters of this category of filters and use them to
      // compare and get a count to index with
      const propertyFilters = filter.get(property);

      filter.active.forEach((active) => {
        // increment only if present
        propertyFilters.indexOf(active) > -1 && rankTree[`${property.id}`]++
      })
    });
  });

  // use the Array.sort and sort based on the values given in the rank tree
  // sort in ascending order and rreturn the reference to the same array
  return properties.sort((a, b) => rankTree[`${b.id}`] - rankTree[`${a.id}`]);
};
