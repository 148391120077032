// @ts-check
import {fetchResults} from './util';


/**
 * From my understanding, this component is used as a ContextProvider whose main
 * purpose is to fetch the data that is associated with the filters used by the
 * user on the left column.
 * 
 * How it Works
 * ------------
 * As soon as the component mounts, the Url string is taken in and scanned for
 * the type of item that is being queried. This can be:
 * `hotel, flight, rental, cruise, restaurant, conferencing, car, tour, activity`
 * 
 * After this step is complete, the query search parameters are gotten from the
 * URL string and used to create a query that is sent to firebase to allow for
 * results to be fetched.
 * 
 * After they are fetched, the results are then displayed on the /search/:param
 * tab
 *
 * Refactor
 * --------
 * This thus means that it can be refactored to a context provider that provides
 * all the data through a context and allows easy changing using the Provider
 * Consumer relationship.
 */
export default function DataFetcher({updateState}) {
  const searchUrl = new URL(location.href);
  const [route, category] =
    searchUrl.pathname.split("/").filter((item) => item !== "");

  if (route === "search") {
    console.log("Fetching Results");
    fetchResults(searchUrl, category, updateState);

    return null;
  }

  console.log("Search Fetch for trip-planner");

  // accounts for this being called from the trip-planner
  if (/hotel/.test(searchUrl.toString())) {
    fetchResults(searchUrl, "hotel", updateState);
  } else if (/flights/.test(searchUrl.toString())) {
    fetchResults(searchUrl, "flights", updateState);
  } else if (/rentals/.test(searchUrl.toString())) {
    fetchResults(searchUrl, "rentals", updateState);
  } else if (/cruises/.test(searchUrl.toString())) {
    fetchResults(searchUrl, "cruises", updateState);
  } else if (/eateries/.test(searchUrl.toString())) {
    fetchResults(searchUrl, "eateries", updateState);
  } else if (/cars/.test(searchUrl.toString())) {
    fetchResults(searchUrl, "cars", updateState);
  } else if (/tours/.test(searchUrl.toString())) {
    fetchResults(searchUrl, "tours", updateState);
  } else if (/activities/.test(searchUrl.toString())) {
    fetchResults(searchUrl, "activities", updateState);
  }

  return null;
}
