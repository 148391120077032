import {Dispatch, SetStateAction} from "react";

import {Facility, Feature} from "../../../api";

export const addFacility = (
  facility: Facility, activeFacilities: Facility[],
  setActiveFacilities: Dispatch<SetStateAction<Facility[]>>,
): void => {
  const newFacilities = [...activeFacilities, facility];

  setActiveFacilities(newFacilities);
};

export const removeFacility = (
  facility: Facility, activeFacilities: Facility[],
  setActiveFacilities: Dispatch<SetStateAction<Facility[]>>,
): void => {
  const newFacilities = [...activeFacilities];
  let facilityIdx = -1;

  // find the facility
  for (let i=0; i<newFacilities.length; i++) {
    if (newFacilities[i].title === facility.title) {
      facilityIdx = i;
      break;
    }
  }

  if (facilityIdx > -1) {
    newFacilities.splice(facilityIdx, 1);
    setActiveFacilities(newFacilities);
  }
};

export const filter = (
  activeFiltersTitles: string[], activeFilters: Facility[] | Feature[],
): Facility[] | Feature[] => {
  const allFilters = [...activeFilters];

  if (activeFilters.length === 0) {
    return allFilters;
  }

  return (
    allFilters
      .filter((filter) => activeFiltersTitles.indexOf(filter.title) > -1));
};
