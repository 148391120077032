import {FC, useRef} from "react";
import {FaTimes} from "react-icons/fa";

import {Facility, Feature, Picture, Policy} from "../../../api";
import {Chip} from "../../../components/chip";
import NewGallery from "./new-gallery";

import {PolicyCard} from "./policy-card";

export interface BookablePropertyOverlayProps {
  title: string;
  gallery: Picture[];
  location: string;
  description: string;
  renderPriceColumn: () => JSX.Element; // eslint-disable-line
  renderCheckoutColumn: () => JSX.Element; // eslint-disable-line
  facilities: Facility[];
  features: Feature[];
  removeOverlay: () => void;
  policies: Policy[];
}

/**
 * This component is used when a property in the view section has sub-properties
 * that can be viewed and are infact the ones getting booked. In this instance,
 * One provideds the Checkout column that will be displayed in the case that
 * the user would like to proceed and pay up for the sub-property.
 * This also displays all the properties of the subcomponent in terms of:
 * title, name, description, location, policies and price.
 * @param props component props
 * @returns Component.
 */
export const BookablePropertyOverlay: FC<BookablePropertyOverlayProps> = ({
  title, location, description, renderPriceColumn, renderCheckoutColumn,
  facilities, features, policies, gallery, removeOverlay,
}) => {
  const focusCheckoutColumn = useRef<HTMLDivElement>(null);

  return (
    <div className="overlay">
      <div
        className={
          "col-start-1 col-end-5 flex flex-row items-center justify-between " +
          "px-4 h-14 sticky sm:-top-2 bg-safari-gold z-30 " +
          "-mx-2 -mt-2 text-white shadow-md"
        }
      >
        <div className="flex flex-row items-center lg:w-auto mr-4">
          <FaTimes
            className="mr-4 flex-shrink-0"
            size={32}
            onClick={removeOverlay}
          />
          <div className="w-full overflow-hidden flex-grow-0">
            <h2
              className={
                "text-xl font-bold w-full overflow-x-hidden overflow-ellipsis " +
                "whitespace-nowrap"
              }
            >
              {title}
            </h2>
            <p
              className={
                "text-xs overflow-hidden w-full overflow-ellipsis " +
                "whitespace-nowrap"
              }
            >
              {location}
            </p>
          </div>
        </div>
        <button
          className={
            "border border-error text-error rounded-md font-semibold " +
            "h-9 px-4 flex flex-row items-center flex-shrink-0 " +
            "whitespace-nowrap "
          }
          onClick={() => {
            focusCheckoutColumn.current?.scrollIntoView({behavior: "smooth"})
          }}

        >
          Book Property
        </button>
      </div>
      <div className="overlay__gallery">
        <NewGallery
          gallery={gallery}
          disable={true}
        />
      </div>
      <div
        className="overlay__price-column"
      >
        {renderPriceColumn()}
      </div>
      <div className="overlay__description">
        {description}
        <div className="overlay__extras">
          {facilities.length && (
            <>
              <h5 className="overlay__extras__title">Facilities</h5>
              <div className="overlay__extras__content">
                {facilities.map((facility, idx) => (
                  <Chip
                    key={`bpo_facility_${idx}`}
                    active={false}
                    label={facility.title}
                  />
                ))}
              </div>
            </>
          )}
          {features.length && (
            <>
              <h5 className="overlay__extras__title">Features</h5>
              <div className="overlay__extras__content">
                {features.map((feature, idx) => (
                  <Chip
                    key={`bpo_feature_${idx}`}
                    active={false}
                    label={feature.title}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="overlay__policies">
        {policies.length && (
          <>
            <div className="policy__section__title">Policies</div>
            {policies.map((policy, idx) => (
              <PolicyCard
                key={`overlay_policy_card_${idx}`}
                policy={policy}
              />
            ))}
          </>
        )}
      </div>
      <div ref={focusCheckoutColumn} className="overlay__checkout">
        {renderCheckoutColumn()}
      </div>
    </div>
  );
};
