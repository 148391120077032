import {fetchCompulsoryToursFilters} from "../../../../reducers";

export default function fetchActivities(state, updateState) {
  const {place} = fetchCompulsoryToursFilters();

  updateState({
    rawResults: [],
    searchResults: [],
    searchItemsLength: 0,
    searchDone: true,
    category: "activities",
    city: place,
  });
}
