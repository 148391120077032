import {useState} from "react";
import {FaWindowClose} from "react-icons/fa";

import {MobileFilterContext} from "../mobile-filter-context";

import {SectionHeader} from "./section-header";

export function FacilitiesFilter({sharedState, updateState}) {
  const url = new URL(location.href);

  const [activeFacilityFilters, setActiveFacilityFilters] =
    useState(url.searchParams.getAll("feature"));

  const filterByFeatures = (feature) => {
    let category = sharedState.category;
    let results = sharedState.rawResults;
    let newResults = [];
    //handle filter removals
    let activeFilters = activeFacilityFilters;
    if (activeFilters.includes(feature)) {
      activeFilters.splice(activeFilters.indexOf(feature), 1);
    } else {
      activeFilters.push(feature);
    }
    // update the url to contain all the filters as features before proceeding
    // remove all features first
    url.searchParams.delete("feature");
    activeFilters.forEach((feature) => {
      url.searchParams.append("feature", feature);
    });

    // update document url
    history.replaceState(null, "", url.toString());

    setActiveFacilityFilters(activeFilters);
    if (activeFilters.length == 0) {
      newResults = results;
      updateState({
        searchResults: newResults,
      });
      return;
    }

    //handle removal
    results.map((item) => {
      let feature_list = [];
      let features = item["hotel_features"];

      switch (category) {
        case "hotel":
          if (features) {
            feature_list = features;
          }
          if (feature_list.includes(feature)) {
            newResults.push(item);
          }

          break;
      }
    });

    updateState({
      searchResults: newResults,
    });
  };

  return (
    <div className="relative shadow-md mb-3">
      <SectionHeader
        style={{borderTop: 0}}
      >
        Facilities
      </SectionHeader>
      <MobileFilterContext.Consumer>
        {({setVisibleFilter}) => (
          <div
            className="absolute top-2 right-2"
            onClick={() => setVisibleFilter("none")}
          >
            <FaWindowClose size={28} />
          </div>
        )}
      </MobileFilterContext.Consumer>
      <div style={{ padding: 20, paddingTop: 10 }}>
        {sharedState.facilities.map((item) => {
          return (
            <div className="py-1">
              <input
                className="inline"
                type="checkbox"
                id={item.title}
                name={item.title}
                value={item.title}
                onChange={(event) => {
                  filterByFeatures(event.target.value);
                }}
                style={{ width: 30 }}
                checked={
                  activeFacilityFilters.includes(item.title)
                }
              />
              <label
                htmlFor={item.title}
                style={{ height: 0, fontSize: 12 }}
              >
                {" "}
                {item.title}{" "}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}
