import {FC, useContext} from "react";
import {FaChevronDown} from "react-icons/fa";
import {Link} from "react-router-dom";

import {OverlayProvider, UserProvider} from "../../context";
import {AppStateContext} from "../../context/app-state-provider";

import {CurrencySheet} from "../../routes/index/components/currency-sheet";

export const LightHeader: FC = () => {
  const {user} = useContext(UserProvider);
  const {state} = useContext(AppStateContext);
  const {
    setIsOverlay, setOnOverlay,
  } = useContext(OverlayProvider);

  return (
    <div
      className="w-full flex flex-row justify-center px-4 bg-white sticky top-0 z-30 shadow-md"
      style={{
        height: 56,
      }}
    >
      {/*
        Makes sure that the header is always centered and is no more than
        1024px
      */}
      <div className="w-full max-w-5xl flex flex-row items-center justify-between">
        <Link to="/">
          {/* <p className="logo-text text-white">
            Safari<span className="text-safari-gold">Xperts</span>
          </p> */}
          <p className="font-lato text-2xl font-semibold">
            Safari<span className="text-safari-gold">Xperts</span>.com
          </p>
        </Link>

        {/* Menu options */}
        <div className="flex flex-row items-center">
          <div
            className="flex flex-row items-center cursor-pointer hover:text-safari-gold"
            onClick={() => {
              setOnOverlay && setOnOverlay(() => () => (
                <CurrencySheet
                  removeOverlay={() => {
                    setIsOverlay && setIsOverlay(false);
                    setOnOverlay(null);
                  }}
                />
              ));

              setIsOverlay && setIsOverlay(true);
            }}
          >
            <p className="font-semibold font-lato ml-2">
              {state.ACTIVE_CURRENCY}
            </p>
            <FaChevronDown className="ml-2" />
          </div>
          <a href="/list-your-property" className="mobile-hidden ml-4">
            <div
              className="border-2 rounded-md flex items-center px-3 border-black text-black font-semibold hover:text-safari-gold hover:border-safari-gold"
              style={{ marginTop: 5, height: 32 }}
            >
              List Your Property
            </div>
          </a>

          {/* profile */}
          {user && (
            <Link to="/user">
              <div className="flex flex-col justify-center ml-5 h-full hover:text-safari-gold cursor-pointer">
                <h6
                  className="font-lato font-semibold text-sm hover:text-safari-gold"
                  style={{
                    fontSize: 13,
                  }}
                >
                  {user.displayName}
                </h6>
                <p className="font-lato font-regular opacity-70 text-xs"
                  style={{
                    fontSize: 12,
                  }}
                >
                  {user.email}
                </p>
              </div>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
