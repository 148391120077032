import {FC} from 'react';
import {Route, RouteComponentProps, Switch} from 'react-router-dom';

import {EditDraftActivity} from './activity';
import {EditDraftCar} from './car';
import {EditConferenceDraft} from './conference';
import {EditDraftEatery} from './eatery';
import {EditDraftHotel} from './hotel';
import {EditDraftRental} from './rental';
import {EditDraftTour} from './tour';

export const EditDraftProperty: FC<RouteComponentProps> = ({match}) => {
  return (
    <Switch>
      <Route
        path={`${match.path}/activity/:draft_id`}
        component={EditDraftActivity}
      />
      <Route
        path={`${match.path}/car/:draft_id`}
        component={EditDraftCar}
      />
      <Route
        path={`${match.path}/conference/:draft_id`}
        component={EditConferenceDraft}
      />
      <Route
        path={`${match.path}/eatery/:draft_id`}
        component={EditDraftEatery}
      />
      <Route
        path={`${match.path}/hotel/:draft_id`}
        component={EditDraftHotel}
      />
      <Route
        path={`${match.path}/rental/:draft_id`}
        component={EditDraftRental}
      />
      <Route
        path={`${match.path}/tour/:draft_id`}
        component={EditDraftTour}
      />
    </Switch>
  );
};
