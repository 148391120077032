import type {FC, ChangeEventHandler, FocusEventHandler} from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import {FaMapMarkerAlt} from "react-icons/fa";
import {AutocompletePrediction} from "react-places-autocomplete";

import {Input} from "../../../components/input";

export interface AutoCompleteProps {
  inputLabel: string;
  name: string;
  value: string;
  error?: string;
  selectAddress: (place: string, latLng: {lat: number, lng: number}) => void;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
}

/**
 * This components with aid of the search input, takes in user input and renders suggestions
 * of possible locations using the Places API provided by Google.
 */
export const PropertyLocationAutoComplete: FC<AutoCompleteProps> = ({
  name, value: inputValue, inputLabel, error, onChange, selectAddress, onBlur,
}) => {
  const {
    ready, value, suggestions: {status, data},
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: [],
    },
    defaultValue: inputValue,
    debounce: 300,
  });

  const suggestionsRef = useOnclickOutside(() => clearSuggestions());

  const handleInput: ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = ({description}: AutocompletePrediction) => {
    setValue(description, false);
    clearSuggestions();

    getGeocode({address: description})
    .then((results) => getLatLng(results[0]))
    .then(({lat, lng}) => {
      selectAddress(description, {lat, lng});
    })
    .catch((error) => console.error(error));
  };


  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id: id,
        structured_formatting: {main_text, secondary_text},
      } = suggestion;

      return (
        <li
          className={
            "px-2 h-12 flex flex-row items-center hover:bg-gray-500 " +
            "hover:bg-opacity-30"
          }
          key={id}
          onClick={() => handleSelect(suggestion)}
        >
          {/* <div className="h-6 w-6 bg-indigo-300"></div> */}
          <FaMapMarkerAlt color="#FF5900" size={24} />
          <div className="ml-3">
            <h6
              style={{color: "#FF5900"}}
              className="text-base font-semibold"
            >
              {main_text}
            </h6>
            <p className="text-sm opacity-70">{secondary_text}</p>
          </div>
        </li>
      );
    });

  return (
    <div
      style={{
        // border: "1px solid #ccc",
        backgroundColor: "white",
        borderRadius: 2,
        position: "relative",
      }}
    >
      {/* <FaMapMarkerAlt
        color="rgb(7, 160, 220)"
        style={{
          marginLeft: 12,
          marginRight: 4,
        }}
      /> */}
      <div>
        <Input
          disabled={!ready}
          name={name}
          id="location-autocomplete"
          helper="Pick the location from the dropdown"
          inputLabel={inputLabel}
          onChange={(e) => {
            handleInput(e);
            onChange(e);
          }}
          onBlur={onBlur}
          value={value}
          error={error}
        />
        <div
          className="elevate-xs"
          ref={suggestionsRef}
          style={{
            position: "absolute",
            backgroundColor: "white",
            zIndex: 10,
            width: "80%",
          }}
        >
          {status === "OK" && (
            <ul className="bg-white absolute w-full shadow-md border top-full -mt-3 z-20">
              {renderSuggestions()}
              {/* <li className="px-2 h-12 flex flex-row items-center hover:bg-gray-500 hover:bg-opacity-30">
                <div className="h-6 w-6 bg-indigo-300"></div>
                <div className="ml-3">
                  <h6
                    style={{color: "#FF5900"}}
                    className="text-base font-semibold"
                  >
                    Town name
                  </h6>
                  <p className="text-sm opacity-70">Kenya</p>
                </div>
              </li> */}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}
