import {FC} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import { NearbyPropertiesColumn } from './nearby-properties';

type ViewAttractionMapProps = RouteComponentProps<{id: string}>;

export const ViewAttractionMap: FC<ViewAttractionMapProps> = ({
  match
}) => {
  return (
    <div>
      <NearbyPropertiesColumn
        attractionId={match.params.id}
      />
    </div>
  );
};
