import {database, firestore} from "firebase";

import {OnCompleteCallback, ReservationCategory} from "..";

import {City, RecommendedPropertyCount} from "./types";

export type ProximitySummary = Record<string, RecommendedPropertyCount>;

export const fetchProximitySummary = async (
  onComplete: OnCompleteCallback<Array<[string, number]>>,
): Promise<void> => {
  const proximitySummarySnapshot = await database()
    .ref("city-proximity-summary")
    .once("value");
  
  if (!proximitySummarySnapshot.exists()) {
    onComplete(new Error("Not found the hotels"), null);

    return;
  }

  const proximitySummary = proximitySummarySnapshot.val() as Record<string, RecommendedPropertyCount>;
  const cityHotels: Array<[string, number]> = [];

  for (const city in proximitySummary) {
    cityHotels.push([city, proximitySummary[city].hotel]);
  }

  cityHotels.sort((a, b) => b[1] - a[1]);

  onComplete(null, cityHotels);
};

type SummaryCategories = ReservationCategory | "attraction";

export const fetchPropertiesSummary = async (
  onComplete: OnCompleteCallback<{[Key in SummaryCategories]: number}>,
): Promise<void> => {
  const propertiesSummarySnapshot = await database().ref("property-summary")
    .once("value");

  if (!propertiesSummarySnapshot.exists()) {
    onComplete(new Error("unable to fetch summary of preoperties"), null);

    return;
  }

  onComplete(
    null,
    propertiesSummarySnapshot.val() as {[Key in SummaryCategories]: number},
  );
};

export const fetchPopulatedCities = async (
  cities: string[], onComplete: OnCompleteCallback<City[]>,
): Promise<void> => {
  const citiesSnapshot = await firestore().collection("city").get();
  const populatedCities: City[] = [];

  cities.forEach((city) => {
    for(let i =0; i<citiesSnapshot.docs.length; i++) {
      if (citiesSnapshot.docs[i].id === city) {
        populatedCities.push(({
          ...citiesSnapshot.docs[i].data(),
          id: citiesSnapshot.docs[i].id,
        } as City));

        break;
      }
    }
  });

  onComplete(null, populatedCities);
};
