import {Component} from "react";
import AuthContainer from "../signin/AuthContainer";
import travellers from "../../assets/travellers_4.jpg";
import Header from "../index/components/Header";
import {AppFooter} from "../index/components/app-footer";

class App extends Component {
  render() {
    return (
      <>
        <Header />
        <div
          className="flex-div"
          style={{
            width: "100%",
            scrollbarWidth: 0,
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <div className="display-port">
            <div
              className="flex-div flex-row"
              style={{
                background: "white",
                padding: 50,
                paddingBottom: 0,
                marginTop: 10,
                borderRadius: 20,
              }}
            >
              <AuthContainer origin="list-property-page" />
              <div
                style={{
                  textAlign: "center",
                  flex: 1,
                  borderLeft: "0px solid #eee",
                  marginBottom: 100,
                  paddingTop: 50,
                }}
              >
                <img alt="" src={travellers} width={520} />
              </div>
            </div>
          </div>
        </div>
        <AppFooter origin="" />
      </>
    );
  }
}

export default App;
