import {Component} from "react";

import AuthContainer from "../signin/AuthContainer";
import BookingSummary from "./BookingSummary";
import PaymentDetails from "./PaymentDetails";
import Header from "../index/components/Header";
import {AppFooter as Footer} from "../index/components/app-footer";

class App extends Component {
  state = {
    location: window.location.hash,
  };
  /**
   * Monitors change in hash to know what Section of checkout to display
   * especially for the Mobile devices.
   *
   * @param {HashChangeEvent} e event
   */
  _hashChangeMonitor = (e) => { // eslint-disable-line no-unused-vars
    console.log("hashchange1", window.location.hash);
    this.setState({ location: window.location.hash });
  };

  componentDidMount() {
    window.addEventListener("hashchange", this._hashChangeMonitor);
  }

  // garbage collection
  componentWillUnmount() {
    window.removeEventListener("hashchange", this._hashChangeMonitor);
  }

  render() {
    return (
      <>
        <Header />
        <div
          className={
            "w-screen xl:w-full h-screen xl:h-full bg-white flex flex-col"
          }
          style={{
            // marginTop: 60,
          }}
        >
          <div className="display-port">
            <div style={{ textAlign: "center", marginTop: 20, fontSize: 30 }}>
              REVIEW & CHECKOUT
            </div>
            <div
              className="linestepbar-container mobile-hidden"
              style={{ width: 1500, marginTop: 20 }}
            >
              <ul className="linestepbar">
                <li
                  className={
                    this.state.location === "" ||
                    this.state.location === "#summary"
                      ? "active"
                      : ""
                  }
                >
                  Signin
                </li>
                <li className={this.state.location === "#summary" ? "active" : ""}>
                  Summary
                </li>
                <li
                  className={this.state.location === "#confirmation" ? "active" : ""}
                >
                  Confirmation
                </li>
              </ul>
            </div>
            <div className="desktop-hidden">
              <div
                style={{
                  display: "flex",
                  marginTop: 15,
                  minWidth: "100%",
                  fontSize: 12,
                  textAlign: "center",
                }}
              >
                {this.state.location === "" ||
                this.state.location === "#summary" ? (
                  <div style={{ flex: 1, fontWeight: "700" }}>1. Signin</div>
                ) : (
                  <div style={{ flex: 1 }}>1. Signin</div>
                )}
                {this.state.location === "#summary" ? (
                  <div style={{ flex: 1, fontWeight: "700" }}>2. Summary</div>
                ) : (
                  <div style={{ flex: 1 }}>2. Summary</div>
                )}
                {this.state.location === "#confirmation" ? (
                  <div style={{ flex: 1, fontWeight: "700" }}>
                    3. Confirmation
                  </div>
                ) : (
                  <div style={{ flex: 1 }}>3. Confirmation</div>
                )}
              </div>
            </div>
          </div>
          <div style={{ borderTop: "0px solid #eee", marginTop: 5 }}>
            {this.state.location === "" && (
              <div
                style={{
                  margin: "auto",
                  padding: 10,
                  textAlign: "center",
                  background: "white",
                }}
                className="checkout-signin-parent"
              >
                <AuthContainer
                  origin="checkout-page"
                  location={this.state.location.replace("/checkout/", "")}
                />
              </div>
            )}
            {this.state.location === "#summary" && <BookingSummary />}
            {this.state.location === "#confirmation" && (
              <PaymentDetails />
            )}
          </div>
        </div>
        <Footer origin="" />
      </>
    );
  }
}

export default App;
