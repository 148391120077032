import type {FC} from 'react';
import { FaChevronRight } from "react-icons/fa";
// import { SectionHeader } from "../../section-header";

import {RangeSlider} from "./RangeSlider";

export interface PriceFilterProps {
  maximumPrice: number;
  minimumPrice: number;
  rangeMin: number;
  rangeMax: number;
  updateState: (
    updatedProps: {
      rangeMin: number,
      rangeMax: number,
      priceChanged: boolean
    }) => void;
}

export const PriceFilter: FC<PriceFilterProps> = ({
  maximumPrice, minimumPrice, rangeMin, rangeMax, updateState,
}) => {
  const onPriceRangeChange = (value: number[]) => {

    const url = new URL(location.href);
    const minPrice = Math.floor(
      (value[0] / 100) * maximumPrice
    );

    const maxPrice = Math.floor(
      (value[1] / 100) * maximumPrice
    );

    if (minPrice !== minimumPrice) {
      if (url.searchParams.has("price_lowest")) {
        if (
          Number(url.searchParams.get("price_lowest")) !== minPrice
        ) {
          url.searchParams.set("price_lowest", `${minPrice}`);
        }
      } else {
        url.searchParams.append("price_lowest", `${minPrice}`)
      }
    }

    if (maxPrice !== maximumPrice) {
      if (url.searchParams.has("price_highest")) {
        if (
            Number(url.searchParams.get("price_highest")) !== 
              maxPrice
          ) {
          url.searchParams.set("price_highest", `${maxPrice}`);
        }
      } else {
        url.searchParams.append("price_highest", `${maxPrice}`);
      }
    }

    history.replaceState(null, "", url.toString());
    
    updateState({
      rangeMin: minPrice,
      rangeMax: maxPrice,
      priceChanged: true,
    });
  };

  return (
    <div className="max-w-xs place-self-center">
      {/* <SectionHeader style={{borderTop: 0}}>
        Price Range
      </SectionHeader> */}
      {/* <div
        className="text-sm mb-3 mt-2 p-2 mx-7"
        style={{backgroundColor: "#FFE9E9"}}
      >
        Filter Hotels Based on their total Price for {" "}
        {sharedState.numberOfDays} days(s)
      </div> */}
      <div
        className="mb-3 p-7 pt-3 pb-0 rounded-none bg-white flex flex-col"
      >
        <RangeSlider
          onRangeChange={onPriceRangeChange}
          rangeMin={rangeMin}
          rangeMax={rangeMax}
          maxPrice={maximumPrice}
        />
        <div className="flex flex-row p-1 justify-between items-center">
          <div className="text-xs">
            {`${rangeMin} USD`}
          </div>
          <div
            className="text-xs text-center pt-1"
          >
            <FaChevronRight />
          </div>
          <div className="text-xs text-right">
            {`USD ${rangeMax}`}
          </div>
        </div>
      </div>
    </div>
  );
}
