import {FC, useEffect, useState} from 'react';
import { RiRoadMapLine } from 'react-icons/ri';
import {RouteComponentProps} from 'react-router';

import {Tour as TourType, fetchTours} from '../../../../api';
import { PartnerHeader } from '../../components';

import {PropertiesSummary} from '../../components/properties-summary';

export const Tour: FC<RouteComponentProps> = () => {
  const [tours, setTours] = useState<TourType[] | null>(null);

  useEffect(() => {
    fetchTours((err, results) => {
      if (err) {
        console.error(err);

        return;
      }

      setTours(results);
    });
  }, []);

  return (
    <>
      {tours && (
        <>
          <PartnerHeader HeaderIcon={RiRoadMapLine} title="All Tours" />
          <PropertiesSummary
            category="tour"
            properties={tours}
          />
        </>
      )}
    </>
  );
};
