import {FC, useState} from 'react';
import {IoMdAdd} from 'react-icons/io';
import {useHistory} from 'react-router';

import {Button} from '../../../components';
import {Input} from '../../../components/input';

type NewRouteOverlayProps = {
  removeOverlay: () => void;
};

export const NewRouteOverlay: FC<NewRouteOverlayProps> = ({
  removeOverlay,
}) => {
  const history = useHistory();

  const [routeTitle, setRouteTitle] = useState<string>("");

  return (
    <div
      className="rounded-md overflow-hidden shadow-lg bg-white"
      style={{
        minWidth: 280,
      }}
    >
      <div
        className={
          "flex flex-row items-center font-lato text-xl font-semibold " +
          "bg-safari-gold text-white"
        }
        style={{
          height: 64,
          padding: "0px 16px 0px 16px",
        }}
      >
        Create new route
      </div>
      <div className="px-4 pt-2">
        <Input
          className="my-2 mb-3 w-72"
          helper="Title of the route"
          id="route-title"
          inputLabel="Title of route"
          name="route_title"
          value={routeTitle}
          onChange={(e) => {
            setRouteTitle(e.target.value);
          }}
        />

        <Input
          disabled={true}
          className="my-2 w-72"
          helper="Preview of the route"
          id="route-preview"
          inputLabel="Preview of the route"
          name="route_title"
          value={`/su/about/edit/${
            routeTitle.toLowerCase().trim().replace(" ", "_")
          }`}
          onChange={(e) => {
            // not supposed to happen
          }}
        />
      </div>
      <div
        className="flex flex-row items-center justify-end border-t px-4"
        style={{
          height: 52,
        }}
      >
        <Button
          disabled={routeTitle === ""}
          mode="outlined"
          type="button"
          onClick={() => {
            // create the new route, and make sure that it does not exist
            history.push(`/su/about/edit/${
              routeTitle.trim().replace(" ", "_").toLowerCase()
            }`);
            // remove overlay
            removeOverlay();
          }}
        >
          <div className="flex flex-row items-center">
            <IoMdAdd className="mr-2" size={24} />
            Create new route
          </div>
        </Button>
      </div>
    </div>
  );
};
