import {FC} from "react";
import {useHistory} from "react-router";

import {Coordinates, Property, ReservationCategory} from "../../../api";
import {AttractionSite} from "../../../api/attraction/types";
import {Rating} from "../../../components/rating";
import {DateUtil} from "../../../util";
import {generatePropertySearchUrl} from "../../../util/generate-property-url";

export type FlipBoxCategory = ReservationCategory & "attraction";

type FlipBoxProps = {
  category: FlipBoxCategory;
  property: Property | AttractionSite;
};

export const FlipBox: FC<FlipBoxProps> = ({
  property, category,
}) => {
  const history = useHistory();

  return (
    <div className="grid-item">
      <div className="flip-box">
        <div className="flip-box-inner">
          <div className="flip-box-front">
            <div
              style={{
                background: "#333",
                height: "100%",
                backgroundImage: `url(${property.gallery[0].url})`,
                backgroundSize: "cover",
                paddingTop: 15,
              }}
            />
          </div>
          <div
            className={
              "flip-box-back flex items-center content-center" +
              " justify-items-center justify-center"
            }
          >
              <div
                className="but but-orange"
                style={{}}
                onClick={() => {
                  // scroll to top to prevent the browser from memorising
                  // the current location
                  window.scrollTo({behavior: "smooth", top: 0, left: 0});
                  history.push(getViewPropertyUrl(
                    category, `${property.id}`, property.location,
                    (category === "attraction") ?
                      (property as AttractionSite).coordinates :
                      (property as Property).coords
                  ));
                }}
              >
                VIEW PROPERTY
              </div>{" "}
          </div>
        </div>
      </div>
      <div
        style={{
          padding: 5,
          fontWeight: "bold",
          height: 100,
          paddingTop: 15,
        }}
      >
        <div
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {" "}
          {category !== "attraction" ?
            (property as Property).title : (property as AttractionSite).name
          }
        </div>
        <div style={{ display: "flex", marginTop: 0 }}>
          <Rating
            rating={
              category !== "attraction" ?
              (property as Property).rating :
              5
            }
          />
        </div>
        <div
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontWeight: 300,
            textTransform: "capitalize",
          }}
        >
          <span>{property.location}</span>
        </div>
      </div>
    </div>
  );
};

const getViewPropertyUrl = (
  category: ReservationCategory & "attraction", propertyId: string,
  locationName: string, coords: Coordinates | undefined,
): string => {
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1)
  const dayAfter = new Date();
  tomorrow.setDate(today.getDate() + 2);

  // since we know that there is the date parameter and the adults parameter
  // and the children parameter, we will use that information to get details
  // from the url
  const [pathname] = location.pathname.split("?");
  const searchParams = new URLSearchParams(location.href.replace(pathname, ""));
  const date = searchParams.get("date");
  const adults = searchParams.get("adults");
  const children = searchParams.get("children");
  const checkout = searchParams.get("checkout");
  // summation of adults and children
  const people =
    0 + ((adults) ? Number(adults) : 1) + ((children) ? Number(children) : 0);

  // set the checkout
  // if the user has interacted with the DatePicker.RangePicker from the search
  // column, there is the checkout parameter set by the search, however if that
  // is not the case, we will need to set a checkout date that is relative to
  // the date parameter (if present), or the dayAfter variable, if the date
  // parameter is null
  const checkoutDate  = checkout ? checkout : DateUtil.stringifyDate(dayAfter);

  let dest = "";

  switch (category) {
    case "activity":
      dest = generatePropertySearchUrl<"activity">(
        "/property",
        "activity",
        `${propertyId}`,
        {
          adults: adults ? Number(adults) : 1,
          date: date ? date : DateUtil.stringifyDate(tomorrow),
          children: children ? Number(children) : 0,
          lat: coords ? coords.lat : 0,
          lng: coords ? coords.lng : 0,
          place: locationName,
        }
      );
      break;

    case "attraction":
      // generate the attraction details
      dest = `view-attraction/${propertyId}/details?place${
        locationName
      }&lat=${
        coords ? coords.lat : 0
      }&lng=${
        coords ? coords.lng : 0
      }&adults=${
        adults? adults : 1
      }&children=${
        children ? children : 0
      }&date=${
        date ? date : DateUtil.stringifyDate(tomorrow)
      }`;
      break;

    case "car":
      dest = generatePropertySearchUrl<"car">(
        "/property",
        "car",
        `${propertyId}`,
        {
          passengers: people,
          date: date ? date : DateUtil.stringifyDate(tomorrow),
          lat: coords ? coords.lat : 0,
          lng: coords ? coords.lng : 0,
          place: locationName,
        }
      );
      break;
  
    case "conference":
      dest = generatePropertySearchUrl<"conference">(
        "/property",
        "conference",
        `${propertyId}`,
        {
          people,
          date: date ? date : DateUtil.stringifyDate(tomorrow),
          lat: coords ? coords.lat : 0,
          lng: coords ? coords.lng : 0,
          place: locationName,
        }
      );
      break;
  
    case "eatery":
      dest = generatePropertySearchUrl<"eatery">(
        "/property",
        "eatery",
        `${propertyId}`,
        {
          people,
          date: date ? date : DateUtil.stringifyDate(tomorrow),
          lat: coords ? coords.lat : 0,
          lng: coords ? coords.lng : 0,
          place: locationName,
        }
      );
      break;
  
    case "hotel":
      dest = generatePropertySearchUrl<"hotel">(
        "/property",
        "hotel",
        `${propertyId}`,
        {
          adults: adults ? Number(adults) : 1,
          checkin: date ? date : DateUtil.stringifyDate(tomorrow),
          checkout: checkoutDate,
          children: children ? Number(children) : 0,
          lat: coords ? coords.lat : 0,
          lng: coords ? coords.lng : 0,
          place: locationName,
        }
      );
      break;
  
    case "rental":
      dest = generatePropertySearchUrl<"rental">(
        "/property",
        "rental",
        `${propertyId}`,
        {
          adults: 1,
          checkin: date ? date : DateUtil.stringifyDate(tomorrow),
          checkout: checkoutDate,
          children: children ? Number(children) : 0,
          lat: coords ? coords.lat : 0,
          lng: coords ? coords.lng : 0,
          place: locationName,
        }
      );
      break;
  
    case "tour":
      dest = generatePropertySearchUrl<"tour">(
        "/property",
        "tour",
        `${propertyId}`,
        {
          adults: adults ? Number(adults) : 1,
          date: DateUtil.stringifyDate(tomorrow),
          children: children ? Number(children) : 0,
          lat: coords ? coords.lat : 0,
          lng: coords ? coords.lng : 0,
          place: locationName,
        }
      );
      break;
  
    default:
      break;
  }

  return dest;
};
