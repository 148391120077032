import {
  createRef,
  FC,
  RefObject,
  useContext,
  useEffect,
  useState,
} from "react";
import { Tabs } from "antd";

import {
  fetchAttraction,
  fetchNearbyProperties,
  ReservationCategory,
} from "../../../../api";
import { AttractionSite, Nearby } from "../../../../api/attraction/types";
import { SnackbarProvider } from "../../../../context/snackbar-provider";

import { MapMarker } from "./map-marker";
import { NearbyPropertiesMapView } from "./nearby-properties-map-view";
import { AllProperties } from "./all-properties";
import { FilterPropertiesColumn } from "./filter-properties-column";
import {
  fetchFacilities,
  fetchFeatures,
  getPropertyFacilities,
  getPropertyFeatures,
} from "../../../../util/facilities-and-features";
import { rankFilters } from "../../../../util/rank-filters";
import { DateUtil } from "../../../../util";
import { useHistory } from "react-router";

type NearbyPropertiesColumnProps = {
  attractionId: string;
};

export type NearbyPropertyRef = {
  marker: RefObject<MapMarker>;
  card: RefObject<HTMLDivElement>;
};

export type NearbyPropertyRefTree = {
  [P in ReservationCategory]: NearbyPropertyRef[];
};

export const NearbyPropertiesColumn: FC<NearbyPropertiesColumnProps> = ({
  attractionId,
}) => {
  const { dismissSnackbar, setIsVisible } = useContext(SnackbarProvider);

  const history = useHistory();

  const [activeTab, setActiveTab] = useState<"1" | "2" | "3">("1");
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [nearbyProperties, setNearbyProperties] = useState<Nearby[]>();
  const [nearbyPropertiesRefs, setNearbyPropertiesRefs] =
    useState<NearbyPropertyRefTree>({
      activity: [],
      car: [],
      conference: [],
      cruise: [],
      eatery: [],
      flight: [],
      hotel: [],
      rental: [],
      tour: [],
    });
  const [attraction, setAttraction] = useState<AttractionSite>();
  const [filterTabVisible, setFilterTabVisible] = useState<boolean>(false);
  const [activeCategories, setActiveCategories] = useState<
    { [K in ReservationCategory]: boolean }
  >({
    activity: true,
    car: true,
    conference: true,
    cruise: true,
    eatery: true,
    flight: true,
    hotel: true,
    rental: true,
    tour: true,
  });
  const [activeCategoryFilter, setActiveCatgoryFilter] =
    useState<ReservationCategory>("hotel");

  const [activeFacilityFilters, setActiveFacilityFilters] = useState<
    { [K in ReservationCategory]: string[] }
  >({
    activity: [],
    car: [],
    conference: [],
    cruise: [],
    eatery: [],
    flight: [],
    hotel: [],
    rental: [],
    tour: [],
  });

  const [activeFeatureFilters, setActiveFeatureFilters] = useState<
    { [K in ReservationCategory]: string[] }
  >({
    activity: [],
    car: [],
    conference: [],
    cruise: [],
    eatery: [],
    flight: [],
    hotel: [],
    rental: [],
    tour: [],
  });

  useEffect(() => {
    fetchAttraction(attractionId, (err, result) => {
      if (err) {
        console.error(err);

        return;
      }

      if (result) {
        setAttraction(result);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    const dayAfter = new Date();
    tomorrow.setDate(today.getDate() + 2);

    // since we know that there is the date parameter and the adults parameter
    // and the children parameter, we will use that information to get details
    // from the url
    const [pathname] = location.pathname.split("?");
    const searchParams = new URLSearchParams(
      location.href.replace(pathname, "")
    );
    const date = searchParams.get("date");
    const checkout = searchParams.get("checkout");
    // set the checkout
    // if the user has interacted with the DatePicker.RangePicker from the search
    // column, there is the checkout parameter set by the search, however if that
    // is not the case, we will need to set a checkout date that is relative to
    // the date parameter (if present), or the dayAfter variable, if the date
    // parameter is null
    const checkinDate: number = date
      ? new Date(
          DateUtil.changeDateFormat(date, "DD-MM-YYYY", "YYYY-MM-DD")
        ).getTime()
      : tomorrow.getTime();
    const checkoutDate: number = checkout
      ? new Date(
          DateUtil.changeDateFormat(checkout, "DD-MM-YYYY", "YYYY-MM-DD")
        ).getTime()
      : dayAfter.getTime();

    fetchNearbyProperties(
      attractionId, checkinDate, checkoutDate,
      (err, results) => {
        setIsFetching(false);

        if (err) {
          console.error(err);

          setIsVisible &&
            setIsVisible({
              fabPresent: true,
              isError: true,
              navRailPresent: false,
              title: "Unable to fetch nearby properties",
              action: {
                label: "DISMISS",
                onClick: () => {
                  dismissSnackbar && dismissSnackbar();
                },
              },
            });

          return;
        }

        if (results) {
          // These refs are bundled together and passed to their as an entire
          // object to both the Marker and the Card (which are written to take
          // their coresponding marker as a ref and the coounterpart marker to
          // make changes to the counterpart once an action happens to it)
          const nearbyPropertyRefs: NearbyPropertyRefTree = {
            activity: [],
            car: [],
            conference: [],
            cruise: [],
            eatery: [],
            flight: [],
            hotel: [],
            rental: [],
            tour: [],
          };

          results.forEach(
            (nearby) =>
              (nearbyPropertyRefs[nearby.category] = nearby.properties.map(
                () => ({
                  marker: createRef<MapMarker>(),
                  card: createRef<HTMLDivElement>(),
                })
              ))
          );

          setNearbyPropertiesRefs(nearbyPropertyRefs);
          setNearbyProperties(results);
        } else {
          setIsVisible &&
            setIsVisible({
              fabPresent: true,
              isError: true,
              navRailPresent: false,
              title: "No nearby properties found",
              action: {
                label: "DISMISS",
                onClick: () => {
                  dismissSnackbar && dismissSnackbar();
                },
              },
            });
        }
      }
    );
  }, [isFetching]); // eslint-disable-line react-hooks/exhaustive-deps

  // anytime either the activeFeatureFilters or activeFacilityFeatures changes,
  // we need to react
  useEffect(() => {
    if (!nearbyProperties) return; // prevent trying to access undefined

    let nearbyIdx = -1;

    const [{ properties }] = nearbyProperties.filter((nearby, idx) => {
      nearbyIdx = idx;

      return nearby.category === activeCategoryFilter;
    });

    const rankedProperties = rankFilters(
      properties,
      {
        active: activeFacilityFilters[activeCategoryFilter],
        get: (property) => {
          return getPropertyFacilities(activeCategoryFilter, property).map(
            (facility) => facility.title
          );
        },
      },
      {
        active: activeFeatureFilters[activeCategoryFilter],
        get: (property) => {
          return getPropertyFeatures(activeCategoryFilter, property).map(
            (feature) => feature.title
          );
        },
      }
    );

    // after the proeprties are ranked, we splice the index and replace it
    // with the a Nearby that contains the ranked propeties
    const newNearby = [...nearbyProperties];
    newNearby.splice(
      nearbyIdx,
      1,
      // replace with the same property but updated with the ranked properties
      { ...nearbyProperties[nearbyIdx], properties: rankedProperties }
    );

    // update with that caegory ranked
    setNearbyProperties(newNearby);
  }, [// eslint-disable-line react-hooks/exhaustive-deps
    activeFacilityFilters,
    activeFeatureFilters,
  ]);

  // toggle categories using a helper function
  const toggleCategory = (category: ReservationCategory) => {
    const updatedCategories = {
      ...activeCategories,
      [category]: !activeCategories[category],
    };

    setActiveCategories(updatedCategories);
  };

  /**
   * This function updates the browser url with the new search parameters in
   * terms of the date to make it easier to propagate those dates forward to
   * the /property route to make it more conventient for the user
   *
   * @param range this is the new range of dates
   */
  const onDateRangeChange = (range: [Date, Date]) => {
    const urlSearchParams = new URLSearchParams(
      location.pathname.replace(history.location.pathname, "")
    );
    urlSearchParams.delete("date");
    urlSearchParams.delete("checkout");

    urlSearchParams.append("date", DateUtil.stringifyDate(range[0]));
    urlSearchParams.append("checkout", DateUtil.stringifyDate(range[1]));

    console.log(history.location.pathname);
    console.log(urlSearchParams.toString());

    history.replace(
      `${history.location.pathname}?${urlSearchParams.toString()}`
    );
    // after the dates are changed, we make the data stale
    setIsFetching(true);
  };

  return (
    <div className="absolute top-0 bottom-0 right-0 left-0">
      <Tabs
        className="w-full h-full sm:hidden"
        renderTabBar={() => <></>}
        activeKey={activeTab}
      >
        <Tabs.TabPane key="1">
          <AllProperties
            activeCategories={activeCategories}
            attraction={attraction}
            isFetching={isFetching}
            nearbyProperties={nearbyProperties}
            nearbyPropertiesRefs={nearbyPropertiesRefs}
            onDateRangeChange={onDateRangeChange}
            setActiveTab={setActiveTab}
            toggleCategory={toggleCategory}
          />
        </Tabs.TabPane>

        <Tabs.TabPane
          key="2"
          className="h-full absolute top-0 right-0 left-0 bottom-0"
        >
          <NearbyPropertiesMapView
            refs={nearbyPropertiesRefs}
            filterTabVisible={filterTabVisible}
            properties={nearbyProperties}
            setFilterTabVisible={setFilterTabVisible}
            activeCategories={activeCategories}
            toggleCategory={toggleCategory}
            setActiveTab={setActiveTab}
          />
        </Tabs.TabPane>

        <Tabs.TabPane
          key="3"
          className="h-full absolute top-0 right-0 left-0 bottom-0"
        >
          <FilterPropertiesColumn
            filterTabVisible={filterTabVisible}
            setFilterTabVisible={setFilterTabVisible}
            setActiveTab={setActiveTab}
            activeCategories={activeCategories}
            columnFilters={[
              {
                title: `${activeCategoryFilter} facilities`,
                data: fetchFacilities(activeCategoryFilter),
                onChange: (activeFacilities) => {
                  const newActiveFilters = {
                    ...activeFacilityFilters,
                    [activeCategoryFilter]: activeFacilities,
                  };

                  setActiveFacilityFilters(newActiveFilters);
                },
              },
              {
                title: `${activeCategoryFilter} features`,
                data: fetchFeatures(activeCategoryFilter),
                onChange: (activeFeatures) => {
                  const newActiveFilters = {
                    ...activeFeatureFilters,
                    [activeCategoryFilter]: activeFeatures,
                  };

                  setActiveFeatureFilters(newActiveFilters);
                },
              },
            ]}
            activeFilters={activeCategoryFilter}
            setActiveFilters={setActiveCatgoryFilter}
          />
        </Tabs.TabPane>
      </Tabs>

      {/* Displayed on larger displays */}
      <div className="h-full hidden sm:flex sm:flex-row flex-nowrap sm:items-stretch sm:overflow-x-hidden relative">
        <AllProperties
          activeCategories={activeCategories}
          attraction={attraction}
          isFetching={isFetching}
          nearbyProperties={nearbyProperties}
          nearbyPropertiesRefs={nearbyPropertiesRefs}
          onDateRangeChange={onDateRangeChange}
          toggleCategory={toggleCategory}
        />
        <NearbyPropertiesMapView
          refs={nearbyPropertiesRefs}
          filterTabVisible={filterTabVisible}
          properties={nearbyProperties}
          setFilterTabVisible={setFilterTabVisible}
          activeCategories={activeCategories}
          toggleCategory={toggleCategory}
        />
        <FilterPropertiesColumn
          filterTabVisible={filterTabVisible}
          setFilterTabVisible={setFilterTabVisible}
          activeCategories={activeCategories}
          columnFilters={[
            {
              title: `${activeCategoryFilter} facilities`,
              data: fetchFacilities(activeCategoryFilter),
              onChange: (activeFacilities) => {
                const newActiveFilters = {
                  ...activeFacilityFilters,
                  [activeCategoryFilter]: activeFacilities,
                };

                setActiveFacilityFilters(newActiveFilters);
              },
            },
            {
              title: `${activeCategoryFilter} features`,
              data: fetchFeatures(activeCategoryFilter),
              onChange: (activeFeatures) => {
                const newActiveFilters = {
                  ...activeFeatureFilters,
                  [activeCategoryFilter]: activeFeatures,
                };

                setActiveFeatureFilters(newActiveFilters);
              },
            },
          ]}
          activeFilters={activeCategoryFilter}
          setActiveFilters={setActiveCatgoryFilter}
        />
      </div>
    </div>
  );
};
