import { database } from "firebase";
import {Component} from "react";

export default class App extends Component {
  state = {
    visibleScreen: "awaiting-payment",
  };
  componentDidMount() {
    const href = window.location.href;
    const transactionID = href.split("transaction=")[1].split("&")[0];
    const currentUid = href.split("uid=")[1].split("&")[0].split("#")[0];
    var transactionReference =
      "USERS/DATA/ACCOUNTS/MPESA/TRANSACTIONS/" +
      currentUid +
      "/" +
      transactionID;
    var purchaseRef = database().ref(transactionReference);
    purchaseRef.on("value", (snap) => {
      if (snap.exists()) {
        var status = snap.val().status;
        console.log("status", status);
        switch (status) {
          case "cancelled":
            this.setState({ visibleScreen: "request-cancelled" });
            break;
          case "awaiting user acceptance":
            this.setState({ visibleScreen: "awaiting-payment" });
            break;
          case "failed":
            this.setState({ visibleScreen: "request-unsuccessful" });
            break;
          case "success":
            this.setState({ visibleScreen: "request-successful" });
            break;
        }
      }
    });
  }
  render() {
    switch (this.state.visibleScreen) {
      case "request-cancelled":
        return (
          <div
            style={{
              display: "flex",
              width: "100%",
              padding: 0,
              marginTop: 20,
            }}
          >
            <div
              className="display-port"
              style={{
                display: "flex",
                width: 900,
                margin: "auto",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h2 style={{ textTransform: "uppercase" }}>REQUEST CANCELLED</h2>
              <p style={{ textAlign: "center" }}>
                Your request seems to have been terminated. Check whether you
                have sufficient funds and kindly try again.
              </p>
            </div>
          </div>
        );

      case "awaiting-payment":
        return (
          <div
            style={{
              display: "flex",
              width: "100%",
              padding: 0,
              marginTop: 20,
            }}
          >
            <div
              className="display-port"
              style={{
                display: "flex",
                width: 900,
                margin: "auto",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h2 style={{ textTransform: "uppercase" }}>
                Awaiting user acceptance
              </h2>
              <p style={{ textAlign: "center" }}>
                A payment request has been sent to your phone. <br />
                Kindly enter your pin to proceed.
              </p>
            </div>
          </div>
        );

      case "request-unsuccessful":
        return (
          <div
            style={{
              display: "flex",
              width: "100%",
              padding: 0,
              marginTop: 20,
            }}
          >
            <div
              className="display-port"
              style={{
                display: "flex",
                width: 900,
                margin: "auto",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h2 style={{ textTransform: "uppercase" }}>
                REQUEST WAS UNSUCCESSFUL
              </h2>
              <p style={{ textAlign: "center" }}>
                We were not able to complete your transaction at the moment.
                Please try again later.
              </p>
            </div>
          </div>
        );
      case "request-successful":
        return (
          <div
            style={{
              display: "flex",
              width: "100%",
              padding: 0,
              marginTop: 20,
            }}
          >
            <div
              className="display-port"
              style={{
                display: "flex",
                width: 900,
                margin: "auto",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h2 style={{ textTransform: "uppercase" }}>
                YOUR PAYMENT WAS SUCCESSFUL
              </h2>
              <p style={{ textAlign: "center" }}>
                We have sent a copy of the following receipt to your email.
                Thank you for using Safarixperts
              </p>
              <div
                className="orange-but"
                style={{
                  width: 300,
                  padding: 12,
                  marginBottom: 20,
                  background: "#121212",
                }}
                onClick={() => {
                  window.location = "/user";
                }}
              >
                VIEW YOUR BOOKING DETAILS
              </div>

              <div
                className="orange-but"
                style={{ width: 300, padding: 12 }}
                onClick={() => {
                  window.location = "/";
                }}
              >
                GO TO MAIN PAGE
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div
            style={{
              display: "flex",
              width: "100%",
              padding: 0,
              marginTop: 20,
            }}
          >
            <div
              className="display-port"
              style={{
                display: "flex",
                width: 900,
                margin: "auto",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h2 style={{ textTransform: "uppercase" }}>AN ERROR OCCURED</h2>
              <p style={{ textAlign: "center" }}>
                We were not able to complete your transaction at the moment.
                Please try again later.
              </p>
            </div>
          </div>
        );
    }
  }
}
