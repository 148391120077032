import {FC, useState} from 'react';
import {Formik} from 'formik';

import {FAQ} from '../../../api';
import { FaTimes } from 'react-icons/fa';
import { Input, TextArea } from '../../../components/input';

type EditFAQProps = {
  faqs: FAQ[];
  onSave: (newFaq: FAQ[]) => void;
  removeOverlay: () => void;
};

export const EditFAQ: FC<EditFAQProps> = ({faqs, onSave, removeOverlay}) => {
  const [currentFAQs, setCurrentFAQs] = useState<FAQ[]>(faqs);

  const addFAQ = (newFAQ: FAQ) => {
    const newFAQs = [...currentFAQs, newFAQ];

    setCurrentFAQs(newFAQs);
  };

  const removeFAQ = (idx: number) => {
    const newFAQs = [...currentFAQs];
    newFAQs.splice(idx, 1);

    setCurrentFAQs(newFAQs);
  };

  return (
    <div
      className={
        "absolute top-0 right-0 w-full h-full overflow-y-scroll bg-white " +
        "sm:max-w-md"
      }
    >
      {/** Edit FAQ App bar */}
      <div
        className={
          "bg-safari-gold sticky top-0 h-14 px-4 shadow-md flex flex-row " +
          "justify-between items-center text-white"
        }
      >
        {/** Title and back action */}
        <div className="flex flex-row items-center">
          <FaTimes size={24} onClick={removeOverlay} />
          <div className="ml-3">
            <h6 className="text-lg font-semibold">Edit FAQs</h6>
            <p className="text-xs">
              Give answers to the most Frequently asked questions
            </p>
          </div>
        </div>
        {/** Title and back action end */}

        {/** save action button */}
        <button
          className={
            "py-2 px-3 border border-black rounded-md text-black font-semibold text-sm"
          }
          onClick={() => {
            // save all the FAQs
            onSave(currentFAQs);
            removeOverlay();
          }}
        >
          <p className="text-sm font-semibold">
            Save
          </p>
        </button>
        {/** End save action button */}
      </div>
      {/** End of Edit FAQ App bar */}

      <Formik
        initialValues={{
          faq_title: "",
          faq_answer: "",
        }}
        validate={(values) => {
          // validates that the form values are not empty
          const errors: {faq_title?: string, faq_answer?: string} = {};

          if (values.faq_title === "") {
            errors.faq_title = "The FAQ question must be stated";
          }

          if (values.faq_answer === "") {
            errors.faq_answer = "an answer to the FAQ question must be provided";
          }

          return errors;
        }}
        onSubmit={({faq_answer, faq_title}, formikEvent) => {
          addFAQ({answer: faq_answer, question: faq_title});
          // after adding new value reset the form
          formikEvent.resetForm();
        }}
      >
        {({values, errors, handleChange, handleSubmit}) => (
          <form onSubmit={handleSubmit}>
            <div className="p-4">
              <Input
                helper="This is the title of the FAQ"
                id="faq-title"
                inputLabel="FAQ Question"
                name="faq_title"
                value={values.faq_title}
                error={errors.faq_title}
                onChange={handleChange}
              />

              <TextArea
                helper="This is the answer to the FAQ question"
                id="faq-answer"
                inputLabel="FAQ Answer"
                name="faq_answer"
                value={values.faq_answer}
                error={errors.faq_answer}
                onChange={handleChange}
              />

              <hr className="my-2" />
              <div className="flex flex-row items-center justify-end">
                <button
                  disabled={
                    errors.faq_title !== undefined ||
                    errors.faq_answer !== undefined
                  }
                  className={
                    "py-2 px-3 border rounded-md " +
                    "font-semibold text-sm " +
                    `${
                      errors.faq_answer || errors.faq_title
                        ? "text-gray-700 border-gray-700 cursor-not-allowed"
                        : "text-red-500 border-red-500"
                    }`
                  }
                  type="submit"
                >
                  <p className="text-sm font-semibold">
                    Add FAQ
                  </p>
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>

      <div className="px-4">
        {currentFAQs.map((quiz, idx) => (
          <div key={`faq_${idx}`} className="p-4 shadow-md">
            <h6 className="text-xl font-semibold">{quiz.question}?</h6>
            <hr className="my-2" />
            <p className="text-sm">
              {quiz.answer}
            </p>
            <hr className="my-2" />
            <div className="flex flex-row items-center justify-end">
              <button
                className={
                  "py-2 px-3 border rounded-md text-red-500 font-semibold " +
                  "text-sm"
                }
                onClick={() => removeFAQ(idx)}
              >
                <p className="text-sm font-semibold">
                  Remove FAQ
                </p>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
