/**
 * This is small text that appears after an element and may be used as a label,
 * or used to highlight error in user input.
 *
 * @param {{
 * children: any,
 * style: import('react').CSSProperties,
 * }} props
 *
 * @returns {JSX.Element}
 */
export function HelperText({children, style}) {
  return (
    <p
      style={{
        color: "#800020",
        fontSize: 12,
        marginTop: 4,
        marginBottom: 0,
        transitionProperty: "all",
        transitionDuration: 1,
        transitionTimingFunction: "linear",
        ...style,
      }}
    >
      {children}
    </p>
  );
}
