import {useRef, useState} from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import {Calendar, DateRange} from 'react-date-range';
import {FaCalendarAlt} from 'react-icons/fa';

import {DateUtil} from '../../../../util';

export function RangeInput({
  checkinName,
  checkinLabel,
  checkinDefaultValue,
  checkoutName,
  checkoutLabel,
  checkoutDefaultValue,
  setCheckout,
  setCheckin,
}) {
  const [checkinValue, setCheckinValue] = useState(checkinDefaultValue);
  const [checkoutValue, setCheckoutValue] = useState(checkoutDefaultValue);
  const [dateRanges, setDateRanges] = useState([{
    startDate: new Date(
      DateUtil.changeDateFormat(checkinValue, "DD-MM-YYYY", "YYYY-MM-DD")),
    endDate: new Date(
      DateUtil.changeDateFormat(checkoutValue, "DD-MM-YYYY", "YYYY-MM-DD")),
    key: "selection",
  }]);

  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const [rangePickerVisible,  setRangePickerVisible] = useState(false);

  const datePickerRef = useOnclickOutside(() => setDatePickerVisible(false));
  const rangePickerRef = useOnclickOutside(() => setRangePickerVisible(false));
  /**@type {import('react').RefObject<HTMLInputElement>} */
  const checkinInputRef = useRef();
  /**@type {import('react').RefObject<HTMLInputElement>} */
  const checkoutInputRef = useRef();

  const handleDateChange = (range) => {
    setCheckoutValue(DateUtil.stringifyDate(range));
    setDatePickerVisible(false);
  };

  /**
   * 
   * @param {import('react-date-range').OnChangeProps} ranges 
   */
  const handleRangeChange = (ranges) => {
    console.log(ranges);
    const newCheckinValue = DateUtil.stringifyDate(ranges.selection.startDate);
    const newCheckoutValue =  DateUtil.stringifyDate(ranges.selection.endDate);

    setCheckinValue(newCheckinValue);
    setCheckoutValue(newCheckoutValue);
    setDateRanges([{
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
      key: "selection",
    }]);
    setRangePickerVisible(false);
    setCheckin(newCheckinValue);
    setCheckout(newCheckoutValue);
  };

  return (
    <div>
      <label htmlFor={checkinName}>{checkinLabel}</label>
      <div
        style={{
          // border: "1px solid #ccc",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          position: "relative",
          backgroundColor: "#fff",
          borderRadius: 2,
          cursor: "pointer",
        }}
        onClick={() => {
          setRangePickerVisible(true);
        }}
      >
        <FaCalendarAlt
          color="rgb(7, 160, 220)"
          style={{
            marginLeft: 12,
            marginRight: 4,
          }}
        />
        <div>
          <input
            ref={checkinInputRef}
            name={checkinName}
            onChange={(e) => {
              // we hijack the value and set our own value
              setCheckin(e.target.value);
            }}
            value={checkinValue}
            style={{
              border: 0,
              padding: 8,
            }}
            type="hidden"
          />
          <div
            style={{
              border: 0,
              padding: 8,
            }}
          >
            {checkinValue}
          </div>
          {rangePickerVisible && (
            <div
              ref={rangePickerRef}
              style={{position: "absolute", zIndex: 20}}
            >
              <DateRange
                minDate={new Date()}
                editableDateInputs={true}
                ranges={dateRanges}
                onChange={handleRangeChange}
                moveRangeOnFirstSelection={false}
              />
            </div>
          )}
        </div>
      </div>

      <label htmlFor={checkoutName}>{checkoutLabel}</label>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          position: "relative",
          backgroundColor: "#fff",
          borderRadius: 2,
          cursor: "pointer",
        }}
        onClick={() => {
          setDatePickerVisible(true);
        }}
      >
        <FaCalendarAlt
          color="rgba(7, 160, 220)"
          style={{
            marginLeft: 12,
            marginRight: 4,
          }}
        />
        <div>
          <input
            ref={checkoutInputRef}
            name={checkoutName}
            onChange={(e) => {
              setCheckout(e.target.value);
            }}
            value={checkoutValue}
            style={{
              border: 0,
              padding: 8,
            }}
            type="hidden"
          />
          <div
            style={{
              border: 0,
              padding: 8,
            }}
          >
            {checkoutValue}
          </div>
          {datePickerVisible && (
            <div
              ref={datePickerRef}
              style={{position: "absolute", zIndex: 20}}
            >
              <Calendar
                minDate={
                  new Date(
                    DateUtil.changeDateFormat(
                      checkinValue, "DD-MM-YYYY", "YYYY-MM-DD"))
                }
                date={
                  new Date(
                    DateUtil.changeDateFormat(
                      checkoutValue, "DD-MM-YYYY", "YYYY-MM-DD"))
                }
                onChange={handleDateChange}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
