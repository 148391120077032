import {FC, useEffect, useState} from "react";
import {BsPencil} from "react-icons/bs";
import {RiRoadMapLine} from "react-icons/ri";
import {RouteComponentProps, useHistory} from "react-router";

import {fetchTour, Tour} from "../../../../api";
import {OverlayProvider} from "../../../search-property/context";
import {PartnerHeader} from "../../components";

import {PartnerProperty} from "../../components/property";
import {RoomOverlay} from "../../components/room-overlay";

export const TourView: FC<RouteComponentProps<{id: string}>> = ({
  match,
}) => {
  const history = useHistory();

  const [tour, setTour] = useState<null | Tour>(null);

  useEffect(() => {
    fetchTour(match.params.id, (err, data) => {
      if (err) {
        console.error(err);

        return;
      }

      setTour(data);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {tour && (
        <>
          <PartnerHeader
            HeaderIcon={RiRoadMapLine}
            title={tour.title}
            actions={[
              {
                ActionIcon: BsPencil,
                alwaysVisible: true,
                label: "Edit Property contact information",
                type: "button",
                onClick: () => {
                  // migrate to the property edit tab
                  history.push(
                    `/property-owner/edit/tour/${tour.id}/details`
                  );
                },
              },
            ]}
          />
          <OverlayProvider.Consumer>
            {({setIsOverlay, setOnOverlay}) => (
              <PartnerProperty
                property={tour}
                propertyType="tour"
                subProperties={tour.stops.map((stop) => ({
                  cover: stop.gallery[0].url,
                  description: stop.description,
                  subtitle: stop.location,
                  title: stop.title,
                  action: {
                    label: "View Stop",
                    onClick: () => {
                      setOnOverlay && setOnOverlay(() => () => (
                        <RoomOverlay
                          gallery={stop.gallery}
                          description={stop.description}
                          facilities={stop.facilities}
                          features={stop.features}
                          onRoomEdit={() => console.log("Editing stop")}
                          policies={[]}
                          removeOverlay={() => {
                            setIsOverlay && setIsOverlay(false);
                            setOnOverlay && setOnOverlay(null);
                          }}
                          title={stop.title}
                          coords={stop.coords}
                        />
                      ));
                    },
                  },
                }))}
                subPropertySectionTitle="Stop"
              />
            )}
          </OverlayProvider.Consumer>
        </>
      )}
    </>
  );
};
