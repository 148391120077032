import {useState} from "react";
import type {FC} from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import {Calendar} from "react-date-range";
import type {OnCalendarChangeProps} from "react-date-range";
import {FaCalendarAlt} from "react-icons/fa";

import {DateUtil} from "../../../../util";

export interface DateInputProps {
  defaultValue: string;
  label: string;
  name: string;
  setDate: (date: string) => void;
}

export const DateInput: FC<DateInputProps> = ({
  defaultValue, label, name, setDate,
}) => {
  const [value, setValue] = useState(defaultValue);
  const [datePickerVisible, setDatePickerVisible] = useState(false);

  const datePickerRef = useOnclickOutside(() => setDatePickerVisible(false));

  const handleDateChange = (range: OnCalendarChangeProps) => {
    const newDate = DateUtil.stringifyDate(range);
    setValue(newDate);
    setDatePickerVisible(false);
    setDate(newDate);
  };

  return (
    <div>
      <label htmlFor={name}>{label}</label>
      <div
        style={{
          // border: "1px solid #ccc",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          position: "relative",
          backgroundColor: "#fff",
          borderRadius: 2,
        }}
        onClick={() => {
          setDatePickerVisible(true);
        }}
      >
        <FaCalendarAlt
          color="rgba(7, 160, 220)"
          style={{
            marginLeft: 12,
            marginRight: 4,
          }}
        />
        <div>
          <input
            name={name}
            onChange={(e) => setDate(e.target.value)}
            value={value}
            style={{
              border: 0,
              padding: 8,
            }}
            type="hidden"
          />
          <div
            style={{
              border: 0,
              padding: 8,
            }}
          >
            {value}
          </div>
          {datePickerVisible && (
            <div
              ref={datePickerRef}
              style={{position: "absolute", zIndex: 20}}
            >
              <Calendar
                minDate={new Date()}
                date={
                  new Date(
                    DateUtil.changeDateFormat(
                      defaultValue, "DD-MM-YYYY", "YYYY-MM-DD"))
                }
                onChange={handleDateChange}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
