import {firestore} from "firebase";
import {OnCompleteCallback, PropertyDetails, ReservationCategory} from "..";
import {getUser, NoUserFoundError} from "../user";

export const fetchPropertyContactDetails = async (
  category: ReservationCategory, id: string,
  onComplete: OnCompleteCallback<PropertyDetails>
): Promise<void> => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);

    return;
  }

  try {
    const snapshot = await firestore().collection(`phonebook/${category}/info`)
      .doc(id)
      .get();
    
    const propertyDetails = snapshot.data() as PropertyDetails;

    onComplete(null, propertyDetails);
  } catch (err) {
    console.error(err);

    onComplete(new Error("Unable to fetch property details"), null);
  }
};

export const postPropertyContactDetails = async (
  category: ReservationCategory, id: string, details: PropertyDetails,
  onComplete: OnCompleteCallback<PropertyDetails>,
): Promise<void> => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);

    return;
  }

  try {
    await firestore().collection(`phonebook/${category}/info`)
      .doc(id)
      .set(details);
    const docRef = firestore()
      .collection(`phonebook/${category}/info`)
      .doc(id);
    
    const snapshot = await docRef.get();
    const data = snapshot.data() as PropertyDetails;
  
    onComplete(null, data);
  } catch (err) {
    const fetchErr = new Error("Unable to create document details");
    onComplete(fetchErr, null);
  }
};

export const updatePropertyContactDetails = async (
  category: ReservationCategory, id: string, details: Partial<PropertyDetails>,
  onComplete: OnCompleteCallback<PropertyDetails>,
): Promise<void> => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);

    return;
  }

  try {
    const docRef = firestore().collection(`phonebook/${category}/info`).doc(id);
    docRef.update({details});

    const dataSnapshot = await docRef.get();
    onComplete(null, dataSnapshot.data() as PropertyDetails);
  } catch (err) {
    console.error(err);

    onComplete(new Error("Unable to update property details"), null);
  }
};
