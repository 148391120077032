import { FC, useState } from "react";
import { FaChevronLeft, FaChevronRight, FaTimes } from "react-icons/fa";
import useOnclickOutside from "react-cool-onclickoutside";

import { Picture } from "../../../api";

type GalleryOverlayProps = {
  gallery: Picture[];
  removeOverlay: () => void;
};

const GalleryOverlay: FC<GalleryOverlayProps> = ({
  gallery,
  removeOverlay,
}) => {
  const numberOfPictures = gallery.length;
  const [currentPicture, setCurrentPicture] = useState(0);

  // on click outside, hide the modal
  const slideshowRef = useOnclickOutside(removeOverlay);

  const _nextPicture = () => {
    // creates a round slider to go through all the images without going out of
    // bounds
    setCurrentPicture((currentPicture + 1) % numberOfPictures);
  };

  const _prevPicture = () => {
    // We perform a complete iteration through numberOfPictures - 1 images,
    // this brings us back to the image before this one.
    setCurrentPicture(
      (currentPicture + numberOfPictures - 1) % numberOfPictures
    );
  };

  return (
    <div
      ref={slideshowRef}
      className={
        "elevate-xs relative flex bg-white view-inner-width h-1/2 " +
        "w-full-mx-2 lg:h-3/4 sm:w-7/12"
      }
      style={
        {
          // height: 700,
        }
      }
    >
      <div className="h-full relative" style={{ flex: 1 }}>
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            border: "1px solid #ccc",
            backgroundColor: "#ccc",
            backgroundImage: `url(${gallery[currentPicture].url})`,
            backgroundSize: "cover",
          }}
        />
        <div style={{ position: "absolute" }} />
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div style={{ display: "flex" }}>
            <div
              onClick={_nextPicture}
              style={{ padding: 10, background: "#eee", cursor: "pointer" }}
            >
              <FaChevronLeft style={{ fontSize: "30px" }} />
            </div>
            <div style={{ flex: 1 }} />
            <div
              onClick={_prevPicture}
              style={{ padding: 10, background: "#eee" }}
            >
              <FaChevronRight style={{ fontSize: "30px" }} />
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          "elevate h-12 w-12 rounded-full bg-white absolute top-0 right-0" +
          " -mt-6 -mr-6 hidden sm:flex items-center justify-center"
        }
        onClick={() => {
          removeOverlay();
        }}
      >
        <FaTimes />
      </div>
    </div>
  );
};

export default GalleryOverlay;
