// @ts-check
import { IoIosBed } from "react-icons/io";

export function Car({sharedState, appendQuery}) {
  return (
    <>
      <div
        className="elevate-xs"
        style={{
          display: "flex",
          padding: 20,
          paddingTop: 10,
          paddingBottom: 10,
          background: "#fff",
        }}
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: 19,
            flex: 1,
            color: "#3f3c55",
            display: "flex",
          }}
        >
          <div style={{ paddingTop: 4, marginRight: 10 }}>
            <IoIosBed />
          </div>{" "}
          Car rentals in{" "}
          <span style={{ textTransform: "capitalize", paddingLeft: 5 }}>
            {" "}
            {sharedState.city}
          </span>
        </div>
        <div style={{ fontSize: 12, color: "#fff", paddingTop: 10 }}>
          <div style={{ fontSize: 12, color: "#333" }}>
            Showing{" "}
            {!sharedState.searchItemsLength
              ? "0"
              : sharedState.pagination * 10 - 9}
            -
            {!sharedState.searchItemsLength
              ? "0"
              : sharedState.searchItemsLength > 10 &&
                sharedState.pagination * 10 <
                  sharedState.searchItemsLength
              ? sharedState.pagination * 10
              : sharedState.searchItemsLength}{" "}
            of{" "}
            {!sharedState.searchItemsLength
              ? "0"
              : sharedState.searchItemsLength}
          </div>
        </div>
      </div>
      <div
        className="elevate-xs"
        style={{
          borderTop: "1px solid #ddd",
          marginTop: 0,
          background: "#fff",
        }}
      >
        <div style={{ height: 30, display: "flex", padding: 10 }}>
          <div
            style={{
              cursor: "pointer",
              flex: 1,
              textAlign: "center",
              borderRight: "3px solid #000",
              paddingTop: 5,
              fontSize: 14,
              color: "#f00",
            }}
            onClick={() => {
              appendQuery("sort=hotel_review_score");
            }}
          >
            Review Score
          </div>
          <div
            style={{
              cursor: "pointer",
              flex: 1,
              textAlign: "center",
              borderRight: "3px solid #000",
              paddingTop: 5,
              fontSize: 12,
            }}
            onClick={() => {
              appendQuery("sort=hotel_number_of_reviews");
            }}
          >
            Most Reviews
          </div>
          <div
            style={{
              cursor: "pointer",
              flex: 1,
              textAlign: "center",
              borderRight: "0px solid #000",
              paddingTop: 5,
              fontSize: 12,
            }}
            onClick={() => {
              appendQuery("sort=price");
            }}
          >
            Price (Lowest)
          </div>
        </div>
      </div>
    </>
  );
}
