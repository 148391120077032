import {Dispatch, FC, SetStateAction} from "react";
import {Select} from 'antd';
import {FaTimes} from "react-icons/fa";

import {ReservationCategory} from "../../../../api";

import {ColumnData} from "../../../search-property/components/left-column";
import {FilterGroup} from "../../../search-property/components/filter-group";

interface FilterPropertiesColumnProps {
  filterTabVisible: boolean;
  setFilterTabVisible: Dispatch<SetStateAction<boolean>>;
  setActiveTab?: Dispatch<SetStateAction<"1" | "2" | "3">>;
  activeCategories: {[K in ReservationCategory]: boolean};
  columnFilters: ColumnData;
  activeFilters: ReservationCategory;
  setActiveFilters: Dispatch<SetStateAction<ReservationCategory>>;
}

export const FilterPropertiesColumn: FC<FilterPropertiesColumnProps> = ({
  activeCategories, activeFilters, filterTabVisible, columnFilters,
  setFilterTabVisible, setActiveTab, setActiveFilters,
}) => {
  return (
    <div
      className={`w-full sm:w-80 flex-shrink-0 flex-grow-0 h-full sm:absolute ${
        filterTabVisible ? "sm:right-0" : "sm:-right-96"
      } sm:top-0 xl:right-0 xl:static z-10 sm:shadow-md xl:shadow-none`}
      style={{
        transitionProperty: "right",
        transitionDuration: "150ms",
      }}
    >
      {/* Filter categories */}
      <div className="sticky xl:hidden text-xl h-14 px-4 flex flex-row justify-between items-center">
        Filters
        <FaTimes
          className="text-2xl"
          onClick={() => {
            setFilterTabVisible(false);
            setActiveTab && setActiveTab("1");
          }}
        />
      </div>

      <div className="px-4">
        <p className="text-sm mb-1 opacity-70 font-medium">Select category to filter from</p>
        <Select
          className="w-full"
          defaultValue={activeFilters}
          onChange={(val) => {
            console.log(val);
            if (val) {
              setActiveFilters(val as ReservationCategory);
            }
          }}
        >
          {Object.keys(activeCategories)
            .filter(
              (category) =>
                category !== "flight" &&
                category !== "cruise" &&
                activeCategories[category as ReservationCategory]
            )
            .map((category) => (
              <Select.Option key={category} value={category}>
                {category}
              </Select.Option>
            ))}
        </Select>

        <h6 className="my-3 text-xl font-semibold font-lato">
          {`Filters for: ${activeFilters}`}
        </h6>

        {columnFilters.map((columnFilter) => (
          <FilterGroup
            key={columnFilter.title}
            groupData={columnFilter}
          />
        ))}
      </div>
    </div>
  );
};
