import {database, firestore} from "firebase";
import {OnCompleteCallback, Property} from "..";
import {AttractionSite} from "../attraction/types";
import {Car} from "../car";
import {Recommended} from "../recommended";
import {ContentCategories, SafariUser} from "./types";

export type {ContentCategories, SafariUser} from './types';

export const fetchAllContent = async (
  onComplete: OnCompleteCallback<ContentCategories>,
): Promise<void> => {
  const content: ContentCategories = {
    activity: [],
    attraction: [],
    car: [],
    conference: [],
    eatery: [],
    hotel: [],
    rental: [],
    tour: [],
  };
  let fetchedContent = 0;

  const addContent = (category: keyof ContentCategories, newContent: unknown) => {
    // NOTE we use push because otherwise TS will not allow us to make
    // conditional statements unless the final statement is a function
    // call.
    category === "car" && content[category].push(...(newContent as Car[]));
    category === "attraction" && content[category].push(
      ...(newContent as AttractionSite[]),
    );
    // all other categories areof type property
    (category !== "car" && category !== "attraction") &&
      content[category].push(...(newContent as Property[]));
    
    fetchedContent++;

    if (fetchedContent === Object.keys(content).length) {
      onComplete(null, content);
    }
  };

  Object.keys(content).forEach(async (category) => {
    try {
      const categorySnapshot = await firestore().collection(category).get();
      const content: unknown[] = [];
  
      // preferred to use for each just in case the collection does not exist,
      // we have the empty array to return back as content.
      categorySnapshot.docs.forEach((doc) =>
        content.push({...doc.data(), id: doc.id}));
      addContent(category as keyof ContentCategories, content);
    } catch (err) {
      // to prevent the loop not returning incase one fails, we give back an
      // empty array as the content if the firebase collection fails
      addContent(category as keyof ContentCategories, []);
    }
  });
};

export const fetchRecommendedContent = async (
  onComplete: OnCompleteCallback<Recommended>,
): Promise<void> => {
  const recommended: Recommended = {
    activity: [],
    attraction: [],
    conference: [],
    eatery: [],
    hotel: [],
    rental: [],
    tour: [],
  };

  let fetched = 0;

  const addRecommended = (
    category: keyof Recommended, recommendedContent: string[],
  ) => {
    recommended[category] = recommendedContent;
    fetched++;
    
    if (fetched === Object.keys(recommended).length) {
      onComplete(null, recommended);
    }
  };

  Object.keys(recommended).map(async (category) => {
    try {
      const snapshot = await database().ref(`recommendation/${category}`)
        .once("value");
  
      if (snapshot.exists()) {
        addRecommended(
          category as keyof Recommended, snapshot.val() as string[],
        );
      } else {
        addRecommended(
          category as keyof Recommended, [],
        );
      }
    } catch (err) {
      addRecommended(
        category as keyof Recommended, [],
      );
    }
  });
};

export const saveRecommendations = async (
  category: keyof ContentCategories, recommendations: string[],
): Promise<boolean> => {
  try {
    await database().ref(`recommendation/${category}`).set(recommendations);

    return true;
  } catch (err) {
    console.error(err);

    return false;
  }
};

export const fetchAllUsers = async (
  onComplete: OnCompleteCallback<(SafariUser & {id: string})[]>,
): Promise<void> => {
  try {
    const usersSnapshot = await database().ref("Users_Master").once("value");
    const users = usersSnapshot.val() as Record<string, SafariUser>;

    const allUsers = Object.keys(users).map((user) => ({
      ...users[user],
      id: user,
    }));

    onComplete(null, allUsers);
  } catch(err) {
    console.error(err);

    onComplete(new Error("Unable to fetch all users"), null);
  }
};

export const updateUserDetails = async (
  userId: string, updatableDetails: Partial<SafariUser>,
  onComplete: OnCompleteCallback<boolean>,
): Promise<void> => {
  try {
    await database().ref(`Users_Master/${userId}`).update(updatableDetails);

    onComplete(null, true);
  } catch (err) {
    console.error(err);

    onComplete(new Error("Unable to update user details"), null);
  }
};

export const updatePropertyState = async (
  category: keyof ContentCategories, propertyId: string, active: boolean,
  onComplete: OnCompleteCallback<boolean>,
): Promise<void> => {
  try {
    await firestore().collection(category).doc(propertyId).update({active});

    onComplete(null, true);
  } catch (err) {
    console.log(err);
    onComplete(new Error("Unable to update property state"), null);
    return;
  }
};
