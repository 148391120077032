import type {FC} from 'react';

export interface SubmitButtonProps {
  disabled: boolean;
}

/**
 * This submt button is exported as it will be in use over several components.
 */
export const SubmitButton: FC<SubmitButtonProps> = ({disabled}) => {
  return (
    <button
      disabled={disabled}
      type="submit"
      className="small-search-but"
      style={{
        backgroundColor: (disabled) ? "#ccc": "",
      }}
    >
      <div
        style={{
          textAlign: "center",
          padding: "8px 0 8px 0",
          color: "white",
          cursor: "pointer",
        }}
      >Search</div>
    </button>
  );
}
