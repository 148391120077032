import {flights} from "../../../../dummy-data";
import {
  fetchCompulsoryFlightFilters, fetchOptionalFlightFilters,
} from "../../../../reducers";

export default function fetchFlights(state, updateState) {
  const {from, to} = fetchCompulsoryFlightFilters();
  const optionalFilters = fetchOptionalFlightFilters();

  const rawResults = flights.map((flight) => ({
    ...flight,
    calcPrice: flight.price,
  }));

  updateState({
    rawResults,
    searchResults: rawResults,
    searchItemsLength: rawResults.length,
    searchDone: true,
    category: "flight",
    depatureCity: from,
    destinationCity: to,
    rangeMax: optionalFilters.price_highest ?
      optionalFilters.price_highest : 120,
    rangeMin: optionalFilters.price_lowest ?
      optionalFilters.price_lowest : 0,
  });
}
