// @ts-check
import {useState} from "react";
import {Calendar, DateRange} from "react-date-range";
import useOnClickOutside from "react-cool-onclickoutside";

/**
 * RangePicker is used to select a single date.
 *
 * @param {{
 * minDate: any,
 * showDatePicker: (rangePickerVisble: boolean) => void,
 * selectDate: (onDateChange: import("react-date-range").OnChangeProps) => void,
 * }} props DatePicker Props
 *
 * @returns {JSX.Element}
 */
export function RangePick({minDate, selectDate, showDatePicker}) {
  const datePickerVisibleRef = useOnClickOutside(() => showDatePicker(false));
  // intiial end date should be tomorrow
  const endDate = new Date();
  endDate.setDate(endDate.getDate() + 1);

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate,
      key: "selection",
    },
  ]);

  return (
    <div
      ref={datePickerVisibleRef}
      className="inline-parent picker-parent"
      // style={{position: "absolute", zIndex: 40}}
    >
      <DateRange
        minDate={minDate}
        editableDateInputs={true}
        onChange={(item) => {
          selectDate([item.selection]);
          setState([item.selection]);
        }}
        moveRangeOnFirstSelection={false}
        ranges={state}
      />
    </div>
  );
}

/**
 * DatePicker is used to select a single date.
 *
 * @param {{
 * minDate: any,
 * showDatePicker: (datePickerVisble: boolean) => void,
 * selectDate: (onDateChange: import("react-date-range").OnChangeProps) => void,
 * }} props DatePicker Props
 *
 * @returns {JSX.Element}
 */
export function DatePick({minDate, selectDate, showDatePicker}) {
  const datePickerVisibleRef = useOnClickOutside(() => showDatePicker(false));

  /**
   *
   * @param {import("react-date-range").OnChangeProps} date selected date
   */
  const handleSelect = (date) => {
    selectDate(date);
  };

  return (
    <div
      ref={datePickerVisibleRef}
      className="inline-parent picker-parent"
    >
      <Calendar
        minDate={minDate}
        date={new Date()}
        onChange={handleSelect}
      />
    </div>
  );
}
