import {firestore} from "firebase";
import {Draft} from "..";
import {OnCompleteCallback, Picture} from "../..";
import {uploadLogo, uploadPictures} from "../../cdn";
import {Hotel} from "../../hotel";
import {getUser, NoUserFoundError} from "../../user";

export const postHotelDraft = (
  draftId: string | null, hotel: Hotel,
  onComplete: OnCompleteCallback<Draft<Hotel>>,
): void => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);

    return;
  }

  if (draftId) {
    updateHotelDraft(draftId, hotel, onComplete);

    return;
  }

  // we fetch all the pictures from the hotel facility itself, and also all
  // the pictues from the rooms themselves.
  // We then create an array (Buffer) that records how many pictures we are
  // uploading for each, this will be used to get back all the pictures in order
  // of so as to re-order them back to their respective places without a hassle.
  //
  // E.g if we have a hotel facility with 6 pictures of its own and three
  // hotel rooms with pictures of their own, then we can create the buffer
  // as follows:
  // buffer = [6, 6, 7, 8];
  // then an array of pictures to upload is created with a total of
  // (6 + 6 + 7 + 8) images, where:
  // they are ordered based on:
  // hotel facility first, rooms based on the index of the room,
  // As such, these pictures can be gotten back by splicing and then pushing
  // back in order of index starting with the hotel facility as the first
  // index (0)

  const bufferSizes: number[] = [];
  const imageBuffer: Picture[] = [];

  bufferSizes.push(hotel.gallery.length);
  imageBuffer.push(...hotel.gallery);

  // push all the pictures for each room
  hotel.rooms.forEach((room) => {
    bufferSizes.push(room.gallery.length);
    imageBuffer.push(...room.gallery);
  });


  // after all the images are pushed we can upload them all and then replace
  // after successful upload
  uploadPictures("hotel", imageBuffer, async (err, uploadedImages) => {
    if (err) {
      onComplete(err, null);
      return;
    }

    if (uploadedImages) {
      hotel.gallery = uploadedImages.splice(0, bufferSizes[0]);

      // starting from the second index, we start assigning the rooms all their
      // images back
      for (let i=1; i<bufferSizes.length; i++) {
        hotel.rooms[i - 1].gallery = imageBuffer.splice(0, bufferSizes[i]);
      }

      // after all the images are uploaded we can now get to uploading the
      // confernce data
      const uploadHotelDetails = async () => {
        try {
          const doc =  await firestore()
            .collection("draft")
            .add({category: "hotel", draft: hotel, uid: user.uid} as Draft<Hotel>);
          
          const draftSnapshot = await doc.get();
  
          onComplete(
            null, ({...draftSnapshot.data(), id: draftSnapshot.id} as Draft<Hotel>),
          );
        } catch (err) {
          const hotelUploadError =
            new Error("Error uploading hotel facility details");
          onComplete(hotelUploadError, null);
        }
      };
      
      if (Object.prototype.hasOwnProperty.call(hotel, "logo")) {
        if (!hotel.logo) {
          // delete the property to avoid errors uploading from firebase for an
          // unsupported data type
          delete hotel.logo;
          uploadHotelDetails();

          return;
        }

        // upload the logo
        uploadLogo(hotel.logo, (err, uploadedLogo) => {
          if (err) {
            onComplete(err, null);

            return;
          }

          if (!uploadedLogo) {
            onComplete(new Error("Error uploading hotel logo"), null);

            return;
          }

          hotel.logo = uploadedLogo;
          uploadHotelDetails();
        });
      } else uploadHotelDetails();
    } else {
      // these images may be null
      const uploadErr = new Error("The images to be uploaded returned null");
      onComplete(uploadErr, null);
    }
  });
}

export const updateHotelDraft = (
  id: string, hotel: Hotel, onComplete: OnCompleteCallback<Draft<Hotel>>,
): void => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);

    return;
  }

  // we fetch all the pictures from the hotel facility itself, and also all
  // the pictues from the rooms themselves.
  // We then create an array (Buffer) that records how many pictures we are
  // uploading for each, this will be used to get back all the pictures in order
  // of so as to re-order them back to their respective places without a hassle.
  //
  // E.g if we have a hotel facility with 6 pictures of its own and three
  // hotel rooms with pictures of their own, then we can create the buffer
  // as follows:
  // buffer = [6, 6, 7, 8];
  // then an array of pictures to upload is created with a total of
  // (6 + 6 + 7 + 8) images, where:
  // they are ordered based on:
  // hotel facility first, rooms based on the index of the room,
  // As such, these pictures can be gotten back by splicing and then pushing
  // back in order of index starting with the hotel facility as the first
  // index (0)

  const bufferSizes: number[] = [];
  const imageBuffer: Picture[] = [];

  bufferSizes.push(hotel.gallery.length);
  imageBuffer.push(...hotel.gallery);

  // push all the pictures for each room
  hotel.rooms.forEach((room) => {
    bufferSizes.push(room.gallery.length);
    imageBuffer.push(...room.gallery);
  });


  // after all the images are pushed we can upload them all and then replace
  // after successful upload
  uploadPictures("hotel", imageBuffer, async (err, uploadedImages) => {
    if (err) {
      onComplete(err, null);
      return;
    }

    if (uploadedImages) {
      hotel.gallery = uploadedImages.splice(0, bufferSizes[0]);

      // starting from the second index, we start assigning the rooms all their
      // images back
      for (let i=1; i<bufferSizes.length; i++) {
        hotel.rooms[i - 1].gallery = imageBuffer.splice(0, bufferSizes[i]);
      }

      // after all the images are uploaded we can now get to uploading the
      // confernce data
      const uploadHotelDetails = async () => {
        try {
          await firestore()
            .collection("draft")
            .doc(id)
            .update({draft: hotel} as Draft<Hotel>);
          
          const draftSnapshot = await firestore().collection("draft")
            .doc(id)
            .get();
  
          onComplete(
            null, ({...draftSnapshot.data(), id: draftSnapshot.id} as Draft<Hotel>),
          );
        } catch (err) {
          console.log(err);
          const hotelUploadError =
            new Error("Error uploading hotel facility details");
          onComplete(hotelUploadError, null);
        }
      };

      if (Object.prototype.hasOwnProperty.call(hotel, "logo")) {
        if (!hotel.logo) {
          // delete the property to avoid errors uploading from firebase for an
          // unsupported data type
          delete hotel.logo;
          uploadHotelDetails();

          return;
        }

        // upload the logo
        uploadLogo(hotel.logo, (err, uploadedLogo) => {
          if (err) {
            onComplete(err, null);

            return;
          }

          if (!uploadedLogo) {
            onComplete(new Error("Error uploading hotel logo"), null);

            return;
          }

          hotel.logo = uploadedLogo;
          uploadHotelDetails();
        });
      } else uploadHotelDetails();
    } else {
      // these images may be null
      const uploadErr = new Error("The images to be uploaded returned null");
      onComplete(uploadErr, null);
    }
  });
}
