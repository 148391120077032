import {Formik} from "formik";
import {FC, useEffect, useState} from "react";
import {FaTimes} from "react-icons/fa";

import {
  getRefundPolicy,
  getReservationPolicies,
  RefundPolicies,
  RefundType,
  ReservationPolicies,
  ReservationType,
} from "../../../api";

import {PolicyCard} from "../../property/components/policy-card";

type EditPolicyProps = {
  reservationPolicy?: ReservationType;
  refundPolicy?: RefundType;
  onSave: (policies: {
    refundPolicy?: RefundType;
    reservationPolicy?: ReservationType;
  }) => void;
  removeOverlay: () => void;
};

export const EditPolicy: FC<EditPolicyProps> = ({
  reservationPolicy,
  refundPolicy,
  removeOverlay,
  onSave,
}) => {
  const [reservationPolicies, setReservationPolicies] =
    useState<ReservationPolicies | null>(null);
  const [refundPolicies, setRefundPolicies] = useState<RefundPolicies | null>(
    null
  );

  useEffect(() => {
    getRefundPolicy((err, result) => {
      if (err) {
        console.error(err);
      } else {
        if (result) {
          setRefundPolicies(result);
        }
      }
    });

    getReservationPolicies((err, result) => {
      if (err) {
        console.error(err);
      } else {
        if (result) {
          setReservationPolicies(result);
        }
      }
    });
  }, []);

  return (
    <div
      className={
        "absolute top-0 right-0 bottom-0 overflow-y-scroll w-full bg-white " +
        "sm:max-w-md"
      }
    >
      {/*
        Wrap the whole for in a Formik container to make the handling of input
        easier
      */}
      <Formik
        initialValues={{
          reservation_policy: reservationPolicy,
          refund_policy: refundPolicy,
        }}
        onSubmit={({ refund_policy, reservation_policy }) => {
          onSave({
            refundPolicy: refund_policy,
            reservationPolicy: reservation_policy,
          });

          removeOverlay();
        }}
      >
        {({ handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div
              className={
                "h-14 px-4 shadow-md flex flex-row justify-between " +
                "items-center bg-safari-gold text-white " +
                "sticky top-0"
              }
            >
              {/** Title and back action */}
              <div className="flex flex-row items-center">
                <FaTimes size={24} onClick={removeOverlay} />
                <div className="ml-3">
                  <h6 className="text-lg">Edit Policy</h6>
                  <p className="text-xs">Pick policy</p>
                </div>
              </div>
              {/** Title and back action end */}

              {/** save action button */}
              <button
                type="submit"
                className={
                  "py-2 px-3 border border-black rounded-md text-black " +
                  "font-semibold text-sm"
                }
              >
                <p className="text-sm font-semibold">Save</p>
              </button>
              {/** End save action button */}
            </div>
            {/** End of policy app bar */}

            {/** Policy container */}
            <div className="">
              {/** Reservation policy container */}
              {reservationPolicy && (
                <div className="p-4">
                  <div
                    className={
                      "sticky top-0 bg-white z-30 h-14 flex flex-col " +
                      "justify-center"
                    }
                  >
                    <h4 className="text-2xl font-semibold">Reservation</h4>
                    <p className="text-xs mb-3">
                      Select one policy to govern reservations made
                    </p>
                  </div>
                  {reservationPolicies && (
                    <div className="flex flex-col items-center">
                      {Object.keys(reservationPolicies).map(
                        (reservationPolicy) => (
                          <label
                            key={reservationPolicy}
                            htmlFor={reservationPolicy}
                          >
                            <div className="flex flex-row">
                              <div className="p-2">
                                <input
                                  id={reservationPolicy}
                                  className="flex-grow-0"
                                  type="radio"
                                  name="reservation_policy"
                                  value={reservationPolicy}
                                  onChange={handleChange}
                                />
                              </div>
                              <PolicyCard
                                // typecast the reservation plicy key to make sure
                                // TS throws an error of it's not the case
                                policy={
                                  reservationPolicies[
                                    reservationPolicy as
                                      | "full_amount"
                                      | "no_deposit"
                                      | "partial_deposit"
                                  ]
                                }
                              />
                            </div>
                          </label>
                        )
                      )}
                    </div>
                  )}
                </div>
              )}
              {/* End of Reservation Policy container */}

              {/** Refund Policy container */}
              {refundPolicy && (
                <div className="p-4">
                  <div className="sticky top-0 bg-white h-14">
                    <h4 className="text-2xl font-semibold">Refund</h4>
                    <p className="text-xs mb-3">
                      Select one policy to govern how refunds are given
                    </p>
                  </div>

                  {refundPolicies && (
                    <div className="flex flex-col items-center">
                      {Object.keys(refundPolicies).map((refundPolicy) => (
                        <label key={refundPolicy} htmlFor={refundPolicy}>
                          <div className="flex flex-row">
                            <div className="p-2">
                              <input
                                id={refundPolicy}
                                className="flex-grow-0"
                                type="radio"
                                name="refund_policy"
                                onChange={handleChange}
                                value={refundPolicy}
                              />
                            </div>
                            <PolicyCard
                              // typecast the reservation plicy key to make sure
                              // TS throws an error of it's not the case
                              policy={
                                refundPolicies[
                                  refundPolicy as
                                    | "no_refund"
                                    | "partial_refund"
                                    | "full_refund"
                                ]
                              }
                            />
                          </div>
                        </label>
                      ))}
                    </div>
                  )}
                </div>
              )}
              {/** End of refund policy container */}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
