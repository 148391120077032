import { Select } from 'antd';
import {FC, useState} from 'react';
import {IoMdAdd, IoMdClose, IoMdSave} from 'react-icons/io';

import {Picture} from '../../../api';
import {Table} from '../../../api/eatery/types';
import { Button } from '../../../components';
import {Input} from '../../../components/input';
import { generateId } from '../../../util';

type AddTableProps = {
  removeOverlay: () => void;
  addTable: (table: Table) => void;
  table?: Table;
};

type TableType = Table["type"];

export const AddTable: FC<AddTableProps> = ({
  table, addTable, removeOverlay,
}) => {
  const [title, setTitle] = useState<string>(table ? table.title : "");
  const [cardinality, setCardinality] = useState<number>(
    table ? table.cardinality : 0,
  );
  const [tableType, setTableType] = useState<TableType>(
    table ? table.type: "standard"
  );
  const [reservationPrice, setReservationPrice] = useState<number>(
    table ? table.price : 0,
  );
  const [picture, setPicture] = useState<Picture | undefined>(
    table ? table.picture : undefined,
  );
  const [capacity, setCapacity] = useState<number>(table ? table.capacity: 0);

  const addNewTable = () => {
    picture && addTable({
      capacity,
      cardinality,
      picture,
      price: reservationPrice,
      title,
      type: tableType,
      // ecccount for already created tables that are just being updated
      id: table ? table.id: generateId(10),
    });

    removeOverlay();
  };

  const disabled = (
    title === "" || cardinality === 0 || reservationPrice === 0 ||
      picture === undefined || capacity === 0
  );

  return (
    <div
      className="absolute top-0 bottom-0 right-0 bg-white max-w-sm w-full"
    >
      <div
        className={
          "h-14 w-full flex flex-row items-center justify-between px-4 " +
          "bg-safari-gold text-white text-lg"
        }
      >
        <div
          className={
            "font-semibold font-lato text-lg text-white " +
            "flex flex-row items-center"
          }
        >
          <IoMdClose
            className="mr-3 cursor-pointer"
            size={24}
            onClick={removeOverlay}
          />
          <div>
            <h6>
              {table ?
                "Update table details" :
                "Add new table"
              }
            </h6>
            <p className="text-xs">
              {table ?
                "Update table details" :
                "Add a new table type"
              }
            </p>
          </div>
        </div>
        <Button
          disabled={disabled}
          type="button"
          mode="outlined"
          onClick={addNewTable}
        >
          <div className="flex flex-row text-white items-center">
            <IoMdSave size={24} className="mr-3" />
            {table ? "Save changes" : "Add table"}
          </div>
        </Button>
      </div>
      <div className="p-4">
        <label htmlFor="table-picture">
          <div
            className={
              "w-full border h-60 bg-cover bg-center rounded-md " +
              "flex items-center justify-center"
            }
            style={{
              backgroundImage: picture ? `url("${picture.url}")` : "",
            }}
          >
            <div className="p-4 bg-white bg-opacity-60">
              <IoMdAdd
                size={24}
                className="text-safari-gold"
              />
            </div>
          </div>
          <input
            id="table-picture"
            className="hidden"
            type="file"
            accept="image/jpeg, image/png"
            onChange={(e) => {
              if (e.target.files) {
                e.target.files[0] && setPicture({
                  caption: `${title} image`,
                  url: URL.createObjectURL(e.target.files[0]),
                  file: e.target.files[0],
                  filename: e.target.files[0].name,
                });
              }
            }}
          />
          <p className="text xs">
            Insert an image of the table
          </p>
        </label>
        <Input
          className="my-2"
          helper="The name of this type of table"
          id="table-title"
          inputLabel="Title"
          name="table_title"
          value={title}
          error={title === "" ? "Title should not be empty" : ""}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
        <Select
          className="mb-2 w-full"
          value={tableType}
          onChange={(val) => setTableType(val)}
        >
          <Select.Option value="standard">
            Standard
          </Select.Option>
          <Select.Option value="high-top">
            High top
          </Select.Option>
          <Select.Option value="outdoor">
            Outdoor
          </Select.Option>
        </Select>
        <Input
          className="mb-2"
          helper="The number of tables of this type"
          id="number-of-tables"
          inputLabel="Number of tables"
          name="no_of_tables"
          value={cardinality ? `${cardinality}`: ""}
          error={cardinality === 0 ? "The number of tables cannot be NIL" : ""}
          onChange={(e) => {
            // Make sure that it is a number first
            if (!isNaN(Number(e.target.value))) {
              setCardinality(Number(e.target.value));
            }
          }}
        />
        <Input
          className="mb-2"
          helper="Maximum number this table can hold"
          id="table-capacity"
          inputLabel="Capacity of the table"
          name="table_capacity"
          value={capacity ? `${capacity}` : ""}
          error={capacity === 0 ? "The capacity of a table cannot be NIL": ""}
          onChange={(e) => {
            // Make sure that it is a number first
            if (!isNaN(Number(e.target.value))) {
              setCapacity(Number(e.target.value));
            }
          }}
        />

        <Input
          className="mb-2"
          helper="Price to reserve this table in USD"
          id="reservation-price"
          inputLabel="Reservation price (in USD)"
          name="reservation_price"
          value={reservationPrice ? `${reservationPrice}` : ""}
          error={reservationPrice === 0 ? "Reservation price cannot be NIL": ""}
          onChange={(e) => {
            // Make sure that it is a number first
            if (!isNaN(Number(e.target.value))) {
              setReservationPrice(Number(e.target.value));
            }
          }}
        />
      </div>
    </div>
  );
};
