// @ts-check
/* eslint-disable no-undef */
import { database } from "firebase";
import {Component, useState, createRef } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import {DateRange} from "react-date-range";

import Top_Pic from "../../../../assets/planner_1.png";
import {HelperText} from "../../../../components";
import {DateUtil} from "../../../../util";

import LocationSearchInput from "../../../index/components/LocationSearchInput";
import {setTripData} from "../../system/DataFetcher";

function DatePick({startDate, returnDate, showDatePicker, selectDate}) {
  const datePickerRef = useOnclickOutside(() => showDatePicker(false));
  // change the dates into Date Objects that are acceptable to the DateRange
  const today =
    new Date(DateUtil.changeDateFormat(startDate, "DD-MM-YYYY", "YYYY-MM-DD"));
  const tomorrow =
    new Date(DateUtil.changeDateFormat(returnDate, "DD-MM-YYYY", "YYYY-MM-DD"));

  const [state, setState] = useState([
    {
      startDate: today,
      endDate: tomorrow,
      key: "selection",
    },
  ]);

  return (
    <div
      ref={datePickerRef}
      className="elevate-xs"
      style={{ position: "absolute", zIndex: 10 }}
    >
      <DateRange
        editableDateInputs={true}
        onChange={(item) => {
          
          selectDate([item.selection]);
          
          setState([item.selection]);
        }}
        moveRangeOnFirstSelection={false}
        ranges={state}
      />
    </div>
  );
}
class App extends Component {

  /**@type {import('react').RefObject<HTMLDivElement>} */
  startDateRef = createRef();

  constructor(props) {
    super(props);

    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    this.state = {
      startDate: DateUtil.stringifyDate(today),
      returnDate: DateUtil.stringifyDate(tomorrow),
      trip_name: "",
      trip_origin: "",
      trip_destination: "",
      
      time: database.ServerValue.TIMESTAMP,
    };
  }

  _selectDate = (date) => {
    // const formattedStartDate = moment(date[0].startDate).format("DD-MM-YYYY");
    const formattedStartDate = DateUtil.stringifyDate(date[0].startDate);
    // const formattedReturnDate = moment(date[0].endDate).format("DD-MM-YYYY");
    const formattedReturnDate = DateUtil.stringifyDate(date[0].endDate);
    this.setState({
      datePickerVisible: false,
      startDate: formattedStartDate,
      returnDate: formattedReturnDate,
    });
  };

  // eslint-disable-next-line no-unused-vars
  _selectFromAddress = (place, latLng) => {
    const city = place.replace(/ /g, "_").replaceAll(",", "-");
    this.setState({ trip_origin: city });
  };

  // eslint-disable-next-line no-unused-vars
  _selectToAddress = (place, latLng) => {
    //console.log(place);
    const city = place.replace(/ /g, "_").replaceAll(",", "-");
    this.setState({ trip_destination: city });
  };

  _setTripData = () => {
    // first of all verify that all the input fields are filled before
    // attempting to create the trip.
    if (
      this.state.trip_destination !== "" && this.state.trip_origin !== "" &&
      this.state.trip_name && this.state.startDate !== "" &&
      this.state.returnDate !== ""
    ) {
      setTripData(this.state, this.props.tripKey);
    }
  };

  render() {
    const disabled = (
      this.state.trip_origin === "" || this.state.trip_name === "" ||
      this.state.trip_destination === ""
    );

    return (
      <>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 90 }}
        >
          <div
            className="planner-setup-parent"
            style={{ alignItems: "center" }}
          >
            <img src={Top_Pic} width={100} />
          </div>
        </div>

        <h1 style={{ marginBottom: 15, textAlign: "center" }}>TRIP SETUP</h1>
        <p style={{ textAlign: "center" }}>Enter your trip details</p>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
        >
          <div className="planner-setup-parent">
            <div style={{ flex: 1 }}>
              <input
                placeholder="Trip name"
                onChange={(event) => {
                  this.setState({ trip_name: event.target.value });
                }}
                style={{
                  border: "0px solid #ccc",
                  backgroundColor: "#eee",
                  padding: 15,
                  paddingLeft: 25,
                  borderRadius: 100,
                  fontSize: 18,
                  color: "#111",
                  textAlign: "left",
                  width: "100%",
                }}
              />
              {this.state.trip_name === "" && (
                <HelperText
                  style={{
                    fontSize: 8,
                    marginLeft: 32,
                    color: "red",
                  }}
                >
                  This field cannot be empty
                </HelperText>
              )}
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
        >
          <div className="planner-setup-parent">
            <div className="location-parent" style={{ flex: 1 }}>
              <LocationSearchInput
                defaultValue={this.state.trip_origin}
                placeholder="Enter trip origin"
                selectAddress={this._selectFromAddress}
                onChange={(e) => this.setState({trip_origin: e.target.value})}
                style={{
                  border: "0px solid #ccc",
                  backgroundColor: "#ffeaea",
                  padding: 15,
                  paddingLeft: 25,
                  borderRadius: 100,
                  fontSize: 18,
                  color: "#111",
                  textAlign: "left",
                }}
                suggestionsContainerStyle={{
                  marginTop: 0,
                }}
              />
              {this.state.trip_origin === "" && (
                <HelperText
                  style={{
                    marginLeft: 16,
                    fontSize: 8,
                    color: "red",
                  }}
                >
                  This field cannot be empty
                </HelperText>
              )}
            </div>
            <div style={{ minWidth: 10, minHeight: 10 }} />
            <div className="location-parent" style={{ flex: 1 }}>
              <LocationSearchInput
                defaultValue={this.state.trip_destination}
                placeholder="Enter trip destination"
                selectAddress={this._selectToAddress}
                onChange={(e) => {
                  this.setState({trip_destination: e.target.value});
                }}
                style={{
                  border: "0px solid #ccc",
                  backgroundColor: "#ffeaea",
                  padding: 15,
                  paddingLeft: 25,
                  borderRadius: 100,
                  fontSize: 18,
                  color: "#111",
                  textAlign: "left",
                }}
                suggestionsContainerStyle={{
                  marginTop: 0,
                }}
              />
              {this.state.trip_destination === "" && (
                <HelperText
                  style={{
                    marginLeft: 16,
                    fontSize: 8,
                    color: "red",
                  }}
                >
                  This field cannot be empty
                </HelperText>
              )}
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
        >
          <div className="planner-setup-parent">
            <div style={{ flex: 1 }}>
              <div style={{ textAlign: "left", padding: 10 }}>Start Date</div>
              <div
                ref={this.startDateRef}
                onClick={() => {
                  this.setState({ datePickerVisible: true });
                  window.scrollTo({
                    left: 0,
                    top: this.startDateRef.current.offsetTop - 50,
                    behavior: "smooth",
                  });
                }}
                style={{
                  cursor: "pointer",
                  border: "0px solid #ccc",
                  backgroundColor: "#f9f9f9",
                  padding: 15,
                  paddingLeft: 15,
                  borderRadius: 100,
                  fontSize: 18,
                  color: "#aaa",
                }}
              >
                {this.state.startDate}
              </div>
              {this.state.datePickerVisible && (
                <DatePick
                  startDate={this.state.startDate}
                  returnDate={this.state.returnDate}
                  selectDate={this._selectDate}
                  showDatePicker={(bool) => {
                    this.setState({datePickerVisible: bool});
                  }}
                />
              )}
            </div>
            <div style={{ minWidth: 10, minHeight: 10 }} />
            <div style={{ flex: 1 }}>
              <div style={{ textAlign: "left", padding: 10 }}>Return Date</div>
              <div
                onClick={() => {
                  this.setState({ datePickerVisible: true });
                  window.scrollTo({})
                }}
                style={{
                  cursor: "pointer",
                  border: "0px solid #ccc",
                  backgroundColor: "#f9f9f9",
                  padding: 15,
                  paddingLeft: 15,
                  borderRadius: 100,
                  fontSize: 18,
                  color: "#aaa",
                }}
              >
                {this.state.returnDate}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 20,
            marginBottom: 50,
          }}
        >
          <div className="planner-setup-parent">
            <div
              style={{
                alignItems: "center",
                bottom: 20,
                display: "flex",
                width: "100%",
              }}
            >
              <div
                onClick={this._setTripData}
                className="checkout-but"
                style={{
                  marginLeft: 0,
                  marginRight: 0,
                  height: 53,
                  background: (disabled) ? "#ccc" : "",
                  cursor: (disabled) ? "not-allowed" : "pointer",
                }}
              >
                PROCEED{" "}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default App;
