import {FC, useState} from 'react';
import GoogleMapReact from 'google-map-react';

import { Coordinates } from '../../../api';
import { Button } from '../../../components';

type ConfirmPropertyLocationProps = {
  removeOverlay: () => void;
  initialCoords: Coordinates;
  onConfirm: (coords: Coordinates) => void;
};

export const ConfirmPropertyLocation: FC<ConfirmPropertyLocationProps> = ({
  initialCoords, removeOverlay, onConfirm,
}) => {
  const [selectedCoords, setSelectedCoords] = useState<Coordinates>(initialCoords);

  return (
    <div
      className="w-72 sm:w-80 sm:h-auto sm:max-h-dialog shadow-lg bg-white overflow-hidden rounded-md"
    >
      {/* Dialog header */}
      <div className="sticky top-0 h-14 w-full shadow-md bg-safari-gold text-white flex flex-row justify-between items-center px-4">
        <div className="flex flex-row items-center">
          {/* <FaTimes size={24} onClick={removeOverlay} /> */}
          <div className="">
            <h6 className="text-base text-white font-semibold">
              Confirm property location
            </h6>
            <p className="text-xs text-white opacity-70">
              Drag the marker to the exact position
            </p>
          </div>
        </div>
      </div>
      {/* End of dialog header */}
      <div
        className="bg-center bg-cover-bg-norepeat h-48 w-full m-2 rounded-md"
      >
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyDxrJtbT4XhPoiNlXoOsNZ77j38ai9ZwAo",
          }}
          defaultCenter={initialCoords}
          defaultZoom={16}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({
            map, maps, ref,
          }) => {
            // eslint-disable-next-line
            const marker = new google.maps.Marker({
              position: initialCoords,
              map,
              draggable: true,
            });

            marker.addListener("dragend", () => {
              const position = marker.getPosition();
              if (position) {
                const newCoords = {
                  lat: position.lat(),
                  lng: position.lng(),
                };

                setSelectedCoords(newCoords);
              }
            });
          }}
        />
      </div>
      <div className="flex flex-row pt-2 pb-2 px-4 justify-end border-t">
        <Button
          type="button"
          mode="outlined"
          className="mr-3"
          onClick={() => {
            onConfirm(initialCoords);
            removeOverlay();
          }}
        >
          Cancel
        </Button>
        <Button
          type="button"
          mode="fill"
          onClick={() => {
            onConfirm(selectedCoords);
            removeOverlay();
          }}
        >
          Set position
        </Button>
      </div>
    </div>
  );
};
