import {Dispatch, FC, SetStateAction, useContext, useState} from 'react';
import {AiOutlineInfoCircle} from 'react-icons/ai';
import {BiChevronRight} from 'react-icons/bi';
import {Link} from 'react-router-dom';

import {Header} from '../../../../components';

import stats from '../../../../assets/stats.jpg';
import {setUserAuthLevel} from '../../../../api';
import {SnackbarProvider} from '../../../../context/snackbar-provider';
import {Auth} from '../../../../util';
import {AppStateContext} from '../../../../context/app-state-provider';

type AffiliateSignupProps = {
  setAuthenticated: Dispatch<SetStateAction<boolean>>;
};

export const AffiliateSignup: FC<AffiliateSignupProps> = ({
  setAuthenticated,
}) => {
  const {actions, state: {USER}} = useContext(AppStateContext);
  const {dismissSnackbar, setIsVisible} = useContext(SnackbarProvider);

  const [authenticating, setAuthenticating] = useState<boolean>(false);

  const updateUserPrivilleges = async () => {
    setAuthenticating(true);
    // ignore the possibility that user might be null
    // since the only way we can get to this screen is if
    // the user object is EXPLICITLY NOT NULL
    const safariUser = await setUserAuthLevel(
      `${USER?.uid}`,
      "affiliate",
    );

    setAuthenticating(false);

    if (safariUser) {
      // if there is a user and the user permissions test true
      // for affiliate privilleges
      if (Auth.hasAuthLevel(safariUser.permissions, "affiliate")) {
        // means that the operation was successful
        // TODO: update the safariUser
        actions.setSafariUser(safariUser);
        setIsVisible && setIsVisible({
          fabPresent: false,
          isError: false,
          navRailPresent: false,
          title: "Updated to affiliate",
          action: {
            label: "DISMISS",
            onClick: () => {
              dismissSnackbar && dismissSnackbar();
            },
          },
        });

        // update the Affiliate state to authenticated
        setAuthenticated(true);
      } else {
        // means that the operation was not successful
        setIsVisible && setIsVisible({
          fabPresent: false,
          isError: true,
          navRailPresent: false,
          title: "Error occured updating user privillege",
          action: {
            label: "DISMISS",
            onClick: () => {
              dismissSnackbar && dismissSnackbar();
            },
          },
        });
      }
    } else {
      // means that the operation was not successful
      setIsVisible && setIsVisible({
        fabPresent: false,
        isError: true,
        navRailPresent: false,
        title: "Error occured updating user privillege",
        action: {
          label: "DISMISS",
          onClick: () => {
            dismissSnackbar && dismissSnackbar();
          },
        },
      });
    }

  };

  return (
    <div className="h-full w-full">
      <Header.LightHeader />
      {/* Content contained here */}
      <div className="p-4 h-full">
        <div
          className={
            "h-48 -mx-4 -mt-4 bg-center bg-cover bg-no-repeat " +
            "bg-gradient-to-b from-transparent to-black relative sm:h-1/3"
          }
          style={{
            backgroundImage: `url(${stats})`,
          }}
        >
          <div
            className={
              "absolute top-0 right-0 bottom-0 left-0 bg-gradient-to-b " +
              "from-transparent to-black"
            }
          >
            <h1
              className={
                "text-2xl sm:text-4xl font-semibold text-center text-white " +
                "absolute bottom-4 sm:left-4 sm:bottom-1/2 sm:max-w-2xl " +
                "lg:max-w-full lg:left-0 lg:right-0 lg:bottom-4"
              }
            >
              Sign up to be an affiliate on
              Safari<span className="text-safari-gold">Xperts</span>.com
            </h1>
          </div>
        </div>

        <div className="sm:mx-auto sm:max-w-2xl">
          <h2 className="text-xl my-4 sm:text-center">
            Become an affiliate and earn from advertising the properties you
            love on our website
          </h2>

          <hr className="my-6" />

          <div className="sm:grid sm:grid-cols-2">
            <div className="flex flex-row items-center">
              <AiOutlineInfoCircle
                size={32}
                className="mr-3 flex-shrink-0 flex-grow-0 self-start mt-1"
              />
              <div>
                <h6 className="text-3xl font-semibold">Impressions</h6>
                <hr className="hidden" />
                <p className="text-base mt-2">
                  Earn revenue anytime a person clicks on an affiliate link associated
                  with you
                </p>
              </div>
            </div>

            <div
              className="flex flex-row items-center mt-6 sm:flex-shrink-0 sm:mt-0"
            >
              <AiOutlineInfoCircle
                size={32}
                className="mr-3 flex-shrink-0 flex-grow-0 self-start mt-1"
              />
              <div>
                <h6 className="text-3xl font-semibold">Follow throughs</h6>
                <hr className="hidden" />
                <p className="text-base mt-2">
                  Earn anytime someone follows through an affiliate link with a
                  reservation of the property.
                </p>
              </div>
            </div>
          </div>

          <div>
            <div className="flex flex-row justify-center my-6">
              <button
                disabled={authenticating}
                className={
                  `py-4 px-8 rounded-3xl text-white mx-auto ${
                    authenticating ?
                      "bg-gray-600 cursor-not-allowed" :
                      "bg-secondary"
                  }`
                }
                onClick={() => {
                  updateUserPrivilleges();
                }}
              >
                Become an affiliate
              </button>
            </div>

            <div className="flex flex-row justify-center">
              <Link
                to="info/affiliate"
                className="text-xl font-semibold flex flex-row items-center"
              >
                <p>Learn more</p>
                <BiChevronRight
                  size={24}
                  className="flex-shrink-0 flex-grow-0"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
