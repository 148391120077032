import { Badge, Input, List, Switch, Table } from 'antd';
import {FC, useContext, useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router-dom';

import {ContentCategories, fetchAllContent, updatePropertyState} from '../../../api';
import {SnackbarProvider} from '../../../context/snackbar-provider';

export const Overview: FC<RouteComponentProps> = () => {
  // context provider for the app state
  const {
    dismissSnackbar, setIsVisible,
  } = useContext(SnackbarProvider);

  // CONTENT STATE
  const [properties, setProperties] = useState<ContentCategories>({
    activity: [],
    attraction: [],
    car: [],
    conference: [],
    eatery: [],
    hotel: [],
    rental: [],
    tour: [],
  });

  const [populatedProperties, setPopulatedProperties] = useState<
    (keyof ContentCategories)[]
  >([]);

  // UI flag to indicate app is still fetching
  const [fetching, setFetching] = useState<boolean>(true);

  const [active, setActive] = useState("hotel");
  const [searchStr, setSearchStr] = useState<string>("");

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const pagination = {
    pageSize: (window.innerHeight < 750) ? 5 : 10,
  };

  useEffect(() => {
    fetchAllContent((err, content) => {
      if (err) {
        console.error(err);

        return;
      }

      if (content) {
        setProperties(content);

        const newPopulatedCategories: typeof populatedProperties = [];

        Object.keys(content).forEach((contentKey) => {
          const key = contentKey as keyof ContentCategories;
          if (content[key].length > 0) {
            newPopulatedCategories.push(key);
          }
        });

        setPopulatedProperties(newPopulatedCategories);
        setFetching(false);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // reset the search string as it is now irrelevant
    setSearchStr("");
  }, [active]);

  const updateActiveState = (propertyId: string, propertyState: boolean) => {
    updatePropertyState(
      active as keyof ContentCategories, propertyId, propertyState,
      (err, isSuccessful) => {
        if (err) {
          setIsVisible && setIsVisible({
            fabPresent: false,
            isError: true,
            navRailPresent: true,
            title: "Unable to change property state",
            action: {
              label: "DISMISS",
              onClick: () => {
                dismissSnackbar && dismissSnackbar();
              },
            },
          });

          return;
        }

        if (isSuccessful) {
          setIsVisible && setIsVisible({
            fabPresent: false,
            isError: false,
            navRailPresent: true,
            title: "Uproperty state",
            action: {
              label: "DISMISS",
              onClick: () => {
                dismissSnackbar && dismissSnackbar();
              },
            },
          });

         const propertiesToUpdate = [...properties[active as keyof ContentCategories]]
          .map((prop) => {
            if (`${prop.id}` === propertyId) {
              return ({...prop, active: propertyState});
            } else {
              return prop;
            }
          });

          setProperties({...properties, [active]: propertiesToUpdate});
        } else {
          setIsVisible && setIsVisible({
            fabPresent: false,
            isError: true,
            navRailPresent: true,
            title: "Unable to change property state",
            action: {
              label: "DISMISS",
              onClick: () => {
                dismissSnackbar && dismissSnackbar();
              },
            },
          });
        }
      },
    );
  };

  const searchRegExp = new RegExp(searchStr, "gi");

  const viewableProperties = (properties[
      active as keyof ContentCategories
    ] as Record<string, unknown>[]).filter((prop) => searchRegExp.test(`${
      active === "attraction" ?
        prop.name :
        prop.title
    }`));

  return (
    <div className="w-full h-full flex flex-row">
      {/* List of all content categories */}
      <div
        className="w-80 flex-grow-0 flex-shrink-0 h-full border-r overflow-y-scroll"
        style={{
          padding: 16,
          height: window.innerHeight - 56,
        }}
      >
        <List
          loading={fetching}
          dataSource={populatedProperties}
          bordered
          header={
            <h4 className="text-2xl font-lato font-semibold">
              All Services
            </h4>
          }
          renderItem={(item) => (
            <div
              className={
                `w-full my-3 cursor-pointer hover:bg-yellow-200 relative ${
                  active === item ?
                    "bg-blue-300" :
                    ""
                }`
              }
            >
              <List.Item
                className="w-full"
                onClick={() => {
                  setActive(item);
                }}
              >
                {item}
              </List.Item>
              <Badge
                overflowCount={1000}
                count={properties[item as keyof ContentCategories].length}
                className="absolute right-2 top-2"
              />
            </div>
          )}
        />
      </div>

      <div
        className="p-4 w-full h-full overflow-y-scroll"
      >
        <Input.Search
          className="mb-3"
          placeholder="Search for property"
          value={searchStr}
          onSearch={(val) => setSearchStr(val)}
          onChange={(e) => setSearchStr(e.target.value)}
        />
        <Table
          className="w-full"
          columns={[
            {
              key: "id",
              dataIndex: "id",
              title: "ID"
            },
            {
              key: "id",
              dataIndex: (active === "attraction") ? "name" : "title",
              title: "Property name"
            },
            {
              key: "id",
              dataIndex: "location",
              title: "Location"
            },
            {
              key: "id",
              title: "Active",
              dataIndex: "id",
              render: (value, record) => (
                <Switch
                  defaultChecked={
                    record.active === undefined || record.active === true
                  }
                  onChange={(val) => {
                    updateActiveState(record.id as string, val);
                  }}
                />
              ),
            },
          ]}
          dataSource={viewableProperties}
          pagination={pagination}
        />
      </div>
    </div>
  );
};
