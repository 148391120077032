import {FC, useEffect, useState} from 'react';
import {Route, RouteComponentProps, Switch, useHistory} from 'react-router';
import Lottie from 'react-lottie';
import {auth} from 'firebase';

import animation from "../../assets/anim/plane.json";

import {NavigationRail} from './components';
import {
  NewProperty,
  PartnerActivitySummaryView, PartnerCarSummaryView,
  PartnerConferenceSummaryView, PartnerDashboard, PartnerEaterySummaryView,
  PartnerHotelSummaryView, PartnerModuleView, PartnerRentalSummaryView,
  PartnerSummary, PartnerTourSummaryView, PartnerWallet,
  EditDraftProperty, EditProperty, PriceManager,
} from './routes';

type PartnerProps = RouteComponentProps;

/**
 * Houses all the partner routes allowing for addition of properties
 */
export const Partner: FC<PartnerProps> = ({match}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    auth().onAuthStateChanged((user) => {
      if (user) {
        setLoading(false);
      } else {
        history.replace("/signin");
      }
    });
  }, [history]);

  return (
    <>
      {loading && (
        <div
          className="w-full flex flex-col items-center justify-center"
        >
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animation,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={300}
            width={300}
          />
          <h6 className="text-2xl">Fetching Details...</h6>
        </div>
      )}
      {!loading && (
        <div
          className={
            "absolute top-0 left-0 right-0 bottom-0 " +
            " lg:flex lg:flex-row"
          }
        >
          <NavigationRail vosd={false} />
          <div
            className="partner-content-container overflow-y-scroll w-full"
          >
            <Switch>
              <Route
                path={`${match.path}/`}
                exact={true}
                component={PartnerDashboard}
              />
              <Route
                path={`${match.path}/wallet`}
                component={PartnerWallet}
              />
              <Route
                path={`${match.path}/summary`}
                component={PartnerSummary}
              />
              <Route
                path={`${match.path}/hotel`}
                component={PartnerHotelSummaryView}
              />
              <Route
                path={`${match.path}/rental`}
                component={PartnerRentalSummaryView}
              />
              <Route
                path={`${match.path}/conference`}
                component={PartnerConferenceSummaryView}
              />
              <Route
                path={`${match.path}/eatery`}
                component={PartnerEaterySummaryView}
              />
              <Route
                path={`${match.path}/car`}
                component={PartnerCarSummaryView}
              />
              <Route
                path={`${match.path}/tour`}
                component={PartnerTourSummaryView}
              />
              <Route
                path={`${match.path}/activity`}
                component={PartnerActivitySummaryView}
              />
              <Route
                path={`${match.path}/view`}
                component={PartnerModuleView}
              />
              <Route
                path={`${match.path}/new`}
                component={NewProperty}
              />
              <Route
                path={`${match.path}/draft`}
                component={EditDraftProperty}
              />
              <Route
                path={`${match.path}/edit`}
                component={EditProperty}
              />
              <Route
                path={`${match.path}/price-manager/:category/:id`}
                component={PriceManager}
              />
            </Switch>
          </div>
          {/* <div className="flex-grow px-4"></div> */}
        </div>
      )}    
    </>
  );
};
