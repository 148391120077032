import {FC, useRef, useState} from "react";
import {FaTimes} from 'react-icons/fa';
import {Formik} from 'formik';
import {Draggable, Droppable, DragDropContext, DropResult, ResponderProvided} from 'react-beautiful-dnd';

import {Picture} from "../../../api";
import {TextArea} from "../../../components/input";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { RiImageAddFill } from "react-icons/ri";
import { Image } from "antd";

import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'

type AddImageOverlayProps = {
  initialImages: Picture[];
  initialLogo?: Picture;
  setPropertyLogo?: (logo?: Picture) => void;
  onSave: (images: Picture[]) => void;
  removeOverlay: () => void;
};

export const AddImageOverlay: FC<AddImageOverlayProps> = ({
  initialImages,
  initialLogo,
  onSave,
  removeOverlay,
  setPropertyLogo,
}) => {
  const [imageURL, setImageURL] = useState<string>("/ATLAS-OF-PLACES.jpg");

  const [logo, setLogo] = useState<Picture | undefined>(initialLogo);
  const [images, setImages] = useState<Picture[]>(initialImages);
  const formContainerRef = useRef<HTMLFormElement>(null);

  const fileChooserRef = useRef<HTMLInputElement>(null);

  const [bannerVisible, setBannerVisible] = useState<boolean>(true);

  const addMultipleImages = (files: FileList, caption: string) => {
    const newImages: Picture[] = [];

    for (let i=0; i<files.length; i++) {
      const fileUrl = URL.createObjectURL(files[i]);
      newImages.push({
        caption, url: fileUrl, filename: files[i].name,
        file: files[i],
      });
    }

    setImages([...images, ...newImages]);
  };

  const removeImage = (idx: number) => {
    const newImages = [...images];
    newImages.splice(idx, 1);

    setImages(newImages);
  };

  const reorderImage = (result: DropResult, provided: ResponderProvided) => {
    if (result.reason === "CANCEL") {
      // means that the image was not dropped within the droppable region
      return;
    }

    // to please tsc, just make sure that there is the destination in the
    // result.destination
    if (result.destination) {
      const sourceIdx = result.source.index;
      const destinationIdx = result.destination.index;

      const reorderedImages = [...images];
      const [removedImage] = reorderedImages.splice(sourceIdx, 1);
      // push in the image to the new location
      reorderedImages.splice(destinationIdx, 0, removedImage);

      // after reordering the images, set the new order
      setImages(reorderedImages);
    }
  };

  return (
    <div
      className={
        "bg-white border rounded-lg w-full h-full overflow-y-scroll " +
        "sm:max-w-lg sm:max-h-dialog"
      }
    >
      {/** App Bar */}
      <div
        className={
          "bg-safari-gold sticky top-0 w-full h-14  px-4 shadow-md " +
          "flex flex-row items-center justify-between text-white z-30"
        }
      >
        <div className="flex flex-row items-center">
          <FaTimes size={24} onClick={removeOverlay} />
          <div className="ml-3">
            <h6 className="text-xl font-semibold">Add Images</h6>
            <p className="text-xs opacity-70">
              Add images to be displayed to users
            </p>
          </div>
        </div>

        {/** Save Button */}
        <button
          className="h-9 px-3 border border-black rounded-md shadow-md text-black font-semibold text-sm flex items-center"
          onClick={() => {
            onSave(images);
            setPropertyLogo && setPropertyLogo(logo);
            removeOverlay();
          }}
        >
          Save
        </button>
        {/** End Save Button */}
      </div>
      {/** End of App Bar */}

      {/* Insert company logo, if the setLogo function is available */}
      {setPropertyLogo && (
        <div className="w-full flex flex-col items-center">
          <label htmlFor="logo-update" className="w-36 mt-3 cursor-pointer">
            <Image
              className="flex-grow-0 flex-shrink-0"
              src={logo ? logo.url : ""}
              fallback=""
              width={144}
              height={144}
              preview={false}
            />

            <input
              id="logo-update"
              className="hidden"
              type="file"
              accept="image/jpeg, image/png"
              onChange={(e) => {
                if (e.target.files) {
                  const newPicture: Picture = {
                    caption: "Property logo",
                    url: URL.createObjectURL(e.target.files[0]),
                    file: e.target.files[0],
                    filename: e.target.files[0].name,
                  };

                  setLogo(newPicture);
                }
              }}
            />
          </label>
          <p className="mt-2 text-center">Insert property logo if present</p>
          <hr className="mt-2" />
        </div>
      )}

      {/* Banner informing user that they can drag images */}
      {bannerVisible && (
        <div
          className={
            "px-4 pb-2 pt-6 bg-white border-b transition-all duration-150"
          }
        >
          <div className="flex flex-row">
            <AiOutlineInfoCircle
              size={40}
              className="text-safari-gold flex-shrink-0"
            />
            <p className="text-md ml-4">
              Drag images to reorder in terms of preference of how you would
              like them displayed. NOTE: The order of the images is the order
              that is used by the system when choosing images to display. Where
              the first image is used as the cover of the property.
            </p>
          </div>
          <div className="flex flex-row justify-end pt-3 pr-2">
            <button
              className="px-4 text-error font-semibold text-xl h-9"
              onClick={() => {
                setBannerVisible(false);
              }}
            >
              DISMISS
            </button>
          </div>
        </div>
      )}
      {/* End of banner */}

      <Formik
        enableReinitialize={true}
        initialValues={{
          image_caption: "",
          new_image: "",
        }}
        onSubmit={({ image_caption, new_image }, formikEvent) => {
          // fetch the image url created by the browser before submitting
          // add image
          // NOTE: Before the image is added, we have to confirm that both the
          // fileChooserRef and the Files a=object are not null
          // to prevent referencing null pointers
          if (fileChooserRef.current) {
            if (fileChooserRef.current.files !== null) {
              addMultipleImages(fileChooserRef.current.files, image_caption);
              // reset the form
              formikEvent.resetForm();
              setImageURL("/ATLAS-OF-PLACES.jpg");
            } else {
              console.error(
                "files variable in the fileChooser are not polulated"
              );
            }
          } else {
            console.error(
              "File ref is currently not pointing to a valid HTMLInputElement"
            );
          }
        }}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <form
            ref={formContainerRef}
            onChange={handleChange}
            onSubmit={handleSubmit}
          >
            {/** Add image container */}
            <div className="p-4">
              <label htmlFor="add-image">
                <div
                  className={
                    " h-96 lg:h-36 rounded-md bg-cover " +
                    "bg-no-repeat bg-center border flex items-center " +
                    "justify-center cursor-pointer"
                  }
                  style={{
                    backgroundImage: `url(${imageURL})`,
                  }}
                >
                  <div className="p-3 bg-white opacity-40">
                    <RiImageAddFill size={48} className="z-40 opacity-100" />
                  </div>
                  <input
                    ref={fileChooserRef}
                    name="new_image"
                    className="hidden"
                    type="file"
                    multiple={true}
                    id="add-image"
                    accept="image/png, image/jpeg"
                    onChange={(e) => {
                      if (fileChooserRef.current?.files) {
                        //   console.log("Setting new imageURL");
                        const newImageURL = URL.createObjectURL(
                          fileChooserRef.current.files[0]
                        );
                        console.log("New Image URL" + newImageURL);
                        setImageURL(newImageURL);
                      } else {
                        console.log("No files input");
                      }

                      handleChange(e);
                    }}
                  />
                </div>
              </label>
              {errors.new_image && (
                <p className="text-xs mt-1 ml-1 text-red-500 font-semibold">
                  Image is required
                </p>
              )}
              {!errors.new_image && fileChooserRef.current?.files && (
                <>
                  {fileChooserRef.current.files.length > 1 && (
                    <p>*Multiple images selected</p>
                  )}
                </>
              )}

              <div className="mt-3">
                <TextArea
                  helper="A caption is required to help visually impaired users"
                  id="image-caption"
                  name="image_caption"
                  onChange={handleChange}
                  error={errors.image_caption}
                  inputLabel="New image caption"
                  value={values.image_caption}
                />
              </div>

              <div className="flex flex-row justify-end pt-3 w-full">
                <button
                  disabled={
                    values.image_caption === "" || values.new_image === ""
                  }
                  type="submit"
                  className={
                    "h-9 px-3 border rounded-md shadow-md " +
                    "font-semibold text-sm whitespace-nowrap flex-shrink-0 " +
                    `${
                      values.image_caption === "" || values.new_image === ""
                        ? "border-gray-700 text-gray-700 cursor-not-allowed"
                        : "border-red-500 text-red-500"
                    }`
                  }
                >
                  Add Image
                </button>
              </div>
            </div>
            {/** End of add image container */}
          </form>
        )}
      </Formik>

      <hr />

      {/** Added images with caption */}
      <DragDropContext onDragEnd={reorderImage}>
        <Droppable droppableId="add-property-image-overlay">
          {(provided, snapshot) => (
            <div
              className="p-4 pt-0"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {images.map((image, idx) => (
                <Draggable
                  key={`saved_image_${idx}`}
                  draggableId={`${image.url}_${idx}`}
                  index={idx}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      className={
                        "p-4 mt-4 border rounded-tl-xl overflow-hidden shadow-md h-28 " +
                        "flex flex-row w-full"
                      }
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                      <div
                        className={
                          "-ml-4 -mb-4 -mt-4 h-28 w-28 flex-shrink-0 " +
                          "bg-cover bg-center bg-no-repeat"
                        }
                        style={{ backgroundImage: `url(${image.url})` }}
                      />
                      <div className="flex flex-col ml-3 h-full w-full">
                        <p className="text-sm flex-shrink h-16 overflow-hidden">
                          {image.caption}
                        </p>
                        <div
                          className={
                            "flex flex-row w-full justify-end flex-shrink-0 " +
                            "mt-2 -mb-2 -mr-2"
                          }
                        >
                          <button
                            className={
                              "h-9 px-3 border border-red-500 rounded-md shadow-md " +
                              "text-red-500 font-semibold text-sm" +
                              " flex items-center whitespace-nowrap"
                            }
                            onClick={() => removeImage(idx)}
                          >
                            Delete Image
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {/** End of added images with caption */}
    </div>
  );
};
