import {FC, useContext, useEffect, useRef, useState} from "react";
import {RouteComponentProps, useHistory} from "react-router-dom";
import {Formik} from "formik";
import {RiCarLine} from "react-icons/ri";

import {
  Coordinates, FAQ, fetchCar, Picture, RefundType,
  ReservationType,
  updateProperty,
} from "../../../../../api";
import {Input} from "../../../../../components/input";

import {OverlayProvider} from "../../../../search-property/context";

import {
  EditReservationPolicyListItem, EditRefundPolicyListItem,
  PropertyLocationAutoComplete, PickImageListItem, PartnerHeader,
} from "../../../components";
import {EditFaqListItem} from "../../../components/edit-faq-list-item";
import {UploadingOverlay} from "../../../components/uploading-overlay";

import {validateCarForm} from "./validate-form";
import ActivityIndicator from "../../../../../components/loading";
import { SnackbarProvider } from "../../../../../context/snackbar-provider";
import { FaSave } from "react-icons/fa";
import { ConfirmPropertyLocation } from "../../../components/confirm-property-location";
import { PriceChange } from "../../../../../api/hotel/Offer";

type EditCarProps = RouteComponentProps<{property_id: string}>;

type InitialCarValues = {
  reg_no: string,
  model: string,
  drivers_picture: string,
  drivers_name: string,
  drivers_phone: string,
  drivers_email: string,
  location: string,
  coords: string,
  exceptions: string,
  passengers: string,
  reservation_policy: string,
  refund_policy: string,
  gallery: string,
  faqs: string,
};

export const EditCar: FC<EditCarProps> = ({match}) => {
  const {
    setIsCancellable, setIsOverlay, setOnOverlay,
  } = useContext(OverlayProvider);
  const {
    setIsVisible: setSnackbarVisible,
    dismissSnackbar,
  } = useContext(SnackbarProvider);

  const history = useHistory();

  const [reservationPolicy, setReservationPolicy] =
    useState<ReservationType>("full_amount");
  const [refundPolicy, setRefundPolicy] =
    useState<RefundType>("partial_refund");
  const [coords, setCoords] = useState<Coordinates>({ lat: 0, lng: 0 });
  const [faqs, setFaqs] = useState<FAQ[]>([]);
  const [gallery, setGallery] = useState<Picture[]>([]);
  const [offers, setOffers] = useState<PriceChange[]>([]);

  // handle to the input that is supposed to contain the driver image
  const driverImagePickerRef = useRef<HTMLInputElement>(null);

  const [driverImageURL, setDriverImageURL] = useState<string>("");

  const [isUploading, setIsUploading] = useState<boolean>(false);

  const propertyId = match.params.property_id;

  // in order to make the validation before submitting easier, even values that
  // will not be got from the for but from overlays are also detailed in the
  // initial values to allow for enforcing of these values inside the formik
  // container to prevent submission if the values are not present.
  const [initialValues, setInitialValues] =
    useState<null | InitialCarValues>(null);
  
  useEffect(() => {
    setIsCancellable && setIsCancellable(false);

    setOnOverlay && setOnOverlay(() => () => (
      <ActivityIndicator caption="" />
    ));

    setIsOverlay && setIsOverlay(true);

    const removeOverlay = () => {
      setIsCancellable && setIsCancellable(true);

      setOnOverlay && setOnOverlay(null);

      setIsOverlay && setIsOverlay(false);
    };

    fetchCar(propertyId, (err, car) => {
      if (err) {
        console.error(err);

        history.goBack();
        setSnackbarVisible && setSnackbarVisible({
          fabPresent: true,
          isError: true,
          navRailPresent: true,
          title: "An error occured while fetching property details, try again",
        });
        removeOverlay();
        return;
      }

      if (car) {
        setInitialValues({
          coords: "",
          drivers_email: car.driver.email,
          drivers_name: car.driver.name,
          drivers_phone: car.driver.phone_number,
          drivers_picture: car.driver.avatar.url,
          exceptions: "",
          faqs: "",
          gallery: "",
          location: car.location,
          model: car.model,
          passengers: isNaN(Number(car.passengers)) ?
            "" :
            `${Number(car.passengers)}`,
          refund_policy: "",
          reg_no: car.reg_no,
          reservation_policy: "",
        });

        setCoords(coords);
        setDriverImageURL(car.driver.avatar.url);
        setFaqs(car.faq);
        setGallery(car.gallery);
        setRefundPolicy(car.refund_policy);
        setReservationPolicy(car.reservation_policy);
        setOffers(car.offers)
      } else {
        history.goBack();
        setSnackbarVisible && setSnackbarVisible({
          fabPresent: true,
          isError: true,
          navRailPresent: true,
          title: "Could not find property details, try again",
        });
      }

      removeOverlay();
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isUploading && (
        <OverlayProvider.Consumer>
          {({setIsCancellable, setIsOverlay, setOnOverlay}) => (
            <UploadingOverlay
              caption="Updating details (this may take a while...)"
              setIsCancellable={setIsCancellable}
              setIsOverlay={setIsOverlay}
              setOnOverlay={setOnOverlay}
            />
          )}
        </OverlayProvider.Consumer>
      )}

      {initialValues !== null && (
        <Formik
          initialValues={initialValues}
          validate={(values) => {
            return validateCarForm({
              coords,
              drivers_email: values.drivers_email,
              drivers_name: values.drivers_name,
              drivers_phone: values.drivers_phone,
              drivers_picture: values.drivers_picture,
              faqs: faqs,
              gallery,
              location: values.location,
              model: values.model,
              passengers: values.passengers,
              refund_policy: refundPolicy,
              reservation_policy: reservationPolicy,
              reg_no: values.reg_no,
            });
          }}
          onSubmit={(values) => {
            // create the new car entry
            let driversPictureUrl = "";
            if (driverImagePickerRef.current?.files) {
              driversPictureUrl = URL.createObjectURL(
                driverImagePickerRef.current.files[0]
              );
            }

            setIsUploading(true);
            // gameloop until the saving of the last draft is done
            updateProperty(
              "car",
              propertyId,
              {
                coords,
                driver: {
                  avatar: {
                    caption: `${values.drivers_name}`,
                    url: driversPictureUrl,
                    filename:
                      driverImagePickerRef.current?.files ?
                      driverImagePickerRef.current.files[0].name :
                      "",
                  },
                  name: values.drivers_name,
                  phone_number: values.drivers_phone,
                  email: values.drivers_email,
                },
                exceptions: [],
                faq: faqs,
                gallery,
                id: values.reg_no,
                location: values.location,
                model: values.model,
                passengers: Number(values.passengers),
                rating: 10,
                refund_policy: refundPolicy,
                reservation_policy: reservationPolicy,
                reg_no: values.reg_no,
                reviews: [],
                offers,
              },
              (err, result) => {
                setIsUploading(false);
                if (err) {
                  console.error(err);
                  setSnackbarVisible && setSnackbarVisible({
                    fabPresent: false,
                    isError: true,
                    navRailPresent: true,
                    title: "Unable to publish car details",
                  });
                  return;
                }
    
                if (result) {
                  setSnackbarVisible && setSnackbarVisible({
                    fabPresent: false,
                    isError: false,
                    navRailPresent: true,
                    title: `Car, ${result.reg_no}, published`,
                    action: {
                      label: "DISMISS",
                      onClick: () => {
                        dismissSnackbar && dismissSnackbar();
                      },
                    },
                  });
                  history.goBack();
                } else {
                  setSnackbarVisible && setSnackbarVisible({
                    fabPresent: false,
                    isError: true,
                    navRailPresent: true,
                    title: "Unable to publish car details",
                  });
                }
              },
            );
          }}
        >
          {({ values, errors, handleChange, handleSubmit, handleBlur, setErrors, validateForm }) => (
            <form onSubmit={handleSubmit} onBlur={handleBlur}>
              <PartnerHeader
                HeaderIcon={RiCarLine}
                title={values.reg_no ? values.reg_no : "New Car"}
                actions={[
                  {
                    label: "UPDATE CAR",
                    disabled: Object.keys(errors).length > 0,
                    ActionIcon: FaSave,
                    alwaysVisible: true,
                    type: "submit",
                    onClick: () => {
                      console.log("Saving new car");
                    },
                  },
                ]}
              />
              <div className="p-4 sm:grid sm:grid-cols-2 gap-4 lg:grid-cols-3 lg:max-w-5xl">
                {/** Input Title */}
                <div>
                  <Input
                    id="car-reg-no"
                    helper="Registration number of the car"
                    inputLabel="Car Reg No."
                    name="reg_no"
                    value={values.reg_no}
                    error={errors.reg_no}
                    onChange={handleChange}
                  />
                </div>
                {/** End of input Title */}

                {/** Input Title */}
                <div className="mt-3 sm:mt-0">
                  {/** Text input */}
                  <Input
                    value={values.model}
                    helper="The model of the car"
                    id="car-model"
                    inputLabel="Vehicle model"
                    name="model"
                    error={errors.model}
                    onChange={handleChange}
                  />
                  {/** End of text input */}
                </div>
                {/** End of input Title */}

                {/** Input Title */}
                <div className="mt-3 sm:mt-0">
                  {/** Text input */}
                  <Input
                    helper="Maximum number of passengers the car can hold"
                    id="car-max-passengers"
                    inputLabel="Passengers"
                    name="passengers"
                    value={values.passengers}
                    error={errors.passengers}
                    onChange={handleChange}
                  />
                  {/** End of text input */}
                </div>
                {/** End of input Title */}

                {/** Input Title */}
                <div className="mt-3 sm:mt-0">
                  {/** Text input */}
                  <PropertyLocationAutoComplete
                    inputLabel="Primary location"
                    name="location"
                    selectAddress={(place, coords) => {
                      setIsCancellable && setIsCancellable(false);
                      // setCoords(coords);
                      setOnOverlay && setOnOverlay(() => () => (
                        <ConfirmPropertyLocation
                          initialCoords={coords}
                          onConfirm={(coords) => {
                            setCoords(coords);
                          }}
                          removeOverlay={() => {
                            setIsOverlay && setIsOverlay(false);
                            setIsCancellable && setIsCancellable(true);
                            setOnOverlay(null);
                          }}
                        />
                      ));

                      setIsOverlay && setIsOverlay(true);
                    }}
                    value={values.location}
                    error={errors.location}
                    onChange={handleChange}
                  />
                  {/** End of text input */}
                </div>
                {/** End of input Title */}

                {/** Reservation policy card */}
                <EditReservationPolicyListItem
                  onSave={async (reservationPolicy) => {
                    setReservationPolicy(reservationPolicy);
                    setErrors(await validateForm());
                  }}
                  reservationPolicy={reservationPolicy}
                />
                {/** End of Reservation policy card */}

                {/** Refund policy card */}
                <EditRefundPolicyListItem
                  onSave={async (refundPolicy) => {
                    setRefundPolicy(refundPolicy);
                    setErrors(await validateForm());
                  }}
                  refundPolicy={refundPolicy}
                />
                {/** End of Refund policy card */}

                {/** Pick images overlay */}
                <PickImageListItem
                  gallery={gallery}
                  minImageCount={3}
                  onOverlaySave={async (gallery) => {
                    setGallery(gallery);

                    setErrors(await validateForm());
                  }}
                />
                {/* End of pick images overlay */}

                {/** Things liks prices and sub properties are found here */}
                <hr className="my-4 sm:col-start-1 sm:col-end-3 h-auto lg:hidden" />
                {/** Special Section */}
                <div className="shadow-md border sm:col-start-1 sm:col-end-3">
                  <div className="h-14 flex flex-row justify-between items-center px-4 border-b">
                    <div>
                      <h6 className="text-xl font-semibold">Driver details</h6>
                      <p className="text-xs">
                        these are the details of the driver that will be handling
                        the car
                      </p>
                    </div>
                  </div>
                  <div className="p-4 flex flex-col items-center sm:flex-row">
                    <div className="sm:mr-2">
                      <label
                        className="sm:flex-shrink-0 sm:mr-4"
                        htmlFor="driver-profile-picture"
                      >
                        <input
                          ref={driverImagePickerRef}
                          className="hidden"
                          id="driver-profile-picture"
                          name="drivers_picture"
                          type="file"
                          onChange={(e) => {
                            if (driverImagePickerRef.current?.files) {
                              setDriverImageURL(
                                URL.createObjectURL(driverImagePickerRef.current?.files[0])
                              );
                            }
                          }}
                        />
                        <div
                          className="mb-2 bg-cover bg-no-repeat bg-center h-36 w-36 rounded-full border"
                          // style="background-image: url(/ATLAS-OF-PLACES.jpg);"
                          style={{
                            backgroundImage: `url(${driverImageURL})`
                          }}
                        ></div>
                      </label>
                      <p className="text-xs text-center mb-2">
                        press to pick picture
                      </p>
                    </div>

                    <div className="w-full sm:flex sm:flex-row sm:flex-wrap">
                      {/** Input Title */}
                      <div className="flex flex-col justify-between sm:mr-3">
                        {/** Text input */}
                        <Input
                          name="drivers_name"
                          value={values.drivers_name}
                          error={errors.drivers_name}
                          helper="This is the driver manning the car"
                          id="car-driver-name"
                          inputLabel="Driver's Name"
                          onChange={handleChange}
                        />
                        {/** End of text input */}
                      </div>
                      {/** End of input Title */}

                      {/** Input Title */}
                      <div className="mt-3 sm:mt-2">
                        {/** Text input */}
                        <Input
                          name="drivers_phone"
                          value={values.drivers_phone}
                          error={errors.drivers_phone}
                          helper="This is the phone number driver manning the car"
                          id="car-driver-phone"
                          inputLabel="Driver's Phone"
                          onChange={handleChange}
                        />
                        {/** End of text input */}
                      </div>
                      {/** End of input Title */}

                      {/** Input Title */}
                      <div className="mt-3">
                        {/** Text input */}
                        <Input
                          name="drivers_email"
                          value={values.drivers_email}
                          error={errors.drivers_email}
                          helper="This is the email of driver manning the car"
                          id="car-driver-phone"
                          inputLabel="Driver's email"
                          onChange={handleChange}
                        />
                        {/** End of text input */}
                      </div>
                      {/** End of input Title */}
                    </div>
                  </div>
                </div>
                {/** Special Section */}

                <div className="">
                  <h3 className="text-2xl font-semibold">Aditional Information</h3>
                  {/** Add FAQs */}
                  <EditFaqListItem
                    faqs={faqs}
                    error={errors.faqs}
                    onSave={async (newFaqs) => {
                      setFaqs(newFaqs);

                      setErrors(await validateForm());
                    }}
                  />
                  {/** End of FAQ */}
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}

    </>
  );
};
