import {FC, useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router';
import {User, auth} from 'firebase';
import {AiOutlineHistory} from 'react-icons/ai';
import {GrMoney} from 'react-icons/gr';
import {FaMoneyBill} from 'react-icons/fa';
import {RiNotification2Fill, RiProfileLine} from 'react-icons/ri';

import {DateUtil} from '../../../../util';
import {PopulatedReservation} from '../../../../api/reservation/types';
import {fetchNotifications, fetchPartnerReservations, fetchPopulatedReservations } from '../../../../api';

import {PartnerHeader} from '../../components';

import {SummaryCard} from './summary-card';
import {Transaction} from '../../../../api/payment/types';
import {fetchInTransactions} from '../../../../api/payment';
import {NotificationCard} from '../../../../components/notification-card';
import {Notification} from '../../../../api/notification/types';

export const Summary: FC<RouteComponentProps> = ({
  match,
}) => {
  const [user, setUser] = useState<User | null>(null)
  const [reservations, setReservations] = useState<PopulatedReservation[]>([]);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    const firebaseUser = auth().currentUser;

    setUser(firebaseUser);

    if (firebaseUser) {
      fetchPartnerReservations(firebaseUser.uid, (err, results) => {
        if (err) {
          console.error(err);

          return;
        }

        if (results) {
          fetchPopulatedReservations(results, (populateError, populatedRes) => {
            if (populateError) {
              console.error(populateError);

              return;
            }
            if (populatedRes) {
              setReservations(populatedRes);
            }
          });
        }
      });

      fetchInTransactions(firebaseUser.uid, (err, res) => {
        if (err) {
          console.error(err);

          return;
        }

        if (res) {
          setTransactions(res);
        }
      });

      fetchNotifications((err, notifications) => {
        if (err) {
          console.log(err);

          return;
        }

        if (notifications) {
          setNotifications(notifications);
        }
      });
    }
  }, []);

  const markNotificationAsRead = (idx: number) => {
    const updatedNotifications = [...notifications];
    updatedNotifications.splice(idx, 1, {...notifications[idx], read: true});

    setNotifications(updatedNotifications);
  };

  return (
    <div style={{overflowY: "unset"}}>
      <PartnerHeader
        HeaderIcon={AiOutlineHistory}
        title="Activity Summary"
      />
      {/* Summary content */}
      <div className="flex flex-row justify-center">
        <div className="p-4 lg:grid lg:grid-cols-2 gap-4 lg:max-w-6xl">
          <SummaryCard
            title="Account Details"
            CardIcon={RiProfileLine}
            className="mb-3 sm:max-w-md"
          >
            {user && (
              <>
                <div className="grid grid-cols-2 border-b py-1">
                  <h6>Name</h6>
                  <p>{user.displayName}</p>
                </div>

                <div className="grid grid-cols-2 border-b py-1">
                  <h6>Email</h6>
                  <p>{user.email}</p>
                </div>

                <div className="grid grid-cols-2 border-b py-1">
                  <h6>Phone Number</h6>
                  <p>{user.phoneNumber}</p>
                </div>

                <div className="flex flex-row justify-center pt-3">
                  <button
                    className="h-8 w-96 bg-error font-semibold text-white shadow-md rounded-md"
                  >
                    Logout
                  </button>
                </div>
              </>
            )}
          </SummaryCard>

          <SummaryCard
            title="Notifications"
            CardIcon={RiNotification2Fill}
            className="mb-3 h-80 overflow-y-scroll"
          >
            {notifications.map((notification, idx) => (
              <NotificationCard
                key={notification.id}
                notification={notification}
                onMarkAsRead={() => {
                  markNotificationAsRead(idx);
                }}
              />
            ))}
          </SummaryCard>

          <SummaryCard
            title="Transaction History"
            CardIcon={GrMoney}
            className="mb-3"
          >
            <div className="relative">
              <div className="sticky top-0 grid grid-cols-4 gap-2 py-2 border-b border-black place-items-center place-content-center">
                <h6 className="text-base font-semibold">Transactor</h6>
                <h6 className="text-base font-semibold">Receiver</h6>
                <p className="font-semibold">Amount</p>
                <p className="font-semibold">Type</p>
              </div>

              {transactions.map((transaction) => (
                <div
                  key={`${transaction.transactionId}`}
                  className="grid grid-cols-4 gap-2 py-2 border-b place-items-center place-content-center">
                  <h6 className="text-base font-semibold text-center">SafariXperts</h6>
                  <h6 className="text-base font-semibold text-center">{user?.displayName}</h6>
                  <p className="font-semibold text-center">{`${transaction.amount} USD`}</p>
                  <div className="flex flex-col items-center">
                    <FaMoneyBill color="green" size={24} />
                    <p className="text-xs mt-1">{transaction.mode}</p>
                  </div>
                </div>
              ))}
            </div>

          </SummaryCard>

          <SummaryCard
            title="Activity History"
            CardIcon={AiOutlineHistory}
            className="mb-3"
          >
            <div>
              <div className="grid grid-cols-4 place-content-center place-items-center border-b border-black py-2">
                <h6 className="font-semibold">Reservation Date</h6>
                <h6 className="font-semibold">Client</h6>
                <h6 className="font-semibold">Property Reserved</h6>
                <h6 className="font-semibold">Checkin</h6>
              </div>

              {reservations.map((reservation) => (
                <div
                  key={`${reservation.id}`}
                  className={
                    "grid grid-cols-4 gap-2 place-content-center " +
                    "place-items-center border-b py-2"
                  }
                >
                  <h6 className="font-semibold text-center">
                    {DateUtil.changeDateFormatFromDate(
                      new Date(reservation.reservation_date), "DD-MM-YYYY")
                    }
                  </h6>
                  <h6 className="font-semibold text-center">
                    {reservation.user}
                  </h6>
                  <h6 className="font-semibold text-center">{reservation.property.title}</h6>
                  <h6 className="font-semibold text-center">
                    {DateUtil.changeDateFormatFromDate(
                      new Date(reservation.checkin), "DD-MM-YYYY")
                    }
                  </h6>
                </div>
              ))}
            </div>
          </SummaryCard>
        </div>
      </div>
    </div>
  );
};
