import { Tooltip } from 'antd';
import {FC, useContext, useEffect, useRef, useState} from 'react';
import { BiLink } from 'react-icons/bi';
import {useLocation} from 'react-router-dom';
import {Marker, StaticGoogleMap} from 'react-static-google-map';

import {
  getRefundPolicy, getReservationPolicies, Policy, Property, ReservationCategory,
} from '../../../api';
import {OverlayProvider} from '../../../context';
import {AppStateContext} from '../../../context/app-state-provider';
import { Auth } from '../../../util';
import {AffiliateOverlay} from './affiliate-overlay';
import NewGallery from './new-gallery';

import {PolicyCard} from './policy-card';

export interface PropertyContainerProps {
  policies?: Policy[];
  property: Property;
  // reservationPolicy: ReservationType;
  // refundPolicy: RefundType;
  renderSearchSection?: () => JSX.Element; // eslint-disable-line
  showDirections?: boolean;
}

/**
 * Wraps all the components in the property viewing section. It allows for the
 * having of sub properties or the having of a section that propceeds directly
 * to checkout if there are not sub properties to be displayed.
 *
 * Sub Property Section
 * --------------------
 * This section is for displaying additional information about sub properties
 * contained on the property being displayed. This enables easy display using
 * the reusable property card under `/routes/property/components
 *
 * If one would like to display components in the subproperty section, one must
 * wrap the content in `SubPropertySection` e.g:
 * ```
 * <SubPropertySection>
 *  {properties.map((property, idx) => (
 *     <PropertyCard key={`property_${idx}`} property={property} />
 *  ))}
 * </SubPropertySection>
 * ```
 * 
 * Checkout Section
 * ----------------
 * This section is included if one proceeds directly to checkout on the screen
 * itself and not on the overlay. If you would like to display the checkout card
 * in this section, one must be sure to wrap the checkout card in the component
 * `CheckoutSection` e.g:
 * ```
 * <CheckoutSection>
 *    <ProceedToCheckout... />
 * </CheckoutSection>
 * ```
 * @param props container props
 * @returns Container component.
 */
const Container: FC<PropertyContainerProps> = ({
  children, policies, property, showDirections, renderSearchSection,
}) => {
  const {
    setIsOverlay, setOnOverlay,
  } = useContext(OverlayProvider);
  const {state: {USER, SAFARI_USER}} = useContext(AppStateContext);

  const location = useLocation();
  const bookPropertyRef = useRef<HTMLDivElement>(null);

  const [refundPolicy, setRefundPolicy] = useState<Policy | null>(null);
  const [reservationPolicy, setReservationPolicy] = useState<Policy | null>(null);

  const [category, setCategory] = useState<string>("");
  const [propertyId, setPropertyId] = useState<string>("");
  const [isAffiliate, setIsAffiliate] = useState<boolean>(false);

  useEffect(() => {
    const [, , category, propertyId] = location.pathname.split("/");

    setCategory(category);
    setPropertyId(propertyId);

    getReservationPolicies((err, result) => {
      if (err) {
        console.log(err);
        return;
      }

      if (result) {
        setReservationPolicy(result[property.reservation_policy]);
      }
    });

    getRefundPolicy((err, result) => {
      if (err) {
        console.log(err);
        return;
      }

      if (result) {
        setRefundPolicy(result[property.refund_policy]);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // monitor the user so as to know whether we can display the affiliate
  // link sharing button
  useEffect(() => {
    if (!SAFARI_USER) return;
    // Confirm that the user has affiliate privilleges before trying to make
    // the affiliate button visible to them.
    const hasAffiliatePrivilleges = Auth.hasAuthLevel(
      SAFARI_USER.permissions, "affiliate",
    );

    setIsAffiliate(hasAffiliatePrivilleges);
  }, [SAFARI_USER]);

  return (
    <div className="property-container">
      <div className="property-container__left">
        <div className="mb-3">
          {renderSearchSection && renderSearchSection()}
        </div>
        <div className="policy__section__title">Policies</div>
        {/*
          Since these policies are fetched from the server, we have to make
          sure
          */}
          {refundPolicy && (
            <PolicyCard
              policy={refundPolicy}
            />
          )}

          {reservationPolicy && (
            <PolicyCard
              policy={reservationPolicy}
            />
          )}

          {policies && policies.map((policy, idx) => (
            <PolicyCard
              key={`policy_${idx}`}
              policy={policy}
            />
          ))}

      </div>
      <div className="property-container__main">
        <div
          className={
            "property-container__main__app-bar col-start-1 col-end-4 w-full " +
            "flex flex-row justify-between items-center"
          }
        >
          <div>
            <h2 className="text-2xl font-bold">
              {property.title}
            </h2>
            <p className="text-sm">
              {property.location}
            </p>
          </div>
          <div className="flex flex-row items-center">
            {isAffiliate && (
              <Tooltip title="Create an affiliate link" placement="bottom">
                <button
                  type="button"
                  className={
                    "h-9 px-4 text-white bg-secondary rounded-md " +
                    "flex flex-row items-center mr-3"
                  }
                  onClick={() => {
                    setOnOverlay && setOnOverlay(() => () => (
                      <AffiliateOverlay
                        // NOTE: Not know to thee typescript compiler but known
                        // to us:
                        // Since the user is already confirmed to be an affiliate
                        // the following things can be assumed to be true:
                        // The AppState.SAFARI_USER is not null
                        // The AppState.USER is not null since the USER.uid is
                        // what is used to fetch the SAFARI_USER from firebase
                        // realtime database and we have already ascertained
                        // that that is NOT NULL;
                        // This is why we are using the template string to beat
                        // the USER might be null error since WE KNOW for a fact
                        // that it is in fact not null
                        affiliateId={`${USER?.uid}`}
                        category={category as unknown as ReservationCategory}
                        property={{
                          gallery: property.gallery,
                          id: propertyId,
                          title: property.title,
                        }}
                      />
                    ));

                    setIsOverlay && setIsOverlay(true);
                  }}
                >
                  <BiLink size={24} className="mr-3" />
                  Affiliate Link
                </button>
              </Tooltip>
            )}
            <button
              className={
                "property-container__main__app-bar__button shadow-md " +
                "border border-safari-gold h-9 px-4 rounded-md"
              }
              onClick={() => {
                // scroll to the bookable property section
                bookPropertyRef.current && bookPropertyRef.current
                  .scrollIntoView({
                    behavior: "smooth",
                  });
              }}
            >
              Book Property
            </button>
          </div>
        </div>
        <div className="property-container__main__gallery">
          <NewGallery
            gallery={property.gallery}
          />
        </div>
        <p className="property-container__main__text">
          {property.description}
        </p>
        <div className="property-container__main__map overflow-hidden">
          <StaticGoogleMap
            size="500x200"
            apiKey="AIzaSyDxrJtbT4XhPoiNlXoOsNZ77j38ai9ZwAo"
            onClick={() => {
              if (showDirections) {
                // open another window showing directions

                if (
                  navigator.platform.indexOf("iPhone") !== -1 ||
                  navigator.platform.indexOf("iPod") !== -1 ||
                  navigator.platform.indexOf("iPad") !== -1
                )
                  window.open(
                    `maps://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${ 
                      property.coords.lat 
                      },${ 
                      property.coords.lng
                      }`
                  );
                else
                  window.open(
                    `https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${ 
                      property.coords.lat 
                      },${ 
                      property.coords.lng
                      }`
                  );
              }
            }}
          >
            <Marker.Group label="h" color="red">
              <Marker
                location={`${property.coords.lat},${property.coords.lng}`}
              />
            </Marker.Group>
          </StaticGoogleMap>
          {showDirections && (
            <p className="text-sm">Click on map to show directions</p>
          )}
        </div>
        <div ref={bookPropertyRef} style={{height: 1}} />
        {children}
      </div>
    </div>
  );
};

type SubPropertySectionProps = {
  title: string;
};

export const SubPropertySection: FC<SubPropertySectionProps> = ({
  title, children,
}) => {
  return (
    <div className="property-container__main__sub-property">
      <h3 className="sub-property__title">{title}</h3>
      <div className="sub-property__content">
        {/* Property Cards here */}
        {children}
      </div>
    </div>
  );
};

/**
 * This wraps the checkout component if it is going to be displayed in the main
 * screen.
 * @returns Checkout component
 */
export const CheckoutSection: FC = ({children}) => {
  return (
    <div className="sub-property__checkout">
      {children}
    </div>
  );
};

export default Container;