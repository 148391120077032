import {OnCompleteCallback, Picture} from "../..";
import {Tour} from "../../tour";

import {Draft} from "..";
import {getUser, NoUserFoundError} from "../../user";
import { uploadPictures } from "../../cdn";
import { firestore } from "firebase";

export const postTourDraft = (
  draftId: string | null, tour: Tour,
  onComplete: OnCompleteCallback<Draft<Tour>>,
): void => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);

    return;
  }

  if (draftId) {
    updateTourDraft(draftId, tour, onComplete);

    return;
  }

  // we fetch all the pictures from the tour facility itself, and also all
  // the pictues from the stops themselves.
  // We then create an array (Buffer) that records how many pictures we are
  // uploading for each, this will be used to get back all the pictures in order
  // of so as to re-order them back to their respective places without a hassle.
  //
  // E.g if we have a tour facility with 6 pictures of its own and three
  // tour stops with pictures of their own, then we can create the buffer
  // as follows:
  // buffer = [6, 6, 7, 8];
  // then an array of pictures to upload is created with a total of
  // (6 + 6 + 7 + 8) images, where:
  // they are ordered based on:
  // tour facility first, stops based on the index of the room,
  // As such, these pictures can be gotten back by splicing and then pushing
  // back in order of index starting with the tour facility as the first
  // index (0)

  const bufferSizes: number[] = [];
  const imageBuffer: Picture[] = [];

  bufferSizes.push(tour.gallery.length);
  imageBuffer.push(...tour.gallery);

  // push all the pictures for each room
  tour.stops.forEach((stop) => {
    bufferSizes.push(stop.gallery.length);
    imageBuffer.push(...stop.gallery);
  });


  // after all the images are pushed we can upload them all and then replace
  // after successful upload
  uploadPictures("tour", imageBuffer, async (err, uploadedImages) => {
    if (err) {
      onComplete(err, null);
      return;
    }

    if (uploadedImages) {
      tour.gallery = uploadedImages.splice(0, bufferSizes[0]);

      // starting from the second index, we start assigning the stops all their
      // images back
      for (let i=1; i<bufferSizes.length; i++) {
        tour.stops[i - 1].gallery = imageBuffer.splice(0, bufferSizes[i]);
      }

      // after all the images are uploaded we can now get to uploading the
      // confernce data
      try {
        const doc =  await firestore()
          .collection("draft")
          .add({...tour, owner: user.uid} as Tour);

        const docSnapshot = await doc.get();

        onComplete(null, ({
          id: docSnapshot.id,
          ...docSnapshot.data(),
        } as Draft<Tour>));
      } catch (err) {
        const tourUploadError =
          new Error("Error uploading tour facility details");
        onComplete(tourUploadError, null);
      }
      
    } else {
      // these images may be null
      const uploadErr = new Error("The images to be uploaded returned null");
      onComplete(uploadErr, null);
    }
  });
};

export const updateTourDraft = (
  id: string, tour: Tour, onComplete: OnCompleteCallback<Draft<Tour>>,
): void => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);

    return;
  }

  // we fetch all the pictures from the tour facility itself, and also all
  // the pictues from the stops themselves.
  // We then create an array (Buffer) that records how many pictures we are
  // uploading for each, this will be used to get back all the pictures in order
  // of so as to re-order them back to their respective places without a hassle.
  //
  // E.g if we have a tour facility with 6 pictures of its own and three
  // tour stops with pictures of their own, then we can create the buffer
  // as follows:
  // buffer = [6, 6, 7, 8];
  // then an array of pictures to upload is created with a total of
  // (6 + 6 + 7 + 8) images, where:
  // they are ordered based on:
  // tour facility first, stops based on the index of the room,
  // As such, these pictures can be gotten back by splicing and then pushing
  // back in order of index starting with the tour facility as the first
  // index (0)

  const bufferSizes: number[] = [];
  const imageBuffer: Picture[] = [];

  bufferSizes.push(tour.gallery.length);
  imageBuffer.push(...tour.gallery);

  // push all the pictures for each room
  tour.stops.forEach((stop) => {
    bufferSizes.push(stop.gallery.length);
    imageBuffer.push(...stop.gallery);
  });


  // after all the images are pushed we can upload them all and then replace
  // after successful upload
  uploadPictures("tour", imageBuffer, async (err, uploadedImages) => {
    if (err) {
      onComplete(err, null);
      return;
    }

    if (uploadedImages) {
      tour.gallery = uploadedImages.splice(0, bufferSizes[0]);

      // starting from the second index, we start assigning the stops all their
      // images back
      for (let i=1; i<bufferSizes.length; i++) {
        tour.stops[i - 1].gallery = imageBuffer.splice(0, bufferSizes[i]);
      }

      // after all the images are uploaded we can now get to uploading the
      // confernce data
      try {
        await firestore()
          .collection("draft")
          .doc(id)
          .update({draft: tour});

        const docSnapshot = await firestore().collection("draft")
          .doc(id)
          .get();

        onComplete(null, ({
          id: docSnapshot.id,
          ...docSnapshot.data(),
        } as Draft<Tour>));
      } catch (err) {
        const tourUploadError =
          new Error("Error uploading tour facility details");
        onComplete(tourUploadError, null);
      }
      
    } else {
      // these images may be null
      const uploadErr = new Error("The images to be uploaded returned null");
      onComplete(uploadErr, null);
    }
  });
};
