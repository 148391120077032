import React from "react";
// import ModalInput from '../../admin/screens/commonElements/ModalInput'
import {
  RiHotelLine,
  RiFlightTakeoffLine,
  RiHotelBedLine,
  RiShipLine,
  RiRestaurantLine,
  RiBuildingLine,
  RiCarLine,
  RiRoadMapLine,
  RiApps2Line,
} from "react-icons/ri";
// import ModuleEditor from '../../admin/screens/homeScreens/modules/_sharedelements/ModuleEditor'
import {connect} from "react-redux";
import {Link} from 'react-router-dom';

class App extends React.Component {
  state = {
    myitems: [],
    view: 0,
    modalShowing: false,
    moduleName: "activitie",
    moduleNameCapitalised: "ACTIVITY",
    moduleIcon: "conference",
    menu: [
      {
        title: "Hotels",
        pathname: "/property-owner/hotel",
        key: 0,
        active: true,
        icon: <RiHotelBedLine />,
      },
      {
        title: "Flights",
        pathname: "/property-owner/flight",
        key: 1,
        active: false,
        icon: <RiFlightTakeoffLine />,
      },
      {
        title: "Rentals",
        pathname: "/property-owner/rental",
        key: 2,
        active: false,
        icon: <RiHotelLine />,
      },
      {
        title: "Cruises",
        pathname: "/property-owner/cruise",
        key: 3,
        active: false,
        icon: <RiShipLine />,
      },
      {
        title: "Eateries",
        pathname: "/property-owner/eatery",
        key: 4,
        active: false,
        icon: <RiRestaurantLine />,
      },
      {
        title: "Conferencing",
        pathname: "/property-owner/conference",
        key: 5,
        active: false,
        icon: <RiBuildingLine />,
      },
      {
        title: "Cars",
        key: 7,
        pathname: "/property-owner/car",
        active: false,
        icon: <RiCarLine />,
      },
      {
        title: "Tours",
        key: 8,
        pathname: "/property-owner/tour",
        active: false,
        icon: <RiRoadMapLine />,
      },
      {
        title: "Activities",
        pathname: "/property-owner/activity",
        key: 9,
        active: false,
        icon: <RiApps2Line />,
      },
    ],
  };
  constructor(props) {
    super(props);
    this._changeView = this._changeView.bind(this);
    this._backToMainMenu = this._backToMainMenu.bind(this);
    this._onModalActionTrue = this._onModalActionTrue.bind(this);
  }
  componentDidMount() {
    /*switch (module) {
            case "hotel":
                this.setState({ moduleIcon: 'building' });
                break;
            case "restaurant":
                this.setState({ moduleIcon: 'restaurant' });
                break;
            case "venue":
                this.setState({ moduleIcon: 'conference' });
                break;
            case "tour":
                this.setState({ moduleIcon: 'tour_ic' });
                break;
            case "activity":
                this.setState({ moduleName: 'activitie' });
                this.setState({ moduleIcon: 'service_activity' });
                break;
            case "car":
                this.setState({ moduleIcon: 'cars' });
                break;
            case "rental":
                this.setState({ moduleIcon: 'house' });
                break;
            case "flight":
                this.setState({ moduleIcon: 'service_flight' });
                break;
            case "cruise":
                this.setState({ moduleIcon: 'service_cruise' });
                break;
        }*/
  }
  _changeView(newValue) {
    this.setState({
      moduleName: newValue.title,
      moduleNameCapitalised: newValue.title.toUpperCase(),
      moduleIcon: `service_${newValue.icon}`,
      modalShowing: true,
    });
  }
  _backToMainMenu() {
    this.setState({ modalShowing: false, viewType: 0 });
  }

  async _onModalActionTrue(response) { // eslint-disable-line no-unused-vars
    // this.setState({ loading: true });
    // this.setState({ modalShowing: false });
    // //create hotel
    // ////console.log(this.state.moduleName);
    // const nameCapitalized = this.state.moduleName.charAt(0).toUpperCase() + this.state.moduleName.slice(1);
    // ////console.log(nameCapitalized);
    // var uniqueId = Math.random().toString(10).substring(2) + Date.now().toString(10);
    // await fire.database().ref('Modules/Data/' + nameCapitalized + '/' + nameCapitalized + 'Lists/' + this.props.user.uid + '/' + uniqueId).set(
    //     {
    //         name: response.textValue,
    //         setupComplete: false,
    //         created: firebase.database.ServerValue.TIMESTAMP,
    //         modified: firebase.database.ServerValue.TIMESTAMP
    //     }
    // );
    // await fire.database().ref('Modules/Data/' + nameCapitalized + '/' + nameCapitalized + 'Lists/' + this.props.user.uid).orderByChild('modified').limitToFirst(100).once('value', async (snapshot) => {
    //     var itemArray = [];
    //     //var number=0;
    //     snapshot.forEach((snap) => {
    //         var value = snap.val();
    //         value['key'] = snap.key;
    //         value['date'] = this.state.selectedDate;
    //         itemArray.push(value);
    //     });
    //     itemArray.sort(function (a, b) {
    //         return a.modified - b.modified;
    //     });
    //     itemArray.reverse();
    //     this.setState({ myitems: itemArray });
    // });
    // //create document
    // await fire.firestore().collection(nameCapitalized + "s").doc(uniqueId).set({
    //     name: response.textValue.toUpperCase()
    // });
    // //
    // this.setState({ loading: false });
    // this.setState({ view: 1 });
  }
  render() {
    // if (this.state.view == 1) {
    //     return <ModuleEditor itemArray={this.state.myitems} selectedItem={this.state.myitems[0]} moduleName={this.state.moduleName.toLocaleLowerCase()} picture={this.state.moduleIcon} />
    // }
    return (
      <div style={{ overflow: "hidden" }}>
        {/* {this.state.modalShowing ? <ModalInput backToMain={this._backToMainMenu} onModalActionTrue={this._onModalActionTrue} title={"CREATE NEW " + this.state.moduleNameCapitalised} picture={this.state.moduleIcon} textInstructions={"Enter " + this.state.moduleNameCapitalised + " name"} /> : <></>} */}
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto auto",
            marginTop: 0,
          }}
        >
          {this.state.menu.map((item) => {
            return (
              <Link key={item.pathname} to={item.pathname}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: 130,
                    marginBottom: 25,
                  }}
                >
                  <div
                    style={{
                      fontSize: 65,
                      textAlign: "center",
                      height: 75,
                      color: "#ff4f5a",
                    }}
                  >
                    {item.icon}
                  </div>
                  <div style={{ textAlign: "center", color: "#111" }}>
                    {item.title}
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
        {/* <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto auto",
            padding: this.props.extra != "headerless" ? 20 : "50px 100px",
          }}
        >
          <RoomItem
            title="Hotel"
            icon="hotel"
            description="List your hotel and manage your bookings in realtime."
            price="1500"
            clickFun={this._changeView}
          />
          <RoomItem
            title="Restaurants"
            icon="restaurant"
            description="Allow your customers to access your catering services."
            price="1500"
            clickFun={this._changeView}
          />
          <RoomItem
            title="Conferencing"
            icon="conferencing"
            description="List your meeting spaces and manage bookings made."
            price="1500"
            clickFun={this._changeView}
          />
          <RoomItem
            title="Tour"
            icon="tour"
            description="Create various tours and allow your customers pay to enjoy"
            price="1500"
            clickFun={this._changeView}
          />
          <RoomItem
            title="Activity"
            icon="activity"
            description="List your hotel and manage your bookings in realtime."
            price="1500"
            clickFun={this._changeView}
          />
          <RoomItem
            title="Car"
            icon="car"
            description="List your car or cars and allow others to hire them."
            price="1500"
            clickFun={this._changeView}
          />
          <RoomItem
            title="Flight"
            icon="flight"
            description="Create flights and manage your bookings in realtime."
            price="1500"
            clickFun={this._changeView}
          />
          <RoomItem
            title="Cruises"
            icon="cruise"
            description="List your cruises and manage your bookings in realtime."
            price="1500"
            clickFun={this._changeView}
          />
        </div> */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
export default connect(mapStateToProps)(App);
