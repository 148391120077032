export const verifyReCAPTCHAToken = async (token: string): Promise<boolean> => {
  try {
    const response = await fetch(
      `https://us-central1-safarixpertz.cloudfunctions.net/recaptcha?token=${
        token
      }`,
      {
        method: "POST",
      },
    );

    if (response.status !== 200) return false;

    const result = (await response.json()) as boolean;

    return result;
  } catch (err) {
    console.log(err);

    return false;
  }
};
