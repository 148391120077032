import {fetchCompulsoryCarFilters} from "../../../../reducers";

export default function fetchCars(state, updateState) {
  const {place} = fetchCompulsoryCarFilters();

  updateState({
    rawResults: [],
    searchResults: [],
    searchItemsLength: 0,
    searchDone: true,
    category: "cars",
    city: place,
  });
}
