import {Dispatch, FC, SetStateAction, useState} from "react";
import {Formik} from "formik";
import {FaTimes} from "react-icons/fa";

import {
  Facility,
  Feature,
  ItemException,
  Picture,
  Policy,
} from "../../../api";
import {HotelRoom} from "../../../api/hotel/types";
import {Input, TextArea} from "../../../components/input";

import {
  EditSubPropertyFacilities, EditSubPropertyFeatures, SubPropertyAddImage,
  SubPropertyEditExceptions, SubPropertyEditPolicies,
} from ".";

import {validateHotelOverlayForm} from '../routes/new/hotel/validate-form';

type EditHotelRoomOverlayProps = {
  idx: number;
  item: HotelRoom;
  hotelRooms: HotelRoom[];
  setHotelRooms: Dispatch<SetStateAction<HotelRoom[]>>;
  removeOverlay: () => void;
};

export const EditHotelRoomOverlay: FC<EditHotelRoomOverlayProps> = ({
  idx, item, hotelRooms, removeOverlay, setHotelRooms,
}) => {
  const [images, setImages] = useState<Picture[]>(item.gallery);
  const [activeFacilities, setActiveFacilities] = useState<Facility[]>(item.facilities);
  const [activeFeatures, setActiveFeatures] = useState<Feature[]>(item.features);
  const [roomPolicies, setRoomPolicies] = useState<Policy[]>(item.room_policy);
  const [exceptions, setExceptions] = useState<ItemException[]>(
    item.exceptions ? item.exceptions : [],
  );

  const initialValues = {
    title: item.title,
    short_description: item.short_description,
    description: item.description,
    gallery: "",
    cardinality: `${item.cardinality}`,
    adults_only_capacity: `${item.capacity.adults_only}`,
    adults_and_children_capacity_adults: `${item.capacity.adults_and_children.adults}`,
    adults_and_children_capacity_children: `${item.capacity.adults_and_children.children}`,
    children_only_capacity: `${item.capacity.children_only}`,
    price: `${item.price}`,
    exceptions: "",
    room_policies: "",
    features: "",
    facilities: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount={true}
      validate={(values) => {
        // validate values
        return validateHotelOverlayForm({
          gallery: images,
          adults_and_children_capacity_adults:
            values.adults_and_children_capacity_adults,
          adults_and_children_capacity_children:
            values.adults_and_children_capacity_children,
          adults_only_capacity: values.adults_only_capacity,
          cardinality: values.cardinality,
          children_only_capacity: values.children_only_capacity,
          description: values.description,
          exceptions,
          facilities: activeFacilities,
          features: activeFeatures,
          price: values.price,
          room_policies: roomPolicies,
          short_description: values.short_description,
          title: values.title,
        });
      }}
      onSubmit={(values) => {
        const newHotelRooms = [...hotelRooms];
        // handle submission
        newHotelRooms.splice(idx, 1, {
          id: item.id,
          title: values.title,
          description: values.description,
          short_description: values.short_description,
          gallery: images,
          price: Number(values.price),
          room_policy: roomPolicies,
          cardinality: Number(values.cardinality),
          capacity: {
            adults_only: Number(values.adults_only_capacity),
            children_only: Number(values.children_only_capacity),
            adults_and_children: {
              adults: Number(values.adults_and_children_capacity_adults),
              children: Number(values.adults_and_children_capacity_children),
            },
          },
          facilities: activeFacilities,
          features: activeFeatures,
          accessibility: [], 
          exceptions,
        });
        setHotelRooms(newHotelRooms);
        removeOverlay();
      }}
    >
      {({
        values, errors, handleChange, handleSubmit, validateForm, setErrors,
      }) => (
        <form
          onSubmit={(e) => {
            // even though this step is not necessary, since the form is not
            // actually "nested in the Hotel form", it is just a step added for
            // precautionary reasons
            e.stopPropagation();
            handleSubmit(e);
          }}
        >
          <div
            className={
              "absolute top-0 right-0 bottom-0 w-full h-full sm:max-w-lg " +
              "sm:shadow-md overflow-scroll bg-white"
            }
          >
            {/* App bar */}
            <div
              className={
                "sticky top-0 bg-safari-gold z-10 h-14 flex flex-row " +
                "justify-between items-center overflow-hidden px-2 " +
                "sm:col-start-1 sm:col-end-4 shadow-md text-white"
              }
            >
              {/* Header Container */}
              <div
                className="overflow-hidden flex-grow-0 flex flex-row items-center"
              >
                {/* Close Overlay icon */}
                <FaTimes size={24} onClick={removeOverlay} />
                <div className="ml-3">
                  <h2
                    className={
                      "text-xl font-semibold overflow-ellipsis whitespace-nowrap"
                    }
                  >
                    New Room
                  </h2>
                  <p className="text-xs italic overflow-ellipsis whitespace-nowrap">
                    Enter room details
                  </p>
                </div>
              </div>
              {/* End of Header container */}

              {/* Edit Button */}
              <button
                type="submit"
                className={
                  `h-9 px-3 border rounded-md shadow-md ${
                    Object.keys(errors).length === 0 && values.title !== "" ?
                      "border-black text-black " :
                      "border-gray-700 text-gray-700 cursor-not-allowed"
                  } font-semibold text-sm flex items-center`
                }
                // since initially the errors are not present, we check the
                // title being empty to account for the initial render
                disabled={Object.keys(errors).length > 0 || values.title === ""}
              >
                Save
              </button>
              {/* End of edit Button */}
            </div>
            {/* End of app bar */}

            <div className="p-4">
              {/* start of text input container */}
              <div className="">
                <Input
                  helper="The name of this type of room (suite)"
                  id="room-name-input"
                  inputLabel="Room name"
                  name="title"
                  value={values.title}
                  error={errors.title}
                  onChange={handleChange}
                />
              </div>
              {/* End of text input container */}

              {/* start of text input container */}
              <div className="mt-3">
                <Input
                  id="cardinality-input"
                  helper="How many room are present of this type"
                  inputLabel="Number of rooms"
                  name="cardinality"
                  value={values.cardinality}
                  error={errors.cardinality}
                  onChange={handleChange}
                />
              </div>
              {/* End of text input container */}

              {/* start of text area container */}
              <div className="mt-3">
                <TextArea
                  id="room-description-long"
                  name="description"
                  helper="Long description of the room"
                  error={errors.description}
                  inputLabel="Room description (long)"
                  value={values.description}
                  onChange={handleChange}
                />
              </div>
              {/* End of text area container */}

              {/* start of text area container */}
              <div className="mt-3">
                <TextArea
                  id="room-description-short"
                  helper="AS brief description of the room"
                  inputLabel="Room description (short)"
                  name="short_description"
                  value={values.short_description}
                  error={errors.short_description}
                  onChange={handleChange}
                />
              </div>
              {/* End of text area container */}

              {/* Room Capacity Container */}
              <div className="px-4 py-2 my-2 shadow-md border rounded-md">
                <h6 className="text-xl font-semibold">Room Capacity Options</h6>
                <p className="pb-2 border-b-2 text-xs">
                  Enter capacity options based on the field
                </p>
                {/* start of text input container */}
                <div className="mt-3">
                  <Input
                    id="adult-only-input"
                    inputLabel="Adult only capacity"
                    name="adults_only_capacity"
                    value={values.adults_only_capacity}
                    error={errors.adults_only_capacity}
                    helper="How many adults can this room hold?"
                    onChange={handleChange}
                  />
                </div>
                {/* End of text input container */}

                {/* start of text input container */}
                <div className="mt-3">
                  <Input
                    id="children-only-input"
                    helper="How many children (only) can this room hold"
                    inputLabel="Children only capacity"
                    name="children_only_capacity"
                    value={values.children_only_capacity}
                    error={errors.children_only_capacity}
                    onChange={handleChange}
                  />
                </div>
                {/* End of text input container */}

                {/* start of text input container */}
                <div className="mt-3">
                  <p className="text-xs">
                    How many adults and children (combined) can this room hold
                  </p>
                  <div className="mt-2 grid grid-cols-2">
                    <Input
                      helper="adults and children (combined) this room can hold"
                      inputLabel="Adults"
                      id="adults-and-children-capacity-adults"
                      name="adults_and_children_capacity_adults"
                      value={values.adults_and_children_capacity_adults}
                      error={errors.adults_and_children_capacity_adults}
                      onChange={handleChange}
                    />
                    <Input
                      helper=""
                      inputLabel="Children"
                      id="adults-and-children-capacity-children"
                      name="adults_and_children_capacity_children"
                      value={values.adults_and_children_capacity_children}
                      error={errors.adults_and_children_capacity_children}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {/* End of text input container */}
              </div>
              {/* End of Room Capacity Container */}

              {/* Room Price Container */}
              <div className="px-4 py-2 my-2 shadow-md border rounded-md">
                <h6 className="text-xl font-semibold">Room Price Options</h6>
                <p className="pb-2 border-b-2 text-xs">
                  Enter price options for the room
                </p>
                {/* start of text input container */}
                <div className="mt-3">
                  <Input
                    id="room-price-container"
                    helper="What is the price of this room per night"
                    inputLabel="Price per night (in US Dollars)"
                    name="price"
                    value={values.price}
                    error={errors.price}
                    onChange={handleChange}
                  />
                </div>
                {/* End of text input container */}
              </div>
              {/* End of Room Price Container */}

              {/* Image Addition container */}
              <SubPropertyAddImage
                images={images}
                threshold={3}
                setImages={async (updatedImages) => {
                  setImages(updatedImages);

                  setErrors(await validateForm());
                }}
              />
              {errors.gallery && (
                <p className="text-red-500 text-xs ml-2">
                  {errors.gallery}
                </p>
              )}
              {/* End of image add container */}

              {/* Add Facilities Container */}
              <EditSubPropertyFacilities
                category="hotel"
                facilities={activeFacilities}
                headerSubtitle="Add the facilities contained in your hotel room"
                setFacilities={async (updatedFacilities) => {
                  setActiveFacilities(updatedFacilities);

                  setErrors(await validateForm());
                }}
              />
              {errors.facilities && (
                <p className="text-xs text-red-500 ml-2">
                  {errors.facilities}
                </p>
              )}
              {/* End of add facilities container */}

              {/* Features container */}
              <EditSubPropertyFeatures
                category="hotel"
                features={activeFeatures}
                setFeatures={async (updatedFeatures) => {
                  setActiveFeatures(updatedFeatures);

                  setErrors(await validateForm());
                }}
              />
              {errors.features && (
                <p className="text-xs text-red-500 ml-2">
                  {errors.features}
                </p>
              )}
              {/* End of features container */}

              {/* End of Policy container */}
              <SubPropertyEditPolicies
                policies={roomPolicies}
                setPolicies={async (updatedPolicies) => {
                  setRoomPolicies(updatedPolicies);

                  setErrors(await validateForm());
                }}
              />
              {errors.room_policies && (
                <p className="text-xs text-red-500 ml-2">
                  {errors.room_policies}
                </p>
              )}
              {/* End of policy edit container */}

              {/* End of Policy container */}
              <SubPropertyEditExceptions
                exceptions={exceptions}
                setExceptions={async (updatedExceptions) => {
                  setExceptions(updatedExceptions);

                  setErrors(await validateForm());
                }}
              />
              {errors.exceptions && (
                <p className="text-xs text-red-500 ml-2">
                  {errors.exceptions}
                </p>
              )}
              {/* End of policy edit container */}
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};
