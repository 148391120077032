import { FC, useEffect } from "react";
import Lottie from "react-lottie";

import animation from "../../../assets/anim/plane.json";
import { OnOverlayCallback } from "../../../context/overlay-provider-context";

type UploadingOverlayProps = {
  caption: string;
  setIsOverlay?: (isOverlay: boolean) => void;
  setOnOverlay?: OnOverlayCallback;
  setIsCancellable?: (isCancellable: boolean) => void;
};

export const UploadingOverlay: FC<UploadingOverlayProps> = ({
  setIsOverlay, setOnOverlay, setIsCancellable, caption,
}) => {
  useEffect(() => {
    setIsCancellable && setIsCancellable(false);

    setOnOverlay &&
      setOnOverlay(() => () => (
        <div
          className={
            "justify-self-center flex flex-col items-center justify-center sm:max-w-sm " +
            "bg-white p-4 rounded-md shadow-md"
          }
        >
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animation,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={300}
            width={300}
          />
          <h6 className="text-2xl">{caption}</h6>
        </div>
      ));

    setIsOverlay && setIsOverlay(true);

    return () => {
      setIsOverlay && setIsOverlay(false);
      setOnOverlay && setOnOverlay(null);
      setIsCancellable && setIsCancellable(false);
    };
  }, []);


  return <></>;
};
