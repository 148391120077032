import {useState} from 'react';
import type {FC, FormEventHandler} from 'react';
import {FaWindowClose} from 'react-icons/fa';
import {useHistory} from 'react-router-dom';

import {fetchCompulsoryRentalFilters} from '../../../../reducers';
import {Dropdown} from '../../../../components';

import {SectionHeader} from "../section-header";

import {MobileFilterContext} from '../../context';

import {AutoCompletePlaces} from './auto-complete-places';
import {RangeInput} from './range-input';
import {SubmitButton} from './submit-button';
import { DateUtil } from '../../../../util';

type RentalProps = {
  setRawResultsStale?: (resultsStale: boolean) => void;
};

export const Rental: FC<RentalProps> = ({setRawResultsStale}) => {
  const compulsoryFilters = fetchCompulsoryRentalFilters();
  const [coords, setCoords] = useState({
    lat: compulsoryFilters.lat, lng: compulsoryFilters.lng,
  });

  const history = useHistory();

  const selectAddress = (
    place: string, newCoords: {lat: number, lng: number},
  ) => {
    setCoords(newCoords);
  };
  const url = new URL(location.href);

  // the search values used in the search fields
  const [place, setPlace] = useState(compulsoryFilters.place);
  const [checkin, setCheckin] = useState(compulsoryFilters.checkin);
  const [checkout, setCheckout] = useState(
    compulsoryFilters.checkout);
  const [adults, setAdults] = useState(compulsoryFilters.adults);
  const [children, setChildren] = useState(compulsoryFilters.children);

  // handle form submission
  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    console.log({place, checkin, checkout, adults, children, coords});
    // set all the compulsory values in the url and then start linking
    url.searchParams.set("place", place);
    url.searchParams.set("lat", `${coords.lat}`);
    url.searchParams.set("lng", `${coords.lng}`);
    if (checkin !== null && checkout !== null) {
      url.searchParams.set("checkin", checkin);
      url.searchParams.set("checkout", checkout);
    }
    url.searchParams.set("adults", `${adults}`);
    url.searchParams.set("children", `${children}`);

    // remove all optionalFilters
    url.searchParams.delete("feature");
    url.searchParams.delete("star_rating");
    url.searchParams.delete("theme");
    url.searchParams.delete("price_lowest");
    url.searchParams.delete("price_highest");

    history.push(`${url.pathname}${url.search}`);
    setRawResultsStale && setRawResultsStale(true);
  };

  return (
    <div
      className={
        "elevate-xs small-search-bar z-10 relative transition-all ease-linear" +
        " duration-300 bg-gradient-to-tr from-start to-end rounded-md " +
        "mb-5 lg:sticky lg:top-0"
      }
    >
      <SectionHeader
        style={{
          borderTop: 0,
          color: "white",
          borderBottomColor: "white",
        }}
      >
        Edit Search
      </SectionHeader>
      <MobileFilterContext.Consumer>
        {({ newSearchFilter, setNewSearchFilterVisible }) => (
          <div
            className="absolute top-2 right-2 md:hidden"
            onClick={() => {
              setNewSearchFilterVisible && setNewSearchFilterVisible(false);
            }}
          >
            {newSearchFilter && <FaWindowClose size={28} color="white" />}
          </div>
        )}
      </MobileFilterContext.Consumer>
      <div style={{ padding: 12 }}>
        <form onSubmit={handleSubmit}>
          <label htmlFor="place">Place to visit</label>
          <AutoCompletePlaces
            name="place"
            setPlace={setPlace}
            placeholder="Where to go"
            value={place === null ? "" : place}
            selectAddress={selectAddress}
          />
          <RangeInput
            checkinLabel="Check in date"
            checkinName="checkin"
            checkinDefaultValue={
              checkin
                ? checkin
                : DateUtil.changeDateFormatFromDate(new Date(), "DD-MM-YYYY")
            }
            setCheckin={setCheckin}
            checkoutLabel="Check out date"
            checkoutName="checkout"
            checkoutDefaultValue={
              checkout
                ? checkout
                : DateUtil.changeDateFormatFromDate(new Date(), "DD-MM-YYYY")
            }
            setCheckout={setCheckout}
          />
          <Dropdown
            defaultValue={adults === null ? 1 : adults}
            label="Number of Adults"
            name="adults"
            options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((adultNumber) => ({
              title: adultNumber === 1 ? `1 adult` : `${adultNumber} adults`,
              value: adultNumber,
            }))}
            onChange={(e) => setAdults(Number(e.target.value))}
          />
          <Dropdown
            defaultValue={children === null ? 0 : children}
            label="Number of Children"
            name="children"
            options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
              (childrenNumber) => ({
                title:
                  childrenNumber !== 1
                    ? `${childrenNumber} children`
                    : "1 child",
                value: childrenNumber,
              })
            )}
            onChange={(e) => setChildren(Number(e.target.value))}
          />
          <SubmitButton
            disabled={
              place === compulsoryFilters.place &&
              checkin === compulsoryFilters.checkin &&
              checkout === compulsoryFilters.checkout &&
              adults === compulsoryFilters.adults &&
              children === compulsoryFilters.children
            }
          />
        </form>
      </div>
    </div>
  );
}
