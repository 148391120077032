// @ts-check
import React from "react";
import {
  FaPlane,
  FaChevronDown,
  FaWheelchair,
  FaPlus,
  FaCalendarAlt,
  FaUser,
  FaMinus,
  FaCircleNotch,
} from "react-icons/fa";
import {AiOutlineSwap} from "react-icons/ai";
import {Link} from 'react-router-dom';
import moment from "moment";

import LocationSearchInput from "./LocationSearchInput";
import {DatePick, RangePick} from "./RangePicker";

export default class Activities extends React.Component {
  constructor(props) {
    super(props);

    let from = "";
    let to = "";
    let departure = "checkin";
    let returnDate = "checkout";

    if (props.origin === "trip-planner") {
      from =
        /[A-Za-z0-9]+-_[A-Za-z0-9]+/g
          .test(this.props.sharedState.trip_data.trip_origin) ?
            this.props.sharedState.trip_data.trip_origin.replace("-_", ", ") :
            this.props.sharedState.trip_data.trip_origin;
      to =
        /[A-Za-z0-9]+-_[A-Za-z0-9]+/g
          .test(this.props.sharedState.trip_data.trip_destination) ?
            this.props.sharedState.trip_data.trip_destination.replace("-_", ", ") :
            this.props.sharedState.trip_data.trip_destination;

      departure = this.props.sharedState.trip_data.startDate;
      returnDate = this.props.sharedState.trip_data.returnDate;
    }

    this.state = {
      from,
      to,
      departure,
      return: returnDate,
      type: "round-trip",
      seat: "economy",
      adults: 1,
      children: 0,
      defaultOrigin: "",
      defaultDestination: to,
      locationReady: true,
      title: "Book your next flight",
    };
  }

  _showError = () => {
    alert("Entter the flight information to proceed!");
  };

  // eslint-disable-next-line no-unused-vars
  _selectToAddress = (place, latLng) => {
    // const city = place.replace(/ /g, "_").replaceAll(",", "-");
    const city = place;
    this.setState({ to: city });
  };

  // eslint-disable-next-line no-unused-vars
  _selectFromAddress = (place, latLng) => {
    //console.log(place);
    // const city = place.replace(/ /g, "_").replaceAll(",", "-");
    const city = place;
    this.setState({ from: city });
  };

  _showDatePicker = () => {
    this.setState({ rangePickerVisible: false, datePickerVisible: true });
  };

  _showRangePicker = () => {
    this.setState({ datePickerVisible: false, rangePickerVisible: true });
  };

  _selectRange = (date) => {
    const formattedStartDate = moment(date[0].startDate).format("DD-MM-YYYY");
    const formattedReturnDate = moment(date[0].endDate).format("DD-MM-YYYY");
    //console.log(startDate, formattedDate);
    let minimumDate = date[0].startDate;
    minimumDate.setDate(minimumDate.getDate() + 1);
    this.setState({
      rangePickerVisible: false,
      departure: formattedStartDate,
      return: formattedReturnDate,
      minimumDate,
    });
  };

  _selectDate = (date) => {
    if (!this.state.isStartDate) {
      const formattedReturnDate = moment(date).format("DD-MM-YYYY");
      this.setState({ datePickerVisible: false, return: formattedReturnDate });
    }
    if (this.state.isStartDate) {
      let minimumDate = date;
      minimumDate.setDate(minimumDate.getDate() + 1);
      const formattedStartDate = moment(date).format("DD-MM-YYYY");
      this.setState({
        datePickerVisible: false,
        departure: formattedStartDate,
        minimumDate,
      });
    }
  };

  _openSearch = (address) => {
    window.location = address;
  };

  render() {
    return (
      <div className="flex-div">
        <div className="tab-content">
          <h1 className="tab-caption">{this.state.title}</h1>
          <div className="flex-div flex-row mobile-14 desktop-pad-top-37">
            <div className="dropdown">
              <div style={{ color: "white", cursor: "pointer" }}>
                <FaCircleNotch
                  style={{ fontSize: 11, marginRight: 10 }}
                  className="hidden sm:inline"
                />
                <span style={{ textTransform: "capitalize" }}>
                  {this.state.type}{" "}
                </span>
                <FaChevronDown
                  style={{ fontSize: 11, marginLeft: 10, marginRight: 10 }}
                  className="inline"
                />
              </div>
              <div className="dropdown-content">
                <p
                  className="title"
                  onClick={() => this.setState({ type: "round-trip" })}
                >
                  Round-trip
                </p>
                <p
                  className="title"
                  onClick={() => this.setState({ type: "one-way" })}
                >
                  One-way
                </p>
              </div>
            </div>
            <div className="dropdown">
              <div style={{ color: "white", cursor: "pointer" }}>
                <FaWheelchair
                  style={{ fontSize: 11, marginRight: 10 }}
                  className="hidden sm:inline"
                />
                <span style={{ textTransform: "capitalize" }}>
                  {this.state.seat}{" "}
                </span>
                <FaChevronDown
                  className="inline"
                  style={{ fontSize: 11, marginLeft: 10, marginRight: 10 }}
                />
              </div>
              <div className="dropdown-content">
                <p
                  className="title"
                  onClick={() => this.setState({ seat: "economy" })}
                >
                  Economy
                </p>
                <p
                  className="title"
                  onClick={() => this.setState({ seat: "business" })}
                >
                  Business
                </p>
                <p
                  className="title"
                  onClick={() => this.setState({ seat: "first-class" })}
                >
                  First Class
                </p>
              </div>
            </div>
            <div className="dropdown">
              <div className="flex-div flex-row">
                <div
                  style={{ color: "white", cursor: "pointer", marginRight: 10 }}
                >
                  <FaUser
                    style={{ fontSize: 11, marginRight: 10 }}
                    className="hidden sm:inline"
                  />
                  <span>
                    {this.state.adults} Adult{this.state.adults > 1 ? "s" : ""}{" "}
                  </span>
                </div>
                <div style={{ color: "white", cursor: "pointer" }}>
                  <span>
                    {this.state.children} Child
                    {this.state.children > 1 ? "ren" : ""}{" "}
                  </span>
                  <FaChevronDown
                    className="inline"
                    style={{ fontSize: 11, marginLeft: 10 }}
                  />
                </div>
              </div>
              <div className="dropdown-content">
                <div>
                  <div className="title">Adults</div>
                  <div className="flex-div flex-row">
                    <div
                      className="circle-but"
                      onClick={() => {
                        let newNum = this.state.adults;
                        if (newNum != 0) {
                          newNum--;
                        }
                        this.setState({ adults: newNum });
                      }}
                    >
                      <FaMinus />
                    </div>
                    <div className="num">
                      <b>{this.state.adults}</b>
                    </div>
                    <div
                      className="circle-but"
                      onClick={() => {
                        let newNum = this.state.adults;
                        if (newNum < 10) {
                          newNum++;
                        }
                        this.setState({ adults: newNum });
                      }}
                    >
                      <FaPlus />
                    </div>
                  </div>
                  <div className="title">Children</div>
                  <div className="flex-div flex-row">
                    <div
                      className="circle-but"
                      onClick={() => {
                        let newNum = this.state.children;
                        if (newNum != 0) {
                          newNum--;
                        }
                        this.setState({ children: newNum });
                      }}
                    >
                      <FaMinus />
                    </div>
                    <div className="num">
                      <b>{this.state.children}</b>
                    </div>
                    <div
                      className="circle-but"
                      onClick={() => {
                        let newNum = this.state.children;
                        if (newNum < 10) {
                          newNum++;
                        }
                        this.setState({ children: newNum });
                      }}
                    >
                      <FaPlus />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-input-fields">
          <div style={{ flex: 1 }} className="tab-input-fields-content">
            <div
              className="flex-div flex-row tab-location-search-input"
              style={{
                borderRadius: 0,
                padding: 6,
                background: "white",
                flex: 1,
                alignItems: "center",
              }}
            >
              <FaPlane size={28} color="#666" />
              {this.state.locationReady && (
                <LocationSearchInput
                  style={{
                    border: "0px solid #ccc",
                    fontSize: 24,
                    paddingLeft: 10,
                    width: "90%",
                    position: "relative",
                  }}
                  defaultValue={this.state.from}
                  selectAddress={this._selectFromAddress}
                  placeholder="From"
                  onChange={(e) => {
                    this.setState({from: e.target.value});
                  }}
                  suggestionsContainerStyle={{}}
                />
              )}
            </div>
            <div
              className="flex-div flex-row mobile-hidden"
              style={{
                borderRadius: "50%",
                padding: 6,
                background: "white",
                marginLeft: 10,
                marginRight: 10,
                alignItems: "center",
              }}
            >
              <AiOutlineSwap size={28} color="#666" />
            </div>
            <div
              className="flex-div flex-row"
              style={{
                borderRadius: 0,
                padding: 6,
                background: "white",
                flex: 1,
                alignItems: "center",
              }}
            >
              <FaPlane size={28} color="#666" />
              {this.state.locationReady && (
                <LocationSearchInput
                  defaultValue={this.state.defaultDestination}
                  selectAddress={this._selectToAddress}
                  placeholder="To"
                  style={{
                    border: "0px solid #ccc",
                    fontSize: 24,
                    paddingLeft: 10,
                    width: "90%",
                  }}
                  onChange={(e) => {
                    this.setState({to: e.target.value});
                  }}
                  suggestionsContainerStyle={{}}
                />
              )}

              {/* <input
                onChange={(event) => {
                  this.setState({
                    Departure: event.target.value.toLocaleLowerCase(),
                  });
                }}
                placeholder="To"
                style={{
                  border: "0px solid #ccc",
                  fontSize: 24,
                  paddingLeft: 10,
                  width: 160,
                }}
              /> */}
            </div>
            <div style={{ flex: 1, display: "flex", position: "relative" }}>
              <div
                className="flex-div flex-row tab-date-input-parent items-center"
              >
                <FaCalendarAlt size={28} color="#666" />
                <div
                  onClick={() => {
                    this.setState({ isStartDate: true });
                    // if (this.state.return == "checkout") {
                    this._showRangePicker();
                    //     this._showDatePicker()
                    // }} else {
                  }}
                  className="tab-date-input"
                >
                  {this.state.departure}
                </div>
              </div>
              {this.state.type == "round-trip" && (
                <div
                  className="flex-div flex-row tab-date-input-parent items-center"
                >
                  <FaCalendarAlt size={28} color="#666" />
                  <div
                    onClick={() => {
                      this.setState({ isStartDate: false });
                      if (this.state.return == "checkout") {
                        this._showRangePicker();
                      } else {
                        this._showDatePicker();
                      }
                    }}
                    className="tab-date-input"
                  >
                    {this.state.return}
                  </div>
                </div>
              )}
              {this.state.rangePickerVisible && (
                <RangePick
                  selectDate={this._selectRange}
                  minDate={new Date()}
                  showDatePicker={(rangePickerVisible) => {
                    this.setState({ rangePickerVisible });
                  }}
                />
              )}
              {this.state.datePickerVisible && (
                <>
                  {this.state.return == "checkout" || this.state.isStartDate ? (
                    <DatePick
                      selectDate={this._selectDate}
                      minDate={new Date()}
                      showDatePicker={(datePickerVisible) => {
                        this.setState({ datePickerVisible });
                      }}
                    />
                  ) : (
                    <DatePick
                      selectDate={this._selectDate}
                      minDate={this.state.minimumDate}
                      showDatePicker={(datePickerVisible) =>
                        this.setState({ datePickerVisible })
                      }
                    />
                  )}
                </>
              )}
            </div>
          </div>
          {this.state.from.length == 0 || this.state.to.length == 0 ? (
            <div>
              <div
                onClick={this._showError}
                className="flex-div but  but-orange index-search-but"
              >
                SEARCH
              </div>
            </div>
          ) : (
            <>
              {this.props.origin === "trip-planner" ? (
                <div>
                  <a
                    onClick={() =>
                      this._openSearch(
                        `${
                            window.location.pathname
                          }?from=${
                            this.state.from
                          }&to=${
                            this.state.to
                          }&departure=${
                            this.state.departure
                          }&return=${
                            this.state.return
                          }&type=${
                            this.state.type
                          }&seat=${
                            this.state.seat
                          }&adults=${
                            this.state.adults
                          }&children=${
                            this.state.children
                          }#search-results-flights`
                      )
                    }
                  >
                    <div className=" flex-div but but-orange index-search-but">
                      SEARCH
                    </div>
                  </a>
                </div>
              ) : (
                <Link
                  to={`/search/flight?from=${this.state.from}&to=${
                    this.state.to
                  }&departure=${this.state.departure}&return=${
                    this.state.return
                  }&type=${this.state.type}&seat=${this.state.seat}&adults=${
                    this.state.adults
                  }&children=${this.state.children}`}
                >
                  <div className=" flex-div but but-orange index-search-but">
                    SEARCH
                  </div>
                </Link>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}
