import {database, User} from "firebase";

export const finishRegistration = async (
  userObj: User,
): Promise<void> => {
  // finsh up sign up/login by making sure that the user ref exists, if not:
  // create the user ref
  const ref = database().ref(`Users_Master/${userObj.uid}`);

  const snapshot = await ref.once("value");

  // if the snapshot does not exist, continue to creating the document
  if (!snapshot.exists()) {
    ref
      .set({
        authLevel: "user",
        dateJoined: database.ServerValue.TIMESTAMP,
        fNLName: userObj.displayName,
        username: userObj.displayName,
        email: userObj.email,
        phone: userObj.phoneNumber,
        registrationComplete: true,
      });
  }
};
