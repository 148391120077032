import {FC, useState} from 'react';
import {RouteComponentProps} from 'react-router';
import {IoIosWallet} from "react-icons/io";

import {PartnerHeader} from "../../components"
import { RiPhoneLine, RiWallet3Line } from 'react-icons/ri';
import { BiStats } from 'react-icons/bi';

export const Wallet: FC<RouteComponentProps> = ({
  match,
}) => {
  const [balance, setBalance] = useState<number>(0);

  const requestWithdrawal = () => {
    console.info("Requesting withdrawal");
  };

  return (
    <div>
      <PartnerHeader
        HeaderIcon={IoIosWallet}
        title="Wallet"
      />

      {/* Wallet content */}
      <div className="p-4 lg:flex lg:flex-row">
        {/* Withdraw request container */}
        <div className="w-full flex-shrink-0 mb-3 sm:grid sm:grid-cols-2 sm:gap-4 sm:w-full lg:block lg:w-96">
          <div
            className="elevate-xs p-3 rounded-sm border bg-white"
            style={{
              flex: 1,
            }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ margin: 10 }}>
                <RiWallet3Line
                  style={{ fontSize: 70, color: "#0a7fb5" }}
                />
              </div>
              <div style={{ paddingTop: 10 }}>
                <span style={{ fontSize: 15, fontWeight: "bold" }}>
                  WITHDRAWABLE AMOUNT
                </span>
                <br />
                <span style={{ fontSize: 29, fontWeight: "bold" }}>
                  $ {balance}
                </span>
              </div>
            </div>
            <div style={{ fontSize: 12, padding: 10 }}>
              You can withdraw your money to your m-pesa phone number. Your
              transaction will be processed within 24-48 working hours.
              <br />
              Note that this amount is subject to commissions
            </div>
            <div
              className="checkout-but"
              style={{
                margin: 0,
                marginTop: 15,
                borderRadius: 0,
                background:
                  "linear-gradient(to left, #ff735c, rgb(255,64,129))",
              }}
              onClick={requestWithdrawal}
            >
              Request Withdrawal
            </div>
          </div>
          <div
            className="bg-white shadow-md mt-5 sm:mt-0 lg:mt-5 rounded-sm border flex flex-row p-3 items-center sm:shadow-none lg:shadow-md"
            style={{
              flex: 1,
            }}
          >
            <div style={{ margin: 10 }}>
              <RiPhoneLine style={{ fontSize: 70, color: "#ccc" }} />
            </div>
            <div>
              <div style={{ fontSize: 15, marginTop: 15 }}>
                YOUR MPESA PHONE
              </div>
              <span style={{ fontSize: 23, fontWeight: "bold" }}>
                +254718870875
              </span>
            </div>
          </div>
        </div>
        {/* End of withdraw request container */}
        {/* Transaction History */}
        <div className="lg:border-l lg:ml-4 lg:pl-4 lg:flex-grow">
          <div
            className="bg-white shadow-md p-4 border"
            style={{
              background: "white",
              height: 450,
            }}
          >
            <div style={{ fontSize: 15, fontWeight: "bold" }}>
              <BiStats style={{ fontSize: 20 }} /> Transaction History
            </div>
          </div>
        </div>
        {/* End of transactino History */}
      </div>
      {/* End of wallet content */}

    </div>
  );
};
