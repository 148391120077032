import {Carousel} from "antd";
import {FC, useRef} from "react";
import {BiHotel} from "react-icons/bi";
import {FaChevronRight} from "react-icons/fa";

import {AttractionSite, Region} from "../../../api/attraction/types";

import regions from '../../../util/regions.json';

type AttractionDetailsProps = {
  attraction: AttractionSite;
  swtichToNearbyTab?: () => void;
};

export const AttractionDetails: FC<AttractionDetailsProps> = ({
  attraction, swtichToNearbyTab,
}) => {
  const attractionContainerRef = useRef<HTMLDivElement>(null);
  const getCountryShortCode = () => {
    if (attraction) {
      const [country] = regions[attraction?.region as Region].filter((country) => (
        country.name === attraction?.country
      ));
  
      return country.code.toLowerCase();
    } else {
      return "ke";
    }
  };

  const carouselWidth = window.innerWidth < 640 ? window.innerWidth:
    (window.innerWidth < 1024) ? window.innerWidth - 384 : 1024 - 384;

  return (
    <div>
      <div className="h-72 relative">
        <div
          className={
            "z-50 sm:hidden absolute top-4 right-4 h-9 px-3 shadow-md " +
            "bg-white font-semibold flex items-center rounded-2xl " +
            "bg-gradient-to-r from-secondary-end to-secondary " +
            "text-white animate-bounce"
          }
          onClick={() => {
            swtichToNearbyTab && swtichToNearbyTab();
          }}
        >
          <BiHotel size={24} className="mr-3" />
          Nearby properties
          <FaChevronRight className="ml-3" />
        </div>
        <div ref={attractionContainerRef} className="block h-72" style={{width: carouselWidth}}>
          <Carousel autoplay dotPosition="top">
            {
              attraction.gallery.map((picture, idx) => (
                <div
                    key={`carousel_picture_${idx}`}
                >
                  <div
                    className="h-72 bg-no-repeat bg-cover bg-center bg-indigo-200"
                    style={{
                      backgroundImage: `url("${picture.url}")`,
                      width: carouselWidth,
                    }}
                  />
                </div>
              ))}
          </Carousel>
        </div>
        <div className="px-4 absolute bg-black bg-opacity-50 bottom-0 py-2 w-full">
          <span className="flex flex-col items-center">
            <h6 className="text-3xl font-bold text-center text-white">
              {attraction?.name}
            </h6>
            <img
              className="inline ml-2 mt-1"
              src={`https://flagcdn.com/16x12/${
                getCountryShortCode()
              }.png`}
              alt={`${attraction?.country}`}
            />
          </span>
        </div>
      </div>
      <div className="p-4">
        {attraction?.description && (
          <p className="" style={{fontFamily: "Merriweather"}}>
            {attraction.description}
          </p>
        )}

        {attraction.content && attraction.content.filter((content) => content.content !== "").map((content, idx) => (
          <div key={content.title}>
            {content.content && (
              <div className=" ">
                <h6 className="text-2xl font-semibold mb-2">
                  {content.title}
                </h6>
                <div
                  className="lg:min-h-36"
                >
                  <img
                    className={`hidden sm:block m-2 mt-0 h-36 w-36 flex-shrink-0 ${
                      idx % 2 === 0 ? "float-left ml-0" : "float-right mr-0"
                    }`}
                    src={attraction.gallery[idx % attraction.gallery.length].url}
                    alt="National park"
                  />
                  <p style={{fontFamily: "Merriweather"}}>
                    {content.content}
                  </p>
                </div>
                <br />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
