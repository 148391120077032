import {useState} from "react";
import {FaClipboard, FaFilter, FaShareAlt} from "react-icons/fa";
import useOnclickOutside from "react-cool-onclickoutside";

import {
  fetchCompulsoryActivitiesFilters,
  fetchCompulsoryCarFilters,
  fetchCompulsoryConferencingFilters,
  fetchCompulsoryCruiseFilters,
  fetchCompulsoryEateriesFilters,
  fetchCompulsoryFilters,
  fetchCompulsoryFlightFilters,
  fetchCompulsoryRentalFilters,
  fetchCompulsoryToursFilters,
  fetchOptionalActivitiesFilters,
  fetchOptionalCarFilters,
  fetchOptionalConferencingFilters,
  fetchOptionalCruiseFilters,
  fetchOptionalEateriesFilters,
  fetchOptionalFilters,
  fetchOptionalFlightFilters,
  fetchOptionalRentalFilters,
  fetchOptionalToursFilters,
} from "../../../reducers";

import {SectionHeader} from "../components";

export function ShareButton({category}) {
  const [shareSheetVisible, setShareSheetVisible] = useState(false);
  const shareButtonRef = useOnclickOutside(() => setShareSheetVisible(false));

  let compulsoryFilters = {};
  let optionalFilters = {};

  switch (category) {
    case "conferencing":
      compulsoryFilters = fetchCompulsoryConferencingFilters();
      optionalFilters = fetchOptionalConferencingFilters();
      break;
    case "hotel":
      compulsoryFilters = fetchCompulsoryFilters();
      optionalFilters = fetchOptionalFilters();
      break;
    case "flight":
      compulsoryFilters = fetchCompulsoryFlightFilters();
      optionalFilters = fetchOptionalFlightFilters();
      break;
    case "rental":
      compulsoryFilters = fetchCompulsoryRentalFilters();
      optionalFilters = fetchOptionalRentalFilters();
      break;
    case "cruises":
      compulsoryFilters = fetchCompulsoryCruiseFilters();
      optionalFilters = fetchOptionalCruiseFilters();
      break;
    case "eateries":
      compulsoryFilters = fetchCompulsoryEateriesFilters();
      optionalFilters = fetchOptionalEateriesFilters();
      break;
    case "cars":
      compulsoryFilters = fetchCompulsoryCarFilters();
      optionalFilters = fetchOptionalCarFilters();
      break;
    case "tours":
      compulsoryFilters = fetchCompulsoryToursFilters();
      optionalFilters = fetchOptionalToursFilters();
      break;
    case "activities":
      compulsoryFilters = fetchCompulsoryActivitiesFilters();
      optionalFilters = fetchOptionalActivitiesFilters();
      break;
    default:
      console.error("Unidentified category searched for");
      break;
  }

  const compulsoryFilterKey = Object.keys(compulsoryFilters);
  const optionalFiltersKey = Object.keys(optionalFilters);

  return (
    <div
      ref={shareButtonRef}
      style={{
        borderLeftColor: "#000",
        borderRadius: "100%",
        cursor: "pointer",
        position: "relative",
      }}
      onClick={() => {
        console.log("Opening ShareSheet");
        setShareSheetVisible(true);
      }}
    >
      <FaShareAlt color="#e60970" />
      {shareSheetVisible && (
        <div
          className="elevate-xs"
          style={{
            backgroundColor: "white",
            marginTop: 8,
            padding: 8,
            position: "absolute",
            right: "-100%",
          }}
        >
          <SectionHeader>Share Search with Filters</SectionHeader>
          <div
            style={{
              display: "inline-flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <div style={{display: "inline-block"}}>
              {/* <SectionHeader>Main</SectionHeader> */}
              <ul style={{paddingInlineStart: 10}}>
                {compulsoryFilterKey.map((filter) =>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: 4,
                    }}
                  >
                    <FaFilter style={{marginRight: 12}} />
                    {filter}
                  </div>
                )}
              </ul>
            </div>
            <div style={{borderLeft: "1px solid #ccc", marginLeft: 12}}>
              {/* <SectionHeader>Optional</SectionHeader> */}
              <ul style={{paddingInlineStart: 10}}>
                {optionalFiltersKey.map((filter) => {
                  // since writing of the whole array will lead to a relatively
                  // large sharesheet, we choose to ignore them and just show
                  // the other features
                  if (optionalFilters[filter] instanceof Array) return;

                  if (optionalFilters[filter]) {
                    return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom: 4,
                      }}
                    >
                      <FaFilter style={{marginRight: 12}} />
                      {filter}
                    </div>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
          <div
            className="but but-orange"
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={async () => {
              const res =
                await navigator.permissions.query({name: "clipboard-write"});
              
              if (res.state === "granted") {
                navigator.clipboard.writeText(location.href);
              }
              // close the sharesheet
              setShareSheetVisible(false);
            }}
          >
            <FaClipboard style={{marginRight: 12}} />
            Click to copy link to clipboard
          </div>
        </div>
      )}
    </div>
  );
}
