import {
  Coordinates,
  Facility, FAQ, Feature, Picture, RefundType, ReservationType,
} from "../../../../../api";

type ActivityForm = {
  title: string,
  location: string,
  coords: Coordinates;
  description: string,
  short_description: string,
  reservation_policy: ReservationType,
  refund_policy: RefundType,
  adult_price: string,
  children_price: string,
  features: Feature[],
  facilities: Facility[],
  faqs: FAQ[],
  gallery: Picture[],
};

type ActivityFormErrors = {
  title?: string,
  location?: string,
  coords?: string;
  description?: string,
  short_description?: string,
  reservation_policy?: string,
  refund_policy?: string,
  adult_price?: string,
  children_price?: string,
  features?: string,
  facilities?: string,
  faqs?: string,
  gallery?: string,
};

export const validateActivityForm = (
  form: ActivityForm,
): ActivityFormErrors => {
  const errors: ActivityFormErrors = {};

  // title
  if (form.title === "") {
    errors.title = "This field cannot be empty";
  }

  // description
  if (form.description === "") {
    errors.description = "The property/activity description cannot be empty";
  }

  if (form.short_description === "") {
    errors.short_description =
      "The brief property/activity description cannot be empty";
  }

  if (form.coords.lat === 0  && form.coords.lng === 0) {
    errors.location =
      "Make sure to choose the location from the dropdown provided while filling in this field";
  }

  if (form.location === "") {
    errors.location = "The location of the property/activity cannot be empty";
  }

  if (form.gallery.length < 6) {
    errors.gallery =
      "The total number of pictures of this facility has to be more that 6";
  }

  if (form.adult_price === "" || isNaN(Number(form.adult_price))) {
    errors.adult_price = "This field must contain a valid number";
  }

  if (form.children_price === "" || isNaN(Number(form.children_price))) {
    errors.children_price = "This field must contain a valid number";
  }

  if (form.facilities.length < 1) {
    errors.facilities = "must have at least one facility selected";
  }

  if (form.features.length < 1) {
    errors.features = "must have at least one feature selected";
  }

  return errors;
}
