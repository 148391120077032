import {FC, useEffect} from "react";
import { IoMdClose } from "react-icons/io";

import TicketImage from '../../assets/gallery.png';
import {OnOverlayCallback} from "../../context";

type SuccessDialogProps = {
  setIsOverlay?: (isOverlay: boolean) => void;
  setOnOverlay?: OnOverlayCallback;
  setIsCancellable?: (isCancellable: boolean) => void;
  onRetry: () => void;
  removeOverlay: () => void;
};

export const FailureDialog:FC<SuccessDialogProps> = ({
  setIsCancellable, setIsOverlay, setOnOverlay, onRetry, removeOverlay,
}) => {
  useEffect(() => {
    // remove overlay before shifting to the home screen
    return () => {
      setIsCancellable && setIsCancellable(true);
      setIsOverlay && setIsOverlay(false);
      setOnOverlay && setOnOverlay(null);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={
        "p-4 bg-white rounded-md shadow-md border w-full " +
        "flex flex-col items-center sm:w-96"
      }
    >
      <div className="flex flex-row items-center justify-end px-4 py-2">
        <IoMdClose
          size={24}
          className="cursor-pointer"
          onClick={removeOverlay}
        />
      </div>
      <div
        className="bg-no-repeat bg-cover bg-center w-full-mx-2 sm:w-96 h-96"
        style={{
          backgroundImage: `url(${TicketImage})`,
        }}
      />

      <h6 className="text-xl my-2 font-semibold text-center">
        Unable to complete reservation
      </h6>
      <button
        onClick={onRetry}
        className={
          "mt-2 bg-gradient-to-tr from-primary to-end text-white h-10 " +
          "rounded-lg shadow-md px-5 font-semibold"
        }
      >
        Retry
      </button>
    </div>
  );
};
