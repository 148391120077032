import {ChangeEventHandler, FC, useContext, useEffect, useState} from 'react';
import {Marker, StaticGoogleMap} from 'react-static-google-map';

import {
  getRefundPolicy, getReservationPolicies, Property,
  RefundPolicies, ReservationCategory, ReservationPolicies,
} from '../../../../api';
import {DateUtil} from '../../../../util';
import {PopulatedReservation} from '../../../../api/reservation/types';
import {fetchPropertyReservations, fetchPopulatedReservations} from '../../../../api/reservation';
import {PhotoGallery} from '../../../../components';

import {CardProps} from '../../../../components/card/property-card';
import {PolicyCard} from '../../../property/components/policy-card';

import {SubPropertyContainer} from './sub-property-container';
import { OverlayProvider } from '../../../search-property/context';
import { ReviewOverlay } from '../review-overlay';
import { useHistory } from 'react-router';
import { ReservationOverlay } from '..';

type PartnerPropertyProps = {
  subPropertySectionTitle?: string;
  subProperties?: CardProps[];
  propertyType: ReservationCategory;
  property: Property;
};

export const PartnerProperty: FC<PartnerPropertyProps> = ({
  property, propertyType, subProperties, subPropertySectionTitle,
}) => {
  const {
    setIsOverlay, setOnOverlay,
  } = useContext(OverlayProvider);
  const today = new Date();
  const history = useHistory();

  const [reservations, setReservations] = useState<PopulatedReservation[]>([]);
  // the initial end date for all summary should be the current date of NOW
  const [summaryEnd, setSummaryEnd] = useState<Date>(new Date(today));
  // initially the boundary of the month should be the begining of the month
  today.setDate(1);
  today.setHours(0, 0, 0, 0);
  const [summaryStart, setSummaryStart] = useState<Date>(new Date(today))

  const [reservationPolicies, setReservationPolicies] =
    useState<ReservationPolicies | null>(null);
  const [coverImage, setCoverImage] = useState<string>("");

  const [refundPolicy, setRefundPolicy] =
    useState<RefundPolicies | null>(null);

  useEffect(() => {
    // fetch all reservations associated with this property
    getReservationPolicies((err, result) => {
      if (err) {
        console.error(err);
        return;
      }

      if (result) {
        setReservationPolicies(result);
      }
    });

    getRefundPolicy((err, result) => {
      if (err) {
        console.error(err);
        return;
      }

      if (result) {
        setRefundPolicy(result);
      }
    });

    // to make sure that we are not handing the function a null value that
    // may cause unexpected behaviour, confirm that the id infact has a value
    if (property.id) {
      fetchPropertyReservations(propertyType, property.id, (err, result) => {
        if (err) {
          console.error(err);

          return;
        }

        if (result) {
          fetchPopulatedReservations(result, (err, populatedReservations) => {
            if (err) {
              console.error(err);

              return;
            }

            if (populatedReservations) {
              setReservations(populatedReservations);
            }
          });
        }
      });
    }
  }, [propertyType]); // eslint-disable-line react-hooks/exhaustive-deps


  const importImage = async () => {
    let coverIcon: string;
  
    switch (propertyType) {
      case "activity":
        coverIcon = (await import('../../../../assets/service_activity.png')).default;
        break;
      case "car":
        coverIcon = (await import('../../../../assets/service_car.png')).default;
        break;
      case "conference":
        coverIcon = (await import('../../../../assets/service_conferencing.png')).default;
        break;
      case "eatery":
        coverIcon = (await import('../../../../assets/service_restaurant.png')).default;
        break;
      case "hotel":
        coverIcon = (await import('../../../../assets/service_hotel.png')).default;
        break;
      case "rental":
        coverIcon = (await import('../../../../assets/service_rental.png')).default;
        break;
      case "tour":
        coverIcon = (await import('../../../../assets/service_tour.png')).default;
        break;
      default:
        coverIcon = (await import('../../../../assets/service_hotel.png')).default;
        break;
    }

    setCoverImage(coverIcon)
  };

  useEffect(() => {
    importImage();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handles a change in the period the partner wants to see a summary
   * for
   *
   * @param e event created by the select form
   */
  const onSummaryPeriodChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    const summaryStart = new Date();
    const summaryEnd = new Date();

    if (e.target.value === "past-week") {
      summaryStart.setTime(new Date().getTime() - (1000 * 60 * 60 * 24 * 7));
      summaryStart.setHours(0, 0, 0, 0); // revert to the beginning of that day

    } else if (e.target.value === "past-month") {
      // revert to the first date and first hour of the month
      summaryStart.setDate(1);
      summaryStart.setHours(0, 0, 0, 0);
    } else if (e.target.value === "past-year") {
      // revert to the first month, first, date and hour of the year
      summaryStart.setDate(1);
      summaryStart.setMonth(0);
      summaryStart.setHours(0, 0, 0, 0);
    }

    setSummaryStart(summaryStart);
    setSummaryEnd(summaryEnd);
  };

  return (
    <div className="lg:flex lg:max-w-5xl">

      {/** Summary Column */}
      <div className="p-4 min-w-max">
        <div className="flex flex-row items-center justify-center">
          <img
            alt="cover icon"
            className="h-36 w-36"
            src={coverImage}
          />
        </div>
        <h2 className="text-xl font-semibold pt-2">
          Summary
        </h2>
        <p className="text-xs">Summary of all {propertyType} activity</p>
        <hr className="my-2" />

        {/** Time period selector */}
        <div
          className={
            "flex flex-row items-center justify-between p-4 shadow-sm border"
          }
        >
          {/** Time fram e description */}
          <div className="">
            {/*
              Form the date based on the summaryStart and summaryEnd Date
              objects
            */}
            <h6 className="text-base font-semibold">
              {`From ${summaryStart.getDate()} ${
                  DateUtil.getMonthOfTheYear(summaryStart.getMonth())
                } - ${summaryEnd.getDate()} ${
                  DateUtil.getMonthOfTheYear(summaryEnd.getMonth())
                }`}
            </h6>
            <p className="text-xs opacity-70">
              Select the time period for summary
            </p>
          </div>
          {/** Time frame description end */}

          {/** Dropdown container */}
          <div className="">
            <select
              className="text-sm font-semibold flex items-center justify-center"
              name="summary-time-period"
              onChange={onSummaryPeriodChange}
            >
              <option
                className="text-sm font-semibold"
                value="past-month"
              >
                This month
              </option>
              <option
                className="text-sm font-semibold"
                value="past-week"
              >
                Past 7 days
              </option>
              <option
                className="text-sm font-semibold"
                value="past-year"
              >
                This year
              </option>
            </select>
          </div>
          {/** End of dropdown container */}
        </div>
        {/** End of time period selector */}

        {/** Booking Summary Card */}
        <div className=" self-stretch pt-4">
          <div className="w-full mx-2 shadow-sm border p-4 rounded-md">
            <h6 className="text-sm font-semibold">Booking Summary</h6>
            <p className="text-xs opacity-70">
              Summary of all bookings this month
            </p>
            <hr className="my-2" />
            <div className="grid grid-cols-2 border-b">
              <h4
                className="text-sm font-semibold pr-2 pb-2 "
              >
                Booked
              </h4>
              <h4 className="text-sm ml-4 font-semibold place-self-center">
                {reservations.filter((reservation) => (
                  reservation.reservation_date >= summaryStart.getTime() &&
                  reservation.reservation_date <= summaryEnd.getTime() &&
                  reservation.status === "pending"
                )).length}
              </h4>
            </div>
            <div className="grid grid-cols-2 border-b">
              <h4 className="text-sm font-semibold pr-2 pb-2 ">
                Cancelled
              </h4>
              <h4 className="text-sm ml-4 font-semibold place-self-center">
                {reservations.filter((reservation) => (
                  reservation.reservation_date >= summaryStart.getTime() &&
                  reservation.reservation_date <= summaryEnd.getTime() &&
                  reservation.status === "cancelled"
                )).length}
              </h4>
            </div>
          </div>
        {/**End of Booking Summary Card */}

          {/** Revenue summary card */}
          {/* TODO Handle section using a cloud function */}
          <div className="w-full mx-2 shadow-sm border p-4 rounded-md mt-4">
            <h6 className="text-sm font-semibold">Revenue Summary</h6>
            <p className="text-xs opacity-70">
              Summary of all revenue this month
            </p>
            <hr className="my-2" />
            <div className="grid grid-cols-2 border-b">
              <h4
                className="text-sm font-semibold pr-2 pb-2 "
              >
                Reservations
              </h4>
              <h4 className="text-sm ml-4 font-semibold place-self-center">
                {/*
                  Create an immedialtely invoked function that calculates
                  the revenue
                */}
                {(function () {
                  let totalRevenue = 0;
                  reservations.filter((reservation) => (
                    reservation.reservation_date >= summaryStart.getTime() &&
                    reservation.reservation_date <= summaryEnd.getTime() &&
                    reservation.status === "cancelled"
                  )).forEach((reservation) => {
                    totalRevenue += reservation.expected_amount;
                  });

                  return `$ ${totalRevenue}`;
                })()}
              </h4>
            </div>
            <div className="grid grid-cols-2 border-b">
              <h4
                className="text-sm font-semibold pr-2 pb-2 "
              >
                Cancelled
              </h4>
              <h4 className="text-sm ml-4 font-semibold place-self-center">
                {/*
                  Will be updated as soon as the pricing model for pricing
                  cancellations is created
                */}
                $ 0
              </h4>
            </div>
            <div className="grid grid-cols-2 border-b">
              <h4 className="text-sm font-semibold pr-2 pb-2 ">
                Stays
              </h4>
              <h4 className="text-sm ml-4 font-semibold place-self-center">
                {(function () {
                  let totalRevenue = 0;
                  reservations.filter((reservation) => (
                    reservation.reservation_date >= summaryStart.getTime() &&
                    reservation.reservation_date <= summaryEnd.getTime() &&
                    reservation.status === "complete"
                  )).forEach((reservation) => {
                    totalRevenue += reservation.expected_amount;
                  });

                  return `$ ${totalRevenue}`;
                })()}
              </h4>
            </div>

            {/** See all Reservations Button Container */}
            <div className="mt-3 flex flex-row justify-end">
              <button
                className={
                  "h-9 px-3 bg-blue-500 rounded-md shadow-md text-white " +
                  "font-semibold text-sm"
                }
                onClick={() => {
                  setOnOverlay && setOnOverlay(() => () => (
                    <ReservationOverlay
                      reservations={reservations}
                      removeOverlay={() => {
                        setIsOverlay && setIsOverlay(false);
                        setOnOverlay(null);
                      }}
                    />
                  ));

                  window.scrollTo({top: 0, left: 0, behavior: "smooth"});
                  setIsOverlay && setIsOverlay(true);
                }}
              >
                View all reservations
              </button>
            </div>
          </div>
          {/** End of revenue summary card */}

          {/** Review Summary Card */}
          <div
            className="w-full mx-2 shadow-sm border p-4 pb-2 rounded-md mt-4"
          >
            <h6 className="text-sm font-semibold">Review Summary</h6>
            <p className="text-xs opacity-70">
              Summary of all reviews this month
            </p>
            <hr className="my-2" />

            {/** Rating Entry */}
            <div className="grid grid-cols-2 my-2 pb-2 border-b">
              {/** Title container */}
              <div>
                <h4 className="text-sm font-semibold">
                  Good
                </h4>
                <p className="text-xs">
                  Rating of 7+
                </p>
              </div>
              {/** End of title container */}

              <h4 className="text-sm place-self-center">
                {property.reviews.filter((review) => review.rating >= 7).length}
              </h4>

            </div>
            {/** End of Rating entry */}

            {/** Rating Entry */}
            <div className="grid grid-cols-2 my-2 pb-2 border-b">
              {/** Title container */}
              <div>
                <h4 className="text-sm font-semibold">
                  Average
                </h4>
                <p className="text-xs">
                  Rating of 4 - 6
                </p>
              </div>
              {/** End of title container */}

              <h4 className="text-sm place-self-center">
                {property.reviews.filter((review) => (
                  review.rating > 3 && review.rating < 7
                )).length}
              </h4>

            </div>
            {/** End of Rating entry */}

            {/** Rating Entry */}
            <div className="grid grid-cols-2 my-2 pb-2 border-b">
              {/** Title container */}
              <div>
                <h4 className="text-sm font-semibold">
                  Bad
                </h4>
                <p className="text-xs">
                  Rating of 1 - 3
                </p>
              </div>
              {/** End of title container */}

              <h4 className="text-sm place-self-center">
                {property.reviews.filter((review) => review.rating < 4).length}
              </h4>

            </div>
            {/** End of Rating entry */}

            {/** See all Rating Button Container */}
            <div className="flex flex-row justify-end">
              <button
                className={
                  "h-9 px-3 bg-blue-500 rounded-md shadow-md text-white " +
                  "font-semibold text-sm"
                }
                onClick={() => {
                  setOnOverlay && setOnOverlay(() => () => (
                    <ReviewOverlay
                      category={propertyType}
                      propertyId={property.id ? property.id : ""}
                      location={property.location}
                      removeOverlay={() => {
                        setIsOverlay && setIsOverlay(false);
                        setOnOverlay(null);
                      }}
                      reviews={property.reviews}
                      title={property.title}
                    />
                  ));

                  setIsOverlay && setIsOverlay(true);
                }}
              >
                View All
              </button>
            </div>

          </div>
          {/** End of review Summary card */}
        </div>
      </div>
      {/** End of Summary Column */}

      {/** Property Column */}
      <div className="px-2 sm:grid sm:grid-cols-3 gap-4 lg:flex-grow">
        {/** App bar */}
        <div
          className={
            "flex flex-row justify-between items-center overflow-hidden h-14 " +
            "px-2 sm:col-start-1 sm:col-end-4"
          }
        >

          {/** Header Container */}
          <div className="overflow-hidden flex-grow-0">
            <h2
              className={
                "text-xl font-semibold overflow-ellipsis whitespace-nowrap"
              }
            >
              Details
            </h2>
            <p className="text-xs overflow-ellipsis whitespace-nowrap">
              Details of {property.title}
            </p>
          </div>
          {/** End of Header container */}

          {/** Edit Button */}
          <button
            className={
              "py-2 px-3 border border-red-500 rounded-md shadow-md " +
              "text-red-500 font-semibold text-sm"
            }
            onClick={() => {
              history.push(`/property-owner/edit/${propertyType}/${property.id}`);
            }}
          >
            Edit Property
          </button>
          {/** End of edit Button */}
        </div>
        {/** End of App Bar */}

        <hr className="mb-2 sm:col-start-1 sm:col-end-4" />

        {/** Gallery Section */}
        <div
          className="ml-4 sm:col-start-1 sm:col-end-4"
          style={{width: "calc(100% - 32px)", height: "400px"}}
        >
          <PhotoGallery
            gallery={property.gallery}
          />
        </div>
        {/** End of Gallery Section */}

        {/** Description */}
        <div className="text-sm sm:col-start-1 sm:col-end-3 sm:ml-2">
          {property.description}
        </div>
        {/** End of description */}

        {/** Map Section */}
        <div
          className={
            "h-40 mx-4 my-3 rounded-md shadow-md " +
            "sm:h-auto sm:col-start-3 sm:col-end-4 sm:mt-0 overflow-hidden"
          }
        >
          {/* <GoogleMapReact
            center={{lat: property.coords.lat, lng: property.coords.lng}}
            style={{height: "100%", width: "100%"}}
            draggable={false}
          /> */}
          <StaticGoogleMap
            size="600x600"
            apiKey="AIzaSyDxrJtbT4XhPoiNlXoOsNZ77j38ai9ZwAo"
            
          >
            <Marker.Group label="h" color="red">
              <Marker
                location={`${property.coords.lat},${property.coords.lng}`}
              />
            </Marker.Group>
          </StaticGoogleMap>
        {/* </div> */}
        </div>
        {/** End of Map Section */}

        {/* Start of Room column section */}
        {subProperties && (
          <SubPropertyContainer
            sectionTitle={`${subPropertySectionTitle}`}
            subProperties={subProperties}
          />
        )}
        {/** End of Room Column Section */}

        {/** Policy Section */}
        <div className="sm:col-start-1 sm:col-end-4">
          {/** Policy App Bar */}
          <div
            className={
              "flex flex-row justify-between items-center " +
              "overflow-hidden py-4 px-2"
            }
          >

            {/** Header Container */}
            <div className="overflow-hidden flex-grow-0">
              <h2
                className={
                  "text-xl font-semibold overflow-ellipsis whitespace-nowrap"
                }
              >
                Policies
              </h2>
              <p className="text-xs italic overflow-ellipsis whitespace-nowrap">
                Policies governing {property.title}
              </p>
            </div>
            {/** End of Header container */}

            {/** Edit Button */}
            {/* <button
              className={
                "py-2 px-3 border border-red-500 rounded-md shadow-md " +
                "text-red-500 font-semibold text-sm"
              }
            >
              Edit Policies
            </button> */}
            {/** End of edit Button */}
          </div>
          {/** End of policy app bar */}

          {/** Policies container */}
          <div className="flex flex-row justify-evenly flex-wrap">
            {reservationPolicies && (
              <PolicyCard
                policy={reservationPolicies[property.reservation_policy]}
              />
            )}

            {refundPolicy && (
              <PolicyCard
                policy={refundPolicy[property.refund_policy]}
              />
            )}
          </div>
          {/** end of Policies Container */}
        </div>
        {/** End of policy Section */}

      </div>
      {/** End of property column */}

    </div>
  );
};
