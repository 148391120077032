// @ts-check
/* eslint-disable no-undef */

import { auth, database } from "firebase";
import { flights, tours } from "../../../dummy-data";

/**
 * This function, given the tripKey, looks for an instance of the trip that also
 * has the same id as the `tripKey` given here.
 *
 * There are two possible scenarios after the look up is done on firebase.
 * 1. The data with the corresponding value is found.
 * 2. The data with the corresponding value is non-existent.
 *
 * If Instance Exists
 * ------------------
 * If the instance exists, the trip itenerary is updated to the component that
 * called this function and the also indicates that the screen that should be
 * visible is the `main` screen
 *
 * if Instance Does not Exist
 * ---------------------------
 * If no such value is found, the state of the component that called this is
 * updated to the tripSetup being `false` to allow for the new trip to be
 * created since the lack of the value in the database means that the trip
 * session is non-existent.
 *
 * @param {any} setSharedState this is the function that updates the state of
 * the component that the method is called from.
 * @param {string} tripKey this is the Key that is contained in the url as a
 * parameter.
 */
export function getTripData(setSharedState, tripKey) {
  auth().onAuthStateChanged((user) => {
    if (user) {
      const uid = user.uid;
      console.log(tripKey, uid);
      
      database()
        .ref(`Trip_Planner/${uid}/${tripKey}`)
        .on("value", (data) => {
          console.log(data.val());
          if (data.val()) {
            setSharedState({
              loading: false,
              tripSetup: true,
              trip_data: data.val(),
              visibleScreen: "main",
            });
            sortItems(setSharedState, data);
          } else {
            setSharedState({ loading: false, tripSetup: false });
          }
        });
    } else {
      window.location.href = "/admin";
    }
  });
}

//inner method
function sortItems(setSharedState, data) {
  const theItemList = data.val().items;
  console.log("items", theItemList);
  const iteneraryList = [];
  const travel_list = [];
  const accomodation_list = [];
  const catering_list = [];
  const activity_list = [];

  let payable_total = 0;
  for (const key in theItemList) {
    if (Object.prototype.hasOwnProperty.call(theItemList, key)) {
      console.log(`${key} -> ${theItemList[key]}`);
      const room_data = theItemList[key];
      switch (key) {
        case "hotels":
          console.log("theItemList[key]", theItemList[key]);
          for (const key2 in room_data) {
            console.log("room", room_data[key2]);
            const data = room_data[key2];
            data["key"] = key2;
            data["category"] = "hotels";
            payable_total += Number(data.price);
            accomodation_list.push(data);
            iteneraryList.push(data);
          }
          break;
        case "flight":
          // get all the entries
          // calculate price
          theItemList.flight.forEach((flight) => {
            payable_total += flights[flight.id].price;
          })
          travel_list.push(...theItemList.flight);
          break;
        case "eateries":
          catering_list.push(...theItemList.eateries);
          break;
        case "tours":
          // calculate the price from all entries
          theItemList.tours.forEach((tour) => {
            payable_total += tours[tour.id].price;
          });
          activity_list.push(...theItemList.tours);
          break;
      }
    }
  }

  setSharedState({
    travel_list,
    accomodation_list,
    catering_list,
    activities_list: [],
    activity_list,
    payable_total,
  });

  sortItenenary(setSharedState, iteneraryList);
}

function sortItenenary(setSharedState, iteneraryList) {
  const itenerary = [];
  const searchUrl = new URL(location.href);

  iteneraryList.map((item) => {
    const checkinDate = searchUrl.searchParams.get("checkin");
    const checkoutDate = searchUrl.searchParams.get("checkout");

    let startDateExists = false;
    let stopDateExists = false;

    switch (item.category) {

      case "hotels":
        //date exists, add
        itenerary.map((data) => {
          if (data.date == checkinDate) {
            startDateExists = true;
            const index = itenerary.indexOf(data);
            const activities = itenerary[index].activities;
            activities.push({
              title: "Hotel Checkin",
              body: `Checkin: ${item.hotel_name}`,
              time: "6:00pm",
            });
            itenerary[index] = {
              date: data.date,
              activities,
            };
          }
          if (data.date == checkoutDate) {
            stopDateExists = true;
            const index = itenerary.indexOf(data);
            const activities = itenerary[index].activities;
            activities.push({
              title: "Hotel Checkin",
              body: `Checkin: ${item.hotel_name}`,
              time: "6:00pm",
            });
            itenerary[index] = {
              date: data.date,
              activities,
            };
          }
        });
        //date does not exist,add
        if (!startDateExists) {
          itenerary.push({
            date: checkinDate,
            activities: [
              {
                title: "Hotel Checkin",
                body: `Checkin: ${item.hotel_name}`,
                time: "6:00pm",
              },
            ],
          });
        }
        if (!stopDateExists) {
          itenerary.push({
            date: checkoutDate,
            activities: [
              {
                title: "Hotel Checkout",
                body: `Checkout: ${item.hotel_name}`,
                time: "10:00am",
              },
            ],
          });
        }

        break;
    }
  });
  console.log("itenerary", itenerary);
  setSharedState({ itenerary });
}

/**
 * This creates a new instance of a trip on the server that is identified by the
 * key `tripKey` and the data contained in is stored on the trip. This trip is
 * stored in a route specific to the `uid` of the current user in session.
 *
 * Upon successful addition of the data to the direbase database, the function
 * forces a browser reload which then makes the screen display the newly created
 * trip planner session.
 * 
 * @param {any} data this is data about the trip
 * @param {string} tripKey this is the unique identifier created to identify
 * the trip on the firebase database.
 */
export function setTripData(data, tripKey) {
    
  const user = auth().currentUser;
  let uid;
  if (user) {
    uid = user.uid;
  }
  
  database()
    .ref(`Trip_Planner/${uid}/${tripKey}`)
    .set(data)
    .then(() => {
      window.location.reload();
    });
}
