import {FC} from 'react';
import {Route, RouteComponentProps, Switch} from 'react-router-dom';

import {NewActivity} from './activity';
import {NewCar} from './car';
import {NewConference} from './conference';
import {Details} from './details';
import {NewEatery} from './eatery';
import {NewHotel} from './hotel';
import {NewRental} from './rental';
import {NewTour} from './tour';

export const NewProperty: FC<RouteComponentProps> = ({match}) => {
  return (
    <Switch>
      <Route
        exact={true}
        path={`${match.path}/activity`}
        component={NewActivity}
      />
      <Route
        exact={true}
        path={`${match.path}/car`}
        component={NewCar}
      />
      <Route
        exact={true}
        path={`${match.path}/conference`}
        component={NewConference}
      />
      <Route
        exact={true}
        path={`${match.path}/eatery`}
        component={NewEatery}
      />
      <Route
        exact={true}
        path={`${match.path}/hotel`}
        component={NewHotel}
      />
      <Route
        exact={true}
        path={`${match.path}/rental`}
        component={NewRental}
      />
      <Route
        exact={true}
        path={`${match.path}/tour`}
        component={NewTour}
      />
      <Route
        exact={true}
        path={`${match.path}/:category/:id`}
        component={Details}
      />
    </Switch>
  );
};
