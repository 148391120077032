import { Tooltip } from "antd";
import {Component, ReactNode} from "react";

import {NearbyPropertyRef} from "./nearby-properties";

interface MapMarkerProps {
  refs: NearbyPropertyRef;
  lat: number;
  lng: number;
}

interface MapMarkerState {
  isActive: boolean;
}

export class MapMarker extends Component<MapMarkerProps, MapMarkerState> {
  state = {
    isActive: false,
  };

  toggleFocus(isFocused: boolean): void {
    this.setState({
      isActive: isFocused,
    });
  }

  render(): ReactNode {
    return (
      <div className="">
        {/* circular marker */}
        <Tooltip
          visible={this.state.isActive}
          color="#FF5900"
          title="Price"
        >
          <div
            className={
              `h-4 w-4 ${
                this.state.isActive ?
                  "bg-safari-gold" :
                  "bg-gradient-to-r from-secondary to-secondary-end"
                } border-4 border-white rounded-full transform ${
                  ""
                } -translate-x-2 -translate-y-2`
            }
          />
        </Tooltip>
      </div>
    );
  }
}
