/* eslint-disable no-else-return */
////////////////////////////////////////////////////////////////////////////////
// JSDoc Types
////////////////////////////////////////////////////////////////////////////////
/**
 * @typedef {"MM-DD-YYYY" | "DD-MM-YYYY" | "YYYY-MM-DD"} DateFormat
 */

const days = [
  "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday",
];

const months = [
  "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug",
  "Sep", "Oct", "Nov", "Dec",
];

/**
 * Given an index of the day of the week, this function returns the equivalent
 * string of what day of the week that is.
 *
 * @param {number} idx index of the day of the day of the week it is.
 *
 * @returns {string} day of the week in string format
 */
export const getDayOfTheWeek = (idx) => days[idx];

/**
 * Given the index of a month in the year, this function returns the equivalent
 * name of that month.
 *
 * @param {number} idx  index of teh month of the year.
 *
 * @returns {string} the month of the year in string format.
 */
export const getMonthOfTheYear = (idx) => months[idx];

/**
 * Returns the date in the format `DD-MM-YYYY`.
 *
 * @param {Date} date the date to be converted.
 *
 * @returns {string} string of date in `DD-MM-YYYY` format.
 */
export const stringifyDate = (date) => {
  return `${
    normalizeInt(date.getDate())
  }-${
    normalizeInt(date.getMonth() + 1)
  }-${
    date.getFullYear()
  }`;
};

/**
 * Returns the date in material design acceptable date format.
 *
 * @param {Date} date This is the date obj of the date to be stringified
 */
export const getHumanReadableFormat = (date) => {
  return `${
    getMonthOfTheYear(date.getMonth())
  } ${date.getDate()} ${
    (date.getFullYear() === new Date().getFullYear()) ?
      "" :
      date.getFullYear()
  }, ${
    normalizeInt(date.getHours())
  }:${
    normalizeInt(date.getMinutes())
  }`;
};

/**
 * converts the date to a `newFormat` from the `oldFormat`.
 *
 * @param {string} dateString is the date that is in the old format.
 * @param {DateFormat} oldFormat old date Format
 * @param {DateFormat} newFormat format that the date will be changed to.
 *
 * @returns {string} returns the string version of the date in the new format
 */
export const changeDateFormat = (dateString, oldFormat, newFormat) => {
  const [first, second, third] = dateString
    .split("-")
    .map(number => Number(number));
  
  let date, month, year;
  if (oldFormat === "DD-MM-YYYY") {
    date = first;
    month = second;
    year = third;
  } else if (oldFormat === "MM-DD-YYYY") {
    date = second;
    month = first;
    year = third;
  } else {
    date = third;
    month = second;
    year = first;
  }

  // convert the date to the new format
  if (newFormat === "DD-MM-YYYY") {
    return `${normalizeInt(date)}-${normalizeInt(month)}-${year}`;
  } else if (newFormat === "MM-DD-YYYY") {
    return `${normalizeInt(month)}-${normalizeInt(date)}-${year}`;
  } else if (newFormat === "YYYY-MM-DD") {
    return `${year}-${normalizeInt(month)}-${normalizeInt(date)}`;
  } else {
    return dateString;
  }
};

/**
 * Converts the Date from an object to the specified string.
 *
 * @param {Date} dateToConvert date to be converted to string.
 * @param {DateFormat} newFormat format to change Date to.
 *
 * @return {string} the string of the date after conversion.
 */
export const changeDateFormatFromDate = (dateToConvert, newFormat) => {
  const date = dateToConvert.getDate();
  const month = dateToConvert.getMonth() + 1;
  const year = dateToConvert.getFullYear();

  // convert the date to the new format
  if (newFormat === "DD-MM-YYYY") {
    return `${normalizeInt(date)}-${normalizeInt(month)}-${year}`;
  } else if (newFormat === "MM-DD-YYYY") {
    return `${normalizeInt(month)}-${normalizeInt(date)}-${year}`;
  } else if (newFormat === "YYYY-MM-DD") {
    return `${year}-${normalizeInt(month)}-${normalizeInt(date)}`;
  } else {
    throw new Error("Invalid date format declared");
  }
};

/**
 * Gets the number of days in between these two dates.
 *
 * @param {Date} startDate this is the start date being used.
 * @param {Date} endDate end Date to subtract from.
 *
 * @returns {number} number of days in between.
 */
export const getNumberOfDays = (startDate, endDate) => {
  const ONE_DAY = 1000 * 60 * 60 * 24;
  const diffTime = endDate.getTime() - startDate.getTime();
  const diffDays = Math.ceil(Math.abs(diffTime / ONE_DAY));

  return diffDays;
};

/**
 * This creates a list of all available dates from the start date to the end
 * date as an array which includes both the start and the end date.
 *
 * @param {string} start this is the start date in `DD-MM-YYYY` format
 * @param {string} end this is the end date in `DD-MM-YYYY` format
 *
 * @returns {Array<string>} a list of all dates.
 */
export function fetchAvailableDates(start, end) {
  const availableDates = [];
  const formattedStartDate = new Date(changeDateFormat(
    start, "DD-MM-YYYY", "YYYY-MM-DD",
  ));
  const formattedReturnDate = new Date(changeDateFormat(
    end, "DD-MM-YYYY", "YYYY-MM-DD",
  ));

  const diffDays =
    getNumberOfDays(formattedStartDate, formattedReturnDate);
  
    // one extra date is added in order to account for the end date
  for (let i = 0; i < diffDays + 1; i++) {
    const currentDate = new Date(formattedStartDate);
    currentDate.setDate(currentDate.getDate() + i);
    availableDates.push(
      changeDateFormatFromDate(currentDate, "DD-MM-YYYY"));
  }

  return availableDates;
}

const normalizeInt = (int) => (int < 10) ? `0${int}`: `${int}`;
