import {auth, database} from "firebase";

export function getPreviousTrips(setSharedState) {
  auth().onAuthStateChanged((user) => {
    if (user) {
      var uid = user.uid;
      console.log(uid);
      database()
        .ref("Trip_Planner/" + uid)
        .on("value", (snapshot) => {
          var myTrips = [];
          snapshot.forEach((data) => {
            var item = data.val();
            item["key"] = data.key;
            myTrips.push(item);
          });
          setSharedState({ loading: false, tripSetup: true, myTrips });
        });
    } else {
      window.location = "/admin";
    }
  });
}
