import {FC} from 'react';
import {NavLink} from "react-router-dom";
import {AiOutlineLink} from 'react-icons/ai';
import {BiReceipt} from "react-icons/bi";
import {RiDashboardLine} from "react-icons/ri";

export const AffiliateNavRail:FC = () => {
  return (
    <ul
      className="hidden sm:block leftSuperMenu h-full flex-grow-0 flex-shrink-0 bg-secondary"
      style={{
        textDecoration: "none",
        width: 72,
      }}
    >
      <li className="topItem">
        <NavLink
          to="/affiliate"
          className="flex flex-col items-center"
          activeClassName="active"
        >
          <RiDashboardLine className="leftMenuIcon" />{" "}
          <div className="leftMenuText">Overview</div>
        </NavLink>
      </li>
      <li className="topItem">
        <NavLink
          to="/affiliate/links"
          className="flex flex-col items-center"
          activeClassName="active"
        >
          <AiOutlineLink className="leftMenuIcon" />{" "}
          <div className="leftMenuText">Links</div>
        </NavLink>
      </li>
      <li className="topItem">
        <NavLink
          to="/affiliate/finance"
          className="flex flex-col items-center"
          activeClassName="active"
        >
          <BiReceipt className="leftMenuIcon" />{" "}
          <div className="leftMenuText">Finance</div>
        </NavLink>
      </li>
    </ul>
  );
};
