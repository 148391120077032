import {Coordinates, Facility, Feature, Picture, Policy, RefundType, ReservationType} from "../../../../../api";
import {RentalRoom} from "../../../../../api/rental/types";

type RentalForm = {
  title: string;
  description: string;
  short_description: string;
  price: string;
  location: string;
  coords: Coordinates;
  gallery: Picture[];
  reservation_policy: ReservationType;
  refund_policy: RefundType;
  facilities: Facility[];
  features: Feature[];
  rooms: RentalRoom[];
};

type RentalFormErrors = {
  title?: string;
  description?: string;
  short_description?: string;
  price?: string;
  location?: string;
  coords?: string;
  gallery?: string;
  reservation_policy?: string;
  refund_policy?: string;
  facilities?: string;
  features?: string;
  rooms?: string;
};

export const validateRentalForm = (form: RentalForm): RentalFormErrors => {
  const errors: RentalFormErrors = {};

  // title
  if (form.title === "") {
    errors.title = "This field cannot be empty";
  }

  // description
  if (form.description === "") {
    errors.description = "This field cannot be empty";
  }

  // short_description
  if (form.short_description === "") {
    errors.short_description = "This field cannot be empty";
  }

  // price
  if (form.price === "" || isNaN(Number(form.price))) {
    errors.price = "This field must contain a valid number";
  }

  // facilities
  if (form.facilities.length < 1) {
    errors.facilities = "This rental must contain at least one facility";
  }

  // features
  if (form.features.length < 1) {
    errors.features = "This rental must contain at least one feature";
  }

  if (form.rooms.length < 1) {
    errors.rooms = "This rental must contain at least one room";
  }

  if(form.gallery.length < 6) {
    errors.gallery = "Must have at least 6 images of the rental";
  }

  return errors;
};

type RentalOverlayForm = {
  title: string;
  description: string;
  short_description: string;
  cardinality: string;
  capacity_adults_only: string;
  capacity_children_only: string;
  capacity_adults_and_children_adults?: string;
  capacity_adults_and_children_children?: string;
  gallery: Picture[];
  room_policy: Policy[];
  facilities: Facility[];
  features: Feature[];
};

type RentalOverlayFormErrors = {
  title?: string;
  description?: string;
  short_description?: string;
  cardinality?: string;
  capacity_adults_only?: string;
  capacity_children_only?: string;
  capacity_adults_and_children_adults?: string;
  capacity_adults_and_children_children?: string;
  gallery?: string;
  room_policy?: string;
  facilities?: string;
  features?: string;
};

export const validateRentalOverlayForm = (
  form: RentalOverlayForm,
): RentalOverlayFormErrors => {
  const errors: RentalOverlayFormErrors = {};

  // title
  if (form.title === "") {
    errors.title = "This field cannot be empty";
  }

  // description
  if (form.description === "") {
    errors.description = "This field cannnot be empty";
  }

  // short_description
  if (form.short_description === "") {
    errors.short_description = "This field cannot be empty";
  }

  // cardinality
  if (form.cardinality === "" || isNaN(Number(form.cardinality))) {
    errors.cardinality = "This field must contain a valid number";
  }

  // gallery
  if (form.gallery.length < 2) {
    errors.gallery = "Room must have at least two images";
  }

  // adult capacity
  if (
    form.capacity_adults_only === "" ||
    isNaN(Number(form.capacity_adults_only))
  ) {
    errors.capacity_adults_only = "This field must have a valid number";
  }

  // children capacity
  if (
    form.capacity_children_only === "" ||
    isNaN(Number(form.capacity_children_only))
  ) {
    errors.capacity_children_only = "This field must contain a valid number";
  }

  if (
    form.capacity_adults_and_children_adults === "" ||
    isNaN(Number(form.capacity_adults_and_children_adults))
  ) {
    errors.capacity_adults_and_children_adults =
      "This field must contain a valid number";
  }

  if (
    form.capacity_adults_and_children_children === "" ||
    isNaN(Number(form.capacity_adults_and_children_children))
  ) {
    errors.capacity_adults_and_children_adults =
      "This field must contain a valid number";
  }

  // if (form.facilities.length < 1) {
  //   errors.facilities = "Room must have at least one facility selected";
  // }

  // if (form.features.length < 1) {
  //   errors.features = "Room must have at least one feature selected";
  // }

  return errors;
};
