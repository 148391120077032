import {FC, useEffect, useState} from 'react';
import { RiApps2Line } from 'react-icons/ri';
import {RouteComponentProps} from 'react-router';

import {Activity as ActivityType, fetchActivities} from '../../../../api';
import { PartnerHeader } from '../../components';

import {PropertiesSummary} from '../../components/properties-summary';

export const Activity: FC<RouteComponentProps> = () => {
  const [activities, setActivities] = useState<ActivityType[] | null>(null);

  useEffect(() => {
    fetchActivities((err, results) => {
      if (err) {
        console.error(err);

        return;
      }

      setActivities(results);
    });
  }, []);

  return (
    <>
      {activities && (
        <>
          <PartnerHeader HeaderIcon={RiApps2Line} title="All Activities" />
          <PropertiesSummary
            category="activity"
            properties={activities}
          />
        </>
      )}
    </>
  );
};
