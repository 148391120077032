import {Facility, Feature} from "..";

export const stopFacilities: Facility[] = [
  {
    title: "In-house eatery/restaurant",
    description: "Restaurant where customer can buy a quick meal",
  },
  {
    title: "Swimming pool",
    description: "Swimming pool available in this facility",
  },
  {
    title: "Zoo & Aquariams",
    description: "This facility contains wild animals the customers can view",
  },
  {
    title: "Sports facilities",
    description: "This facility contains sports facilities such as football pitches",
  },
  {
    title: "Casino and gaming",
    description: "This facility contains casino and gaming facilities for customers",
  },
  {
    title: "Museum",
    description: "This facility contains (or is) a museum",
  },
  {
    title: "Amusement park",
    description: "Contains amusement park facilities such as merry-go rounds and ferris wheels",
  },
  {
    title: "Hiking",
    description: "Contains hikable landmarks",
  },
];

export const stopFeatures: Feature[] = [
  {
    title: "Tour-guides",
    description: "This facility provides tour guides for you upon request",
  },
  {
    title: "Team buiding activities",
    description: "This facility has teambuiding facilities provided upon request",
  },
  {
    title: "Group activity",
    description: "This facility is highly recommended for group activities",
  },
  {
    title: "Outdoor",
    description: "This facility mainly embraces outdoor events",
  },
  {
    title: "Familiy friendly",
    description: "This facility is familiy friendly",
  },
  {
    title: "Adults only",
    description: "This facility is for adults only",
  },
];
