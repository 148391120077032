import { Formik } from 'formik';
import {Dispatch, FC, SetStateAction, useState} from 'react';
import {FaTimes} from "react-icons/fa";
import { Facility, Feature, ItemException, Picture, Policy } from '../../../api';
import { RentalRoom } from '../../../api/rental/types';

import {Input, TextArea} from "../../../components/input";

import {
  EditSubPropertyFacilities, EditSubPropertyFeatures, SubPropertyAddImage,
  SubPropertyEditExceptions, SubPropertyEditPolicies,
} from "."

import {validateRentalOverlayForm} from '../routes/new/rental/validate-form';

type EditRentalRoomOverlayProps = {
  idx: number;
  item: RentalRoom;
  rentalRooms: RentalRoom[];
  setRentalRooms: Dispatch<SetStateAction<RentalRoom[]>>;
  removeOverlay: () => void;
};

export const EditRentalRoomOverlay: FC<EditRentalRoomOverlayProps> = ({
  idx, item, rentalRooms, removeOverlay, setRentalRooms,
}) => {
  const [images, setImages] = useState<Picture[]>(item.gallery);
  const [features, setFeatures] = useState<Feature[]>(item.features);
  const [facilities, setFacilities] = useState<Facility[]>(item.facilities);
  const [policies, setPolicies] = useState<Policy[]>(item.room_policy);
  const [exceptions, setExceptions] = useState<ItemException[]>(
    item.exceptions ?
      item.exceptions :
      []
  );

  const initialValues = {
    title: item.title,
    description: item.description,
    short_description: item.short_description,
    cardinality: `1`,
    capacity_adults_only: `${item.capacity.adults_only}`,
    capacity_children_only: `${item.capacity.children_only}`,
    capacity_adults_and_children_adults: `${item.capacity.adults_and_children.adults}`,
    capacity_adults_and_children_children: `${item.capacity.adults_and_children.children}`,
    gallery: "",
    room_policy: "",
    facilities: "",
    features: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        return validateRentalOverlayForm({
          title: values.title,
          description: values.description,
          short_description: values.short_description,
          facilities,
          features,
          gallery: images,
          cardinality: values.cardinality,
          room_policy: policies,
          capacity_adults_only: values.capacity_adults_only,
          capacity_children_only: values.capacity_children_only,
          capacity_adults_and_children_adults:
            values.capacity_adults_and_children_adults,
          capacity_adults_and_children_children:
            values.capacity_adults_and_children_children,
        });
      }}
      onSubmit={(values) => {
        const newRentalRooms = [...rentalRooms];
        // handle Submission
        newRentalRooms.splice(idx, 1, {
          id: item.id,
          title: values.title,
          capacity: {
            adults_and_children: {
              adults: Number(values.capacity_adults_and_children_adults),
              children: Number(values.capacity_adults_and_children_children),
            },
            children_only: Number(values.capacity_children_only),
            adults_only: Number(values.capacity_adults_only),
          },
          description: values.description,
          facilities,
          features,
          gallery: images,
          room_policy: policies,
          short_description: values.short_description,
          exceptions,
        });

        setRentalRooms(newRentalRooms);
        removeOverlay();
      }}
    >
      {({
        values, errors, handleChange, handleSubmit, validateForm, setErrors,
      }) => (
        <form onSubmit={handleSubmit}>
          <div
            className={
              "absolute top-0 right-0 bottom-0 w-full h-full bg-white " +
              "sm:max-w-lg sm:shadow-md overflow-scroll"
            }
          >
              {/** App bar */}
              <div
                className="sticky top-0 bg-safari-gold text-white z-10 h-14 flex flex-row justify-between items-center overflow-hidden px-2 sm:col-start-1 sm:col-end-4 shadow-md"
              >

                {/** Header Container */}
                <div className="overflow-hidden flex-grow-0 flex flex-row items-center">
                  {/** Close Overlay icon */}
                  <FaTimes size={24} onClick={removeOverlay} />
                  <div className="ml-3">
                    <h2 className="text-xl font-semibold overflow-ellipsis whitespace-nowrap">
                      New Room
                    </h2>
                    <p className="text-xs italic overflow-ellipsis whitespace-nowrap">
                      Enter room details
                    </p>
                  </div>
                </div>
                {/** End of Header container */}

                {/** Edit Button */}
                <button
                  className="h-9 px-3 border border-black rounded-md shadow-md text-black font-semibold text-sm flex items-center"
                >
                  Save
                </button>
                {/** End of edit Button */}
              </div>
              {/** End of app bar */}

              <div className="p-4">
                {/** start of text input container */}
                <div className="">
                  <Input
                    helper="The name of this type of room (suite)"
                    id="room-name-input"
                    inputLabel="Room name"
                    name="title"
                    value={values.title}
                    error={errors.title}
                    onChange={handleChange}
                  />
                </div>
                {/** End of text input container */}

                {/** start of text input container */}
                <div className="mt-3">
                  <Input
                    id="cardinality-input"
                    helper="How many room are present of this type"
                    inputLabel="Number of rooms"
                    name="cardinality"
                    value={values.cardinality}
                    error={errors.cardinality}
                    onChange={handleChange}
                  />
                </div>
                {/** End of text input container */}

                {/** start of text area container */}
                <div className="mt-3">
                  <TextArea
                    id="room-description-long"
                    name="description"
                    helper="Long description of the room"
                    error={errors.description}
                    inputLabel="Room description (long)"
                    value={values.description}
                    onChange={handleChange}
                  />
                </div>
                {/** End of text area container */}

                {/** start of text area container */}
                <div className="mt-3">
                  <TextArea
                    id="room-description-short"
                    helper="AS brief description of the room"
                    inputLabel="Room description (short)"
                    name="short_description"
                    value={values.short_description}
                    error={errors.short_description}
                    onChange={handleChange}
                  />
                </div>
                {/** End of text area container */}

                {/** Room Capacity Container */}
                <div className="px-4 py-2 my-2 shadow-md border rounded-md">
                  <h6 className="text-xl font-semibold">Room Capacity Options</h6>
                  <p className="pb-2 border-b-2 text-xs">
                    Enter capacity options based on the field
                  </p>
                  {/* start of text input container */}
                  <div className="mt-3">
                    <Input
                      id="adult-only-input"
                      inputLabel="Adult only capacity"
                      name="capacity_adults_only"
                      value={values.capacity_adults_only}
                      error={errors.capacity_adults_only}
                      helper="How many adults can this room hold?"
                      onChange={handleChange}
                    />
                  </div>
                  {/* End of text input container */}

                  {/* start of text input container */}
                  <div className="mt-3">
                    <Input
                      id="children-only-input"
                      helper="How many children (only) can this room hold"
                      inputLabel="Children only capacity"
                      name="capacity_children_only"
                      value={values.capacity_children_only}
                      error={errors.capacity_children_only}
                      onChange={handleChange}
                    />
                  </div>
                  {/* End of text input container */}

                  {/* start of text input container */}
                  <div className="mt-3">
                    <p className="text-xs">
                      How many adults and children (combined) can the room hold
                    </p>

                    <div className="mt-2 grid grid-cols-2">
                      <Input
                        helper="Number of adults"
                        inputLabel="Adults"
                        id="adults-and-children-capacity-adults"
                        name="capacity_adults_and_children_adults"
                        value={values.capacity_adults_and_children_adults}
                        error={errors.capacity_adults_and_children_adults}
                        onChange={handleChange}
                      />

                      <Input
                        helper="Number of children"
                        inputLabel="Children"
                        id="adults-and-children-capacity-children"
                        name="capacity_adults_and_children_children"
                        value={values.capacity_adults_and_children_children}
                        error={errors.capacity_adults_and_children_children}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {/* End of text input container */}
                </div>
                {/** End of Room Capacity Container */}

                {/** End of Room Capacity Container */}

                {/** Image Addition container */}
                <SubPropertyAddImage
                  images={images}
                  setImages={async (updatedImages) => {
                    setImages(updatedImages);

                    setErrors(await validateForm());
                  }}
                  threshold={6}
                />
                {/** End of image add container */}

                {/** Add Facilities Container */}
                <EditSubPropertyFacilities
                  category="rental"
                  headerSubtitle="Set facilities contained in this room"
                  facilities={facilities}
                  setFacilities={async (updatedFacilities) => {
                    setFacilities(updatedFacilities);

                    setErrors(await validateForm());
                  }}
                />
                {/** End of add facilities container */}

                {/** Features container */}
                <EditSubPropertyFeatures
                  features={features}
                  setFeatures={async (updatedFeatures) => {
                    setFeatures(updatedFeatures);

                    setErrors(await validateForm());
                  }}
                  category="rental"
                />

              {/** End of Policy container */}
              <SubPropertyEditPolicies
                policies={policies}
                setPolicies={async (updatedPolicies) => {
                  setPolicies(updatedPolicies);

                  setErrors(await validateForm());
                }}
              />
              {/** End of policy edit container */}

              {/** End of Policy container */}
              <SubPropertyEditExceptions
                exceptions={exceptions}
                setExceptions={async (updatedExceptions) => {
                  setExceptions(updatedExceptions);

                  setErrors(await validateForm());
                }}
              />
              {/** End of policy edit container */}
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};
