// @ts-check
import fetchSearchCategory from '../routes/search/data-fetcher/util/fetch-search-category';
import { addToTripItinerary } from '../util/add-to-trip-itinerary';
import {Rating} from './rating'

/**
 * @typedef {import('../dummy-data')} Review
 */

/**
 * This is re-used for the /search route. It displays the results that are
 * fetched from the server based on the search params entered.
 *
 * @param {{
 * cover: string,
 * title: string,
 * location: string,
 * rating: number,
 * comments: Review[],
 * description: string,
 * price: number,
 * duration: string,
 * action: {
 *  label: string,
 *  onClick: import('react').MouseEventHandler<HTMLSpanElement>,
 * },
 * style: import('react').CSSProperties,
 * origin: string,
 * item: any,
 * idx: number,
 * }} props
 *
 * @returns {JSX.Element}
 */
export function SearchCard({
  cover, title, location, rating, comments, description, price, duration,
  action, style, origin, item, idx,
}) {
  const addToTrip = () => {
    addToTripItinerary(fetchSearchCategory(), item, idx);
  };

  return (
    <div
      className={
        "shadow-md w-card-mobile md:flex md:flex-row md:w-full " +
        " md:p-4 md:rounded-lg"
      }
      style={{...style}}
    >
      <div
        // alt={`${title}`}
        className="w-full h-48 md:h-48 md:w-48 self-center bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${cover})`,
        }}
      />
      <div
        className={
          "flex flex-col p-4 md:p-0 md:pl-3 md:justify-center " +
          "md:justify-items-center"
        }
      >
        <div className="flex flex-col md:flex-row justify-between">
          <div>
            <h6 className="text-2xl font-semibold md:w-96">{title}</h6>
            <p className="text-sm opacity-70">This is the distance matrix</p>
          </div>
          <div
            className="flex flex-row items-center md:flex-col-reverse md:pr-3"
          >
            {/* <div className="h-7 w-20 bg-gray-400" /> */}
            <Rating rating={rating} />
            <div className="text-xs font-semibold md:self-end">
              {`${rating} (${comments.length})`}
            </div>
          </div>
        </div>
        <hr className="hidden md:block my-2 md:mr-3" />
        <div className="flex flex-col md:flex-row md:self-center">
          <div className="mb-3 md:w-96 max-w-xs">
            <h6 className="text-sm font-semibold">{location}</h6>
            <p className="text-sm">
              {description.length > 200 ?
                `${description.substr(0, 200)}...` :
                description
              }
            </p>
          </div>
          <hr className="md:hidden" />
          <div
            className={
              "md:w-48 md:h-auto md:flex md:flex-col md:items-end px-3 " +
              "md:pr-3 justify-center"
            }
          >
            <div
              className={
                "mt-2 flex flex-row items-center justify-between " +
                " md:flex-col md:justify-around"
              }
            >
              <p className="text-base font-semibold">{duration}</p>
              <p className="text-2xl font-bold">{`${price} USD`}</p>
            </div>
            <span
              onClick={
                (origin !== "planner") ?
                  action.onClick :
                  addToTrip
                }
              className={
                "mt-4 px-4 rounded text-sm capitalize h-9 flex items-center" +
                " justify-center bg-secondary text-white cursor-pointer" +
                " max-w-md self-center md:self-end shadow-md"
              }
            >
              {origin !== "planner" ?
                action.label.toUpperCase() :
                "ADD TO TRIP"
              }
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
