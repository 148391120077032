import {User} from "firebase";
import {createContext} from "react";
import { SafariUser } from "../api";

type UserProviderProps = {
  user: null | User;
  safariUser: null | SafariUser;
};

export const UserProvider = createContext<UserProviderProps>({
  user: null,
  safariUser: null,
})
