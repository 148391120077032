// @ts-check
import {FaChevronLeft, FaChevronRight, FaPlane} from "react-icons/fa";

import {ShareButton} from "./share-button";

export function Flight({category, sharedState, appendQuery, updateState}) {
  return (
    <div className="sticky top-0 z-20">
      <div
        className="elevate-xs items-center"
        style={{
          display: "flex",
          padding: 20,
          paddingTop: 10,
          paddingBottom: 10,
          background: "#fff",
        }}
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: 19,
            flex: 1,
            color: "#3f3c55",
            display: "flex",
          }}
        >
          <div style={{ paddingTop: 4, marginRight: 10 }}>
            <FaPlane />
          </div>
          Flights from{" "}
          <span
            style={{
              textTransform: "capitalize",
              paddingLeft: 5,
              paddingRight: 5,
            }}
          >
            {sharedState.departureCity}
          </span>{" "}
          to{" "}
          <span style={{ textTransform: "capitalize", paddingLeft: 5 }}>
            {sharedState.destinationCity}
          </span>
        </div>
        <FaChevronLeft
          style={{
            marginRight: 4,
            marginLeft: 4,
            color: (sharedState.pagination - 1 > 0)  ? "red": "#424242",
          }}
          onClick={() => {
            if (sharedState.pagination - 1 > 0) {
              updateState({pagination: sharedState.pagination - 1})
            }
          }}
        />
        <div className="selector-number-of-results">
          <div>
            Showing{" "}
            {!sharedState.searchResults.length
              ? "0"
              : sharedState.pagination * 10 - 9}
            -
            {!sharedState.searchResults.length
              ? "0"
              : sharedState.searchResults.length > 10 &&
                sharedState.pagination * 10 <
                  sharedState.searchResults.length
              ? sharedState.pagination * 10
              : sharedState.searchResults.length}{" "} 
            of{" "}
            {!sharedState.searchResults.length
              ? "0"
              : sharedState.searchResults.length}
          </div>
        </div>
        <FaChevronRight
          style={{
            marginRight: 4,
            marginLeft: 4,
          }}
          color={
            (sharedState.pagination <
              Math.ceil(sharedState.searchResults.length / 10)
            ) ? "red": "#424242"
          }
          onClick={() => {
            if (sharedState.pagination <
              Math.ceil(sharedState.searchResults.length / 10)
            ) {
              updateState({pagination: sharedState.pagination + 1})
            }
          }}
        />
        <ShareButton category={category} />
      </div>
      {/* <div
        className="elevate-xs"
        style={{
          borderTop: "1px solid #ddd",
          marginTop: 0,
          background: "#fff",
        }}
      >
        <div style={{ height: 30, display: "flex", padding: 10 }}>
          <div
            style={{
              cursor: "pointer",
              flex: 1,
              textAlign: "center",
              borderRight: "3px solid #000",
              paddingTop: 5,
              fontSize: 14,
              color: "#f00",
            }}
            onClick={() => {
              appendQuery("sort=hotel_review_score");
            }}
          >
            Review Score
          </div>
          <div
            style={{
              cursor: "pointer",
              flex: 1,
              textAlign: "center",
              borderRight: "3px solid #000",
              paddingTop: 5,
              fontSize: 12,
            }}
            onClick={() => {
              appendQuery("sort=hotel_number_of_reviews");
            }}
          >
            Most Reviews
          </div>
          <div
            style={{
              cursor: "pointer",
              flex: 1,
              textAlign: "center",
              borderRight: "0px solid #000",
              paddingTop: 5,
              fontSize: 12,
            }}
            onClick={() => {
              appendQuery("sort=price");
            }}
          >
            Price (Lowest)
          </div>
        </div>
      </div> */}
    </div>
  );
}
