// @ts-check

import { conferences } from "../../../../dummy-data";
import { fetchOptionalConferencingFilters } from "../../../../reducers";

/**
 * This function fetched the conferencing venues based on the filters listed in
 * the search parameters. The viable search parameters are:
 * 1. `place` - this is the venue of the conference
 * 2. `lat` - this is the latitude coordinates
 * 3. `lng` - this is the longitude coordinates
 * 4. `date` - this is the date that the function is supposed to  take place.
 * 5. `adults` - this is the number of adults that will be in attendance.
 * 6. `children` - this is the number of children that will be in attendance.
 *
 * @param {URL} url this the search url that is being used for this query.
 */
export default function fetchVenues(url, updateState) {
  // get all the search parameters that have been entered
  let place = null;
  let lat = null;
  let lng = null;
  let date = null;
  let adults = null;
  let children = null;

  if (url.searchParams.has("place")) {
    place = url.searchParams.get("place");
  }

  if (url.searchParams.has("lat")) {
    lat = url.searchParams.get("lat");
  }

  if (url.searchParams.has("lng")) {
    lng = url.searchParams.get("lng");
  }

  if (url.searchParams.has("date")) {
    date = url.searchParams.get("date");
  }

  if (url.searchParams.has("adults")) {
    adults = url.searchParams.get("adults");
  }

  if (url.searchParams.has("children")) {
    children = url.searchParams.get("children");
  }

  console.log(`searchParams`);
  console.log({place, lat, lng, date, adults, children});

  const optionalFilters = fetchOptionalConferencingFilters();
  // Dummy results
  const rawResults = conferences.map((conference) => ({
    ...conference,
    calcPrice: conference.price.day,
  }));

  console.log("Conference Results", rawResults);

  updateState({
    rawResults,
    searchResults: rawResults,
    searchItemsLength: rawResults.length,
    searchDone: true,
    category: "conferencing",
    minPrice: 0,
    maxPrice: 799,
    rangeMax: optionalFilters.price_highest ?
      optionalFilters.price_highest : 799,
    rangeMin: optionalFilters.price_lowest ?
      optionalFilters.price_lowest : 0,
    adults,
    city: place,
    center: {lat, lng},
  });
}
