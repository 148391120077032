import {FC, useEffect, useState} from 'react';
import type {RouteComponentProps} from 'react-router-dom';
import Lottie from 'react-lottie';
import {DatePicker, InputNumber} from 'antd';

import animation from '../../../../assets/anim/loading-hotel.json';
import {Input, TimePicker} from '../../../../components/input';
import {Rental, fetchRental} from '../../../../api';
import {fetchCompulsoryRentalFilters} from '../../../../reducers';
import {RentalReservation} from '../../../../api/reservation/types';
import {DateUtil} from '../../../../util';

import {OverlayProvider} from '../../../search-property/context';

import Container, {CheckoutSection, SubPropertySection} from '../../components/container';
import {Card} from '../../../../components/card/property-card';
import {NonBookablePropertyOverlay} from '../../components/nbo';
import {ProceedToCheckout} from '../../components/proceed-to-checkout';
import { calculateRentalPrice } from '../../../../util/price-calculator';
import { TextInput } from '../../../../components/input/text-input';
import { Button } from '../../../../components';
import ActivityIndicator from '../../../../components/loading';
import { checkRentalAvailability } from '../../../../api/rental';

const {RangePicker} = DatePicker;

type RentalProps = RouteComponentProps<{id: string}>;

/**
 * For rental properties, the property contains rooms that are non-bookable by
 * themselves. This property checkout happens on the main screen as such the
 * overlay does not have the chekout feature `NonBookablePropertyOverlay`.
 * Upon wanting to checkout, the user is also asked for the time that they would
 * like to checkin. This will be used to fill in the user calendar and also mark
 * reservation timetable in the `BigCalendar` also.
 *
 * @param props rental property props
 *
 * @returns Rental Property component
 */
export const RentalProperty: FC<RentalProps> = ({match}) => {
  const [rental, setRental] = useState<Rental | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorOccured, setErrorOccured] = useState<boolean>(false);

  // input control
  const [checkinTime, setCheckinTime] = useState<string>("10:00");
  const [isAvailable, setIsAvailable] = useState<boolean>(true);

  // get the colpulsory filters that the user used to make the search
  const compulsoryFilters = fetchCompulsoryRentalFilters();

  const {place} = compulsoryFilters;

  const [adults, setAdults] = useState<number>(
    compulsoryFilters.adults ? compulsoryFilters.adults : 1,
  );
  const [children, setChildren] = useState<number>(
    compulsoryFilters.children ? compulsoryFilters.children : 0,
  );
  const date = new Date();

  date.setDate(date.getDate() + 1);
  const [checkin, setCheckin] = useState<string>(
    compulsoryFilters.checkin ?
      compulsoryFilters.checkin :
      DateUtil.changeDateFormatFromDate(date, "DD-MM-YYYY"),
  );

  date.setDate(date.getDate() + 1);
  const [checkout, setCheckout] = useState<string>(
    compulsoryFilters.checkout ?
      compulsoryFilters.checkout :
      DateUtil.changeDateFormatFromDate(date, "DD-MM-YYYY"),
  );

  useEffect(() => {
    // fetch the Hotels and after fetching, carry out the on success function
    fetchRental(match.params.id, (err, data) => {
      setIsLoading(false);

      if (err) {
        setErrorOccured(true);
        return;
      }

      // handle the hotel data that has been retrieved by the system
      setRental(data);
    });
  }, [match.params.id]);

  const calculatePrice = (): number => {
    if (!rental) return 0;
  
    return calculateRentalPrice(rental, checkin, checkout);
  };

  const onProceedToCheckout = () => {
    // creates the reservation card and then proceeds to checkout
    const reservation: RentalReservation = {
      amount_received: calculatePrice(),
      category: "rental",
      checkin: new Date().getTime(),
      due_date: new Date().getTime(),
      expected_amount: calculatePrice(),
      property_id: rental?.id ? rental.id : "",
      reservation_date: Date.now(),
      reservation_type: rental ? rental.reservation_policy : "full_amount",
      status: "pending",
      checkout: new Date().getTime(),
      adults,
      children,
    };

    return reservation;
  };

  return (
    <>
      {isLoading && (
        <div className="w-full h-96 flex flex-col items-center justify-center">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animation,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            style={{}}
            height={200}
            width={200}
          />
          <p className="text-base font-semibold">Fetching Hotel Details</p>
        </div>
      )}
      {!isLoading && !errorOccured && rental && (
        <OverlayProvider.Consumer>
          {({setIsOverlay, setOnOverlay, setIsCancellable}) => (
            <Container
              property={rental}
              showDirections={true}
              renderSearchSection={() => {
                return (
                  <>
                    <TextInput
                      disabled={true}
                      value={rental.title}
                      helper="Name of the rental"
                      id="rental-name"
                      inputLabel="Rental property Name"
                      name="rental_name"
                      onChange={(e) => console.log("Should not change")}
                      className="mb-2"
                    />
                    <p className="text-xs mb-1">
                      Choose new check in and checkout dates
                    </p>
                    <RangePicker
                      disabledDate={(date) => {
                        const timestamp = Number(date.format("x"));
                        
                        const today = new Date();
                        today.setHours(0, 0, 0, 0);

                        return today.getTime() > timestamp;
                      }}
                      onChange={(values) => {
                        if (values) {
                          if (values[0]) {
                            setCheckin(
                              DateUtil.stringifyDate(values[0].toDate()));
                          }

                          if (values[1]) {
                            setCheckout(
                              DateUtil.stringifyDate(values[1].toDate()));
                          }
                        }
                      }}
                    />
                    <p className="text-xs mb-1">Number of adults</p>
                    <InputNumber
                      className="mb-2 w-full"
                      defaultValue={adults}
                      value={adults}
                      onChange={(e) => {
                        setAdults(e);
                      }}
                    />
                    <p className="text-xs mb-1">Number of children</p>
                    <InputNumber
                      className="mb-2 w-full"
                      defaultValue={children}
                      value={children}
                      onChange={(e) => {
                        setChildren(e);
                      }}
                    />
                    <div className="flex flex-row justify-center">
                      <Button
                        disabled={
                          checkin === compulsoryFilters.checkin &&
                            checkout === compulsoryFilters.checkout
                        }
                        type="button"
                        onClick={() => {
                          setIsCancellable && setIsCancellable(false);
                          setOnOverlay && setOnOverlay(() => () => (
                            <ActivityIndicator
                              caption=""
                            />
                          ));
                          setIsOverlay && setIsOverlay(true);

                          checkRentalAvailability(
                            match.params.id,
                            new Date(
                              DateUtil.changeDateFormat(
                                checkin, "DD-MM-YYYY", "YYYY-MM-DD",
                              ),
                            ).getTime(),
                            (err, facilityIsAvailable) => {
                              setIsCancellable && setIsCancellable(true);
                              setIsOverlay && setIsOverlay(false);
                              setOnOverlay && setOnOverlay(null);
                              if (err) {
                                console.error(err);

                                return;
                              }

                              // set the hotel details after search
                              if (typeof facilityIsAvailable === "boolean") {
                                setIsAvailable(facilityIsAvailable);
                              }
                            },
                          )
                        }}
                      >
                        Check Availability
                      </Button>
                    </div>
                  </>
                );
              }}
            >
              <SubPropertySection title="Rooms">
                {rental.rooms.map((room, idx) => (
                  <Card
                    key={`rental_room_${idx}`}
                    cover={room.gallery[0].url}
                    description={room.description}
                    subtitle={rental.location}
                    title={room.title}
                    action={{
                      label: "View Room",
                      onClick: () => {
                        setOnOverlay && setOnOverlay(() => () => (
                          <NonBookablePropertyOverlay
                            gallery={room.gallery}
                            description={room.description}
                            facilities={room.facilities}
                            features={room.features}
                            location={rental.location}
                            policies={room.room_policy}
                            removeOverlay={() => {
                              setIsOverlay && setIsOverlay(false);
                              setOnOverlay && setOnOverlay(null);
                            }}
                            title={room.title}
                          />
                        ))
                        setIsOverlay && setIsOverlay(true);
                      },
                    }}
                  />
                ))}
              </SubPropertySection>
              <CheckoutSection>
                <ProceedToCheckout
                  isAvailable={isAvailable}
                  onProceedToCheckout={onProceedToCheckout}
                  price={calculatePrice()}
                >
                  <Input
                    className="mb-2"
                    disabled={true}
                    inputLabel="Rental"
                    name="rental"
                    onChange={() => console.log("Should not happen")}
                    value={rental ? rental.title: ""}
                    id="rental-name"
                    helper="Name of the rental"
                  />
                  <Input
                    className="mb-2"
                    disabled={true}
                    error=""
                    inputLabel="Location of Rental"
                    name="place"
                    onChange={() => console.log("Should not happen")}
                    value={place ? place: ""}
                    id="rental-location"
                    helper="Location of the rental"
                  />
                  <Input
                    className="mb-2"
                    disabled={true}
                    error=""
                    inputLabel="Checkin Date"
                    name="checkin"
                    onChange={() => console.log("Should not happen")}
                    value={checkin ? checkin: ""}
                    helper="Checkin date of the hotel"
                    id="rental-checkin-date"
                  />
                  <TimePicker
                    className="mb-2"
                    name="Checkin time"
                    onChange={(e) => setCheckinTime(e.target.value)}
                    value={checkinTime}
                    error=""
                    label="checkin time"
                  />
                  <Input
                    className="mb-2"
                    disabled={true}
                    error=""
                    inputLabel="Checkout Date"
                    name="checkout"
                    onChange={() => console.log("Should not happen")}
                    value={checkout ? checkout: ""}
                    helper="Checkout date out the rental"
                    id="rental-checkout-date"
                  />
                  <Input
                    disabled={true}
                    error=""
                    inputLabel="Adults"
                    name="adults"
                    onChange={() => console.log("Should not happen")}
                    value={adults ? `${adults}`: ""}
                    id="rental-number-of-adults"
                    helper="Number of adults renting the room"
                  />
                  <Input
                    disabled={true}
                    error=""
                    inputLabel="Children"
                    name="children"
                    onChange={() => console.log("Should not happen")}
                    value={children ? `${children}`: ""}
                    helper="Number of children"
                    id="rental-number-of-children"
                  />
                </ProceedToCheckout>
              </CheckoutSection>
            </Container>
          )}
        </OverlayProvider.Consumer>
      )}
      {!isLoading && !errorOccured && !rental && (
        <></>
      )}
      {!isLoading && errorOccured && (
        <></>
      )}
    </>
  );
};
