import {Tour} from "../../api";

/**
 * Calculates how much it will cost for a tour package given the declared
 * number of attendees based input entered by the user.
 *
 * @param adults These are the number of adults going for the tour
 * @param children Number of children going for the tour
 * @param tour This is an pbject containing all the tour details
 * @returns Returns the price it will cost to fit all the people going
 */
export const calculateTourPrice = (
  adults: number, children: number, tour: Tour,
): number => {
  let price = 0;
  price += (adults * tour.price.adult);
  price += (children * tour.price.children);

  return price;
};
