import { Component } from "react";
import { Link, withRouter } from 'react-router-dom';
import {
  FaStar,
  FaAngleRight,
} from "react-icons/fa";
import { numberWithCommas } from "../../../_system/GeneralMethodology";

class ItemHotel extends Component {
  _showSearchProperty = () => {
    window.location.href =
      `${window.location.pathname.replace("navigator", "search") 
      }/hotel=${ 
      this.props.data.hotelId 
      }#view-property`;

      this.props.history.push();
  };
  render() {
    console.log("item", this.props);
    const stars = [];
    const notstars = [];
    for (let i = 0; i < this.props.data.hotel_star; i++) {
      stars.push("");
    }
    for (let i = 0; i < 5 - this.props.data.hotel_star; i++) {
      notstars.push("");
    }
    //get pic
    let finalPic;
    const picArray = this.props.data.hotel_picture_gallery;
    // console.log(this.props.data);
    if (picArray) {
      const fin = picArray[0];
      if (fin) {
        finalPic = fin;
      }
    }
    console.log("origin", window.location.pathname);
    let linkToView = `/view/hotel/${this.props.data.hotelId}`;
    if (this.props.origin == "planner") {
      console.log("yeah");
      linkToView =
        `${window.location.href.split("place=")[0] 
        }hotel=${ 
        this.props.data.hotelId 
        }/${ 
        new URL(location.href).search.replace("?", "")
      }`;
    } else {
      // linkToView = linkToView + '/' + this.props.urlParameters;
      linkToView = `${
        linkToView
      }/${
        new URL(location.href).search.replace("?", "")
      }`;
    }
    return (
      <div
        className="elevate-xs flex-col sm:flex-row"
        style={{
          marginBottom: 20,
          padding: 0,
          borderRadius: 5,
          backgroundColor: "#fff",
          flex: 1,
          overflow: "hidden",
          display: "flex",
        }}
      >
        <Link onClick={() => this._showSearchProperty()}>
          <div
            className={picArray ? "search-image" : "image-shimmer search-image"}
            style={{ backgroundImage: finalPic ? `url(${finalPic})` : "" }}
          />
        </Link>
        <div className="item-details-parent">
          <div
            style={{
              flex: 1,
              padding: 15,
              paddingBottom: 0,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                fontSize: 15,
                color: "#0a7fb5",
                fontFamily: "Roboto",
              }}
            >
              {this.props.data.hotel_name}
            </div>
            <div
              className="mt-2 sm:mt-5 font-bold capitalize"
            >
              {this.props.data.hotel_address}
            </div>
            <div className="mobile-hidden description_text">
              {this.props.data.description}
            </div>
          </div>
          <div className="item-hotel-highlights">
            <div style={{ display: "flex" }}>
              <div className="item-hotel-highlights-pusher" />
              <div>
                <div
                  style={{
                    backgroundColor: "#0a7fb5",
                    color: "white",
                    padding: 5,
                    display: "inline",
                    margin: 0,
                  }}
                >
                  {this.props.data.hotel_review_score == "undefined"
                    ? "0"
                    : this.props.data.hotel_review_score}
                </div>
              </div>
            </div>
            <div style={{ display: "flex", marginTop: 5 }}>
              <div className="item-hotel-highlights-pusher" />
              <div>
                <div style={{ display: "flex", marginTop: 5 }}>
                  {stars.map(() => {
                    return (
                      <div>
                        <FaStar style={{ color: "#ff6000" }} />
                      </div>
                    );
                  })}
                  {notstars.map(() => {
                    return (
                      <div>
                        <FaStar />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div className="item-hotel-highlights-pusher" />
              <div>
                <div style={{ fontFamily: "Roboto" }} className="mobile-hidden">
                  {this.props.data.hotel_number_of_reviews == "undefined"
                    ? "0"
                    : this.props.data.hotel_number_of_reviews}{" "}
                  reviews
                </div>
              </div>
            </div>
            <div style={{ display: "flex", marginTop: 10 }}>
              <div className="item-hotel-highlights-pusher" />

              <div>
                <div
                  style={{ textAlign: "right", fontSize: 12, marginBottom: 10 }}
                >
                  total for {this.props.days} night(s)
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    fontFamily: "Roboto",
                  }}
                >
                  {numberWithCommas(
                    this.props.data.calcPrice * this.props.selectedMultiplex
                  )}{" "}
                  {this.props.selectedCurrency}
                </div>
              </div>
            </div>
            <div className="flex mt-2">
              <div />
              <div className="w-full sm:w-auto pl-1 mt-0">
                {this.props.origin == "planner" ? (
                  <Link onClick={() => this._showSearchProperty()}>
                    <span
                      className={
                        "bg-secondary flex font-normal " +
                        "cursor-pointer font-bold text-white " +
                        "px-4 py-2 text-xs w-full sm:w-auto text-center " +
                        " items-center justify-center hover:bg-white " +
                        "hover:text-gray-600 hover:shadow-md transition-all"
                      }
                    >
                      <div>
                        {!this.props.plannerActive
                          ? "See Availability"
                          : "Add to trip"}{" "}
                      </div>
                      <div style={{ fontSize: 15, paddingLeft: 3 }}>
                        <FaAngleRight />
                      </div>
                    </span>
                  </Link>
                ) : (
                  <Link to={linkToView}>
                    <span
                      className={
                        "bg-secondary flex font-normal " +
                        "cursor-pointer font-bold text-white " +
                        "px-4 py-2 text-xs w-full sm:w-auto self-center " +
                        "items-center justify-center hover:bg-white " +
                        "hover:text-gray-600 hover:shadow-md transition-all"
                      }
                    >
                      <div className="text-center">
                        {!this.props.plannerActive
                          ? "See Availability"
                          : "Add to trip"}{" "}
                      </div>
                      <div style={{ fontSize: 15, paddingLeft: 3 }}>
                        <FaAngleRight />
                      </div>
                    </span>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ItemHotel);
