// @ts-check
////////////////////////////////////////////////////////////////////////////////
// JSDoc Types
////////////////////////////////////////////////////////////////////////////////
/**
 * @typedef {{
 * name: string,
 * id: string,
 * shortname: string,
 * }} Airport
 *
 * @typedef {{
 * flight_id: string,
 * origin: string,
 * destination: string,
 * stops: string[],
 * take_off: number,
 * landing: number,
 * duration: number,
 * type: "one_way" | "round_trip",
 * cabin: "economy" | "business" | "first_class",
 * airline: string,
 * airports: {
 *  origin: Airport,
 *  destination: Airport,
 * },
 * price: number,
 * }} Flight
 */

/**@type {Flight[]} */
export const flights = [
  {
    cabin: "economy",
    origin: "Nairobi",
    destination: "Mombasa",
    landing: Date.now() + 1000 * 60 * 60,
    take_off: Date.now(),
    stops: [],
    flight_id: "FLI-1",
    duration: 1000 * 10 * 60,
    type: "round_trip",
    price: 100,
    airline: "Jambo Jet",
    airports: {
      origin: {
        id: "_ui-2345-erty-iopa-23456",
        name: "Wilson Airport",
        shortname: "WIL",
      },
      destination: {
        id: "_yt-67890-fghty",
        name: "Mombasa International Airport",
        shortname: "MIA",
      },
    },
  },
  {
    cabin: "first_class",
    airline: "540 Airlines",
    origin: "Nairobi",
    destination: "Mombasa",
    landing: Date.now() + 1000 * 60 * 60,
    take_off: Date.now(),
    stops: [],
    flight_id: "FLI-1",
    duration: 1000 * 10 * 60,
    type: "round_trip",
    airports: {
      origin: {
        id: "_ui-2345-erty-iopa-23456",
        name: "Wilson Airport",
        shortname: "WIL",
      },
      destination: {
        id: "_yt-67890-fghty",
        name: "Mombasa International Airport",
        shortname: "MIA",
      },
    },
    price: 120,
  },
  {
    cabin: "business",
    airline: "540 Airlines",
    origin: "Nairobi",
    destination: "Mombasa",
    landing: Date.now() + 1000 * 60 * 60,
    take_off: Date.now(),
    stops: [],
    flight_id: "FLI-1",
    duration: 1000 * 10 * 60,
    type: "round_trip",
    airports: {
      origin: {
        id: "_ui-1234-poiu-rtyu-56789",
        name: "Wilson Airport",
        shortname: "WIL",
      },
      destination: {
        id: "_yt-67890-fghty",
        name: "Mombasa International Airport",
        shortname: "MIA",
      },
    },
    price: 105,
  },
];
