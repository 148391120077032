interface ActivitySearchParams {
  lat: number;
  lng: number;
  place: string;
  date: string;
  children: number;
  adults: number;
}

interface CarSearchParams {
  place: string;
  date: string;
  passengers: number;
  lat: number;
  lng: number;
}

interface ConferenceSearchParams {
  place: string;
  people: number;
  lat: number;
  lng: number;
  date: string;
}

interface EaterySearchParams {
  place: string;
  people: number;
  lat: number;
  lng: number;
  date: string;
}

interface HotelSearchParameters {
  checkin: string;
  checkout: string;
  lat: number;
  lng: number;
  place: string;
  adults: number;
  children: number;
}

interface RentalSearchParameters {
  checkin: string;
  checkout: string;
  lat: number;
  lng: number;
  place: string;
  children: number;
  adults: number;
}

interface TourSearchParameters {
  lat: number;
  lng: number;
  place: string;
  date: string;
  children: number;
  adults: number;
}

interface AttractionSearchParameters {
  lat: number;
  lng: number;
  place: string;
  date: string;
  children: number;
  adults: number;
}

export interface SearchParameters {
  activity: ActivitySearchParams;
  attraction: AttractionSearchParameters;
  car: CarSearchParams;
  conference: ConferenceSearchParams;
  eatery: EaterySearchParams;
  hotel: HotelSearchParameters;
  rental: RentalSearchParameters;
  tour: TourSearchParameters;
}

export const generatePropertySearchUrl = <T extends keyof SearchParameters>(
  pathname: string, category: T, propertyId: string,
  queryParams: SearchParameters[T],
): string => {
  const urlSearchParams = new URLSearchParams();

  for (const param in queryParams) {
    urlSearchParams.append(
      param, `${queryParams[param]}`,
    );
  }

  return `${pathname}/${category}/${propertyId}?${urlSearchParams.toString()}`;
};
