// @ts-check
import React from "react";
import {
  FaChevronDown,
  FaPlus,
  FaCalendarAlt,
  FaUser,
  FaMinus,
} from "react-icons/fa";
import {IoIosBed} from "react-icons/io";
import {Link} from 'react-router-dom';
import moment from "moment";

import LocationSearchInput from "./LocationSearchInput";
import {DatePick, RangePick} from "./RangePicker";

// TODO: Create a method to create the endDate that takes into account boundary conditions such
// as end of moonth in which `new Date().getDate() + 1` would lead to an invalid date

/**
 * This component displays the Search bar (Hotel Search Bar) to allow users to search for
 * hotels and plan based on the `time`, `dates` and `location` they would like to visit.
 *
 * Components
 * ----------
 * At the core, this component has the following children:
 * 1. Title - Detials what type of search the user is making (contained in the `this.state.hotel`)
 * 2. Dropdown - this allows the user to add or decrement the amount of people going to the trip
 *               based on two categories: `adult` and `child`
 * 3. Search Container - This has the following childre:
 *      1. LocationSearchInput - this helps the user to search based on location with help from the
 *                               places API from Google.
 *      2. Date Search - this allows the user to search as based on a certain time from either by:
 *          1. Range picker - this helps the user search by a time frame
 *          2. DatePicker - this helps the user search by a specific date.
 * 4. `Search Button` - This search button changes based on the `origin`
 *
 * How It Works
 * ------------
 * After a user enters the details by which they would like to search by:
 * - The places API is used to make query based on the location and give back the coordinates
 * - The search button sends the search query results to a different destination as based on the
 *    `origin` (this is the route that came before the search). These routes are:
 *    - `trip-planner` - this proceeds to the places route with the results given.
 *    - `/` - this proceeds to the `/search/hotels` route to show the hotel results
 *
 */
export default class Activities extends React.Component {
  constructor(props) {
    super(props);

    let from = "";
    let to = "";
    let departure = "checkin";
    let returnDate = "checkout";

    if (props.origin === "trip-planner") {
      from =
        /[A-Za-z0-9]+-_[A-Za-z0-9]+/g
          .test(this.props.sharedState.trip_data.trip_origin) ?
            this.props.sharedState.trip_data.trip_origin.replace("-_", ", ") :
            this.props.sharedState.trip_data.trip_origin;
      to =
        /[A-Za-z0-9]+-_[A-Za-z0-9]+/g
          .test(this.props.sharedState.trip_data.trip_destination) ?
            this.props.sharedState.trip_data.trip_destination.replace("-_", ", ") :
            this.props.sharedState.trip_data.trip_destination;

      departure = this.props.sharedState.trip_data.startDate;
      returnDate = this.props.sharedState.trip_data.returnDate;
    }


    this.state = {
      title: "Add a hotel booking to your trip",
      from,
      to,
      defaultDestination: to,
      departure,
      return: returnDate,
      type: "round-trip",
      seat: "economy",
      adults: 1,
      children: 0,
      isStartDate: false,
      minimumDate: false,
      latLng: {
        lat: 0,
        lng: 0,
      },
      searchContainerOffset: null,
      searchContainerOffsetLeft: null,
      locationReady: true,
    };
    /** @type {React.RefObject<HTMLDivElement>} */
    this.searchContainerRef = React.createRef();
  }


  _showError = () => {
    alert("Enter the flight information to proceed!");
  };

  _selectToAddress = (place, latLng) => {
    // const city = place.replace(/ /g, "_").replaceAll(",", "-");
    const city = place;
    this.setState({ to: city, latLng});
  };

  _selectFromAddress = (place, latLng) => {
    // const city = place.replace(/ /g, "_").replaceAll(",", "-");
    const city = place;
    this.setState({ from: city, latLng });
  };

  _showDatePicker = () => {
    this.setState({ rangePickerVisible: false, datePickerVisible: true });
  };

  _showRangePicker = () => {
    this.setState({ datePickerVisible: false, rangePickerVisible: true });
  };

  _selectRange = (date) => {
    const formattedStartDate = moment(date[0].startDate).format("DD-MM-YYYY");
    const formattedReturnDate = moment(date[0].endDate).format("DD-MM-YYYY");
    //console.log(startDate, formattedDate);
    const minimumDate = date[0].startDate;
    minimumDate.setDate(minimumDate.getDate() + 1);
    this.setState({
      rangePickerVisible: false,
      departure: formattedStartDate,
      return: formattedReturnDate,
      minimumDate,
    });
  };

  _selectDate = (date) => {
    if (!this.state.isStartDate) {
      const formattedReturnDate = moment(date).format("DD-MM-YYYY");
      this.setState({ datePickerVisible: false, return: formattedReturnDate });
    }
    if (this.state.isStartDate) {
      const minimumDate = date;
      minimumDate.setDate(minimumDate.getDate() + 1);
      const formattedStartDate = moment(date).format("DD-MM-YYYY");
      this.setState({
        datePickerVisible: false,
        departure: formattedStartDate,
        minimumDate,
      });
    }
    // const formattedStartDate = moment(date[0].startDate).format('DD-MM-YYYY');
    // const formattedReturnDate = moment(date[0].endDate).format('DD-MM-YYYY');
    // //console.log(startDate, formattedDate);
    // this.setState({
    //   datePickerVisible: false,
    //   departure: formattedStartDate,
    //   return: formattedReturnDate,
    // });
  };

  // eslint-disable-next-line no-unused-vars
  _openSearch = (address) => {
    this.props.setSharedState({
      tabArray: [
        { title: "trip-view", active: false },
        { title: "navigator-view", active: false },
        { title: "search-results", active: true },
        { title: "view-property", active: false },
        { title: "checkout", active: false },
      ],
    });

    setTimeout(() => {
      location.reload();
    }, 200);
  };

  /**
   * This method updates the current position of the searchinput to allow for
   * the date picker to be positioned correctly.
   */
  _setSearchContainerPosition = () => {
    this.setState({
      searchContainerOffset: this.searchContainerRef.current.offsetTop,
      searchContainerOffsetLeft: this.searchContainerRef.current.offsetLeft,
    });
    console.warn(
      `Coordinates set to\ntop: ${
        this.searchContainerRef.current.offsetTop
      }\nleft${this.searchContainerRef.current.offsetLeft}`
    );
  };

  render() {
    return (
      <div className="flex-div" style={{ overflowY: "visible" }}>
        <div className="tab-content">
          <h1 className="tab-caption">{this.state.title}</h1>
          {/* This dropdown is styled to open upon hover */}
          <div className="dropdown ">
            <div className="flex-div flex-row">
              <div className="tab-dropdown-parent">
                <FaUser
                  className="inline"
                  style={{ fontSize: 11, marginRight: 10 }}
                />
                <span>
                  {this.state.adults} Adult{this.state.adults > 1 ? "s" : ""}{" "}
                </span>
              </div>
              <div className="tab-dropdown-parent">
                <span>
                  {this.state.children} Child
                  {this.state.children > 1 ? "ren" : ""}{" "}
                </span>
                <FaChevronDown
                  className="inline"
                  style={{ fontSize: 11, marginLeft: 10 }}
                />
              </div>
            </div>
            <div className="dropdown-content">
              <div>
                <div className="title">Adults</div>
                <div className="flex-div flex-row">
                  <div
                    className="circle-but"
                    onClick={() => {
                      let newNum = this.state.adults;
                      if (newNum !== 0) {
                        newNum--;
                      }
                      this.setState({ adults: newNum });
                    }}
                  >
                    <FaMinus />
                  </div>
                  <div className="num">
                    <b>{this.state.adults}</b>
                  </div>
                  <div
                    className="circle-but"
                    onClick={() => {
                      let newNum = this.state.adults;
                      console.info(`New Number: ${newNum}`);
                      if (newNum < 10) {
                        newNum++;
                      }
                      this.setState({ adults: newNum });
                    }}
                  >
                    <FaPlus />
                  </div>
                </div>
                <div className="title">Children</div>
                <div className="flex-div flex-row">
                  <div
                    className="circle-but"
                    onClick={() => {
                      let newNum = this.state.children;
                      if (newNum !== 0) {
                        newNum--;
                      }
                      this.setState({ children: newNum });
                    }}
                  >
                    <FaMinus />
                  </div>
                  <div className="num">
                    <b>{this.state.children}</b>
                  </div>
                  <div
                    className="circle-but"
                    onClick={() => {
                      let newNum = this.state.children;
                      if (newNum < 10) {
                        newNum++;
                      }
                      this.setState({ children: newNum });
                    }}
                  >
                    <FaPlus />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End of dropdown */}
        </div>
        {/* Input Fields */}
        <div className="tab-input-fields">
          <div style={{ flex: 1 }} className="tab-input-fields-content">
            <div
              className={
                "flex-div flex-row tab-location-search-input items-center relative"
              }
              style={{
                borderRadius: 0,
                padding: 6,
                background: "white",
                flex: 2,
                alignItems: "center",
              }}
            >
              <IoIosBed size={28} color="#666" />
              {this.state.locationReady && (
                <LocationSearchInput
                  style={{
                    border: "0px solid #ccc",
                    paddingLeft: 10,
                    width: "100%",
                    position: "relative",
                  }}
                  defaultValue={this.state.from}
                  placeholder="Where do you want to go?"
                  selectAddress={this._selectFromAddress}
                  onChange={(e) => {
                    this.setState({from: e.target.value})
                  }}
                  suggestionsContainerStyle={{}}
                />
              )}
              {/* <input
                onChange={(event) => {
                  this.setState({
                    Departure: event.target.value.toLocaleLowerCase(),
                  });
                }}
                placeholder="From"
                style={{
                  border: "0px solid #ccc",
                  fontSize: 24,
                  paddingLeft: 10,
                  width: 160,
                }}
              /> */}
            </div>
            {/* {this.state.rangePickerVisible && (
              <div style={{position: "relative"}}>
                <RangePick
                  selectDate={this._selectRange}
                  minDate={new Date()}
                  showDatePicker={(rangePickerVisible) => this.setState({rangePickerVisible})}
              />
              </div>
            )} */}
            <div
              ref={this.searchContainerRef}
              style={{ flex: 1, display: "flex", position: "relative" }}
            >
              <div
                ref={this.searchContainerRef}
                className="flex-div flex-row tab-date-input-parent items-center"
              >
                <FaCalendarAlt size={28} color="#666" />
                <div
                  onClick={() => {
                    this.setState({ isStartDate: true });
                    this._showRangePicker();
                  }}
                  className="tab-date-input"
                >
                  {this.state.departure}
                </div>
              </div>
              <div
                className="flex-div flex-row tab-date-input-parent items-center"
              >
                <FaCalendarAlt size={28} color="#666" />
                <div
                  onClick={() => {
                    this.setState({ isStartDate: false });
                    if (this.state.return == "checkout") {
                      this._showRangePicker();
                    } else {
                      this._showDatePicker();
                    }
                  }}
                  className="tab-date-input"
                >
                  {this.state.return}
                </div>
              </div>
              {this.state.rangePickerVisible && (
                <div>
                  <RangePick
                    selectDate={this._selectRange}
                    minDate={new Date()}
                    showDatePicker={(rangePickerVisible) =>
                      this.setState({ rangePickerVisible })
                    }
                  />
                </div>
              )}
              {this.state.datePickerVisible && (
                <div>
                  {this.state.return == "checkout" || this.state.isStartDate ? (
                    <DatePick
                      selectDate={this._selectDate}
                      minDate={new Date()}
                      showDatePicker={(datePickerVisible) =>
                        this.setState({ datePickerVisible })
                      }
                    />
                  ) : (
                    <DatePick
                      selectDate={this._selectDate}
                      minDate={this.state.minimumDate}
                      showDatePicker={(datePickerVisible) =>
                        this.setState({ datePickerVisible })
                      }
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          {this.state.from.length == 0 ? (
            <div>
              <div
                onClick={this._showError}
                className="flex-div but but-orange index-search-but"
              >
                SEARCH
              </div>
            </div>
          ) : (
            <>
              {this.props.origin == "trip-planner" ? (
                <div>
                  <Link
                    onClick={this._openSearch}
                    to={
                      `${window.location.pathname}?` +
                      `place=${
                        this.state.from
                      }&lat=${
                        this.state.latLng.lat
                      }&lng=${
                        this.state.latLng.lng
                      }&checkin=${this.state.departure}&checkout=${
                        this.state.return
                      }&adults=${this.state.adults}&children=${
                        this.state.children
                      }#search-results-hotel`
                    }
                  >
                    <div className=" flex-div but but-orange index-search-but">
                      SEARCH
                    </div>
                  </Link>
                </div>
              ) : (
                <Link
                  to={`/search-property/hotel?place=${
                    this.state.from
                  }&lat=${
                    this.state.latLng.lat
                  }&lng=${
                    this.state.latLng.lng
                  }&checkin=${this.state.departure}&checkout=${
                    this.state.return
                  }&adults=${this.state.adults}&children=${
                    this.state.children
                  }`}
                >
                  <div className=" flex-div but but-orange index-search-but">
                    SEARCH
                  </div>
                </Link>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}
