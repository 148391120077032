import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { FC, useContext, useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { IoMdSave } from "react-icons/io";
import { RouteComponentProps, useHistory } from "react-router";

import { fetchRoute, setInfo } from "../../../api/info";
import { Button } from "../../../components";
import { Input } from "../../../components/input";
import ActivityIndicator from "../../../components/loading";
import { OverlayProvider } from "../../../context";
import { SnackbarProvider } from "../../../context/snackbar-provider";

type AboutEditorProps = RouteComponentProps<{ route: string }>;

export const AboutEditor: FC<AboutEditorProps> = ({ match }) => {
  const {setIsCancellable, setIsOverlay, setOnOverlay} =
    useContext(OverlayProvider);
  const {dismissSnackbar, setIsVisible} = useContext(SnackbarProvider);

  const history = useHistory();

  const [title, setTitle] = useState<string>(normaliseText(match.params.route));
  const [content, setContent] = useState<EditorState>(
    EditorState.createEmpty()
  );

  useEffect(() => {
    fetchRoute(match.params.route, (err, routeInfo) => {
      if (err) {
        console.error(err);

        return;
      }

      if (routeInfo) {
        setTitle(routeInfo.title);
        setContent(
          EditorState.createWithContent(convertFromRaw(routeInfo.content))
        );
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="w-full h-full">
      <div
        className="w-ful px-4 relative flex-1 py-4 no-scrollbar"
        style={{
          transitionDuration: "150ms",
          transitionProperty: "height",
          height: window.innerHeight - 56,
          overflowY: "scroll",
        }}
      >
        <div className="flex flex-row justify-between my-3 items-center">
          <Input
            helper="This is the title of the route blog"
            id="blog-title"
            inputLabel="Blog title"
            name="blog_title"
            value={title}
            className="w-80"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
          <Button
            type="button"
            onClick={() => {
              setOnOverlay &&
                setOnOverlay(() => () => <ActivityIndicator caption="" />);

              setIsCancellable && setIsCancellable(false);
              setIsOverlay && setIsOverlay(true);

              // start the uploading process
              // since the route name may have been changed by the user,
              // we make sure to normalise the title fresh instead of using the
              // already normalised one in :route
              setInfo(
                title.trim().toLowerCase().replace(" ", "_"),
                {
                  title,
                  content: convertToRaw(content.getCurrentContent()),
                },
                (err, isSuccessful) => {
                  setIsOverlay && setIsOverlay(false);
                  setOnOverlay && setOnOverlay(null);
                  setIsCancellable && setIsCancellable(true);

                  if (err) {
                    setIsVisible &&
                      setIsVisible({
                        fabPresent: false,
                        isError: true,
                        navRailPresent: true,
                        title: "Unable to upload route",
                        action: {
                          label: "DISMISS",
                          onClick: () => {
                            dismissSnackbar && dismissSnackbar();
                          },
                        },
                      });

                    return;
                  }

                  if (isSuccessful) {
                    history.replace("/su/about");

                    setIsVisible &&
                      setIsVisible({
                        fabPresent: false,
                        isError: false,
                        navRailPresent: true,
                        title: "Route successfully uploaded",
                        action: {
                          label: "DISMISS",
                          onClick: () => {
                            dismissSnackbar && dismissSnackbar();
                          },
                        },
                      });
                  } else {
                    setIsVisible &&
                      setIsVisible({
                        fabPresent: false,
                        isError: true,
                        navRailPresent: true,
                        title: "Unable to upload route",
                        action: {
                          label: "DISMISS",
                          onClick: () => {
                            dismissSnackbar && dismissSnackbar();
                          },
                        },
                      });
                  }
                }
              );
            }}
          >
            <div className="flex flex-row items-center">
              <IoMdSave size={24} className="mr-3" />
              Save Route
            </div>
          </Button>
        </div>

        <div className="relative">
          <Editor
            editorClassName="mx-auto mt-3 w-full max-w-5xl shadow-lg border p-4"
            toolbarClassName="top-0 sticky z-30 bg-white"
            editorState={content}
            onEditorStateChange={(editorState) => setContent(editorState)}
          />
        </div>
      </div>
    </div>
  );
};

const normaliseText = (value: string) => {
  const newValue = value.replace("_", " ");
  const splitValue = newValue.split("");
  splitValue[0] = value[0].toUpperCase();

  return splitValue.join("");
};
