import {FC} from 'react';
import { EditableOffer } from '.';

import {Car, Property, ReservationCategory} from '../../../../api';
import {PriceChange} from '../../../../api/hotel/Offer';

import {SeasonalPriceChange} from "../../components/seasonal-price-change";

import {CreatedOffer} from './created-offer';

type SearchColumnProps = {
  category: ReservationCategory;
  property: Record<string, unknown>;
  propertyId: string;
  offers: EditableOffer[];
  addToCalendar: (newOffer: Record<string, unknown>) => void;
  editOffer: (editedOffer: EditableOffer) => void;
  removeFromCalendar: (offerToBeDeleted: EditableOffer) => void;
};

const TODAY_TIMESTAMP = Date.now();

export const SearchColumn: FC<SearchColumnProps> = ({
  category, property, offers, propertyId, addToCalendar, editOffer,
  removeFromCalendar,
}) => {
  console.log("Offers", offers);

  return (
    <div
      className="p-4 flex-grow-0 flex-shrink-0"
    >
      <SeasonalPriceChange
        category={category}
        property={property}
        propertyId={propertyId}
        removeOverlay={() => {
          console.log("Removing overlay");
        }}
        addToCalendar={addToCalendar}
      />
      <h6 className="font-lato font-semibold">Upcoming price changes</h6>
      {/* 
        We check the end date to make sure that we are only altering price
        changes that are in effect and/or will be in effect in future 
      */}
      {(offers as unknown as PriceChange[])
        .filter(offer => offer.endDate >= TODAY_TIMESTAMP)
        .map((offer, idx) => (
          <CreatedOffer
            key={`${category}_offer_${idx}`}
            offer={offer as unknown as PriceChange & EditableOffer}
            property={property as Property | Car}
            category={category}
            editOffer={editOffer}
            removeFromCalendar={removeFromCalendar}
          />
        ))}
    </div>
  );
};
