import { FaChevronRight } from "react-icons/fa";
import RangeSlider from "../RangeSlider";

import {SectionHeader} from "./section-header";

export function PriceFilter({sharedState, updateState}) {
  const onPriceRangeChange = (value) => {

    const url = new URL(location.href);
    const minPrice = Math.floor(
      (value[0] / 100) * sharedState.maxPrice
    );

    const maxPrice = Math.floor(
      (value[1] / 100) * sharedState.maxPrice
    );

    if (minPrice !== sharedState.minPrice) {
      if (url.searchParams.has("price_lowest")) {
        if (
          Number(url.searchParams.get("price_lowest")) !== minPrice
        ) {
          url.searchParams.set("price_lowest", `${minPrice}`);
        }
      } else {
        url.searchParams.append("price_lowest", `${minPrice}`)
      }
    }

    if (maxPrice !== sharedState.maxPrice) {
      if (url.searchParams.has("price_highest")) {
        if (
            Number(url.searchParams.get("price_highest")) !== 
              maxPrice
          ) {
          url.searchParams.set("price_highest", `${maxPrice}`);
        }
      } else {
        url.searchParams.append("price_highest", `${maxPrice}`);
      }
    }

    history.replaceState(null, "", url.toString());
    
    updateState({
      rangeMin: minPrice,
      rangeMax: maxPrice,
      priceChanged: true,
    });
  };

  return (
    <div className="max-w-xs place-self-center shadow-md">
      <SectionHeader style={{borderTop: 0}}>
        Price Range
      </SectionHeader>
      <div
        className="text-sm mb-3 mt-2 p-2 mx-7"
        style={{backgroundColor: "#FFE9E9"}}
      >
        Filter Hotels Based on their total Price for {" "}
        {sharedState.numberOfDays} days(s)
      </div>
      <div
        className="mb-3 p-7 pt-3 rounded-none bg-white flex flex-col"
      >
        <RangeSlider
          onRangeChange={onPriceRangeChange}
          rangeMin={sharedState.rangeMin}
          rangeMax={sharedState.rangeMax}
          maxPrice={sharedState.maxPrice}
        />
        <div className="flex flex-row p-1">
          <div className="text-xs">
            {`${sharedState.rangeMin} USD`}
          </div>
          <div
            className="text-xs text-center pt-1"
            style={{ flex: 1 }}
          >
            <FaChevronRight />
          </div>
          <div className="text-xs text-right">
            {`USD ${sharedState.rangeMax}`}
          </div>
        </div>
      </div>
    </div>
  );
}
