// @ts-check
import {useState} from "react";
import {connect} from "react-redux";

import {fetchOptionalFilters} from "../../reducers";

import Header from "../index/components/Header";
import {AppFooter} from "../index/components/app-footer";

import LeftColumn from "./LeftColumn";
import ResultsMain from "./Results_Main";
import ResultsMap from "./Results_Map";
import Selector_Header from "./Selector_Header";
import DataFetcher from "./data-fetcher";
import {MobileFilterContext} from "./mobile-filter-context";

////////////////////////////////////////////////////////////////////////////////
// JS Docs Type
////////////////////////////////////////////////////////////////////////////////
/**
 *
 * @typedef {"none" | "rating" | "price" | "features" | "facilities"} VisibleFilter
 * @typedef {{
 * visibleFilter: VisibleFilter,
 * setVisibleFilter: ((visibleFilter: VisibleFilter) => void),
 * }} MobileFilterContextValue
 */


const Search = ({selected_currency, selected_multiplex}) => {
  const [sharedState, setSharedState] = useState({
    displayMode: "normal",
    searchDone: false,
    category: false,
    searchResults: [],
    rawResults: [],
    city: "",
    departure: "checkin",
    return: "checkout",
    checkinDate: "none",
    checkoutDate: "none",
    searchItemsLength: 0,
    rangeMin: 0,
    rangeMax: 0,
    minPrice: 0,
    maxPrice: 0,
    priceChanged: false,
    pagination: 1,
    pagesArray: [],
    newCity: "",
    rating_category: [],
    numberOfDays: 0,
    daysSelected: [],
    plannerActive: false,
    adults: 0,
    center: {
      lat: 0,
      lng: 0,
    },
    urlParameters: "",
    facilities: [
      { title: "Air Conditioning", value: "aircon" },
      { title: "Airport Transport", value: "air_transport" },
      { title: "Fitness Center", value: "fitness_center" },
      { title: "Flat Tv", value: "flat_tv" },
      { title: "Heater", value: "heater" },
      { title: "Wi-Fi Internet", value: "internet" },
      { title: "Parking", value: "parking" },
      { title: "Pool", value: "pool" },
      { title: "Restaurant", value: "restaurant" },
      { title: "Shuttle Bus Service", value: "shuttle_bus_service" },
      { title: "Smoking Room", value: "smoking_room" },
      { title: "SPA", value: "spa" },
      { title: "Spa and Sauna", value: "sauna" },
      { title: "Vocabulary", value: "vocabulary" },
      { title: "Washer &amp; Dryer", value: "washer" },
    ],
    themes: [
      { title: "Best value", value: "best_value" },
      { title: "Business", value: "business" },
      { title: "Family-friendly", value: "family_friendly" },
      { title: "Mid-range", value: "mid_range" },
      { title: "Quite", value: "quiet" },
      { title: "Trendy", value: "trendy" },
      { title: "Boutique", value: "boutique" },
      { title: "Charming", value: "charming" },
      { title: "Green", value: "green" },
      { title: "Party", value: "party" },
      { title: "Romantic", value: "romantic" },
      { title: "Budget", value: "budget" },
      { title: "Luxury", value: "luxury" },
      { title: "Classic", value: "classic" },
      { title: "Quaint", value: "quaint" },
      { title: "Standard", value: "standard" },
    ],
    selected_currency,
    selected_multiplex,
  });

  const [visibleFilter, setVisibleFilter] = useState("none");
  const [newSearchFilter, setNewSearchFilterVisible] = useState(false);

  // eslint-disable-next-line
  // const [state] = useSearchReducer();

  // TODO: Add a stale flag in the parameters to prevent unecessary filter
  // process when the data is not bound to change
  const _updateState = (newState) => {
    // after the state us updated, we have the option of filtering all the
    // results by the optional filters that have currently been applied to in
    // the document url.
    // Before each filter is applied to the data, we have to first check to
    // ensure that the filter exists in the URL, this is to prevent the
    // returning of an empty array after filter since no object meets the
    // condition `null` if the filter does not exist.
    // if the new state has updated results, we use those, otherwise we use the
    // the results from the previously stored state.
    let searchResults = (newState.rawResults) ?
      newState.rawResults: sharedState.rawResults;
    const url = new URL(location.href);
    const optionalFilters = fetchOptionalFilters();

    // prevent filtering in the instance that the header filters are used
    if (newState.filter !== false) {
      if (url.searchParams.has("star_rating")) {
        searchResults = filterByStars(optionalFilters.star_rating, searchResults);
      }
      // filter by the price
      if (
        url.searchParams.has("price_highest") ||
        url.searchParams.has("price_lowest")
      ) {
        searchResults = filterByPrice(
          optionalFilters.price_lowest, optionalFilters.price_highest,
          searchResults,
        );
      }
    }
    // flter by the stars

    setSharedState({...sharedState, ...newState, searchResults});
  };

  const filterByStars = (rating, results) => {
    const filteredResults = results.filter((result) => 
      Number(result.hotel_star) === rating);
    // sort the results
    filteredResults.sort((a, b) => b - a);

    return filteredResults;
  };

  const filterByPrice = (min, max, results) => {
    let newResults = results;
    // explicit comparison is done since lack of will lead to the JSVM resorting
    // to use of truthy calues in which 0 will be read as false, and that is
    // unpexpected behaviour.
    if (max !== null && max !== undefined) {
      newResults = newResults.filter((result) => result.calcPrice <= max);
    }

    if (min !== null && min !== undefined) {
      newResults = newResults.filter((result) => result.calcPrice >= min);
    }

    return newResults;
  };

  return (
    <MobileFilterContext.Provider
      value={{
        visibleFilter,
        newSearchFilter,
        setVisibleFilter,
        setNewSearchFilterVisible,
      }}
    >
      <Header />
      <div className="flex-div search-parent">
        {/* <Header/> */}
        <div className="display-port page-parent">
          <LeftColumn
            sharedState={sharedState}
            updateState={_updateState}
          />
          <div className="main-column">
            <Selector_Header
              sharedState={sharedState}
              updateState={_updateState}
            />
            <ResultsMain
              sharedState={sharedState}
              origin="search"
              updateState={_updateState}
            />
            {
              !sharedState.searchDone && (
                <DataFetcher
                  updateState={_updateState}
                />
              )
            }
          </div>
        </div>
        {sharedState.displayMode == "map" && (
          <ResultsMap
            sharedState={sharedState}
            updateState={_updateState}
          />
        )}
      </div>
      <AppFooter origin="" />
    </MobileFilterContext.Provider>
  );
}

const mapStateToProps = (state) => {
  return {
    // multiplex_usd: state.multiplex_usd,
    // multiplex_kes: state.multiplex_kes,
    // multiplex_pound: state.multiplex_pound,
    // multiplex_euro: state.multiplex_euro,
    selected_currency: state.selected_currency,
    selected_multiplex: state.selected_multiplex,
  };
};

export default connect(mapStateToProps)(Search);
