import {FaStar} from 'react-icons/fa';

/**
 * Creates a list of `n` stars in a line give the rating `n`
 *
 * @param {{
 * rating: 1 | 2 | 3 | 4 | 5,
 * checked: boolean,
 * onChange: import('react').ChangeEventHandler<HTMLInputElement>,
 * }} props
 * 
 * @returns {JSX.Element} 
 */
export function Rating({rating, checked, onChange}) {
  // creates a dummy array with `rating values`
  const arr = [];
  // populates the array with `rating number of values`
  for (let i=0; i<rating; i++) {
    arr.push(1);
  }

  return (
    <div style={{ display: "flex", paddingTop: 7, paddingBottom: 7 }}>
      <div style={{ flex: 1, textAlign: "left", display: "flex" }}>
        <input
          type="checkbox"
          id={`${rating}str`}
          name={`${rating}str`}
          onChange={onChange}
          style={{ width: 30 }}
          checked={checked}
        />
        <label htmlFor={`${rating}str`} style={{ marginTop: -6, paddingTop: 0 }}>
          <div style={{ display: "flex", marginTop: 5 }}>
            {arr.map((_, idx) => (
              <div key={`${rating}str_${idx}`}>
                <FaStar style={{ color: "#FF6000" }} />
              </div>
            ))}
          </div>
        </label>
      </div>
    </div>
  );
}
