import {Component} from "react";

import LoginScreen from "./LoginScreen";
import RegisterScreen from "./RegisterScreen";
import Loading from "./Loading";
import LoggedIn from "./LoggedIn";
import { auth, database } from "firebase";

class AuthContainer extends Component {
  state = {
    visibleScreen: "loading",
    registrationCompletion: false,
    userData: [],
  };

  constructor(props) {
    super(props);
    this._changeScreenInFocus = this._changeScreenInFocus.bind(this);
  }

  _changeScreenInFocus(newScreen) {
    this.setState({ visibleScreen: newScreen });
  }

  componentDidMount() {
    auth().onAuthStateChanged((user) => { // eslint-disable-line no-undef
      console.info(user);
      if (user) {
        database()
          .ref(`Users_Master/${user.uid}`)
          .on("value", (snapshot) => {
            if (snapshot.exists()) {
              console.log("data", snapshot.val());
              const registrationComplete = snapshot.val().registrationComplete;
              //console.log(auth + " " + user.uid);
              if (registrationComplete) {
                this.setState({
                  registrationCompletion: true,
                  visibleScreen: "loggedin",
                  userData: snapshot.val(),
                });
                return;
              }
              if (!registrationComplete) {
                this.setState({
                  registrationCompletion: true,
                  visibleScreen: "register",
                });
              }
            } else {
              this.setState({
                registrationCompletion: true,
                visibleScreen: "register",
              });
            }
          });
      } else {
        if (this.props.origin === "list-property-page") {
          this.setState({
            registrationCompletion: false,
            visibleScreen: "register",
          });
          return;
        }
        this.setState({
          registrationCompletion: false,
          visibleScreen: "login",
        });
        //console.log(user);
      }
    });
    console.log(this.props.origin);
  }

  render() {
    return (
      <div className="signin-container">
        {this.state.visibleScreen === "loading" && <Loading />}
        {this.state.visibleScreen === "loggedin" && (
          <LoggedIn
            userData={this.state.userData}
            origin={this.props.origin}
            location={this.props.location}
          />
        )}
        {this.state.visibleScreen === "login" && (
          <div className="flex flex-row items-center">
            <div
              className="w-full-mx-2 mx-2 self-center md:w-4/5 md:mx-0"
            >
              <LoginScreen changeScreen={this._changeScreenInFocus} />
            </div>
          </div>
        )}
        {this.state.visibleScreen === "register" && (
          <div className="flex flex-row items-center">
            <div
              className="w-full-mx-2 mx-2 self-center md:w-4/5 md:mx-0"
            >
              <RegisterScreen
                changeScreen={this._changeScreenInFocus}
                registrationCompletion={this.state.registrationCompletion}
                origin={this.props.origin}
              />
            </div>
          </div>
        )}
        {this.state.visibleScreen === "recover" && <LoginScreen />}
      </div>
    );
  }
}

export default AuthContainer;
