import TabHost from "./navigator/TabHost";

const App = ({sharedState, setSharedState, tabView}) => {
  return (
    <>
      <div
        className="planner-search-parent"
        style={{
          height: "90vh",
          background: "#044348",
          clipPath: "polygon(0 0, 100% 0%, 100% 73%, 52% 100%, 0 75%)",
        }}
      >
        <TabHost
          sharedState={sharedState}
          setSharedState={setSharedState}
          tabView={tabView}
        />
        {/* <p style={{ textAlign: "center", color: "white" }}>
          The properties you add from the search above will be added to your
          trip to your trip
        </p> */}
      </div>
    </>
  );
};

export default App;
