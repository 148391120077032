import {useReducer} from 'react';

import {Affiliate, AffiliateSummary, ReservationCategory} from '../api';

export type AffiliateState = {
  affiliateLinks: Affiliate[];
  activeCategory: ReservationCategory;
  activePropertyIdx: number;
  affiliateSummary: AffiliateSummary;
};

enum AffiliateReducerActionType {
  SET_AFFILIATE_LINKS = 1,
  SET_ACTIVE_CATEGORY,
  SET_ACTIVE_PROPERTY,
  SET_AFFILIATE_SUMMARY,
}

type AffiliateReducerAction = {
  type: AffiliateReducerActionType;
  payload: unknown;
};

function affiliateReducer(
  state: AffiliateState, action: AffiliateReducerAction,
): AffiliateState {
  switch (action.type) {
    case AffiliateReducerActionType.SET_AFFILIATE_LINKS:
      return ({
        ...state,
        affiliateLinks: action.payload as Affiliate[],
      });
    case AffiliateReducerActionType.SET_ACTIVE_CATEGORY:
      return ({
        ...state,
        activeCategory: action.payload as ReservationCategory,
      });
    case AffiliateReducerActionType.SET_ACTIVE_PROPERTY:
      return ({
        ...state,
        activePropertyIdx: action.payload as number,
      });
    case AffiliateReducerActionType.SET_AFFILIATE_SUMMARY:
      return ({
        ...state,
        affiliateSummary: action.payload as AffiliateSummary,
      });
    default:
      return state;
  }
}

export const initialState: AffiliateState = {
  affiliateLinks: [],
  activeCategory: "hotel",
  activePropertyIdx: -1,
  affiliateSummary: {
    affiliateLinks: {
      activity: {
        category: "activity",
        currentMonth: [],
        follow_throughs: 0,
        impressions: 0,
        links: [],
        property_summary: {},
      },
      car: {
        category: "car",
        currentMonth: [],
        follow_throughs: 0,
        impressions: 0,
        links: [],
        property_summary: {},
      },
      conference: {
        category: "conference",
        currentMonth: [],
        follow_throughs: 0,
        impressions: 0,
        links: [],
        property_summary: {},
      },
      cruise: {
        category: "cruise",
        currentMonth: [],
        follow_throughs: 0,
        impressions: 0,
        links: [],
        property_summary: {},
      },
      eatery: {
        category: "eatery",
        currentMonth: [],
        follow_throughs: 0,
        impressions: 0,
        links: [],
        property_summary: {},
      },
      flight: {
        category: "flight",
        currentMonth: [],
        follow_throughs: 0,
        impressions: 0,
        links: [],
        property_summary: {},
      },
      hotel: {
        category: "hotel",
        currentMonth: [],
        follow_throughs: 0,
        impressions: 0,
        links: [],
        property_summary: {},
      },
      rental: {
        category: "rental",
        currentMonth: [],
        follow_throughs: 0,
        impressions: 0,
        links: [],
        property_summary: {},
      },
      tour: {
        category: "tour",
        currentMonth: [],
        follow_throughs: 0,
        impressions: 0,
        links: [],
        property_summary: {},
      },
    },
    currentMonth: [],
    follow_throughs: 0,
    impressions: 0,
  },
};

export type AffiliateReducer = {
  state: AffiliateState;
  actions: {
    setAffiliateLinks: (links: Affiliate[]) => void;
    setActiveCategory: (activeCategory: ReservationCategory) => void;
    setActivePropertyIdx: (propertyIdx: number) => void;
    setAffiliateSummary: (affiliateSummary: AffiliateSummary) => void;
  },
};

export const useAffiliateReducer = (): AffiliateReducer => {
  const [state, dispatch] = useReducer(affiliateReducer, initialState);

  const setAffiliateLinks = (links: Affiliate[]) => {
    dispatch({
      type: AffiliateReducerActionType.SET_AFFILIATE_LINKS,
      payload: links,
    });
  };

  const setActiveCategory = (activeCategory: ReservationCategory) => {
    dispatch({
      type: AffiliateReducerActionType.SET_ACTIVE_CATEGORY,
      payload: activeCategory,
    });
  };

  const setActivePropertyIdx = (activePropertyIdx: number) => {
    dispatch({
      type: AffiliateReducerActionType.SET_ACTIVE_PROPERTY,
      payload: activePropertyIdx,
    });
  };

  const setAffiliateSummary = (affiliateSummary: AffiliateSummary) => {
    dispatch({
      type: AffiliateReducerActionType.SET_AFFILIATE_SUMMARY,
      payload: affiliateSummary,
    });
  };

  return {
    state,
    actions: {
      setAffiliateLinks,
      setActiveCategory,
      setActivePropertyIdx,
      setAffiliateSummary,
    },
  };
};
