// @ts-check
import {TripPlannerCard} from '../../../components/trip-planner-card';
import { eateries } from '../../../dummy-data';

/**
 * @typedef {{
 * id: number,
 * checkin: string,
 * menu_item: string,
 * }} CateringListItem
 *
 * @typedef {{
 * cateringList: CateringListItem[],
 * }} CateringListProps
 */

/**@type {import('react').FC<CateringListProps>}>} */
export const CateringList = ({cateringList}) => {
  return (
    <div>
      {cateringList.map((item, idx) => (
        <TripPlannerCard
          title={eateries[item.id].title}
          action={{
            label: "Remove",
            onClick: () => console.log("Removing Eatery"),
          }}
          description={eateries[item.id].description}
          overline={item.checkin}
          price={10}
          subtitle={eateries[item.id].location}
          key={`travel_list_${idx}_${eateries[item.id].email}`}
          cover="/public/eatery.jpg"
        />
      ))}
    </div>
  );
};
