import React, { Component } from "react";
import { FiCalendar } from "react-icons/fi";
class App extends Component {
  render() {
    return (
      <>
        {this.props.itenerary.map((item) => {
          return (
            <>
              <div
                style={{
                  background: "#e60970",
                  padding: "3px 10px",
                  color: "white",
                  display: "inline",
                }}
              >
                {item.date}
              </div>
              {item.activities.map((data) => {
                return (
                  <div
                    style={{ display: "flex", marginTop: 10, marginBottom: 15 }}
                  >
                    <div
                      style={{
                        background: "#fff",
                        padding: 3,
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      {data.time}
                    </div>
                    <div style={{ flex: 1, paddingLeft: 15 }}>
                      <div style={{ fontWeight: "bold" }}>{data.title}</div>
                      <div style={{ fontSize: 12 }}>{data.body}</div>
                    </div>
                  </div>
                );
              })}
            </>
          );
        })}
      </>
    );
  }
}

export default App;
