import type {FC} from 'react';
import { Picture } from '../../../api';
import { OverlayProvider } from '../../search-property/context';
import GalleryOverlay from './gallery-overlay';

type NewGalleryProps = {
  gallery: Picture[],
  disable?: boolean,
};

const NewGallery: FC<NewGalleryProps> = ({gallery, disable}) => {
  return (
    <OverlayProvider.Consumer>
      {({setIsOverlay, setOnOverlay}) => (
        <div
          onClick={() => {
            if (disable) return;
            // create the overlay
            setOnOverlay && setOnOverlay(() => () => (
              <GalleryOverlay
                gallery={gallery}
                removeOverlay={() => {
                  setIsOverlay && setIsOverlay(false);
                  setOnOverlay && setOnOverlay(null);
                }}
              />
            ));

            setIsOverlay && setIsOverlay(true);
          }}
          className="view-gallery-parent"
        >
          <div
            style={{
              flex: 2,
              border: "1px solid #ccc",
              height: 420,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundColor: "#ccc",
              marginRight: 5,
              backgroundImage: `url(${gallery[0].url})`,
            }}
          />
          {/* <div style={{ flex: 1, padding: 4, paddingTop: 0 }}>
                        <div className="view-gallery-grid-container">
                        </div>
                    </div> */}
          <div
            style={{
              flex: 1,
              padding: 4,
              paddingTop: 0,
              flexDirection: "column",
            }}
            className="mobile-hidden"
          >
            <div
              style={{
                flex: 1,
                padding: 4,
                paddingTop: 0,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ display: "flex", flex: 1, marginBottom: 10 }}>
                <div
                  style={{
                    flex: 1,
                    border: "1px solid #ccc",
                    height: 130,
                    backgroundColor: "#ccc",
                    marginRight: 5,
                    backgroundImage: `url(${gallery[1].url})`,
                  }}
                />
                <div
                  style={{
                    flex: 1,
                    border: "1px solid #ccc",
                    height: 130,
                    backgroundColor: "#ccc",
                    marginLeft: 5,
                    backgroundImage: `url(${gallery[2]?.url})`,
                  }}
                />
              </div>
              <div style={{ display: "flex", flex: 1, marginBottom: 10 }}>
                <div
                  style={{
                    flex: 1,
                    border: "1px solid #ccc",
                    height: 130,
                    backgroundColor: "#ccc",
                    marginRight: 5,
                    backgroundImage: `url(${gallery[3]?.url})`,
                  }}
                />
                <div
                  style={{
                    flex: 1,
                    border: "1px solid #ccc",
                    height: 130,
                    backgroundColor: "#ccc",
                    marginLeft: 5,
                    backgroundImage: `url(${gallery[4]?.url})`,
                  }}
                />
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                <div
                  style={{
                    flex: 1,
                    border: "1px solid #ccc",
                    height: 135,
                    backgroundColor: "#ccc",
                    marginRight: 5,
                    backgroundImage: `url(${gallery[5]?.url})`,
                  }}
                />
                <div
                  style={{
                    flex: 1,
                    border: "1px solid #ccc",
                    height: 135,
                    backgroundColor: "#ccc",
                    marginLeft: 5,
                    backgroundImage: gallery[6]
                      ? `url(${gallery[6].url})`
                      : "",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </OverlayProvider.Consumer>
  );
}


export default NewGallery;
