import {FC} from 'react';
import {Checkbox} from 'antd';
import {CheckboxValueType} from 'antd/lib/checkbox/Group';

import {SectionHeader} from '../../../components/section-header';

type AttractionFilterGroupProps = {
  title: string;
  filters: string[];
  onChange: (filters: CheckboxValueType[]) => void;
};

export const AttractionFilterGroup: FC<AttractionFilterGroupProps> = ({
  title, filters, onChange,
}) => {
  return (
    <div className="mb-3">
      <SectionHeader
        style={{
          borderTopWidth: 0,
          marginBottom: 12,
        }}
      >
        {title}
      </SectionHeader>
      <Checkbox.Group
        options={filters}
        onChange={onChange}
      />
    </div>
  );
};
