import {FC, useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router';
import {Link} from 'react-router-dom';
import { auth } from 'firebase';
import {BiGridAlt, BiStats, BiTable} from 'react-icons/bi';
import {
  RiApps2Line, RiBuildingLine, RiCalendarLine, RiCarLine, RiFlightTakeoffLine,
  RiHotelBedLine, RiHotelLine, RiMoneyDollarCircleLine, RiRestaurantLine,
  RiRoadMapLine, RiShipLine, RiWallet3Line,
} from 'react-icons/ri';
import {
  LineChart, YAxis, XAxis, Label, CartesianGrid, Line, Tooltip,
} from "recharts";

import {Reservation} from '../../../../api/reservation/types';
import {fetchPartnerReservations} from '../../../../api';

import {PartnerHeader} from '../../components';

const data = [
  { name: "Jan", uv: 280, pv: 1398, amt: 2400, uvError: 40 },
  { name: "Feb", uv: 200, pv: 9800, amt: 2400, uvError: 20 },
  { name: "Mar", uv: 189, pv: 4800, amt: 2400, uvError: 28 },
  { name: "Apr", uv: 189, pv: 4800, amt: 2400, uvError: 28 },
  { name: "May", uv: 189, pv: 4800, amt: 2400, uvError: 28 },
  { name: "Jun", uv: 189, pv: 4800, amt: 2400, uvError: 28 },
  { name: "Jul", uv: 189, pv: 4800, amt: 2400, uvError: 28 },
  { name: "Aug", uv: 189, pv: 4800, amt: 2400, uvError: 28 },
  { name: "Sep", uv: 189, pv: 4800, amt: 2400, uvError: 28 },
  { name: "Oct", uv: 189, pv: 4800, amt: 2400, uvError: 28 },
  { name: "Nov", uv: 189, pv: 4800, amt: 2400, uvError: 28 },
  { name: "Dec", uv: 189, pv: 4800, amt: 2400, uvError: 28 },
];

export const Dashboard: FC<RouteComponentProps> = ({
  match,
}) => {
  const [reservations, setReservations] = useState<Reservation[]>([]);

  const [grossPrice, setGrossPrice] = useState<number>(0);

  useEffect(() => {
    const user = auth().currentUser;

    if (user) {
      fetchPartnerReservations(user.uid, (err, res) => {
        if (err) {
          console.error(err);

          return;
        }

        if (res) {
          // filter to only the ones that are from this month
          const monthBeginning = new Date();
          monthBeginning.setDate(1);
          monthBeginning.setHours(0, 0, 0, 0);

          const updatedReservations =
            res.filter(
              (reserve) =>
                (
                  reserve.reservation_date >= monthBeginning.getTime() &&
                    reserve.status === "complete"
                ),
            );

          let price = 0;
          updatedReservations.forEach((ur) => price += ur.expected_amount);
          setReservations(updatedReservations);
          setGrossPrice(price);
        }
      });
    }
  }, []);

  const menu = [
    {
      title: "Hotels",
      pathname: `${match.path}hotel`,
      key: 0,
      active: true,
      icon: <RiHotelBedLine />,
      number: 623,
    },
    {
      title: "Flights",
      pathname: `${match.path}flight`,
      key: 1,
      active: false,
      icon: <RiFlightTakeoffLine />,
      number: 34,
    },
    {
      title: "Rentals",
      pathname: `${match.path}rental`,
      key: 2,
      active: false,
      icon: <RiHotelLine />,
      number: 3,
    },
    {
      title: "Cruises",
      pathname: `${match.path}cruise`,
      key: 3,
      active: false,
      icon: <RiShipLine />,
      number: 3,
    },
    {
      title: "Eateries",
      pathname: `${match.path}eatery`,
      key: 4,
      active: false,
      icon: <RiRestaurantLine />,
      number: 3,
    },
    {
      title: "Conferencing",
      pathname: `${match.path}conference`,
      key: 5,
      active: false,
      icon: <RiBuildingLine />,
      number: 1,
    },
    {
      title: "Cars",
      key: 7,
      pathname: `${match.path}car`,
      active: false,
      icon: <RiCarLine />,
      number: 1,
    },
    {
      title: "Tours",
      key: 8,
      pathname: `${match.path}tour`,
      active: false,
      icon: <RiRoadMapLine />,
      number: 1,
    },
    {
      title: "Activities",
      pathname: `${match.path}activity`,
      key: 9,
      active: false,
      icon: <RiApps2Line />,
      number: 0,
    },
  ];

  return (
    <div style={{overflowY: "auto"}}>
      <PartnerHeader
        title="Dashboard"
        HeaderIcon={BiTable}
      />
      {/* Dashboard content */}
      <div className="p-4">
        {/* Earnings summary wrapping */}
        <div className="sm:flex sm:flex-row sm:flex-wrap sm:justify-around">
          <div
            className="shadow-md flex flex-row items-center p-2 border rounded-sm px-4"
          >
            <div className="mr-3">
              <RiCalendarLine size={70} color="#ff735c" />
            </div>
            <div>
              <span style={{ fontSize: 15, fontWeight: "bold" }}>
                NET EARNING THIS MONTH
              </span>
              <br />
              <span style={{ fontSize: 29, fontWeight: "bold" }}>
                {`$${grossPrice}`}
              </span>
            </div>
          </div>

          <div
            className="shadow-md flex flex-row items-center p-2 px-4 border rounded-md mt-3 sm:mt-0"
          >
            <div className="mr-3">
              <RiWallet3Line style={{ fontSize: 70, color: "#0a7fb5" }} />
            </div>
            <div>
              <span style={{ fontSize: 15, fontWeight: "bold" }}>
                YOUR BALANCE
              </span>
              <br />
              <span style={{ fontSize: 29, fontWeight: "bold" }}>
                {`$${grossPrice}`}
              </span>
            </div>
          </div>

          <div
            className="shadow-md flex flex-row items-center p-2 border rounded-md mt-3 px-4 lg:mt-0"
          >
            <div className="mr-3">
              <RiMoneyDollarCircleLine style={{ fontSize: 70, color: "#ccc" }} />
            </div>
            <div>
              <span style={{ fontSize: 15, fontWeight: "bold" }}>
                TOTAL EARNING
              </span>
              <br />
              <span style={{ fontSize: 29, fontWeight: "bold" }}>
                {`$${grossPrice}`}
              </span>
            </div>
          </div>
        </div>

        {/* Start of the stats container */}
        <div className="lg:grid lg:grid-cols-2 lg:gap-4 lg:mt-4">
          <div
            className="elevate-xs mt-3 lg:mt-0"
            style={{ background: "white", padding: 15, flex: 1 }}
          >
            <div style={{ fontSize: 15, fontWeight: "bold", marginBottom: 20 }}>
              <BiGridAlt style={{ fontSize: 20 }} /> Your Services
            </div>
            <div
              className="grid grid-cols-3 gap-4"
              style={{
                marginTop: 0,
              }}
            >
              {menu.map((item) => {
                return (
                  <Link to={item.pathname} key={item.pathname}>
                    <div
                      className="relative flex flex-col items-center"
                      style={{
                        // width: 130,
                        // marginBottom: 25,
                      }}
                    >
                      <div
                        className="relative"
                        style={{
                          fontSize: 64,
                          textAlign: "center",
                          color: "#0a7fb5",
                        }}
                      >
                        <div
                          className="absolute -right-3 top-0 text-xs p-2 rounded-full bg-error text-white"
                        >
                          {item.number}
                        </div>
                        {item.icon}
                      </div>
                      <div
                        className="text-center"
                        style={{ color: "#111" }}>
                        {item.title}
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>

          <div
            className="elevate-xs bg-white p-4"
            // style={{ height: 450 }}
          >
            <div style={{ fontSize: 15, fontWeight: "bold" }}>
              <BiStats style={{ fontSize: 20 }} /> Activity Summary
            </div>
            <LineChart
              style={{
                width: "100%",
              }}
              width={600}
              height={400}
              data={data}
              margin={{ top: 20, right: 20, left: 10, bottom: 5 }}
            >
              <XAxis dataKey="name" height={40}>
                <Label value="Month" position="insideBottom" />
              </XAxis>
              <Tooltip />
              <CartesianGrid stroke="#f5f5f5" />
              <Line type="monotone" dataKey="uv" stroke="#ff7300" yAxisId={0} />
              <Line type="monotone" dataKey="pv" stroke="#387908" yAxisId={1} />
            </LineChart>
          </div>
        </div>
        {/* End of the stats container */}
      </div>
    </div>
  );
};
