import { FaRegStar, FaStar } from "react-icons/fa";

export function Rating({rating}) {
  const full = [];
  const empty = [];

  for (let i=0; i<rating; i++) {
    full.push(null);
  }

  for(let i=0; i<(5 - rating); i++) {
    empty.push(null);
  }

  return (
    <div className="flex flex-row">
      {full.map(() => (
        <FaStar size={14} color="#FF6000" />
      ))}
      {empty.map(() => (
        <FaRegStar size={14} color="#FF6000" />
      ))}
    </div>
  );
}
