/* eslint-disable no-undef */
import {auth} from "firebase";
import {useState} from "react";

/**
 * This component uses firebase servies to send emails to the user to enable them to
 * reset their email. This is a simple interface that just has a centered form that only
 * takes in the user email and a send button. Upon successful send, the user is notified
 * to check their email.
 * Otherwise the user is notified that an error occured during the sending of the email.
 *
 * @todo thers is flawed logic that does not give the user visual feedback when they try
 * to reset but the error occures on the server and thus the user may be suck expecting
 * feedback where there isn't.
 */
const App = () => {
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  const resetEmail = () => {
    if (email.length == 0) {
      alert("Enter a valid email address");
      return;
    }
    if (!email.includes("@")) {
      alert("Enter a valid email address");
      return;
    }
    if (!email.includes(".")) {
      alert("Enter a valid email address");
      return;
    }
    
    auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        setEmailSent(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div
      style={{
        background: "#f2f2f2",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {emailSent ? (
        <>
          <div style={{ background: "white", padding: 20 }} className="form">
            <h2>Email Sent</h2>
            <p>
              Check your email for the reset link that we have sent to{" "}
              {email}
            </p>
          </div>
        </>
      ) : (
        <>
          <div style={{ background: "white", padding: 20 }} className="form">
            <h2>Recover Password</h2>
            <p>Enter your email address</p>
            <input
              placeholder="Your Email"
              style={{ margin: 0, width: "100%", marginBottom: 15 }}
              onChange={(event) =>
                setEmail(event.target.value)
              }
            />
            <div
              className="but but-orange"
              onClick={resetEmail}
            >
              RESET PASSWORD
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default App;
