type VerifyFunc = (input: string) => boolean;

const verifyEmail: VerifyFunc = (input) => {
  const regExp = /[\w+\d+]+(\.\w+)*@\w+(\.\w+)+\w+/;

  return regExp.test(input);
};

const verifyPhoneNumber: VerifyFunc = (input) => {
  if (!input) {
    return false;
  } else {
    if (/[A-Za-z]/g.test(input)) {
      return false;
    } else {
      const testInput = input.replaceAll(" ", "");
      if (/\+/.test(testInput)) {
        const digitLength = testInput.split("");
        digitLength.shift();
        if (digitLength.length !== 12) {
          return false;
        } else {
          return true;
        }
      } else {
        const digitLength = testInput.split("");
        if (digitLength.length !== 10) {
          return false;
        } else {
          return true;
        }
      }
    }
  }
};

const verifyTillNumber: VerifyFunc = (input) => {
  const regExp = /\D+/gi;

  return !regExp.test(input);
};

const verifyPaybillNumber: VerifyFunc = (input) => {
  const regExp = /\D+/gi;

  return !regExp.test(input);
};

const verifyPaybillAccountNumber: VerifyFunc = (input) => {
  const regExp = /\D+/gi;

  return !regExp.test(input);
};

/**
 * This function validates urls to make sure that the input follows the format
 * of a valid url.
 *
 * @param input The url that needs to be validated that it is infact in the
 * correct format of an actual url
 * @returns A boolean on whether it is a valid url or not
 */
const verifyUrl: VerifyFunc = (input) => {
  const parsedUrl = (
    input.startsWith("http://") || input.startsWith("https://")
  ) ?
    input : `https://${input}`;
  
  // using the URL contructor, we test this url to make sure that it is infact
  // valid
  let urlValid = true;
  try {
    // we just try to run the URL contructor, which throws an error is the url
    // is not valid
    new URL(parsedUrl);
  } catch (err) {
    urlValid = false;
  }

  return urlValid;
};

export {
  verifyEmail, verifyTillNumber, verifyPaybillNumber, verifyPaybillAccountNumber,
  verifyPhoneNumber, verifyUrl,
};
