import {firestore} from 'firebase';

import {ApiFunc, Coordinates, OnCompleteCallback, Picture} from '..';
import {uploadPictures} from '../cdn';
import {getUser, NoUserFoundError} from '../user';

import type {Rental} from './types';
export type {Rental} from './types';

export const fetchRental = async (
  id: string, onFetch: (err: Error | null, data: Rental | null) => void,
): Promise<void> => {
  console.log(`Fetching rental with id: ${id}`)

  try {
    const snapshot = await firestore().collection("rental").doc(id).get();
    const rental = {id: snapshot.id, ...snapshot.data()} as Rental;

    onFetch(null, rental);
  } catch (err) {
    const fetchError = new Error("An error occured while fetching rental");
    onFetch(fetchError, null);
  }
};

export const fetchRentals: ApiFunc<Rental[]> = async (onComplete) => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);

    return;
  }

  try {
    const snapshot = await firestore().collection("rental")
      .where("owner", "==", user.uid)
      .get();
    const {docs} = snapshot;

    const rentals: Rental[] = [];
    docs.forEach((doc) => {
      rentals.push({id: doc.id, ...doc.data()} as Rental);
    });

    onComplete(null, rentals);
  } catch (err) {
    const fetchError = new Error("An error occured while fetching rentals");
    onComplete(fetchError, null);
  }
};

export const checkRentalAvailability = async (
  rentalId: string, checkin: number,
  onComplete: OnCompleteCallback<boolean>,
): Promise<void> => {
  try {
    const response = await fetch(
      `https://us-central1-safarixpertz.cloudfunctions.net/fetch_rentals/${
        rentalId
      }?checkin=${checkin}`,
      {
        method: "GET",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
      },
    );

    if (response.status === 200) {
      const {isAvailable} = (await response.json() as {isAvailable: boolean});

      onComplete(null, isAvailable);
    } else {
      const fetchError = new Error("Error fetching rentals");

      onComplete(fetchError, null);
    }
  } catch (err) {
    const fetchErr = new Error("An error occured while fetching");
    onComplete(fetchErr, null);
  }
};

export const filterRentals = async (
  location: string, coords: Coordinates, checkin: number,
  onComplete: OnCompleteCallback<Rental[]>,
): Promise<void> => {
  try {
    const response = await fetch(
      "https://us-central1-safarixpertz.cloudfunctions.net/fetch_rentals",
      {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          location,
          coords,
          checkin,
        }),
      },
    );

    if (response.status === 200) {
      const rentals = (await response.json()) as Rental[];

      onComplete(null, rentals);
    } else {
      const fetchError = new Error("Error fetching rentals");

      onComplete(fetchError, null);
    }
  } catch (err) {
    const fetchErr = new Error("An error occured while fetching");
    onComplete(fetchErr, null);
  }
};

export const postRental = (
  rental: Rental, onComplete: OnCompleteCallback<Rental>,
): void => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);

    return;
  }

  // we fetch all the pictures from the rental facility itself, and also all
  // the pictues from the rooms themselves.
  // We then create an array (Buffer) that records how many pictures we are
  // uploading for each, this will be used to get back all the pictures in order
  // of so as to re-order them back to their respective places without a hassle.
  //
  // E.g if we have a rental facility with 6 pictures of its own and three
  // rental rooms with pictures of their own, then we can create the buffer
  // as follows:
  // buffer = [6, 6, 7, 8];
  // then an array of pictures to upload is created with a total of
  // (6 + 6 + 7 + 8) images, where:
  // they are ordered based on:
  // rental facility first, rooms based on the index of the room,
  // As such, these pictures can be gotten back by splicing and then pushing
  // back in order of index starting with the rental facility as the first
  // index (0)

  const bufferSizes: number[] = [];
  const imageBuffer: Picture[] = [];

  bufferSizes.push(rental.gallery.length);
  imageBuffer.push(...rental.gallery);

  // push all the pictures for each room
  rental.rooms.forEach((room) => {
    bufferSizes.push(room.gallery.length);
    imageBuffer.push(...room.gallery);
  });


  // after all the images are pushed we can upload them all and then replace
  // after successful upload
  uploadPictures("rental", imageBuffer, async (err, uploadedImages) => {
    if (err) {
      onComplete(err, null);
      return;
    }

    if (uploadedImages) {
      rental.gallery = uploadedImages.splice(0, bufferSizes[0]);

      // starting from the second index, we start assigning the rooms all their
      // images back
      for (let i=1; i<bufferSizes.length; i++) {
        rental.rooms[i - 1].gallery = uploadedImages.splice(0, bufferSizes[i]);
      }

      // after all the images are uploaded we can now get to uploading the
      // confernce data
      try {
        const doc =  await firestore()
          .collection("rental")
          .add({...rental, owner: user.uid} as Rental);

        onComplete(null, (await doc.get()).data() as Rental);
      } catch (err) {
        const rentalUploadError =
          new Error("Error uploading rental facility details");
        onComplete(rentalUploadError, null);
      }
      
    } else {
      // these images may be null
      const uploadErr = new Error("The images to be uploaded returned null");
      onComplete(uploadErr, null);
    }
  });
};

export const updateRental = (
  rentalId: string, rental: Rental, onComplete: OnCompleteCallback<Rental>,
): void => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);

    return;
  }

  // we fetch all the pictures from the rental facility itself, and also all
  // the pictues from the rooms themselves.
  // We then create an array (Buffer) that records how many pictures we are
  // uploading for each, this will be used to get back all the pictures in order
  // of so as to re-order them back to their respective places without a hassle.
  //
  // E.g if we have a rental facility with 6 pictures of its own and three
  // rental rooms with pictures of their own, then we can create the buffer
  // as follows:
  // buffer = [6, 6, 7, 8];
  // then an array of pictures to upload is created with a total of
  // (6 + 6 + 7 + 8) images, where:
  // they are ordered based on:
  // rental facility first, rooms based on the index of the room,
  // As such, these pictures can be gotten back by splicing and then pushing
  // back in order of index starting with the rental facility as the first
  // index (0)

  const bufferSizes: number[] = [];
  const imageBuffer: Picture[] = [];

  bufferSizes.push(rental.gallery.length);
  imageBuffer.push(...rental.gallery);

  // push all the pictures for each room
  rental.rooms.forEach((room) => {
    bufferSizes.push(room.gallery.length);
    imageBuffer.push(...room.gallery);
  });


  // after all the images are pushed we can upload them all and then replace
  // after successful upload
  uploadPictures("rental", imageBuffer, async (err, uploadedImages) => {
    if (err) {
      onComplete(err, null);
      return;
    }

    if (uploadedImages) {
      rental.gallery = uploadedImages.splice(0, bufferSizes[0]);

      // starting from the second index, we start assigning the rooms all their
      // images back
      for (let i=1; i<bufferSizes.length; i++) {
        rental.rooms[i - 1].gallery = uploadedImages.splice(0, bufferSizes[i]);
      }

      // after all the images are uploaded we can now get to uploading the
      // confernce data
      try {
        await firestore()
          .collection("rental")
          .doc(rentalId)
          .update({...rental} as Rental);
        
        const doc = firestore().collection("rental").doc(rentalId);

        onComplete(null, (await doc.get()).data() as Rental);
      } catch (err) {
        const rentalUploadError =
          new Error("Error uploading rental facility details");
        onComplete(rentalUploadError, null);
      }
      
    } else {
      // these images may be null
      const uploadErr = new Error("The images to be uploaded returned null");
      onComplete(uploadErr, null);
    }
  });
};
