import type {Dispatch, FC, SetStateAction} from 'react';
import { FaPlusCircle } from 'react-icons/fa';

import {Picture} from "../../../api";
import { OverlayProvider } from '../../search-property/context';
import { AddImageOverlay } from './add-image-overlay';

type PickImageListItemProps = {
  gallery: Picture[];
  logo?: Picture;
  minImageCount: number;
  onOverlaySave: (gallery: Picture[]) => void;
  setLogo?: Dispatch<SetStateAction<Picture | undefined>>;
};

export const PickImageListItem: FC<PickImageListItemProps> = ({
  gallery, logo, minImageCount, onOverlaySave, setLogo,
}) => {
  return (
    <OverlayProvider.Consumer>
      {({setIsOverlay, setOnOverlay}) => (
        <div>
          <div
            className={
              "border mt-4 px-4 py-2 flex flex-row items-center shadow-md " +
              "rounded-md overflow-hidden cursor-pointer"
            }
            onClick={() => {
              // create the overlay and pass all the images and the onSave
              // reference
              setOnOverlay && setOnOverlay(() => () => (
                <AddImageOverlay
                  initialImages={gallery}
                  initialLogo={logo}
                  setPropertyLogo={setLogo}
                  onSave={onOverlaySave}
                  removeOverlay={() => {
                    setOnOverlay(null);
                    setIsOverlay && setIsOverlay(false);
                  }}
                />
              ));

              // activate overlay
              setIsOverlay && setIsOverlay(true);
            }}
          >
            <div
              className={
                "h-16 w-16 -my-2 -ml-4 bg-cover bg-center bg-no-repeat " +
                "flex-shrink-0 border-r"
              }
              style={{
                backgroundImage: (gallery.length > 0)
                  ? `url(${gallery[0].url})`
                  : `url(/press-to-select-image.jpg)`
              }}
            />
            <div className="ml-3 overflow-hidden h-12 w-full">
              {/** Caption of first gallery image */}
              <h6
                className={
                  "whitespace-nowrap overflow-ellipsis w-full " +
                  "overflow-x-hidden"
                }
              >
                {gallery.length > 0 && gallery[0].caption}
                {/* Only shown when there are not images */}
                {!gallery.length &&
                  "Insert images representing propery/activity"
                }
              </h6>
              <p className="text-xs">Press to edit images</p>
            </div>
            {/* TODO icon */}
            <FaPlusCircle
              size={24}
              color="#FF5900"
              className="justify-self-end"
            />
          </div>
          {/* Error shown if the images are less than the given threshold */}
          {gallery.length < minImageCount && (
            <p className="text-xs mt-1 text-red-500 pl-2">
              {`Must have at least ${minImageCount} image(s)`}
            </p>
          )}
        </div>
      )}
    </OverlayProvider.Consumer>
  );
};
