import {FC, RefObject, useContext} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import { MarkerInnerRef } from '.';
import { OverlayProvider } from '../../context';
export interface MapListItemProps {
  cover: string;
  id?: string;
  title: string;
  totalCost: number;
  location: string;
  markerRef: RefObject<MarkerInnerRef>;
}

export const MapListItem: FC<MapListItemProps> = ({
  cover, title, id, location, markerRef: innerRef, totalCost,
}) => {
  const {
    setIsOverlay, setOnOverlay,
  } = useContext(OverlayProvider);
  const {search, pathname} = useLocation();
  console.log(search);

  const history = useHistory();

  const checkPropertyAvailability = () => {
    const [, category] = pathname.split("/")
      .filter((param) => param !== "");
    history.push(
      `/property/${category}/${id}${search}`,
    );

    setIsOverlay && setIsOverlay(false);
    setOnOverlay && setOnOverlay(null);
  };

  return (
    <div
      className="p-3 w-auto lg:w-72 mr-2 lg:mb-2 lg:mr-0 shadow-md flex-none lg:block"
    >
      {/* Top contains the cover, title, location and total cost */}
      <div
        onMouseEnter={() => {
          if (innerRef.current) {
            innerRef.current.openPopOver();
          }
        }}
        onMouseLeave={() => {
          if (innerRef.current) {
            innerRef.current.closePopOver();
          }
        }}
      >
        {/* Contains the cover, titls and the location */}
        <div className="lg:flex lg:flex-row">
          {/*
            The image has a margin-top of -3 for small devices to remove effects
            of overall card padding
          */}
          <img
            src={cover}
            alt={`${title}`}
            className="w-full h-20 lg:w-20 lg:mr-3 -mt-3 lg:mt-0"
          />
          <div className="lg:ml-2">
            <h6 className="mb-1 text-xl font-semibold text-secondary">
              {title}
            </h6>
            <p className="text-xs">{location}</p>
          </div>
        </div>
        <p className="mt-2 text-sm">Total: {totalCost}</p>
      </div>
      <hr className="my-2" />
      <div
        className={
          "inline py-1 px-3 font-semibold text-primary cursor-pointer " +
          "hover:bg-black hover:bg-opacity-40"
        }
        onClick={checkPropertyAvailability}
      >
        CHECK AVAILABILITY
      </div>
    </div>
  );
};
