// @ts-check
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import {FaMapMarkerAlt} from "react-icons/fa";

/**
 * This components with aid of the search input, takes in user input and renders suggestions
 * of possible locations using the Places API provided by Google.
 * 
 * Note
 * ----
 * Since the autocomple-places-parent makes hard margin resets to make sure that
 * the autocomplete suggestions is correctly placed in the HomePage, there is
 * the option to override the autocomplete-places-parent css properties to allow
 * use anywhere other than the Homepage.
 *
 * @param {{
 * defaultValue: string,
 * style: import('react').CSSProperties,
 * placeholder: string,
 * selectAddress: (place: any, latLng: any) => void,
 * onChange: React.ChangeEventHandler<HTMLInputElement>,
 * suggestionsContainerStyle: import('react').CSSProperties | null,
 * }} props props taken in by the component
 *
 * @returns {JSX.Element}
 */
const PlacesAutocomplete = ({
  defaultValue, placeholder, style, suggestionsContainerStyle, selectAddress,
  onChange,
}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      types: ["(cities)"],
    },
    defaultValue,
    debounce: 300,
  });

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  /**
   * @type {React.ChangeEventHandler<HTMLInputElement>}
   */
  const handleInput = (e) => {
    // Update the keyword of the input element
    // TODO: Turn on once needed
    setValue(e.target.value);
    onChange(e);
  };

  const handleSelect = ({ description }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);
    clearSuggestions();

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        selectAddress(description, { lat, lng });
      })
      .catch((error) => {
        console.log("😱 Error: ", error);
      });
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id: id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <div
          key={id}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          onClick={handleSelect(suggestion)}
          className="hover:bg-autocomplete-hover cursor-pointer"
        >
          <FaMapMarkerAlt size={28} color="tomato" />
          <li
            key={id}
            // onClick={handleSelect(suggestion)}
          >
            <strong>{main_text}</strong>
            <br />
            <small>{secondary_text}</small>
          </li>
        </div>
      );
    });

  return (
    <div className="w-full">
      <input
        style={style}
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder={placeholder}
      />
      <div className="relative">
        <div
          ref={ref}
          className="autocomplete-places-parent"
          style={suggestionsContainerStyle}
        >
          {/* use "status" to decide whether dropdown should be displated */}
          {status === "OK" && (
            <ul className=" w-full" style={{marginTop: 0}}>
              {renderSuggestions()}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlacesAutocomplete;
