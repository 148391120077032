import {FC, useEffect, useState} from 'react';
import { ReservationManager } from '../../context';

import AuthContainer from '../signin/AuthContainer';

import {SummaryDialog} from './summary-dialog';

export const CheckoutDialog: FC = () => {
  const [visibleSection, setVisibleSection] =
    useState<"summary" | "payment" | "">("")

  useEffect(() => {
    function onHashChange(this: Window) {
      const {hash} = window.location;

      if (hash === "#summary") {
        setVisibleSection("summary");
      } else if (hash === "#payment") {
        setVisibleSection("payment");
      }
    }

    window.addEventListener("hashchange", onHashChange);

    return () => {
      window.removeEventListener("hashchange", onHashChange);
    };
  }, []);

  return (
    <div>
      {window.location.hash === "" && (
        <AuthContainer
          origin="property-checkout"
          location=""
        />
      )}
      {visibleSection === "summary" && (
        <ReservationManager.Consumer>
          {({reservations, removeReservation}) => (
            <SummaryDialog
              reservations={reservations}
              removeReservation={removeReservation}
            />
          )}
        </ReservationManager.Consumer>
      )}
    </div>
  );
};
