import {firestore} from 'firebase';
import {Activity, updateActivity, fetchActivity} from './activity';
import {Car, updateCar, fetchCar} from './car';
import {Conference, updateConference, fetchConference} from './conference';
import {Eatery, updateEatery, fetchEatery} from './eatery';
import {Hotel, updateHotel, fetchHotel} from './hotel';
import {PriceChange} from './hotel/Offer';
import {Rental, updateRental, fetchRental} from './rental';
import {Tour, updateTour, fetchTour} from './tour';
import {getUser, NoUserFoundError} from './user';

/**
 * This is used to identify which type of reservation the system is dealing with
 */
export type ReservationCategory = "hotel" | "flight" | "rental" | "cruise" |
  "eatery" | "conference" | "car" | "tour" | "activity";

/**
 * A bed is a common amenity rhat may be advertised across different types of
 * properties.
 */
export type Bed = {
  type: string;
  adult_capacity: number;
  children_capacity: number;
};

/**
 * This is a coordinates object as stored in the system database
 */
export type Coordinates = {
  lat: number;
  lng: number;
};

/**
 * Each picture stored in the system has to have a url that points to the image
 * and the caption that will be used in the alt component. This caption is
 * explicitly needed as it would be better if the property manager provided this
 * caption themselves
 */
export type Picture = {
  url: string;
  caption: string;
  filename?: string;
  file?: File;
};

/**
 * To enable versitility while still enforcing some form of shared consistency,
 * the review topic contains the title of the review that will change based on
 * the category.
 * This can be seen with the example of review topics such as: Hospitality,
 * Accomodation, Catering may be available for hotels but may not be available
 * for activities.
 */
export type ReviewTopic = {
  title: string;
  comment: string;
  rating: number;
};

/**
 * A review at the core contains the user that made the review, their rating of
 * the service and maybe also the rating for individual topics that they chose
 * to review. This is weighted in a manner to give the overall rating of the
 * user in a `rating` variable.
 */
export type Review = {
  user: string;
  topic: ReviewTopic[];
  comment: string;
  rating: number;
  reply?: string;
  timestamp: number;
};

/**
 * Each item is bound to have a Frequently Asked Question that the property
 * owner gets a lot. This can be input directly into the item to prevent owner
 * from getting questions that could easily have been stopped.
 */
export type FAQ = {
  question: string;
  answer: string;
};

export type Policy = {
  title: string;
  description: string[];
};

export type ReservationType = "full_amount" | "no_deposit" | "partial_deposit";

/**
 * This will be used to manage how the system collects monwy from the client.
 * This has a detailed description that allows the client to manage expectations
 * and also plan for the reservation that they want to make.
 */
export type ReservationPolicy = {
  title: string;
  type: "full_amount" | "no_deposit" | "partial_deposit";
  description: string[];
  deposit: number;
};

export type RefundType = "no_refund" | "partial_refund" | "full_refund";

/**
 * In the event that a client is unable to see through payment or decides to
 * cancel a trip at the last minute, the system needs a policy input by the
 * owner on how to handle refunds that will be carried out in the system.
 * This may include giving back full deposits, getting a partial refund or not
 * getting a refund at all.
 */
export type RefundPolicy = {
  title: string;
  type: "no_refund" | "partial_refund" | "full_refund";
  description: string[];
  amount: number;
};

/**
 * This includes the exceptions that the owner explicitly details that they are
 * unable to handle thus making them aware to the user to prevent inconveninces
 * that would have come is the user was not made aware before reservation/
 */
export type ItemException = {
  title: string;
  description: string[];
};

/**
 * A facility is an amenity provided for a specific purpose. As such if a
 * property has a facility that they would like to advertise, this would be the
 * ground rules of how to capture data of that facility.
 */
export type Facility = {
  title: string;
  description: string;
  charge?: number;
};

/**
 * A feature is a distinctive feature of a property that the owner thinks is
 * worth adverising since they feel like it might bring more user to be
 * attracted to the propoerty.
 */
export type Feature = {
  title: string;
  description: string;
};

/**
 * In cases of properties such as a hotel, there is the option of adding
 * landmarks that are near the hotel for the user to visit.
 */
export type Landmark = {
  title: string;
  description: string;
};

export type PropertyDetails = {
  email: string;
  phone_number: string;
  till_number: string;
  paybill: {
    number: string;
    account_number: string;
  };
  website: string;
  video: string;
};

/**
 * Each property has a common set of properties that are comon across all
 * properties regarless of what type of property they are. This can be
 * refactored into the property module instead of repeating them over and over
 * while declaring the type of each module.
 */
export type Property = {
  id?: string;
  owner?: string;
  title: string;
  location: string;
  coords: Coordinates;
  description: string;
  short_description: string;
  rating: number;
  cover?: string;
  gallery: Picture[];
  logo?: Picture;
  reviews: Review[];
  faqs: FAQ[];
  offers: PriceChange[];
  reservation_policy: ReservationType;
  refund_policy: RefundType;
  exceptions: ItemException[];
};

export type ReservationRequest = {
  title: string;
  comment: string;
};

/**
 * At the core of this system is allowing for users to make reservations on
 * properties. This thus means that the reservation is supposed to be unique and
 * identifiable by a unique id to allow for cancellations and followthroughs in
 * the case that a user may have a query.
 * This reservation must also capture:
 * - `the user` (through the id)
 * - `the property` (and the subproerty being leased attached to it, if present) 
 */
export type PropertyReservation = {
  category: ReservationCategory;
  price: number;
  checkin: number;
  checkout?: number;
  id: string;
  user: string;
  property: string;
  request: ReservationRequest;
  status: "pending" | "stayed" | "cancelled";
  timestamp: number;
};

////////////////////////////////////////////////////////////////////////////////
// callbacks types common to all functions
////////////////////////////////////////////////////////////////////////////////

export type OnCompleteCallback<T> = (err: null | Error, result: T | null) => void;

export type ApiFunc<T> = (onComplete: OnCompleteCallback<T>) => Promise<void>;

export type UpdateFunction = (
  category: ReservationCategory, propertyId: string,
  data: Activity | Car | Conference | Eatery | Hotel | Rental | Tour,
  onComplete: OnCompleteCallback<Record<string, unknown>>,
) => Promise<void>;

export type FetchAdminPropertyFunction = (
  category: ReservationCategory, propertyId: string,
  onComplete: OnCompleteCallback<Record<string, unknown>>,
) => Promise<void>;

export const fetchAdminProperty: FetchAdminPropertyFunction = async (
  category, propertyId, onComplete,
) => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);
    return;
  }

  switch (category) {
    case "activity":
      fetchActivity(propertyId, onComplete);
      break;
    case "car":
      fetchCar(propertyId, onComplete);
      break;
    case "conference":
      fetchConference(propertyId, onComplete);
      break;
    case "eatery":
      fetchEatery(propertyId, onComplete);
      break;
    case "hotel":
      fetchHotel(propertyId, onComplete);
      break;
    case "rental":
      fetchRental(propertyId, onComplete);
      break;
    case "tour":
      fetchTour(propertyId, onComplete);
      break;
    default:
      break;
  }
};

export const updateProperty: UpdateFunction = async (
  category, propertyId, data, onComplete,
) => {
  switch (category) {
    case "activity":
      updateActivity(propertyId, data as Activity, onComplete);
      break;
    case "car":
      updateCar(propertyId, data as Car, onComplete);
      break;
    case "conference":
      updateConference(propertyId, data as Conference, onComplete);
      break;
    case "eatery":
      updateEatery(propertyId, data as Eatery, onComplete);
      break;
    case "hotel":
      updateHotel(propertyId, data as Hotel, onComplete);
      break;
    case "rental":
      updateRental(propertyId, data as Rental, onComplete);
      break;
    case "tour":
      updateTour(propertyId, data as Tour, onComplete);
      break;
    default:
      break;
  }
};

export const updateReviewReply = async (
  category: ReservationCategory, propertyId: string, idx: number,
  reviewReply: string, onComplete: OnCompleteCallback<Review>,
): Promise<void> => {
  try {
    const docRef = firestore().collection(category).doc(propertyId);
    const snapshotBefore = await docRef.get();

    const beforeUpdate = (snapshotBefore.data() as Property | Car).reviews;

    beforeUpdate[idx].reply = reviewReply;

    await docRef.update({reviews: beforeUpdate} as Property | Car);

    // confirm that it has been updated
    const snapshotAfter = await docRef.get();
    const afterUpdate = (snapshotAfter.data() as Property | Car).reviews;

    onComplete(null, afterUpdate[idx]);
  } catch (err) {
    console.error(err);
    const updateReviewError = new Error("Unable to update reviews");
    onComplete(updateReviewError, null);
  }
};

export const updatePropertyOffers = async (
  category: ReservationCategory, propertyId: string,
  offers: Record<string, unknown>[],
  onComplete: OnCompleteCallback<Record<string, unknown>[]>,
): Promise<void> => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);

    return;
  }

  const ref = firestore().collection(category).doc(propertyId);

  const snapshotBefore = await ref.get();

  if (user.uid !== (snapshotBefore.data() as Property | Car).owner) {
    onComplete(new Error("Action not allowed"), null);
    return;
  }

  await ref.update({offers});

  const snapshotAfter = await ref.get();

  onComplete(null, (snapshotAfter.data() as Property | Car).offers)
};

////////////////////////////////////////////////////////////////////////////////
// export all modules from the individual categories
////////////////////////////////////////////////////////////////////////////////

export {
  fetchHotel, fetchAvailableHotelRooms, fetchHotels, filterHotels,
  postHotel,
} from './hotel';
export type {Hotel} from './hotel';
export {
  fetchRental, filterRentals, fetchRentals, postRental,
} from './rental';
export type {Rental} from './rental';
export {
  fetchEatery, fetchAvailableEateryTables, fetchEateries, filterEateries,
  postEatery,
} from './eatery';
export type {Eatery} from './eatery';
export {
  fetchConference, fetchAvailabeConferenceRooms, fetchConferences,
  filterConferences, postConference,
} from './conference';
export type {Conference} from './conference';
export {fetchCar, fetchCars, filterCars, postCar} from './car';
export type {Car} from './car';
export {
  fetchTour, fetchTours, filterTours, postTour, checkTourAvailability,
} from './tour';
export type {Tour} from './tour';
export {
  fetchActivity, fetchActivities, filterActivities, postActivity,
  checkActivityAvailability, updateActivity,
} from './activity';
export type {Activity} from './activity';
export {getRefundPolicy, getReservationPolicies} from './policy';
export type {ReservationPolicies, RefundPolicies} from './policy';
export {
  fetchClientReservations, fetchPartnerReservations,
  fetchPopulatedReservations, fetchReservation, postReservation,
  fetchCategoryReservations,
} from './reservation';
export {postMPesaTransaction, postPayPalTransaction} from './payment';
export type {Draft} from './draft';
export {
  fetchDrafts, postActivityDraft, postCarDraft, postConferenceDraft,
  postEateryDraft, postHotelDraft, postRentalDraft, postTourDraft,
  removeDraft, updateActivityDraft, updateCarDraft, updateConferenceDraft,
  updateEateryDraft, updateHotelDraft, updateRentalDraft, updateTourDraft,
  fetchDraft, fetchReservationDraft, fetchReservationDrafts,
  postReservationDraft, updateReservationDraft, deleteDraftReservation,
  fetchPopulatedReservationDrafts,
} from './draft';
export {fetchAttraction, fetchNearbyProperties} from './attraction';
export {
  postPropertyContactDetails, updatePropertyContactDetails,
  fetchPropertyContactDetails,
} from './phonebook';
export {fetchRecommended} from './recommended';
export type {Recommended, RecommendedProperties} from './recommended';
export {
  fetchNotifications, markNotificationAsRead, fetchAdminNotifications,
} from './notification';
export {
  fetchAllContent, fetchRecommendedContent, saveRecommendations,
  fetchAllUsers, updateUserDetails, updatePropertyState,
} from './su';
export type {ContentCategories, SafariUser} from './su';
export {
  fetchUserDetails, removeUserAuthLevel, setUserAuthLevel,
} from './user';
export {
  fetchProximitySummary, fetchPopulatedCities,
  fetchPropertiesSummary,
} from "./summary";
export {
  deleteInfo, fetchInfo, setInfo, fetchRoute,
} from './info';
export type {Info, ListContent, ParagraphContent} from './info';
export {
  createAffiliateLink, fetchAllAffliateLinks, fetchAffiliateSummary,
  postFollowThrough,
} from './affiliate';
export type {Affiliate, AffiliateSummary} from './affiliate';
export {verifyReCAPTCHAToken} from './recaptcha';
