import {ChangeEventHandler, FC, FocusEventHandler} from 'react';

import {InputProps} from '.';

export type TextAreaProps = InputProps & {
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
};

export const TextArea: FC<TextAreaProps> = ({
  helper, id, name, inputLabel, value, error, disabled, onChange,
}) => {
  return (
    <>
      <div
        className="text-area-container"
      >
        <textarea
          id={id}
          name={name}
          className="text-area"
          placeholder=" "
          value={value}
          disabled={disabled ? disabled : false}
          onChange={onChange}
        />
        <label htmlFor={id} className="text-area-label">{inputLabel}</label>
      </div>
      {error && (
        <p className="text-xs pl-2 text-red-500">{error}</p>
      )}
      {!error && value === "" && (
        <p className="text-xs pl-2">{helper}</p>
      )}
    </>
  );
};
