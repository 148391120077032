import {createContext} from "react";

import {SnackbarProps} from "../components";

type SnackbarProviderProps = {
  setIsVisible?: (snackbarProps: SnackbarProps) => void;
  dismissSnackbar?: () => void;
};

export const SnackbarProvider = createContext<SnackbarProviderProps>({
});
