import {Car} from "../../api";

/**
 * Returns the cost of the car per kilometre based on the parameters
 * entered about the car.
 *
 * @param car The car in question
 * @returns The price to use the car per kilometre
 */
export const calculateCarPrice = (car: Car): number => {
  let price = 0;
  
  if (car.passengers <= 4) {
    price = 0.4; // the whole system calcs in USD
  } else {
    price = 0.3;
  }

  return price;
};
