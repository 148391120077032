import type {FC} from 'react';
import type {IconType} from 'react-icons';

export type ChipProps = {
  label: string;
  Icon?: IconType;
  active: boolean;
  onClick?: () => void;
};

/**
 * This is a reusable chip that takes in the label of the chip and optionally an
 * icon that can ve displayed on the start of the chip.
 *
 * @param props props of the chip
 *
 * @returns Chip.
 */
export const Chip: FC<ChipProps> = ({label, active, Icon, onClick}) => {
  return (
    <div
      style={{
        backgroundColor: (active) ? "#e1ad01" : "#f0dc82",
        borderWidth: "1px",
        borderColor: (!active) ? "#e1ad01" : "",
      }}
      className={
        `m-1 pr-3 pl-1 h-9 border rounded-full w-auto flex flex-row items-center`
      }
      onClick={onClick}
    >
      {Icon && (
        <Icon size={24} />
      )}
      <p className="pl-2 text-sm">{label}</p>
    </div> 
  );
};
