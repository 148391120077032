import {auth, storage} from "firebase";

import {OnCompleteCallback, Picture} from "..";

/**
 * Takes in a `Picture` with a local blob URL and uploads the picture
 * returning a new `Picture` object containing the equivalent firebase url of
 * uploaded image.
 * This image takes in the current user as a subreference to make sure that the
 * storage structure looks as follows:
 * `/:category/:user_id/:file_name.ext`.
 * Using the firebase callback, the image is able to keep track of progress and
 * also relay the error or successful completion upon function completion.
 */
export const uploadPicture = (
  category: "hotel" | "flight" | "rental" | "cruise" | "eatery" | "conference" |
    "car" | "tour" | "activity" | "info",
  pictureToUpload: Picture, onComplete: OnCompleteCallback<Picture>
): void => {
  console.log(`Uploading image: ${pictureToUpload.url}`);
  const user = auth().currentUser;

  if (user) {
    if (isUploaded(pictureToUpload.url)) {
      onComplete(
        null, {caption: pictureToUpload.caption, url: pictureToUpload.url},
      );
      // since the picture is already uploaded, there is no need to continue
      // with the function, just return the normal function
      return;
    }
    if(pictureToUpload.file) {
      const [, ext] = pictureToUpload.file.type.split("/");
      const filename = `${Date.now()}.${ext}`;
      // since the info category also uses this category and does not
      // for the user id to be used to store the images, we check if the
      // category is info, is so, we will not use the user.uid, otherwise
      // we will use the user.uid
      const fileRef = category !== "info" ?
        storage().ref(`${category}/${user.uid}/${filename}`) :
        storage().ref(`${category}/${filename}`)
        ;

      const uploadTask = fileRef.put(pictureToUpload.file);
      
      uploadTask
        .on(
          "state_changed",
          (snapshot) => {
            console.log("File Upload progress: ");
            console.log(snapshot.bytesTransferred / snapshot.totalBytes * 100);
          },
          (error) => {
            if (error) {
              onComplete(error, null);
            }
          },
          () => {
            const uploadedPicture = pictureToUpload;
            fileRef.getDownloadURL().then((uploadedUrl) => {
              uploadedPicture.url = uploadedUrl;
              // since the upload is complete, passback the updated Picture obj
              onComplete(
                null,
                {
                  caption: uploadedPicture.caption,
                  url: uploadedPicture.url,
                  filename: uploadedPicture.filename,
                }
              );
            });
          },
        );
    } else {
      console.log("Unable to upload image");
      onComplete(new Error(), null);
    }
  }
};

/**
 * This function is used to recursively upload files to the firebse storage
 * api and upon finish, it calls the callback provided with either the error
 * that was generated after upload, or the completed request with the array of
 * the new pictures that are successfully uploaded and ready for use.
 *
 * This function also does `garbage collection` in the sense that it deletes all
 * the images that have been successfull uploaded before the error in the case
 * that upload fails in between the uploading process.
 * After successfully deleting, the onCompete callback is then called with the
 * error that as generated during the upload.
 *
 * @param category this is the caegory that images are being uploaded for
 * @param picturesToUpload these are the pictures to be uploaded
 * @param onComplete this is the callback that is passed the uploaded image
 * objects as an array.
 */
export const uploadPictures = (
  category: "hotel" | "flight" | "rental" | "cruise" | "eatery" | "conference" |
    "car" | "tour" | "activity" | "info", 
  picturesToUpload: Picture[], onComplete: OnCompleteCallback<Picture[]>,
): void => {
  const uploadedPictures: Picture[] = [];
  let count = 0;

  const uploadFiles = (): void => {
    if (count === picturesToUpload.length) {
      onComplete(null, uploadedPictures);

      return;
    }

    // continure the file upload process;
    uploadPicture(category, picturesToUpload[count], (err, uploadedPic) => {
      if (err) {
        // since there may be some images that have already been uploaded, we
        // need to carry out garbage collection before we call the onComplete
        // callback
        const user = auth().currentUser;
        if (user) {
          uploadedPictures.forEach((pictureToDelete) => {
            storage()
              .ref(`/${category}/${user.uid}/${pictureToDelete.filename}`)
              .delete()
                .then(() => {
                  console.log("Successfully deleted unused picture");
                })
                .catch((err) => {
                  console.error(err);
                });
          });
        }

        onComplete(err, null);
        return;
      }

      // fetch the uploaded picture and push it to the uploaded pictures
      if (uploadedPic) {
        uploadedPictures.push(uploadedPic);
      }

      // increase the count to go to the next image and then recurse
      count++;

      uploadFiles();
    });
  }

  uploadFiles();
};

/**
 * This is a utilitarian funtion used by uploading functions to check whether
 * an image should be uploaded based on the url provided of the image to help
 * know whether it is locally stored or already uploaded to Firebase by a
 * previous function.
 *
 * @param url the url to be confirmed whether uploaded or not.
 *
 * @returns a boolean on whether the image is uploaded or not.
 */
export const isUploaded = (url: string): boolean => {
  // returns true of the image url contains firebase storage which leads to the
  // assummption that the image has a firebase url thus is already uploaded.
  return /firebasestorage/.test(url);
};

export const uploadLogo = (
  logo: Picture, onComplete: OnCompleteCallback<Picture>,
): void => {
  console.log("Logo uploading");
  const user = auth().currentUser;

  if (user) {
    if (isUploaded(logo.url)) {
      onComplete(null, logo);
      return;
    }

    if(!logo.file) {
      onComplete(new Error("Unable to upload logo"), null);
      return;
    }

    const [, ext] = logo.file.type.split("/");
    const filename = `${Date.now()}.${ext}`;
    const fileRef = storage().ref(`logo/${user.uid}/${filename}`);

    const uploadTask = fileRef.put(logo.file);

    uploadTask
      .on(
        "state_changed",
        (snapshot) => {
          console.log("File Upload progress: ");
          console.log(snapshot.bytesTransferred / snapshot.totalBytes * 100);
        }
        ,
        (error) => {
          if (error) {
            console.error(error);
          }
        }
        ,
        () => {
          fileRef.getDownloadURL().then((uploadedUrl) => {
            console.log(uploadedUrl);
            onComplete(null, {
              caption: logo.caption,
              url: uploadedUrl,
              filename: logo.filename,
            });
          });
        });
  }
};
