import {useEffect, useState} from 'react';

import {Range} from 'rc-slider'

import 'rc-slider/assets/index.css'


export default function RangeSlider({
  rangeMin, rangeMax, maxPrice, onRangeChange,
}) {
  const [value, setValue] = useState([
    Math.round(rangeMin / maxPrice * 100),
    Math.round(rangeMax / maxPrice * 100),
  ]);

  const onSliderChange = (value) => {
    setValue(value);
    onRangeChange(value);
  };

  useEffect(() => {
    setValue([
      Math.round(rangeMin / maxPrice * 100),
      Math.round(rangeMax / maxPrice * 100),
    ]);
  }, [rangeMin, rangeMax, maxPrice]);

  return (
    <div style={{ margin: 5, marginBottom: 15}}>
      <Range
        allowCross={false}
        value={value}
        onChange={onSliderChange}
      />
    </div>
  );
}
