// @ts-check
/* eslint-disable no-undef */
import {Component} from "react";
import {Link} from 'react-router-dom';
import {connect} from "react-redux";

import {
  updateSignedInStatus,
  updateTheUser,
} from "../../_system/storage/actions";
import {auth, database} from "firebase";

/**
 * This screen is used to log in the user with either one of the following methods:
 * - `OAuth`
 *    - Google
 *    - Facebook
 *    - Twitter
 * - `With Email` - allows the user to log in with their email credentials
 * All options are made available to the user with the `Gotcha` being that there is no way
 * to retrieve user details once they are already logged in with the credentials and decide
 * to use another means to log in at any given time.
 * This means that if the user logged in using facebook (for example) the next time they cannnot
 * log in with their email as they do not have any form of password verification.
 */
class App extends Component {
  state = {
    email: "",
    phoneNumber: "",
    username: "",
    login: true,
    password: "",
    password2: "",
    registering: false,
    enterData: false,
    errorVisible: false,
    errorMessage: "",
    buttonText: "Login with Email",
  };

  logUserInWithEmail() {
    // TODO Add better email validation
    if (!this.state.email || !this.state.password) {
      alert("Fill in all fields");
      return;
    }

    this.setState({ buttonText: "loading..." });
    
    auth()
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then((u) => {
        console.log(u);
        const user = u.user;
        
        database()
          .ref(`Users_Master/${user.uid}`)
          .on("value", (snapshot) => {
            if (snapshot.exists()) {
              // const auth = snapshot.val().authLevel;
              const registrationComplete = snapshot.val().registrationComplete;
              //console.log(auth + " " + user.uid);
              const value = snapshot.val();
              value["uid"] = user.uid;
              this.props.updateTheUser(value);
              if (registrationComplete) {
                //window.location = "/" + auth;
                return;
              }
              return;
            }
          });
      })
      .catch((err) => {
        alert(err);
        this.setState({ buttonText: "Try Again" });
      });
  }

  logUserInWithFacebook() {
    
    const provider = new auth.FacebookAuthProvider();
    
    auth()
      .signInWithPopup(provider)
      .then((u) => {
        const user = u.user;
        
        database()
          .ref(`Users_Master/${user.uid}`)
          .on("value", (snapshot) => {
            if (snapshot.exists()) {
              // const auth = snapshot.val().authLevel;
              const registrationComplete = snapshot.val().registrationComplete;
              //console.log(auth + " " + user.uid);
              if (registrationComplete) {
                //window.location = "/" + auth;
                return;
              }
              return;
            }
          });
      })
      .catch((err) => {
        alert(err);
      });
  }

  logUserInWithTwitter() {
    
    const provider = new auth.TwitterAuthProvider();
    
    auth()
      .signInWithPopup(provider)
      .then((u) => {
        const user = u.user;
        
        database()
          .ref(`Users_Master/${user.uid}`)
          .on("value", (snapshot) => {
            if (snapshot.exists()) {
              // const auth = snapshot.val().authLevel;
              const registrationComplete = snapshot.val().registrationComplete;
              //console.log(auth + " " + user.uid);
              if (registrationComplete) {
                //window.location = "/" + auth;
                return;
              }
              return;
            }
          });
      })
      .catch((err) => {
        alert(err);
      });
  }

  logUserInWithGoogle() {
    
    const provider = new auth.GoogleAuthProvider();
    
    auth()
      .signInWithPopup(provider)
      .then((u) => {
        //console.log(u)
        const user = u.user;
        
        database()
          .ref(`Users_Master/${user.uid}`)
          .on("value", (snapshot) => {
            if (snapshot.exists()) {
              // const auth = snapshot.val().authLevel;
              const registrationComplete = snapshot.val().registrationComplete;
              //console.log(auth + " " + user.uid);
              const value = snapshot.val();
              value["uid"] = user.uid;
              this.props.updateTheUser(value);
              if (registrationComplete) {
                //window.location = "/" + auth;
                return;
              }
              return;
            }
          });
      })
      .catch((err) => {
        alert(err);
      });
  }

  componentDidMount() {
    // not sure what this part does, probably promt user verification before
    // checkout because it doesn't seem to be of need on the log in screen
    if (this.props.status === "enterData") {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ login: false, enterData: true });
    }
  }

  render() {
    return (
      <div>
        <h1 style={{ marginTop: 10 }}>Login to Account</h1>
        <div style={{ marginBottom: 20 }}>
          Select one of the options below to login with your existing social
          account
        </div>
        <div style={{ display: "flex" }}>
          <div
            onClick={() => this.logUserInWithFacebook()}
            style={{
              cursor: "pointer",
              backgroundColor: "#3B5998",
              color: "white",
              padding: 10,
              marginRight: 10,
              flex: 1,
              textAlign: "center",
            }}
          >
            Facebook
          </div>
          <div
            onClick={() => this.logUserInWithTwitter()}
            style={{
              cursor: "pointer",
              backgroundColor: "#55ACEE",
              color: "white",
              padding: 10,
              marginRight: 10,
              flex: 1,
              textAlign: "center",
            }}
          >
            Twitter
          </div>
          <div
            onClick={() => this.logUserInWithGoogle()}
            style={{
              cursor: "pointer",
              backgroundColor: "#db4a39",
              color: "white",
              padding: 10,
              flex: 1,
              textAlign: "center",
            }}
          >
            Google
          </div>
        </div>
        <h2 style={{ marginTop: 10, marginBottom: 10 }}>OR</h2>
        <div>Use your email and password to login</div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <input
            placeholder="Email Address"
            onChange={(event) => {
              this.setState({ email: event.target.value });
            }}
            style={{
              fontSize: 20,
              fontWeight: "bold",
              paddingBottom: 15,
              border: "2px solid #aaa",
              margin: 0,
              borderRadius: 0,
              marginTop: 20,
              borderTop: 0,
              borderRight: 0,
              borderLeft: 0,
            }}
          />
          <input
            placeholder="Password"
            type="password"
            onChange={(event) => {
              this.setState({ password: event.target.value });
            }}
            style={{
              fontSize: 20,
              fontWeight: "bold",
              paddingBottom: 15,
              border: "2px solid #aaa",
              margin: 0,
              borderRadius: 0,
              marginTop: 20,
              maxWidth: 500,
              borderTop: 0,
              borderRight: 0,
              borderLeft: 0,
            }}
          />
          <Link to="/recover-password">
            <div style={{ marginTop: 20, color: "black" }}>
              Forgot password?{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>Recover</span>
            </div>
          </Link>
          <div
            onClick={() => this.logUserInWithEmail()}
            style={{
              cursor: "pointer",
              backgroundColor: "#3f3d56",
              color: "white",
              padding: 10,
              textAlign: "center",
              marginTop: 20,
            }}
          >
            {this.state.buttonText}
          </div>
          <div
            style={{ marginTop: 20, cursor: "pointer" }}
            onClick={() => {
              this.props.changeScreen("register");
            }}
          >
            {"Don't have an account yet? "}
            <a style={{ color: "red", fontWeight: "bold" }}>Register</a>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isSignedIn: state.isSignedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTheUser: (user) => dispatch(updateTheUser(user)),
    updateSignedInStatus: (status) => dispatch(updateSignedInStatus(status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
