import {Dispatch, FC, SetStateAction, useEffect, useRef, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {Card, DatePicker, Tabs, Tag} from 'antd';
import {BiMapAlt, BiSearch} from 'react-icons/bi';
import {FaArrowLeft} from 'react-icons/fa';
import {FixedSizeList as List} from 'react-window';

import {ReservationCategory} from '../../../../api';
import {AttractionSite, Nearby} from "../../../../api/attraction/types";

import {MapPropertyCardProps} from '../../components/map-property-card';

import {NearbyPropertyRefTree} from './nearby-properties';
import {MapListItem} from './map-list-item';

type AllPropertiesProps = {
  attraction?: AttractionSite;
  isFetching: boolean;
  nearbyProperties?: Nearby[];
  activeCategories: {[K in ReservationCategory]: boolean};
  nearbyPropertiesRefs: NearbyPropertyRefTree;
  setActiveTab?: Dispatch<SetStateAction<"1" | "2" | "3">>;
  onDateRangeChange: (newDates: [Date, Date]) => void;
  toggleCategory: (category: ReservationCategory) => void;
};

// FLAG FOR END OF TODAY
const TODAY = new Date();
TODAY.setHours(23, 59, 59, 999);

export const AllProperties: FC<AllPropertiesProps> = ({
  attraction, isFetching, nearbyProperties, nearbyPropertiesRefs,
  setActiveTab, onDateRangeChange, toggleCategory,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const searchParams = new URLSearchParams(useLocation().search);
  const [startSearchDate, setStartSearchDate] = useState<Date>();
  const [endSearchDate, setEndSearchDate] = useState<Date>();
  const [isStale, setIsStale] = useState<boolean>(true);

  useEffect(() => {
    setIsStale(true);
  }, [isFetching])

  return (
    <div className="w-full sm:w-100 max-h-full">
      <div
        ref={headerRef}
        className="bg-center bg-cover bg-no-repeat sticky top-0 shadow-md"
        style={{
          backgroundImage: `url(${
            attraction ?
              attraction.gallery[0].url :
            "https://images.pexels.com/photos/9506085/pexels-photo-9506085.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
          })`,
        }}
      >
        <div
          className="w-full flex flex-col justify-between z-10 shadow-md bg-safari-gold bg-opacity-30 text-white"
          style={{
            height: 128,
            padding: "16px 16px 0px 16px",
          }}
        >
          {/* First part of the header that is always sticky */}
          <div
            className="flex flex-row justify-between sticky"
            style={{ top: 16, paddingBottom: 16 }}
          >
            {attraction && (
              <Link
                to={`/view-attraction/${
                  attraction.id
                }/details?${searchParams.toString()}`}
              >
                <FaArrowLeft size={24} className="mix-blend-color-screen" />
              </Link>
            )}

            {!attraction && (
              <div className="h-6 w-6 rounded-full animate-pulse bg-gray-400" />
            )}
            {/* Context actions */}
            <div
              className="flex flex-row"
              style={{ padding: "0px 0px 0 16px" }}
            >
              <BiMapAlt
                className="sm:hidden"
                size={24}
                // style={{marginRight: 12}}
                onClick={() => {
                  setActiveTab && setActiveTab("2");
                }}
              />
              {/* <BiCalendarEdit size={24} /> */}
            </div>
          </div>

          {/* Title container */}
          {attraction && (
            <h6
              className="text-white text-xl font-lato font-semibold"
              style={{ marginLeft: 48, marginBottom: 28, top: 16 }}
            >
              {attraction.name}
            </h6>
          )}

          {!attraction && (
            <div
              className="bg-gray-400 h-5 w-56 animate-pulse rounded-sm"
              style={{ marginLeft: 48, marginBottom: 28, top: 16 }}
            />
          )}
        </div>
        <div className="bg-white" style={{ padding: 16 }}>
          <p className="text-xs mb-1">
            Select date range to look for property availability
          </p>
          <div
            className="flex flex-row items-center justify-items-stretch"
            style={{ height: 40 }}
          >
            <DatePicker.RangePicker
              className="w-full flex-grow"
              disabledDate={(date) =>
                TODAY.getTime() > Number(date.format("x"))
              }
              onChange={(vals) => {
                if (vals) {
                  setStartSearchDate(vals[0]?.toDate());
                  setEndSearchDate(vals[1]?.toDate());
                  setIsStale(false);
                }
              }}
            />

            <button
              disabled={!startSearchDate || !endSearchDate || isStale}
              className={`ml-3 shadow-md ${
                !startSearchDate || !endSearchDate || isStale
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-safari-gold"
              } text-white rounded-full flex-shrink-0 flex-grow-0`}
              style={{ padding: 8 }}
              onClick={() => {
                // make sure the dates arent falsy values
                if (!startSearchDate || !endSearchDate) {
                  return;
                }

                onDateRangeChange([startSearchDate, endSearchDate]);
              }}
            >
              <BiSearch size={24} />
            </button>
          </div>
        </div>
      </div>
      <div className="px-4">
        {/* <p className="text-xs pt-2">Click on a category to turn it on or off</p>
        <div className="flex flex-row flex-wrap border-b pb-2">
          {[
            "activity",
            "car",
            "conference",
            "eatery",
            "hotel",
            "rental",
            "tour",
          ].map((category) => (
            <Tag
              key={`${category}_tag`}
              className="mt-2 cursor-pointer"
              color={
                activeCategories[category as ReservationCategory]
                  ? "#FF5900"
                  : "default"
              }
              onClick={() => {
                toggleCategory(category as ReservationCategory);
              }}
            >
              {category}
            </Tag>
          ))}
        </div> */}
        {isFetching &&
          [1, 2, 3].map((i) => <Card key={`loading_card_${i}`} loading />)}

        {attraction && !isFetching && nearbyProperties && (
          <Tabs>
            {nearbyProperties
              .filter((nearby) => nearby.properties.length > 0)
              .map(
                (nearby) =>
                  nearby.properties.length > 0 && (
                    <Tabs.TabPane
                      key={nearby.category}
                      tab={nearby.category}
                    >
                      {
                        <>
                          <List<MapPropertyCardProps[]>
                            height={
                              headerRef.current ?
                                window.innerHeight - headerRef.current.clientHeight :
                                window.innerHeight - 200
                            }
                            itemCount={nearby.properties.length}
                            itemSize={156}
                            width="100%"
                            itemData={nearby.properties.map((property, idx) => ({
                              attraction,
                              category: nearby.category,
                              property,
                              refs: nearbyPropertiesRefs[nearby.category][idx]
                            } as MapPropertyCardProps))}
                          >
                            {MapListItem}
                          </List>
                        </>
                      }
                    </Tabs.TabPane>
                  )
              )}
          </Tabs>
        )}
      </div>
    </div>
  );
};
