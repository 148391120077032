// @ts-check
/* eslint-disable no-undef */
import React from "react";
import GoogleMapReact from "google-map-react";
import {connect} from "react-redux";
import {StaticGoogleMap, Marker} from "react-static-google-map";
import {FaStar, FaCheck, FaMapMarkerAlt} from "react-icons/fa";
import {FcApproval} from "react-icons/fc";
import {Link} from 'react-router-dom';

import {updateTripPlanner} from "../../_system/storage/actions";
import marker_asset from "../../assets/marker_1.png";

// import Reviews from "./shared/Reviews";
import RoomModal from "./hotel/RoomModal";
import GalleryModal from "./shared/GalleryModal";
import Gallery from "./components/Gallery";
import Header from "../index/components/Header";
import {AppFooter} from "../index/components/app-footer";

function showDirections(lat, long) {
  // If it's an iPhone..
  if (
    navigator.platform.indexOf("iPhone") != -1 ||
    navigator.platform.indexOf("iPod") != -1 ||
    navigator.platform.indexOf("iPad") != -1
  )
    window.open(
      `maps://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${ 
        lat 
        },${ 
        long 
        }`
    );
  else
    window.open(
      `https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${ 
        lat 
        },${ 
        long 
        }`
    );
}

// eslint-disable-next-line no-unused-vars
function AnyReactComponent({lat, lng, text}) {
  return (
    <div>
      <img src={marker_asset} style={{ width: 50, height: 50 }} />
    </div>
  );
}

// TODO Finish up the refactor the other dev started that removes the different sections to
// make them each their own modules.
/**
 * This componet shows a Hotel based with details about the rooms available on the property
 * and allows the user to book hotel rooms after viewing the services, amenities and servies
 * offered in the hotel.
 */
class App extends React.Component {
  state = {
    galleryModalActive: false,
    roomModalActive: false,
    selectedItem: false,
    returnDate: "",
    tabs: [
      {
        title: "overview",
        active: true,
        reference: React.createRef(),
        scrollFun: () => {
          this.state.tabs[0].reference.current.scrollIntoView({
            behavior: "smooth",
          });
        },
      },
      {
        title: "rooms",
        active: false,
        reference: React.createRef(),
        scrollFun: () => {
          this.state.tabs[1].reference.current.scrollIntoView({
            behavior: "smooth",
          });
        },
      },
      {
        title: "service & amenities",
        active: false,
        reference: React.createRef(),
        scrollFun: () => {
          this.state.tabs[2].reference.current.scrollIntoView({
            behavior: "smooth",
          });
        },
      },
      {
        title: "policies",
        active: false,
        reference: React.createRef(),
        scrollFun: () => {
          this.state.tabs[3].reference.current.scrollIntoView({
            behavior: "smooth",
          });
        },
      },
      {
        title: "maps",
        active: false,
        reference: React.createRef(),
        scrollFun: () => {
          this.state.tabs[4].reference.current.scrollIntoView({
            behavior: "smooth",
          });
        },
      },
      {
        title: "reviews",
        active: false,
        reference: React.createRef(),
        scrollFun: () => {
          this.state.tabs[5].reference.current.scrollIntoView({
            behavior: "smooth",
          });
        },
      },
    ],
    fieldData: [],
    stars: [],
    nostars: [],
    roomData: [],
    hotelFeatures: [
      { title: "Air Conditioning", value: "aircon", checked: false },
      { title: "Airport Transport", value: "aircon", checked: false },
      { title: "Fitness Center", value: "aircon", checked: false },
      { title: "Flat Tv", value: "aircon", checked: false },
      { title: "Heater", value: "aircon", checked: false },
      { title: "Wi-Fi Internet", value: "aircon", checked: false },
      { title: "Parking", value: "aircon", checked: false },
      { title: "Pool", value: "aircon", checked: false },
      { title: "Restaurant", value: "aircon", checked: false },
      { title: "Shuttle Bus Service", value: "aircon", checked: false },
      { title: "Smoking Room", value: "aircon", checked: false },
      { title: "SPA", value: "aircon", checked: false },
      { title: "Spa &amp; Sauna", value: "aircon", checked: false },
      { title: "Vocabulary", value: "aircon", checked: false },
      { title: "Washer &amp; Dryer", value: "aircon", checked: false },
    ],
    reviews: [
      {
        userId: "123456",
        username: "John Doe",
        score: 5,
        review: "I Love the hotel",
        country: "kenya",
      },
      {
        userId: "123456",
        username: "Jane Doe",
        score: 5,
        review: "I Love the hotel",
        country: "kenya",
      },
      {
        userId: "123456",
        username: "Marry Doe",
        score: 5,
        review: "I Love the hotel",
        country: "kenya",
      },
      {
        userId: "123456",
        username: "Anne Doe",
        score: 5,
        review: "I Love the hotel",
        country: "kenya",
      },
      {
        userId: "123456",
        username: "Edwin Doe",
        score: 5,
        review: "I Love the hotel",
        country: "kenya",
      },
      {
        userId: "123456",
        username: "Kevin Doe",
        score: 5,
        review: "I Love the hotel",
        country: "kenya",
      },
      {
        userId: "123456",
        username: "Andrew Doe",
        score: 5,
        review: "I Love the hotel",
        country: "kenya",
      },
      {
        userId: "123456",
        username: "Patric Doe",
        score: 5,
        review: "I Love the hotel",
        country: "kenya",
      },
    ],
    picture_galery: [],
    hotel_reviews: [],
    urlVars: "",
  };
  componentDidMount() {
    let hotelId, urlVars;
    if (this.props.origin == "trip-planner") {
      hotelId = window.location.href.split("hotel=")[1].split("#")[0];
      urlVars = window.location.href.split("search/")[1].split("/hotel")[0];
    } else {
      hotelId = window.location.href.split("hotel/")[1].split("/")[0];
      urlVars = window.location.href.split(`hotel/${  hotelId  }/`)[1];
    }
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ hotelId, urlVars });
    
    firebase
      .firestore()
      .collection("Hotel_Directory")
      .doc(hotelId)
      .collection("Data")
      .doc("Info")
      .get()
      .then((doc) => {
        if (doc.exists) {
          //console.log("Document data:", doc.data());
          //console.log(doc)
          this.setState({ fieldData: doc.data() });
          const stars = [];
          const notstars = [];
          for (let i = 0; i < doc.data().hotel_star; i++) {
            stars.push("");
          }
          for (let i = 0; i < 5 - doc.data().hotel_star; i++) {
            notstars.push("");
          }

          const picture_galery = doc.data().picture_gallery;
          if (picture_galery) {
            this.setState({ picture_galery });
          }
          const hotel_reviews = doc.data().hotel_reviews;
          console.log("hotel_reviews", hotel_reviews);
          if (hotel_reviews) {
            this.setState({ hotel_reviews });
          }

          this.setState({
            nostars: notstars,
            stars,
            center: {
              lat: parseFloat(doc.data().map_lat),
              lng: parseFloat(doc.data().map_lng),
            },
          });
        } else {
          // doc.data() will be undefined in this case
          //console.log("No such documents!");
        }
        this.setState({ isLoading: false });
      });
    
    firebase
      .firestore()
      .collection("Hotel_Directory")
      .doc(hotelId)
      .collection("Rooms")
      .get()
      .then((querySnapshot) => {
        let newRooms = [];
        querySnapshot.forEach((doc) => {
          let roomItem = doc.data();
          roomItem["roomId"] = doc.id;
          newRooms.push(roomItem);
        });
        this.setState({ roomData: newRooms });
      });
  }

  _addToTrip = (item) => {
    const trip_id = window.location.pathname
      .split("planner/")[1]
      .split("/search")[0];
    
    const uid = firebase.auth().currentUser.uid;
    let data = item;
    data["search_vars"] = window.location.pathname;
    
    firebase
      .database()
      .ref(
        `Trip_Planner/${uid}/${trip_id}/items/hotels/${item.roomId}`
      )
      .update(data)
      .then(() => {
        window.location.href =
          `/trip-planner/${trip_id}#trip-view-accomodation`;
      });
  };

  async _checkout(data) {
    const hotelId = window.location.href.split("hotel/")[1].split("#")[0];
    await this.setState({ selectedItem: data, hotelId });

    if (!this.props.tripPlanner.activity) {
      this.setState({ galleryModalActive: true });
      return;
    }
    let hotelList = {};
    hotelList[`${hotelId  }`] = [data];
    await this.props.updateTripPlanner({
      activity: false,
      startDate: false,
      endDate: false,
      hotels: hotelList,
      flights: [],
    });
    window.location.href = "/planner";
  }

  _hideFunction() {
    this.setState({ galleryModalActive: false, roomModalActive: false });
  }

  _showRoomModal = (data) => {
    this.setState({ visibleRoom: data, roomModalActive: true });
    // this.state.topRef.current.scrollIntoView({behavior: "smooth"});
  };

  _setSharedState = (state) => {
    this.setState(state);
  };

  render() {
    return (
      <>
        {this.props.origin != "trip-planner" && <Header />}

        <div className="view-inner-width">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                background: "#f2f2f2",
                width: "100%",
                height: "100%",
                marginTop: 50,
              }}
            >
              {/* Container of the Header, Gallery and Hotel Location and Description */}
              <div
                ref={this.state.tabs[0].reference}
                style={{
                  background: "#fff",
                  margin: "auto",
                  paddingBottom: 20,
                  marginBottom: 20,
                }}
              >
                {/* Header for the Hotel */}
                {/* Contains the Hotel Name And Location, and the Select A room button */}
                <div style={{ padding: 20, paddingBottom: 0, display: "flex" }}>
                  {/* Hotel Name and Adtress container */}
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      height: 37,
                      marginTop: 6,
                    }}
                  >
                    <div className="selector-heading">
                      {this.state.fieldData.title}
                    </div>
                    <div style={{ display: "flex", color: "#555" }}>
                      <div style={{ marginRight: 5 }}>
                        <FaMapMarkerAlt />
                      </div>
                      <div style={{ fontWeight: 100, fontSize: 14 }}>
                        {this.state.fieldData.address}
                      </div>
                    </div>
                  </div>
                  {/* Header Ttile and Adress End */}
                  {/* Select a ROOM button */}
                  <div style={{ paddingTop: 10 }}>
                    <div
                      className="but but-transparent"
                      style={{
                        borderRadius: 0,
                        color: "black",
                        borderColor: "black",
                      }}
                      onClick={() => {
                        this.state.tabs[1].scrollFun();
                      }}
                    >
                      SELECT A ROOM
                    </div>
                  </div>
                  {/* Select room button end */}
                </div>
                {/* End of the Header */}
                {/* Media View of hotel pictures */}
                <Gallery
                  setSharedState={this._setSharedState}
                  sharedState={this.state}
                />
                {/* Hotel Location and description container */}
                <div className="view-location-parent">
                  {/* Hotel Location and Directions */}
                  <div style={{ flex: 1, paddingRight: 20 }}>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: 30,
                        display: "flex",
                      }}
                    >
                      <div style={{ flex: 1 }}>Hotel Location</div>
                      <div
                        className="view-show-directions"
                        onClick={() =>
                          showDirections(
                            this.state.center.lat,
                            this.state.center.lng
                          )
                        }
                      >
                        Show Directions
                      </div>
                    </div>
                    <div
                      style={{
                        height: 200,
                        overflow: "hidden",
                        margin: "auto",
                        marginTop: 20,
                      }}
                    >
                      {this.state.fieldData.map_lat ? (
                        <div>
                          <StaticGoogleMap
                            size="500x200"
                            apiKey="AIzaSyDxrJtbT4XhPoiNlXoOsNZ77j38ai9ZwAo"
                          >
                            <Marker.Group label="h" color="red">
                              <Marker
                                location={`${this.state.fieldData.map_lat},${this.state.fieldData.map_lng}`}
                              />
                            </Marker.Group>
                          </StaticGoogleMap>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  {/* Hotel Description */}
                  <div style={{ flex: 1 }}>
                    <div style={{ display: "flex" }}>
                      <div style={{ flex: 1 }}>
                        <div style={{ fontWeight: "bold", fontSize: 30 }}>
                          Description
                        </div>
                        <div style={{ display: "flex", marginTop: 5 }}>
                          {this.state.stars.map(() => {
                            return (
                              <div>
                                <FaStar style={{ color: "#ff6000" }} />
                              </div>
                            );
                          })}
                          {this.state.nostars.map(() => {
                            return (
                              <div>
                                <FaStar />
                              </div>
                            );
                          })}
                        </div>
                        <div style={{ fontWeight: 600 }}>
                          {this.state.fieldData.hotel_star} star hotel
                        </div>
                      </div>
                      <div style={{ paddingTop: 20 }}>
                        <div
                          style={{
                            backgroundColor: "#f60000",
                            color: "white",
                            padding: 5,
                            display: "inline",
                            margin: 10,
                            fontSize: 20,
                            fontWeight: "bold",
                          }}
                        >
                          {!this.state.fieldData.hotel_review_score
                            ? "0/10"
                            : `${this.state.fieldData.hotel_review_score}/10`}
                        </div>
                        <div style={{ marginTop: 15, fontWeight: 600 }}>
                          0 reviews
                        </div>
                      </div>
                    </div>
                    <div
                      style={{ marginTop: 10 }}
                      className="description_text_main"
                    >
                      {this.state.fieldData.long_description}
                    </div>
                  </div>
                </div>
              </div>
              {/* Room Data */}
              <div
                style={{ background: "#f2f2f2" }}
                ref={this.state.tabs[1].reference}
              >
                {/* Map out all the rooms */}
                {this.state.roomData.map((item) => {
                  return (
                    <div
                      style={{ background: "#fff", marginTop: 30, padding: 20 }}
                    >
                      {/* Room Type Title Starts here */}
                      <div
                        style={{
                          fontSize: 30,
                          fontWeight: "bold",
                          marginBottom: 20,
                        }}
                      >
                        {item.title}
                      </div>
                      <div className="view-room-item">
                        {/* Room Type Column */}
                        <div>
                          <div
                            style={{
                              fontSize: 19,
                              fontWeight: 600,
                              color: "grey",
                              background: "#f9f9f9",
                              textAlign: "center",
                              padding: 20,
                              marginBottom: 20,
                            }}
                          >
                            Room Type
                          </div>
                          <div
                            onClick={() => this._showRoomModal(item)}
                            style={{
                              border: "1px solid #ccc",
                              height: 150,
                              width: "100%",
                              backgroundColor: "#ccc",
                            }}
                          />
                          <div
                            onClick={() => this._showRoomModal(item)}
                            style={{
                              fontWeight: "bold",
                              color: "darkorange",
                              textAlign: "center",
                              margin: 10,
                            }}
                          >
                            view room
                          </div>
                        </div>
                        {/* Features Column */}
                        <div className="view-room-item-details">
                          {/* Features Column */}
                          <div style={{}}>
                            <div
                              style={{
                                fontSize: 19,
                                fontWeight: 600,
                                color: "grey",
                                background: "#f9f9f9",
                                textAlign: "center",
                                padding: 20,
                                marginBottom: 20,
                              }}
                            >
                              Features
                            </div>
                            <div
                              style={{
                                marginLeft: 20,
                                marginRight: 20,
                                marginTop: 20,
                              }}
                            >
                              {item.room_features ? (
                                <>
                                  {item.room_features.map((feature) => (
                                    <>
                                      {feature == "deluxe" ? (
                                        <FcApproval
                                          title={feature}
                                          style={{
                                            marginRight: 10,
                                            fontSize: 20,
                                          }}
                                        />
                                      ) : (
                                        <></>
                                      )}
                                      {/* <div
                                        style={{
                                          overflow: "hidden",
                                          maxWidth: 110,
                                          fontSize: 11,
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        <TiStarburstOutline
                                          style={{ marginRight: 10 }}
                                        />
                                        {feature.substr(0, 30)}
                                      </div> */}
                                    </>
                                  ))}
                                </>
                              ) : (
                                <>
                                  <div
                                    style={{
                                      overflow: "hidden",
                                      maxWidth: 90,
                                      fontSize: 11,
                                      textTransform: "capitalize",
                                      textAlign: "center",
                                    }}
                                  >
                                    ...
                                  </div>
                                </>
                              )}

                              {/* <div
                                style={{
                                  border: "1px solid #ccc",
                                  padding: 10,
                                  display: "inline",
                                  marginLeft: 0,
                                }}
                              >
                                <FaWifi />
                              </div>
                              <div
                                style={{
                                  border: "1px solid #ccc",
                                  padding: 10,
                                  display: "inline",
                                  marginLeft: 10,
                                }}
                              >
                                <FaWifi />
                              </div> */}
                            </div>
                          </div>
                          {/* Desciption Container */}
                          <div
                            style={{
                              borderLeft: "1px solid #fff",
                              borderRight: "1px solid #fff",
                              flex: 1,
                            }}
                          >
                            <div
                              style={{
                                fontSize: 19,
                                fontWeight: 600,
                                color: "grey",
                                background: "#f9f9f9",
                                textAlign: "center",
                                padding: 20,
                                marginBottom: 20,
                              }}
                            >
                              Description
                            </div>
                            <div
                              className="description_text_main"
                              style={{ fontSize: 14 }}
                            >
                              {item.description
                                ? `${item.description.substr(0, 200)}...`
                                : ""}
                            </div>
                          </div>
                          {/* Extras Column */}
                          <div style={{ flex: 1, textAlign: "center" }}>
                            <div
                              style={{
                                fontSize: 19,
                                fontWeight: 600,
                                color: "grey",
                                background: "#f9f9f9",
                                textAlign: "center",
                                padding: 20,
                                marginBottom: 20,
                              }}
                            >
                              Extras
                            </div>
                            <div style={{ fontSize: 15, fontWeight: 600 }}>
                              rooms remaining
                              <br />{" "}
                              <label
                                style={{
                                  backgroundColor: "#ff6000",
                                  color: "white",
                                  padding: 3,
                                  fontSize: 12,
                                  borderRadius: 4,
                                  marginLeft: 3,
                                  paddingLeft: 6,
                                  paddingRight: 6,
                                  textAlign: "center",
                                }}
                              >
                                {item.number_room}
                              </label>
                            </div>
                          </div>
                          {/* Average Price and Book Room One */}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              paddingRight: 10,
                            }}
                          >
                            <div
                              style={{
                                fontSize: 19,
                                fontWeight: 600,
                                color: "grey",
                                background: "#f9f9f9",
                                textAlign: "center",
                                padding: 20,
                                marginBottom: 20,
                              }}
                            >
                              Avg. price per room/night
                            </div>
                            <div
                              style={{
                                fontSize: 30,
                                fontWeight: "bold",
                                color: "#ff0000",
                                textAlign: "center",
                              }}
                            >
                              {item.price} USD
                            </div>
                            <div
                              style={{ display: "flex", position: "relative" }}
                            >
                              <div style={{ flex: 1 }} />
                              <>
                                {this.props.origin != "trip-planner" ? (
                                  <Link
                                    to={`/checkout/hotel=${
                                      this.state.hotelId
                                    }/room=${
                                      item.roomId
                                    }/${
                                      this.state.urlVars
                                    }`}
                                  >
                                    <div
                                      className="listYourSpaceBt"
                                      style={{
                                        display: "inline-block",
                                        textAlign: "center",
                                        borderRadius: 2,
                                        marginTop: 20,
                                      }}
                                    >
                                      BOOK ROOM
                                    </div>
                                  </Link>
                                ) : (
                                  <div
                                    onClick={() => this._addToTrip(item)}
                                    className="listYourSpaceBt"
                                    style={{
                                      display: "inline-block",
                                      textAlign: "center",
                                      borderRadius: 2,
                                      marginTop: 20,
                                    }}
                                  >
                                    ADD TO TRIP PLAN
                                  </div>
                                )}
                              </>
                              <div style={{ flex: 1 }} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* Services section */}
              <div
                ref={this.state.tabs[2].reference}
                style={{ background: "#f2f2f2" }}
              >
                <div style={{ padding: 20, backgroundColor: "#fff" }}>
                  <div
                    style={{
                      fontSize: 30,
                      fontWeight: "bold",
                      marginBottom: 20,
                    }}
                  >
                    Services & amenities
                  </div>
                  <div className="view-amenities">
                    {this.state.hotelFeatures.map((item) => {
                      return (
                        <div
                          style={{
                            textAlign: "left",
                            display: "flex",
                            margin: 10,
                            padding: 10,
                          }}
                        >
                          <div style={{ padding: 3, paddingRight: 10 }}>
                            <FaCheck />
                          </div>
                          <label
                            htmlFor={item.title}
                            style={{ height: 0, paddingTop: 0 }}
                          >
                            {" "}
                            {item.title}{" "}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              {/* Policies Section */}
              <div
                ref={this.state.tabs[3].reference}
                style={{ background: "#f2f2f2" }}
              >
                <div style={{ padding: 20, backgroundColor: "#fff" }}>
                  <div
                    style={{
                      fontSize: 30,
                      fontWeight: "bold",
                      marginBottom: 20,
                    }}
                  >
                    Policies
                  </div>
                </div>
              </div>
              {/* Maps Section */}
              <div
                ref={this.state.tabs[4].reference}
                style={{ background: "#f2f2f2" }}
              >
                <div
                  style={{
                    padding: 20,
                    backgroundColor: "#fff",
                    display: "flex",
                    flexDirection: "column",
                    height: 550,
                  }}
                >
                  <div style={{ height: 450, width: "100%", marginTop: 20 }}>
                    {this.state.center && (
                      <GoogleMapReact
                        bootstrapURLKeys={{
                          key: "AIzaSyDxrJtbT4XhPoiNlXoOsNZ77j38ai9ZwAo",
                        }}
                        defaultCenter={this.state.center}
                        defaultZoom={16}
                      >
                        <AnyReactComponent
                          lat={this.state.center.lat}
                          lng={this.state.center.lng}
                          text="Hotel name"
                        />
                      </GoogleMapReact>
                    )}
                  </div>
                </div>
              </div>
              {/* <Reviews
                reference={this.state.tabs[5].reference}
                sharedState={this.state}
                reviewScore={this.state.fieldData.hotel_review_score}
                hotel_reviews={this.state.hotel_reviews}
                hotelId={this.state.hotelId}
              /> */}
            </div>
          </div>
        </div>
        {/* Gallery Modal */}
        {this.state.galleryModalActive && (
          <GalleryModal
            hideFunc={() => this._hideFunction()}
            module="hotel"
            sharedState={this.state}
          />
        )}
        {/* Room Modal opens on?? */}
        {this.state.roomModalActive && (
          <RoomModal
            hideFunc={() => this._hideFunction()}
            module="hotel"
            sharedState={this.state}
            roomData={this.state.visibleRoom}
          />
        )}

        {this.props.origin != "trip-planner" && <AppFooter origin="" />}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tripPlanner: state.tripPlanner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTripPlanner: (tripData) => dispatch(updateTripPlanner(tripData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
