import {Table, Tag} from 'antd';
import {FC, useContext} from 'react';
import {RouteComponentProps} from 'react-router-dom';

import {ReservationCategory} from '../../../../api';
import {PopulatedAffiliate} from '../../../../api/affiliate/types';
import {PopulatedReservation} from '../../../../api/reservation/types';
import {AffiliateStateContext} from '../../../../context/affiliate';

export const AffiliateStatistics: FC<RouteComponentProps> = () => {
  const {state} = useContext(AffiliateStateContext);
  const affiliateLinks: PopulatedAffiliate[] = [];
  const affiliateReservations: PopulatedReservation[] = [];

  for (const category in state.affiliateSummary.affiliateLinks) {
    state.affiliateSummary.affiliateLinks[category as ReservationCategory]
      .links.forEach((link) => {
        link.populated_follow_throughs.forEach((followThrough) => {
          affiliateReservations.push(followThrough);
          affiliateLinks.push(link);
        });
      });
  }

  return (
    <div>
      <Table
        columns={[
          {
            dataIndex: "id",
            title: "[#Ref_ID]",
            key: "id",
          },
          {
            dataIndex: "reservation_date",
            title: "Date",
            key: "id",
          },
          {
            dataIndex: "category",
            title: "Category",
            key: "id",
          },
          {
            dataIndex: "property",
            title: "Property",
            key: "id",
          },
          {
            dataIndex: "status",
            title: "Status",
            key: "id",
            render: (status: PopulatedReservation["status"], row, index) => (
              <Tag color={status === "complete" ? "green" : "red"}>
                {status}
              </Tag>
            ),
          },
          {
            dataIndex: "expected_amount",
            title: "Amount",
            key: "id",
          },
          {
            dataIndex: "expected_amount",
            title: "Commission",
            key: "id",
            render: (commision) => {
              return `$ ${(Number(commision) * 0.15).toFixed(2)}`;
            },
          },
        ]}
        dataSource={affiliateReservations}
        pagination={{pageSize: 10}}
      />
    </div>
  );
};
