import { Component } from "react";
import { getPreviousTrips } from "../system/DataManager";
import Lottie from "react-lottie";
import animation from "../../../assets/anim/loading_1.json";

class App extends Component {
  state = {
    loading: true,
    myTrips: [],
  };
  _setSharedState = (state) => {
    this.setState(state);
  };
  componentDidMount() {
    getPreviousTrips(this._setSharedState);
  }
  render() {
    if (this.state.loading) {
      return (
        <div style={{ paddingTop: "15%" }}>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animation,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={170}
            width={170}
          />
          <h2 style={{ textAlign: "center" }}>Your Trips</h2>
          <p style={{ textAlign: "center" }}>Loading your created trips...</p>
        </div>
      );
    }
    return (
      <>
        {this.state.myTrips.length == 0 ? (
          <div>
            <h1>You have no trips</h1>
          </div>
        ) : (
          <></>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 150,
          }}
        >
          <div>
            <h1>Your Trips</h1>
          </div>
          {this.state.myTrips.map((item) => {
            return (
              <div
                key={item.key}
                style={{
                  display: "flex",
                  border: "1px solid #eee",
                  padding: 20,
                  width: 500,
                }}
              >
                <div style={{ fontWeight: "bold", flex: 1 }}>
                  {item.trip_name}
                </div>
                {/* <div>{item.trip_destination}</div> */}
                {/* <div>created: {item.time}</div> */}
                <div
                  onClick={() =>
                    (window.location = "/trip-planner/" + item.key)
                  }
                  style={{
                    fontWeight: "bold",
                    color: "#0a7fb5",
                    cursor: "pointer",
                  }}
                >
                  Edit
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }
}

export default App;
