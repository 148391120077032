import {FC, useEffect, useState} from 'react';

import {ReservationCategory} from '../../../../../api';
import {useHistory} from 'react-router-dom';

type CompleteProps = {
  category: ReservationCategory;
  propertyDetails: {
    name: string;
    email: string;
    phoneNumber: string;
    tillNumber: string;
    paybillNumber: string;
    website: string;
    video: string;
  };
};

export const Complete: FC<CompleteProps> = ({category, propertyDetails}) => {
  const history = useHistory();

  const [coverImage, setCoverImage] = useState<string>("");

  const importImage = async () => {
    let coverIcon: string;
  
    switch (category) {
      case "activity":
        coverIcon = (await import('../../../../../assets/service_activity.png')).default;
        break;
      case "car":
        coverIcon = (await import('../../../../../assets/service_car.png')).default;
        break;
      case "conference":
        coverIcon = (await import('../../../../../assets/service_conferencing.png')).default;
        break;
      case "eatery":
        coverIcon = (await import('../../../../../assets/service_restaurant.png')).default;
        break;
      case "hotel":
        coverIcon = (await import('../../../../../assets/service_hotel.png')).default;
        break;
      case "rental":
        coverIcon = (await import('../../../../../assets/service_rental.png')).default;
        break;
      case "tour":
        coverIcon = (await import('../../../../../assets/service_tour.png')).default;
        break;
      default:
        coverIcon = (await import('../../../../../assets/service_hotel.png')).default;
        break;
    }

    setCoverImage(coverIcon)
  };

  useEffect(() => {
    importImage();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="w-full h-full flex flex-col items-center">
      <img
        alt="Successfully added property details!"
        src={coverImage}
        style={{height: 128}}
      />
      <p className="mt-3 text-center">Successfully added details!</p>
      
      <h6 className="text-2xl font-lato my-2 pb-2 w-full border-b font-semibold">
        {propertyDetails.name}
      </h6>

      <div className="grid grid-cols-2 border-b h-12 w-full">
        <h6 className="font-semibold font-lato my-auto">
          Email
        </h6>
        <p className="place-self-center justify-self-center">
          {propertyDetails.email}
        </p>
      </div>

      <div className="grid grid-cols-2 border-b h-12 w-full">
        <h6 className="font-semibold font-lato my-auto">
          Phone Number
        </h6>
        <p className="place-self-center justify-self-center">
          {propertyDetails.phoneNumber}
        </p>
      </div>

      <div className="grid grid-cols-2 border-b h-12 w-full">
        <h6 className="font-semibold font-lato my-auto">
          Mpesa Till Number
        </h6>
        <p className="place-self-center justify-self-center">
          {propertyDetails.tillNumber}
        </p>
      </div>

      <div className="grid grid-cols-2 border-b h-12 w-full">
        <h6 className="font-semibold font-lato my-auto">
          Mpesa Paybill number
        </h6>
        <p className="place-self-center justify-self-center">
          {propertyDetails.paybillNumber}
        </p>
      </div>

      <div className="grid grid-cols-2 border-b h-12 w-full">
        <h6 className="font-semibold font-lato my-auto">
          Website
        </h6>
        <p className="place-self-center justify-self-center">
          {propertyDetails.website}
        </p>
      </div>

      <div className="grid grid-cols-2 border-b h-12 w-full">
        <h6 className="font-semibold font-lato my-auto">
          video
        </h6>
        <p className="place-self-center justify-self-center">
          {propertyDetails.video}
        </p>
      </div>

      <div
        className={
          "mt-3 py-3 px-4 bg-gradient-to-r from-secondary to-secondary-end " +
          "text-white font-semibold font-lato rounded-full cursor-pointer"
        }
        onClick={() => {
          history.replace(`/property-owner/${category}`);
        }}
      >
        Back to all properties
      </div>
    </div>
  );
};
