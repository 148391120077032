import { FC } from "react";
import {FaPlusCircle} from "react-icons/fa";
import { EditPolicy } from ".";

import {ReservationType} from "../../../api";

import {OverlayProvider} from "../../search-property/context";

type EditReservationPolicyListItemProps = {
  reservationPolicy: ReservationType;
  onSave: (reservationPolicy: ReservationType) => void;
};

export const EditReservationPolicyListItem: FC<EditReservationPolicyListItemProps> =
  ({ reservationPolicy, onSave, }) => {
    const reservationPolicies = {
      full_amount: "Full amount on reservation",
      partial_deposit: "Partial amount on reservation",
      no_deposit: "No amount on reservation",
    };

    return (
      <OverlayProvider.Consumer>
        {({setIsOverlay, setOnOverlay}) => (
          <div
            className={
              "mt-4 px-4 p-2 shadow-md flex flex-col justify-center " +
              "bg-secondary rounded-md text-white lg:mt-0 cursor-pointer"
            }
            onClick={() => {
              setOnOverlay && setOnOverlay(() => () => (
                <EditPolicy
                  reservationPolicy={reservationPolicy}
                  removeOverlay={() => {
                    setOnOverlay && setOnOverlay(null);
                    setIsOverlay && setIsOverlay(false);
                  }}
                  onSave={({reservationPolicy}) => {
                    reservationPolicy && onSave(reservationPolicy);
                  }}
                />
                ));

                setIsOverlay && setIsOverlay(true);
            }}
          >
            <div className="flex flex-row items-center justify-between w-full">
              <div>
                <h6 className="font-semibold">Reservation Policy</h6>
                <p className="text-sm opacity-70">
                  {reservationPolicies[reservationPolicy]}
                </p>
              </div>
              <FaPlusCircle color="#FF5900" size={24} />
            </div>
            <hr className="mt-4 bg-white border-white" />
            <p className="text-xs mt-1">Press to pick a reservation policy</p>
          </div>
        )}
      </OverlayProvider.Consumer>
    );
  };
