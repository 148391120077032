import {FC, useState} from 'react';
import {FaPaperPlane} from 'react-icons/fa';

import {ReservationCategory, Review, updateReviewReply} from "../../../../api";
import { TextArea } from '../../../../components/input';

type UserReviewProps = {
  propertyId: string;
  propertyCategory: ReservationCategory,
  idx: number;
  propertyTitle: string;
  propertyLocation: string;
  review: Review;
};

export const UserReview: FC<UserReviewProps> = ({
  propertyId, propertyCategory, propertyTitle, propertyLocation, review, idx,
}) => {
  const [reply, setReply] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  return (
    <div className="border p-4 rounded-md shadow-md">
      {/** Review Container Header */}
      <div className="flex flex-row py-2 items-center">
        <div
          className="h-8 w-8 rounded-full bg-no-repeat bg-cover bg-center"
          style={{backgroundImage: "url(/not-an-image.jpg)"}}
        />
        <div className="ml-3">
          <h6 className="text-xl font-semibold">Review In Question</h6>
          <p className="text-xs">Kenya</p>
        </div>
      </div>
      {/** End of Review container Header */}

      {/** Review comment */}
      <p className="text-sm ml-4 border-l-2 border-black px-2">
        {review.comment}
      </p>
      {/** End of Review comment */}

      {/** Review Score */}
      <div
        className={
          "py-3 px-2 ml-4 mt-2 flex flex-row items-center justify-between " +
          "border-t border-b"
        }
      >
        <p>Overall Rating</p>
        <p className="text-xl font-semibold">{review.rating}</p>
      </div>
      {/** End of Review Score */}
      {!review.reply && (
        <div className="ml-8 border mt-2 p-4">
          {/** Hotel Details Header */}
          <div className="flex flex-row items-center">
            <div
              className="h-8 w-8 rounded-full bg-cover bg-no-repeat bg-center"
              style={{backgroundImage: "url(/not-an-image.jpg)"}}
            />
            <div className="ml-3">
              <h6 className="font-semibold">{propertyTitle}</h6>
              <p className="text-xs">{propertyLocation}</p>
            </div>
          </div>
          {/** End of Hotel Details Header */}

          {/** Input Reply comment */}
          <div className="ml-4 mt-2 border-l-2 border-black">
            <TextArea
              disabled={isSubmitted || isSubmitting}
              className="ml-2"
              helper="Leave a reply for the user"
              id="review-reply"
              inputLabel="Reply"
              name="reply"
              value={reply}
              onChange={(e) => {
                setReply(e.target.value);
              }}
            />
          </div>
          {/** End of input reply comment */}

          {/** Reply Button */}
          <div className="flex flex-row items-center justify-end">
            <button
              disabled={reply === "" || isSubmitted || isSubmitting}
              className={
                "py-2 px-3 border border-red-500 rounded-md shadow-md " +
                "text-red-500 font-semibold text-sm " +
                "flex flex-row items-center"
              }
              type="button"
              onClick={() => {
                setIsSubmitting(true);

                updateReviewReply(
                  propertyCategory, propertyId, idx, reply,
                  (err, ownerReply) => {
                    setIsSubmitting(false);

                    if (err) {
                      console.log(err);
                      return;
                    }

                    if (ownerReply) {
                      setIsSubmitted(true);
                    }
                  },
                );
              }}
            >
              <FaPaperPlane size={24} />
              <p className="text-sm font-semibold ml-3">
                Reply
              </p>
            </button>
          </div>
          {/** Reply Button */}
        </div>
      )}
      {review.reply && (
        <div className="ml-8 border mt-2 p-4">
          <div className="flex flex-row items-center">
            <div
              className="h-8 w-8 rounded-full bg-cover bg-no-repeat bg-center"
              style={{backgroundImage: "url(/not-an-image.jpg)"}}
            />
            <div className="ml-3">
              <h6 className="font-semibold">{propertyTitle}</h6>
              <p className="text-xs">{propertyLocation}</p>
            </div>
          </div>
          {/** End of Hotel Details Header */}

          {/** Reply comment */}
          <p className="ml-4 mt-2 px-2 border-l-2 border-black">
            {review.reply}
          </p>
          {/** End of reply comment */}
        </div>
      )}
    </div>
  );
};
