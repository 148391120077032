type AuthLevel = "user" | "affiliate" | "partner" | "admin";

const SET_USER_FLAG_MASK = 1; // 0001
const SET_AFFILIATE_FLAG_MASK = 2; // 0010
const SET_PARTNER_FLAG_MASK = 4; // 0100
const SET_ADMIN_FLAG_MASK = 8; // 1000

const UNSET_USER_FLAG_MASK = 14; // 1110
const UNSET_AFFILIATE_FLAG_MASK = 13; // 1101
const UNSET_PARTNER_FLAG_MASK = 11; // 1011
const UNSET_ADMIN_FLAG_MASK = 7; // 0111

const setAuthFlagMask: {[Auth in AuthLevel]: number} = {
  admin: SET_ADMIN_FLAG_MASK,
  affiliate: SET_AFFILIATE_FLAG_MASK,
  partner: SET_PARTNER_FLAG_MASK,
  user: SET_USER_FLAG_MASK,
};

const unsetAuthFlagMask: {[Auth in AuthLevel]: number} = {
  admin: UNSET_ADMIN_FLAG_MASK,
  affiliate: UNSET_AFFILIATE_FLAG_MASK,
  partner: UNSET_PARTNER_FLAG_MASK,
  user: UNSET_USER_FLAG_MASK,
};

/**
 * This turns on the authLevel of a user given.
 *
 * @param previousAuth This is the previous user authLevel
 * @param authLevel This is the authFlag that will be turned on
 * @returns Returns the new user authLevels with the `authLevel` turned on.
 */
export const setAuthFlag = (
  previousAuth: number, authLevel: AuthLevel,
): number => {
  const newAuth = setAuthFlagMask[authLevel] | previousAuth;

  return newAuth;
};

/**
 * This turns off the authLevel privilleges specified.
 *
 * @param previousAuth This is the previous user authLevel
 * @param authLevel This is the authFlag that will be turned off
 * @returns The new user auth with the specifieed authLevel privilleges
 * removed.
 */
export const removeAuthFlag = (
  previousAuth: number, authLevel: AuthLevel,
): number => {
  const newAuth = unsetAuthFlagMask[authLevel] & previousAuth;

  return newAuth;
};

/**
 * Checks is a user has given authLevel privilleges given their privilleges
 * and the authLevel to be checked.
 *
 * @param permissions This is the privilleges assigned to the user
 * @param authLevel This is the authLevel that is being hecked for
 * @returns a boolean value that dictates whether the user has the given
 * privilleges.
 */
export const hasAuthLevel = (
  permissions: number, authLevel: AuthLevel,
): boolean => {
  const permissionsMask = permissions & setAuthFlagMask[authLevel];

  return permissionsMask === setAuthFlagMask[authLevel];
};
