import {FC} from 'react';
import { ReservationRow } from '..';
import {PopulatedRentalReservation} from '../../../../../api/reservation/types';
import { DateUtil } from '../../../../../util';

type RentalReservationRowsProps = {
  reservation: PopulatedRentalReservation;
};

export const RentalReservationRows: FC<RentalReservationRowsProps> = ({
  reservation,
}) => {
  return (
    <>
      <ReservationRow
        title="Checkin"
        description={
          DateUtil.changeDateFormatFromDate(
            new Date(reservation.checkin), "DD-MM-YYYY",
          )
        }
      />

      <ReservationRow
        title="Checkout"
        description={
          DateUtil.changeDateFormatFromDate(
            new Date(reservation.checkout), "DD-MM-YYYY",
          )
        }
      />

      <ReservationRow
        title="Adults"
        description={`${reservation.adults}`}
      />

      <ReservationRow
        title="Children"
        description={`${reservation.children}`}
      />
    </>
  );
};
