// @ts-check
import fetchActivities from "./fetch-activities";
import fetchCars from "./fetch-cars";
import fetchCruises from "./fetch-cruises";
import fetchFlights from "./fetch-flights";
import fetchRentals from "./fetch-rentals";
import fetchRestaurants from "./fetch-restaurants";
import fetchSearchCategory from "./fetch-search-category";
import fetchTours from "./fetch-tours";
import fetchVenues from "./fetch-venues";
import fetchHotels from "./fetchHotels";

////////////////////////////////////////////////////////////////////////////////
// JSDoc Types
////////////////////////////////////////////////////////////////////////////////
/**
 * @typedef {import('../../../../reducers/search').SearchState} SearchState
 */

/**
 * This helps fetch the results as based on the category and the search
 * parameters that will be contained in the url passed to the function.
 *
 * @param {URL} url search route reducer state.
 * @param {string} category this is the category results are being fetched for.
 */
export const fetchResults = (url, category, updateState) => {
  console.log(`Fetching results for: ${category}`);
  const searchCategory = fetchSearchCategory();

  switch (searchCategory) {
    case "conferencing":
      fetchVenues(url, updateState);
      break;
    case "hotel":
      fetchHotels(url, updateState);
      break;
    case "flight":
      fetchFlights(url, updateState);
      break;
    case "rental":
      fetchRentals(url, updateState);
      break;
    case "cruises":
      fetchCruises(url, updateState);
      break;
    case "eateries":
      fetchRestaurants(url, updateState);
      break;
    case "cars":
      fetchCars(url, updateState);
      break;
    case "tours":
      fetchTours(url, updateState);
      break;
    case "activities":
      fetchActivities(url, updateState);
      break;
    default:
      console.error("Unidentified category searched for");
      break;
  }
}
