import type {FC} from 'react';

import {Hotel} from './hotel';
import {Flight} from './flight';
import {Rental} from './rental';
import {Cruises} from './cruises';
import {Eateries} from './eateries';
import {Conferencing} from './conferencing';
import {Cars} from './cars';
import {Tours} from './tours';
import {Activities} from './activities';

type NewSearchProps = {
  setRawResultsStale?: (rawResultsStale: boolean) => void;
};

export const NewSearch: FC<NewSearchProps> = ({setRawResultsStale}) => {
  const url = new URL(location.href);
  const [, category] = url.pathname
    .split("/")
    .filter((category) => category !== "");

  switch (category) {
    case "hotel": 
      return (
        <Hotel setRawResultsStale={setRawResultsStale} />
      );
    case "flight":
      return (
        <Flight />
      );
    case "rental":
      return (
        <Rental setRawResultsStale={setRawResultsStale} />
      )
    case "cruises":
      return (
        <Cruises />
      );
    case "cruise":
      return (
        <Cruises />
      );
    case "eateries":
      return (
        <Eateries setRawResultsStale={setRawResultsStale} />
      );
    case "eatery":
      return (
        <Eateries setRawResultsStale={setRawResultsStale} />
      );
    case "conferencing":
      return (
        <Conferencing setRawResultsStale={setRawResultsStale} />
      );
    case "conference":
      return (
        <Conferencing setRawResultsStale={setRawResultsStale} />
      );
    case "cars":
      return (
        <Cars setRawResultsStale={setRawResultsStale} />
      );
    case "car":
      return (
        <Cars setRawResultsStale={setRawResultsStale} />
      );
    case "tours":
      return (
        <Tours setRawResultsStale={setRawResultsStale} />
      );
    case "tour":
      return (
        <Tours setRawResultsStale={setRawResultsStale} />
      );
    case "activities":
      return (
        <Activities setRawResultsStale={setRawResultsStale} />
      );
    case "activity":
      return (
        <Activities setRawResultsStale={setRawResultsStale} />
      );
    default:
      return (
        <div></div>
      )
  }
}
