import {FaSearch} from "react-icons/fa";

import {MobileFilterContext} from "../mobile-filter-context";

export function NewSearchFab({searchDone}) {
  return (
    <MobileFilterContext.Consumer>
      {({newSearchFilter, setNewSearchFilterVisible, setVisibleFilter}) => (
        <>
          {!newSearchFilter && searchDone && (
            <div
              className={
                "fixed bottom-4 right-4 h-10 rounded-full " +
                "flex flex-row items-center content-center justify-center " +
                "text-white px-5 bg-gradient-to-tr from-start to-end" +
                " lg:hidden transition-display duration-300"
              }
              onClick={() => {
                window.scrollTo({left: 0, top: 0, behavior: "smooth"});
                setNewSearchFilterVisible(true);
                setVisibleFilter("none");
              }}
            >
              <FaSearch className="mr-2" />
              New Search
            </div>
          )}
        </>
      )}
    </MobileFilterContext.Consumer>
  );
}
