import {FC, useContext, useEffect, useState} from 'react';
import type {RouteComponentProps} from 'react-router-dom';
import Lottie from 'react-lottie';
import {DatePicker, InputNumber, TimePicker} from 'antd';

import animation from '../../../../assets/anim/loading-eatery.json';
import {Input} from '../../../../components/input';
import {Eatery, fetchAvailableEateryTables} from '../../../../api';
import {fetchCompulsoryEateriesFilters} from '../../../../reducers';

import Container, {
  CheckoutSection, SubPropertySection,
} from '../../components/container';
import {Card} from '../../../../components/card/property-card';
import {ProceedToCheckout} from '../../components/proceed-to-checkout';
import {EateryReservation} from '../../../../api/reservation/types';
import {DateUtil} from '../../../../util';
import { TextInput } from '../../../../components/input/text-input';
import { Button } from '../../../../components';
import { OverlayProvider } from '../../../search-property/context';
import ActivityIndicator from '../../../../components/loading';

type EateryProps = RouteComponentProps<{id: string}>;

/**
 * Displays an eatery with regard to the menu contained in the eatery and then
 * provides a checkout section that allows the user to detail what they would
 * like to order at that particular day based on the menu provided on the
 * propery screen.
 *
 * @param props eatery props
 * @returns Eatery screen.
 */
export const EateryProperty: FC<EateryProps> = ({match}) => {
  const {
    setIsCancellable, setIsOverlay, setOnOverlay,
  } = useContext(OverlayProvider);
  // const [activeSubProperty, setActiveSubProperty] = useState(-1);
  const [eatery, setEatery] = useState<Eatery | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorOccured, setErrorOccured] = useState<boolean>(false);

  // get the colpulsory filters that the user used to make the search
  const {
    adults, date: reservationDate, children, place,
  } = fetchCompulsoryEateriesFilters();
  const numberOfAdults = adults ? adults : 1;
  const numberOfChildren = children ? children : 0;

  // input control
  const [time, setTime] = useState({
    hours: 18,
    minutes: 0,
    seconds: 0,
  });
  const [numberOfTables , setNumberOfTables] = useState<number>(1);
  const [date, setDate] = useState<Date>(
    reservationDate ?
      new Date(
        DateUtil.changeDateFormat(reservationDate, "DD-MM-YYYY", "MM-DD-YYYY"),
      ) :
      new Date(),
  );
  const [people, setPeople] = useState<number>(numberOfAdults + numberOfChildren);

  useEffect(() => {
    const checkinDate = reservationDate ?
      new Date(
        DateUtil.changeDateFormat(reservationDate, "DD-MM-YYYY", "MM-DD-YYYY"),
      ) :
      new Date();
    checkinDate.setHours(time.hours, time.minutes, time.seconds, 0);
    // fetch the Hotels and after fetching, carry out the on success function
    fetchAvailableEateryTables(
      match.params.id,
      checkinDate.getTime(),
      (err, data) => {
        setIsLoading(false);

        if (err) {
          setErrorOccured(true);
          return;
        }

        // handle the hotel data that has been retrieved by the system
        setEatery(data);
      }
    );
  }, [match.params.id, time, reservationDate]);

  const calculatePrice = (): number => {
    return 0;
  };

  const onProceedToCheckout = () => {
    // creates the reservation card and then proceeds to checkout
    const reservation: EateryReservation = {
      amount_received: 0,
      category: "hotel",
      checkin: new Date(reservationDate + " " + time).getTime(),
      due_date: new Date(reservationDate + " " + time).getTime(),
      expected_amount: calculatePrice(),
      property_id: eatery?.id ? eatery.id : "",
      reservation_date: Date.now(),
      reservation_type: eatery ? eatery.reservation_policy: "full_amount",
      status: "pending",
      number_of_tables: numberOfTables,
      people: numberOfAdults + numberOfChildren,
    };

    return reservation;
  };

  return (
    <>
      {isLoading && (
        <div className="w-full h-96 flex flex-col items-center justify-center">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animation,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            style={{}}
            height={200}
            width={200}
          />
          <p className="text-base font-semibold">Fetching Hotel Details</p>
        </div>
      )}
      {!isLoading && !errorOccured && eatery && (
        <Container
          property={eatery}
          showDirections={true}
          renderSearchSection={() => {
            return (
              <>
                <TextInput
                  disabled={true}
                  value={eatery.title}
                  helper="Name of eatery"
                  id="eatery-name"
                  inputLabel="Eatery"
                  name="eatery_name"
                  onChange={(e) => console.log("Should not change")}
                  className="mb-2"
                />
                <p className="text-xs mb-1">
                  {"choose time you'd like to reserve at"}
                </p>
                <DatePicker
                  disabledDate={(date) => {
                    const timestamp = Number(date.format("x"));
                    
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);

                    return today.getTime() > timestamp;
                  }}
                  onChange={(val) => {
                    if (val) {
                      setDate(val.toDate());
                    }
                  }}
                />
                <TimePicker
                  onChange={(val) => {
                    if (val) {
                      setTime({
                        hours: val.minutes(),
                        minutes: val.minutes(),
                        seconds: val.seconds(),
                      });
                    }
                  }}
                />
                <p className="text-xs mb-1">Number of people</p>
                <InputNumber
                  className="mb-2 w-full"
                  defaultValue={people}
                  value={people}
                  onChange={(e) => {
                    setPeople(e);
                  }}
                />
                <div className="flex flex-row justify-center">
                  <Button
                    disabled={
                      DateUtil.changeDateFormatFromDate(date, "DD-MM-YYYY") ===
                        reservationDate
                    }
                    type="button"
                    onClick={() => {
                      setIsCancellable && setIsCancellable(false);
                      setOnOverlay && setOnOverlay(() => () => (
                        <ActivityIndicator
                          caption=""
                        />
                      ));
                      setIsOverlay && setIsOverlay(true);

                      fetchAvailableEateryTables(
                        match.params.id,
                        date.getTime(),
                        (err, eatery) => {
                          setIsCancellable && setIsCancellable(true);
                          setIsOverlay && setIsOverlay(false);
                          setOnOverlay && setOnOverlay(null);
                          if (err) {
                            console.error(err);

                            return;
                          }

                          setEatery(eatery);
                        },
                      )
                    }}
                  >
                    Check Availability
                  </Button>
                </div>
              </>
            );
          }}
        >
          <SubPropertySection title="Menu Items">
            {eatery.menu.map((item, idx) => (
              <Card
                key={`menu_item_${idx}`}
                cover={item.picture.url}
                description={item.description}
                title={item.title}
                subtitle={`${item.price}`}
              />
            ))}
          </SubPropertySection>
          <CheckoutSection>
            <ProceedToCheckout
              onProceedToCheckout={onProceedToCheckout}
              price={calculatePrice()}
            >
              <Input
                disabled={true}
                error=""
                inputLabel="Eatery"
                name="eatery"
                onChange={() => console.log("This shouldn't happen")}
                value={eatery ? eatery.title: ""}
                id="eatery-name"
                helper="Name of the eatery/restaurant"
              />
              <Input
                disabled={true}
                error=""
                inputLabel="Location"
                name="place"
                value={place ? place: ""}
                onChange={() => console.log("This shouldn't happen")}
                helper="location of the eatery"
                id="eatery-location"
              />
              <Input
                disabled={true}
                error=""
                inputLabel="Date"
                name="date"
                onChange={() => console.log("Should not happen")}
                value={reservationDate ? reservationDate: ""}
                helper="Chosen date"
                id="eatery-date"
              />
              <Input
                disabled={true}
                helper="Reservation time"
                id="reservation-time"
                inputLabel="Reservation time"
                name="reservation_time"
                onChange={(e) => console.log("Should not be happening")}
                // TODO Shoddy work, refacto to a cleaner solution instead of
                // using an Immediately Invoked Function (harder to read)
                value={DateUtil.getHumanReadableFormat((() => {
                  const checkinDate = new Date(date);
                  checkinDate.setHours(
                    time.hours, time.minutes, time.seconds, 0,
                  );

                  return checkinDate;
                })())}
              />
              <Input
                disabled={true}
                error=""
                inputLabel="People"
                name="adults"
                onChange={() => console.log("Should not happen")}
                value={`${people}`}
                helper="Number of people"
                id="adult-number"
              />
              <Input
                error={
                  numberOfTables < 1 ?
                    "Number cannot be less than 1" :
                    ""
                }
                helper="Number of tables youd like to reserve"
                id="no-of-tables"
                inputLabel="Number of tables"
                name="no-of-tables"
                value={`${numberOfTables}`}
                onChange={(e) => {
                  setNumberOfTables(Number(e.target.value));
                }}
              />
            </ProceedToCheckout>
          </CheckoutSection>
        </Container>
      )}
      {!isLoading && !errorOccured && !eatery && (
        <></>
      )}
      {!isLoading && errorOccured && (
        <></>
      )}
    </>
  );
};
