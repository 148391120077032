import {createRef, FC, useContext, useEffect, useState} from 'react';
import {Link, RouteComponentProps} from 'react-router-dom';

import {UserProvider} from '../../context';
import {AppStateContext} from '../../context/app-state-provider';
import {DateUtil} from '../../util';
import {Header} from '../../components';

import {DraftedReservationSheet} from './components/drafted-reservations-sheet';
import {CategoryTabs} from './components/Tabs';
import {RecommendedTab} from './components/recommended-tab';
import {AppFooter} from './components/app-footer';
import {RecommendedCities} from './components/recommended-cities';
// import {BrowseByProperty} from './components/browseby-property';

const TODAY = new Date();

TODAY.setDate(TODAY.getDate() + 1);
const TOMORROW = new Date(TODAY);

// TODAY.setDate(TODAY.getDate() + 1);
// const DAY_AFTER = new Date();


const App: FC<RouteComponentProps> = () => {
  const {user} = useContext(UserProvider);
  const {state} = useContext(AppStateContext);

  const tabContainerRef = createRef<HTMLDivElement>();

  const [reminderMounted, setReminderMounted] = useState<boolean>(false);
  const [lightHeaderVisible, setLightHeaderVisible] = useState<boolean>(false);

  // useEffect(() => {
    window.onscroll = () => {
      if (tabContainerRef.current) {
        const {clientHeight, clientTop} = tabContainerRef.current;
        const tabEnd = clientTop + clientHeight;
        if (window.scrollY > tabEnd) {
          !lightHeaderVisible && setLightHeaderVisible(true);
        } else {
          lightHeaderVisible && setLightHeaderVisible(false);
        }
      }
    };

  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className="top-section flex-div"
    >
      <div className="fixed top-0 w-full z-30">
        <Header.DarkHeader visible={lightHeaderVisible} />
      </div>
      {user && !reminderMounted && !state.REMINDER_SHOWN && (
        <DraftedReservationSheet
          setReminderMounted={setReminderMounted}
        />
      )}
      {/* TobBar image starts here */}
      <div className="top-background safari-grad" />
      <div className="top-background-texture" />
      <div className="top-background-image" />
      <div className="backdrop" />
      {/* TopBarImage ends her */}
      <div className="triangle-mask-parent">
        <div className="left-triangle-mask"> </div>
        <div className="right-triangle-mask"> </div>
      </div>
      <div className="flex-div header" />
      {/* Contains the header and the quick options tabs */}
      <div className="flex-div header-content" style={{ color: "#000" }}>
        <Header.TransparentHeader />
        <div
          className="flex-div display-port flex-row"
        >
          <CategoryTabs tabContainerRef={tabContainerRef}/>
        </div>
      </div>
      <div className="flex-div flex-column-mobile section-2">
        <h1
          className="text-3xl font-lato font-semibold"
          style={{ textAlign: "center", marginBottom: 16 }}
        >
          {"What's Hot"}
        </h1>
        <div className="display-port">
          {/* What's Hot tab*/}
          <div style={{ display: "flex" }} className="flex-column-mobile">
            {/* Trip Planner card */}
            <div style={{ flex: 1 }}>
              <Link to="/trip-planner">
                <div
                  className="ad-image2 lg:mr-2"
                  style={{ height: 200, padding: 5 }}
                >
                  <div
                    style={{
                      color: "white",
                      fontSize: 30,
                      margin: 10,
                      marginTop: 40,
                      fontWeight: 700,
                    }}
                  >
                    Trip Planner
                  </div>
                  <div
                    style={{
                      color: "white",
                      fontSize: 13,
                      margin: 10,
                      fontWeight: 700,
                      width: 175,
                    }}
                  >
                    Plan your trips just in a few clicks and we will do the
                    heavy lifting for you
                  </div>
                </div>
              </Link>
            </div>
            {/* Conferencing card */}
            <div className="ad" style={{ flex: 1 }}>
              {/* Why is this hardcoded?? */}
              <Link
                // eslint-disable-next-line max-len
                to={
                  `/search-property/conference?place=Nairobi&lat=-1.2920659&lng=36.8219462&date=${
                    DateUtil.changeDateFormatFromDate(TOMORROW, "DD-MM-YYYY")
                  }&adults=1&children=0`}
              >
                <div
                  className="ad-image3 lg:mr-2"
                  style={{ height: 200, padding: 5 }}
                >
                  <div
                    style={{
                      color: "white",
                      fontSize: 30,
                      margin: 10,
                      marginTop: 40,
                      fontWeight: 700,
                    }}
                  >
                    Conferencing
                  </div>
                  <div
                    style={{
                      color: "white",
                      fontSize: 13,
                      margin: 10,
                      fontWeight: 700,
                      width: 200,
                    }}
                  >
                    Get affordable conferencing services for your next gig.
                  </div>
                </div>
              </Link>
            </div>
            {/* Eateries card */}
            <div className="ad" style={{ flex: 1 }}>
              <Link
                // eslint-disable-next-line max-len
                to={
                  `/search-attraction?place=Nairobi&lat=-1.2920659&lng=36.8219462&date=${
                    DateUtil.changeDateFormatFromDate(TOMORROW, "DD-MM-YYYY")
                  }&adults=1&children=0`
                }
              >
                <div
                  className="ad-image1"
                  style={{ height: 200, padding: 5 }}
                >
                  <div
                    className="font-lato font-extrabold"
                    style={{
                      color: "#121212",
                      fontSize: 30,
                      margin: 10,
                      marginTop: 40,
                      // fontWeight: 700,
                    }}
                  >
                    Attractions
                  </div>
                  <div
                    style={{
                      color: "#121212",
                      fontSize: 13,
                      margin: 10,
                      fontWeight: 700,
                      width: 190,
                    }}
                  >
                    Discover attraction near you.
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <RecommendedCities />
        {/* <BrowseByProperty /> */}
        <RecommendedTab />
        <AppFooter origin="" />
      </div>
    </div>
  );
};
export default App;
