import {List} from 'antd';
import {FC, useContext, useEffect, useState} from 'react';
import {IoMdAdd} from 'react-icons/io';
import draftToHTML from 'draftjs-to-html';

import {fetchInfo, Info} from '../../../api';
import {OverlayProvider} from '../../../context';

import {NewRouteOverlay} from '../components';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { BiPencil } from 'react-icons/bi';
import { Link } from 'react-router-dom';

export const About: FC = () => {
  const {setIsOverlay, setOnOverlay} = useContext(OverlayProvider);

  const [fetching, setIsFetching] = useState<boolean>(true);

  const [infoRoutes, setInfoRoutes] = useState<Info[]>([]);
  const [active, setActive] = useState<number>(0);

  useEffect(() => {
    fetchInfo((err, results) => {
      if (err) {
        console.log(err);

        return;
      }

      if (results) {
        setInfoRoutes(results);
        setIsFetching(false);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="flex flex-row h-full w-full">
      <div className="w-80 h-full border-r overflow-y-auto">
        {/* Render all the sections in collection /info */}
        <List
          loading={fetching}
          dataSource={infoRoutes}
          bordered
          header={
            <h4 className="text-2xl font-lato font-semibold">
              Created footer routes
            </h4>
          }
          renderItem={(item, idx) => (
            <div className="w-full my-3 cursor-pointer hover:bg-yellow-200 relative">
              <List.Item
                className="w-full"
                onClick={() => {
                  setActive(idx);
                }}
              >
                {item.title}
              </List.Item>
            </div>
          )}
        />
      </div>

      {infoRoutes.length > 0 && (
        <div className="w-full flex-1">
          <div className="w-full h-14 flex flex-row justify-between items-center px-4 border-b shadow-lg">
            <h6 className="text-xl font-semibold">
              {`${infoRoutes[active].title} preview`}
            </h6>
            <Link to={`/su/about/edit/${infoRoutes[active].id}`}>
              <button className="flex flex-row items-center h-9 px-4 rounded-lg hover:shadow-md hover:border">
                <BiPencil size={24} className="mr-3" />
                Edit Route Details
              </button>
            </Link>
          </div>
          <div className="flex-1 p-4 mx-auto max-w-5xl" dangerouslySetInnerHTML={{
            __html: draftToHTML(infoRoutes[active].content)
          }}>
          </div>
        </div>
      )}

      {/* Floating action Button*/}
      <div
        className={
          "h-14 px-4 shadow-md absolute bottom-4 right-4 bg-secondary " +
          "flex flex-row items-center text-white rounded-full font-semibold " +
          "font-lato cursor-pointer"
        }
        onClick={() => {
          setOnOverlay && setOnOverlay(() => () => (
            <NewRouteOverlay
              removeOverlay={() => {
                setIsOverlay && setIsOverlay(false);
                setOnOverlay(null);
              }}
            />
          ));

          setIsOverlay && setIsOverlay(true);
        }}
      >
        <IoMdAdd className="text-2xl mr-3" />
        Create new footer section
      </div>
    </div>
  );
};
