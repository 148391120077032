////////////////////////////////////////////////////////////////////////////////
// JSDoc Types
////////////////////////////////////////////////////////////////////////////////
/**
 * @typedef {{
 * place: string,
 * lat: number,
 * lng: number,
 * checkin: string | null,
 * checkout: string | null,
 * adults: number | null,
 * children: number | null,
 * filter: ?string[],
 * }} CompulsoryRentalFilters
 *
 * @typedef {{
 * price_lowest: ?number,
 * price_highest: ?number,
 * star_rating: ?number,
 * feature: ?string[],
 * theme: ?string,
 * }} OptionalRentalFilters
 *
 * @typedef {{
 * compulsoryFilters: CompulsoryRentalFilters,
 * optionalFilters: OptionalRentalFilters,
 * }} SearchState
 *
 * @typedef {{
 * type: "UPDATE_All_FILTERS" | "UPDATE_OPTIONAL_FILTERS" |
 * "UPDATE_COMPULSORY_FILTERS",
 * value: any,
 * }} SearchStoreAction
 */

/**
 * This function fetches all the compulsory Filters from the current
 * `window.location` Url.
 * 
 * @returns {CompulsoryRentalFilters}
 */
export const fetchCompulsoryRentalFilters = () => {
  const url = new URL(location.href);
  /**@type {CompulsoryRentalFilters} */
  const compulsoryFilters = {};

  const [loc, ] = url.searchParams.get("place").split(",");
  compulsoryFilters.place = loc;
  
  if (url.searchParams.has("adults")) {
    compulsoryFilters.adults = Number(url.searchParams.get("adults"));
  } else {
    compulsoryFilters.adults = 1;
  }

  if (url.searchParams.has("children")) {
    compulsoryFilters.children = Number(url.searchParams.get("children"));
  } else {
    compulsoryFilters.children = 0;
  }

  compulsoryFilters.checkin = url.searchParams.get("checkin");
  compulsoryFilters.checkout = url.searchParams.get("checkout");
  if (url.searchParams.has("lat")) {
    compulsoryFilters.lat = Number(url.searchParams.get("lat"));
    compulsoryFilters.lng = Number(url.searchParams.get("lng"));
  }
  compulsoryFilters.filter = url.searchParams.getAll("filter");

  return compulsoryFilters;
};

/**
 * This function fetches all the optional features encoded in the URL.
 *
 * @returns {OptionalRentalFilters} the optional filters that have been captured.
 */
export const fetchOptionalRentalFilters = () => {
  const url = new URL(location.href);

  /**@type {OptionalRentalFilters} */
  const optionalFilters = {};

  optionalFilters.feature = url.searchParams.getAll("feature");
  if (url.searchParams.has("price_highest")) {
    optionalFilters.price_highest = Number(url.searchParams.get("price_highest"));
  }

  if (url.searchParams.has("price_lowest")) {
    optionalFilters.price_lowest = Number(url.searchParams.get("price_lowest"));
  }

  optionalFilters.star_rating = Number(url.searchParams.get("star_rating"));
  optionalFilters.theme = url.searchParams.get("theme");

  return optionalFilters;
};
