import {FC, useState} from 'react';
import {ImCancelCircle} from 'react-icons/im';
import { useHistory } from 'react-router';
import { markNotificationAsRead } from '../api';
import { Notification } from '../api/notification/types';
import { DateUtil } from '../util';

type NotificationCardProps = {
  notification: Notification;
  onMarkAsRead: () => void;
};

export const NotificationCard: FC<NotificationCardProps> = ({
  notification, onMarkAsRead,
}) => {
  const [read, setRead] = useState<boolean>(notification.read);
  const [loading, setLoading] = useState<boolean>(false);

  const history = useHistory();

  return (
    <div className="my-2 p-2 border border-safari-gold bg-yellow-100">
      <div className="flex flex-row justify-between mb-2 text-safari-gold">
        <p className="font-lato font-bold">
          {notification.title}
        </p>
        <ImCancelCircle
          size={18}
          className="text-safari-gold"
        />
      </div>
      <p className="text-sm opacity-70 font-medium mb-3">
        {DateUtil.getHumanReadableFormat(new Date())}
      </p>
      <p className="font-merriweather">
        {notification.message}
      </p>

      <div className="flex flex-row justify-end">
        <button
          className="font-semibold font-lato text-error px-3 py-1 cursor-pointer mx-2"
          disabled={read || loading}
          onClick={() => {
            setLoading(true);

            markNotificationAsRead(notification.id).then((isRead) => {
              setRead(isRead);
              setLoading(false);
              isRead && onMarkAsRead();
            });
          }}
        >
          Mark as Read
        </button>

        <button
          className="font-semibold font-lato text-secondary px-3 py-1 cursor-pointer"
          disabled={loading}
          onClick={() => {
            setLoading(true);

            markNotificationAsRead(notification.id).then((isRead) => {
              setRead(isRead);
              setLoading(false);
            });

            history.push(notification.actions);
          }}
        >
          View
        </button>
      </div>
    </div>
  );
};
