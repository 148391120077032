// @ts-check
import {TripPlannerCard} from '../../../components/trip-planner-card';
import {cruises, flights} from '../../../dummy-data';

/**
 * @typedef {{
 * id: number,
 * departure: string,
 * return: string,
 * price: number,
 * category: "cars" | "cruises" | "flight",
 * }} TravelItem
 */

/**@type {import('react').FC<{travelList: TravelItem[]}>} */
export const TravelList = ({travelList}) => {
  console.log(travelList);
  return (
    <div>
      {travelList.map((item) => {
        console.log("Flight Items");
        console.log(item);
        if (item.category === "flight") {
          return (
            <TripPlannerCard
              overline={`${item.departure} - ${item.return}`}
              title={
                `Flight from ${
                  flights[item.id].origin
                } to ${
                  flights[item.id].destination
                }`
              }
              action={{
                label: "REMOVE",
                onClick: () => console.log("Removing item"),
              }}
              subtitle={flights[item.id].airline}
              description={"No Stops in Between"}
              price={flights[item.id].price}
              cover="/public/flight.jpg"
            />
          );
        } else if (item.category === "cars") {
          return (
            // <TripPlannerCard
            //   overline={`${item.checkin} - ${item.checkout}`}
            //   title={}
            // />
            null
          );
        } else if (item.category === "cruises") {
          return (
            <TripPlannerCard
              action={{label: "REMOVE", onClick: () => "Removing Cruise"}}
              description={cruises[item.id].description}
              overline={`${item.departure} - ${item.return}`}
              price={cruises[item.id].price}
              subtitle={
                `${cruises[item.id].origin} to ${cruises[item.id].destination}`
              }
              title={cruises[item.id].title}
            />
          );
        }
      })}
    </div>
  );
};
