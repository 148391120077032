import {FC, useContext} from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import {FaTimes} from 'react-icons/fa';

import {FilterContext, OverlayContent} from '../../context';

import {FilterGroup} from '../filter-group';
import {ColumnData} from '../left-column';
import {NewSearch} from '../new-search';

export interface SearchOverlayProps extends OverlayContent {
  columnData: ColumnData;
}

export const SearchOverlay: FC<SearchOverlayProps> = ({
  columnData, removeOverlay,
}) => {
  const searchOverlayRef = useOnclickOutside(() => removeOverlay());

  const {setRawResultsStale} = useContext(FilterContext);

  return (
    <div
      ref={searchOverlayRef}
      className={
        "sm:flex flex-col sm:flex-row bg-white w-full h-screen max-w-sm " +
        "place-self-center sm:max-w-2xl sm:h-3/5 overflow-y-scroll"
      }
    >
      {/* AppBar */}
      <div
        className={
          "sm:hidden h-14 flex flex-row items-center bg-white z-30 sticky " +
          "top-0 px-4 font-semibold text-xl shadow-md"
        }
      >
        <FaTimes size={24} className="mr-8" onClick={removeOverlay} />
        Modify Current Search
      </div>
      {/* Search Column */}
      <div className="flex-grow-0 sm:max-w-xs">
        <NewSearch setRawResultsStale={setRawResultsStale} />
      </div>
      {/* Filter column */}
      <div className="overflow-y-scroll">
        <h3 className="hidden sm:block text-2xl font-bold m-2">
          Search Filters
        </h3>
        <div
          className={
            "flex-grow max-w-md grid sm:grid-cols-2 gap-1 overflow-y-scroll "
          }
        >
          {columnData.map((filterGroup, idx) => (
            <FilterGroup
              key={`search_overlay_fg_${idx}`}
              groupData={filterGroup}
            />
          ))}
        </div>
        <hr />
        <div className="flex justify-center items-center my-2">
          <div
            className={
              "py-2 px-3 shadow-md cursor-pointer bg-secondary text-white " +
              "rounded-sm"
            }
          >
            Apply Filters
          </div>
        </div>
      </div>
    </div>
  );
};
