import {FC} from 'react';
import {FaChevronRight} from 'react-icons/fa';
import {useHistory} from 'react-router';

import {Property, ReservationCategory} from '../../../../api';
import {AttractionSite} from '../../../../api/attraction/types';
import {Rating} from '../../../../components/rating';
import {DateUtil} from '../../../../util';
import {generatePropertySearchUrl} from '../../../../util/generate-property-url';

import {NearbyPropertyRef} from '../../routes/map/nearby-properties';

export interface MapPropertyCardProps {
  attraction: AttractionSite;
  category: ReservationCategory;
  property: Property;
  refs: NearbyPropertyRef;
}

export const MapPropertyCard: FC<MapPropertyCardProps> = ({
  attraction, category, property, refs,
}) => {

  const history = useHistory();

  return (
    <div
      className="h-36 mt-3 p-4 pr-0 border rounded-2xl flex flex-row items-center overflow-hidden">
      <div
        className="bg-gray-200 -my-4 -ml-4 w-36 h-36 rounded-2xl rounded-tr-none rounded-br-none self-stretch mr-4 bg-center bg-no-repeat bg-cover"
        style={{
          backgroundImage: `url(${property?.gallery[0].url})`
        }}
      />
      {/* Content container */}
      <div
        className="flex-1 h-full flex flex-col justify-between"
        onMouseEnter={() => {
          refs.marker.current?.toggleFocus(true);
        }}
        onMouseLeave={() => {
          refs.marker.current?.toggleFocus(false);
        }}
      >
        <div>
          <h6
            className="text-xl font-semibold"
            style={{fontFamily: "Lato"}}
          >
            {property?.title}
          </h6>
          <p
            className="text-sm opacity-70"
            style={{fontFamily: "Lato"}}
          >
            2KM from National Park
          </p>
          <div className="flex flex-row items-center">
            <Rating rating={5} />
            <p className="ml-2">(24)</p>
          </div>
        </div>
        <h6
          className="text-xl font-bold opacity-70 justify-self-end"
          style={{fontFamily: "Lato"}}
        >
          $ 299 / night
        </h6>
      </div>
      {/* End of content container */}
      <div
        className={
          "flex-grow-0 flex-shrink-0 bg-safari-gold text-white " +
          "cursor-pointer self-stretch -my-4 flex flex-row justify-center " +
          "px-2"
        }
      >
        <FaChevronRight
          className="self-center justify-self-end"
          size={24}
          onClick={() => {
            const destinationUrl = generateViewPropertyUrl(
              category, `${property.id}`, attraction.name,
            );

            history.push(destinationUrl);
          }}
        />
      </div>
    </div>
  );
};

const generateViewPropertyUrl = (
  category: ReservationCategory, propertyId: string, attractionName: string,
): string => {
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1)
  const dayAfter = new Date();
  tomorrow.setDate(today.getDate() + 2);

  // since we know that there is the date parameter and the adults parameter
  // and the children parameter, we will use that information to get details
  // from the url
  const [pathname] = location.href.split("?");
  console.log(location.pathname);
  const searchParams = new URLSearchParams(location.href.replace(pathname, ""));
  const date = searchParams.get("date");
  const adults = searchParams.get("adults");
  const children = searchParams.get("children");
  const checkout = searchParams.get("checkout");
  // summation of adults and children
  const people =
    0 + ((adults) ? Number(adults) : 1) + ((children) ? Number(children) : 0);

  // set the checkout
  // if the user has interacted with the DatePicker.RangePicker from the search
  // column, there is the checkout parameter set by the search, however if that
  // is not the case, we will need to set a checkout date that is relative to
  // the date parameter (if present), or the dayAfter variable, if the date
  // parameter is null
  const checkoutDate  = checkout ? checkout : DateUtil.stringifyDate(dayAfter);

  let dest = "";

  switch (category) {
    case "activity":
      dest = generatePropertySearchUrl<"activity">(
        "/property",
        "activity",
        `${propertyId}`,
        {
          adults: adults ? Number(adults) : 1,
          date: date ? date : DateUtil.stringifyDate(tomorrow),
          children: children ? Number(children) : 0,
          lat: 0,
          lng: 0,
          place: attractionName,
        }
      );
      break;
  
    case "car":
      dest = generatePropertySearchUrl<"car">(
        "/property",
        "car",
        `${propertyId}`,
        {
          passengers: people,
          date: date ? date : DateUtil.stringifyDate(tomorrow),
          lat: 0,
          lng: 0,
          place: attractionName,
        }
      );
      break;
  
    case "conference":
      dest = generatePropertySearchUrl<"conference">(
        "/property",
        "conference",
        `${propertyId}`,
        {
          people,
          date: date ? date : DateUtil.stringifyDate(tomorrow),
          lat: 0,
          lng: 0,
          place: attractionName,
        }
      );
      break;
  
    case "eatery":
      dest = generatePropertySearchUrl<"eatery">(
        "/property",
        "eatery",
        `${propertyId}`,
        {
          people,
          date: date ? date : DateUtil.stringifyDate(tomorrow),
          lat: 0,
          lng: 0,
          place: attractionName,
        }
      );
      break;
  
    case "hotel":
      dest = generatePropertySearchUrl<"hotel">(
        "/property",
        "hotel",
        `${propertyId}`,
        {
          adults: adults ? Number(adults) : 1,
          checkin: date ? date : DateUtil.stringifyDate(tomorrow),
          checkout: checkoutDate,
          children: 0,
          lat: 0,
          lng: 0,
          place: attractionName,
        }
      );
      break;
  
    case "rental":
      dest = generatePropertySearchUrl<"rental">(
        "/property",
        "rental",
        `${propertyId}`,
        {
          adults: 1,
          checkin: date ? date : DateUtil.stringifyDate(tomorrow),
          checkout: checkoutDate,
          children: 0,
          lat: 0,
          lng: 0,
          place: attractionName,
        }
      );
      break;
  
    case "tour":
      dest = generatePropertySearchUrl<"tour">(
        "/property",
        "tour",
        `${propertyId}`,
        {
          adults: adults ? Number(adults) : 1,
          date: DateUtil.stringifyDate(tomorrow),
          children: children ? Number(children) : 0,
          lat: 0,
          lng: 0,
          place: attractionName,
        }
      );
      break;
  
    default:
      break;
  }

  return dest;
};
