import {Dispatch, FC, SetStateAction, useState} from 'react';
import {
  FaChevronDown, FaChevronUp, FaInfoCircle,
} from 'react-icons/fa';

import {Policy} from '../../../api';
import {Input, TextArea} from '../../../components/input';

type SubPropertyEditPoliciesProps = {
  policies: Policy[];
  setPolicies: Dispatch<SetStateAction<Policy[]>>;
};

export const SubPropertyEditPolicies: FC<SubPropertyEditPoliciesProps> = ({
  policies, setPolicies,
}) => {
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  // minimize or maximize the section
  const [isMaximized, setIsMaximized] = useState<boolean>(false);

  const addPolicy = (newPolicy: Policy) => {
    setPolicies([...policies, newPolicy]);
    setTitle("");
    setDescription("");
  };

  const removePolicy = (removablePolicy: Policy) => {
    let policyIndex = -1;

    for (let i=0; i<policies.length; i++) {
      if (policies[i].title === removablePolicy.title && removablePolicy.description === policies[i].description) {
        policyIndex = i;
        break;
      }
    }

    // to prevent trying to splice otua number that does not exist, still confirm
    // that it is not -1
    if (policyIndex > -1) {
      policies.splice(policyIndex, 1);
      setPolicies(policies);
    }
  };

  return (
      <div
        className={
          `border rounded-md overflow-hidden mt-3 ${
            !isMaximized ? "h-14" : ""
          } transition-all duration-150`
        }
      >
        <div
          className={
            "h-14 flex flex-row items-center justify-between border-b px-4"
          }
          onClick={() => {
            setIsMaximized(!isMaximized)
          }}
        >
          <div>
            <h6 className="text-xl font-semibold">Add room policy</h6>
            <p className="text-xs opacity-70">
              Add unique policies that govern the room
            </p>
          </div>
          {/* Expand or minimize icon to add and remove images */}
          {isMaximized && (
            <FaChevronUp size={24} color="#FF5900" />
          )}
          {!isMaximized && (
            <FaChevronDown size={24} color="#FF5900" />
          )}
          {/* Replace with minimize or maximize icon */}
        </div>
        {/* Section container */}
        <div className="p-4">

            {/* Input container */}
            <div>
              <div>
                <Input
                  helper="Policy name, brief title"
                  id="sub-property-policy-title"
                  inputLabel="Policy title"
                  name="policy-title"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
              </div>
              <div className="mt-3">
                <TextArea
                  helper="Policy description"
                  id="policy-description"
                  inputLabel="Policy description"
                  name="policy-description"
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </div>
              <hr className="my-2" />
              <div className="flex flex-row justify-end">
                <button
                  disabled={title === "" || description === ""}
                  className={
                    `h-9 px-3 border rounded-md shadow-md ${
                      title === ""  || description === "" ?
                        "border-gray-700 text-gray-700 cursor-not-allowed":
                        "border-red-500 text-red-500 "
                    } font-semibold text-sm flex items-center `
                  }
                  onClick={() => {
                    addPolicy({description: [description], title})
                  }}
                >
                  Add new FAQ
                </button>
              </div>
            </div>
            {/* End of input container */}

            {/* All Policies container */}
            <div className="mt-3">
              {/* Policy container */}
              {policies.map((policy) => (
                <div key={`sub_prop_${policy.title}`} className="p-4 border">
                  {/* Policy header */}
                  <div className="flex flex-row items-center overflow-x-hidden">
                    {/* Replace with Info icon */}
                    <FaInfoCircle size={24} />
                    <h6
                      className="ml-3 text-xl font-semibold flex-grow pb-2 border-b-2"
                    >
                      {policy.title}
                    </h6>
                  </div>
                  {/* Policy Description */}
                  <p className="text-sm mt-2 mx-2">
                    {policy.description}
                  </p>
                  {/* End of policy description */}
                  <hr className="my-2" />
                  <div className="flex flex-row justify-end -mb-2">
                    <button
                      className={
                        "h-9 px-3 border border-red-500 rounded-md shadow-md " +
                        "text-red-500 font-semibold text-sm flex items-center"
                      }
                      onClick={() => {
                        removePolicy(policy)
                      }}
                    >
                      Remove Policy
                    </button>
                  </div>
                </div>
              ))}
            </div>
            {/* End of all policies container */}

        </div>
      </div>
  );
};
