// @ts-check
import {TripPlannerCard} from '../../../components/trip-planner-card';
import {tours} from '../../../dummy-data';

/**
 * @typedef {{
 * id: number,
 * checkin: string,
 * category: "activities" | "tours",
 * }} ActivityListItem
 * @typedef {{
 * activityList: ActivityListItem[],
 * }} ActivityListProps
 */

/**@type {import('react').FC<ActivityListProps>} */
export const ActivityList = ({activityList}) => {
  return (
    <div>
      {activityList.map((item, idx) => {
        if (item.category === "activities") {
          return (
            null
          );
        } else if (item.category === "tours") {
          return (
            <TripPlannerCard
              action={{
                label: "REMOVE",
                onClick: () => console.log("Removing Tour Item"),
              }}
              description={tours[item.id].description}
              overline={`${item.checkin}`}
              price={tours[item.id].price}
              subtitle={
                `from ${
                  tours[item.id].origin
                } to ${
                  tours[item.id].destination
                }`
              }
              title={tours[item.id].title}
              key={`tour_activity_list${idx}`}
              cover="/public/tour.jpg"
            />
          );
        }
      })}
    </div>
  );
};
