import {FC, useEffect} from 'react';
import {useHistory} from 'react-router';

import reservationNotFound from '../../assets/reservation-not-found.png';

export const NoReservationFound: FC = () => {
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      history.push("/");
    }, 3000);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="w-full h-full p-4 flex flex-col items-center justify-center">
      <img
        className="w-full h-96 sm:w-96"
        src={reservationNotFound}
        alt="Reservation not found"
      />
      <h6 className="text-2xl font-semibold text-center">
        No reservation found, redirecting to homepage
      </h6>
    </div>
  );
};
