import {firestore} from 'firebase';

import {ApiFunc, Coordinates, OnCompleteCallback, Picture} from '..';
import {uploadPictures} from '../cdn';
import {getUser, NoUserFoundError} from '../user';

import type {Tour} from './types';
export type {Tour} from './types';

export const fetchTour = async (
  id: string, onFetch: (err: Error | null, data: Tour | null) => void,
): Promise<void> => {
  console.log(`Fetching tour with id: ${id}`)

  try {
    const snapshot = await firestore().collection("tour").doc(id).get();
    const tour = {id: snapshot.id, ...snapshot.data()} as Tour;

    onFetch(null, tour);
  } catch (err) {
    const fetchError = new Error("An error occured while fetching tour");
    onFetch(fetchError, null);
  }
};

export const checkTourAvailability = async (
  tourId: string, checkin: number, onComplete: OnCompleteCallback<boolean>,
): Promise<void> => {
  try {
    const response = await fetch(
      `https://us-central1-safarixpertz.cloudfunctions.net/fetch_tours/${
        tourId
      }?checkin=${checkin}`,
      {
        method: "GET",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
      },
    );

    if (response.status === 200) {
      const {isAvailable} = (await response.json() as {isAvailable: boolean});

      onComplete(null, isAvailable);
    } else {
      const fetchError = new Error("Error fetching rentals");

      onComplete(fetchError, null);
    }
  } catch (err) {
    const fetchErr = new Error("An error occured while fetching");
    onComplete(fetchErr, null);
  }
};

export const fetchTours: ApiFunc<Tour[]> = async (onComplete) => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);
    return;
  }

  try {
    const snapshot = await firestore().collection("tour")
      .where("owner", "==", user.uid)
      .get();
    const {docs} = snapshot;

    const tours: Tour[] = [];
    docs.forEach((doc) => {
      tours.push({id: doc.id, ...doc.data()} as Tour);
    });

    onComplete(null, tours);
  } catch (err) {
    const fetchError = new Error("An error occured while fetching tours");
    onComplete(fetchError, null);
  }
};

export const filterTours = async (
  location: string, coords: Coordinates, onComplete: OnCompleteCallback<Tour[]>,
): Promise<void> => {
  try {
    const response = await fetch(
      "https://us-central1-safarixpertz.cloudfunctions.net/fetch_tours",
      {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          location,
          coords,
        }),
      },
    );

    if (response.status === 200) {
      const tours = (await response.json()) as Tour[];

      onComplete(null, tours);
    } else {
      const fetchError = new Error("An error occured while fetching tours");
      onComplete(fetchError, null);
    }
  } catch (error) {
    const fetchError = new Error("An error occured while fetching tours");
    onComplete(fetchError, null);
  }
};

export const postTour = (tour: Tour, onComplete: OnCompleteCallback<Tour>): void => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);

    return;
  }

  // we fetch all the pictures from the tour facility itself, and also all
  // the pictues from the stops themselves.
  // We then create an array (Buffer) that records how many pictures we are
  // uploading for each, this will be used to get back all the pictures in order
  // of so as to re-order them back to their respective places without a hassle.
  //
  // E.g if we have a tour facility with 6 pictures of its own and three
  // tour stops with pictures of their own, then we can create the buffer
  // as follows:
  // buffer = [6, 6, 7, 8];
  // then an array of pictures to upload is created with a total of
  // (6 + 6 + 7 + 8) images, where:
  // they are ordered based on:
  // tour facility first, stops based on the index of the room,
  // As such, these pictures can be gotten back by splicing and then pushing
  // back in order of index starting with the tour facility as the first
  // index (0)

  const bufferSizes: number[] = [];
  const imageBuffer: Picture[] = [];

  bufferSizes.push(tour.gallery.length);
  imageBuffer.push(...tour.gallery);

  // push all the pictures for each room
  tour.stops.forEach((stop) => {
    bufferSizes.push(stop.gallery.length);
    imageBuffer.push(...stop.gallery);
  });


  // after all the images are pushed we can upload them all and then replace
  // after successful upload
  uploadPictures("tour", imageBuffer, async (err, uploadedImages) => {
    if (err) {
      onComplete(err, null);
      return;
    }

    if (uploadedImages) {
      tour.gallery = uploadedImages.splice(0, bufferSizes[0]);

      // starting from the second index, we start assigning the stops all their
      // images back
      for (let i=1; i<bufferSizes.length; i++) {
        tour.stops[i - 1].gallery = uploadedImages.splice(0, bufferSizes[i]);
      }

      // after all the images are uploaded we can now get to uploading the
      // confernce data
      try {
        const doc =  await firestore()
          .collection("tour")
          .add({...tour, owner: user.uid} as Tour);

        onComplete(null, (await doc.get()).data() as Tour);
      } catch (err) {
        const tourUploadError =
          new Error("Error uploading tour facility details");
        onComplete(tourUploadError, null);
      }
      
    } else {
      // these images may be null
      const uploadErr = new Error("The images to be uploaded returned null");
      onComplete(uploadErr, null);
    }
  });
};

export const updateTour = (
  tourId: string, tour: Tour, onComplete: OnCompleteCallback<Tour>,
): void => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);

    return;
  }

  // we fetch all the pictures from the tour facility itself, and also all
  // the pictues from the stops themselves.
  // We then create an array (Buffer) that records how many pictures we are
  // uploading for each, this will be used to get back all the pictures in order
  // of so as to re-order them back to their respective places without a hassle.
  //
  // E.g if we have a tour facility with 6 pictures of its own and three
  // tour stops with pictures of their own, then we can create the buffer
  // as follows:
  // buffer = [6, 6, 7, 8];
  // then an array of pictures to upload is created with a total of
  // (6 + 6 + 7 + 8) images, where:
  // they are ordered based on:
  // tour facility first, stops based on the index of the room,
  // As such, these pictures can be gotten back by splicing and then pushing
  // back in order of index starting with the tour facility as the first
  // index (0)

  const bufferSizes: number[] = [];
  const imageBuffer: Picture[] = [];

  bufferSizes.push(tour.gallery.length);
  imageBuffer.push(...tour.gallery);

  // push all the pictures for each room
  tour.stops.forEach((stop) => {
    bufferSizes.push(stop.gallery.length);
    imageBuffer.push(...stop.gallery);
  });


  // after all the images are pushed we can upload them all and then replace
  // after successful upload
  uploadPictures("tour", imageBuffer, async (err, uploadedImages) => {
    if (err) {
      onComplete(err, null);
      return;
    }

    if (uploadedImages) {
      tour.gallery = uploadedImages.splice(0, bufferSizes[0]);

      // starting from the second index, we start assigning the stops all their
      // images back
      for (let i=1; i<bufferSizes.length; i++) {
        tour.stops[i - 1].gallery = uploadedImages.splice(0, bufferSizes[i]);
      }

      // after all the images are uploaded we can now get to uploading the
      // confernce data
      try {
        await firestore()
          .collection("tour")
          .doc(tourId)
          .update({...tour} as Tour);
        const doc = await firestore()
          .collection("tour")
          .doc(tourId);

        onComplete(null, (await doc.get()).data() as Tour);
      } catch (err) {
        const tourUploadError =
          new Error("Error uploading tour facility details");
        onComplete(tourUploadError, null);
      }
      
    } else {
      // these images may be null
      const uploadErr = new Error("The images to be uploaded returned null");
      onComplete(uploadErr, null);
    }
  });
};
