// @ts-check
import { Link } from 'react-router-dom';
import {
  FaStar,
  FaAngleRight,
} from "react-icons/fa";


/**
 *
 * @param {any} props MapListItemProps
 *
 * @returns {JSX.Element}
 */
const MapListItem = (props) => {
  console.log("item", props);
  const stars = [];
  const notstars = [];
  for (let i = 0; i < props.data.hotel_star; i++) {
    stars.push("");
  }
  for (let i = 0; i < 5 - props.data.hotel_star; i++) {
    notstars.push("");
  }
  //get pic
  let finalPic;
  const picArray = props.data.hotel_picture_gallery;
  console.log(props.data);
  if (picArray) {
    const fin = picArray[0];
    if (fin) {
      finalPic = fin;
    }
  }
  let linkToView = `/view/hotel/${props.data.hotelId}`;
  if (props.origin === "planner") {
    console.log("yeah");
    linkToView =
      `${window.location.href.split("place=")[0] 
      }hotel=${ 
      props.data.hotelId 
      }/${ 
      new URL(location.href).search.replace("?", "")
    }`;
  } else {
    linkToView = `${
      linkToView
    }/${
      new URL(location.href).search.replace("?", "")
    }`;
  }
  return (
    <div
      className={
        "elevate-xs border-0 p-0 rounded-md bg-white overflow-hidden " +
        "flex mr-2 w-56 lg:mb-5 lg:w-full-mx-2 mx-2 shadow-sm"
      }
    >
      <div style={{ display: "flex", flex: 1 }}>
        <div
          style={{
            flex: 1,
            padding: 15,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            className="md:hidden"
            style={{
              fontWeight: "bold",
              fontSize: 15,
              color: "#0a7fb5",
              fontFamily: "Roboto",
            }}
          >
            {props.data.hotel_name.length > 20 ?
              `${props.data.hotel_name.substr(0, 20)}...` :
              props.data.hotel_name
            }
          </div>
          <div
            className="hidden md:block"
            style={{
              fontWeight: "bold",
              fontSize: 15,
              color: "#0a7fb5",
              fontFamily: "Roboto",
            }}
          >
            {props.data.hotel_name.length > 20 ?
              `${props.data.hotel_name.substr(0, 20)}...` :
              props.data.hotel_name
            }
          </div>
          <div style={{ fontWeight: "bold", marginTop: 5, marginBottom: 5 }}>
            {(props.data.hotel_address.length) > 30 ?
              `${props.data.hotel_address.substr(0, 30)}...` :
              props.data.hotel_address
            }
          </div>
          <div>
            <div style={{ display: "flex", marginTop: 5 }}>
              {stars.map(() => {
                return (
                  <div>
                    <FaStar style={{ color: "#ff6000" }} />
                  </div>
                );
              })}
              {notstars.map(() => {
                return (
                  <div>
                    <FaStar />
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <div
              style={{ textAlign: "right", fontSize: 12, marginBottom: 10 }}
            >
              total for {props.days} night(s)
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: 17,
                textAlign: "right",
                fontFamily: "Roboto",
              }}
            >
              {props.data.calcPrice} USD
            </div>
          </div>
          <div style={{ paddingLeft: 5, marginTop: 0 }}>
            <Link to={linkToView}>
              <div
                className="listYourSpaceBt"
                style={{
                  height: 30,
                  fontSize: 12,
                  padding: 5,
                  paddingLeft: 15,
                  paddingRight: 15,
                  borderRadius: 0,
                  display: "flex",
                  fontWeight: "normal",
                }}
              >
                <div>
                  {!props.plannerActive
                    ? "See Availability"
                    : "Add to trip"}{" "}
                </div>
                <div style={{ fontSize: 15, paddingLeft: 3 }}>
                  <FaAngleRight />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MapListItem;
