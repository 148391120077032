import { Rate } from 'antd';
import {FC, useState} from 'react';
import { Button } from '../../../components';

type HotelRatingOverlayProps = {
  onSave: (rating: number) => void;
  removeOverlay: () => void;
};

export const HotelRatingOverlay: FC<HotelRatingOverlayProps> = ({
  onSave, removeOverlay,
}) => {
  const [hotelRating, setHotelRating] = useState<number>(0);

  return (
    <div
      className="h-full w-full rounded-md overflow-hidden sm:w-80 sm:h-auto sm:max-h-dialog shadow-lg bg-white"
    >
      {/* Dialog header */}
      <div className="sticky top-0 h-14 w-full shadow-md bg-safari-gold text-white flex flex-row justify-between items-center px-4">
        <div className="flex flex-row items-center">
          <h6 className="text-base text-white font-semibold">One more thing...</h6>
        </div>
      </div>
      {/* End of dialog header */}
      <h6 className="text-xl font-semibold pl-4 border-b pb-2">
        What is your hotel rating?
      </h6>

      <div className="py-2 flex flex-col items-center">
        <p className="text-xs mb-1">Slide over to set your rating out of 8</p>
        <Rate
          defaultValue={hotelRating}
          count={8}
          onChange={(val) => {
            setHotelRating(val);
          }}
        />
      </div>

      <div className="py-3 mt-1 border-t flex flex-row justify-end px-4">
        <Button
          disabled={hotelRating === 0}
          type="button"
          onClick={() => {
            onSave(hotelRating);
            removeOverlay();
          }}
        >
          Publish Hotel
        </Button>
      </div>
    </div>
  );
};
