import { Coordinates, FAQ, ItemException, Picture, RefundType, ReservationType } from "../../../../../api";

type CarForm = {
  reg_no: string,
  model: string,
  drivers_picture: string,
  drivers_name: string,
  drivers_phone: string,
  drivers_email: string,
  location: string,
  coords: Coordinates,
  passengers: string,
  reservation_policy: ReservationType,
  refund_policy: RefundType,
  gallery: Picture[],
  faqs: FAQ[],
};

type CarFormErrors = {
  reg_no?: string,
  model?: string,
  drivers_picture?: string,
  drivers_name?: string,
  drivers_phone?: string,
  drivers_email?: string,
  location?: string,
  coords?: string,
  exceptions?: string,
  passengers?: string,
  reservation_policy?: string,
  refund_policy?: string,
  gallery?: string,
  faqs?: string,
};

export const validateCarForm = (form: CarForm): CarFormErrors => {
  const errors: CarFormErrors = {};

  if (form.reg_no === "") {
    errors.reg_no = "This field cannot be empty";
  }

  if (form.model === "") {
    errors.model = "This field cannot be empty";
  }

  if (form.coords.lat === 0 && form.coords.lng === 0) {
    errors.location = "Make sure to select the location from the dropdown while typing";
  }

  if (form.location === "") {
    errors.location = "This field cannot be empty";
  }

  if (form.passengers === "" || isNaN(Number(form.passengers))) {
    errors.passengers = "This field must have a valid number";
  }

  if (form.gallery.length < 3) {
    errors.gallery = "Must contain at least three picture of the car";
  }

  if (form.drivers_picture === "") {
    errors.drivers_picture = "Must contain a picture of the driver";
  }

  if (form.drivers_name === "") {
    errors.drivers_name = "Must contain the name of the driver";
  }

  if (form.drivers_phone === "") {
    errors.drivers_phone = "Must contain the phone of the driver";
  }

  if (form.drivers_email === "") {
    errors.drivers_email = "Must contain the email of the driver";
  }

  return errors;
};
