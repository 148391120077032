import {FC, useContext, useEffect, useState} from 'react';
import type {RouteComponentProps} from 'react-router-dom';
import Lottie from 'react-lottie';
import { DatePicker, TimePicker, InputNumber } from 'antd';

import animation from '../../../../assets/anim/loading-car.json';
import {Input} from '../../../../components/input';
import {Car, checkActivityAvailability, fetchCar} from '../../../../api';
import {fetchCompulsoryCarFilters} from '../../../../reducers';

import Container, {CheckoutSection} from '../../components/container';
import {ProceedToCheckout} from '../../components/proceed-to-checkout';
import {CarReservation} from '../../../../api/reservation/types';
import {DateUtil} from '../../../../util';
import {calculateCarPrice} from '../../../../util/price-calculator';
import { Button } from '../../../../components';
import ActivityIndicator from '../../../../components/loading';
import { OverlayProvider } from '../../../search-property/context';
import { TextInput } from '../../../../components/input/text-input';

type CarProps = RouteComponentProps<{id: string}>;

export const CarProperty: FC<CarProps> = ({match}) => {
  const {
    setIsCancellable, setIsOverlay, setOnOverlay,
  } = useContext(OverlayProvider);
  const [car, setCar] = useState<Car | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorOccured, setErrorOccured] = useState<boolean>(false);

  // get the colpulsory filters that the user used to make the search
  const {
    date: dateParameter, passengers: passengersParameter, place,
  } = fetchCompulsoryCarFilters();

  const [passengers, setPassengers] = useState<number>(
    passengersParameter ?
      Number(passengersParameter) :
      1,
  );
  const [date, setDate] = useState<Date>(
    dateParameter ?
      new Date(
        DateUtil.changeDateFormat(dateParameter, "DD-MM-YYYY", "YYYY-MM-DD"),
      ) :
      new Date()
  );
  const [isAvailable, setIsAvailable] = useState<boolean>(true);

  // input control
  const [time, setTime] = useState({
    hours: 10,
    minutes: 0,
    seconds: 0,
  });
  const [destination, setDestination] = useState<string>(""); 

  useEffect(() => {
    // fetch the Hotels and after fetching, carry out the on success function
    fetchCar(match.params.id, (err, data) => {
      setIsLoading(false);

      if (err) {
        setErrorOccured(true);
        return;
      }

      // handle the hotel data that has been retrieved by the system
      setCar(data);
    });
  }, [match.params.id]);

  const calculatePrice = (): number => {
    if (!car) return 0;

    return calculateCarPrice(car);
  };

  const onProceedToCheckout = () => {
    // creates the reservation card and then proceeds to checkout
    console.log(`Request for cab from: ${place} to: ${destination} at: ${time}`)

    const pickupDate = dateParameter ?
      new Date(
        `${
          DateUtil.changeDateFormat(dateParameter, "DD-MM-YYYY", "MM-DD-YYYY")
        } ${time}`).getTime() :
      new Date(
        `${
          DateUtil.changeDateFormatFromDate(new Date(), "MM-DD-YYYY")
        } ${time}`
      ).getTime();

    const reservation: CarReservation = {
      amount_received: 0,
      category: "car",
      checkin: pickupDate,
      due_date: pickupDate,
      expected_amount: calculatePrice(),
      property_id: car ? car.reg_no : "",
      reservation_date: Date.now(),
      reservation_type: car ? car.reservation_policy : "full_amount",
      status: "pending",
      passengers: passengersParameter ? Number(passengersParameter) : 1,
    };

    return reservation;
  };

  return (
    <>
      {isLoading && (
        <div className="w-full h-96 flex flex-col items-center justify-center">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animation,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            style={{}}
            height={200}
            width={200}
          />
          <p className="text-base font-semibold">Fetching Hotel Details</p>
        </div>
      )}
      {!isLoading && !errorOccured && car && (
        <Container
          property={{
            cover: car.gallery[0].url,
            description: "",
            exceptions: car.exceptions,
            faqs: car.faq,
            location: "",
            coords: {lat: 0, lng: 0},
            gallery: car.gallery,
            id: car.id,
            rating: car.rating,
            refund_policy: car.refund_policy,
            reservation_policy: car.reservation_policy,
            reviews: car.reviews,
            short_description: "",
            title: car.reg_no,
            offers: car.offers,
          }}

          renderSearchSection={() => {
            return (
              <>
                <TextInput
                  disabled={true}
                  value={car.reg_no}
                  helper="Registration number"
                  id="activity-name"
                  inputLabel="Registration number"
                  name="registration_number"
                  onChange={(e) => console.log("Should not change")}
                  className="mb-2"
                />
                <p className="text-xs mb-1">Date:</p>
                <DatePicker
                  className="mb-2"
                  onChange={(val) => {
                    if (val) {
                      setDate(val.toDate());
                    }
                  }}
                />

                <p className="text-xs mb-1">Set pick up time</p>
                <TimePicker
                  className="mb-2"
                  onChange={(val) => {
                    if (val) {
                      setTime({
                        hours: val.minutes(),
                        minutes: val.minutes(),
                        seconds: val.seconds(),
                      });
                    }
                  }}
                />

                <p className="text-xs mb-1">Number of passengers</p>
                <InputNumber
                  className="mb-2"
                  defaultValue={passengers}
                  value={passengers}
                  onChange={(val) => {
                    setPassengers(val);
                  }}
                />

                <Button
                  // TODO Add disabled button check
                  type="button"
                  onClick={() => {
                    setOnOverlay && setOnOverlay(() => () => (
                      <ActivityIndicator
                        caption=""
                      />
                    ));
                    setIsCancellable && setIsCancellable(false);
                    setIsOverlay && setIsOverlay(true);
                    checkActivityAvailability(
                      match.params.id,
                      date.getTime(),
                      (err, isAvailable) => {
                        setIsOverlay && setIsOverlay(false);
                        setOnOverlay && setOnOverlay(null);
                        setIsCancellable && setIsCancellable(true);

                        if (err) {
                          console.error(err);

                          return;
                        }

                        if (isAvailable !== null) {
                          setIsAvailable(isAvailable);
                        }
                      },
                    );
                  }}
                >
                  Check Availability
                </Button>
              </>
            );
          }}
        >
          <CheckoutSection>
            <ProceedToCheckout
              onProceedToCheckout={onProceedToCheckout}
              price={calculatePrice()}
              isAvailable={isAvailable}
            >
              <Input
                disabled={true}
                error=""
                inputLabel="Reg No."
                name="reg_no"
                onChange={() => console.log("Should not happen")}
                value={car ? car.reg_no: ""}
                id="car-reg-no-input"
                helper="Registration number of the car"
              />
              <Input
                disabled={true}
                error=""
                inputLabel="Pickup location"
                name="place"
                onChange={() => console.log("Should not happen")}
                value={place ? place: ""}
                id="origin-input"
                helper="Location you'll be picked up"
              />
              <Input
                disabled={false}
                error={
                  !destination ? "Field cannot be empty" : ""
                }
                inputLabel="Destination"
                name="destination"
                onChange={(e) => {
                  setDestination(e.target.value);
                }}
                value={destination ? destination: ""}
                helper="Where you want to go to"
                id="checkout-destination-input"
              />
              <Input
                disabled={dateParameter !== null}
                error=""
                inputLabel="Pick up Date"
                name="checkin"
                onChange={() => console.log("Should not happen")}
                value={dateParameter ? dateParameter: ""}
                id="car-pick-up-date"
                helper="Date cab is supposed to pick you up"
              />
              <Input
                disabled={true}
                helper="Reservation time"
                id="reservation-time"
                inputLabel="Reservation time"
                name="reservation_time"
                onChange={(e) => console.log("Should not be happening")}
                // TODO Shoddy work, refacto to a cleaner solution instead of
                // using an Immediately Invoked Function (harder to read)
                value={DateUtil.getHumanReadableFormat((() => {
                  const checkinDate = new Date(date);
                  checkinDate.setHours(
                    time.hours, time.minutes, time.seconds, 0,
                  );

                  return checkinDate;
                })())}
              />
              <Input
                disabled={true}
                error=""
                inputLabel="Passengers"
                name="passengers"
                onChange={() => console.log("Should not happen")}
                value={passengersParameter ? `${passengersParameter}`: ""}
                id="passenger-number-input"
                helper="Number of passengers being picked up"
              />
            </ProceedToCheckout>
          </CheckoutSection>
        </Container>
      )}
      {!isLoading && !errorOccured && !car && (
        <></>
      )}
      {!isLoading && errorOccured && (
        <></>
      )}
    </>
  );
};
