// @ts-check
import Lottie from "react-lottie";
import animation from "../../assets/anim/loading_1.json";

/**
 * Loading screen component for the Signin screen.
 *
 * @returns {JSX.Element}
 */
const App = () => {
  return (
    <div style={{ paddingTop: 120 }}>
      <div style={{ padding: 20 }}>
        <div style={{ padding: 50 }}>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animation,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={100}
            width={100}
          />
          <h2 style={{ marginBottom: 15, textAlign: "center" }}>
            checking...
          </h2>
        </div>
      </div>
    </div>
  );
}
export default App;
