// @ts-check
import {useState} from "react";
import {FiMenu} from "react-icons/fi";
import {FaTimes} from "react-icons/fa";
import {Link} from 'react-router-dom';

/**
 * Header Component used for mobile devices, this uses menus instead of having all the options
 * put across in a line. As such, once the hamburger menu is  opened, there is an overlay created
 * exposing all other options available to the user.
 *
 * This component listents for the `window.onscroll` event in order to set the header color in
 * accordance to the `window.pageYOffset`
 *
 * @returns {JSX.Element}
 */
const App = () => {
  const [headerColor, setHeaderColor] = useState("white");
  const [menuOpenState, setMenuOpenState] = useState(false);
  const [headerItemColor, setHeaderItemColor] = useState("black");

  // this callback is reassigned to the window every time the page color of header changes.
  // this was the approach taken as only assigning it during componentDidMount and watching
  // the headerColor to re-execute didnt seem to let the item re-execute upon pageOffset
  // condition change.
  window.onscroll = () => {
    if (window.pageYOffset > 100 && headerColor === "white") {
      setHeaderColor("black");
      setHeaderItemColor("white");
    } else if (window.pageYOffset <= 100 && headerColor === "black") {
      setHeaderColor("white");
      setHeaderItemColor("black");
    }
  };

  return (
    <>
      <div
        id="mobile-header"
        className="mobile-header z-20"
        style={{ background: headerColor, position: "fixed" }}
      >
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ flex: 1 }}>
            <span style={{ color: headerItemColor }}>
              Safarixperts
            </span>
          </div>
          <FiMenu
            color={headerItemColor}
            onClick={() => setMenuOpenState(!menuOpenState)}
          />
        </div>
      </div>
      {menuOpenState && (
        <div className="mobile-menu-items-parent text-white">
          <div
            style={{
              position: "fixed",
              top: 0,
              display: "flex",
              width: "98%",
              padding: 20,
              color: "#cacaca",
              fontSize: 28,
            }}
          >
            <div style={{ flex: 1 }} />
            <FaTimes onClick={() => setMenuOpenState(!menuOpenState)} />
          </div>
          <Link to="/signin">Signin</Link>
          <Link to="/list-your-property">List Your Property</Link>
          <Link to="/trip-planner">Trip Planner</Link>
        </div>
      )}
    </>
  );
};

export default App;
