import {FC} from 'react';
import {Empty} from "antd"

export const NotFound: FC = () => {
  return (
    <div
      className="flex flex-row justify-center w-full max-w-5xl items-center mx-auto"
      style={{
        height: 600,
      }}
    >
      <Empty
        className="mb-4"
        description="Coming soon"  
      />
    </div>
  );
};

