import {tours} from "../../../../dummy-data";
import {
  fetchCompulsoryToursFilters,
  fetchOptionalToursFilters,
} from "../../../../reducers";

export default function fetchTours(state, updateState) {
  const {place} = fetchCompulsoryToursFilters();
  const optionalFilters = fetchOptionalToursFilters();

  const rawResults = tours.map((tour) => ({
    ...tour,
    calcPrice: tour.price,
  }));

  updateState({
    rawResults,
    searchResults: rawResults,
    searchItemsLength: rawResults.length,
    searchDone: true,
    category: "tours",
    city: place,
    maxPrice: 800,
    minPrice: 0,
    rangeMax: optionalFilters.price_highest ?
      optionalFilters.price_highest : 800,
    rangeMin: optionalFilters.price_lowest ?
      optionalFilters.price_lowest : 0,
  });
}
