// @ts-check
import React, { Component } from "react";
import {
  FaArrowLeft,
  FaChevronRight,
  FaPlus,
  FaShareAlt,
} from "react-icons/fa";
import Lottie from "react-lottie";

import animation from "../../../../../assets/anim/trip-planner-timeline.json";
import { DateUtil } from "../../../../../util";

import TripItenerary from "./TripItenerary";
import { AccomodationItem } from "../../accomodation-item";
import { TravelList } from "../../travel-list";
import { CateringList } from "../../catering-list";
import { ActivityList } from "../../activity-list";

class App extends Component {
  /**@type {import('react').RefObject<HTMLDivElement>} */
  itineraryRef = React.createRef();
  /**@type {import('react').RefObject<HTMLDivElement>} */
  summaryRef = React.createRef();

  constructor(props) {
    super(props);
    const defaultNavModuleHash =
      /trip-view-travel/.test(location.href) ?
        "#navigator-view-flights" :
      /trip-view-accomodation/.test(location.href) ?
        "#navigator-view-hotels" :
      /trip-view-catering/.test(location.href) ?
        "#navigator-view-eateries" :
      /trip-view-activities/.test(location.href) ?
        "#navigator-view-activities" :
        "#navigator-view-flights";

    this.state = {
      tabArray: [
        {
          title: "travel",
          ref: "trip-view-travel",
          active: /trip-view-travel/.test(location.href) || !location.hash,
          defaultNavModuleHash: "#navigator-view-flights",
        },
        {
          title: "accomodation",
          ref: "trip-view-accomodation",
          active: /trip-view-accomodation/.test(location.href),
          defaultNavModuleHash: "#navigator-view-hotels",
        },
        {
          title: "catering",
          ref: "trip-view-catering",
          active: /trip-view-catering/.test(location.href),
          defaultNavModuleHash: "#navigator-view-eateries",
        },
        {
          title: "activities",
          ref: "trip-view-activities",
          active: /trip-view-activities/.test(location.href),
          defaultNavModuleHash: "#navigator-view-activities",
        },
      ],
      totalPay: 0,
      searchNavigatorModalVisible: false,
      printableModuleVisible: false,
      searchResultsVisible: false,
      modalViewPropertyVisible: false,
      defaultNavModuleHash,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line no-unused-vars
    window.addEventListener("hashchange", (e) => {
      let windowHash = window.location.hash;
      if (!windowHash) {
        windowHash = "#trip-view-travel";
      }
      console.log("hashchange2", windowHash);
      const tabArray = [];
      let defaultNavModuleHash;
      this.state.tabArray.map((item) => {
        if (`#${item.ref}` == windowHash) {
          const newItem = item;
          newItem["active"] = true;
          defaultNavModuleHash = item.defaultNavModuleHash;
          tabArray.push(newItem);
        } else {
          const newItem = item;
          newItem["active"] = false;
          tabArray.push(newItem);
        }
      });
      this.setState({ tabArray, defaultNavModuleHash });
    });
  }

  // componentWillUnmount() {
  //   window.removeEventListener("hashchange", this.doSomething, false);
  // }

  _showSearchNavigator = (fabDisabled) => {
    if (!fabDisabled) {
      // before we set the location navigator, we have to set the tabView that
      // will be visible upon update of the searchNavigator
      let activeIndex = null;
      this.state.tabArray.forEach((tab, idx) => {
        if (tab.active) {
          activeIndex = idx;
        }
      });
  
      history.pushState(
        null,
        ``,
        `${
          window.location.pathname
        }/navigator${
          this.state.defaultNavModuleHash
        }`,
      );
      this.props.navigateToSearch(`${activeIndex}`);
    }
  };

  _showCheckoutPage = () => {
    if (this.props.sharedState.payable_total > 0) {
      window.location.href = `${window.location.pathname}/checkout#checkout`;
    }
  };

  _setSharedState = (state) => {
    console.log("state changed:", state);
    this.setState(state);
  };

  // eslint-disable-next-line no-unused-vars
  _deleteFromTrip = (item) => { // eslint-disable-line no-empty-function
  };

  /**
   * This method checks whether the FAB button should be disabled based on the
   * section that we are currently in and whether the itinerary list for this
   * section is currently full.
   *
   * @returns {boolean} whether the fab should be disabled or not.
   */
  _checkFabState = () => {
    let activeTab = "";

    this.state.tabArray.forEach((tab) => {
      if (tab.active) {
        activeTab = tab.title;
      }
    });

    switch (activeTab) {
      case "travel":
        return this._checkFabForTravelling();
      case "accomodation":
        return this._checkFabForAccomodation();
      case "catering":
        return this._checkFabForCatering();
      case "activities":
        return this._checkFabForActivities();
      default:
        return false;
    }
  };

  /**
   * This is used to check whether the Fab should be disabled for the
   * Accommodation secition. This is done by in two steps:
   * 1. `the size of the accomodation_list` - if it is 0, then by default the
   * Fab should be enabled, otherwise we go to a deeper comparisson.
   * 2. `check the occupied dates` - if any of the dates in the itinerary list
   * is not occupied by the with an accomodation action, then we can allow
   * updates to the list, otherwise we do not allow the search action unless the
   * user removes from the itinerary list.
   *
   * @returns {boolean} whether the fab should be disabled or not.
   */
  _checkFabForAccomodation = () => {
    if (this.props.sharedState.accomodation_list.length === 0) {
      return false;
    }

    // eslint-disable-next-line no-unused-vars
    const occupiedDatesList = DateUtil.fetchAvailableDates(
      this.props.sharedState.trip_data.startDate,
      this.props.sharedState.trip_data.returnDate,
    );

    console.log(occupiedDatesList);

    // as of now, the occupiedDateList is the same as the checkin and the
    // checkout, so as long as there is an entry in the accomodation list, then
    // we can treat the hotel as occupied.

    return this.props.sharedState.accomodation_list.length > 0;
  };

  /**
   * This is used to check whether the FAB should be disabled when in the
   * catering section.
   * This is done in the following steps:
   * 1. `checking the length of the catering list` - if it is 0, then the fab
   * should be enabled by default.
   *
   * @returns {boolean} whether the catering Fab should be disabled.
   */
  _checkFabForCatering = () => {
    let isDisabled = false;

    if (this.props.sharedState.catering_list.length == 0) {
      return false;
    }

    // TODO deeper search will be added once functionality is enabled

    return isDisabled;
  };

  /**
   * Checks whether the Fab for the Travelling section should be disabled.
   *
   * @returns {boolean} whether the fab should be disabled or not.
   */
  _checkFabForTravelling = () => {
    let isDisabled = false;

    if (this.props.sharedState.travel_list.length === 0) {
      return isDisabled;
    }

    // TODO deeper comparissons will be handled once functionality is added.

    return isDisabled;
  };

  /**
   * Checks for whether the fab on the Activities section should be disabled.
   *
   * @returns {boolean} whether the fab should be disabled or not.
   */
  _checkFabForActivities = () => {
    let isDisabled = false;

    if (this.props.sharedState.activities_list.length === 0) {
      return isDisabled;
    }

    // TODO Deeper comparisson for activities

    return isDisabled;
  };

  render() {
    // we use this variable to know whether to turn off functionality such as
    // allowing payment by disabling the button for payment preventing pay if
    // the user hasnot added any itinerary.
    // we check to ensure that all of the elements contained in the itinerary
    // list are of length 0, if so: disable the button.
    const payDisabled = this.props.sharedState.payable_total === 0;

    // to prevent users being able to book twice over a period that they are
    // currenly statying in another hotel, we check whether to disable the fab
    // based on the context the user is in at the given period and whether their
    // itinerary is already full for that particular section.
    const fabDisabled = this._checkFabState();

    return (
      <div style={{ display: "flex" }}>
        <div
          ref={this.itineraryRef}
          className="flex flex-col m-10 mt-0 lg:mt-5"
          style={{ flex: 2, margin: 10, marginTop: 20 }}>
          <div
            className={
              "flex justify-between items-center justify-items-center mt-5 " +
              "lg:hidden"
            }
          >
            {/* <div
              style={{ fontSize: 20, padding: 20, paddingRight: 0, flex: 1 }}
            >
              Trip Itenerary
            </div> */}
            <span
              className={
                "py-2 px-3 flex flex-row items-center text-xs"
              }
            >
              Trip itinerary
            </span>
            <span
              className={
                "py-2 px-3 flex flex-row items-center text-xs"
              }
              onClick={() => {
                this.summaryRef.current.scrollIntoView({behavior: "smooth"});
              }}
            >
              {/* <FaClipboardCheck
                className="mr-3 text-primary"
                onClick={() => {
                  window.scrollTo({top: 0, left: 0, behavior: "smooth"});
                }}
              /> */}
              View Trip Summary & Checkout
              <FaChevronRight
                className="ml-3 text-primary"
                onClick={() => {
                  window.scrollTo({top: 0, left: 0, behavior: "smooth"});
                }}
              />
            </span>
          </div>
          <div
            style={{ paddingTop: 0, display: "flex", justifyContent: "center" }}
          >
            <div
              className="breadcrumb flat"
              style={{ borderRight: "1px solid #eee" }}
            >
              {this.state.tabArray.map((item) => {
                if (item.active) {
                  return (
                    <a className="active">
                      <span>{item.title}</span>
                    </a>
                  );
                }
                return (
                  <a
                    // href={`#${item.ref}`}
                    onClick={() => {
                      window.location.hash = `#${item.ref}`;
                      const newTabArray = this.state.tabArray.map((tab) => ({
                        ...tab,
                        active: tab.ref === item.ref,
                      }));
                      console.log(newTabArray);

                      this.setState({tabArray: newTabArray});
                    }}
                    style={{
                      borderTop: "1px solid #eee",
                      borderBottom: "1px solid #eee",
                    }}
                  >
                    <span>{item.title}</span>
                  </a>
                );
              })}
            </div>
          </div>
          <div
            style={{
              paddingTop: 15,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div style={{ fontSize: 20 }}>
              <h6
                style={{
                  margin: 0,
                  padding: 0,
                  textOverflow: "ellipsis",
                  flex: 1,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  color: "#111",
                }}
              >
                {this.state.destination}
              </h6>
            </div>
            <div style={{ flex: 1 }} />
          </div>
          <div
            style={{
              marginTop: 15,
              background: "white",
              minHeight: 500,
              padding: 15,
              position: "relative",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            {this.state.tabArray[0].active && (
              <>
                {this.props.sharedState.travel_list.length === 0 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: 300,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          color: "#aaa",
                          fontSize: 12,
                          marginTop: -50,
                        }}
                      >
                        Add a <b style={{ color: "#25363e" }}>flight</b> plan,{" "}
                        <b style={{ color: "#25363e" }}>cruise</b> or book a{" "}
                        <b style={{ color: "#25363e" }}>car</b> for during the time
                        of your trip
                      </div>
                    </div>
                  </div>
                )}
                {this.props.sharedState.travel_list.length > 0 && (
                  <TravelList
                    travelList={this.props.sharedState.travel_list}
                  />
                )}
              </>
            )}
            {this.state.tabArray[1].active && (
              <>
                {this.props.sharedState.accomodation_list.length == 0 ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        height: 300,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "center",
                            color: "#aaa",
                            fontSize: 12,
                            marginTop: -50,
                          }}
                        >
                          Book a <b style={{ color: "#25363e" }}>hotel</b> or a
                          {" "}
                          <b style={{ color: "#25363e" }}>rental</b> for your
                          trip
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {this.props.sharedState.accomodation_list.map((item) => {
                      console.warn("Item");
                      console.log(item);
                      const checkinDate =
                        this.props.sharedState.trip_data.startDate;
                      const checkoutDate =
                        this.props.sharedState.trip_data.returnDate;
                      //number of days
                      const oneDay = 24 * 60 * 60 * 1000;
                      const startDate =
                        new Date(DateUtil.changeDateFormat(
                          checkinDate, "DD-MM-YYYY", "YYYY-MM-DD",
                        )).getTime();
                      const stopDate =
                        new Date(DateUtil.changeDateFormat(
                          checkoutDate, "DD-MM-YYYY", "YYYY-MM-DD",
                        )).getTime();
                      let diffDays = Math.round(
                        Math.abs((stopDate - startDate) / oneDay)
                      );
                      if (diffDays == 0) {
                        diffDays = 1;
                      }
                      console.log("diffDays", diffDays);
                      return (
                        <AccomodationItem
                          checkinDate={checkinDate}
                          checkoutDate={checkoutDate}
                          diffDays={diffDays}
                          item={item}
                        />
                      );
                    })}
                  </>
                )}
              </>
            )}
            {this.state.tabArray[2].active && (
              <>
                {this.props.sharedState.catering_list.length === 0 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: 300,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          color: "#aaa",
                          fontSize: 12,
                          marginTop: -50,
                        }}
                      >
                        Add <b style={{ color: "#25363e" }}>restaurant</b> bookings
                        for different times of your trip
                      </div>
                    </div>
                  </div>
                )}
                {this.props.sharedState.catering_list.length > 0 && (
                  <CateringList
                    cateringList={this.props.sharedState.catering_list}
                  />
                )}
              </>
            )}
            {this.state.tabArray[3].active && (
              <>
                {this.props.sharedState.activity_list.length === 0 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: 300,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          color: "#aaa",
                          fontSize: 12,
                          marginTop: -50,
                        }}
                      >
                        Add an <b style={{ color: "#25363e" }}>active</b>,{" "}
                        <b style={{ color: "#25363e" }}>tour</b> or book{" "}
                        <b style={{ color: "#25363e" }}>conferencing facilities</b>
                        {" "}for your trip
                      </div>
                    </div>
                  </div>
                )}
                {this.props.sharedState.activity_list.length >0 && (
                  <ActivityList
                    activityList={this.props.sharedState.activity_list}
                  />
                )}
              </>
            )}
            <div className="fab-parent">
              <div
                className={`${(fabDisabled) ? "fab-disabled" : "fab"} elevate`}
                onClick={() => this._showSearchNavigator(fabDisabled)}
              >
                <FaPlus />
              </div>
            </div>
          </div>
        </div>
        <div
          ref={this.summaryRef}
          className={
            "flex flex-col items-center absolute top-14 left-full lg:relative " +
            "lg:top-0 lg:left-0 w-screen lg:w-auto"
          }
          style={{
            border: "0px solid #ccc",
            flex: 1,
            margin: 2,
            justifyContent: "start",
          }}
        >
          {/* Hidden on larger screens above 1024 */}
          {/* <div className="flex items-end">
            <span
              className="py-2 px-3 border-2 flex flex-row items-center"
            >
              <FaArrowLeft className="mr-8" /> Show itinerary
            </span>
          </div> */}
          <div
            className="flex-div"
            style={{
              background: "white",
              width: 350,
              height: 500,
              position: "relative",
            }}
          >
            <div
              className={
                "flex justify-between items-center justify-items-center my-5"
              }
            >
              {/* <div
                style={{ fontSize: 20, padding: 20, paddingRight: 0, flex: 1 }}
              >
                Trip Itenerary
              </div> */}
              <span
                className={
                  "py-2 px-3 flex flex-row items-center lg:p-0"
                }
              >
                <FaArrowLeft
                  className="mr-8 text-primary lg:hidden"
                  onClick={() => {
                    this.itineraryRef.current.scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                />
                Trip summary
              </span>
              <div
                className={
                  "flex items-center justify-items-center content-center " +
                  "text-xl"
                }
              >
                <FaShareAlt
                  onClick={() => {
                    this.setState({ printableModuleVisible: true });
                  }}
                  style={{ color: "#f65888" }}
                />
              </div>
            </div>
            <div
              style={{
                padding: 10,
                overflowY: "scroll",
                height: 280,
                background: "#fdfcfc",
              }}
            >
              {this.props.sharedState.itenerary.length == 0 ? (
                <>
                  <div style={{ marginTop: 20 }}>
                    <Lottie
                      options={{
                        loop: false,
                        autoplay: true,
                        animationData: animation,
                        rendererSettings: {
                          preserveAspectRatio: "xMidYMid slice",
                        },
                      }}
                      height={150}
                      width={270}
                    />
                    <h3
                      style={{
                        marginTop: 40,
                        textAlign: "center",
                        color: "#d6d0d0",
                      }}
                    >
                      No Itenerary yet
                    </h3>
                  </div>
                </>
              ) : (
                <>
                  <TripItenerary itenerary={this.props.sharedState.itenerary} />
                </>
              )}
            </div>
            <div style={{ fontSize: 20, paddingLeft: 20, paddingTop: 20 }}>
              Total Spendings: <b>{this.props.sharedState.payable_total} USD</b>
            </div>
            <a
              onClick={this._showCheckoutPage}
              style={{
                cursor: (payDisabled) ? "not-allowed" : "pointer",
              }}
            >
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  width: "100%",
                  marginTop: 25,
                }}
              >
                <div
                  className={payDisabled ? "checkout-but-disabled" : "checkout-but"}
                >
                  REVIEW & PAY
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
