import {firestore} from "firebase";

import {OnCompleteCallback} from "..";
import {getUser, NoUserFoundError} from "../user";

import {Draft} from "./types";

export type {Draft};

export const fetchDrafts = async (
  category: string, onComplete: OnCompleteCallback<Draft<unknown>[]>,
): Promise<void> => {
  const user = getUser();

  if (user) {
    try {
      const snapshot = await firestore().collection("draft")
        .where("category", "==", category)
        .where("uid", "==", user.uid)
        .get();
      const drafts = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      } as Draft<unknown>));
  
      onComplete(null, drafts);
    } catch (err) {
      const fetchErr = new Error("Fetching error occured");

      onComplete(fetchErr, null);
    }
  } else {
    const err = new Error("No user found");
    onComplete(err, null);
  }
};

export const fetchDraft = async (
  draftId: string, onComplete: OnCompleteCallback<Draft<unknown>>,
): Promise<void> => {
  const user = getUser();

  if (user) {
    const docSnapshot = await firestore().collection("draft")
      .doc(draftId)
      .get();
    
    onComplete(
      null, {id: docSnapshot.id, ...docSnapshot.data()} as Draft<unknown>,
    );
  } else {
    onComplete(new NoUserFoundError(), null);
  }
};

// export const postDraft = async (
//   data: Record<string, unknown>,
//   onComplete: OnCompleteCallback<Record<string, unknown>>,
// ): Promise<void> => {
//   const docRef = await firestore()
//     .collection("draft")
//     .add(data);
  
//   const docSnapshot = await docRef.get();

//   onComplete(null, {
//     id: docSnapshot.id,
//     ...docSnapshot.data(),
//   } as Draft<Record<string, unknown>>)
// };

export const removeDraft = async (
  id: string, onComplete: OnCompleteCallback<boolean>,
): Promise<void> => {
  await firestore().collection("draft").doc(id).delete();

  onComplete(null, true);
};

export {
  postActivityDraft, postCarDraft, postConferenceDraft, postEateryDraft,
  postHotelDraft, postRentalDraft, postTourDraft, updateActivityDraft,
  updateCarDraft, updateConferenceDraft, updateEateryDraft,
  updateHotelDraft, updateRentalDraft, updateTourDraft,
} from './upload';

export {
  fetchReservationDraft, fetchReservationDrafts, fetchPopulatedReservationDrafts,
  postReservationDraft, updateReservationDraft, deleteDraftReservation,
} from './reservation';
