import {auth, firestore, User} from "firebase";

import {OnCompleteCallback} from "../..";
import {Car} from "../../car";
import {getUser, NoUserFoundError} from "../../user";
import {uploadPictures} from "../../cdn";

import {Draft} from "..";

export const postCarDraft = async (
  draftId: string | null, car: Car,
  onComplete: OnCompleteCallback<Draft<Car>>,
): Promise<void> => {
  /** function executed once we are sure that there is a user present */
  const user = getUser();

  const addNewCar = (user: User) => {
    const picturesToUpload = car.gallery;
    uploadPictures("car", picturesToUpload, async (err, uploadedPictures) => {
      if (err) {
        onComplete(err, null);
        return;
      }
      if (uploadedPictures) {
        car.gallery = uploadedPictures;
    
        // upload the car details since the document has been successfully added.
    
        try {
          const val = await firestore().collection("draft")
            .add({
              draft: car,
              uid: user.uid,
              category: "car",
            } as Draft<Car>);
          
          // get a reference to the data that has been posted about the car back to
          // the program after successful addition
          const doc = await val.get();
          onComplete(
            null,
            ({
              ...doc.data(),
              id: doc.id,
            } as Draft<Car>),
          );
        } catch (err) {
          onComplete(
            new Error("An error occured whil uploading the document"),
            null
          );
        }
      } else {
        const pictureUploadError = new Error("Pictures obj returned as null");
        onComplete(pictureUploadError, null);
      }
    });
  };

  if (user !== null) {
    if (draftId) {
      updateCarDraft(draftId, car, onComplete);

      return;
    }
    addNewCar(user);
  } else {
    auth().onAuthStateChanged((user) => {
      if(user) {
        addNewCar(user);
      } else {
        onComplete(new NoUserFoundError(), null);
      }
    });
  }
};

export const updateCarDraft = async (
  id: string, car: Car, onComplete: OnCompleteCallback<Draft<Car>>,
): Promise<void> => {
  /** function executed once we are sure that there is a user present */
  const user = getUser();

  const addNewCar = () => {
    const picturesToUpload = car.gallery;
    uploadPictures("car", picturesToUpload, async (err, uploadedPictures) => {
      if (err) {
        onComplete(err, null);
        return;
      }
      if (uploadedPictures) {
        car.gallery = uploadedPictures;
    
        // upload the car details since the document has been successfully added.
    
        try {
          await firestore().collection("draft")
            .doc(id)
            .update({
              draft: car,
            } as Draft<Car>);
          
          // get a reference to the data that has been posted about the car back to
          // the program after successful addition
          const docSnapshot = await firestore().collection("draft")
            .doc(id)
            .get();

          onComplete(
            null,
            ({
              ...docSnapshot.data(),
              id: docSnapshot.id,
            } as Draft<Car>),
          );
        } catch (err) {
          onComplete(
            new Error("An error occured whil uploading the document"),
            null
          );
        }
      } else {
        const pictureUploadError = new Error("Pictures obj returned as null");
        onComplete(pictureUploadError, null);
      }
    });
  };

  if (user !== null) {
    addNewCar();
  } else {
    auth().onAuthStateChanged((user) => {
      if(user) {
        addNewCar();
      } else {
        onComplete(new NoUserFoundError(), null);
      }
    });
  }
};
