import {useState} from 'react';
import type {FC, FormEventHandler} from 'react';
import {FaWindowClose} from 'react-icons/fa';
import {useHistory} from 'react-router-dom';

import {fetchCompulsoryConferencingFilters} from '../../../../reducers';
import {Dropdown} from '../../../../components';

import {SectionHeader} from "../section-header";

import { MobileFilterContext } from '../../context';

import {AutoCompletePlaces} from './auto-complete-places';
import {DateInput} from './date-input';
import {SubmitButton} from './submit-button';

type ConferencingProps = {
  setRawResultsStale?: (resultsStale: boolean) => void;
};

export const Conferencing: FC<ConferencingProps> = ({setRawResultsStale}) => {
  const compulsoryFilters = fetchCompulsoryConferencingFilters();
  const [coords, setCoords] = useState({
    lat: compulsoryFilters.lat, lng: compulsoryFilters.lng,
  });
  const history = useHistory();

  const selectAddress = (
    place: string, newCoords: {lat: number, lng: number},
  ) => {
    setCoords(newCoords);
  };
  const url = new URL(location.href);

  // the search values used in the search fields
  const [place, setPlace] = useState(compulsoryFilters.place);
  const [date, setDate] = useState(compulsoryFilters.date);
  const [adults, setAdults] = useState(compulsoryFilters.adults);
  const [children, setChildren] = useState(compulsoryFilters.children);

  // handle form submission
  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    // make sure that there are no null values that may lead to the app crashing
    // in production scenarios
    if (place === null || date === null) {
      return; // early return
    }
    // set all the compulsory values in the url and then start linking
    url.searchParams.set("place", place);
    url.searchParams.set("lat", `${coords.lat}`);
    url.searchParams.set("lng", `${coords.lng}`);
    url.searchParams.set("date", date);
    url.searchParams.set("adults", `${adults}`);
    url.searchParams.set("children", `${children}`);

    // remove all optionalFilters
    url.searchParams.delete("feature");
    url.searchParams.delete("star_rating");
    url.searchParams.delete("theme");
    url.searchParams.delete("price_lowest");
    url.searchParams.delete("price_highest");

    history.push(`${url.pathname}${url.search}`);
    setRawResultsStale && setRawResultsStale(true);
  };

  return (
    <div
      className={
        "elevate-xs small-search-bar z-10 relative transition-all ease-linear" +
        " duration-300 bg-gradient-to-tr from-start to-end rounded-md " +
        "mb-5 lg:sticky lg:top-0"
      }
    >
      <SectionHeader
        style={{
          borderTop: 0,
          color: "white",
          borderBottomColor: "white",
        }}
      >
        Edit Search
      </SectionHeader>
      <MobileFilterContext.Consumer>
        {({ newSearchFilter, setNewSearchFilterVisible }) => (
          <div
            className="absolute top-2 right-2 md:hidden"
            onClick={() => {
              // To prevent accessing a variable that is null which may lead to
              // crashes in production, we have to make sure that the varaible
              // is not null before we try to access the function.
              setNewSearchFilterVisible && setNewSearchFilterVisible(false);
            }}
          >
            {newSearchFilter && <FaWindowClose size={28} color="white" />}
          </div>
        )}
      </MobileFilterContext.Consumer>
      <div style={{ padding: 12 }}>
        <form onSubmit={handleSubmit}>
          <label htmlFor="place">Venue of the conference</label>
          <AutoCompletePlaces
            name="place"
            setPlace={setPlace}
            placeholder="Where will the conference be held?"
            value={place === null ? "" : place}
            selectAddress={selectAddress}
          />

          {date !== null && (
            <DateInput
              defaultValue={date}
              label="When will the conference be held?"
              name="date"
              setDate={setDate}
            />
          )}
          <Dropdown
            defaultValue={adults === null ? 1 : adults}
            label="Number of Adults"
            name="adults"
            options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((adultNumber) => ({
              title: adultNumber === 1 ? `1 adult` : `${adultNumber} adults`,
              value: adultNumber,
            }))}
            onChange={(e) => setAdults(Number(e.target.value))}
          />

          <Dropdown
            defaultValue={children === null ? 0 : children}
            label="Number of Children"
            name="children"
            options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
              (childrenNumber) => ({
                title:
                  childrenNumber !== 1
                    ? `${childrenNumber} children`
                    : "1 child",
                value: childrenNumber,
              })
            )}
            onChange={(e) => setChildren(Number(e.target.value))}
          />

          <SubmitButton
            disabled={
              place === compulsoryFilters.place &&
              date === compulsoryFilters.date &&
              adults === compulsoryFilters.adults &&
              children === compulsoryFilters.children
            }
          />
        </form>
      </div>
    </div>
  );
}
