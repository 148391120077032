import type {FC, CSSProperties} from "react";

/**
 * This is the section header element that displays the title of the section
 * the user is currently in. This allows taking in of extra style properties to
 * override the general styles of the section header as is.
 */
export const SectionHeader: FC<{style: CSSProperties}> = ({
  children, style,
}) => {
  return (
    <div
      style={{
        fontWeight: "bold",
        // fontSize: 20,
        padding: 10,
        paddingLeft: 30,
        color: "#111",
        borderTop: "1px solid #ccc",
        borderBottom: "1px solid #ccc",
        fontSize: 14,
        marginRight: 10,
        marginLeft: 10,
        ...style,
      }}
    >
      {children}
    </div>
  );
}
