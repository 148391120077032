import {useEffect, FC, useState} from "react";

import {
  fetchRecommended, Property, RecommendedProperties,
} from "../../../api";
import {AttractionSite} from "../../../api/attraction/types";

import {FlipBox, FlipBoxCategory} from "./flip-box";

export const RecommendedTab: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [recommendedProperties, setRecommendedProperties] =
    useState<RecommendedProperties>({
      activity: [],
      conference: [],
      eatery: [],
      hotel: [],
      rental: [],
      tour: [],
      attraction: [],
    })

  useEffect(() => {
    // fetch recommended hotels
    fetchRecommended((err, recommendedResults) => {
      console.log("Recommended results", recommendedResults);
      if (err) {
        console.log(err);

        return;
      }

      if (recommendedResults) {
        setRecommendedProperties(recommendedResults);
        setIsLoading(false);
      }
    });
  }, []);

  return (
    <div className="display-port flex-shrink-0 flex-grow-0">
      {/* Recommended categories are for all nine categories */}
      <h1 className="font-lato font-bold text-3xl text-center mt-4">
        {"Editors' picks "}
      </h1>
      <span>
        <p className="text-center font-semibold opacity-70">
          These are the properties our editors think you might love {" "}
        </p>
        {/* <span className="text-red-500">{"\u2764"}</span> */}
      </span>
      {isLoading && Object.keys(recommendedProperties).map((category) => (
        <div key={category} className="animate-pulse pt-4 px-4 lg:px-0">
          <div className="h-6 mb-3 bg-gray-200" />
          <div className="h-64 mb-3 bg-gray-200" />
        </div>
      ))}
      {!isLoading && Object.keys(recommendedProperties).map((category) => {
        console.log(category);
        if (category === "attraction") {
          return (
            <div
              key={`recommended_${category}`}
              className="pt-2 px-4 xl:px-0"
              style={{zIndex: 0}}
            >
              <h2 className="text-xl font-lato font-semibold">
                Discover attractions near you
              </h2>

              <div className="grid-container-4 display-port">
                {recommendedProperties[category as keyof RecommendedProperties]
                  .map((property: unknown) => (
                    // this typecasting issue was introduced by the introduction of
                    // attraction sites to the recommended tab.
                    // TODO: this section may nenefit from refactor to remove the
                    // unecessary typecast
                    <FlipBox
                      key={(property as AttractionSite).id}
                      property={property as AttractionSite}
                      category={category as FlipBoxCategory}
                    />
                  ))}
              </div>
            </div>
          );
        } else if (recommendedProperties[category as keyof RecommendedProperties].length > 0) {
          return (
            <div
              key={`recommended_${category}`}
              className="pt-2 px-4 lg:px-0"
            >
              <h2 className="text-xl font-lato font-semibold">
                Recommended{" "}{category}
              </h2>

              <div className="grid-container-4 display-port">
                {recommendedProperties[category as keyof RecommendedProperties]
                  .map((property: unknown) => (
                    <FlipBox
                      key={(property as Property).id}
                      property={property as Property}
                      category={category as FlipBoxCategory}
                    />
                  ))}
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};
