import {database, auth} from "firebase";
import {OnCompleteCallback} from "..";
import { postReservations } from "../reservation";
import {Reservation} from "../reservation/types";

import {Transaction} from "./types";

export const postMPesaTransaction = async (
  amount: number, phoneNumber: string, reservations: Reservation[],
  onComplete: OnCompleteCallback<Reservation[]>,
): Promise<void> => {
  auth().onAuthStateChanged(async (user) => {
    console.info(user);
    let updatedPhoneNumber = "";

    if(phoneNumber.startsWith("07")) {
      updatedPhoneNumber = phoneNumber.replace("07", "2547");
    } else if (phoneNumber.startsWith("+254")) {
      updatedPhoneNumber = phoneNumber.replace("+254", "254");
    } else {
      updatedPhoneNumber = phoneNumber;
    }
    
    if (user) {
      // create the transaction first
      const transaction: Transaction = {
        amount,
        mode: "mpesa",
        receiver: "safari_xperts",
        sender: user.uid,
        status: "pending",
        timestamp: Date.now(),
      };
  
      const transactionId = createTransactionId(
        transaction.sender, transaction.receiver, transaction.timestamp,
      );
  
      const transactionRef = database()
        .ref(`transaction/${transactionId}`);
      
      // set the transaction and then initiate it
      transactionRef.set(transaction);
  
      try {
        // after the transaction is created, we can call the mpesa api,
        //  and then wait on it to update
        await fetch("https://us-central1-safarixpertz.cloudfunctions.net/payment/mpesa/request_payment", {
          method: "POST",
          headers: {
            // "Access-Control-Allow-Origin": "https://us-central1-safarixpertz.cloudfunctions.net/initiate_transaction",
            "Content-Type": "application/json",
            "Accept": "application/json",
          },
          body: JSON.stringify({
            transaction,
            transactionId,
            phoneNumber: updatedPhoneNumber,
          }),
        });
  
        transactionRef.on("value", (snapshot) => {
          const updatedTransaction = snapshot.val() as Transaction;
  
          if (updatedTransaction.status === "pending") {
            console.info("Transaction is still being processed");
          } else if (
            updatedTransaction.status === "cancelled" ||
              updatedTransaction.status === "failed"
          ) {
            const transactionFailedError = new Error("Transaction failed");
            onComplete(transactionFailedError, null);
          } else if(updatedTransaction.status === "successful") {
            // create all the reservations and then handle the payment delivery
            console.log("Posting Reservations: ", reservations);
            postReservations(reservations, onComplete);
          }
        });
      } catch (err) {
        const fetchError = new Error("Unable to make request to mpesa");
  
        onComplete(fetchError, null);
      }
    } else {
      // the user credentials could not be got, as such we cannot
      const noUserFoundError = new Error("No user details found");
      onComplete(noUserFoundError, null);
    }
  });
};

export const postPayPalTransaction = (
  price: number, transactionId: string, reservations: Reservation[],
  onComplete: OnCompleteCallback<Reservation[]>,
): void => {
  const user = auth().currentUser;
  if (user) {
    const payPalTransaction: Transaction = {
      amount: price,
      mode: "paypal",
      receiver: "safari_xperts",
      sender: user.uid,
      status: "successful",
      timestamp: Date.now(),
      transactionId,
    };

    const ref = database()
      .ref(`transaction/${
        createTransactionId(
          payPalTransaction.sender,
          payPalTransaction.receiver, payPalTransaction.timestamp,
        )
      }`);
    
    ref.set(payPalTransaction);

    postReservations(reservations, onComplete);
  } else {
    const userNotFoundError = new Error("User not found Error");
    onComplete(userNotFoundError, null);
  }
};

const createTransactionId = (
  sender: string, receiver: string, timestamp: number,
): string => {
  return `${sender}${receiver}${timestamp}`;
};

// fetches all transactions where this user is the receiver
export const fetchInTransactions = (
  user: string, onComplete: OnCompleteCallback<Transaction[]>,
): void => {
  const inTransactionsRef = database()
    .ref("transaction")
    .equalTo(user, "receiver");
  
  inTransactionsRef.once(
    "value",
    (snapshot) => {
      const transactions: Transaction[] = snapshot.val() as Transaction[];

      onComplete(null, transactions);
    },
    (err) => {
      onComplete(err, null);
    },
  );

};
