import {Tabs} from "antd";
import {FC, useContext, useEffect, useState} from "react";
import {RouteComponentProps, useHistory} from "react-router";
import {RiClipboardLine, RiPriceTag3Line} from "react-icons/ri";
import {BiCalendarAlt, BiPlus} from "react-icons/bi";

import {
  Car,
  fetchAdminProperty,
  Property,
  ReservationCategory,
  updatePropertyOffers,
} from "../../../../api";
import ActivityIndicator from "../../../../components/loading";

import {OverlayProvider} from "../../../search-property/context";

import {PartnerHeader} from "../../components";

import {SearchColumn} from "./search-column";
import {CalendarColumn} from "./calendar-column";

type PriceManagerProps = RouteComponentProps<{
  id: string;
  category: ReservationCategory;
}>;

export type EditableOffer = Record<string, unknown> & {
  idx: number;
};

export const PriceManager: FC<PriceManagerProps> = ({ match }) => {
  const history = useHistory();

  const {
    setIsCancellable, setIsOverlay, setOnOverlay,
  } = useContext(OverlayProvider);

  const propertyId = match.params.id;
  const category = match.params.category;

  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [property, setProperty] = useState<Record<string, unknown> | null>(
    null
  );
  const [offers, setOffers] = useState<EditableOffer[]>([]);

  useEffect(() => {
    setIsFetching(true);

    fetchAdminProperty(category, propertyId, (err, fetchedProperty) => {
      if (err) {
        console.error(err);

        return;
      }

      setIsFetching(false);
      if (fetchedProperty) {
        setProperty(fetchedProperty);
        setOffers(
          (fetchedProperty as Property | Car).offers.map((offer, idx) => ({
            ...offer,
            idx,
          }))
        );
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const addToCalendar = (newOffer: Record<string, unknown>) => {
    // added to the last index
    const newOffers: EditableOffer[] = [
      ...offers,
      {...newOffer, idx: offers.length},
    ];

    setOffers(newOffers);
  };

  const removeFromCalendar = (offerToBeDeleted: EditableOffer) => {
    const newOffers = [...offers];
    newOffers.splice(offerToBeDeleted.idx, 1);

    // update the indexes to enable future update and deletion
    const editableOffers = newOffers.map((offer, idx) => ({...offer, idx}));

    setOffers(editableOffers);
  };

  const editOffer = (editedOffer: EditableOffer) => {
    const newOffers = [...offers];
    newOffers[editedOffer.idx] = editedOffer;

    setOffers(newOffers);
  };

  return (
    <div>
      <PartnerHeader
        HeaderIcon={RiPriceTag3Line}
        title="Manage property price"
        actions={[
          {
            ActionIcon: RiClipboardLine,
            alwaysVisible: true,
            label: "Update price offers",
            onClick: () => {
              // updateS
              setOnOverlay && setOnOverlay(() => () => (
                <ActivityIndicator caption="" />
              ));

              setIsCancellable && setIsCancellable(false);
              setIsOverlay && setIsOverlay(true);

              // before we update the offers, we need to first remove the idx
              // key value to make sure that it does not interfere with the
              // property manipulations later on
              // NOTE: To enable deletion, the offer.idx parameter must be
              // optional, thus we typecase the EditableOffer[] to
              // Partial<EditableOffer>[] since making the idx parameter
              // optional from the start would lead to a lot of unecessary code
              // to please TS compiler even though we know that the idx varaible
              // is there throughout
              (offers as Partial<EditableOffer>[])
                .forEach((offer) => delete offer.idx);

              updatePropertyOffers(category, propertyId, offers,
                (err) => {
                  setOnOverlay && setOnOverlay(null);

                  setIsCancellable && setIsCancellable(true);
                  setIsOverlay && setIsOverlay(false);

                  if (err) {
                    console.error(err);

                    return;
                  }

                  history.push(`/property-owner/view/${category}/${propertyId}`);
                })
            },
            type: "button",
          },
        ]}
      />
      {isFetching && <ActivityIndicator caption="" />}
      {property && (
        <>
          <Tabs className="sm:hidden" centered>
            <Tabs.TabPane
              key="1"
              tab={
                <span className="flex flex-row items-center">
                  <BiPlus className="mr-2" />
                  Create
                </span>
              }
            >
              <SearchColumn
                addToCalendar={addToCalendar}
                category={category}
                property={property}
                propertyId={propertyId}
                offers={offers}
                editOffer={editOffer}
                removeFromCalendar={removeFromCalendar}
              />
            </Tabs.TabPane>

            <Tabs.TabPane
              key="2"
              tab={
                <span className="flex flex-row items-center">
                  <BiCalendarAlt className="mr-2" />
                  Calendar
                </span>
              }
            >
              <CalendarColumn
                category={category}
                offers={offers}
                property={property}
              />
            </Tabs.TabPane>
          </Tabs>
          <div className="hidden sm:flex sm:flex-row relative">
            <SearchColumn
              addToCalendar={addToCalendar}
              category={category}
              property={property}
              propertyId={propertyId}
              offers={offers}
              editOffer={editOffer}
              removeFromCalendar={removeFromCalendar}
            />
            <CalendarColumn
              category={category}
              offers={offers}
              property={property}
            />
          </div>
        </>
      )}
    </div>
  );
};
