import {firestore, User, auth} from 'firebase';
import {ApiFunc, Coordinates, OnCompleteCallback} from '..';
import {uploadPictures} from '../cdn';
import {getUser, NoUserFoundError} from '../user';
import type {Car} from './types';
export type {Car} from './types';

/**
 * This function fetched the car details from the server based on the id passed
 * on to it and passes back the data and any error back to the component that
 * called it.
 *
 * @param id this is the unique identifier of the car that is being fetched.
 * @param onFetch this is the callback executed being passed any data and error
 * that might have occured as a result of the fetch.
 */
export const fetchCar = async (
  id: string, onFetch: (err: Error | null, data: Car | null) => void,
): Promise<void> => {
  console.log(`Fetching car with id: ${id}`);

  try {
    const val = await firestore().collection("car").doc(id).get();
    const data = ({id: val.id, ...val.data()} as Car);

    onFetch(null, data);
  } catch (err) {
    const fetchError = new Error("An error occured while fetching");
    onFetch(fetchError, null);
  }
};

export const fetchCars: ApiFunc<Car[]> = async (onComplete) => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);
    return;
  }

  try {
    const snapshot = await firestore()
      .collection("car")
      .where("owner", "==", user.uid)
      .get();
    const docs = snapshot.docs;
    const cars: Car[] = [];
    
    docs.forEach((doc) => {
      cars.push({id: doc.id, ...doc.data()} as Car);
    });
  } catch (err) {
    onComplete(
      new Error("An error occured while fetching all the cars"),
      null,
    );
  }
  onComplete(null, []);
}

export const filterCars = async (
  location: string, coords: Coordinates, checkin: number,
  onComplete: OnCompleteCallback<Car[]>,
): Promise<void> => {
  try {
    const response = await fetch(
      "https://us-central1-safarixpertz.cloudfunctions.net/fetch_cars",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify({
          location,
          coords,
          checkin,
        }),
      },
    );

    if (response.status === 200) {
      const data = (await response.json()) as Car[];

      onComplete(null, data);
    } else {
      const fetchError = new Error("Error fetching cars with filter");

      onComplete(fetchError, null);
    }
  } catch (err) {
    const fetchError = new Error("Error occured fetching results");
    onComplete(fetchError, null);
  }
};

export const postCar = async (
  car: Car, onComplete: OnCompleteCallback<Car>,
): Promise<void> => {
  /** function executed once we are sure that there is a user present */
  const user = getUser();

  const addNewCar = (user: User) => {
    const picturesToUpload = car.gallery;
    uploadPictures("car", picturesToUpload, async (err, uploadedPictures) => {
      if (err) {
        onComplete(err, null);
        return;
      }
      if (uploadedPictures) {
        car.gallery = uploadedPictures;
    
        // upload the car details since the document has been successfully added.
    
        try {
          const val = await firestore().collection("car")
            .add({...car, owner: user.uid} as Car);
          
          // get a reference to the data that has been posted about the car back to
          // the program after successful addition
          const doc = await val.get();
          onComplete(null, ({id: doc.id, ...doc.data()} as Car));
        } catch (err) {
          onComplete(
            new Error("An error occured whil uploading the document"),
            null
          );
        }
      } else {
        const pictureUploadError = new Error("Pictures obj returned as null");
        onComplete(pictureUploadError, null);
      }
    });
  };

  if (user !== null) {
    addNewCar(user);
  } else {
    auth().onAuthStateChanged((user) => {
      if(user) {
        addNewCar(user);
      } else {
        onComplete(new NoUserFoundError(), null);
      }
    });
  }
};

export const updateCar = async (
  carId: string, car: Car, onComplete: OnCompleteCallback<Car>,
): Promise<void> => {
  /** function executed once we are sure that there is a user present */
  const user = getUser();

  const addNewCar = (user: User) => {
    const picturesToUpload = car.gallery;
    uploadPictures("car", picturesToUpload, async (err, uploadedPictures) => {
      if (err) {
        onComplete(err, null);
        return;
      }
      if (uploadedPictures) {
        car.gallery = uploadedPictures;
    
        // upload the car details since the document has been successfully added.
    
        try {
          await firestore().collection("car")
            .doc(carId)
            .update({...car} as Car);
          
          // get a reference to the data that has been posted about the car back to
          // the program after successful addition
          const docSnapshot = firestore().collection("car").doc(carId);
          const doc = await docSnapshot.get();
          onComplete(null, ({id: doc.id, ...doc.data()} as Car));
        } catch (err) {
          onComplete(
            new Error("An error occured whil uploading the document"),
            null
          );
        }
      } else {
        const pictureUploadError = new Error("Pictures obj returned as null");
        onComplete(pictureUploadError, null);
      }
    });
  };

  if (user !== null) {
    addNewCar(user);
  } else {
    auth().onAuthStateChanged((user) => {
      if(user) {
        addNewCar(user);
      } else {
        onComplete(new NoUserFoundError(), null);
      }
    });
  }
};
