import {FC, useState} from 'react';
import {FaTimes} from 'react-icons/fa';
import {Select} from 'antd';

import {PopulatedReservation} from '../../../../api/reservation/types';

import {ExternalReservationCard} from './external-reservation-card';
import {ReservationCard} from './reservation-card';
import { Car, Conference, Eatery, Hotel, ReservationCategory } from '../../../../api';

type ReservationOverlayProps = {
  reservations: PopulatedReservation[];
  removeOverlay: () => void;
};

export const ReservationOverlay: FC<ReservationOverlayProps> = ({
  reservations, removeOverlay,
}) => {
  const [viewable, setViewable] = useState<
    "external" | "pending" | "cancelled" | "complete" | "all"
  >("all");

  return (
    <div className="bg-white absolute top-0 right-0 w-full h-full sm:max-w-lg overflow-y-scroll">
      {/** APP BAR **/}
      <div
        className={
          "sticky top-0 h-14 bg-safari-gold text-white shadow-md " +
          "flex flex-row justify-between items-center px-4"
        }
      >
        <div className="flex flex-row items-center">
          <FaTimes size={24} onClick={removeOverlay} />
          <h6 className="text-xl font-semibold ml-3 text-white">Reservation</h6>
        </div>
        <Select
          defaultValue={viewable}
          onChange={(value) => {
            console.log(value);
            if (value === "all") {
              setViewable("all");

              return;
            }

            setViewable(value);
          }}
        >
          <Select.Option value="all">All Reservations</Select.Option>
          <Select.Option value="pending">Pending Reservations</Select.Option>
          <Select.Option value="complete">Complete Reservations</Select.Option>
          <Select.Option value="cancelled">
            Cancelled Reservations
          </Select.Option>
          <Select.Option value="external">External Reservations</Select.Option>
        </Select>
      </div>
      {/** End of App Bar **/}
      <div className="px-4 py-2">
        {reservations
          .filter((reservation) =>
            viewable === "all" ? true : reservation.status === viewable
          )
          .map((reservation) =>
            reservation.status !== "external" ? (
              <ReservationCard key={reservation.id} reservation={reservation} />
            ) : (
              <ExternalReservationCard key={reservation.id} reservation={reservation} />
            )
          )}
      </div>
    </div>
  );
};

type ReservationRowProps = {
  title: string,
  description: string;
};

export const ReservationRow: FC<ReservationRowProps> = ({
  title, description,
}) => {
  return (
    <div className="grid grid-cols-2 border-b py-2">
      <h6 className="place-self-center">{title}</h6>
      <p className="place-self-center">{description}</p>
    </div>
  );
};

export const getReservedPropertyTitle = (
  category: ReservationCategory, populatedReservation: Record<string, unknown>,
): string => {
  if (category === "hotel" || category === "conference" || category === "eatery") {
    const [, subPropertyId] = (populatedReservation as PopulatedReservation)
      .property_id
      .split(":");
    if (category === "hotel") {
      // since each hotel has a unique id, it is safe to assume that this filter
      // method will yield a tuple with only one member, which is the required
      // sub-property
      const [subProp] = (populatedReservation as PopulatedReservation<Hotel>)
        .property
        .rooms
        .filter((room) => (room.id === subPropertyId));
      
      return subProp.title;
    } else if (category === "conference") {
      // since each hotel has a unique id, it is safe to assume that this filter
      // method will yield a tuple with only one member, which is the required
      // sub-property
      const [subProp] = (populatedReservation as PopulatedReservation<Conference>)
        .property
        .rooms
        .filter((room) => (room.id === subPropertyId));
      
      return subProp.title;
    } else {
      const [subProp] = (populatedReservation as PopulatedReservation<Eatery>)
        .property
        .table
        .filter((t) => (t.id === subPropertyId));
      
      return subProp.title;
    }
  } else {
    if (category === "car") {
      return (populatedReservation as PopulatedReservation<Car>).property.reg_no;
    }

    return (populatedReservation as PopulatedReservation).property.title;
  }
}
