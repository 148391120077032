import { FC } from "react";
import { FaBan, FaExclamationCircle } from "react-icons/fa";
import { getReservedPropertyTitle } from "..";
import { Car, Property } from "../../../../../api";

import {
  PopulatedActivityReservation,
  PopulatedCarReservation,
  PopulatedConferenceReservation,
  PopulatedEateryReservation,
  PopulatedHotelReservation,
  PopulatedRentalReservation,
  PopulatedReservation,
  PopulatedTourReservation,
} from "../../../../../api/reservation/types";

import { ActivityReservationRows } from "./activity";
import { CarResevationRows } from "./car";
import { ConferenceReservationRows } from "./conference";
import { EateryReservationRows } from "./eatery";
import { HotelReservationRows } from "./hotel";
import { RentalReservationRows } from "./rental";
import { TourReservationRows } from "./tour";

type ReservationCardProps = {
  reservation: Record<string, unknown>;
};

export const ExternalReservationCard: FC<ReservationCardProps> = ({
  reservation,
}) => {
  const { id, category } = reservation as PopulatedReservation;

  return (
    <div key={`${id}`} className="border rounded-md p-2 my-3">
      <div className="py-2 px-4 border-b flex flex-row items-center">
        <FaBan size={24} className="text-red-700" />
        <div className="ml-3">
          <h6 className="text-xl font-semibold">
            {getReservedPropertyTitle(category, reservation)}
          </h6>
          <p className="text-xs opacity-70">
            {category === "car"
              ? `${(reservation.property as Car).reg_no}`
              : `${(reservation.property as Property).title}`}
          </p>
        </div>
      </div>

      {category === "activity" && (
        <ActivityReservationRows
          reservation={reservation as PopulatedActivityReservation}
        />
      )}

      {category === "car" && (
        <CarResevationRows
          reservation={reservation as PopulatedCarReservation}
        />
      )}

      {category === "conference" && (
        <ConferenceReservationRows
          reservation={reservation as PopulatedConferenceReservation}
        />
      )}

      {category === "eatery" && (
        <EateryReservationRows
          reservation={reservation as PopulatedEateryReservation}
        />
      )}

      {category === "hotel" && (
        <HotelReservationRows
          reservation={reservation as PopulatedHotelReservation}
        />
      )}

      {category === "tour" && (
        <TourReservationRows
          reservation={reservation as PopulatedTourReservation}
        />
      )}

      {category === "rental" && (
        <RentalReservationRows
          reservation={reservation as PopulatedRentalReservation}
        />
      )}

      <span className="flex flex-row items-center text-red-600">
        <FaExclamationCircle size={11} />
        <p className="ml-2 text xs text-red-600">
          Reservation made outside system
        </p>
      </span>
    </div>
  );
};
