import type {FC} from 'react';
import { ReservationCategory } from '../../../../api';

import {PriceChange} from "../../../../api/hotel/Offer";
import { getPropertyTitle } from '../../components/seasonal-price-change';

type OfferDateCardProps = {
  offer: PriceChange;
  category: ReservationCategory;
  property: Record<string, unknown>;
};

export const OfferDateCard: FC<OfferDateCardProps> = ({
  offer,
  category, 
  property,
}) => {
  return (
    <li className="flex flex-row">
      {offer.type === "discount" && (
        <div className="w-3 items-stretch flex-grow bg-green-400 mr-2"></div>
      )}
      {offer.type === "hike" && (
        <div className="w-3 items-stretch flex-grow bg-red-600 mr-2"></div>
      )}
      {`${
        offer.type === "discount" ?
          "Discount " :
          "Hike "
      } on property: ${getPropertyTitle(category, offer.property_id, property)}`}
    </li>
  );
};

