import {Popconfirm, Statistic, Tag, Timeline} from "antd";
import {FC, useContext, useEffect, useState} from "react";
import {FaArrowDown, FaArrowUp} from "react-icons/fa";

import {
  Car, Conference, Hotel, Eatery, Property, ReservationCategory,
} from "../../../../api";
import {ConferenceRoom} from "../../../../api/conference/types";
import {Table} from "../../../../api/eatery/types";
import {PriceChange} from "../../../../api/hotel/Offer";
import {HotelRoom} from "../../../../api/hotel/types";
import {Button} from "../../../../components";
import {OverlayProvider} from "../../../../context";
import {DateUtil} from "../../../../util";
import {getOfferPrice, getPropertyPrice} from "../../../../util/price-getter";

import {
  EditSeasonalPriceChange,
} from "../../components/edit-seasonal-price-change";

import {EditableOffer} from ".";

type CreatedOfferProps = {
  category: ReservationCategory;
  offer: PriceChange & EditableOffer;
  property: Property | Car;
  editOffer: (editedOffer: EditableOffer) => void;
  removeFromCalendar: (offerToBeDeleted: EditableOffer) => void;
};

export const CreatedOffer: FC<CreatedOfferProps> = ({
  category, offer, property, editOffer, removeFromCalendar,
}) => {
  const {
    setIsOverlay, setOnOverlay,
  } = useContext(OverlayProvider);
  // if the room has bookable sub-properties, we will use tags to make sure that
  // the user can see which subproperty prices have been directly affected by
  // the price change
  // NOTE: This only happens for Conference, hotel and eatery
  // we use a stateful componet to store the tags to make sure that they are not
  // re-rendered on every instance
  const [tags, setTags] = useState<string[]>([]);

  useEffect(() => {
    const subProperties: Array<Table | HotelRoom | ConferenceRoom> = [];

    // take all the other elements in the array since they are the
    // affected subproperties
    const [, ...affectedPropertyIds] = offer.property_id.split(":");

    if (category === "conference") {
      subProperties.push(...(property as unknown as Conference).rooms);
    } else if (category === "eatery") {
      subProperties.push(...(property as unknown as Eatery).table);
    } else if (category === "hotel") {
      subProperties.push(...(property as unknown as Hotel).rooms);
    }

    setTags(
      subProperties
        .filter((sub) => {
          // we run the predicate below to get only the subProperties whose
          // price is affected by the price change by checking for the property
          // ids in the affectedProperties array (which contains all the
          // affected ids)
          return affectedPropertyIds.indexOf(`${sub.id}`) !== -1;
        })
        // map out the titles of all the affected subProperties
        .map((sub) => sub.title)
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const percentageDiff = Number(`${(
    (getOfferPrice(category, offer) / getPropertyPrice(category, property)) *
    100 - 100
  ).toFixed(2)}`);

  return (
    <div className="w-full border px-4 py-2 my-2 rounded-md">
      <Statistic
        title={`price ${offer.type}`}
        value={offer.type === "hike" ? percentageDiff : 100 - percentageDiff}
        prefix={
          offer.type === "hike" ? (
            <FaArrowUp color="red" />
          ) : (
            <FaArrowDown color="green" />
          )
        }
        suffix="%"
      />
      <div className="font-lato font-semibold mb-1 opacity-70">
        Dates affected
      </div>
      <Timeline mode="left" className="">
        <Timeline.Item
          color="green"
          label={
            DateUtil
              .changeDateFormatFromDate(new Date(offer.startDate), "YYYY-MM-DD")
          }
        >
          Start
        </Timeline.Item>
        <Timeline.Item
          color="red"
          label={
            DateUtil
              .changeDateFormatFromDate(new Date(offer.endDate), "YYYY-MM-DD")
          }
        >
          End
        </Timeline.Item>
      </Timeline>

      {tags.length > 0 && (
        <div className="-mt-10">
          <h6 className="font-lato font-semibold opacity-70">
            Sub propreties affected
          </h6>
          <div className="flex flex-row flex-wrap justify-between">
            {/* Map through all the affected propery */}
            {tags.map((tag, idx) => (
              <Tag
                key={`affected_sub_${idx}`}
                className="my-2 text-safari-gold border-safari-gold"
                color="orange"
              >
                {tag}
              </Tag>
            ))}
          </div>
        </div>
      )}

      {/*
        In effort to reuse this component for all categories, we need to check
        whether the tag length is more that one, so that we can know the margin
        that is to be set as a result to account for the excess padding made
        by the Timeline component
      */}
      <div
        className={
          `flex flex-row justify-end ${
            tags.length > 0 ? "mt-1" : "-mt-9"
          } border-t`
        }
      >
        <Popconfirm
          title="This action cannot be undone. Proceed?"
          onConfirm={() => removeFromCalendar(offer)}
          onCancel={() => console.log("Opted out")}
          okText="Delete Offer"
          cancelText="Cancel"
          okButtonProps={{danger: true}}
        >
          <Button
            type="button"
            mode="outlined"
            className="mr-3 text-error"
          >
            Delete
          </Button>
        </Popconfirm>
        <Button
          type="button"
          mode="outlined"
          onClick={() => {
            setOnOverlay && setOnOverlay(() => () => (
              <EditSeasonalPriceChange
                category={category}
                editOffer={editOffer}
                offer={offer}
                property={property}
                propertyId={`${property.id}`}
                removeOverlay={() => {
                  setIsOverlay && setIsOverlay(false);
                  setOnOverlay(null);
                }}
              />
            ));

            setIsOverlay && setIsOverlay(true);
          }}
        >
          Edit
        </Button>
      </div>
    </div>
  );
};
