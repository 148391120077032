import {firestore} from "firebase";

import {OnCompleteCallback} from "..";
import {getUser, NoUserFoundError} from "../user";

import {Notification} from "./types";

export const fetchNotifications = async (
  onComplete: OnCompleteCallback<Notification[]>,
): Promise<void> => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);

    return;
  }

  try {
    const notificationSnapshot = await firestore()
      .collection(`notification`)
      .where("recepient", "==", user.uid)
      .get();

    const notifications: Notification[] = notificationSnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    } as Notification));

    onComplete(null, notifications);
  } catch (err) {
    console.log(err);

    onComplete(
      new Error("Unable to fetch user notifications"),
      null,
    );
  }
};

export const markNotificationAsRead = async (
  notificationId: string,
): Promise<boolean> => {
  const user = getUser();

  if (!user) {
    console.error(new NoUserFoundError());

    return false;
  }
  try {
    const notificationRef = firestore()
      .collection(`notification`)
      .doc(notificationId);
    
    await notificationRef.update({read: true} as Partial<Notification>)

    return true;
  } catch(err) {
    console.error(err);

    return false;
  }
};

export const fetchAdminNotifications = async (
  onComplete: OnCompleteCallback<Notification[]>,
): Promise<void> => {
  try {
    const notificationSnapshot = await firestore()
      .collection(`notification`)
      .where("recepient", "==", "admin")
      .get();
    const notifications: Notification[] = notificationSnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    } as Notification));

    onComplete(null, notifications);
  } catch (err) {
    console.log(err);

    onComplete(
      new Error("Unable to fetch user notifications"),
      null,
    );
  }
};
