import {createContext} from 'react';
import { Reservation } from '../api/reservation/types';

type ReservationManagerProps = {
  reservations: Reservation[];
  addReservation?: (reservation: Reservation) => void;
  removeReservation?: () => void;
};

export const ReservationManager = createContext<ReservationManagerProps>({
  reservations: [],
});
