// @ts-check

/**
 * This card is re-used for the trip planner section to display items in the
 * itinerary.
 *
 * @param {{
 * overline: string,
 * title: string,
 * subtitle: string,
 * description: string,
 * price: number,
 * action: {
 *  label: string,
 *  onClick: import('react').MouseEventHandler<HTMLSpanElement>,
 * },
 * cover: string,
 * }} props
 *
 * @returns {JSX.Element}
 */
export function TripPlannerCard({
  cover, overline, title, subtitle, description, price, action: {label, onClick},
}) {
  return (
    <div className="shadow-md p-4 rounded-lg">
      <div className="flex flex-row sm:flex-row-reverse justify-between">
        <img
          className="h-20 w-20 sm:h-36 sm:w-36 mr-4 sm:ml-4 sm:mr-0"
          src={cover}
        />
        <div>
          <div>
            <p className="text-xs font-bold">{overline}</p>
            <h6 className="text-xl font-semibold text-blue-600">
              {title}
            </h6>
          </div>
          <div className="mt-1 sm:mt-3">
            <p className="text-base font-semibold">{subtitle}</p>
            <p className="text-sm font-semibold">{description}</p>
          </div>
        </div>
      </div>
      <hr className="mt-2" />
      <div className="flex flex-row justify-between items-end">
        <h6 className="text-3xl font-bold">{`${price} USD`}</h6>
        <span
          onClick={onClick}
          className={
            "mt-4 px-4 rounded text-sm capitalize h-9 flex items-center " +
            "justify-center bg-red-600 text-white cursor-pointer " +
            "max-w-md self-center md:self-end shadow-md"
          }
        >
          {label}
        </span>
      </div>
    </div>
  );
}
