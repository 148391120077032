import {
  Coordinates, Facility, Feature, ItemException, Picture, Policy, RefundType,
  ReservationType,
} from "../../../../../api";
import {TourStop} from "../../../../../api/tour/types";

type TourForm = {
  title: string,
  location: string,
  coords: Coordinates,
  destination: string,
  origin: string,
  description: string,
  short_description: string,
  reservation_policy: ReservationType,
  refund_policy: RefundType,
  adult_price: string,
  children_price: string,
  features: Feature[],
  facilities: Facility[],
  gallery: Picture[],
  stops: TourStop[],
};

type TourFormErrors = {
  title?: string,
  location?: string,
  coords?: Coordinates,
  destination?: string,
  origin?: string,
  description?: string,
  short_description?: string,
  reservation_policy?: string,
  refund_policy?: string,
  adult_price?: string,
  children_price?: string,
  features?: string,
  facilities?: string,
  gallery?: string,
  stops?: string,
};

export const validateTourForm = (form: TourForm): TourFormErrors => {
  const errors: TourFormErrors = {};

  if (form.title === "") {
    errors.title = "Field cannot be empty";
  }

  if (form.description === "") {
    errors.description = "Field cannot be empty";
  }

  if (form.short_description === "") {
    errors.short_description = "Field cannot be empty";
  }

  if (form.coords.lat === 0 && form.coords.lng === 0) {
    errors.location = "Make sure to select location from dropdown suggestions";
  }

  if (form.location === "") {
    errors.location = "This field cannot be empty";
  }

  if (form.gallery.length < 6) {
    errors.gallery = "Total number of images for this tour should be at least 6";
  }

  if (form.stops.length < 1) {
    errors.stops = "There must be at least one stop for the tour";
  }

  if (form.adult_price === "" || isNaN(Number(form.adult_price))) {
    errors.adult_price = "This field must contain a valid number";
  }

  if (form.children_price === "" || isNaN(Number(form.children_price))) {
    errors.children_price = "This field must contain a valid number";
  }

  return errors;
};

type TourOverlayForm = {
  title: string,
  location: string,
  coords: Coordinates,
  description: string,
  short_description: string,
  days: string,
  hours: string,
  mins: string,
  end_days: string,
  end_hours: string,
  end_mins: string,
  gallery: Picture[],
  facilities: Facility[],
  features: Feature[],
  policies: Policy[],
  exceptions: ItemException[],
};

type TourOverlayFormErrors = {
  title?: string,
  location?: string,
  description?: string,
  short_description?: string,
  days?: string,
  hours?: string,
  mins?: string,
  end_days?: string,
  end_hours?: string,
  end_mins?: string,
  gallery?: string,
  facilities?: string,
  features?: string,
  policies?: string,
  exceptions?: string,
};

export const validateTourOverlayForm = (
  form: TourOverlayForm,
): TourOverlayFormErrors => {
  const errors: TourOverlayFormErrors = {};

  // title
  if (form.title === "") {
    errors.title = "This field cannot be empty";
  }

  if (form.description === "") {
    errors.description = "This field cannot be empty";
  }

  if (form.short_description === "") {
    errors.short_description = "This field cannot be empty";
  }

  if (form.coords.lat === 0 && form.coords.lng === 0) {
    errors.location = "Make sure to select location from dropdown suggestions";
  }

  if (form.location === "") {
    errors.location = "This field cannot be empty";
  }

  if (form.hours === "" || isNaN(Number(form.hours))) {
    errors.hours = "Value is not a number";
  }

  if (form.days === "" || isNaN(Number(form.days))) {
    errors.days = "Value is not a number";
  }

  if (form.mins === "" || isNaN(Number(form.mins))) {
    errors.mins = "Value is not a number";
  }

  if (form.end_hours === "" || isNaN(Number(form.end_hours))) {
    errors.end_hours = "Value is not a number";
  }

  if (form.end_days === "" || isNaN(Number(form.end_days))) {
    errors.end_days = "Value is not a number";
  }

  if (form.end_mins === "" || isNaN(Number(form.end_mins))) {
    errors.end_mins = "Value is not a number";
  }

  if (form.gallery.length < 1) {
    errors.gallery = "Must contain at least one image";
  }

  if (form.features.length < 1) {
    errors.features = "Must contain at least one feature";
  }

  if (form.facilities.length < 1) {
    errors.facilities = "Must contain at least one facility";
  }

  return errors;
};
