import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react'
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {createStore} from "redux";
import {initializeApp} from 'firebase';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.css';
import './style/app.css';
import './style/search-page.css';
import './style/index-page.css';
import './style/index-mobile.css';
import './style/signin-page.css';
import './style/signin-mobile.css';
import './style/trip-planner-page.css';
import './style/trip-planner-mobile.css';
import './style/view-property.css';
import './style/search-mobile.css';
import './style/admin-dashboard.css';
import './style/admin-dashboard-mobile.css';
import './style/admin-availability.css';
import './style/property.css';
import './style/checkout.css';
import './style/text-area.css';
import './style/text-input.css';
import './style/tailwind.css'; // tailwind styles
import './style/partner.css';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import rootReducer from "./_system/storage/reducer";

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(persistedReducer)
const persistor = persistStore(store)

// firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD0lIdWQgaZzWaYWSt5hFPIa7UuhAqahu0",
  authDomain: "safarixpertz.firebaseapp.com",
  databaseURL: "https://safarixpertz.firebaseio.com",
  projectId: "safarixpertz",
  storageBucket: "safarixpertz.appspot.com",
  messagingSenderId: "707513909085",
  appId: "1:707513909085:web:d776f6278824394c4172b4",
  measurementId: "G-DRMZK74TK5"
};

initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
