import {
  Coordinates, Facility, Feature, Picture, Policy, RefundType, ReservationType,
} from "../../../../../api";
import {ConferenceRoom} from "../../../../../api/conference/types";

type ConferenceForm = {
  title: string;
  description: string;
  short_description: string;
  gallery: Picture[];
  location: string;
  coords: Coordinates;
  reservation_policy: ReservationType;
  refund_policy: RefundType;
  rooms: ConferenceRoom[];
};

type ConferenceFormErrors = {
  title?: string;
  description?: string;
  short_description?: string;
  gallery?: string;
  location?: string;
  coords?: string;
  reservation_policy?: string;
  refund_policy?: string;
  rooms?: string;
};


export const validateConferenceForm = (
  form: ConferenceForm,
): ConferenceFormErrors => {
  const errors: ConferenceFormErrors = {};

  // title
  if (form.title === "") {
    errors.title = "This field cannot be empty";
  }

  // description
  if (form.description === "") {
    errors.description = "The conference description cannot be empty";
  }

  if (form.short_description === "") {
    errors.short_description =
      "The bried conference description facility cannot be empty";
  }

  if (form.coords.lat === 0  && form.coords.lng === 0) {
    errors.location =
      "Make sure to choose the location from the dropdown provided while filling in this field";
  }

  if (form.location === "") {
    errors.location = "The location of the conference facility cannot be empty";
  }

  if (form.gallery.length < 6) {
    errors.gallery =
      "The total number of pictures of this facility has to be more that 6";
  }

  if (form.rooms.length < 1) {
    errors.rooms = "Facility should have at least one room";
  }

  return errors;
};

type ConferenceOverlayForm = {
  title: string;
  description: string;
  short_description: string;
  cardinality: string;
  facilities: Facility[];
  features: Feature[];
  policies: Policy[];
  price_day: string;
  price_hour: string;
  gallery: Picture[];
  capacity: string;
};

type ConferenceFormOverlayErrors = {
  title?: string;
  description?: string;
  short_description?: string;
  cardinality?: string;
  facilities?: string;
  features?: string;
  policies?: string;
  price_day?: string;
  price_hour?: string;
  gallery?: string;
  capacity?: string;
};

export const validateConferenceOverlayForm = (
  form: ConferenceOverlayForm,
): ConferenceFormOverlayErrors => {
  const errors: ConferenceFormOverlayErrors = {};

  if (form.title === "") {
    errors.title = "A conference room should have a name";
  }

  if (form.description === "") {
    errors.description = "This field cannot be empty";
  }

  if (form.short_description === "") {
    errors.short_description = "This field cannot empty";
  }

  if (form.gallery.length < 6) {
    errors.gallery = "This conference room must have at least 6 images of it";
  }

  if (form.price_day === "" || isNaN(Number(form.price_day))) {
    errors.price_day = "This field must contain a valid number";
  }

  if (form.price_hour === "" || isNaN(Number(form.price_hour))) {
    errors.price_hour = "This fiel must contain a valid number";
  }

  if (form.cardinality === "" || isNaN(Number(form.cardinality))) {
    errors.cardinality = "This fiel must contain a valid number";
  }

  if (form.capacity === "" || isNaN(Number(form.capacity))) {
    errors.capacity = "This field must contain a valid number";
  }

  if (form.facilities.length < 1) {
    errors.facilities = "This room must contain at least one facility";
  }

  if (form.features.length < 1) {
    errors.features = "This room must contain at least one feature";
  }

  return errors;
};
