// @ts-check
import {Component} from "react";
import Lottie from "react-lottie";

import animation from "../../../assets/anim/trip-planner-loading.json";

import {getTripData} from "../system/DataFetcher";

import TripSetup from "./session/trip-setup";
import Main from "./session/main";

class App extends Component {
  state = {
    tripSetup: false,
    loading: true,
    tripKey: "",
    visibleScreen: "none",
    trip_data: {},
    //
    itenerary: [
      // {
      //   date: "XX-XX-XXXX",
      //   activities: [
      //     {
      //       title: "Hotel Checkin",
      //       body: "Checkin to Boabab Hotel",
      //       time: "XX:XXhrs",
      //     },
      //     {
      //       title: "Hotel Checkin",
      //       body: "Checkin to Boabab Hotel",
      //       time: "XX:XXhrs",
      //     },
      //   ],
      // },
      // {
      //   date: "XX-XX-XXXX",
      //   activities: [
      //     {
      //       title: "Hotel Checkin",
      //       body: "Checkin to Boabab Hotel",
      //       time: "XX:XXhrs",
      //     },
      //     {
      //       title: "Hotel Checkin",
      //       body: "Checkin to Boabab Hotel",
      //       time: "XX:XXhrs",
      //     },
      //   ],
      // },
    ],
    travel_list: [],
    accomodation_list: [],
    catering_list: [],
    activity_list: [],
    payable_total: 0,
  };

  _setSharedState = (state) => {
    this.setState(state);
    console.log(state);
  };

  /**
   * Extracts the trip key and saves it to the component state.
   */
  _extractTripKey = () => {
    const tripKey = window.location.pathname
      .split("trip-planner/")[1]
      .split("/")[0];
    this.setState({ tripKey });

    getTripData(this._setSharedState, tripKey);
  };

  componentDidMount() {
    this._extractTripKey();
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <>
            <div style={{ paddingTop: "15%", paddingBottom: "15%" }}>
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: animation,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                height={270}
                width={270}
              />
              <h2 style={{ textAlign: "center" }}>Trip Planner</h2>
              <p style={{ textAlign: "center" }}>
                Loading your trip session...
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="planner-parent">
              <div className="planner-wrapper">
                {!this.state.tripSetup && (
                  <TripSetup tripKey={this.state.tripKey} />
                )}
                {this.state.visibleScreen == "main" && (
                  <Main sharedState={this.state} tripKey={this.state.tripKey} />
                )}
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default App;
