import {firestore} from "firebase";

import {OnCompleteCallback, Picture} from "../..";
import {Eatery} from "../../eatery";
import {getUser, NoUserFoundError} from "../../user";
import {uploadPictures} from "../../cdn";

import {Draft} from "..";

export const postEateryDraft = (
  draftId: string | null, eatery: Eatery,
  onComplete: OnCompleteCallback<Draft<Eatery>>,
): void => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);

    return;
  }

  if (draftId) {
    updateEateryDraft(draftId, eatery, onComplete);
    return;
  }

  // we fetch all the pictures from the eatery facility itself, and also all
  // the pictues from the rooms themselves.
  // We then create an array (Buffer) that records how many pictures we are
  // uploading for each, this will be used to get back all the pictures in order
  // of so as to re-order them back to their respective places without a hassle.
  //
  // E.g if we have a eatery facility with 6 pictures of its own and three
  // eatery rooms with pictures of their own, then we can create the buffer
  // as follows:
  // buffer = [6, 6, 7, 8];
  // then an array of pictures to upload is created with a total of
  // (6 + 6 + 7 + 8) images, where:
  // they are ordered based on:
  // eatery facility first, rooms based on the index of the room,
  // As such, these pictures can be gotten back by splicing and then pushing
  // back in order of index starting with the eatery facility as the first
  // index (0)
  const bufferSizes: number[] = [];
  const imageBuffer: Picture[] = [];

  bufferSizes.push(eatery.gallery.length);
  imageBuffer.push(...eatery.gallery);

  // push all the pictures for each menuItems
  eatery.menu.forEach((menuItem) => {
    bufferSizes.push(1);
    imageBuffer.push(menuItem.picture);
  });

  // push all the pictures of the tables
  eatery.table.forEach((tableType) => {
    bufferSizes.push(1);
    imageBuffer.push(tableType.picture);
  });

  // after all the images are pushed we can upload them all and then replace
  // after successful upload
  uploadPictures("eatery", imageBuffer, async (err, uploadedImages) => {
    if (err) {
      onComplete(err, null);
      return;
    }

    if (uploadedImages) {
      eatery.gallery = uploadedImages.splice(0, bufferSizes[0]);

      let menuItemCount = 0;
      let tableCount = 0;
      // starting from the second index, we start assigning the rooms all their
      // images back
      for (let i=1; i<bufferSizes.length; i++) {
        // since menu items only contain one picture (we extract only one pic)
        if (menuItemCount < eatery.menu.length) {
          eatery.menu[menuItemCount].picture = uploadedImages.splice(0, 1)[0];
          menuItemCount++;
          continue;
        }

        // after the menu item count is complete, we need to push to tables
        if (tableCount < eatery.table.length) {
          eatery.table[tableCount].picture = uploadedImages.splice(0, 1)[0];
          tableCount++;
        }
      }

      // after all the images are uploaded we can now get to uploading the
      // confernce data
      try {
        const doc =  await firestore()
          .collection("draft")
          .add({
            draft: eatery,
            uid: user.uid,
            category: "eatery",
          } as Draft<Eatery>);
        
        const docSnapshot = await doc.get();

        onComplete(
          null,
          {
            id: docSnapshot.id,
            ...docSnapshot.data(),
          } as Draft<Eatery>,
        );
      } catch (err) {
        console.error(err);
        const eateryUploadError =
          new Error("Error uploading eatery facility details");
        onComplete(eateryUploadError, null);
      }
      
    } else {
      // these images may be null
      const uploadErr = new Error("The images to be uploaded returned null");
      onComplete(uploadErr, null);
    }
  });
};

export const updateEateryDraft = (
  id: string, eatery: Eatery, onComplete: OnCompleteCallback<Draft<Eatery>>,
): void => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);

    return;
  }

  // we fetch all the pictures from the eatery facility itself, and also all
  // the pictues from the rooms themselves.
  // We then create an array (Buffer) that records how many pictures we are
  // uploading for each, this will be used to get back all the pictures in order
  // of so as to re-order them back to their respective places without a hassle.
  //
  // E.g if we have a eatery facility with 6 pictures of its own and three
  // eatery rooms with pictures of their own, then we can create the buffer
  // as follows:
  // buffer = [6, 6, 7, 8];
  // then an array of pictures to upload is created with a total of
  // (6 + 6 + 7 + 8) images, where:
  // they are ordered based on:
  // eatery facility first, rooms based on the index of the room,
  // As such, these pictures can be gotten back by splicing and then pushing
  // back in order of index starting with the eatery facility as the first
  // index (0)
  const bufferSizes: number[] = [];
  const imageBuffer: Picture[] = [];

  bufferSizes.push(eatery.gallery.length);
  imageBuffer.push(...eatery.gallery);

  // push all the pictures for each menuItems
  eatery.menu.forEach((menuItem) => {
    bufferSizes.push(1);
    imageBuffer.push(menuItem.picture);
  });

  // push all the pictures of the tables
  eatery.table.forEach((tableType) => {
    bufferSizes.push(1);
    imageBuffer.push(tableType.picture);
  });

  // after all the images are pushed we can upload them all and then replace
  // after successful upload
  uploadPictures("eatery", imageBuffer, async (err, uploadedImages) => {
    if (err) {
      onComplete(err, null);
      return;
    }

    if (uploadedImages) {
      eatery.gallery = uploadedImages.splice(0, bufferSizes[0]);

      let menuItemCount = 0;
      let tableCount = 0;
      // starting from the second index, we start assigning the rooms all their
      // images back
      for (let i=1; i<bufferSizes.length; i++) {
        // since menu items only contain one picture (we extract only one pic)
        if (menuItemCount < eatery.menu.length) {
          eatery.menu[menuItemCount].picture = imageBuffer.splice(0, 1)[0];
          menuItemCount++;
          continue;
        }

        // after the menu item count is complete, we need to push to tables
        if (tableCount < eatery.table.length) {
          eatery.table[tableCount].picture = imageBuffer.splice(0, 1)[0];
          tableCount++;
        }
      }

      // after all the images are uploaded we can now get to uploading the
      // confernce data
      try {
        await firestore()
          .collection("draft")
          .doc(id)
          .update({
            draft: eatery,
          } as Draft<Eatery>);
        
        const docSnapshot = await firestore().collection("draft").doc(id).get();

        onComplete(
          null,
          {
            id: docSnapshot.id,
            ...docSnapshot.data(),
          } as Draft<Eatery>,
        );
      } catch (err) {
        const eateryUploadError =
          new Error("Error uploading eatery facility details");
        onComplete(eateryUploadError, null);
      }
      
    } else {
      // these images may be null
      const uploadErr = new Error("The images to be uploaded returned null");
      onComplete(uploadErr, null);
    }
  });
};
