import {database, firestore} from "firebase";

import {OnCompleteCallback} from "..";
import {Activity} from "../activity";
import { AttractionSite } from "../attraction/types";
import {Conference} from "../conference";
import {Eatery} from "../eatery";
import {Hotel} from "../hotel";
import {Rental} from "../rental";
import {Tour} from "../tour";

export type RecommendedProperties = {
  activity: Activity[];
  conference: Conference[];
  eatery: Eatery[];
  hotel: Hotel[];
  rental: Rental[];
  tour: Tour[];
  attraction: AttractionSite[];
};

export type Recommended = {
  [Key in keyof RecommendedProperties]: string[]
};

export const fetchRecommended = async (
  onComplete: OnCompleteCallback<RecommendedProperties>,
): Promise<void> => {
  const recommendedProperties: RecommendedProperties = {
    activity: [],
    conference: [],
    eatery: [],
    hotel: [],
    rental: [],
    tour: [],
    attraction: [],
  };
  console.log("Fetching recommended results");
  const recommendedSnapshot = await database()
    .ref("recommendation")
    .once("value");
  console.log("recommended results", recommendedSnapshot.val());

  const recommended = recommendedSnapshot.val() as Recommended;
  let addedCount = 0;

  const addRecommended = <T extends keyof RecommendedProperties>(
    category: T, properties: RecommendedProperties[T],
  ): void => {
    recommendedProperties[category] = properties;
    addedCount++;

    if (addedCount === Object.keys(recommended).length) {
      onComplete(null, recommendedProperties);
    }
  }
  
  Object.keys(recommended).forEach(async (category) => {
    const properties: unknown[] = [];

    for (let i = 0; i<recommended[category as keyof Recommended].length; i++) {
      try {
        const propertySnapshot = await firestore().collection(category)
          .doc(recommended[category as keyof Recommended][i])
          .get();
  
        properties.push({
          ...propertySnapshot.data(), id: propertySnapshot.id,
        });
      } catch (err) {
        console.error(err);
        break;
      }
    }

    // TODO: I know that this is unecessary and probably impedes performance in
    // the search of correct types, SHOULD PROBABLY BE REFACTORED
    switch (category as keyof Recommended) {
      case "activity":
          addRecommended<"activity">("activity", properties as Activity[]);
        break;
      case "conference":
        addRecommended<"conference">("conference", properties as Conference[]);
        break;
      case "eatery":
        addRecommended<"eatery">("eatery", properties as Eatery[]);
        break;
      case "hotel":
        addRecommended<"hotel">("hotel", properties as Hotel[]);
        break;
      case "rental":
        addRecommended<"rental">("rental", properties as Rental[]);
        break;
      case "tour":
        addRecommended<"tour">("tour", properties as Tour[]);
        break;
      case "attraction":
        addRecommended<"attraction">("attraction", properties as AttractionSite[]);
        break;
      default:
        break;
    }
  });
};
