import {FC, useContext, useEffect, useState} from "react";
import {FaPlus, FaPlusCircle, FaSave} from "react-icons/fa";
import {Formik} from "formik";
import {RouteComponentProps, useHistory} from "react-router-dom";
import {RiApps2Line} from "react-icons/ri";

import {Input, TextArea} from "../../../../../components/input";
import {
  Activity, Coordinates, Facility, FAQ, Feature, Picture, postActivity,
  postActivityDraft, RefundType, removeDraft, ReservationType,
} from "../../../../../api";
import ActivityIndicator from "../../../../../components/loading";
import {fetchDraft} from "../../../../../api/draft";

import {OverlayProvider} from "../../../../search-property/context";

import {
  EditFacilitiesListItem, EditFeaturesListItem, EditRefundPolicyListItem,
  EditReservationPolicyListItem, PartnerHeader, PickImageListItem,
  PropertyLocationAutoComplete,
} from "../../../components";
import {EditFaqListItem} from "../../../components/edit-faq-list-item";
import {UploadingOverlay} from "../../../components/uploading-overlay";

import {validateActivityForm} from "./validate-form";
import { SnackbarProvider } from "../../../../../context/snackbar-provider";
import { ConfirmPropertyLocation } from "../../../components/confirm-property-location";

type EditDraftActivityProps = RouteComponentProps<{draft_id: string}>

type ActivityInitialValues = {
  title: string,
  location: string,
  description: string,
  short_description: string,
  reservation_policy: string,
  refund_policy: string,
  adult_price: string,
  children_price: string,
  features: string,
  facilities: string,
  faqs: string,
  gallery: string,
};

export const EditDraftActivity: FC<EditDraftActivityProps> = ({match}) => {
  const {
    setIsCancellable, setIsOverlay, setOnOverlay,
  } = useContext(OverlayProvider);
  const {setIsVisible, dismissSnackbar} = useContext(SnackbarProvider);

  const [gallery, setGallery] = useState<Picture[]>([]);
  // coordinates are fetched once the partner autofills the location
  const [coords, setCoords] = useState<Coordinates>({ lat: 0, lng: 0 });
  // by default the reservation type should be the user paying the full amount
  const [reservationPolicy, setReservationPolicy] =
    useState<ReservationType>("full_amount");
  // by default the refund policy is to allow for deduction of a cancellation
  // fee
  const [refundPolicy, setRefundPolicy] =
    useState<RefundType>("partial_refund");
  // monitor all the FAQS input by the partner.
  const [faqs, setFaqs] = useState<FAQ[]>([]);
  // Monitor all the facilities that have been input by the partner
  const [facilities, setFacilities] = useState<Facility[]>([]);
  // monitor all the features input by the partner for the property
  const [features, setFeatures] = useState<Feature[]>([]);

  // UI State manager, used to control whether the UI is in loading state or actions
  // are allowed
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const draftId = match.params.draft_id;

  const history = useHistory();

  // to allow easy typing, declared in function scope
  const [initialValues, setInitialValues] =
    useState<null | ActivityInitialValues>(null);
  
  const [isSaving, setIsSaving] = useState<boolean>(false);
  
  useEffect(() => {
    setIsCancellable && setIsCancellable(false);

    setOnOverlay && setOnOverlay(() => () => (
      <ActivityIndicator caption="" />
    ));

    setIsOverlay && setIsOverlay(true);

    fetchDraft(draftId, (err, draft) => {
      if (err) {
        console.error(err);
        // since there has been an error, instead of trying to recover on
        // the client side, push the client to create a new property instead to
        // prevent cases of people without credentials being able to direct
        // to this page.
        history.push("/property-owner/new/activity");
        setIsCancellable && setIsCancellable(true);
        setIsOverlay && setIsOverlay(false);
        setOnOverlay && setOnOverlay(null);
        return;
      }

      if (draft) {
        // set the initial values since the draft exists
        const activity = draft.draft as Activity;

        setInitialValues({
          adult_price: isNaN(Number(activity.price.adult)) ?
            "" :
            `${activity.price.adult}`,
          children_price: isNaN(Number(activity.price.children)) ?
            "" :
            `${activity.price.children}`,
          description: activity.description,
          facilities: "",
          faqs: "",
          features: "",
          gallery: "",
          location: activity.location,
          refund_policy: "",
          reservation_policy: "",
          short_description: activity.short_description,
          title: activity.title,
        });

        setFaqs(activity.faqs);
        setFacilities(activity.facilities);
        setFeatures(activity.features);
        setCoords(activity.coords);
        setGallery(activity.gallery);
        setReservationPolicy(activity.reservation_policy);
        setRefundPolicy(activity.refund_policy);

        // Remove activity indicator
        setIsCancellable && setIsCancellable(true);
        setIsOverlay && setIsOverlay(false);
        setOnOverlay && setOnOverlay(null);

        return;
      } else {
        history.push("/property-owner/new/activity");

        // Remove activity indicator
        setIsCancellable && setIsCancellable(true);
        setIsOverlay && setIsOverlay(false);
        setOnOverlay && setOnOverlay(null);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <OverlayProvider.Consumer>
      {({setIsCancellable, setIsOverlay, setOnOverlay}) => (
        <>
          {isUploading && (
            <UploadingOverlay
              caption="Uploading Activity details..."
              setIsCancellable={setIsCancellable}
              setIsOverlay={setIsOverlay}
              setOnOverlay={setOnOverlay}
            />
          )}
          {initialValues !== null && (
            <Formik
              initialValues={initialValues}
              validateOnMount={true}
              validate={(values) => {
                return validateActivityForm({
                  adult_price: values.adult_price,
                  children_price: values.children_price,
                  coords,
                  description: values.description,
                  facilities,
                  features,
                  faqs,
                  gallery,
                  location: values.location,
                  refund_policy: refundPolicy,
                  reservation_policy: reservationPolicy,
                  short_description: values.short_description,
                  title: values.title,
                })
              }
              }
              onSubmit={(values) => {
                // create the new activity
                setIsUploading(true);
                postActivity(
                  {
                    coords,
                    cover: "",
                    description: values.description,
                    exceptions: [],
                    facilities,
                    features,
                    faqs,
                    gallery,
                    location: values.location,
                    price: {
                      adult: Number(values.adult_price),
                      children: Number(values.children_price),
                    },
                    rating: 10,
                    refund_policy: refundPolicy,
                    reservation_policy: reservationPolicy,
                    reviews: [],
                    short_description: values.short_description,
                    title: values.title,
                    offers: [],
                  },
                  (err, result) => {
                    if (err) {
                      console.error(err);
                      setIsVisible && setIsVisible({
                        fabPresent: false,
                        navRailPresent: true,
                        isError: true,
                        title: "Unable to publish activity details",
                      });
                      return;
                    }
  
                    if (result) {
                      // successful result, revert back to the partner with the new
                      // details
                      // remove the draft because it is no longer needed
                      removeDraft(draftId, () => console.log("Draft removed"));
                      setIsUploading(false);
                      setIsVisible && setIsVisible({
                        fabPresent: false,
                        navRailPresent: true,
                        isError: false,
                        title: `Published ${result.title} details`,
                      });
                      // navigate to all the activities
                      history.replace(`property-owner/new/activty/${result.id}`);
                    } else {
                      setIsVisible && setIsVisible({
                        fabPresent: false,
                        navRailPresent: true,
                        isError: true,
                        title: "Unable to publish activity details",
                      });
                    }
                  }
                );
              }}
            >
              {({ values, errors, handleChange, handleSubmit, handleBlur, setErrors, validateForm }) => (
                <form onSubmit={handleSubmit} onBlur={handleBlur}>
                  <PartnerHeader
                    HeaderIcon={RiApps2Line}
                    title={values.title ? values.title : "New Activity"}
                    actions={[
                      {
                        ActionIcon: FaSave,
                        alwaysVisible: false,
                        label: "Save Draft",
                        onClick: () => {
                          setIsSaving(true);
                          setOnOverlay && setOnOverlay(() => () => (
                            <ActivityIndicator
                              caption=""
                            />
                          ));
                          setIsOverlay && setIsOverlay(true);
                          setIsCancellable && setIsCancellable(false);
                          postActivityDraft(
                            draftId,
                            {
                              coords,
                              cover: "",
                              description: values.description,
                              exceptions: [],
                              facilities,
                              features,
                              faqs,
                              gallery,
                              location: values.location,
                              price: {
                                adult: Number(values.adult_price),
                                children: Number(values.children_price),
                              },
                              rating: 10,
                              refund_policy: refundPolicy,
                              reservation_policy: reservationPolicy,
                              reviews: [],
                              short_description: values.short_description,
                              title: values.title,
                              offers: [],
                            },
                            (err, result) => {
                              setIsSaving(false);
                              setIsOverlay && setIsOverlay(false);
                              setIsCancellable && setIsCancellable(true);
                              setOnOverlay && setOnOverlay(null);

                              if (err) {
                                console.error(err);
                                setIsVisible && setIsVisible({
                                  fabPresent: false,
                                  isError: true,
                                  navRailPresent: true,
                                  title: `Draft not saved`,
                                });
                                return;
                              }
          
                              if (result) {
                                setIsVisible && setIsVisible({
                                  fabPresent: false,
                                  isError: false,
                                  navRailPresent: true,
                                  title: `Draft of ${result.draft.title} saved`,
                                  action: {
                                    label: "DISMISS",
                                    onClick: () => {
                                      dismissSnackbar && dismissSnackbar();
                                    },
                                  },
                                });
                                history.replace("/property-owner/activity");
                              } else {
                                setIsVisible && setIsVisible({
                                  fabPresent: false,
                                  isError: true,
                                  navRailPresent: true,
                                  title: `Draft not saved`,
                                  action: {
                                    label: "DISMISS",
                                    onClick: () => {
                                      dismissSnackbar && dismissSnackbar();
                                    },
                                  },
                                });
                              }
                            }
                          );
                        },
                        type: "button",
                        disabled: isSaving,
                      },
                      {
                        label: "Save",
                        onClick: () => console.log("Saving Activity"),
                        disabled: Object.keys(errors).length > 0,
                        type: "submit",
                        ActionIcon: FaPlus,
                        alwaysVisible: true,
                      },
                    ]}
                  />
                  <div
                    className={
                      "p-4 sm:grid sm:grid-cols-2 gap-4 lg:grid-cols-3 " +
                      "lg:grid-flow-col-dense lg:max-w-5xl"
                    }
                  >
                    {/** Input Title */}
                    <div>
                      <Input
                        helper="This is the name of the place/activity"
                        id="title"
                        name="title"
                        inputLabel="Activity name"
                        onChange={handleChange}
                        value={values.title}
                        error={errors.title}
                      />
                    </div>
                    {/** End of input Title */}

                    {/** Input Title */}
                    <div className="mt-3 sm:mt-0 lg:col-start-1 col-start-2">
                      {/** Text input */}
                      <PropertyLocationAutoComplete
                        name="location"
                        value={values.location}
                        error={errors.location}
                        inputLabel="Property/Activity location"
                        onChange={handleChange}
                        selectAddress={(place, coords) => {
                          setIsCancellable && setIsCancellable(false);
                          // setCoords(coords);
                          setOnOverlay && setOnOverlay(() => () => (
                            <ConfirmPropertyLocation
                              initialCoords={coords}
                              onConfirm={(coords) => {
                                setCoords(coords);
                              }}
                              removeOverlay={() => {
                                setIsOverlay && setIsOverlay(false);
                                setIsCancellable && setIsCancellable(true);
                                setOnOverlay(null);
                              }}
                            />
                          ));
                        }}
                      />
                      {/** End of text input */}
                    </div>
                    {/** End of input Title */}

                    {/** Start of Text Area */}
                    <div className="mt-3 lg:col-start-1 lg:col-end-3">
                      <TextArea
                        id="activity-description"
                        name="description"
                        value={values.description}
                        helper="longform description of property/activity"
                        inputLabel="Property/Activity description(long)"
                        onChange={handleChange}
                        error={errors.description}
                      />
                    </div>
                    {/** End of Text Area */}

                    {/** Start of Text Area */}
                    <div className="mt-3 lg:col-start-1 lg:col-end-3">
                      <TextArea
                        helper="Shorter description of the property/activity"
                        id="activity-description-short"
                        name="short_description"
                        inputLabel="Property/Activity description (short)"
                        value={values.short_description}
                        error={errors.short_description}
                        onChange={handleChange}
                      />
                    </div>
                    {/** End of Text Area */}

                    {/** Reservation policy card */}
                    <EditReservationPolicyListItem
                      reservationPolicy={reservationPolicy}
                      onSave={async (reservationPolicy) => {
                        setReservationPolicy(reservationPolicy);
                        setErrors(await validateForm());
                      }}
                    />
                    {/** End of Reservation policy card */}

                    {/** RefunD policy card */}
                    <EditRefundPolicyListItem
                      refundPolicy={refundPolicy}
                      onSave={async (refundPolicy) => {
                        setRefundPolicy(refundPolicy);
                        setErrors(await validateForm());
                      }}
                    />
                    {/** End of Refund policy card */}

                    {/** Pick images item */}
                    <PickImageListItem
                      gallery={gallery}
                      minImageCount={6}
                      onOverlaySave={async (gallery) => {
                        setGallery(gallery);
                        setErrors(await validateForm());
                      }}
                    />
                    {/* End of Pick image item */}

                    {/** Things liks prices and sub properties are found here */}
                    <hr className="my-4 sm:col-start-1 sm:col-end-3 h-auto lg:hidden" />
                    {/** Special Section */}
                    <div className="shadow-md border sm:col-start-1 sm:col-end-3">
                      {/** Header for price Section */}
                      <div
                        className={
                          "h-14 flex flex-row justify-between items-center px-4 " +
                          "border-t"
                        }
                      >
                        <div>
                          <h6 className="text-xl font-semibold">
                            Price of entrance
                          </h6>
                          <p className="text-xs">
                            Detail the cost of the property/activity per person
                          </p>
                        </div>
                      </div>

                      <div className="flex flex-row flex-wrap justify-between">
                        <div className="w-80 px-4 py-2">
                          {/** Price input container */}
                          <Input
                            id="adult-price"
                            name="adult_price"
                            helper="Price it costs an adult to enter property/activity"
                            inputLabel="Cost per adult (in USD)"
                            value={values.adult_price}
                            error={errors.adult_price}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="w-96 px-4 py-2">
                          {/** Price input container */}
                          <Input
                            id="children-price"
                            name="children_price"
                            helper="Price it costs a child to enter propery/activity"
                            inputLabel="Cost per child (in USD)"
                            value={values.children_price}
                            error={errors.children_price}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    {/** Special Section */}

                    <div className="sm:col-span-2 lg:col-start-1 lg:col-end-4">
                      <h3 className="text-2xl font-semibold sm:col-start-1 sm:col-end-3">
                        Aditional Information
                      </h3>
                      <div className="sm:grid sm:grid-cols-2 sm:gap-4 lg:grid-cols-3">
                        {/** Add FAQs */}
                        <EditFaqListItem
                          faqs={faqs}
                          onSave={async (newFaqs) => {
                            // update the stateful var faqs
                            setFaqs(newFaqs);
                            setErrors(await validateForm());
                          }}
                          error={
                            !faqs.length ? "Must have at least one FAQ" : ""
                          }
                        />
                        {/** End of FAQ */}

                        {/** Add Facilities */}
                        <EditFacilitiesListItem
                          category="activity"
                          facilities={facilities}
                          onSave={async (newFacilities) => {
                            // set the new features
                            setFacilities(newFacilities);
                            setErrors(await validateForm());
                          }}
                          error={
                            !features.length
                              ? "Must have at least one feature"
                              : ""
                          }
                        />
                        {/** End of Add Facilities */}

                        {/** Add Features */}
                        <EditFeaturesListItem
                          category="activity"
                          features={features}
                          onSave={async (newFeatures) => {
                            // save the new features
                            setFeatures(newFeatures);
                            setErrors(await validateForm());
                          }}
                          error={
                            !features.length
                              ? "Must have at least one feature"
                              : ""
                          }
                        />
                        {/** End of features */}

                        {/** Add Features */}
                        <div className="py-2">
                          <div
                            className={
                              "border flex flex-row justify-between shadow-md " +
                              "px-4 py-2 items-center"
                            }
                          >
                            <div>
                              <h6 className="text-xl font-semibold">
                                Accessibiity Features
                              </h6>
                              <p className="text-xs opacity-70">
                                Press to edit the accessible features in the
                                activity
                              </p>
                            </div>
                            {/* <div className="h-6 w-6 bg-indigo-300"></div> */}
                            <FaPlusCircle size={24} color="#FF5900" />
                          </div>
                          <p className="mt-1 text-xs text-red-500 pl-2">
                            Must have at least one feature
                          </p>
                        </div>
                        {/** End of features */}
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          )}
        </>
      )}
    </OverlayProvider.Consumer>
  );
};
