import {FC} from 'react';

import {InputProps} from '.';

export const TextInput: FC<InputProps> = ({
  helper, id, name, inputLabel, error, value, disabled, className,
  onBlur, onChange,
}) => {

  return (
    <div className={className ? className : ""}>
      <div className="text-input-container">
        <input
          autoComplete="off"
          className="text-input"
          id={id}
          name={name}
          placeholder=" "
          value={value}
          onChange={onChange}
          disabled={disabled ? disabled : false}
          onBlur={onBlur}
        />
        <label htmlFor={id} className="text-input-label">{inputLabel}</label>
      </div>
      {error && (
        <p className="text-xs pl-2 text-red-500">{error}</p>
      )}
      {!error && value === "" && (
        <p className="text-xs pl-2">{helper}</p>
      )}
    </div>
  );
};
