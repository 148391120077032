// @ts-check
import {useEffect, useState} from "react";
import {Link} from 'react-router-dom';
import {FaMoneyBill, FaUserAlt} from "react-icons/fa"; // eslint-disable-line no-unused-vars

import MobileHeader from "./MobileHeader";
import { auth } from "firebase";

/**
 * Takes in the origin and the isLoggedIn route in order to makessure that the components
 * showed on the right hand side of the header map adapt accordingly. This is in the sense that:
 * - `origin` determines whether the the `TRIP PLANNER` or `MY TRIPS` button will be shown.
 * - `isLoggedIn` determines whether the `SIGNIN` button will be shown.
 *
 * ### NavBar options
 * #### If a user IS NOT logged in, the navbar has the following options:
 * - `CURRENCY` - allows the user to select their currency since we have no info on them
 * - `SIGNIN` - Gves the user the option of signing in.
 * - if the user is in the `/trip-planner` route:
 *   - `MY TRIPS` - gives a user a glimpse of the previous trips they had planned.
 * - else:
 *   - `TRIP PLANNER` - allows them to go to the trip planner section.
 *
 * #### If a user IS logged in, the following options are available:
 * - if the user is in the `/trip-planner` route:
 *   - `MY TRIPS` - gives a user a glimpse of the previous trips they had planned.
 * - else:
 *   - `TRIP PLANNER` - allows them to go to the trip planner section.
 *
 * @param {{origin: string}} props
 */
const AuthHeaderRoute = ({origin}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    
    auth().onAuthStateChanged((user) => {
      if (user) setIsLoggedIn(true);
    });
  }, []);

  /**
   * Helper function used to scroll to the very bottom of the file to the currency section
   * which is conveniently located at the very bottom of the website page at all times.
   */
  const scrollToBottom = () => {
    window.scrollTo({left: 0, top: document.body.scrollHeight, behavior: "smooth"});
  };

  return (
    <div>
      <div className="flex-div flex-row contact-link-area">
        {/* If a user is logged in, they should be able to access their account from the Header */}
        {isLoggedIn && (
          <Link to="/user">
            <div className="contact-link">MY ACCOUNT</div>
          </Link>
        )}
        {/* If a user is not logged in, they should instead be given the option to in the Header */}
        {!isLoggedIn && (
          <>
            <a onClick={scrollToBottom}>
              <div className="contact-link">
                <FaMoneyBill style={{ marginRight: 7, fontSize: 16 }} />
                <div>CURRENCY</div>
              </div>
            </a>
            <Link to="/signin">
              <div className="contact-link">
                <FaUserAlt style={{ marginRight: 5, fontSize: 16 }} />
                <div>SIGNIN</div>
              </div>
            </Link>
          </>
        )}
        {/* This Next section should be displayed whether or not the user is logged in */}
        {origin == "trip-planner" ? (
          <Link to="/user/my-trips">
            <div className="but but-orange header-but">MY TRIPS</div>
          </Link>
        ) : (
          <Link to="/trip-planner">
            <div className="but but-orange header-but">TRIP PLANNER</div>
          </Link>
        )}
      </div>
    </div>
  );
}

/**
 * This is the dynamic header that will be displayed across the SafariXperts website.
 * This site changes based on the `origin` in that:
 * - if the origin is `/signin`, the signing button will not be displayed.
 * - if the origin is `/trip-planner` the `TRIP PLANNER` button will be replaced with `MY TRIPS`
 *
 * @param {{
 * origin?: string,
 * }} props props passed to the `Header`
 *
 * @returns {JSX.Element}
 */
const Header = ({origin}) => {
  return (
    <>
      <div className="header-background mobile-hidden" />
      <div
        className="flex-div"
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <div
          className="flex-div display-port flex-row mobile-hidden"
          style={{ paddingTop: 12, position: "absolute", top: 0 }}
        >
          <div className="flex-div logo-area">
            <div className="flex-div">
              <Link to="/">
                <p className="logo-text">
                  Safari<span style={{ color: "#e81164" }}>Xperts</span>
                </p>
              </Link>
            </div>
          </div>
          <div className="flex-div spanner" />
          <AuthHeaderRoute origin={origin} />
        </div>
      </div>
      {/* Only displayed on mobile devices */}
      <MobileHeader />
    </>
  );
}

export default Header;
