import {FC} from 'react';
import {FaChevronRight} from 'react-icons/fa';

import {PopulatedAffiliate} from '../../../api/affiliate/types';
import {DateUtil} from '../../../util';

type AffiliatePropertyCardProps = {
  link: PopulatedAffiliate;
  activeProperty: number;
  propertyIdx: number;
  onPropertyFocus: (propertyIdx: number) => void;
};

export const AffiliatePropertyCard: FC<AffiliatePropertyCardProps> = ({
  activeProperty, propertyIdx, link, onPropertyFocus,
}) => {
  const {property} = link;

  return (
    <div
      className={
        "h-36 mt-3 p-4 pr-0 border rounded-2xl flex flex-row items-center " +
        "overflow-hidden shadow-md cursor-pointer " +
        `${
          activeProperty === propertyIdx ?
            "bg-black bg-opacity-10" :
            "bg-white"
        }`
      }
      onClick={() => {
        onPropertyFocus(propertyIdx);
      }}
    >
      <div
        className={
          "bg-indigo-200 w-28 rounded-2xl self-stretch mr-4 " +
          "bg-center bg-no-repeat bg-cover"
        }
        style={{
          backgroundImage: `url(${property.gallery[0].url})`
        }}
      />
      {/* Content container */}
      <div className="flex-1 h-full flex flex-col justify-between overflow-hidden">
        <div className="overflow-hidden">
          <p
            className="text-sm opacity-70 whitespace-nowrap overflow-ellipsis overflow-hidden"
            style={{fontFamily: "Lato"}}
          >
            {`Created on ${DateUtil.getHumanReadableFormat(new Date())}`}
          </p>
          <h6
            className="text-xl font-semibold whitespace-nowrap overflow-ellipsis overflow-hidden"
            style={{fontFamily: "Lato"}}
          >
            {property.title}
          </h6>
        </div>
        <h6
          className="text-3xl font-bold"
          style={{fontFamily: "Lato"}}
        >
          {`${link.impressions} IMP`}
        </h6>
      </div>
      {/* End of content container */}
      <div
        className={
          "flex-grow-0 flex-shrink-0 bg-secondary text-white " +
          "cursor-pointer self-stretch -my-4 flex flex-row justify-center " +
          "px-2"
        }
      >
        <FaChevronRight
          className="self-center justify-self-end"
          size={24}
        />
      </div>
    </div>
  );
};
