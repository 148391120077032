// @ts-check
/* eslint-disable max-len */
import { useState } from "react";
import {FaAngleDown, FaGlobeAfrica} from "react-icons/fa";
import { MobileFilterContext } from "../mobile-filter-context";

////////////////////////////////////////////////////////////////////////////////
// JSDoc Types
////////////////////////////////////////////////////////////////////////////////
/**
 * @typedef {"hotel_review_score" | "hotel_number_of_reviews" | "price" | "hotel_star" | "room_footage" | "distance"} FilterSortFilters
 */

/**
 * 
 * @param {{
 * orderByFilter: (filter: FilterSortFilters, polarity :?any) => void,
 * state: any,
 * updateState: (newState: any) => void,
 * }} props
 * @returns 
 */
export function FilterSort({orderByFilter, state, updateState}) {
  const [activeSort, setActiveSort] = useState("Choose Filter");
  const [activeFilter, setActiveFilter] = useState("Choose Filter");

  return (
    <MobileFilterContext.Consumer>
      {({setVisibleFilter, setNewSearchFilterVisible}) => (
        <div className="desktop-hidden bg-white p-2">
          <div style={{ display: "flex", marginTop: 10 }}>
            <div
              className="filter-sort relative"
              style={{
                border: "1px solid #eee",
                padding: 10,
                marginRight: 10,
                display: "flex",
                alignItems: "center",
                fontSize: 12,
              }}
            >
              {activeFilter}
              <FaAngleDown style={{ fontSize: 19, marginLeft: 5 }} />
              <div
                className={
                  "filter-sort-dropdown absolute top-full left-0 shadow-sm " +
                  " bg-white w-full "
                }
              >
                <div className="flex flex-col">
                  <span
                    className=" text-sm py-1 px-1 border-b-2"
                    onClick={() => {
                      setActiveFilter("Price Range");
                      setVisibleFilter("price")
                      setNewSearchFilterVisible(false);
                    }}
                  >
                    Price Range
                  </span>
                  <span
                    className=" text-sm py-1 px-1 border-b-2"
                    onClick={() => {
                      setActiveFilter("Star Rating");
                      setVisibleFilter("rating");
                      setNewSearchFilterVisible(false);
                    }}
                  >
                    Star Rating
                  </span>
                  <span
                    className=" text-sm py-1 px-1 border-b-2"
                    onClick={() => {
                      setActiveFilter("Facilities");
                      setVisibleFilter("facilities");
                      setNewSearchFilterVisible(false);
                    }}
                  >
                    Facilities
                  </span>
                  <span
                    className=" text-sm py-1 px-1 border-b-2"
                    onClick={() => {
                      setActiveFilter("Facility Features");
                      setVisibleFilter("features");
                      setNewSearchFilterVisible(false);
                    }}
                  >
                    Facility Features
                  </span>
                </div>
              </div>
            </div>
            <div
              className="filter-sort relative"
              style={{
                border: "1px solid #eee",
                padding: 10,
                alignItems: "center",
                display: "flex",
                fontSize: 12,
              }}
            >
              {activeSort}{" "}
              <FaAngleDown style={{ fontSize: 19, marginLeft: 5 }} />
              <div
                className={
                  "filter-sort-dropdown absolute top-full left-0 shadow-sm " +
                  " bg-white w-full "
                }
              >
                <div className="flex flex-col">
                  <span
                    className=" text-sm py-1 px-1 border-b-2"
                    onClick={() => {
                      setActiveSort("Review Score");
                      orderByFilter("hotel_review_score")
                    }}
                  >
                    Review Score
                  </span>
                  <span
                    className=" text-sm py-1 px-1 border-b-2"
                    onClick={() => {
                      setActiveSort("Most Reviews");
                      orderByFilter("hotel_number_of_reviews")
                    }}
                  >
                    Most Reviews
                  </span>
                  <span
                    className=" text-sm py-1 px-1 border-b-2"
                    onClick={() => {
                      setActiveSort(
                        `Price ${(state.pricePolarity) ? "(highest)" : "(lowest)"}`
                      )
                      orderByFilter("price", state.pricePolarity)
                    }}
                  >
                    Price {(state.pricePolarity) ? "(highest)" : "(lowest)"}
                  </span>
                  <span
                    className=" text-sm py-1 px-1 border-b-2"
                    onClick={() => {
                      setActiveSort("Star Rating");
                      orderByFilter("hotel_star");
                    }}
                  >
                    Star Rating
                  </span>
                  <span
                    className=" text-sm py-1 px-1 border-b-2"
                    onClick={() => {
                      setActiveSort(
                        `Room ${
                          (state.roomFootagePolarity) ? "(smallest)": "(largest)"
                        }`
                      );
                      orderByFilter("room_footage", state.roomFootagePolarity)
                    }}
                  >
                  {(state.roomFootagePolarity) ?
                    "Room (smallest)" :
                    "Room (largest)"
                  }
                  </span>
                  <span
                    className=" text-sm py-1 px-1"
                    onClick={() => {
                      setActiveSort("Dist. ")
                      orderByFilter("distance")
                    }}
                  >
                    Distance From City
                  </span>
                </div>
              </div>
            </div>
            <div
              className={
                "flex mx-1 rounded shadow-sm bg-gradient-to-r" +
                " from-start to-end items-center justify-center" +
                " content-center self-stretch text-white"
              }
              style={{
                flex: 1,
              }}
              onClick={() => {
                // display the map
                window.scrollTo({top: 0, left: 0, behavior: "smooth"});
                updateState({displayMode: "map"});
              }}
            >
              <FaGlobeAfrica
                color={"white"}
              />
              <div className="hidden sm:block ml-1">Show Results on Map</div>
            </div>
          </div>
        </div>
      )}
    </MobileFilterContext.Consumer>
  );
}
