import {FC, useEffect, useState} from 'react';
import {BsPencil} from 'react-icons/bs';
import {RiBuildingLine} from 'react-icons/ri';
import {RouteComponentProps, useHistory} from 'react-router';

import {Conference, fetchConference} from '../../../../api';
import {OverlayProvider} from '../../../search-property/context';
import { PartnerHeader } from '../../components';

import {PartnerProperty} from '../../components/property';
import {RoomOverlay} from '../../components/room-overlay';

export const ConferenceView: FC<RouteComponentProps<{id: string}>> = ({
  match,
}) => {
  const history = useHistory();

  const [conference, setConference] = useState<Conference | null>(null);
  
  useEffect(() => {
    const {id} = match.params;
    fetchConference(id, (err, data) => {
      if (err) {
        console.error(err);

        return;
      }

      setConference(data);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {conference && (
        <>
          <PartnerHeader
            HeaderIcon={RiBuildingLine}
            title={conference.title}
            actions={[
              {
                ActionIcon: BsPencil,
                alwaysVisible: true,
                label: "Edit Property contact information",
                type: "button",
                onClick: () => {
                  // migrate to the property edit tab
                  history.push(
                    `/property-owner/edit/conference/${conference.id}/details`
                  );
                },
              },
            ]}
          />
          <OverlayProvider.Consumer>
            {({setIsOverlay, setOnOverlay}) => (
              <PartnerProperty
                property={conference}
                propertyType="conference"
                subProperties={conference.rooms.map((room) => ({
                  cover: room.gallery[0].url,
                  description: room.description,
                  subtitle: `${room.price.hour}/hr or ${room.price.day}/day`,
                  title: room.title,
                  action: {
                    label: "View conference room",
                    onClick: () => {
                      setOnOverlay && setOnOverlay(() => () => (
                        <RoomOverlay
                          gallery={room.gallery}
                          capacity={{
                            adults: room.capacity,
                            children: room.capacity,
                          }}
                          description={room.description}
                          facilities={room.facilities}
                          features={room.features}
                          onRoomEdit={() => console.log("Editing room details")}
                          policies={room.policies}
                          removeOverlay={() => {
                            setIsOverlay && setIsOverlay(false);
                            setOnOverlay && setOnOverlay(null);
                          }}
                          title={room.title}
                          price={room.price.day}
                          coords={conference.coords}
                        />
                      ));
                    },
                  },
                  overflowActions: [
                    {
                      label: "Make room unavailable",
                      onClick: () => {
                        console.log("Making conference room unavailable");
                      },
                    }
                  ],
                }))}
                subPropertySectionTitle={"conference rooms"}
              />
            )}
          </OverlayProvider.Consumer>
        </>
      )}
    </>
  );
};
