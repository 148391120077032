////////////////////////////////////////////////////////////////////////////////
// JSDoc Types
////////////////////////////////////////////////////////////////////////////////
/**
 * @typedef {{
 * place: ?string,
 * lat: ?number,
 * lng: ?number,
 * checkin: ?string,
 * checkout: ?string,
 * adults: ?number,
 * children: ?number,
 * filter: ?string[],
 * }} CompulsoryFilters
 *
 * @typedef {{
 * price_lowest: ?number,
 * price_highest: ?number,
 * star_rating: ?number,
 * feature: ?string[],
 * theme: ?string,
 * }} OptionalFilters
 *
 * @typedef {{
 * compulsoryFilters: CompulsoryFilters,
 * optionalFilters: OptionalFilters,
 * }} SearchState
 *
 * @typedef {{
 * type: "UPDATE_All_FILTERS" | "UPDATE_OPTIONAL_FILTERS" |
 * "UPDATE_COMPULSORY_FILTERS",
 * value: any,
 * }} SearchStoreAction
 */

/**
 * This function fetches all the compulsory Filters from the current
 * `window.location` Url.
 * 
 * @returns {CompulsoryFilters}
 */
export const fetchCompulsoryFilters = () => {
  const url = new URL(location.href);
  /**@type {CompulsoryFilters} */
  const compulsoryFilters = {};

  const [loc, ] = url.searchParams.get("place").split(",");
  compulsoryFilters.place = loc;
  
  if (url.searchParams.has("adults")) {
    compulsoryFilters.adults = Number(url.searchParams.get("adults"));
  } else {
    compulsoryFilters.adults = 1;
  }

  if (url.searchParams.has("children")) {
    compulsoryFilters.children = Number(url.searchParams.get("children"));
  } else {
    compulsoryFilters.children = 0;
  }

  compulsoryFilters.checkin = url.searchParams.get("checkin");
  compulsoryFilters.checkout = url.searchParams.get("checkout");
  if (url.searchParams.has("lat")) {
    compulsoryFilters.lat = Number(url.searchParams.get("lat"));
    compulsoryFilters.lng = Number(url.searchParams.get("lng"));
  }
  compulsoryFilters.filter = url.searchParams.getAll("filter");

  return compulsoryFilters;
};

/**
 * This function fetches all the optional features encoded in the URL.
 *
 * @returns {OptionalFilters} the optional filters that have been captured.
 */
export const fetchOptionalFilters = () => {
  const url = new URL(location.href);

  /**@type {OptionalFilters} */
  const optionalFilters = {};

  optionalFilters.feature = url.searchParams.getAll("feature");
  if (url.searchParams.has("price_highest")) {
    optionalFilters.price_highest = Number(url.searchParams.get("price_highest"));
  }

  if (url.searchParams.has("price_lowest")) {
    optionalFilters.price_lowest = Number(url.searchParams.get("price_lowest"));
  }

  optionalFilters.star_rating = Number(url.searchParams.get("star_rating"));
  optionalFilters.theme = url.searchParams.get("theme");

  return optionalFilters;
};
