import type {FC} from "react";
import {FaTimes} from "react-icons/fa";

import {Chip} from "../../../components/chip";
import {Coordinates, Facility, Feature, Picture, Policy} from "../../../api";

import {PolicyCard} from "../../property/components/policy-card";
import {SubPropertyPhotoGallery} from "../../../components";
import { Marker, StaticGoogleMap } from "react-static-google-map";

type RoomOverlayProps = {
  gallery: Picture[];
  title: string;
  description: string;
  price?: number;
  capacity?: { adults: number; children: number };
  facilities: Facility[];
  features: Feature[];
  policies: Policy[];
  coords: Coordinates;
  removeOverlay: () => void;
  onRoomEdit: () => void;
};

export const RoomOverlay: FC<RoomOverlayProps> = ({
  title,
  description,
  price,
  capacity,
  facilities,
  features,
  policies,
  gallery,
  coords,
  removeOverlay,
  onRoomEdit,
}) => {
  return (
    <div className="absolute top-0 right-0 bottom-0 overflow-y-scroll w-full sm:grid sm:grid-cols-3 sm:gap-4 lg:w-3/5 bg-white">
      {/** App bar */}
      <div className="top-0 sticky bg-safari-gold text-white h-14 flex flex-row justify-between items-center overflow-hidden px-2 sm:col-start-1 sm:col-end-4 shadow-md">
        {/** Header Container */}
        <div className="flex flex-row items-center">
          <FaTimes size={24} onClick={removeOverlay} />
          <div className="overflow-hidden flex-grow-0 ml-3">
            <h2 className="text-xl font-semibold overflow-ellipsis whitespace-nowrap">
              {title}
            </h2>
            <p className="text-xs italic overflow-ellipsis whitespace-nowrap">
              {`Details of ${title}`}
            </p>
          </div>
        </div>
        {/** End of Header container */}

        {/** Edit Button */}
        {/* <button
          className="h-9 px-3 border border-red-500 rounded-md shadow-md text-red-500 font-semibold text-sm flex items-center"
          onClick={onRoomEdit}
        >
          Edit Room
        </button> */}
        {/** End of edit Button */}
      </div>
      {/** End of app bar */}

      {/** Gallery */}
      <div className="mx-2 h-96 bg-indigo-300 sm:col-start-1 sm:col-end-4">
        <SubPropertyPhotoGallery
          gallery={gallery}
          disable={true}
        />
      </div>
      {/** Price container */}
      <div className="flex flex-row px-4 items-center justify-between sm:col-start-1 sm:col-end-4">
        <div className="flex flex-row items-center mt-2">
          <div className="h-8 w-8 bg-indigo-300 rounded-full"></div>
          <div className="ml-3">
            {price && (
              <h6 className="text-xl font-semibold">{price} / Night</h6>
            )}
            <p className="text-xs">
              {`sleeps ${capacity?.adults} adults or ${capacity?.children} children`}
            </p>
          </div>
        </div>
      </div>
      {/** End of price container */}

      {/** room description */}
      <p className="text-sm mt-2 sm:col-start-1 sm:col-end-3 sm:ml-4">
        {description}
      </p>

      {/** Map container */}
      <div
        className="h-40 rounded-md shadow-md mt-2 sm:mr-4 sm:col-start-3 sm:col-end-4 overflow-hidden">
          <StaticGoogleMap
            size="600x600"
            apiKey="AIzaSyDxrJtbT4XhPoiNlXoOsNZ77j38ai9ZwAo"
          >
            <Marker.Group label="h" color="red">
              <Marker
                location={`${coords.lat},${coords.lng}`}
              />
            </Marker.Group>
          </StaticGoogleMap>
        </div>
      {/** End of map container */}

      <div className="px-4 sm:col-start-1 sm:col-end-4">
        {/** Facilities container */}
        <div className="mt-4 sm:col-start-1 sm:col-end-2 sm:mt-2 sm:ml-2">
          <h3 className="text-2xl font-semibold">Facilities</h3>
          <p className="text-xs opacity-70">
            Facilities available at the hotel
          </p>
          <div className="flex flex-row justify-between flex-wrap py-4 sm:justify-evenly">
            {/** Facilities chip here */}
            {facilities.map((facility) => (
              <Chip
                key={facility.title}
                label={facility.title}
                active={false}
              />
            ))}
          </div>
        </div>
        {/** End of facilities container */}

        {/** Features container */}
        <div className="mt-4 sm:col-start-2 sm:col-end-4 sm:mt-2 sm:mr-4">
          <h3 className="text-2xl font-semibold">Features</h3>
          <p className="text-xs opacity-70">Features available at the room</p>
          <div className="flex flex-row justify-between flex-wrap py-4 sm:justify-evenly">
            {/** Features chip */}
            {features.map((feature) => (
              <Chip key={feature.title} label={feature.title} active={false} />
            ))}
          </div>
        </div>
        {/** End of features container */}
      </div>

      {/** End of facilities container */}

      {/** Policies container */}
      <div className="px-4 pb-4 sm:col-start-1 sm:col-end-4">
        <h3 className="text-2xl font-semibold">Room Policies</h3>
        <p className="text-xs opacity-70">Policies governing the room</p>

        <div className="mt-2 flex flex-col flex-wrap items-center sm:flex-row justify-between">
          {policies.map((policy, idx) => (
            <PolicyCard key={`sub-property-policy_${idx}`} policy={policy} />
          ))}
        </div>
        {/** End of policies container */}

        {/** End of room content */}
      </div>
    </div>
  );
};
