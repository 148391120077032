import {OnCompleteCallback} from "..";

import {AttractionFilters, AttractionSite, Nearby} from "./types";

export const fetchAttraction = async (
  id: string, onComplete: OnCompleteCallback<AttractionSite>,
): Promise<void> => {
  const response = await fetch(
    `https://us-central1-safarixpertz.cloudfunctions.net/attraction/search/${
      id
    }`
  );

  if (response.status === 200) {
    const attraction = (await response.json()) as AttractionSite;

    onComplete(null, attraction);
  } else {
    onComplete(new Error("fetch error"), null);
  }
};

export const fetchAttractions = async (
  filters: AttractionFilters, onComplete: OnCompleteCallback<AttractionSite[]>,
): Promise<void> => {
  const response = await fetch(
    `https://us-central1-safarixpertz.cloudfunctions.net/attraction/search?${
      "" // used to justprevent going past 80 columns
    }lat=${filters.coords.lat}&lng=${filters.coords.lng}&location=${
      filters.place
    }`,
  );

  if (response.status === 200) {
    const attractions = (await response.json()) as AttractionSite[];

    onComplete(null, attractions);
  } else {
    onComplete(new Error("fetch error"), null);
  }
};

export const fetchNearbyProperties = async (
  attractionId: string, checkin: number, checkout: number,
  onComplete: OnCompleteCallback<Nearby[]>
): Promise<void> => {
  const response = await fetch(
    `https://us-central1-safarixpertz.cloudfunctions.net/attraction/search/${
      attractionId
    }/nearby?checkin=${checkin}&checkout=${checkout}`,
  );

  if(response.status === 200) {
    const nearby = (await response.json()) as Nearby[];

    onComplete(null, nearby);
  } else {
    onComplete(new Error("fetch error"), null);
  }
};
