// @ts-check
/* eslint-disable no-undef */
import { auth, database } from "firebase";
import {Component} from "react";
import PhoneInput from "react-phone-input-2";
import {connect} from "react-redux";

import {
  updateSignedInStatus,
  updateTheUser,
} from "../../_system/storage/actions";

/**
 * This screen component is used to register new users to the platform. This is with the
 * option of:
 * - Facebook (OAuth Services)
 * - Google (OAuth Services)
 * - Twitter (OAuth Services)
 * - Email (normal sign in services)
 * All OAuth services are not (in a sense) registering as a new user but are just linking an
 * ezisting account ans using it instead of creating new accounts.
 *
 * @todo the verification of user details offered through the application is rather week and
 * needs improvement to use regular expressions and actually refactoring the module into a
 * reusable util that can be used ouside this module.
 */
class App extends Component {
  state = {
    email: "",
    password: "",
    password2: "",
    fname: "",
    lname: "",
    username: "",
    authType: "user",
  };

  // creation of an account with email is the only real registering that hapens, all the
  // other methods are just linking existing accounts to this platform.
  // TODO: Refactor this to use better credential validation
  _registerUserInWithEmail() {
    //verify
    if (
      this.state.email == "" ||
      this.state.password == "" ||
      this.state.password2 == "" ||
      this.state.fname == "" ||
      this.state.lname == "" ||
      this.state.username == "" ||
      this.state.authType == ""
    ) {
      this.setState({ errorVisible: false });
      setTimeout(() => {
        this.setState({
          errorVisible: true,
          errorMessage: "Fill in all the above fields first!",
        });
      }, 100);
      return;
    }

    // validate that both passwords are the same
    if (this.state.password != this.state.password2) {
      this.setState({ errorVisible: false });
      setTimeout(() => {
        this.setState({
          errorVisible: true,
          errorMessage: "Your passwords do not match!",
        });
      }, 100);
      return;
    }

    this.setState({ registering: true }); // update UIState to registering
    // connect to firebase
    
    auth()
      .createUserWithEmailAndPassword(this.state.email, this.state.password)
      .then(async (data) => {
        // once a user is successfully created, added details after getting their basic information
        // includes adding:
        // authLevel - this is the privelleges given to the user in the system
        // dateJoined - timestamp recording date joined
        // fNLName - full name of the user, last and first name combined
        // email - from the user object
        // phone - from the user object still
        // password - if created using email, the user definitely has a password
        // registrationComplete - records the registration status of the user
        
        await database()
          .ref(`Users_Master/${data.user.uid}`)
          .set({
            authLevel: this.state.authType,
            
            dateJoined: database.ServerValue.TIMESTAMP,
            fNLName: `${this.state.fname} ${this.state.lname}`,
            username: this.state.username,
            email: data.user.email,
            phone: this.state.phone,
            password: this.state.password,
            registrationComplete: true,
          });
      })
      .catch((err) => {
        alert(err);
        this.setState({ registering: false });
      });
  }

  async _finishRegistration() {
    // TODO Add better verification of userCredentials
    if (
      this.state.email == "" ||
      this.state.fname == "" ||
      this.state.lname == "" ||
      this.state.phone == undefined ||
      this.state.username == "" ||
      this.state.authType == ""
    ) {
      this.setState({ errorVisible: false });
      setTimeout(() => {
        this.setState({
          errorVisible: true,
          errorMessage: "Fill in all the above fields first!",
        });
      }, 100);
      return;
    }

    if (this.state.password != this.state.password2) {
      this.setState({ errorVisible: false });
      setTimeout(() => {
        this.setState({
          errorVisible: true,
          errorMessage: "Your passwords do not match!",
        });
      }, 100);
      return;
    }

    this.setState({ registering: true });
    
    const uid = auth().currentUser.uid;
    
    const email = auth().currentUser.email;
    // connect to firebase and add user details
    
    await database()
      .ref(`Users_Master/${uid}`)
      .set({
        authLevel: this.state.authType,
        
        dateJoined: database.ServerValue.TIMESTAMP,
        fNLName: `${this.state.fname} ${this.state.lname}`,
        username: this.state.username,
        email,
        phone: this.state.phone,
        password: this.state.password,
        registrationComplete: true,
      });

    window.location.href = `/${this.state.authType}`;
  }

  logUserInWithFacebook() {
    
    const provider = new auth.FacebookAuthProvider();
    
    auth()
      .signInWithPopup(provider)
      .then((u) => {
        //console.log(u)
        const user = u.user;
        
        database()
          .ref(`Users_Master/${user.uid}`)
          .on("value", (snapshot) => {
            if (snapshot.exists()) {
              // const auth = snapshot.val().authLevel;
              const registrationComplete = snapshot.val().registrationComplete;
              //console.log(auth + " " + user.uid);
              if (registrationComplete) {
                //window.location = "/" + auth;
                return;
              }
              return;
            }
          });
      })
      .catch((err) => {
        alert(err);
      });
  }

  logUserInWithTwitter() {
    
    const provider = new auth.TwitterAuthProvider();
    
    auth()
      .signInWithPopup(provider)
      .then((u) => {
        //console.log(u)
        const user = u.user;
        
        database()
          .ref(`Users_Master/${user.uid}`)
          .on("value", (snapshot) => {
            if (snapshot.exists()) {
              // const auth = snapshot.val().authLevel;
              const registrationComplete = snapshot.val().registrationComplete;
              //console.log(auth + " " + user.uid);
              if (registrationComplete) {
                //window.location = "/" + auth;
                return;
              }
              return;
            }
          });
      })
      .catch((err) => {
        alert(err);
      });
  }

  logUserInWithGoogle() {
    
    const provider = new auth.GoogleAuthProvider();
    
    auth()
      .signInWithPopup(provider)
      .then((u) => {
        //console.log(u)
        const user = u.user;
        
        database()
          .ref(`Users_Master/${user.uid}`)
          .on("value", (snapshot) => {
            if (snapshot.exists()) {
              // const auth = snapshot.val().authLevel;
              const registrationComplete = snapshot.val().registrationComplete;
              //console.log(auth + " " + user.uid);
              const value = snapshot.val();
              value["uid"] = user.uid;
              this.props.updateTheUser(value);
              if (registrationComplete) {
                //window.location = "/" + auth;
                return;
              }
              return;
            }
          });
      })
      .catch((err) => {
        alert(err);
      });
  }

  _checkUserCompletionState = () => {
    if (this.props.registrationCompletion) {
      
      if (auth().currentUser) {
        
        const email = auth().currentUser.email;
        if (email) this.setState({ email });
        
        const username = auth().currentUser.displayName;
        if (username) this.setState({ username });
      }
    }
  };

  componentDidMount() {
    this._checkUserCompletionState();
  }

  render() {
    return (
      <div style={{ width: 320 }}>
        <h1 style={{ marginTop: 5 }}>
          {this.props.registrationCompletion ? (
            "Complete Registration"
          ) : (
            <>
              {" "}
              {this.props.origin == "list-property-page"
                ? "Register As a Partner"
                : "Register account"}
            </>
          )}
        </h1>
        {!this.props.registrationCompletion && (
          <div>
            <div style={{ marginBottom: 20, display: "none" }}>
              Select one of the options below to register with your existing
              social account
            </div>
            <div style={{ display: "flex" }}>
              <div
                onClick={() => this.logUserInWithFacebook()}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#3B5998",
                  color: "white",
                  padding: 10,
                  marginRight: 10,
                  flex: 1,
                  textAlign: "center",
                }}
              >
                Facebook
              </div>
              <div
                onClick={() => this.logUserInWithTwitter()}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#55ACEE",
                  color: "white",
                  padding: 10,
                  marginRight: 10,
                  flex: 1,
                  textAlign: "center",
                }}
              >
                Twitter
              </div>
              <div
                onClick={() => this.logUserInWithGoogle()}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#db4a39",
                  color: "white",
                  padding: 10,
                  flex: 1,
                  textAlign: "center",
                }}
              >
                Google
              </div>
            </div>
            <h2 style={{ marginTop: 20 }}>OR</h2>
            <div style={{ marginBottom: 20 }}>Use your email to register</div>
          </div>
        )}
        <div className="form">
          <div style={{ display: "flex" }}>
            <input
              placeholder="First Name"
              onChange={(event) => {
                this.setState({ fname: event.target.value });
              }}
              style={{
                fontSize: 17,
                fontWeight: "bold",
                paddingBottom: 15,
                border: "1px solid #bbb",
                margin: 0,
                borderRadius: 0,
                marginTop: 0,
                maxWidth: 125,
                borderTop: 0,
                borderRight: 0,
                borderLeft: 0,
              }}
            />
            <div style={{ padding: 20 }} />
            <input
              placeholder="Last Name"
              onChange={(event) => {
                this.setState({ lname: event.target.value });
              }}
              style={{
                fontSize: 17,
                fontWeight: "bold",
                paddingBottom: 15,
                border: "1px solid #bbb",
                margin: 0,
                borderRadius: 0,
                marginTop: 0,
                maxWidth: 125,
                borderTop: 0,
                borderRight: 0,
                borderLeft: 0,
              }}
            />
          </div>
          <div style={{ display: "flex" }}>
            <input
              placeholder="Username"
              value={this.state.username}
              onChange={(event) => {
                this.setState({ username: event.target.value });
              }}
              style={{
                fontSize: 17,
                fontWeight: "bold",
                paddingBottom: 15,
                border: "1px solid #bbb",
                margin: 0,
                borderRadius: 0,
                marginTop: 20,
                maxWidth: 125,
                borderTop: 0,
                borderRight: 0,
                borderLeft: 0,
              }}
            />
            <div style={{ padding: 20 }} />
            <select
              style={{
                color: "#737171",
                fontSize: 17,
                fontWeight: "bold",
                paddingBottom: 15,
                border: "1px solid #bbb",
                margin: 0,
                borderRadius: 0,
                marginTop: 20,
                maxWidth: 125,
                borderTop: 0,
                borderRight: 0,
                borderLeft: 0,
              }}
              onChange={(event) => {
                this.setState({ authType: event.target.value });
              }}
            >
              <option value="user">I am a user</option>
              <option value="partner">I want to list my property</option>
            </select>
          </div>
          <div style={{ display: "flex", marginTop: 20 }}>
            <div style={{ display: "flex" }}>
              <input
                placeholder="Email Address"
                value={this.state.email}
                onChange={(event) => {
                  this.setState({ email: event.target.value });
                }}
                style={{
                  fontSize: 17,
                  fontWeight: "bold",
                  paddingBottom: 15,
                  border: "1px solid #bbb",
                  margin: 0,
                  borderRadius: 0,
                  maxWidth: 125,
                  borderTop: 0,
                  borderRight: 0,
                  borderLeft: 0,
                }}
              />
            </div>
            <div style={{ padding: 20 }} />
            <PhoneInput
              country={"ke"}
              value={this.state.phone}
              onChange={(phone) => this.setState({ phone })}
            />
          </div>
          {!this.props.registrationCompletion ? (
            <div style={{ display: "flex" }}>
              <input
                placeholder="New Password"
                type="password"
                onChange={(event) => {
                  this.setState({ password: event.target.value });
                }}
                style={{
                  fontSize: 17,
                  fontWeight: "bold",
                  paddingBottom: 15,
                  border: "1px solid #bbb",
                  margin: 0,
                  borderRadius: 0,
                  marginTop: 20,
                  maxWidth: 125,
                  borderTop: 0,
                  borderRight: 0,
                  borderLeft: 0,
                }}
              />
              <div style={{ padding: 20 }} />
              <input
                placeholder="Confirm Password"
                type="password"
                onChange={(event) => {
                  this.setState({ password2: event.target.value });
                }}
                style={{
                  fontSize: 17,
                  fontWeight: "bold",
                  paddingBottom: 15,
                  border: "1px solid #bbb",
                  margin: 0,
                  borderRadius: 0,
                  marginTop: 20,
                  maxWidth: 125,
                  borderTop: 0,
                  borderRight: 0,
                  borderLeft: 0,
                }}
              />
            </div>
          ) : (
            <></>
          )}
          <div style={{ display: "flex" }}>
            <div
              style={{ marginTop: 17, cursor: "pointer" }}
              onClick={() => {
                this.props.changeScreen("login");
              }}
            >
              Already have an account?{" "}
              <a style={{ color: "#55acee", fontWeight: "bold" }}>Login</a>
            </div>
          </div>
          {this.state.errorVisible ? (
            <div
              style={{
                fontSize: 17,
                color: "red",
                fontWeight: "bold",
                textAlign: "center",
                marginTop: 15,
              }}
            >
              {this.state.errorMessage}
            </div>
          ) : (
            <></>
          )}
          <div
            onClick={() => {
              if (!this.props.registrationCompletion) {
                this._registerUserInWithEmail();
                return;
              }
              this._finishRegistration();
            }}
            style={{
              cursor: "pointer",
              backgroundColor: "#3f3d56",
              color: "white",
              padding: 10,
              width: "100%",
              textAlign: "center",
              marginTop: 20,
              marginBottom: 100,
            }}
          >
            Proceed With Email
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.isSignedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTheUser: (user) => dispatch(updateTheUser(user)),
    updateSignedInStatus: (status) => dispatch(updateSignedInStatus(status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
