import type {FC} from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import {BiWallet} from "react-icons/bi";
import {CgMore} from "react-icons/cg";
import {
  RiApps2Line,
  RiBuildingLine,
  RiCarLine,
  RiDashboard3Line,
  RiDashboardLine,
  RiFlightTakeoffLine,
  RiHotelBedLine,
  RiHotelLine,
  RiRestaurantLine,
  RiRoadMapLine,
  RiShipLine,
} from "react-icons/ri";
import {NavLink} from "react-router-dom";

type NavigationRailProps = {
  vosd: boolean;
  removeOverlay?: () => void;
};

/**
 * This component is used as both a navigation rail and a navigation drawer on
 * smaller devices. As such, this requires the monitoring of the device it is in
 * by used of `vosd (visible on small devices)` prop that makes it positionally
 * aware where it is on the screen. If the `vosd` is set to `true` then the
 * drawer is on the lef fixed permanently, but otherwise the drawer will be used
 * as an overlay hence is hidden on small devices with the use of the extra
 * class `nav-rail__desktop` to make it visible on only desktop devices and
 * hidden on small devices.
 * If used as an overlay: the Rail prop `vosd` is set to `true` thus making it
 * visible only in the overlay
 *
 * @param props navigation rail props
 * @returns NavigationRail Component
 */
export const NavigationRail: FC<NavigationRailProps> = ({vosd, removeOverlay}) => {
  // NOTE: The onClickOutside ref only works if the nav rail is overlayed
  const navRailRef = useOnclickOutside(() => {
    removeOverlay && removeOverlay();
  });

  return (
    <div
      ref={navRailRef}
      className={`nav-rail ${!vosd ? "nav-rail__desktop" : "absolute left-0"}`}
      style={{
        height: "100%",
        borderRight: 0,
        borderRightStyle: "solid",
        background: "#000104",
        backgroundSize: "cover",
      }}
    >
      <ul className="leftMenu" style={{ textDecoration: "none" }}>
        <li className="topItem">
          <NavLink
            to="/property-owner"
            activeClassName="active"
            exact={true}
            onClick={() => removeOverlay && removeOverlay()}
          >
            <RiDashboardLine className="leftMenuIcon" size={24} />{" "}
            <div className="leftMenuText">Overview</div>
          </NavLink>
        </li>

        <li className="topItem">
          <NavLink
            to="/property-owner/summary"
            activeClassName="active"
            onClick={() => removeOverlay && removeOverlay()}
          >
            <RiDashboard3Line className="leftMenuIcon" size={24} />{" "}
            <div className="leftMenuText">Activity</div>
          </NavLink>
        </li>
        <li className="topItem">
          <NavLink
            to="/property-owner/wallet"
            activeClassName="active"
            onClick={() => removeOverlay && removeOverlay()}
          >
            <BiWallet className="leftMenuIcon" size={24} />{" "}
            <div className="leftMenuText">Wallet</div>
          </NavLink>
        </li>

        <div
          style={{
            color: "#666",
            textAlign: "center",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <CgMore />
        </div>
        <div className="nav-rail__more-section">
          <li className="topItem">
            <NavLink
              to="/property-owner/hotel"
              activeClassName="active"
              onClick={() => removeOverlay && removeOverlay()}
            >
              <RiHotelBedLine className="leftMenuIcon" size={24} />{" "}
              <div className="leftMenuText">Hotel</div>
            </NavLink>
          </li>
          <li className="topItem">
            <NavLink
              to="/property-owner/flight"
              activeClassName="active"
              onClick={() => removeOverlay && removeOverlay()}
            >
              <RiFlightTakeoffLine className="leftMenuIcon" size={24} />{" "}
              <div className="leftMenuText">Flight</div>
            </NavLink>
          </li>
          <li className="topItem">
            <NavLink
              to="/property-owner/rental"
              activeClassName="active"
              onClick={() => removeOverlay && removeOverlay()}
            >
              <RiHotelLine className="leftMenuIcon" size={24} />{" "}
              <div className="leftMenuText">Rental</div>
            </NavLink>
          </li>
          <li className="topItem">
            <NavLink
              to="/property-owner/cruise"
              activeClassName="active"
              onClick={() => removeOverlay && removeOverlay()}
            >
              <RiShipLine className="leftMenuIcon" size={24} />{" "}
              <div className="leftMenuText">Cruise</div>
            </NavLink>
          </li>
          <li className="topItem">
            <NavLink
              to="/property-owner/eatery"
              activeClassName="active"
              onClick={() => removeOverlay && removeOverlay()}
            >
              <RiRestaurantLine className="leftMenuIcon" size={24} />{" "}
              <div className="leftMenuText">Restaurant</div>
            </NavLink>
          </li>
          <li className="topItem">
            <NavLink
              to="/property-owner/conference"
              activeClassName="active"
              onClick={() => removeOverlay && removeOverlay()}
            >
              <RiBuildingLine className="leftMenuIcon" size={24} />{" "}
              <div className="leftMenuText">Conference</div>
            </NavLink>
          </li>
          <li className="topItem">
            <NavLink
              to="/property-owner/car"
              activeClassName="active"
              onClick={() => removeOverlay && removeOverlay()}
            >
              <RiCarLine className="leftMenuIcon" size={24} />{" "}
              <div className="leftMenuText">Car</div>
            </NavLink>
          </li>
          <li className="topItem">
            <NavLink
              to="/property-owner/tour"
              activeClassName="active"
              onClick={() => removeOverlay && removeOverlay()}
            >
              <RiRoadMapLine className="leftMenuIcon" size={24} />{" "}
              <div className="leftMenuText">Tour</div>
            </NavLink>
          </li>
          <li className="topItem">
            <NavLink
              to="/property-owner/activity"
              activeClassName="active"
              onClick={() => removeOverlay && removeOverlay()}
            >
              <RiApps2Line className="leftMenuIcon" size={24} />{" "}
              <div className="leftMenuText">Activity</div>
            </NavLink>
          </li>
        </div>
      </ul>
    </div>
  );
};
