import {FC, useContext, useEffect, useState} from 'react';
import type {RouteComponentProps} from 'react-router-dom';
import Lottie from 'react-lottie';

import animation from '../../../../assets/anim/loading-activity.json';
import {Input} from '../../../../components/input';
import {Activity, checkActivityAvailability, fetchActivity} from '../../../../api';
import {fetchCompulsoryActivitiesFilters} from '../../../../reducers';

import Container, {CheckoutSection} from '../../components/container';
import {ProceedToCheckout} from '../../components/proceed-to-checkout';
import {ActivityReservation} from '../../../../api/reservation/types';
import {calculateActivityPrice} from '../../../../util/price-calculator';
import {DatePicker, InputNumber} from 'antd';
import {Button} from '../../../../components';
import ActivityIndicator from '../../../../components/loading';
import {OverlayProvider} from '../../../search-property/context';
import {TextInput} from '../../../../components/input/text-input';
import {DateUtil} from '../../../../util';

type ActivityProps = RouteComponentProps<{id: string}>;

export const ActivityProperty: FC<ActivityProps> = ({match}) => {
  const {
    setIsCancellable, setIsOverlay, setOnOverlay,
  } = useContext(OverlayProvider);

  const [activity, setActivity] = useState<Activity | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorOccured, setErrorOccured] = useState<boolean>(false);

  // get the colpulsory filters that the user used to make the search
  const {
    adults: adultsParameter, date: activityDate, children: childrenParameter,
    place,
  } = fetchCompulsoryActivitiesFilters();

  const [date, setDate] = useState<Date>(new Date());
  const [adults, setAdults] = useState<number>(
    adultsParameter ?
      adultsParameter :
      1,
  );
  const [children, setChildren] = useState<number>(
    childrenParameter ?
      childrenParameter :
      1,
  );

  const [isAvailable, setIsAvailable] = useState<boolean>(true);

  useEffect(() => {
    // fetch the Hotels and after fetching, carry out the on success function
    fetchActivity(match.params.id, (err, data) => {
      setIsLoading(false);

      if (err) {
        setErrorOccured(true);
        return;
      }

      // handle the hotel data that has been retrieved by the system
      setActivity(data);
    });
  }, [match.params.id]);

  const calculatePrice = (): number => {
    if (!activity) return 0;

    return calculateActivityPrice(
      adultsParameter ? adultsParameter : 1,
      childrenParameter ? childrenParameter : 0,
      activity
    );
  };

  const onProceedToCheckout = () => {
    // creates the reservation card and then proceeds to checkout
    const reservation: ActivityReservation = {
      amount_received: 0,
      category: "activity",
      checkin: new Date(activityDate).getTime(),
      due_date: new Date(activityDate).getTime(),
      expected_amount: calculatePrice(),
      property_id: activity?.id ? activity.id : "",
      reservation_date: Date.now(),
      reservation_type: activity ? activity.reservation_policy : "full_amount",
      status: "pending",
      adults: adultsParameter ? adultsParameter : 1,
      children: childrenParameter ? childrenParameter : 0,
    };

    return reservation;
  };

  return (
    <>
      {isLoading && (
        <div className="w-full h-96 flex flex-col items-center justify-center">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animation,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            style={{}}
            height={200}
            width={200}
          />
          <p className="text-base font-semibold">Fetching Hotel Details</p>
        </div>
      )}
      {!isLoading && !errorOccured && activity && (
        <Container
          property={activity}
          showDirections={true}
          renderSearchSection={() => {
            return (
              <>
                <TextInput
                  disabled={true}
                  value={activity.title}
                  helper="Name of the activity"
                  id="activity-name"
                  inputLabel="Activity Name"
                  name="activity_name"
                  onChange={(e) => console.log("Should not change")}
                  className="mb-2"
                />
                <p className="text-xs mb-1">Date:</p>
                <DatePicker
                  className="mb-2"
                  onChange={(val) => {
                    if (val) {
                      setDate(val.toDate());
                    }
                  }}
                />

                <p className="text-xs mb-1">Number of adults</p>
                <InputNumber
                  className="mb-2"
                  defaultValue={adults}
                  value={adults}
                  onChange={(val) => {
                    setAdults(val);
                  }}
                />

                <p className="text-xs mb-1">Number of children</p>
                <InputNumber
                  className="mb-2"
                  defaultValue={children}
                  value={children}
                  onChange={(val) => {
                    setChildren(val);
                  }}
                />

                <Button
                  disabled={
                    DateUtil.changeDateFormatFromDate(date, "DD-MM-YYYY") ===
                      activityDate
                  }
                  type="button"
                  onClick={() => {
                    setOnOverlay && setOnOverlay(() => () => (
                      <ActivityIndicator
                        caption=""
                      />
                    ));
                    setIsCancellable && setIsCancellable(false);
                    setIsOverlay && setIsOverlay(true);
                    checkActivityAvailability(
                      match.params.id,
                      date.getTime(),
                      (err, isAvailable) => {
                        setIsOverlay && setIsOverlay(false);
                        setOnOverlay && setOnOverlay(null);
                        setIsCancellable && setIsCancellable(true);

                        if (err) {
                          console.error(err);

                          return;
                        }

                        if (isAvailable !== null) {
                          setIsAvailable(isAvailable);
                        }
                      },
                    );
                  }}
                >
                  Check Availability
                </Button>
              </>
            );
          }}
        >
          <CheckoutSection>
            <ProceedToCheckout
              onProceedToCheckout={onProceedToCheckout}
              price={calculatePrice()}
              isAvailable={isAvailable}
            >
              <Input
                disabled={true}
                error=""
                inputLabel="Tour"
                name="tour"
                onChange={() => console.log("Should not happen")}
                value={activity ? activity.title : ""}
                helper="Name of the tour"
                id="tour-name"
              />
              <Input
                disabled={true}
                error=""
                inputLabel="Place"
                name="place"
                onChange={() => console.log("Should not happen")}
                value={place ? place : ""}
                helper="Name of the start location"
                id="place-name"
              />
              <Input
                disabled={true}
                error=""
                inputLabel="Checkin Date"
                name="checkin"
                onChange={() => console.log("Should not happen")}
                value={activityDate ? activityDate : ""}
                helper="Date the trip should start"
                id="trip-checkin-date"
              />
              <Input
                disabled={true}
                error=""
                inputLabel="Adults"
                name="adults"
                onChange={() => console.log("Should not happen")}
                value={adultsParameter ? `${adultsParameter}` : ""}
                helper="Number of adults"
                id="adult-number"
              />
              <Input
                disabled={true}
                error=""
                inputLabel="Children"
                name="children"
                onChange={() => console.log("Should not happen")}
                value={childrenParameter ? `${childrenParameter}` : ""}
                helper="Number of children"
                id="children-number"
              />
            </ProceedToCheckout>
          </CheckoutSection>
        </Container>
      )}
      {!isLoading && !errorOccured && !activity && <></>}
      {!isLoading && errorOccured && <></>}
    </>
  );
};
