import {FC, useState} from 'react';
import {FaRegBell} from 'react-icons/fa';
import {IoMdClose} from 'react-icons/io';
import { Notification } from '../../../api/notification/types';
import { NotificationCard } from '../../../components/notification-card';

type SuNotificationOverlayProps = {
  removeOverlay: () => void;
  notifications: Notification[];
};


export const SuNotificationOverlay: FC<SuNotificationOverlayProps> = ({
  removeOverlay, notifications: initialNotifications,
}) => {
  const [notifications, setNotifications] = useState(initialNotifications);

  const markNotificationAsRead = (idx: number) => {
    const unreadNotifications = [...notifications];
    unreadNotifications.splice(idx, 1);

    setNotifications(unreadNotifications);
  };

  return (
    <div className="absolute top-0 right-0 bottom-0 w-96 bg-white">
      <div
        className="w-full bg-safari-gold shadow-md px-4 flex flex-row items-center justify-between text-white sticky top-0"
        style={{
          height: 56,
        }}
      >
        <div className="flex flex-row items-center">
          <FaRegBell size={24} className="mr-3" />
          <h6 className="text-xl font-semibold font-lato">
            Unread notifications
          </h6>
        </div>
        <IoMdClose
          className="cursor-pointer"
          size={24}
          onClick={removeOverlay}
        />
      </div>
      <div
        className="px-4 py-2 overflow-y-scroll"
        style={{
          height: window.innerHeight - 56,
        }}
      >
        {notifications.map((notification, idx) => (
          <NotificationCard
            key={notification.id}
            notification={notification}
            onMarkAsRead={() => {
              // remove the notification from visible
              markNotificationAsRead(idx);
            }}
          />
        ))}
      </div>
    </div>
  );
};
