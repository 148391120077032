import {Dispatch, FC, SetStateAction, useEffect} from 'react';
import {useState} from 'react';
import {IoIosBed} from 'react-icons/io';
import {useHistory} from 'react-router';

import {
  Conference as ConferenceProperty, Coordinates, Facility,
  Feature,
  filterConferences, Property,
} from '../../../../api';
import { conferenceFacilities, conferenceFeatures } from '../../../../api/features';
import {SearchPropertyCard} from '../../../../components/search-property-card';
import {fetchCompulsoryConferencingFilters} from '../../../../reducers';
import {DateUtil, DistanceUtil} from '../../../../util';
import {calculateConferencePrice} from '../../../../util/price-calculator';
import {rankFilters} from '../../../../util/rank-filters';

import {ContainerTwo} from '../../components/container-two';
import {FilterableProperty, FilterContext} from '../../context';
import {addFacility, filter, removeFacility} from '../../util';

export const Conference: FC = () => {
  const history = useHistory();

  const [isFetching, setIsFetching] = useState<boolean>(true);

  const [filterableProperties, setFilterableProperties] =
    useState<FilterableProperty[]>([]);
  const [viewableResults, setViewableResults] = useState<ConferenceProperty[]>([])
  const [results, setResults] = useState<ConferenceProperty[]>([]);
  const [activeFacilities, setActiveFacilities] =
    useState<Facility[]>([]);
  const [activeFeatures, setActiveFeatures] = useState<Feature[]>([]);

  const [rawResultsStale, setRawResultsStale] = useState<boolean>(false);
  const [filterResultsStale, setFilterResultsStale] = useState<boolean>(false);

  const {place, date, lat, lng} = fetchCompulsoryConferencingFilters();
  const coords: Coordinates = {
    lat: (lat) ? lat : 0,
    lng: (lng) ? lng :0,
  };

  useEffect(() => {
    const location = (place) ? place : "";
    const coords: Coordinates = {
      lat: (lat) ? lat : 0,
      lng: (lng) ? lng :0,
    };
    const checkinDate = date ? new Date(
      DateUtil.changeDateFormat(date, "DD-MM-YYYY", "MM-DD-YYYY"),
    ) : new Date();

    checkinDate.setHours(10, 0);

    filterConferences(location, coords, checkinDate.getTime(), (err, conferences) => {
      if (err) {
        console.error(err);

        return;
      }

      if (conferences) {
        setIsFetching(false);
        setResults(conferences);
        setViewableResults(conferences);
        setFilterableProperties(conferences.map((result) => ({
          ...result,
          price: calculateConferencePrice(result.rooms[0], 1, "hour"),
        })));
        setRawResultsStale(false);
      }
    });
  }, [rawResultsStale]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const rankedResults = rankFilters(
      results,
      {
        active: activeFacilities.map((facility) => facility.title),
        get: (conference) =>
          conference.facilities.map((facility) => facility.title),
      },
      {
        active: activeFeatures.map((feature) => feature.title),
        get: (conference) =>
          conference.features.map((feature) => feature.title),
      },
    );

    setViewableResults(rankedResults);
    setFilterResultsStale(false);
  }, [filterResultsStale]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FilterContext.Provider
      value={{
        filterResultsStale,
        rawResults: results,
        rawResultsStale,
        results: filterableProperties,
        setFilterResultsStale,
        setRawResultsStale,
        setResults: setResults as Dispatch<SetStateAction<Property[]>>,
        setViewableResults: setViewableResults as (viewableResults: Property[]) => void,
        addFacility: (facility) => {
          addFacility(facility, activeFacilities, setActiveFacilities);
        },
        removeFacility: (facility) => {
          removeFacility(facility, activeFacilities, setActiveFacilities);
        },
      }}
    >
      <ContainerTwo<ConferenceProperty>
        HeaderIcon={IoIosBed}
        columnData={[
          {
            title: "Conference facilities",
            data: conferenceFacilities,
            onChange: (activeFacilities) => {
              setActiveFacilities(filter(activeFacilities, conferenceFeatures));
              setFilterResultsStale(true);
            },
          },
          {
            title: "Conference features",
            data: conferenceFeatures,
            onChange: (activeFeatures) => {
              setActiveFeatures(filter(activeFeatures, conferenceFeatures));
              setFilterResultsStale(true);
            },
          },
        ]}
        filterOptions={[
          {
            idx: 0,
            onClick: () => console.log("Selecting top solo filters"),
            title: "Top solo picks",
            onFilter: (results) => results,
          },
          {
            idx: 1,
            onClick: () => console.log("Selecting Stars (Highest first)"),
            title: "Stars (Highest first)",
            onFilter: (results) => {
              const parsedResults = (results as Property[]).sort((result, nextResult) => {
                return nextResult.rating - result.rating;
              });

              return parsedResults;
            },
          },
          {
            idx: 2,
            onClick: () => console.log("Selecting stars (Lowest First)"),
            title: "Stars (Lowest first)",
            onFilter: (results) => {
              const parsedResults = (results as Property[]).sort((result, nextResult) => {
                return result.rating - nextResult.rating;
              });

              return parsedResults;
            },
          },
          {
            idx: 3,
            onClick: () => console.log("Selecting by Price (Highest)"),
            title: "Price (Highest)",
            onFilter: (results) => {
              const parsedResults = (results as unknown as ConferenceProperty[]).sort((result, nextResult) => {
                return nextResult.rooms[0].price.day - result.rooms[0].price.day;
              });

              return parsedResults;
            },
          },
          {
            idx: 4,
            onClick: () => console.log("Select by price (lowest)"),
            title: "Price (Lowest)",
            onFilter: (results) => {
              const parsedResults = (results as unknown as ConferenceProperty[]).sort((result, nextResult) => {
                return result.rooms[0].price.day - nextResult.rooms[0].price.day;
              });

              return parsedResults;
            },
          },
          {
            idx: 5,
            onClick: () => console.log("Selecting top Reviewed"),
            title: "Top Reviewed",
            onFilter: (results) => {
              const parsedResults = (results as Property[]).sort((result, nextResult) => {
                return nextResult.reviews.length - result.reviews.length;
              });

              return parsedResults;
            },
          }
        ]}
        headerTitle={`Found ${results.length} Results in: ${place}`}
        loading={isFetching}
        results={viewableResults}
      >
        {(viewableResults) => viewableResults.map((conference, idx) => (
          <SearchPropertyCard
            key={conference.id}
            action={{
              label: "View Availability",
              onClick: () => {
                history.push(`/property/conference/${conference.id}?${
                  new URL(location.href).searchParams.toString()
                }`);
              },
            }}
            comments={conference.reviews}
            cover={conference.gallery[0].url}
            description={conference.description}
            duration={"2 days"}
            idx={idx}
            item={conference}
            location={conference.location}
            origin="search"
            price={conference.rooms[0].price.day}
            style={{}}
            rating={conference.rating}
            title={conference.title}
            subtitle={
              `${
                DistanceUtil.haversineDistance("km", coords, conference.coords)
                  .toFixed(2)
              } KM from ${place}`
            }
          />
        ))}
      </ContainerTwo>
    </FilterContext.Provider>
  );
};
