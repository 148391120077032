import {FC, useEffect, useState} from 'react';
import {Tabs} from 'antd';
import {RouteComponentProps, useLocation} from 'react-router-dom';
import {FaClipboardList, FaSearch} from 'react-icons/fa';

import {fetchAttractions} from '../../api/attraction';
import {AttractionSite} from '../../api/attraction/types';
import {DateUtil} from '../../util';
import {Coordinates} from '../../api';

import Header from '../index/components/Header';

import {TourSearch} from './components';
import {TourResults} from './components/tour-results';

const TOMORROW = new Date();
TOMORROW.setDate(TOMORROW.getDate() + 1);

export const SearchAttraction: FC<RouteComponentProps> = () => {
  const [activeKey, setActiveKey] = useState<"1" | "2">("1");
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [results, setResults] = useState<AttractionSite[]>([]);

  const [attractionsStale, setAttractionsStale] = useState<boolean>(true);

  const searchParams = new URLSearchParams(useLocation().search)

  const placeCoordinates: Coordinates = {
    lat: Number(searchParams.get("lat")),
    lng: Number(searchParams.get("lng")),
  };
  const place = searchParams.get("place");
  const date = searchParams.get("date");

  useEffect(() => {
    if (!attractionsStale) return;

    setIsFetching(true);

    fetchAttractions({
      place: place ? place : "",
      coords: placeCoordinates,
      date: date ? date : DateUtil.stringifyDate(TOMORROW),
    }, (err, attractions) => {
      if (err) {
        setIsFetching(false);
        console.error(err);

        return;
      }

      if (attractions) {
        setIsFetching(false);
        setResults(attractions);
        setAttractionsStale(false);
      } else {
        setIsFetching(false);
      }
    });
  }, [attractionsStale]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="h-full">
      <Header />
      {/* container for the content */}
      <div className="flex flex-row items-center justify-center w-full h-full">
        <div className="w-full pt-14 lg:max-w-5xl">
          {/*
            The tabs are hidden on large devices as thy are capable of holding
            more content
          */}
          <Tabs
            defaultActiveKey="1"
            size="large"
            centered={true}
            activeKey={activeKey}
            onTabClick={(key) => setActiveKey(key as "1" | "2")}
            className="h-full lg:hidden"
          >
            <Tabs.TabPane
              key="1"
              tab={
                <span className="flex flex-row items-center">
                  <FaSearch size={24} className="mr-3" />
                  Search
                </span>
              }
              
            >
              <TourSearch
                switchToResults={() => setActiveKey("2")}
                setAttractionsStale={setAttractionsStale}
              />
            </Tabs.TabPane>

            <Tabs.TabPane
              key="2"
              tab={
                <span className="flex flex-row items-center">
                  <FaClipboardList size={24} className="mr-3" />
                  Results
                </span>
              }
              className="h-full"
            >
              <TourResults loading={isFetching} results={results} />
            </Tabs.TabPane>
          </Tabs>

          <div className="hidden lg:grid grid-cols-4 gap-4">
            {/* Search container */}
            <div>
              <TourSearch setAttractionsStale={setAttractionsStale} />
            </div>
            <div className="col-start-2 col-end-5">
              <TourResults loading={isFetching} results={results} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
