import {FC, MouseEventHandler} from "react";

type ButtonProps = {
  type: "submit" | "button" | "reset";
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  mode?: "outlined" | "fill";
  disabled?: boolean;
};

export const Button: FC<ButtonProps> = ({
  children, className, type, disabled, mode, onClick,
}) => {
  return (
    <button
      className={
        `h-9 px-3 rounded-md text-sm font-semibold ${
          disabled ?
            "bg-gray-500 text-white cursor-not-allowed" :
            (mode ?
              (mode === "outlined" ?
                "border-safari-gold text-safari-gold" :
                "bg-safari-gold text-white"
              ) :
              "bg-safari-gold text-white"
            )
        } ${
          className ? className : ""
        }`
      }
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
