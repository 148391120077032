// @ts-check
import {Component} from "react";
import {connect} from "react-redux";

import LeftColumn from "../../../../search/LeftColumn";
import ResultsMain from "../../../../search/Results_Main";
import ResultsMap from "../../../../search/Results_Map";
import Selector_Header from "../../../../search/Selector_Header";
import DataFetcher from "../../../../search/data-fetcher";
import {fetchOptionalFilters} from "../../../../../reducers";

class App extends Component {
  state = {
    displayMode: "normal",
    searchDone: false,
    category: false,
    searchResults: [],
    rawResults: [],
    city: "",
    departure: "checkin",
    return: "checkout",
    checkinDate: "none",
    checkoutDate: "none",
    searchItemsLength: 0,
    rangeMin: 0,
    rangeMax: 0,
    minPrice: 0,
    maxPrice: 0,
    priceChanged: false,
    pagination: 1,
    pagesArray: [],
    newCity: "",
    rating_category: [],
    numberOfDays: 0,
    daysSelected: [],
    plannerActive: false,
    adults: 0,
    center: {
      lat: 0,
      lng: 0,
    },
    urlParameters: "",
    facilities: [
      { title: "Air Conditioning", value: "aircon" },
      { title: "Airport Transport", value: "air_transport" },
      { title: "Fitness Center", value: "fitness_center" },
      { title: "Flat Tv", value: "flat_tv" },
      { title: "Heater", value: "heater" },
      { title: "Wi-Fi Internet", value: "internet" },
      { title: "Parking", value: "parking" },
      { title: "Pool", value: "pool" },
      { title: "Restaurant", value: "restaurant" },
      { title: "Shuttle Bus Service", value: "shuttle_bus_service" },
      { title: "Smoking Room", value: "smoking_room" },
      { title: "SPA", value: "spa" },
      { title: "Spa and Sauna", value: "sauna" },
      { title: "Vocabulary", value: "vocabulary" },
      { title: "Washer &amp; Dryer", value: "washer" },
    ],
    themes: [
      { title: "Best value", value: "best_value" },
      { title: "Business", value: "business" },
      { title: "Family-friendly", value: "family_friendly" },
      { title: "Mid-range", value: "mid_range" },
      { title: "Quite", value: "quiet" },
      { title: "Trendy", value: "trendy" },
      { title: "Boutique", value: "boutique" },
      { title: "Charming", value: "charming" },
      { title: "Green", value: "green" },
      { title: "Party", value: "party" },
      { title: "Romantic", value: "romantic" },
      { title: "Budget", value: "budget" },
      { title: "Luxury", value: "luxury" },
      { title: "Classic", value: "classic" },
      { title: "Quaint", value: "quaint" },
      { title: "Standard", value: "standard" },
    ],
    loading: true,
    selected_currency: this.props.selected_currency,
    selected_multiplex: this.props.selected_multiplex,
  };

  _updateState = (newState) => {
    let searchResults = (newState.rawResults) ?
      newState.rawResults: this.state.rawResults;
    const url = new URL(location.href);
    const optionalFilters = fetchOptionalFilters();

    // prevent filtering in the instance that the header filters are used
    if (newState.filter !== false) {
      if (url.searchParams.has("star_rating")) {
        searchResults =
          this.filterByStars(optionalFilters.star_rating, searchResults);
      }
      // filter by the price
      if (
        url.searchParams.has("price_highest") ||
        url.searchParams.has("price_lowest")
      ) {
        searchResults = this.filterByPrice(
          optionalFilters.price_lowest, optionalFilters.price_highest,
          searchResults,
        );
      }
    }

    this.setState({...newState, searchResults});
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 200);
  }

  filterByStars = (rating, results) => {
    const filteredResults = results.filter((result) => 
      Number(result.hotel_star) === rating);
    // sort the results
    filteredResults.sort((a, b) => b - a);

    return filteredResults;
  };

  filterByPrice = (min, max, results) => {
    let newResults = results;
    if (max !== null) {
      newResults = newResults.filter((result) => result.calcPrice <= max);
    }

    if (min !== null) {
      newResults = newResults.filter((result) => result.calcPrice >= min);
    }

    return newResults;
  };

  render() {
    if (this.state.loading) {
      return "side loading";
    }

    return (
      <div style={{ display: "flex" }}>
        <LeftColumn sharedState={this.state} updateState={this._updateState} />
        <div className="main-column">
          <Selector_Header
            sharedState={this.state}
            updateState={this._updateState}
          />
          <ResultsMain
            sharedState={this.state}
            origin="planner"
            updateState={this._updateState}
          />
          {!this.state.searchDone && (
            <DataFetcher updateState={this._updateState} />
          )}
        </div>
        {this.state.displayMode == "map" && (
          <ResultsMap
            sharedState={this.state}
            updateState={this._updateState}
          />
        )}
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    // multiplex_usd: state.multiplex_usd,
    // multiplex_kes: state.multiplex_kes,
    // multiplex_pound: state.multiplex_pound,
    // multiplex_euro: state.multiplex_euro,
    selected_currency: state.selected_currency,
    selected_multiplex: state.selected_multiplex,
  };
};

export default connect(mapStateToProps)(App);
