import {FC, useContext} from "react";
import { Link } from "react-router-dom";

import TicketImage from '../../assets/gallery.png';
import {OnOverlayCallback} from "../../context";
import { AppStateContext } from "../../context/app-state-provider";

type SuccessDialogProps = {
  setIsOverlay?: (isOverlay: boolean) => void;
  setOnOverlay?: OnOverlayCallback;
  setIsCancellable?: (isCancellable: boolean) => void;
};

export const SuccessDialog:FC<SuccessDialogProps> = ({
  setIsCancellable, setIsOverlay, setOnOverlay,
}) => {
  const {actions} = useContext(AppStateContext);

  return (
    <div
      className={
        "p-4 bg-white rounded-md shadow-md border w-full " +
        "flex flex-col items-center sm:w-96"
      }
    >
      <div
        className="bg-no-repeat bg-cover bg-center w-full-mx-2 sm:w-96 h-96"
        style={{
          backgroundImage: `url(${TicketImage})`,
        }}
      />

      <h6 className="text-xl my-2 font-semibold text-center">
        Reservation complete!
      </h6>

      <Link
        to="/user"
        onClick={() => {
          setIsCancellable && setIsCancellable(true);
          setIsOverlay && setIsOverlay(false);
          setOnOverlay && setOnOverlay(null);
          // clear the state.AFFILIATE since the affiliate link has already
          // served its purpose
          actions.setAffiliateId(null);
        }}
      >
        <button
          className={
            "mt-2 bg-gradient-to-tr from-primary to-end text-white h-10 " +
            "rounded-lg shadow-md px-5 font-semibold"
          }
        >
          Go to user dashboard
        </button>
      </Link>
    </div>
  );
};
