// @ts-check
import {Component} from "react";

import {Hotel} from "./selector-header/hotel";
import {Flight} from './selector-header/flight';
import {Rental} from "./selector-header/rental";
import {Cruise} from "./selector-header/cruise";
import {Restaurant} from "./selector-header/restaurant";
import {Conferencing} from "./selector-header/conferencing";
import {Car} from "./selector-header/car";
import {Tour} from "./selector-header/tour";
import {Activity} from "./selector-header/activity";
import fetchSearchCategory from "./data-fetcher/util/fetch-search-category";


let sortVar = "room_footage";

function compareLarger(a, b) {
  if (a[sortVar] < b[sortVar]) {
    return -1;
  }
  if (a[sortVar] > b[sortVar]) {
    return 1;
  }
  return 0;
}

function compareSmaller(a, b) {
  if (a[sortVar] < b[sortVar]) {
    return 1;
  }
  if (a[sortVar] > b[sortVar]) {
    return -1;
  }
  return 0;
}

class App extends Component {
  state = {
    pricePolarity: true,
    roomFootagePolarity: true,
  };

  _orderByFilter = (filter, order) => {
    sortVar = filter;
    // var category = this.props.sharedState.category;
    let results = this.props.sharedState.rawResults;
    let newResults = [];
    if (order) {
      newResults = results.sort(compareLarger);
    } else {
      newResults = results.sort(compareSmaller);
    }
    switch (filter) {
      case "price":
        this.setState({ pricePolarity: !this.state.pricePolarity });
        break;
      case "room_footage":
        this.setState({ roomFootagePolarity: !this.state.roomFootagePolarity });
        break;
    }
    this.props.updateState({
      searchResults: newResults,
      filter: false, // this flag helps prevent the automatic filter.
    });
  };

  render() {
    if (!this.props.sharedState.searchDone) {
      return (
        <>
          <div style={{ height: 75, marginTop: -5, overflow: "hidden" }}>
            <div className="shimmer" style={{ paddingTop: 80 }} />
          </div>
        </>
      );
    }

    const category = fetchSearchCategory();
    // WTF!!!
    switch (this.props.sharedState.category) {
      case "hotel":
        return (
          <Hotel
            category={category}
            orderByFilter={this._orderByFilter}
            sharedState={this.props.sharedState}
            state={this.state}
            updateState={this.props.updateState}
          />
        );

      case "flight":
        return (
          <Flight
            category={category}
            sharedState={this.props.sharedState}
            appendQuery={() => console.log("Appending query...")}
            updateState={this.props.updateState}
          />
        );

      case "rental":
        return (
          <Rental
            category={category}
            sharedState={this.props.sharedState}
            appendQuery={() => console.log("Appending Query")}
            updateState={this.props.updateState}
          />
        );

      case "cruises":
        return (
          <Cruise
            category={category}
            sharedState={this.props.sharedState}
            appendQuery={() => console.log("Appending Query")}
            updateState={this.props.updateState}
          />
        );

      case "eateries":
        return (
          <Restaurant
            category={category}
            sharedState={this.props.sharedState}
            appendQuery={() => console.log("Appending Query")}
            updateState={this.props.updateState}
          />
        );

      case "conferencing":
        return (
          <Conferencing
            category={category}
            sharedState={this.props.sharedState}
            appendQuery={() => console.log("Appending Query")}
            updateState={this.props.updateState}
          />
        );

      case "car":
        return (
          <Car
            category={category}
            sharedState={this.props.sharedState}
            appendQuery={() => console.log("Appending Query")}
            updateState={this.props.updateState}
          />
        );

      case "tours":
        return (
          <Tour
            category={category}
            sharedState={this.props.sharedState}
            appendQuery={() => console.log("Appending Query")}
            updateState={this.props.updateState}
          />
        );

      case "activity":
        return (
          <Activity
            category={category}
            sharedState={this.props.sharedState}
            appendQuery={() => console.log("Appending Query")}
            updateState={this.props.updateState}
          />
        );
    }
  }
}

export default App;
