// @ts-check
import { rentals } from "../../../../dummy-data";
import {
  fetchCompulsoryRentalFilters, fetchOptionalRentalFilters,
} from "../../../../reducers";
import {DateUtil} from "../../../../util";

export default async function fetchRentals(state, updateState) {
  const compulsoryFilters = fetchCompulsoryRentalFilters();
  const optionalFilters = fetchOptionalRentalFilters();

  let checkin = compulsoryFilters.checkin;
  let checkout = compulsoryFilters.checkout;
  let place = compulsoryFilters.place;
  let adults = compulsoryFilters.adults;
  let children = compulsoryFilters.children;
  
  // maximum and minimum price flags to help monitor price changes
  let maximumPrice = 0;
  let minimumPrice = 0;

  const checkinDate = new Date(
    DateUtil.changeDateFormat(checkin, "DD-MM-YYYY", "YYYY-MM-DD"));
  const checkoutDate = new Date(
    DateUtil.changeDateFormat(checkout, "DD-MM-YYYY", "YYYY-MM-DD"));

  const diffDays = DateUtil.getNumberOfDays(checkinDate, checkoutDate);

  const rawResults = rentals.map((rental) => ({
    ...rental,
    calcPrice: rental.price,
  }));


  setTimeout(() => {
    updateState({
      rawResults,
      searchResults: rawResults,
      searchItemsLength: rawResults.length,
      searchDone: true,
      category: "rental",
      minPrice: 450,
      maxPrice: 0,
      rangeMax: optionalFilters.price_highest ?
        optionalFilters.price_highest : 450,
      rangeMin: optionalFilters.price_lowest ?
        optionalFilters.price_lowest : 0,
      numberOfDays: diffDays,
      adults,
      city: place,
      center: {lat: compulsoryFilters.lat, lng: compulsoryFilters.lng},
    });
  }, 2000);
}

/**
 * Given the number of peops expected `(children & adults)`, it calculates and
 * returns the total charge the whole hotel experience will cost.
 * 
 * How it works
 * ------------
 * As detailed in hotel renting, there are multiple pricing models that can be
 * used to assess cost of a room. Each pricing model is listed below:
 * 1. `0` - `per room`, this model simply calculates the cost as a factor of how
 * many rooms will be used to host everyone.
 * 2. `1` - `per person`
 * 3. `2` - `cost shared`
 *
 * @param {0 | 1 | 2 | undefined} pricingModel type of pricing model used by the
 * hotel.
 * @param {number} adultNumber this is the number of adults to be expected at
 * the hotel. 
 * @param {number} childrenNumber this is the number of children expected to be
 * hosted in the hotel.
 * @param {any} room object containing all the room details. 
 * @param {number} noOfDays this is the number of days the hotel is expected to
 * host the visitors.
 *
 * @returns {number} the total charge the whole experience will cost.
 */
const calculateHotelPrice = (
  pricingModel, adultNumber, childrenNumber, room, noOfDays
) => {
  let totalCharge = 0;

  if (Number(pricingModel) === 0) {
    // in this pricing model, the cost is calculated by counting the number of
    // rooms that will be used to fit all the available parties, this is a
    // factor of distributing all the adults and children based on how much is
    // the maximum capacity of each room until they all fit without exceeding
    // capacity of any single room.
    const roomsToFitAdults = Math.ceil(adultNumber / room.adult_number);
    const roomsToFitChildren = Math.ceil(childrenNumber / room.children_number);
    // if the number of rooms to fit adults are less than the number of rooms
    // needed to fit the children, the there needs to be
    const extraRoomsNeeded = (roomsToFitChildren > roomsToFitAdults) ?
      roomsToFitChildren - roomsToFitAdults :
      0;
    
    // calculate total charge
    totalCharge =
      (roomsToFitAdults + extraRoomsNeeded) * room.price * noOfDays;
    
    room.calcPrice = totalCharge;
  } else {
    // the default pricing model in the case that it lacks is: 0
    const roomsToFitAdults = Math.ceil(adultNumber / room.adult_number);
    const roomsToFitChildren = Math.ceil(childrenNumber / room.children_number);
    // if the number of rooms to fit adults are less than the number of rooms
    // needed to fit the children, the there needs to be
    const extraRoomsNeeded = (roomsToFitChildren > roomsToFitAdults) ?
      roomsToFitChildren - roomsToFitAdults :
      0;
    
    // calculate total charge
    totalCharge =
      (roomsToFitAdults + extraRoomsNeeded) * room.price * noOfDays;
    
    room.calcPrice = totalCharge;
  }

  return totalCharge;
}
