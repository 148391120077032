import {FC, useEffect} from 'react';

import {Reservation} from '../../api/reservation/types';
import ActivityIndicator from '../../components/loading';
import {OnOverlayCallback} from '../../context';

type ConfirmationDialogProps = {
  reservations?: Reservation[];
  setIsOverlay?: (isOverlay: boolean) => void;
  setOnOverlay?: OnOverlayCallback;
  setIsCancellable?: (isCancellable: boolean) => void;
};

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  setIsOverlay, setOnOverlay, setIsCancellable, 
}) => {
  useEffect(() => {
    return () => {
      setIsOverlay && setIsOverlay(false);
      setOnOverlay && setOnOverlay(null);
      setIsCancellable && setIsCancellable(true);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="bg-white p-4 rounded-md shadowmd border">
      <ActivityIndicator caption="Processing payment" />
    </div>
  );
};
