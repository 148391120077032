import {FC, useEffect, useState} from 'react';
import { FaClipboardList, FaTimes, FaTrashAlt } from 'react-icons/fa';
import { useHistory } from 'react-router';

import {Car, Draft, Property, removeDraft, ReservationCategory} from '../../../api';
import {OnOverlayCallback} from '../../../context';

type DraftDialogProps = {
  category: ReservationCategory;
  setIsOverlay?: (isOverlay: boolean) => void;
  setOnOverlay?: OnOverlayCallback;
  setIsCancellable?: (isCancellable: boolean) => void;
  removeOverlay: () => void;
  drafts: Draft<unknown>[];
};

export const DraftDialog: FC<DraftDialogProps> = ({
  category, drafts, setIsOverlay, setOnOverlay, setIsCancellable,
  removeOverlay,
}) => {
  const history = useHistory();

  const [propertyDrafts, setPropertyDrafts] = useState<Draft<unknown>[]>(drafts);

  const removePropertyDraft = (idx: number) => {
    const newDrafts = [...propertyDrafts];
    const [removedDraft] = newDrafts.splice(idx, 1);

    setPropertyDrafts(newDrafts);

    console.log("Rmoving draft from firestore()");

    if (removedDraft.id) {
      removeDraft(removedDraft.id, (err, res) => {
        if (err) {
          console.error(err);
          return;
        }
  
        if (res) {
          console.info(`Successfully removed draft ${removedDraft.id}`);
        }
      });
    }
  };

  const editDraft = (draft: Draft<unknown>) => {
    history.push(`/property-owner/draft/${draft.category}/${draft.id}`);
    removeOverlay();
  };

  useEffect(() => {
    return () => {
      setIsCancellable && setIsCancellable(true);
      setIsOverlay && setIsOverlay(false);
      setOnOverlay && setOnOverlay(null);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="w-72 bg-white rounded-md shadow-md overflow-hidden sm:w-full sm:max-w-xl">
      {/** Header **/}
      <div className="h-14 bg-safari-gold shadow-md flex flex-row items-center px-6 justify-between text-white">
        {/** Action **/}
        <div className="flex flex-row items-center">
          <FaTimes size={24} className="flex-shrink-0" onClick={removeOverlay} />
          <div className="ml-6">
            <h6 className="text-lg font-semibold">
              {`${category} drafts`}
            </h6>
            <p className="text-xs">
              Click on a draft to continue editing
            </p>
          </div>
        </div>
        {/** End of action **/}
      </div>
      {/** End of Header **/}

      {/** Dialog content **/}
      <div className="px-6 max-h-96 overflow-y-scroll py-3">
        {/** Example draft **/}
        {propertyDrafts.map((draft, idx) => (
          <div
            key={draft.id}
            className="flex flex-row h-12 items-center w-full border-b"
          >
            <FaClipboardList
              size={24}
              onClick={() => {
                editDraft(draft);
              }}
            />
            <div
              className="ml-3 w-full overflow-hidden cursor-pointer"
              onClick={() => {
                editDraft(draft);
              }}
            >
              <h6
                className={
                  "text-base font-semibold w-full overflow-ellipsis " +
                  "whitespace-nowrap overflow-hidden"
                }
              >
                {category === "car" && (
                  (draft.draft as Car).reg_no
                )}

                {category !== "car" && (
                  (draft.draft as Property).title
                )}
              </h6>
              <p
                className={
                  "text-sm w-full overflow-ellipsis whitespace-nowrap " +
                  "overflow-hidden"
                }
              >
                {(draft.draft as Property).location}
              </p>
            </div>
            <FaTrashAlt
              size={24}
              className="text-error flext-shrink-0 cursor-pointer"
              onClick={() => {
                removePropertyDraft(idx);
              }}
            />
          </div>
        ))}
        {/** End of example draft **/}

      </div>
      {/** End of dialog content **/}

      {/** Actions **/}
      <div
        className="h-12 flex flex-row justify-end items-center px-6 border-t"
      >
        <button
          className={
            "h-9 bg-white border px-4 rounded-md shadow-sm font-semibold " +
            "border-error text-error"
          }
          onClick={removeOverlay}
        >
          CANCEL
        </button>

        <button
          className={
            "h-9 border px-4 rounded-md shadow-sm font-semibold ml-3 " +
            "bg-secondary text-white"
          }
          onClick={() => {
            history.push(`/property-owner/new/${category}`);
          }}
        >
          CREATE NEW
        </button>
      </div>
      {/** End of actions **/}
    </div>
  );
};
