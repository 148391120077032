import { Link } from 'react-router-dom';

/**
 * This submt button is exported as it will be in use over several components.
 * This
 *
 * @todo make it sensitive to the search parameters and only clickable if the
 * search parameters have changed to something new. Otherwise it should be in a
 * disabled state.
 */
export function SubmitButton({disabled, url}) {
  return (
    <button
      disabled={disabled}
      type="submit"
      className="small-search-but"
      style={{
        backgroundColor: (disabled) ? "#ccc": "",
      }}
    >
      {/* {!disabled && (
        <Link to={url.toString()}>
          <div
            style={{
              textAlign: "center",
              padding: "8px 0 8px 0",
              color: "white",
              cursor: "pointer",
            }}
          >Search</div>
        </Link>
      )}
      {disabled && (
        <div
          style={{
            textAlign: "center",
            padding: "8px 0 8px 0",
            color: "white",
            cursor: "pointer",
            backgroundColor: "#ccc",
          }}
        >Search</div>
      )} */}
      <div
        style={{
          textAlign: "center",
          padding: "8px 0 8px 0",
          color: "white",
          cursor: "pointer",
        }}
      >Search</div>
    </button>
  );
}
