import {Tabs} from 'antd';
import {FC, useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router-dom';

import {ReservationCategory} from '../../../../api';

import {
  AffiliateProperties, AffiliatePropertyDetails, LinkCategories,
} from '../../components';

export const AffiliateLinks: FC<RouteComponentProps> = () => {
  const [activeTab, setActiveTab] = useState<"1" | "2" | "3">("1");
  const [activeCategory, setActiveCategory] =
    useState<ReservationCategory>("hotel");
  const [activeProperty, setActiveProperty] = useState<number>(-1);

  // once the active category changes, we reset the activeProperty to -1
  useEffect(() => {
    setActiveProperty(-1);
  }, [activeCategory]);

  return (
    <>
      <Tabs
        centered
        className="w-full sm:hidden"
        activeKey={activeTab}
      >
        <Tabs.TabPane key="1" tab="Categories">
          <LinkCategories
            activeCategory={activeCategory}
            onCategoryFocus={(category) => {
              setActiveCategory(category);
              setActiveTab("2");
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="2" tab="Properties">
          <AffiliateProperties
            activeProperty={activeProperty}
            category={activeCategory}
            onPropertyFocus={(propertyIdx) => {
              setActiveProperty(propertyIdx);
              setActiveTab("3");
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="3" tab="Property">
          <AffiliatePropertyDetails
            activeCategory={activeCategory}
            activeProperty={activeProperty}
            onTabDismiss={() => {
              setActiveTab("2");
            }}
          />
        </Tabs.TabPane>
      </Tabs>

      <div
        className="hidden sm:w-full sm:h-full sm:overflow-x-hidden sm:flex sm:flex-row"
        style={{
          height: "calc(100vh - 56px)",
        }}
      >
        <div
          className={`flex-shrink-0 flex-grow-0 ${
            activeTab === "3" ?
              "sm:w-0 sm:overflow-hidden lg:overflow-auto":
              "sm:w-1/2"
          } lg:w-96`}
          style={{
            transitionDuration: "150ms",
            transitionProperty: "width",
          }}
        >
          <LinkCategories
            activeCategory={activeCategory}
            onCategoryFocus={(category) => setActiveCategory(category)}
          />
        </div>
        <div className="flex-grow lg:flex-shrink-0 lg:flex-grow-0 lg:w-96">
          <AffiliateProperties
            activeProperty={activeProperty}
            category={activeCategory}
            onPropertyFocus={(propertyIdx) => {
              setActiveProperty(propertyIdx);
              setActiveTab("3");
            }}
          />
        </div>
        <div
          className={`flex-shrink-0 flex-grow-0 ${
            activeTab !== "3" ?
              "sm:w-0 sm:overflow-hidden":
              "sm:w-1/2 lg:w-96"
          } lg:flex-grow`}
          style={{
            // width: activeTabOpen ? "calc(50vw - 72px)" : 0,
            transitionDuration: "150ms",
            transitionProperty: "width",
          }}
        >
          <AffiliatePropertyDetails
            activeCategory={activeCategory}
            activeProperty={activeProperty}
            onTabDismiss={() => {
              setActiveTab("2");
            }}
          />
        </div>
      </div>
    </>
  );
};
