import { Activity, Car, Conference, Eatery, Facility, Feature, Hotel, Property, Rental, ReservationCategory, Tour } from "../api";
import { activityFacility, activityFeatures, conferenceFacilities, conferenceFeatures, eateryFacilities, eateryFeatures, hotelFacilities, hotelFeatures, rentalFacilities, rentalFeatures, stopFacilities, stopFeatures, } from "../api/features";

export const fetchFacilities = (category: ReservationCategory): Facility[] => {
  switch (category) {
    case "activity":
      return activityFacility;
    case "conference":
      return conferenceFacilities;
    case "eatery":
      return eateryFacilities;
    case "hotel":
      return hotelFacilities;
    case "rental":
      return rentalFacilities;
    case "tour":
      return stopFacilities;
    default:
      return [];
  }
};

export const fetchFeatures = (category: ReservationCategory): Feature[] => {
  switch (category) {
    case "activity":
      return activityFeatures;
    case "conference":
      return conferenceFeatures;
    case "eatery":
      return eateryFeatures;
    case "hotel":
      return hotelFeatures;
    case "rental":
      return rentalFeatures;
    case "tour":
      return stopFeatures;
    default:
      return [];
  }
};

export const getPropertyFacilities = (
  category: ReservationCategory,
  property: Activity | Car | Conference | Eatery | Hotel | Rental | Tour | Property,
): Facility[] => {
  // NOTE: This is only used for allFacilities in tours
  const allFacilities: Facility[] = [];

  switch (category) {
    case "activity":
      return (property as Activity).facilities;
    case "conference":
      return (property as Conference).facilities;
    case "eatery":
      return [];
    case "hotel":
      return (property as Hotel).facilities;
    case "rental":
      return (property as Rental).facilities;
    case "tour":
      (property as Tour).stops.forEach((stop) => allFacilities.push(...stop.facilities));

      return allFacilities;
    default:
      return [];
  }
};

export const getPropertyFeatures = (
  category: ReservationCategory,
  property: Activity | Car | Conference | Eatery | Hotel | Rental | Tour | Property,
): Feature[] => {
  // NOTE: This is only used for allFacilities in tours
  const allFeatures: Feature[] = [];

  switch (category) {
    case "activity":
      return (property as Activity).features;
    case "conference":
      return (property as Conference).features;
    case "eatery":
      return [];
    case "hotel":
      return (property as Hotel).features;
    case "rental":
      return (property as Rental).features;
    case "tour":
      (property as Tour).stops.forEach((stop) => allFeatures.push(...stop.features));

      return allFeatures;
    default:
      return [];
  }
};
