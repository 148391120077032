import type {FC} from 'react';
import { PopulatedConferenceReservation } from '../../../../../api/reservation/types';
import { DateUtil } from '../../../../../util';

import { getReservedPropertyTitle, ReservationRow } from '..';

type ConferenceReservationRowsProps = {
  reservation: PopulatedConferenceReservation;
};

export const ConferenceReservationRows: FC<ConferenceReservationRowsProps> = ({
  reservation
}) => {
  return (
    <>
      <ReservationRow
        title="Checkin"
        description={
          DateUtil.changeDateFormatFromDate(
            new Date(reservation.checkin), "DD-MM-YYYY",
          )
        }
      />

      <ReservationRow
        title="Attendees"
        description={`${reservation.people}`}
      />

      <ReservationRow
        title="Room type"
        description={getReservedPropertyTitle("conference", reservation)}
      />

      <ReservationRow
        title="Number of rooms"
        description={`${reservation.number_of_rooms}`}
      />
    </>
  );
};
