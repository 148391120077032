import type {FC} from 'react';
import { PopulatedConferenceReservation } from '../../../../../api/reservation/types';
import { DateUtil } from '../../../../../util';

import { ReservationRow } from '..';

type ConferenceReservationRowsProps = {
  reservation: PopulatedConferenceReservation;
};

export const ConferenceReservationRows: FC<ConferenceReservationRowsProps> = ({
  reservation
}) => {
  return (
    <>
      <ReservationRow
        title="Start date"
        description={
          DateUtil.getHumanReadableFormat(
            new Date(reservation.checkin),
          )
        }
      />

      {reservation.checkout && (
        <ReservationRow
          title="End date"
          description={
            DateUtil.getHumanReadableFormat(
              new Date(reservation.checkout),
            )
          }
        />
      )}

      <ReservationRow
        title="Number of Rooms"
        description={`${reservation.number_of_rooms}`}
      />
    </>
  );
};
