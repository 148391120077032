import {createContext, Dispatch, FC, SetStateAction} from "react";

/**
 * This is the base type that is extended for components that will
 * be display content in the overlay.
 */
export type OverlayContent = {
  removeOverlay: () => void;
};

export type OnOverlayCallback =
  // eslint-disable-next-line no-undef
  Dispatch<SetStateAction<(() => JSX.Element) | null>>;

export type OverlayProviderContext = {
  setIsOverlay?: (isOverlay: boolean) => void;
  setOnOverlay?: OnOverlayCallback;
  setIsCancellable?: (isCancellable: boolean) => void;
};

export const OverlayProvider = createContext<OverlayProviderContext>({});
