import {Dispatch, FC, SetStateAction, useState} from 'react';
import {FaChevronDown, FaChevronUp, FaInfoCircle} from 'react-icons/fa';

import {ItemException} from '../../../api';
import {Input, TextArea} from '../../../components/input';

type SubPropertyEditExceptionsProps = {
  exceptions: ItemException[];
  setExceptions: Dispatch<SetStateAction<ItemException[]>>;
};

export const SubPropertyEditExceptions: FC<SubPropertyEditExceptionsProps> = ({
  exceptions, setExceptions,
}) => {
  const [isMaximized, setIsMaximized] = useState<boolean>(false);

  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const addException = (newException: ItemException) => {
    setExceptions([...exceptions, newException]);
  };

  const removeException = (removableException: ItemException) => {
    let exceptionIndex = -1;

    for (let i=0; i<exceptions.length; i++) {
      if (exceptions[i].title === removableException.title && removableException.description === exceptions[i].description) {
        exceptionIndex = i;
        break;
      }
    }

    // to prevent trying to splice otua number that does not exist, still confirm
    // that it is not -1
    if (exceptionIndex > -1) {
      exceptions.splice(exceptionIndex, 1);
      setExceptions(exceptions);
    }
  };

  return (
    <div
      className={
        `border rounded-md overflow-hidden mt-3 ${
          !isMaximized ? "h-14" : ""
        } transition-all duration-150`
      }
    >
      <div
        className="h-14 flex flex-row items-center justify-between border-b px-4"
        onClick={() => {
          setIsMaximized(!isMaximized)
        }}
      >
        <div>
          <h6 className="text-xl font-semibold">Add exceptions</h6>
          <p className="text-xs opacity-70">
            Add demographic exceptions for this room
          </p>
        </div>
        {/* Expand or minimize icon to add and remove images */}
        {isMaximized && (
          <FaChevronUp size={24} />
        )}
        {!isMaximized && (
          <FaChevronDown size={24} color="#FF5900" />
        )}
        {/* Replace with minimize or maximize icon */}
      </div>
      {/* Section container */}
      <div className="p-4">
          {/* Input container */}
          <div>
            <div>
              <Input
                helper="Title of the exception"
                id="sub-property-exception"
                inputLabel="Exception"
                name="sub-property-exception"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </div>
            <div className="mt-3">
              <TextArea
                helper="Longform description of the input"
                id="exception-title"
                inputLabel="Description"
                name="sub-prop-exception"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </div>
            <hr className="my-2" />
            <div className="flex flex-row justify-end">
              <button
                className="h-9 px-3 border border-red-500 rounded-md shadow-md text-red-500 font-semibold text-sm flex items-center"
                onClick={(e) => {
                  e.preventDefault();
                  addException({title, description: [description]});

                  // reset the values
                  setTitle("");
                  setDescription("");
                }}
              >
                Add new exception
              </button>
            </div>
          </div>
          {/* End of input container */}

          {/* All Exceptions container */}
          <div className="mt-3">
            {/* Policy container */}
            {exceptions.map((exception) => (
              <div
                key={`sub_property_exception_${exception.title}`}
                className="p-4 border"
              >
                {/* Policy header */}
                <div className="flex flex-row items-center overflow-x-hidden">
                  <FaInfoCircle
                    className="flex-grow-0 flex-shrink-0"
                    size={24}
                  />
                  <h6
                    className={
                      "ml-3 text-xl font-semibold flex-grow pb-2 border-b-2"
                    }
                  >
                    {exception.title}
                  </h6>
                </div>
                {/* Policy Description */}
                <p className="text-sm mt-2 mx-2">
                  {exception.description[0]}
                </p>
                {/* End of policy description */}
                <hr className="my-2" />
                <div className="flex flex-row justify-end -mb-2">
                  <button
                    className={
                      "h-9 px-3 border border-red-500 rounded-md " +
                      "shadow-md text-red-500 font-semibold text-sm " +
                      "flex items-center"
                    }
                    onClick={() => {
                      removeException(exception)
                    }}
                  >
                    Remove exception
                  </button>
                </div>
              </div>
            ))}
          </div>
          {/* End of all Exceptions container */}
      </div>
      {/* End of Section container */}
    </div>
  );
};
