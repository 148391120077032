import {FC, useContext, useEffect, useState} from 'react';
import {Route, Switch, RouteComponentProps} from 'react-router-dom';

import {fetchAffiliateSummary, fetchAllAffliateLinks} from '../../api';
import ActivityIndicator from '../../components/loading';
import {AffiliateStateContext} from '../../context/affiliate';
import { AppStateContext } from '../../context/app-state-provider';
import {SnackbarProvider} from '../../context/snackbar-provider';
import {useAffiliateReducer} from '../../reducers/affiliate';
import {Auth} from '../../util';

import {AffiliateNavBar} from './components';
import {AffiliateNavRail} from './components/affiliate-nav-rail';
import {
  AffiliateDashboard, AffiliateLinks, AffiliateFinance, AffiliateSignup,
} from './routes';

export const Affiliate: FC<RouteComponentProps> = ({match}) => {
  const {state, actions} = useAffiliateReducer();
  
  const {dismissSnackbar, setIsVisible} = useContext(SnackbarProvider);
  const {state: {USER, SAFARI_USER}} = useContext(AppStateContext);

  const [authenticating, setAuthenticating] = useState<boolean>(true);
  const [authenticated, setAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    if (!SAFARI_USER) return;

    // we check the authLevels by making tsure that the permission flag
    // for the Affiliate is set
    // we use the userPermissions (since they may not be present since
    // legacy users do no have SafariUser.permissions, we use a truthy
    // value to first check whether it exists first)
    const userPermissions = SAFARI_USER.permissions ? SAFARI_USER.permissions : 1;
    setAuthenticating(false);
    setAuthenticated(Auth.hasAuthLevel(userPermissions, "affiliate"));
  }, [SAFARI_USER, USER]); // eslint-disable-line react-hooks/exhaustive-deps

  // check the authentication and anytime it changes, fetch or refetch the
  // affiliate links
  useEffect(() => {
    if (!authenticated) return;

    if (!SAFARI_USER) return;

    if (!USER) return;

    fetchAllAffliateLinks(USER.uid, (err, affiliateLinks) => {
      if (err) {
        console.error(err);

        return;
      }

      if (affiliateLinks) {
        actions.setAffiliateLinks(affiliateLinks);
      } else {
        actions.setAffiliateLinks([]);
      }
    });

    // fetch the summary
    fetchAffiliateSummary(USER.uid, (err, affiliateSummary) => {
      if (err) {
        console.error(err);
        setIsVisible && setIsVisible({
          fabPresent: false,
          isError: true,
          navRailPresent: true,
          title: "Unable to fetch the affiliate data",
          action: {
            label: "DISMISS",
            onClick: () => {
              dismissSnackbar && dismissSnackbar();
            },
          },
        });

        return;
      }

      if (affiliateSummary) {
        actions.setAffiliateSummary(affiliateSummary);
      } else {
        setIsVisible && setIsVisible({
          fabPresent: false,
          isError: true,
          navRailPresent: true,
          title: "Unable to fetch the affiliate data",
          action: {
            label: "DISMISS",
            onClick: () => {
              dismissSnackbar && dismissSnackbar();
            },
          },
        });
      }
    });
  }, [authenticated, SAFARI_USER, USER]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={
        `absolute top-0 right-0 bottom-0 left-0 flex flex-row ${
          (!authenticated) ? "justify-center items-center" : ""
        } overflow-y-hidden bg-white`
      }
    >
      {authenticating && (
        <ActivityIndicator
          caption=""
        />
      )}

      {!authenticating && !authenticated && (
        <AffiliateSignup
          setAuthenticated={setAuthenticated}
        />
      )}

      {!authenticating && authenticated && (
        <AffiliateStateContext.Provider value={{actions, state}}>
          <AffiliateNavRail />
          <div className="flex-grow overflow-y-scroll">
            {/* NavRail goes here */}
            {/* <div className="h-14 border-b shadow-md sticky top-0 z-50 bg-safari-gold"></div> */}
            <AffiliateNavBar />
            <Switch>
              <Route
                path={`${match.path}/`}
                component={AffiliateDashboard}
                exact={true}
              />
              <Route
                path={`${match.path}/finance`}
                component={AffiliateFinance}
              />
              <Route
                path={`${match.path}/links`}
                component={AffiliateLinks}
              />
            </Switch>
          </div>
        </AffiliateStateContext.Provider>
      )}
    </div>
  );
};
