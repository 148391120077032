import {Dispatch, FC, SetStateAction, useState} from "react";
import {FaChevronDown, FaChevronUp} from "react-icons/fa";

import {Facility} from "../../../../api";
import { Button } from "../../../../components";
import {Chip} from "../../../../components/chip";
import { Input, TextArea } from "../../../../components/input";

import {activityFacilities} from "./activity";
import {carFacilities} from "./car";
import {conferenceFacilities} from "./conference";
import {cruiseFacilities} from "./cruise";
import {eateryFacilities} from "./eatery";
import {flightFacilities} from "./flight";
import {hotelFacilities} from "./hotel";
import {rentalFacilities} from "./rental";
import {tourFacilities} from "./tour";

type EditFacilitiesProps = {
  category:
    | "hotel"
    | "flight"
    | "rental"
    | "cruise"
    | "eatery"
    | "conference"
    | "car"
    | "tour"
    | "activity";
  headerSubtitle: string;
  facilities: Facility[];
  setFacilities: Dispatch<SetStateAction<Facility[]>>;
};

export const EditSubPropertyFacilities: FC<EditFacilitiesProps> = ({
  category,
  headerSubtitle,
  facilities,
  setFacilities,
}) => {
  let allFacilities: Facility[] = [];

  switch (category) {
    case "activity":
      allFacilities = activityFacilities;
      break;
    case "car":
      allFacilities = carFacilities;
      break;
    case "conference":
      allFacilities = conferenceFacilities;
      break;
    case "cruise":
      allFacilities = cruiseFacilities;
      break;
    case "eatery":
      allFacilities = eateryFacilities;
      break;
    case "flight":
      allFacilities = flightFacilities;
      break;
    case "hotel":
      allFacilities = hotelFacilities;
      break;
    case "rental":
      allFacilities = rentalFacilities;
      break;
    case "tour":
      allFacilities = tourFacilities;
      break;
    default:
      break;
  }

  const [inactiveFacilities, setInactiveFacilities] = useState<Facility[]>(allFacilities.filter((facility) => {
    // look if the facility is present in active facilities, if not, add it to
    // inactive
    let isActive = false;

    for (let i = 0; i < facilities.length; i++) {
      isActive = facilities[i].title === facility.title;

      if (isActive) {
        break;
      }
    }

    // if is not active, it'll be added to the list of inactiveFacilities
    return !isActive;
  }));

  const [isMaximized, setIsMaximized] = useState<boolean>(false);
  const [facilityName, setFacilityName] = useState<string>("");
  const [facilityDescription, setFacilityDescription] = useState<string>("");

  const addFacility = (facility: Facility) => {
    setFacilities([...facilities, facility]);

    // look for the facility in the inactive facilities, splice it out and set
    // the inactive facilities.
    // TODO Note this might be irrelevant as a change to props may lead to a
    // refresh of the whole component.
    let facilityIndex = -1;

    for (let i=0; i<inactiveFacilities.length; i++) {
      if (inactiveFacilities[i].title === facility.title && inactiveFacilities[i].description === facility.description) {
        facilityIndex = i;
        break;
      }
    }

    if (facilityIndex !== -1) {
      const newInactiveFacilities = [...inactiveFacilities];
      newInactiveFacilities.splice(facilityIndex, 1);

      setInactiveFacilities(newInactiveFacilities);
    }
  };

  const removeFacility = (removableFacility: Facility) => {
    let activeFacilityIndex = -1;

    for (let i=0; i<facilities.length; i++) {
      if (facilities[i].description === removableFacility.description && removableFacility.title === facilities[i].title) {
        activeFacilityIndex = i;
        break;
      }
    }

    if (activeFacilityIndex !== -1) {
      const newFacilities = [...facilities];
      newFacilities.splice(activeFacilityIndex, 1);

      setFacilities(newFacilities);
      setInactiveFacilities([...inactiveFacilities, removableFacility]);
    }
  };

  return (
    <div
      className={
        `border rounded-md mt-3 transition-all duration-75 ${
          !isMaximized ?
            "h-14 overflow-y-hidden" :
            ""
        }`
      }
    >
      <div
        className="h-14 flex flex-row items-center justify-between border-b px-4"
        onClick={() => {
          setIsMaximized(!isMaximized);
        }}
      >
        <div>
          <h6 className="text-xl font-semibold">Edit Facilities</h6>
          <p className="text-xs opacity-70">{headerSubtitle}</p>
        </div>
        {/* Expand or minimize icon to add and remove images */}
        {isMaximized && (
          <FaChevronUp size={24} color="#FF5900" />
        )}
        {!isMaximized && (
          <FaChevronDown size={24} color="#FF5900" />
        )}
        {/* Replace with minimize or maximize icon */}
      </div>
      {/* Active Facilities */}
      <div className="p-4">
        <h5 className="text-xl">Active Facilities</h5>
        <p className="text-sm opacity-70">Facilities available</p>
        <hr className="my-2" />

        {/* Chips of active facilities */}
        <div className="flex flex-row justify-between flex-wrap">
          {facilities.map((facility) => (
            <Chip
              key={`af_${facility.title}`}
              active={true}
              label={facility.title}
              onClick={() => removeFacility(facility)}
            />
          ))}
        </div>
        {/* End of chip of active facilties */}
      </div>
      {/* End of active facilities */}

      {/* Inactive Facilities */}
      <div className="p-4">
        <h5 className="text-xl">Inactive Facilities</h5>
        <p className="text-sm opacity-70">Press on a facility to add</p>
        <hr className="my-2" />

        {/* Chips of active facilities */}
        <div className="flex flex-row justify-between flex-wrap">
          {inactiveFacilities.map((facility) => (
            <Chip
              key={`if_${facility.title}`}
              label={facility.title}
              active={false}
              onClick={() => addFacility(facility)}
            />
          ))}
        </div>
        {/* End of chip of active facilties */}
      </div>
      {/* End of inactive facilities */}
      <div className="p-4">
        <h5 className="text-2xl font-semibold">
          New facility
        </h5>
        <p className="text-sm opacity-70">
          Add facility below if its not in the options
        </p>
        <Input
          className="my-3"
          helper="Name of the facility you'd like to add"
          id="facility-title"
          inputLabel="New facility name"
          name="facility_name"
          value={facilityName}
          onChange={(e) => {
            setFacilityName(e.target.value);
          }}
        />

        <TextArea
          helper="Small description of the facility"
          id="facility-description"
          inputLabel="Facility dscription"
          name="facility_description"
          value={facilityDescription}
          onChange={(e) => {
            setFacilityDescription(e.target.value);
          }}
        />

        <div className="flex flex-row justify-center mt-3">
          <Button
            disabled={facilityDescription === "" || facilityName === ""}
            type="button"
            onClick={() => {
              const newActiveFacilities = [...facilities];
              newActiveFacilities.push({
                description: facilityDescription,
                title: facilityName,
              });

              // clearForm
              setFacilityName("");
              setFacilityDescription("");
              setFacilities(newActiveFacilities);
            }}
          >
            Add Facility
          </Button>
        </div>
      </div>
    </div>
  );
};
