// @ts-check
import React from "react";
import {
  FaChevronDown,
  FaPlus,
  FaCalendarAlt,
  FaUser,
  FaMinus,
} from "react-icons/fa";
import {IoIosBed} from "react-icons/io";
import {Link} from 'react-router-dom';
import moment from "moment";

import LocationSearchInput from "./LocationSearchInput";
import {DatePick, RangePick} from "./RangePicker";

export default class Activities extends React.Component {
  constructor(props) {
    super(props);

    let from = "";
    let to = "";

    if (props.origin === "trip-planner") {
      from =
        /[A-Za-z0-9]+-_[A-Za-z0-9]+/g
          .test(this.props.sharedState.trip_data.trip_origin) ?
            this.props.sharedState.trip_data.trip_origin.replace("-_", ", ") :
            this.props.sharedState.trip_data.trip_origin;
      to =
        /[A-Za-z0-9]+-_[A-Za-z0-9]+/g
          .test(this.props.sharedState.trip_data.trip_destination) ?
            this.props.sharedState.trip_data.trip_destination.replace("-_", ", ") :
            this.props.sharedState.trip_data.trip_destination;
    }

    this.state = {
      from,
      to,
      departure: "select date",
      return: "checkout",
      type: "round-trip",
      seat: "economy",
      adults: 1,
      children: 0,
      isStartDate: false,
      minimumDate: false,
      latLng: {
        lat: 0,
        lng: 0,
      },
    };
  }

  _showError = () => {
    alert("Enter the flight information to proceed!");
  };

  _selectToAddress = (place, latLng) => {
    // const city = place.replace(/ /g, "_").replaceAll(",", "-");
    const city = place;
    this.setState({ to: city, latLng });
  };

  _selectFromAddress = (place, latLng) => {
    // const city = place.replace(/ /g, "_").replaceAll(",", "-");
    const city = place;
    this.setState({ from: city, latLng });
  };

  _showDatePicker = () => {
    this.setState({ rangePickerVisible: false, datePickerVisible: true });
  };

  _showRangePicker = () => {
    this.setState({ datePickerVisible: false, rangePickerVisible: true });
  };

  _selectRange = (date) => {
    const formattedStartDate = moment(date[0].startDate).format("DD-MM-YYYY");
    const formattedReturnDate = moment(date[0].endDate).format("DD-MM-YYYY");
    //console.log(startDate, formattedDate);
    let minimumDate = date[0].startDate;
    minimumDate.setDate(minimumDate.getDate() + 1);
    this.setState({
      rangePickerVisible: false,
      departure: formattedStartDate,
      return: formattedReturnDate,
      minimumDate,
    });
  };
  _selectDate = (date) => {
    if (!this.state.isStartDate) {
      const formattedReturnDate = moment(date).format("DD-MM-YYYY");
      this.setState({ datePickerVisible: false, return: formattedReturnDate });
    }

    if (this.state.isStartDate) {
      let minimumDate = date;
      minimumDate.setDate(minimumDate.getDate() + 1);
      const formattedStartDate = moment(date).format("DD-MM-YYYY");
      this.setState({
        datePickerVisible: false,
        departure: formattedStartDate,
        minimumDate,
      });
    }
  };

  _openSearch = (address) => {
    window.location = address;
  };

  render() {
    return (
      <div className="flex-div">
        <div className="tab-content">
          <h1 className="tab-caption">Make a reservation for your next meal</h1>
          <div className="dropdown ">
            <div className="flex-div flex-row">
              <div className="tab-dropdown-parent">
                <FaUser
                  className="inline"
                  style={{ fontSize: 11, marginRight: 10 }}
                />
                <span>
                  {this.state.adults} Adult{this.state.adults > 1 ? "s" : ""}{" "}
                </span>
              </div>
              <div className="tab-dropdown-parent">
                <span>
                  {this.state.children} Child
                  {this.state.children > 1 ? "ren" : ""}{" "}
                </span>
                <FaChevronDown
                  className="inline"
                  style={{ fontSize: 11, marginLeft: 10 }}
                />
              </div>
            </div>
            <div className="dropdown-content">
              <div>
                <div className="title">Adults</div>
                <div className="flex-div flex-row">
                  <div
                    className="circle-but"
                    onClick={() => {
                      let newNum = this.state.adults;
                      if (newNum != 0) {
                        newNum--;
                      }
                      this.setState({ adults: newNum });
                    }}
                  >
                    <FaMinus />
                  </div>
                  <div className="num">
                    <b>{this.state.adults}</b>
                  </div>
                  <div
                    className="circle-but"
                    onClick={() => {
                      let newNum = this.state.adults;
                      if (newNum < 10) {
                        newNum++;
                      }
                      this.setState({ adults: newNum });
                    }}
                  >
                    <FaPlus />
                  </div>
                </div>
                <div className="title">Children</div>
                <div className="flex-div flex-row">
                  <div
                    className="circle-but"
                    onClick={() => {
                      let newNum = this.state.children;
                      if (newNum != 0) {
                        newNum--;
                      }
                      this.setState({ children: newNum });
                    }}
                  >
                    <FaMinus />
                  </div>
                  <div className="num">
                    <b>{this.state.children}</b>
                  </div>
                  <div
                    className="circle-but"
                    onClick={() => {
                      let newNum = this.state.children;
                      if (newNum < 10) {
                        newNum++;
                      }
                      this.setState({ children: newNum });
                    }}
                  >
                    <FaPlus />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-input-fields">
          <div style={{ flex: 1 }} className="tab-input-fields-content">
            <div
              className="flex-div flex-row tab-location-search-input items-center"
              style={{
                borderRadius: 0,
                padding: 6,
                background: "white",
                flex: 2,
              }}
            >
              <IoIosBed size={28} color="#666" />
              <LocationSearchInput
                defaultValue={this.state.from}
                selectAddress={this._selectFromAddress}
                placeholder="Where would you eat?"
                style={{
                  border: "0px solid #ccc",
                  paddingLeft: 10,
                  width: "100%",
                  position: "relative",
                }}
                onChange={(e) => {
                  this.setState({ from: e.target.value });
                }}
                suggestionsContainerStyle={{}}
              />
              {/* <input
                onChange={(event) => {
                  this.setState({
                    Departure: event.target.value.toLocaleLowerCase(),
                  });
                }}
                placeholder="From"
                style={{
                  border: "0px solid #ccc",
                  fontSize: 24,
                  paddingLeft: 10,
                  width: 160,
                }}
              /> */}
            </div>
            <div style={{ flex: 1, display: "flex", position: "relative" }}>
              <div
                className="flex-div flex-row tab-date-input-parent items-center"
              >
                <FaCalendarAlt size={28} color="#666" />
                <div
                  onClick={() => {
                    this.setState({ isStartDate: true });
                    // if (this.state.return == "checkout") {
                    this._showDatePicker();
                    //     this._showDatePicker()
                    // }} else {
                  }}
                  className="tab-date-input"
                >
                  {this.state.departure}
                </div>
              </div>
              {this.state.rangePickerVisible && (
                <RangePick
                  selectDate={this._selectRange}
                  minDate={new Date()}
                  showDatePicker={(rangePickerVisible) => {
                    this.setState({ rangePickerVisible });
                  }}
                />
              )}
              {this.state.datePickerVisible && (
                <>
                  {this.state.return == "checkout" || this.state.isStartDate ? (
                    <DatePick
                      selectDate={this._selectDate}
                      minDate={new Date()}
                      showDatePicker={(datePickerVisible) => {
                        this.setState({ datePickerVisible });
                      }}
                    />
                  ) : (
                    <DatePick
                      selectDate={this._selectDate}
                      minDate={this.state.minimumDate}
                      showDatePicker={(datePickerVisible) => {
                        this.setState({ datePickerVisible });
                      }}
                    />
                  )}
                </>
              )}
            </div>
          </div>
          {this.state.from.length == 0 ? (
            <div>
              <div
                onClick={this._showError}
                className="flex-div but  but-orange index-search-but"
              >
                SEARCH
              </div>
            </div>
          ) : (
            <>
              {this.props.origin == "trip-planner" ? (
                <div onClick={() => this._openSearch(
                      `${window.location.pathname}?place=${ 
                      this.state.from 
                      }&lat=${ 
                      this.state.latLng.lat
                      }&lng=${ 
                      this.state.latLng.lng 
                      }&date=${ 
                      this.state.departure 
                      }&adults=${ 
                      this.state.adults 
                      }&children=${ 
                      this.state.children}#search-results-eateries`
                    )}
                  >
                    <div className=" flex-div but but-orange index-search-but">
                      SEARCH
                    </div>
                </div>
              ) : (
                <Link
                  to={
                    `/search-property/eatery?place=${
                    this.state.from 
                    }&lat=${ 
                    this.state.latLng.lat 
                    }&lng=${ 
                    this.state.latLng.lng 
                    }&date=${ 
                    this.state.departure 
                    }&adults=${ 
                    this.state.adults 
                    }&children=${ 
                    this.state.children}`
                  }
                >
                  <div className=" flex-div but but-orange index-search-but">
                    SEARCH
                  </div>
                </Link>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}
