import {FC, useEffect, useState} from "react";
import {BrowserRouter as Router, Route} from "react-router-dom";
import {FaTimes} from "react-icons/fa";
import GoogleOneTapLogin from 'react-google-one-tap-login';

import Index from "./routes/index";
import Signin from "./routes/signin";
import TripPlanner from "./routes/trip-planner";
import ListYourProperty from "./routes/list-your-property";
import Search from "./routes/search";
import ViewProperty from "./routes/view-property";
import Checkout from "./routes/checkout";
// import AdminPartner from "./routes/admin-partner";
import User from "./routes/admin-user";
import {SU} from "./routes/su";
import {Affiliate} from './routes/affiliate';
import RecoverPassword from "./routes/signin/RecoverPassword";
import {SearchProperty} from "./routes/search-property";
import {PropertyView} from "./routes/property";
import {OverlayProvider} from "./context/overlay-provider-context";
import {Partner} from "./routes/partner";
import {ReservationManager, UserProvider} from "./context";
import {Reservation} from "./api/reservation/types";
import {PropertyCheckout} from "./routes/property-checkout";
import {Snackbar, SnackbarProps} from "./components";
import {SnackbarProvider} from "./context/snackbar-provider";
import {auth, database, User as FirebaseUser} from "firebase";
import {SearchAttraction} from "./routes/search-attraction";
import {ViewAttraction} from "./routes/view-attraction";
import {finishRegistration} from "./util/register-util";
import {AppStateContext} from "./context/app-state-provider";
import {useAppReducer} from "./reducers/app";
import regions from "./util/regions.json";
import {getCurrency} from "./util/currency-converter";
import {Info} from "./routes/info";
import {fetchUserDetails, SafariUser} from "./api";

export type CountryDetails = typeof regions.Africa[number];
export type CurrencyRates = {
  timestamp: number;
  disclaimer: string;
  license: string;
  base: string;
  rates: Record<string, number>;
};

const App: FC = () => {
  const [state, actions] = useAppReducer();

  const [isOverlay, setIsOverlay] = useState<boolean>(false);
  // eslint-disable-next-line no-undef
  const [onOverlay, setOnOverlay] = useState<null | (() => JSX.Element)>(null);
  const [isCancellable, setIsCancellable] = useState<boolean>(true);

  const [snackbarVisible, setSnackBarVisible] = useState<boolean>(true);
  const [snackbarProps, setSnackbarProps] = useState<null | SnackbarProps>(
    null
  );

  // monitors for any reservations mafe on the system
  const [reservations, setReservations] = useState<Reservation[]>([]);

  const addReservation = (reservation: Reservation) => {
    const allReservations: Reservation[] = [...reservations, reservation];

    setReservations(allReservations);
  };
  const [user, setUser] = useState<FirebaseUser | null>(null);
  const [safariUser, setSafariUser] = useState<SafariUser | null>(null);
  const [notLoggedIn, setNotLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    // register the service worker at the beginning of the mounting
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register("/firebase-messaging-sw.js");
      console.log("Registered service worker");
    }

    // first we look for whether there is a persisted currency that the user
    // "preffered" in a previous session
    // if so: we set it as the activeCurrency in the app state
    // otherwise: we use USD until we fetch the currency based on location
    const savedCurrency = getCurrency();
    console.log("Setting active currency to: ", savedCurrency);
    savedCurrency && actions.setActiveCurrency(savedCurrency);

    // user details from firebase
    auth().onAuthStateChanged((user) => {
      if (!user) {
        setNotLoggedIn(true);
      } else {
        setUser(user);
        actions.setUser(user);

        fetchUserDetails(user.uid, (err, safariUser) => {
          if (err) {
            console.error(err);

            return;
          }

          if (safariUser) {
            setSafariUser(safariUser);
            actions.setSafariUser(safariUser);
          }
        });
      }
    });

    // anytime the currency rates are updated, we perform an AppState update
    database().ref("currency-buffer").on("value", (snapshot) => {
      if (!snapshot.exists()) return;

      const rates = snapshot.val() as CurrencyRates;
      actions.setConversionRates(rates);
    });

    // get all currency details from regions.json and the currency name
    const countries = [...regions.Africa];
    // all regions
    countries.push(...regions.Americas);
    countries.push(...regions.Asia);
    countries.push(...regions.Europe);
    countries.push(...regions.Oceania);

    // create a tree baed on the currency short code
    const currenyMap: Record<string, typeof countries[number]> = {};

    countries.forEach((country) => {
      currenyMap[country.code] = country;
    });

    actions.setCountryDetails(currenyMap);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(() => {
     if (isOverlay) {
       // before we stop scrolling in the body, we make sure that
      //  we have scrolled to the top to prevent bugs like the user
      // having scrolled past the original viewpart and now not
      // having the ability to scroll to top to cancel the
      // dropdown
       window.scrollTo({left: 0, top: 0, behavior: "smooth"});
      //  stop the scrolling ability ouside the overlay
       document.body.style.overflow = 'hidden';
      }
     // unset the scrolling if the overlay is removed
     if (!isOverlay) document.body.style.overflow = 'unset';
  }, [isOverlay]);

  return (
    <Router>
      <AppStateContext.Provider value={{actions, state}}>
        <UserProvider.Provider value={{user, safariUser}}>
          <OverlayProvider.Provider
            value={{ setIsOverlay, setOnOverlay, setIsCancellable }}
          >
            <SnackbarProvider.Provider
              value={{
                setIsVisible: (snackbarProps) => {
                  setSnackBarVisible(true);
                  setSnackbarProps(snackbarProps);

                  setTimeout(() => {
                    setSnackBarVisible(false);
                    setSnackbarProps(null);
                  }, 5000);
                },
                dismissSnackbar: () => {
                  setSnackBarVisible(false);
                  setSnackbarProps(null);
                },
              }}
            >
              <ReservationManager.Provider
                value={{
                  reservations,
                  addReservation,
                  removeReservation: () => setReservations([]),
                }}
              >

                <div id="app"
                >
                  {notLoggedIn && (
                    <div className="z-50">
                      <GoogleOneTapLogin
                        googleAccountConfigs={{
                          client_id: "707513909085-oeurhgapbkcumuqlp13eea2njcud3s6a.apps.googleusercontent.com",
                          callback: (response) => {
                            if (response.credential) {
                              const credential = auth.GoogleAuthProvider.credential(response.credential);
                              auth().signInWithCredential(credential).then((val) => {
                                setNotLoggedIn(false);
                                // create the Users_Master variables just incase
                                // it might be needed
                                if (val.user) {
                                  finishRegistration(val.user);
                                } else {
                                  console.log("No user Obj found", val.user);
                                }
                              });
                            }
                          },
                        }}
                        onSuccess={(response) => {
                          console.log("User logged in");
                        }}
                        onError={() => {
                          console.error("Unable to auto sign in");
                        }}
                      />
                    </div>
                  )}

                  {/*
                    To allow easy overlay, the entire app has on place to instantiate
                    overlay through a function call that sets the overlay on the root of
                    application, the necessary function calls are passed down to the
                    application through a OverlayProvider the aprovides the relevant
                    functions to turn on the overlay (setIsOverlay) and the Component to
                    display on overlay (setOnOverlay)
                  */}
                  {isOverlay && onOverlay && (
                    <div
                      className={
                        "absolute top-0 right-0 bottom-0 left-0 z-50 bg-black " +
                        "bg-opacity-70 flex items-center justify-center " +
                        "overflow-y-hidden"
                      }
                    >
                      {isCancellable && (
                        <FaTimes
                          size={32}
                          className="absolute right-8 top-8 text-white cursor-pointer"
                          onClick={() => {
                            setIsOverlay(false);
                            setOnOverlay(null);
                          }}
                        />
                      )}
                      {onOverlay()}
                    </div>
                  )}
                  {/* App starts here */}
                  <Route path="/" exact={true} component={Index} />
                  <Route path="/s/:route" exact={true} component={Index} />
                  <Route path="/signin" exact={true}>
                    <Signin />
                  </Route>
                  <Route path="/recover-password" exact={true}>
                    <RecoverPassword />
                  </Route>
                  <Route
                    path="/trip-planner/:route/:search/:sesh/:sesh2"
                    exact={true}
                  >
                    <TripPlanner />
                  </Route>
                  <Route path="/trip-planner/:route/:search/:sesh" exact={true}>
                    <TripPlanner />
                  </Route>
                  <Route path="/trip-planner/:route/:search" exact={true}>
                    <TripPlanner />
                  </Route>
                  <Route path="/trip-planner/:route" exact={true}>
                    <TripPlanner />
                  </Route>
                  <Route path="/trip-planner" exact={true}>
                    <TripPlanner />
                  </Route>
                  <Route path="/search/:module/:params" exact={true}>
                    <Search />
                  </Route>
                  <Route path="/search/:module" exact={true}>
                    <Search />
                  </Route>
                  <Route path="/search-property" component={SearchProperty} />
                  <Route path="/view/:module/:property_id/:params" exact={true}>
                    <ViewProperty />
                  </Route>
                  <Route path="/property" component={PropertyView} />
                  <Route path="/list-your-property" exact={true}>
                    <ListYourProperty />
                  </Route>
                  <Route
                    path="/checkout/:module/:propertyid/:urlVars"
                    exact={true}
                  >
                    <Checkout />
                  </Route>
                  <Route
                    path="/checkout/:module/:propertyid/:urlVars/:urlVars2"
                    exact={true}
                  >
                    <Checkout />
                  </Route>
                  <Route path="/property-owner" component={Partner} />
                  <Route path="/user" exact={true}>
                    <User />
                  </Route>
                  <Route path="/user/:route" exact={true}>
                    <User />
                  </Route>
                  <Route path="/search-attraction" component={SearchAttraction} />
                  <Route path="/view-attraction" component={ViewAttraction} />
                  <Route path="/property-checkout" component={PropertyCheckout} />
                  <Route path="/su" component={SU} />
                  <Route path="/info" component={Info} />
                  <Route path="/affiliate" component={Affiliate} />
                  {snackbarVisible && snackbarProps !== null && (
                    <Snackbar
                      navRailPresent={snackbarProps.navRailPresent}
                      fabPresent={snackbarProps.fabPresent}
                      isError={snackbarProps.isError}
                      title={snackbarProps.title}
                      action={snackbarProps.action}
                    />
                  )}
                </div>
              </ReservationManager.Provider>
            </SnackbarProvider.Provider>
          </OverlayProvider.Provider>
        </UserProvider.Provider>
      </AppStateContext.Provider>
    </Router>
  );
};

export default App;
