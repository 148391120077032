export default function fetchSearchCategory() {
  const searchUrl = new URL(location.href);
  let [route, category] =
    searchUrl.pathname.split("/").filter((item) => item !== "");

  if (route === "search") {
    return category;
  }

  // accounts for this being called from the trip-planner
  if (/hotel/.test(searchUrl.toString())) {
    category = "hotel";
  } else if (/flights/.test(searchUrl.toString())) {
    category = "flight";
  } else if (/rentals/.test(searchUrl.toString())) {
    category = "rentals";
  } else if (/cruises/.test(searchUrl.toString())) {
    category = "cruises";
  } else if (/eateries/.test(searchUrl.toString())) {
    category = "eateries";
  } else if (/cars/.test(searchUrl.toString())) {
    category = "cars";
  } else if (/tours/.test(searchUrl.toString())) {
    category = "tours";
  } else if (/activities/.test(searchUrl.toString())) {
    category = "activities";
  }

  return category;
}
