import {cruises} from "../../../../dummy-data";
import {
  fetchCompulsoryCruiseFilters,
  fetchOptionalCruiseFilters,
} from "../../../../reducers";

export default function fetchCruises(state, updateState) {
  const {from, to} = fetchCompulsoryCruiseFilters();
  const optionalFilters = fetchOptionalCruiseFilters();
  const rawResults = cruises.map((cruise) => ({
    ...cruise,
    calcPrice: cruise.price,
  }));

  updateState({
    rawResults,
    searchResults: rawResults,
    searchItemsLength: rawResults.length,
    searchDone: true,
    category: "cruises",
    depatureCity: from,
    destinationCity: to,
    maxPrice: 2099,
    minPrice: 0,
    rangeMax: optionalFilters.price_highest ?
      optionalFilters.price_highest : 2099,
    rangeMin: optionalFilters.price_lowest ?
      optionalFilters.price_lowest : 0,
  });
}
