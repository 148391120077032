import type {FC} from 'react';
import Lottie from 'react-lottie';

import animation from '../../../../assets/anim/loading_1.json';

export const Loading: FC = () => {
  return (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: animation,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      }}
      style={{ marginTop: "20vh", marginBottom: "35vh" }}
      height={100}
      width={100}
    />
  );
}
