import AuthContainer from "../../signin/AuthContainer";
import travellers from "../../../assets/planner.jpg";

function App() {
  return (
    <div
      className="flex-div"
      style={{
        width: "100%",
        scrollbarWidth: 0,
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <div className="display-port">
        <div
          className="flex-div flex-row"
          style={{
            background: "white",
            padding: 50,
            marginTop: 10,
            borderRadius: 20,
          }}
        >
          <div
            style={{
              textAlign: "center",
              flex: 1,
              borderLeft: "0px solid #eee",
              marginBottom: 100,
              paddingTop: 100,
            }}
            className="mobile-hidden"
          >
            <img src={travellers} width={420} />
            <h1>Plan your next trip</h1>
            <p>
              Our trip planner allows you to plan your entire trip down to the
              smallest detail
            </p>
          </div>
          <div style={{ flex: 2 }} className="trip-planner-auth-parent">
            <AuthContainer origin="trip-planner" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
