import {FC} from 'react';

import { PopulatedCarReservation } from '../../../../../api/reservation/types';
import { DateUtil } from '../../../../../util';

import { ReservationRow } from '..';

type CarReservationRows = {
  reservation: PopulatedCarReservation;
};

export const CarResevationRows: FC<CarReservationRows> = ({
  reservation,
}) => {
  return (
    <>
      <ReservationRow
        title="Checkin"
        description={
          DateUtil.changeDateFormatFromDate(
            new Date(reservation.checkin), "DD-MM-YYYY",
          )
        }
      />

      <ReservationRow
        title="Adults"
        description={`${reservation.passengers}`}
      />
    </>
  );
};
