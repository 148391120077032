import {createContext, Dispatch, SetStateAction} from "react";
import {Facility, Property} from "../../../api";

export type FilterableProperty<T = Property> = T & {
  price: number;
};

export type QuickFilterAction<T> = ((properties: T[]) => T[]) | null;
export type SetQuickFilterAction<T extends Property> = Dispatch<SetStateAction<QuickFilterAction<T>>>;

type FilterContextProps<T extends Property = Property> = {
  rawResults: T[];
  results: FilterableProperty<T>[];
  setViewableResults?: (viewableResults: T[]) => void;
  setResults?: Dispatch<SetStateAction<T[]>>;
  rawResultsStale: boolean;
  setRawResultsStale?: (rawResultsStale: boolean) => void;
  filterResultsStale: boolean;
  setFilterResultsStale?: (filterResultsStale: boolean) => void;
  addFacility?: (facility: Facility) => void;
  removeFacility?: (facility: Facility) => void;
};

export const FilterContext = createContext<FilterContextProps>({
  rawResults: [],
  results: [],
  rawResultsStale: false,
  filterResultsStale: false,
});
