// @ts-check
/* eslint-disable no-undef */

import {
  fetchCompulsoryActivitiesFilters,
  fetchCompulsoryCarFilters,
  fetchCompulsoryCruiseFilters,
  fetchCompulsoryEateriesFilters,
  fetchCompulsoryFlightFilters,
  fetchCompulsoryToursFilters,
} from "../reducers";

////////////////////////////////////////////////////////////////////////////////
// JSDoc Types
////////////////////////////////////////////////////////////////////////////////
/* eslint-disable max-len */
/**
 * @typedef {import("../routes/trip-planner/components/travel-list").TravelItem} TravelItem
 * @typedef {import("../routes/trip-planner/components/catering-list").CateringListItem} CateringListItem
 * @typedef {import("../routes/trip-planner/components/activity-list").ActivityListItem} ActivityListItem
 * @typedef {import("../reducers/search/flight-filters").FlightCompulsoryFilters} FlightCompulsoryFilters
 * @typedef {import("../reducers/search/cruise-filters").CruiseCompulsoryFilters} CruiseCompulsoryFilters
 * @typedef {import("../reducers/search/cars-filters").CompulsoryCarFilters} CompulsoryCarFilters
 */
/* eslint-enable max-len */

export function addToTripItinerary(category, item, idx) {
  console.log(`Adding to the ${category} category`);

  
  const {uid} = firebase.auth().currentUser;
  const [, tripId] = new URL(location.href).pathname
    .split("/")
    .filter((substr) => substr !== "");
  
  /**
   * @type {
   * CompulsoryCarFilters | FlightCompulsoryFilters | CruiseCompulsoryFilters
   * }
   */
  let compulsoryFilters = null;
  if (category === "flight")
    compulsoryFilters = fetchCompulsoryFlightFilters();
  else if (category === "cars")
    compulsoryFilters = fetchCompulsoryCarFilters();
  else if (category === "cruises")
    compulsoryFilters = fetchCompulsoryCruiseFilters();
  else if (category === "eateries") {
    compulsoryFilters = fetchCompulsoryEateriesFilters();
  } else if (category === "activities") {
    compulsoryFilters = fetchCompulsoryActivitiesFilters();
  } else if (category === "tours") {
    compulsoryFilters = fetchCompulsoryToursFilters();
  }

  if (category === "flight" || category === "cars" || category === "cruises") {
    console.log("Adding to travel_list")
    /**@type {TravelItem} */
    const itineraryItem = {
      category,
      departure: category === "flight" || category === "cruises" ?
        compulsoryFilters.departure :
        compulsoryFilters.checkin,
      return: category === "flight" || category === "cruises" ?
        compulsoryFilters.return:
        null,
      id: idx,
      price: item.price,
    };
    
    firebase
      .database()
      .ref(`Trip_Planner/${uid}/${tripId}/items/${category}/${idx}`)
      .set(itineraryItem)
      .then(() => {
        window.location.href = `/trip-planner/${tripId}#trip-view-travel`;
      });
  } else if (category === "eateries") {
    /**@type {CateringListItem} */
    const itineraryItem = {
      checkin: compulsoryFilters.date,
      id: idx,
      menu_item: null,
    };
    console.log(`${uid}/${tripId}/items/${category}/${idx}`);
    
    firebase
      .database()
      .ref(`Trip_Planner/${uid}/${tripId}/items/${category}/${idx}`)
      .set(itineraryItem)
      .then(() => {
        window.location.href = `/trip-planner/${tripId}#trip-view-travel`;
      });
  } else if (category === "activities" || category === "tours") {
    /**@type {ActivityListItem} */
    const itineraryItem = {
      category,
      checkin: compulsoryFilters.date,
      id: idx,
    };
    console.log(`${uid}/${tripId}/items/${category}/${idx}`);
    
    firebase
      .database()
      .ref(`Trip_Planner/${uid}/${tripId}/items/${category}/${idx}`)
      .set(itineraryItem)
      .then(() => {
        window.location.href = `/trip-planner/${tripId}#trip-view-travel`;
      });
  }
}
