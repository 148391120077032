import {FC, useEffect, useState} from 'react';
import {RiBuildingLine} from 'react-icons/ri';
import {RouteComponentProps} from 'react-router';

import {Conference as ConferenceType, fetchConferences} from '../../../../api';
import {PartnerHeader} from '../../components';

import {PropertiesSummary} from '../../components/properties-summary';

export const Conference: FC<RouteComponentProps> = () => {
  const [conferences, setConferences] = useState<ConferenceType[] | null>(null);

  useEffect(() => {
    fetchConferences((err, results) => {
      if (err) {
        console.error(err);

        return;
      }

      setConferences(results);
    });
  }, []);

  return (
    <>
      {conferences && (
        <>
          <PartnerHeader HeaderIcon={RiBuildingLine} title="All Venues" />
          <PropertiesSummary
            category="conference"
            properties={conferences}
          />
        </>
      )}
    </>
  );
};
