import {FC} from 'react';
import {Route, RouteComponentProps, Switch} from 'react-router-dom';

import {EditActivity} from './activity';
import {EditCar} from './car';
import {EditConference} from './conference';
import {Details} from './details';
import {EditEatery} from './eatery';
import {EditHotel} from './hotel';
import {EditRental} from './rental';
import {EditTour} from './tour';

export const EditProperty: FC<RouteComponentProps> = ({match}) => {
  return (
    <Switch>
      <Route
        exact={true}
        path={`${match.path}/activity/:property_id`}
        component={EditActivity}
      />
      <Route
        exact={true}
        path={`${match.path}/car/:property_id`}
        component={EditCar}
      />
      <Route
        exact={true}
        path={`${match.path}/conference/:property_id`}
        component={EditConference}
      />
      <Route
        exact={true}
        path={`${match.path}/eatery/:property_id`}
        component={EditEatery}
      />
      <Route
        exact={true}
        path={`${match.path}/hotel/:property_id`}
        component={EditHotel}
      />
      <Route
        exact={true}
        path={`${match.path}/rental/:property_id`}
        component={EditRental}
      />
      <Route
        exact={true}
        path={`${match.path}/tour/:property_id`}
        component={EditTour}
      />
      <Route
        exact={true}
        path={`${match.path}/:category/:id/details`}
        component={Details}
      />
    </Switch>
  );
};
