import {FC, useContext, useEffect} from 'react';
import {Route, Switch, useLocation} from "react-router-dom";
import type {RouteComponentProps} from "react-router-dom";


import {AppFooter} from "../index/components/app-footer"

import {HotelProperty} from './routes/hotel';
import {RentalProperty} from './routes/rental';
import {EateryProperty} from './routes/eatery';
import {ConferenceProperty} from './routes/conference';
import {CarProperty} from './routes/car';
import {TourProperty} from './routes/tour';
import {ActivityProperty} from './routes/activity';
import {Header} from '../../components';
import {AppStateContext} from '../../context/app-state-provider';
import { ReservationCategory } from '../../api';

export type PropertyViewProps = RouteComponentProps;

export const PropertyView: FC<PropertyViewProps> = ({match}) => {
  const location = useLocation();

  const {actions: {setAffiliateId}} = useContext(AppStateContext);
  // check for the aid (affiliate_id)
  useEffect(() => {
    // NOTE: skip the first two since the first value from the split is an
    // empty string since the pathname opens with a '/'
    const [,, category, propertyId] = location.pathname.split("/");
    const urlObj = new URLSearchParams(location.search);
    const aidPresent = urlObj.has("aid");

    if (aidPresent) {
      // set the affiliate id to the app store to make sure that we record
      // it when referencing the affiliate that made the reservation possible
      const aid = urlObj.get("aid");

      if (!aid) {
        return;
      }

      setAffiliateId({
        affiliateId: aid,
        propertyId,
        // typecase since we already know that this can only one of 9 categories
        category: (category as unknown as ReservationCategory),
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="">
      <Header.LightHeader />
        <div
          className={
            "h-full w-full flex items-center content-center justify-center " +
            "mt-4"
          }
        >
        <Switch>
          <Route path={`${match.path}/hotel/:id`} component={HotelProperty} />
          <Route path={`${match.path}/flight/:id`}>
            <div>Rendering Flight Property</div>
          </Route>
          <Route path={`${match.path}/rental/:id`} component={RentalProperty}>
          </Route>
          <Route path={`${match.path}/cruise/:id`}>
            <div>Rendering Cruise Property</div>
          </Route>
          <Route
            path={`${match.path}/eatery/:id`}
            component={EateryProperty}
          />
          <Route
            path={`${match.path}/conference/:id`}
            component={ConferenceProperty}
          />
          <Route
            path={`${match.path}/car/:id`}
            component={CarProperty}
          />
          <Route path={`${match.path}/tour/:id`}
            component={TourProperty}
          />
          <Route
            path={`${match.path}/activity/:id`}
            component={ActivityProperty}
          />
        </Switch>
      </div>
      <AppFooter origin="" />
    </div>
  );
};
