import {createContext} from 'react';

import {AffiliateReducer, initialState} from '../reducers/affiliate';

const initialArgs: AffiliateReducer = {
  state: initialState,
  actions: {
    setAffiliateLinks: () => console.log("settting Affiliate links"),
    setActiveCategory: () => console.log("Should not happen"),
    setActivePropertyIdx: () => console.log("Should not happen"),
    setAffiliateSummary: () => console.log("Should not happen"),
  },
};

export const AffiliateStateContext = createContext<AffiliateReducer>(
  initialArgs,
);
