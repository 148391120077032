import {List} from "antd";
import {FC, useContext} from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import {IoMdCheckmark, IoMdClose} from "react-icons/io";

import {AppStateContext} from "../../../context/app-state-provider";

type CurrencySheetProps = {
  removeOverlay: () => void;
};

export const CurrencySheet: FC<CurrencySheetProps> = ({
  removeOverlay,
}) => {
  const {state, actions} = useContext(AppStateContext);

  const currencyContainerRef = useOnclickOutside(() => removeOverlay());

  const shortCodeSet = new Set<string>();
  const longNameArr: Array<string | undefined> = [];
  const currencyDetails: Array<{long: string; short: string}> = [];

  for (const country in state.COUNTRY_DETAILS) {
    if (state.COUNTRY_DETAILS[country]) {
      if (!shortCodeSet.has(state.COUNTRY_DETAILS[country].currency.short)) {
        shortCodeSet.add(state.COUNTRY_DETAILS[country].currency.short);
        longNameArr.push(state.COUNTRY_DETAILS[country].currency.long);
        const longForm = state.COUNTRY_DETAILS[country].currency.long;
        if (longForm) {
          currencyDetails.push({
            long: longForm,
            short: state.COUNTRY_DETAILS[country].currency.short,
          });
        }
      }
    }
  }

  return (
    <div
      ref={currencyContainerRef}
      className="bg-white max-w-5xl absolute top-32 bottom-0 overflow-y-scroll overflow-x-hidden rounded-lg"
      style={{
        scrollbarWidth: "none",
      }}
    >
      <List
        className="mx-4"
        dataSource={currencyDetails}
        header={(
          <div className="flex flex-row justify-between items-center font-lato font-semibold text-xl">
            Select a currency
            <IoMdClose size={24} className="cursor-pointer" onClick={removeOverlay} />
          </div>
        )}
        grid={{
          column: 3,
          gutter: 16,
          lg: 3,
          xs: 1,
          sm: 2,
          md: 3,
        }}
        renderItem={(item) => (
          <div
            key={item.short}
            className="p-4 hover:bg-gray-200 flex flex-row items-center justify-between cursor-pointer"
            onClick={() => {
              actions.setActiveCurrency(item.short);
            }}
          >
            <div>
              <h6 className="font-semibold font-lato">{item.long}</h6>
              <p className="font-semibold opacity-70">{item.short}</p>
            </div>
            {item.short === state.ACTIVE_CURRENCY && (
              <IoMdCheckmark size={24} className="text-blue-300" />
            )}
          </div>
        )}
      />
    </div>
  );
};
