import {FC, MouseEventHandler} from 'react';

export type SnackbarProps = {
  fabPresent: boolean;
  navRailPresent: boolean;
  isError: boolean;
  title: string;
  action?: {
    label: string;
    onClick: MouseEventHandler;
  },
};

export const Snackbar: FC<SnackbarProps> = ({
  fabPresent, navRailPresent, isError, title, action,
}) => {
  return (
    <div
      className={
        "w-80 h-12 px-4 flex flex-row items-center shadow-lg z-50 fixed " +
        "justify-between text-white rounded-md transition-all duration-150 " +
        `${fabPresent ?
            "bottom-16" :
            "bottom-4"
        } ${
          navRailPresent ?
            "left-4 lg:left-20" :
            "left-4"
        } ${
          isError ?
            "bg-error " :
            "bg-gray-900"
        }`
      }
    >
      {title}
      {action && (
        <button
          className={
            "h-9 px-4 font-semibold flex-shrink-0 " +
            `${
              isError ?
                "text-black " :
                "text-error"
            }`
          }
        >
          {action.label}
        </button>
      )}
    </div>
  );
};
