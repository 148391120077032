import {FC, useContext, useEffect, useState} from "react";
import {
  RiMessage2Line, RiShieldUserLine, RiNotificationBadgeLine, RiNotification2Line,
  RiCalendarLine, RiWallet3Line,
} from "react-icons/ri";
import {CgClipboard} from "react-icons/cg";
import {Card, Statistic} from "antd";

import {timestampToDate} from "../../../_system/GeneralMethodology";
import {PopulatedReservation} from "../../../api/reservation/types";
import {
  deleteDraftReservation, Draft, fetchClientReservations,
  fetchPopulatedReservations, fetchReservationDrafts,
} from "../../../api";
import {OverlayProvider, UserProvider} from "../../../context";
import {database} from "firebase";
import {Button} from "../../../components";
import {ReservationSheet} from "../../../components/reservations-sheet";
import {ClientReservationOverlay} from "./client-reservation-overlay";
import {NotificationCard} from "../../../components/notification-card";
import {Notification} from "../../../api/notification/types";
import { fetchNotifications } from "../../../api/notification";

const Dashboard: FC = () => {
  const {user} = useContext(UserProvider);
  const {
    setIsOverlay, setOnOverlay,
  } = useContext(OverlayProvider);

  const [safariUser, setSafariUser] = useState<
    Record<string, string> | null
  >(null);
  const [isFetchingReservations, setIsFetchingReservations] =
    useState<boolean>(true);
  const [reservations, setReservations] = useState<PopulatedReservation[]>([]);
  const [isFetchingDraftReservations, setIsFetchingDraftReservations] =
    useState<boolean>(true);
  const [draftReservations, setDraftReservations] = useState<
    Draft<Record<string, unknown>>[]
  >([]);
  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    if (user) {
        database()
          .ref(`Users_Master/${user.uid}`)
          .once(
            "value",
            (snapshot) => {
              console.info("User");
              console.log(snapshot.val());
              setSafariUser(snapshot.val());
            }
          );

      fetchReservationDrafts((err, result) => {
        if (err) {
          console.error(err);

          return;
        }

        setIsFetchingDraftReservations(false);

        if (result) {
          setDraftReservations(result);
        }
      });

      fetchClientReservations(user.uid, (err, result) => {
        if (err) {
          console.error(err);

          return;
        }


        if (result) {
          fetchPopulatedReservations(result, (err, popReservations) => {
            setIsFetchingReservations(false);

            if (err) {
              console.error(err);

              return;
            }

            if (popReservations) {
              setReservations(popReservations);
            }
          });
        }
      });

    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (safariUser) {
      fetchNotifications((err, userNotifications) => {
        if (err) {
          console.error(err);

          return;
        }

        if (userNotifications) {
          setNotifications(userNotifications);
        }
      });
    }
  }, [safariUser]);

  const removeDraftReservation = (
    reservation: Draft<Record<string, unknown>>
  ): void => {
    const newDraftReservations = [...draftReservations];

    let draftIdx = -1;

    for (let i = 0; i < newDraftReservations.length; i++) {
      if (newDraftReservations[i].id === reservation.id) {
        draftIdx = i;
        break;
      }
    }

    if (draftIdx > -1) {
      newDraftReservations.splice(draftIdx, 1);
    }

    setDraftReservations(newDraftReservations);

    // remove the draft from the firebase store
    deleteDraftReservation(`${reservation.id}`);
  };

  const markNotificationAsRead = (idx: number) => {
    const updatedNotifications = [...notifications];
    updatedNotifications.splice(idx, 1, {...notifications[idx], read: true});

    setNotifications(updatedNotifications);
  };

  return (
    <div style={{ padding: 10, paddingLeft: 40, paddingRight: 40 }}>
      <div className="flex-col md:flex-row" style={{ display: "flex" }}>
        <div style={{ width: 350 }}>
          <div
            className="elevate-xs"
            style={{ paddingBottom: 10, background: "white", margin: 15 }}
          >
            <div style={{ marginBottom: 15, padding: 20 }}>
              <div
                style={{
                  fontSize: 15,
                  fontWeight: "bold",
                  marginBottom: 5,
                  display: "flex",
                }}
              >
                <div>
                  <RiShieldUserLine
                    style={{ fontSize: 20, marginRight: 3 }}
                  />
                </div>{" "}
                <div>Your Details</div>
              </div>
              <div
                style={{
                  width: 50,
                  background: "#ff735c",
                  padding: 3,
                  borderRadius: 100,
                  marginTop: 5,
                }}
              ></div>
            </div>
            <div style={{ margin: 20 }}>
              <div style={{ display: "flex", marginBottom: 5 }}>
                <span style={{ fontWeight: "bold", flex: 1 }}>Username</span>
                <span style={{ color: "#b9b8b8" }}>
                  {safariUser?.username}
                </span>
              </div>
              <div style={{ display: "flex", marginBottom: 5 }}>
                <span style={{ fontWeight: "bold", flex: 1 }}>Phone</span>
                <span style={{ color: "#b9b8b8" }}>
                  +{safariUser?.phone}
                </span>
              </div>
              <div style={{ display: "flex", marginBottom: 5 }}>
                <span style={{ fontWeight: "bold", flex: 1 }}>Email</span>
                <span style={{ color: "#b9b8b8" }}>
                  {safariUser?.email}
                </span>
              </div>
              <div style={{ display: "flex", marginBottom: 5 }}>
                <span style={{ fontWeight: "bold", flex: 1 }}>
                  Authentication
                </span>
                <span style={{ color: "#ff735c", fontWeight: "bold" }}>
                  {safariUser?.authLevel}
                </span>
              </div>
              <div style={{ display: "flex", marginBottom: 5 }}>
                <span style={{ fontWeight: "bold", flex: 1 }}>
                  Date Joined
                </span>
                <span style={{ color: "#b9b8b8" }}>
                  {timestampToDate(safariUser?.dateJoined)}
                </span>
              </div>

              {/* <div onClick={() => firebase.auth().signOut()} className="checkout-but" style={{ margin: 0, marginTop: 35, borderRadius: 10, background: 'linear-gradient(to left, #ff735c, rgb(255,64,129))' }}>LOGOUT</div> */}
            </div>
          </div>
          <div
            className="elevate-xs"
            style={{ background: "white", margin: 15 }}
          >
            <div style={{ marginBottom: 15, padding: 20 }}>
              <div
                style={{
                  fontSize: 15,
                  fontWeight: "bold",
                  marginBottom: 5,
                  display: "flex",
                }}
              >
                <div>
                  <RiNotification2Line
                    style={{ fontSize: 20, marginRight: 3 }}
                  />
                </div>{" "}
                <div>Notifications</div>
              </div>
              <div
                style={{
                  width: 50,
                  background: "#ff735c",
                  padding: 3,
                  borderRadius: 100,
                  marginTop: 5,
                }}
              ></div>
              <div
                style={{
                  width: "100%",
                  padding: 3,
                  borderRadius: 100,
                  marginTop: 5,
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    color: "#777",
                    paddingTop: 40,
                    paddingBottom: 40,
                  }}
                >
                  <RiNotificationBadgeLine style={{ fontSize: 80 }} />
                  <h5>No new notifications</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <div
            className="flex-col md:flex-row"
            style={{
              display: "flex",
              margin: 15,
              marginRight: 0,
            }}
          >
            <div
              className="elevate-xs"
              style={{
                display: "flex",
                marginRight: 30,
                padding: 0,
                flex: 1,
                border: "1px solid #eee",
                borderRadius: 2,
              }}
            >
              <div style={{ margin: 19 }}>
                <RiCalendarLine style={{ fontSize: 80, color: "#ff735c" }} />
              </div>
              <div style={{ paddingTop: 15 }}>
                <span style={{ fontSize: 15, fontWeight: "bold" }}>
                  TRIP PLANNER
                </span>
                <br />
                <a href="/user/my-trips">
                  <div
                    className="checkout-but"
                    style={{
                      margin: 0,
                      marginTop: 5,
                      borderRadius: 10,
                      background:
                        "linear-gradient(to left, #ff735c, rgb(255,64,129))",
                    }}
                  >
                    MY TRIPS
                  </div>
                </a>
              </div>
            </div>
            <div
              className="elevate-xs"
              style={{
                display: "flex",
                marginRight: 15,
                padding: 0,
                flex: 1,
                border: "1px solid #eee",
                borderRadius: 2,
              }}
            >
              <div style={{ margin: 19 }}>
                <RiWallet3Line style={{ fontSize: 80, color: "#0a7fb5" }} />
              </div>
              <div style={{ paddingTop: 15 }}>
                <span style={{ fontSize: 15, fontWeight: "bold" }}>
                  YOUR BALANCE
                </span>
                <br />
                <span style={{ fontSize: 29, fontWeight: "bold" }}>$ 0</span>
              </div>
            </div>
          </div>
          <div className="px-4">
            <div
              style={{
                fontSize: 15,
                fontWeight: "bold",
                marginBottom: 5,
                display: "flex",
              }}
            >
              <div>
                <CgClipboard
                  style={{ fontSize: 20, marginRight: 3 }}
                />
              </div>{" "}
              <div>Reservations</div>
            </div>
            <div
              style={{
                width: 50,
                background: "#ff735c",
                padding: 3,
                borderRadius: 100,
                marginTop: 5,
              }}
            ></div>
            <div className="flex flex-col md:flex-row mt-2">
              <Card
                className="mb-3 sm:mb-0 sm:mr-3"
                actions={[
                  <Button
                    disabled={reservations.length === 0}
                    key="reservation_button"
                    type="button"
                    mode="outlined"
                    onClick={() => {
                      setOnOverlay && setOnOverlay(() => () => (
                        <ClientReservationOverlay
                          removeOverlay={() => {
                            setIsOverlay && setIsOverlay(false);
                            setOnOverlay && setOnOverlay(null);
                          }}
                          reservations={reservations}
                        />
                      ));

                      setIsOverlay && setIsOverlay(true);
                    }}
                  >
                    View all
                  </Button>
                ]}
              >
                <Statistic
                  loading={isFetchingReservations}
                  className="flex-shrink-0 flex-grow-0"
                  title="Reservations made"
                  value={reservations.length}
                  suffix="Reservations"
                />
              </Card>

              <Card
                actions={[
                  <Button
                    key="pending_reservation_button"
                    disabled={draftReservations.length === 0}
                    type="button"
                    mode="outlined"
                    onClick={() => {
                      setOnOverlay && setOnOverlay(() => () => (
                        <ReservationSheet
                          closeSheet={() => {
                            setIsOverlay && setIsOverlay(false);
                            setOnOverlay && setOnOverlay(null);
                          }}
                          reservations={draftReservations}
                          removeReservation={removeDraftReservation}
                        />
                      ));

                      setIsOverlay && setIsOverlay(true);
                    }}
                  >
                    View all
                  </Button>
                ]}
              >
                <Statistic
                  loading={isFetchingDraftReservations}
                  className="flex-shrink-0 flex-grow-0 ml-3"
                  title="Incomplete reservations"
                  value={draftReservations.length}
                  suffix="pending"
                />
              </Card>
            </div>
          </div>
          <div
            className="elevate-xs md:max-w-xl"
            style={{ background: "white", margin: 15}}
          >
            <div style={{ paddingBottom: 35, padding: 20 }}>
              <div
                style={{
                  fontSize: 15,
                  fontWeight: "bold",
                  marginBottom: 5,
                  display: "flex",
                }}
              >
                <div>
                  <RiMessage2Line style={{ fontSize: 20, marginRight: 3 }} />
                </div>{" "}
                <div>Activity History</div>
              </div>
              <div
                style={{
                  width: 50,
                  background: "#ff735c",
                  padding: 3,
                  borderRadius: 100,
                  marginTop: 5,
                }}
              ></div>
              <div
                style={{
                  width: "100%",
                  padding: 3,
                  borderRadius: 100,
                  marginTop: 5,
                }}
              >
                <div className="timeline h-56 overflow-scroll">
                  {notifications.map((notification, idx) => (
                    <NotificationCard
                      key={notification.id}
                      notification={notification}
                      onMarkAsRead={() => {
                        markNotificationAsRead(idx);
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
