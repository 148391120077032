import { Facility, Feature } from "..";

export const eateryFacilities: Facility[] = [
  {
    title: "Wifi connectivity",
    description: "Fast wifi connectivity inside the eatery present",
  },
  {
    title: "Parking",
    description: "Parking spaced provided inside the eatery",
  },
  {
    title: "Air conditioning",
    description: "Air conditioning present inside the facility",
  },
  {
    title: "TV",
    description: "TV screens present inside the eatery",
  },
  {
    title: "Toilets",
    description: "Eatery has toilet facilities available to the customers",
  },
];

export const eateryFeatures: Feature[] = [
  {
    title: "Free-delivery",
    description: "Eatery has free delivery services",
  },
  {
    title: "Credit/Debit card payments",
    description: "Eatery accepts credit/debit cards as modes of payment",
  },
  {
    title: "Cash-only",
    description: "Eatery only accepts cash as the only form of payment",
  },
  {
    title: "Outdoor-dining",
    description: "Customers have the option to dine from tables located on the outside of the eatery",
  },
  {
    title: "Complimentary food ad drinks",
    description: "Eatery provides complimentary drinks and/or foods with certain meals",
  },
];
