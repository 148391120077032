import {createContext} from "react";
import {AppReducerActions, AppState, initialState} from "../reducers/app";

type AppProviderProps = {
  state: AppState;
  actions: AppReducerActions;
};

export const AppStateContext = createContext<AppProviderProps>({
  actions: {
    setReminderShown: () => console.log("empty"),
    setCountryDetails: () => console.log("empty"),
    setConversionRates: () => console.log("empty"),
    setActiveCurrency: () => console.log("empty"),
    setAffiliateId: () => console.log("Empty"),
    setUser: () => console.log("empty"),
    setSafariUser: () => console.log("empty"),
  },
  state: initialState,
});
