// @ts-check
import {Hotel} from './hotel';
import {Flight} from './flight';
import {Rental} from './rental';
import {Cruises} from './cruises';
import {Eateries} from './eateries';
import {Conferencing} from './conferencing';
import {Cars} from './cars';
import {Tours} from './tours';
import {Activities} from './activities';

export function NewSearch({updateState}) {
  const url = new URL(location.href);
  const [_route, category] = url.pathname
    .split("/")
    .filter((category) => category !== "");

  switch (category) {
    case "hotel": 
      return (
        <Hotel updateState={updateState} />
      );
    case "flight":
      return (
        <Flight updateState={updateState} />
      );
    case "rental":
      return (
        <Rental updateState={updateState} />
      )
    case "cruises":
      return (
        <Cruises updateState={updateState} />
      );
    case "eateries":
      return (
        <Eateries updateState={updateState} />
      );
    case "conferencing":
      return (
        <Conferencing updateState={updateState} />
      );
    case "cars":
      return (
        <Cars updateState={updateState} />
      );
    case "tours":
      return (
        <Tours updateState={updateState} />
      );
    case "activities":
      return (
        <Activities updateState={updateState} />
      );
  }
}
