import {Carousel, Empty, Statistic} from 'antd';
import {FC, useContext, useEffect, useRef, useState} from 'react';
import {HiLocationMarker} from 'react-icons/hi';
import {IoMdArrowBack, IoMdClose} from 'react-icons/io';
import {CartesianGrid, Line, LineChart, XAxis, YAxis, Tooltip} from 'recharts';

import {Property, ReservationCategory} from '../../../api';
import {AffiliateStateContext} from '../../../context/affiliate';
import {DateUtil} from '../../../util';

type AffiliatePropertyDetailsProps = {
  activeProperty: number;
  activeCategory: ReservationCategory;
  onTabDismiss?: () => void;
};

export const AffiliatePropertyDetails: FC<AffiliatePropertyDetailsProps> = ({
  activeCategory, activeProperty, onTabDismiss,
}) => {
  const {state} = useContext(AffiliateStateContext);

  const containerRef = useRef<HTMLDivElement>(null);
  // conditional rendering of the width of the graph based on the
  // device
  const windowWidth = window.innerWidth;

  const [graphWidth, setGraphWidth] = useState((windowWidth < 640) ?
    windowWidth :
    windowWidth < 1024 ?
    Math.floor((windowWidth - 72) / 2) :
    windowWidth - (384 * 2 + 72)); 
  
  const [property, setProperty] = useState<Property>();
  const [currentMonthStats, setCurrentMonthStats] = useState<Array<{
    date: number; impressions: number; follow_throughs: number;
  }>>([]);
  
  useEffect(() => {
    if (activeProperty === -1) return;

    const property = state.affiliateSummary
      .affiliateLinks[activeCategory]
      .links[activeProperty]
      .property;
    
    const currentMonthStats = state.affiliateSummary
      .affiliateLinks[activeCategory] 
      .property_summary[
        state.affiliateSummary.affiliateLinks[activeCategory]
          .links[activeProperty].property_id
    ];

    setProperty(property);
    setCurrentMonthStats(currentMonthStats);
  }, [activeCategory, state, activeProperty]); // eslint-disable-line react-hooks/exhaustive-deps
  

  useEffect(() => {
    const windowWidth = window.innerWidth;
    setGraphWidth(
      windowWidth < 640
        ? windowWidth
        : windowWidth < 1024
        ? Math.floor((windowWidth - 72) / 2)
        : windowWidth - (384 * 2 + 72)
    );
  }, [window.innerWidth]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={
        "h-full w-full lg:w-full overflow-y-scroll overflow-x-hidden " +
        "no-scrollbar"
      }
    >
      {!property && (
        <div className="h-full w-full flex items-center justify-center">
          <Empty
            className="my-auto"
            description="No data to display"
          />
        </div>
      )}
      {property && (
        <>
          <div className="h-48 relative w-full" ref={containerRef}>
            <Carousel className="h-48" dotPosition="top" autoplay>
              <div className="h-48 bg-blue-300"></div>
              <div className="h-48 bg-red-300"></div>
              <div className="h-48 bg-green-300"></div>
              {property.gallery.map((picture) => (
                <div
                  key={picture.url}
                  className="h-48 bg-green-300 bg-center bg-cover bg-no-repeat"
                  style={{
                    backgroundImage: `url(${picture.url})`,
                  }}
                />
              ))}
            </Carousel>

            {/* gradien container that contains the title of the proeprty */}
            <div
              className={
                "absolute z-30 top-0 right-0 bottom-0 left-0 bg-gradient-to-b " +
                "from-transparent to-black flex flex-col p-4 justify-end"
              }
            >
              <h6 className="text-white font-bold text-3xl">
                {property.title}
              </h6>
            </div>
            <div
              className={
                "p-2 rounded-full bg-white hidden sm:block sm:absolute sm:top-4 " +
                "sm:left-4 lg:hidden z-30"
              }
              onClick={() => {
                // if there is a function to be clicked once focus is not on this
                // tab (i.e on smaller to mid screen devices) it is done so here
                onTabDismiss && onTabDismiss();
              }}
            >
              <IoMdArrowBack className="sm:hidden" size={24} />
              <IoMdClose className="hidden sm:block lg:hidden" size={24} />
            </div>
          </div>

          {/* information container */}
          <div
            className="p-4"
          >
            <div className="flex flex-row items-center mb-3">
              <HiLocationMarker size={18} className="text-safari-gold" />
              {property.location}
            </div>
            <p className="text-sm opacity-70">
              {`Created on ${
                DateUtil.getHumanReadableFormat(new Date(
                  state.affiliateSummary.affiliateLinks[activeCategory]
                    .links[activeProperty]
                    .created
                ))
              }`}
            </p>

            <hr className="my-3" />

            <div>
              <Statistic
                className="w-max mb-3"
                title="Number of impressions"
                value={
                  state.affiliateSummary.affiliateLinks[activeCategory]
                    .impressions
                }
                suffix="IMP"
                valueStyle={{
                  fontSize: 32,
                  fontWeight: 600,
                }}
              />

              <Statistic
                className="w-max mb-3"
                title="Number of follow throughs"
                value={
                  state.affiliateSummary.affiliateLinks[activeCategory]
                    .impressions
                }
                suffix="FTs"
                valueStyle={{
                  fontSize: 32,
                  fontWeight: 600,
                }}
              />
            </div>

            <hr className="my-3" />

          </div>
          <div
            id="affiliate-property-details-container"
            className="flex flex-col justify-center"
          >
            <div className="flex flex-col items-center mb-4">
              <h6 className="text-xl font-semibold">
                Follow throughs over the month
              </h6>
              <p className="text-sm opacity-70">
                Chart of follow throughs and impressions this month
              </p>
            </div>
            <LineChart
              className="h-full w-full"
              data={currentMonthStats}
              height={400}
              width={
                containerRef.current ?
                  containerRef.current.clientWidth - 16 :
                  graphWidth
              }
            >
              <Tooltip />
              <CartesianGrid stroke="#B0002033" />
              <XAxis dataKey="date" label="Date of month" />
              <YAxis dataKey="impressions" />
              <Line dataKey="impressions" type="monotone" stroke="#FF5900" />
              <Line dataKey="follow_throughs" type="monotone" stroke="#0a7fb5" />
            </LineChart>
          </div>
        </>
      )}
    </div>
  );
};
