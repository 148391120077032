import { Eatery } from "../../api";

/**
 * Returns the price that is needed to reserve a spot at this eatery in
 * terms of amount to reserve a table.
 *
 * @param eatery The eatery property that the user wants to book
 * @returns The price to dine at this eatery in terms of reservation
 */
export const calculateEateryPrice = (
  adults: number, children: number, eatery: Eatery,
): number => {
  let price = 0;
  const numberOfPeople = children + adults;
  const numberOfTables = Math.ceil(numberOfPeople / eatery.table[0].capacity);

  price = numberOfTables * eatery.table[0].price;

  return price;
};
