import {useState} from "react";

import {SectionHeader} from "./section-header";
import {Rating} from "./star-rating";

export function RatingFilter({updateState}) {
  const [rating, setRating] = useState(0);
  const url = new URL(location.href);

  const filterByStars = (newRating) => {
    setRating(newRating);
    // in order to make sure that there are not several copies of star_rating,
    // we first check for its existence before attempting to append to the
    // URLSearchParams, if present, we set the star_rating to a new value
    // else, we use the append function to create it.
    if (url.searchParams.has("star_rating")) {
      url.searchParams.set("star_rating", newRating);
    } else {
      url.searchParams.append("star_rating", newRating);
    }
    // create the new URL
    history.replaceState(null, "", url.toString());
    // since updating will happen in the main component, there is no need to
    // pass any parameters to update state.
    updateState({});
  };

  return (
    <div className="shadow-md my-2">
      <SectionHeader
        style={{borderTop: 0}}
      >
        Star Rating
      </SectionHeader>
      <div
        className="inline-flex flex-col sm:flex-row sm:justify-between"
        style={{ padding: 20, paddingTop: 0 }}>
        <Rating
          checked={rating === 5}
          rating={5}
          onChange={() => filterByStars(5)}
        />
        <Rating
          checked={rating === 4}
          rating={4}
          onChange={() => filterByStars(4)}
        />
        <Rating
          checked={rating === 3}
          rating={3}
          onChange={() => filterByStars(3)}
        />
        <Rating
          checked={rating === 2}
          rating={2}
          onChange={() => filterByStars(2)}
        />
        <Rating
          checked={rating === 1}
          rating={1}
          onChange={() => filterByStars(1)}
        />
      </div>
    </div>
  );
}
