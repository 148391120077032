/**
 * Generates strings of length `length` consisting of case sensitive letters and
 * numbers.
 *
 * @param {{}} length the length of the screen that is to be created.
 *
 * @returns {string} a string of length `length` of random letters.
 */
export function generateString(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export function numberWithCommas(x) {
  const num = Math.ceil(x);

  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function timestampToDate(UNIX_timestamp) {
  const a = new Date(UNIX_timestamp);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const year = a.getFullYear();
  const month = months[a.getMonth()];
  const date = a.getDate();
//   const hour = a.getHours();
//   const min = a.getMinutes();
//   const sec = a.getSeconds();
  const time = `${date} ${month} ${year}`;

  return time;
}
