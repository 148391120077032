import type {FC} from 'react';
import {Route, RouteComponentProps, Switch} from 'react-router-dom';
import { Header } from '../../components';
import {AppFooter} from '../index/components/app-footer';

import {Activity, Car, Conference, Eatery, Hotel, Tour, Rental} from './routes';

type SearchPropertyProps = RouteComponentProps;

export const SearchProperty: FC<SearchPropertyProps> = ({match}) => {
  return (
      <div className="">
        <Header.LightHeader />
        <div
          className={
            "h-full w-full flex items-center content-center justify-center " +
            "mt-4"
          }
        >
          <Switch>
            <Route
              path={`${match.path}/hotel`}
              component={Hotel}
            />
            <Route
              path={`${match.path}/rental`}
              component={Rental}
            />
            <Route
              path={`${match.path}/eatery`}
              component={Eatery}
            />
            <Route
              path={`${match.path}/conference`}
              component={Conference}
            />
            <Route
              path={`${match.path}/car`} component={Car} />
            <Route
              path={`${match.path}/tour`} component={Tour} />
            <Route
              path={`${match.path}/activity`} component={Activity} />
            <Route
              path={`${match.path}/`} exact={true}>
              <div>
                <h1>Comng soon</h1>
              </div>
            </Route>
          </Switch>
        </div>
        <AppFooter origin="" />
      </div>
  );
};