import {Tag} from 'antd';
import {CSSProperties, FC, MouseEventHandler} from 'react';

import fetchSearchCategory from '../routes/search/data-fetcher/util/fetch-search-category';
import {addToTripItinerary} from '../util/add-to-trip-itinerary';

/**
 * @typedef {import('../dummy-data')} Review
 */

/**
 * This is re-used for the /search route. It displays the results that are
 * fetched from the server based on the search params entered.
 *
 * @param {{
 * cover: string,
 * title: string,
 * location: string,
 * rating: number,
 * comments: Review[],
 * description: string,
 * price: number,
 * duration: string,
 * action: {
 *  label: string,
 *  onClick: import('react').MouseEventHandler<HTMLSpanElement>,
 * },
 * style: import('react').CSSProperties,
 * origin: string,
 * item: any,
 * idx: number,
 * }} props
 *
 * @returns {JSX.Element}
 */

type SafariCardProps = {
  cover: string;
  title: string;
  subtitle?: string;
  location: string;
  description: string;
  action: {
    label: string;
    onClick: MouseEventHandler<HTMLSpanElement>;
  };
  style?: CSSProperties;
  origin: string;
  item: unknown;
  idx: number;
};

export const SafariCard: FC<SafariCardProps> = ({
  cover, title, subtitle, location, description,
  action, style, origin, item, idx,
}) => {
  const addToTrip = () => {
    addToTripItinerary(fetchSearchCategory(), item, idx);
  };

  return (
    <div
      className={
        "shadow-md w-card-mobile md:flex md:flex-row md:w-full-mx-2 lg:max-w-3xl" +
        " md:p-4 md:pb-1 md:rounded-lg border mt-2"
      }
      style={{...style}}
    >
      <img
        alt={`${title}`}
        className="w-full h-48 bg-cover md:h-48 md:w-48 self-center"
        src={cover}
      />
      <div
        className={
          "flex flex-col p-4 pb-1 md:p-0 md:pl-3 md:pb-0 md:justify-center " +
          "md:justify-items-center md:self-stretch md:w-full"
        }
      >
        <div className="flex flex-col md:flex-row md:items-center justify-between">
          <div>
            <h6 className="text-2xl font-semibold md:w-96">{title}</h6>
            {subtitle && (
              <p className="text-sm opacity-70">{subtitle}</p>
            )}
          </div>
        </div>
        <hr className="hidden md:block my-2 md:mr-3" />
        <div className="flex flex-col md:flex-row md:self-start md:justify-between md:w-full">
          <div className="mb-3 md:w-96 max-w-xs">
            <h6 className="text-sm font-semibold">{location}</h6>
            <p className="text-sm">
              {description.length > 200 ?
                `${description.substr(0, 200)}...` :
                description
              }
            </p>
          </div>
          <hr className="md:hidden" />
          <div
            className={
              "md:w-48 md:h-auto md:flex md:flex-col md:items-end px-3 " +
              "md:pr-0 md:justify-end"
            }
          >
            <span
              onClick={
                (origin !== "planner") ?
                  action.onClick :
                  addToTrip
                }
              className={
                "mt-4 px-4 rounded text-sm capitalize h-9 flex items-center" +
                " justify-center bg-secondary text-white cursor-pointer" +
                " max-w-md self-center md:self-end shadow-md"
              }
            >
              {origin !== "planner" ?
                action.label.toUpperCase() :
                "ADD TO TRIP"
              }
            </span>
          </div>
        </div>
        <div className="flex justify-center md:justify-start w-full py-2 border-t mt-2">
          <Tag color="green">Big 5</Tag>
          <Tag color="green">Nairobi</Tag> 
          <Tag color="green">Game park</Tag> 
        </div>
      </div>
    </div>
  );
}
