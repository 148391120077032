// @ts-check
/* eslint-disable no-undef */
import React from "react";
import {FaBed} from "react-icons/fa";
// import GooglePayButton from '@google-pay/button-react';
import {BiHotel} from "react-icons/bi";
import {PayPalButton} from "react-paypal-button-v2";
import { auth, database } from "firebase";

class App extends React.Component {
  state = {
    bookings: [
      {
        type: 0,
        module: "hotel accomodation",
        provider: "SAROVA STANLEY",
        price: 200,
        adults: 2,
        children: 2,
        startDate: "10-6-2020",
        endDate: "12-6-2020",
        extra: {},
        numberOfDays: 1,
        icon: <FaBed />,
      },
    ],
    module: "",
    roomSelected: "",
    isLoading: true,
    checkin: "",
    checkout: "",
    adults: "",
    children: "",
    subTotal: 0,
    taxRate: 0.16,
    taxes: 0,
    total: 0,
    currency: "USD",
    phone: "",
    uid: "RE7JL1VfcBOF1RaFNWe4eMP8VDI3",
    plannerSummaryVisible: false,
  };
  componentDidMount() {
    // const href = window.location.href;
    // const module = href.split("checkout/")[1].split("=")[0];
    // console.log(module);
    // switch (module) {
    //   case "hotel":
    //     const moduleId = href.split("hotel=")[1].split("/")[0];
    //     const roomId = href.split("room=")[1].split("/")[0];
    //     const place = href
    //       .split("place=")[1]
    //       .split("&")[0]
    //       .replaceAll("-", ",")
    //       .replaceAll("_", " ");
    //     const checkin = href.split("checkin=")[1].split("&")[0];
    //     const checkout = href.split("checkout=")[1].split("&")[0];
    //     const adults = href.split("adults=")[1].split("&")[0];
    //     const children = href.split("children=")[1].split("&")[0];
    //     firebase
    //       .firestore()
    //       .collection("Hotel_Directory")
    //       .doc(moduleId)
    //       .collection("Rooms")
    //       .doc(roomId)
    //       .get()
    //       .then((doc) => {
    //         if (doc.exists) {
    //           console.log("Document data:", doc.data());
    //           const roomSelected = doc.data();
    //           const taxes = parseInt(roomSelected.price) * this.state.taxRate;
    //           const total =
    //             parseInt(roomSelected.price) * this.state.taxRate +
    //             parseInt(roomSelected.price);
    //           var uid = "RE7JL1VfcBOF1RaFNWe4eMP8VDI3";
    //           if (doc.data().uid) {
    //             uid = doc.data().uid;
    //           }
    //           this.setState({
    //             uid,
    //             total,
    //             taxes,
    //             subTotal: parseInt(roomSelected.price),
    //             place,
    //             adults,
    //             children,
    //             checkin,
    //             checkout,
    //             module,
    //             roomSelected,
    //             isLoading: false,
    //             moduleId: moduleId,
    //             roomId: roomId,
    //           });
    //         } else {
    //           // doc.data() will be undefined in this case
    //           console.log("No such document!");
    //         }
    //       })
    //       .catch(function (error) {
    //         console.log("Error getting document:", error);
    //       });
    //     break;
    //   case "trip":
    //     console.log(this.props.tripPlanner);
    //     this.setState({
    //       plannerSummaryVisible: true,
    //       plannerData: JSON.parse(this.props.tripPlanner),
    //     });
    //     break;
    // }
  }
  _sendPaymentRequest = () => {
    this.setState({ visibleScreen: "sending-payment" });
    let phone = this.state.phone;

    if (phone.startsWith("0")) {
      phone = phone.substring(1);
    }

    if (phone.startsWith("254")) {
      phone = phone.substring(3);
    }

    if (phone.length != 9) {
      this.setState({ visibleScreen: "main" });
      return alert("the number you entered is not valid/supported");
    }

    
    const currentUid = auth().currentUser.uid;
    const transactionID = Math.floor(Math.random() * 7000 + 1);
    const amount = 1; //Math.ceil(this.state.total * 1);
    const transactionReference =
      `USERS/DATA/ACCOUNTS/MPESA/TRANSACTIONS/${ 
      currentUid 
      }/${ 
      transactionID}`;
    console.log("transactionReference", transactionReference);
    
    const purchaseRef = database().ref(transactionReference);
    purchaseRef.set({
      status: "sent",
      
      time: database.ServerValue.TIMESTAMP,
      amount,
    });

    purchaseRef.on("value", (snap) => {
      if (snap.exists()) {
        const status = snap.val().status;
        switch (status) {
          case "cancelled":
            this.setState({ visibleScreen: "request-unsuccessful" });
            break;
          case "awaiting user acceptance":
            this.setState({ visibleScreen: "awaiting-payment" });
            break;
          case "success":
            alert("success");
            break;
        }
      }
    });

    const data = {
      company: "SafariXperts",
      response_ref: transactionReference,
      balance_ref: `USERS/DATA/ACCOUNTS/MPESA/BALANCE/${this.state.uid}`,
      phone_number: phone,
      amount,
    };

    fetch("https://us-central1-htolexpay.cloudfunctions.net/trigger_request", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((data) => {
        console.log("Success:", data);

        window.location.href =
          `${window.location.pathname 
          }/transaction=${ 
          transactionID 
          }&uid=${ 
          currentUid 
          }#confirmation`;
      })
      .catch((error) => {
        console.error("Error:", error);
        alert(error);
      });
  };

  render() {
    return (
      <div
        style={{ display: "flex", width: "100%", padding: 0, marginTop: 20 }}
      >
        <div className="display-port summary-parent">
          <div className="summary-left">
            <div
              style={{
                padding: 10,
                background: "#eee",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Booking Information
            </div>
            {this.props.sharedState.accomodation_list.map((item, idx) => {
              return (
                <div key={`acc_list_${idx}`} style={{ border: "1px solid #eee", marginBottom: 10 }}>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        padding: 15,
                        fontSize: 65,
                        textAlign: "center",
                        height: 75,
                        color: "#ff4f5a",
                      }}
                    >
                      <BiHotel />
                    </div>
                    <div style={{ padding: 15, flex: 1 }}>
                      <h3>{item.title}</h3>
                      <p>{item.description}</p>
                      <h3 style={{ color: "#0a7fb5" }}>{item.price} USD</h3>
                    </div>
                  </div>
                </div>
              );
            })}
            {/* {this.state.isLoading ? (
              <></>
            ) : (
              <div style={{ padding: 50, paddingTop: 20 }}>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>
                    <div style={{ fontWeight: "bold", fontSize: 19 }}>
                      Hotel
                    </div>
                    <div
                      style={{
                        fontWeight: "200",
                        color: "#777",
                        fontSize: 15,
                        marginTop: 10,
                      }}
                    >
                      {this.state.roomSelected.hotel_name}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ fontWeight: "bold", fontSize: 19 }}>Room</div>
                    <div
                      style={{
                        fontWeight: "200",
                        color: "#777",
                        fontSize: 15,
                        marginTop: 10,
                      }}
                    >
                      {this.state.roomSelected.title}
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", marginTop: 15 }}>
                  <div style={{ flex: 1 }}>
                    <div style={{ fontWeight: "bold", fontSize: 19 }}>
                      Location
                    </div>
                    <div
                      style={{
                        fontWeight: "200",
                        color: "#777",
                        fontSize: 15,
                        marginTop: 10,
                      }}
                    >
                      {this.state.place}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ fontWeight: "bold", fontSize: 19 }}>
                      Dates
                    </div>
                    <div
                      style={{
                        fontWeight: "200",
                        color: "#777",
                        fontSize: 15,
                        marginTop: 10,
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        checkin
                        <div style={{ flex: 1 }}></div>
                        {this.state.checkin}
                      </div>
                      <div style={{ display: "flex" }}>
                        checkout
                        <div style={{ flex: 1 }}></div>
                        {this.state.checkout}
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", marginTop: 15 }}>
                  <div style={{ flex: 1 }}>
                    <div style={{ fontWeight: "bold", fontSize: 19 }}>
                      Guests
                    </div>
                    <div
                      style={{
                        fontWeight: "200",
                        color: "#777",
                        fontSize: 15,
                        marginTop: 10,
                      }}
                    >
                      {this.state.adults} adult(s) & {this.state.children}{" "}
                      child(ren)
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: 25,
                    paddingTop: 25,
                    borderTop: "1px solid #ccc",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div style={{ fontWeight: "bold", fontSize: 15, flex: 1 }}>
                      Sub-total
                    </div>
                    <div
                      style={{
                        fontWeight: "200",
                        color: "#777",
                        fontSize: 15,
                        width: 100,
                      }}
                    >
                      {this.state.subTotal} {this.state.currency}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ fontWeight: "bold", fontSize: 15, flex: 1 }}>
                      Taxes
                    </div>
                    <div
                      style={{
                        fontWeight: "200",
                        color: "#777",
                        fontSize: 15,
                        width: 100,
                      }}
                    >
                      {this.state.taxes} {this.state.currency}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: 25,
                    paddingTop: 25,
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ fontWeight: "bold", fontSize: 14 }}>TOTAL</div>
                  <div>
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#0c6906",
                        fontSize: 50,
                        marginRight: 5,
                      }}
                    >
                      {this.state.total}
                    </span>
                    <sup>{this.state.currency}</sup>
                  </div>
                </div>
              </div>
            )} */}
          </div>
          <div className="summary-right">
            <div
              style={{
                padding: 10,
                background: "#ccc",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Payment Method
            </div>
            <div style={{ padding: 20, paddingBottom: 20 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: 20,
                  paddingTop: 20,
                }}
              >
                <div className="mpesa-logo" />
                <div className="form" style={{ width: "100%" }}>
                  <input
                    placeholder="Enter your mpesa number"
                    style={{
                      width: "84%",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                    value={this.state.phone}
                    onChange={(event) => {
                      this.setState({ phone: event.target.value });
                    }}
                  />
                </div>

                <div
                  onClick={this._sendPaymentRequest}
                  className="orange-but"
                  style={{
                    background: "#0c6906",
                    width: 240,
                    borderRadius: 5,
                    marginBottom: 5,
                    padding: 6,
                  }}
                >
                  PAY WITH MPESA
                </div>
                <div style={{ fontSize: 19, margin: 30 }}>OR</div>
                <div className="credit-cards" />
                <div
                  style={{
                    fontSize: 11,
                    margin: 10,
                    textAlign: "center",
                    color: "#000",
                  }}
                >
                  Use google pay to pay via credit card.
                </div>
                <PayPalButton
                  amount="0.01"
                  shippingPreference="NO_SHIPPING"
                  // eslint-disable-next-line no-unused-vars
                  onSuccess={(details, data) => {
                    //alert("Transaction completed by " + details.payer.name.given_name);
                    
                    const currentUid = auth().currentUser.uid;
                    const transactionID = Math.floor(Math.random() * 7000 + 1);
                    const amount = 10; //Math.ceil(this.state.total * 1);
                    const transactionReference =
                      `USERS/DATA/ACCOUNTS/MPESA/TRANSACTIONS/${ 
                      currentUid 
                      }/${ 
                      transactionID}`;
                    console.log("transactionReference", transactionReference);
                    
                    const purchaseRef = database()
                      .ref(transactionReference);
                    purchaseRef
                      .set({
                        status: "success",
                        
                        time: database.ServerValue.TIMESTAMP,
                        amount,
                      })
                      .then(() => {
                        window.location.href =
                          `${window.location.pathname 
                          }/transaction=${ 
                          transactionID 
                          }&uid=${ 
                          currentUid 
                          }#confirmation`;
                      });

                    // // OPTIONAL: Call your server to save the transaction
                    // return fetch("/paypal-transaction-complete", {
                    //     method: "post",
                    //     body: JSON.stringify({
                    //         orderID: data.orderID
                    //     })
                    // });
                  }}
                />
                {/* <GooglePayButton
                  environment="TEST"
                  buttonType="plain"
                  paymentRequest={{
                    apiVersion: 2,
                    apiVersionMinor: 0,
                    allowedPaymentMethods: [
                      {
                        type: "CARD",
                        parameters: {
                          allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                          allowedCardNetworks: ["MASTERCARD", "VISA"],
                        },
                        tokenizationSpecification: {
                          type: "PAYMENT_GATEWAY",
                          parameters: {
                            gateway: "gateway name",
                          },
                        },
                      },
                    ],
                    merchantInfo: {
                      merchantId: "12345678901234567890",
                      merchantName: "Demo Merchant",
                    },
                    transactionInfo: {
                      totalPriceStatus: "FINAL",
                      totalPriceLabel: "Total",
                      totalPrice: this.state.total,
                      currencyCode: "USD",
                      countryCode: "US",
                    },
                    emailRequired: true,
                  }}
                  onLoadPaymentData={(paymentRequest) => {
                    console.log("Success", paymentRequest);
                  }}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
