import { Empty } from 'antd';
import {FC, useContext} from 'react';

import {ReservationCategory} from '../../../api';
import {AffiliateStateContext} from '../../../context/affiliate';

import {AffiliatePropertyCard} from './affiliate-property-card';

type AffiliatePropertiesProps = {
  category: ReservationCategory;
  activeProperty: number;
  onPropertyFocus: (propertyIdx: number) => void;
};

export const AffiliateProperties: FC<AffiliatePropertiesProps> = ({
  activeProperty, category, onPropertyFocus,
}) => {
  const {state} = useContext(AffiliateStateContext);

  return (
    <div
      className={
        "h-full w-full border-r px-4 overflow-x-hidden overflow-y-scroll " +
        "no-scrollbar"
      }
    >
      {state.affiliateSummary.affiliateLinks[category].links.length === 0 && (
        <div className="h-full w-full flex flex-row items-center justify-center">
          <Empty
            description={`No affiliated ${category} properties`}
          />
        </div>
      )}
      {state.affiliateSummary.affiliateLinks[category].links.map((link) => (
        <AffiliatePropertyCard
          key={`${link.id}`}
          activeProperty={activeProperty}
          propertyIdx={0}
          link={link}
          onPropertyFocus={onPropertyFocus}
        />
      ))}
    </div>
  );
};
