import {FC, useState} from 'react';
import {FaTimes} from 'react-icons/fa';

import {Feature} from '../../../../api';
import { Button } from '../../../../components';
import {Chip} from '../../../../components/chip';
import { Input, TextArea } from '../../../../components/input';

import {activityFeatures} from './activity';
import {carFeatures} from './car';
import {conferenceFeatures} from './conference';
import {cruiseFeatures} from './cruise';
import {eateryFeatures} from './eatery';
import {flightFeatures} from './flight';
import {hotelFeatures} from './hotel';
import {rentalFeatures} from './rental';
import {tourFeatures} from './tour';

type EditFeaturesProps = {
  category: "hotel" | "flight" | "rental" | "cruise" | "eatery" | "conference" |
    "car" | "tour" | "activity";
  features: Feature[];
  onSave: (activeFeatures: Feature[]) => void;
  removeOverlay: () => void;
};

export const EditFeatures: FC<EditFeaturesProps> = ({
  category, features, onSave, removeOverlay,
}) => {
  // fetch all features based on the category
  let allFeatures: Feature[] = [];
  // match the category
  switch (category) {
    case "activity":
      allFeatures = activityFeatures;
      break;
    case "car":
      allFeatures = carFeatures;
      break;
    case "conference":
      allFeatures = conferenceFeatures;
      break;
    case "cruise":
      allFeatures = cruiseFeatures;
      break;
    case "eatery":
      allFeatures = eateryFeatures;
      break;
    case "flight":
      allFeatures = flightFeatures;
      break;
    case "hotel":
      allFeatures = hotelFeatures;
      break;
    case "rental":
      allFeatures = rentalFeatures;
      break;
    case "tour":
      allFeatures = tourFeatures;
      break;
    default:
      break;
  }

  const [activeFeatures, setActiveFeatures] = useState<Feature[]>(features);
  // filter all the feature that is not contained in the features to be listed
  // as inactive
  const [inactiveFeatures, setInactiveFeatures] =
    useState<Feature[]>(allFeatures.filter((feature) => {
      // traverse through active filters looking for this feature
      let isNotActive = true;

      for (let i=0; i<activeFeatures.length; i++) {
        isNotActive = activeFeatures[i].title !== feature.title;

        // means that the feature is ontained in the active feature set thus the
        // loop should stop
        if (!isNotActive) break;
      }

      return isNotActive;
    }));

  const [featureName, setFeatureName] = useState<string>("");
  const [featureDescription, setFeatureDescription] = useState<string>("");

  const removeActivity = (idx: number) => {
    const newActiveFeatures = [...activeFeatures];
    const [removed] = newActiveFeatures.splice(idx, 1);
    const newInactiveFeatures = [...inactiveFeatures, removed];

    setActiveFeatures(newActiveFeatures);
    setInactiveFeatures(newInactiveFeatures);
  };

  const addActivity = (idx: number) => {
    const [newActiveFeature] = inactiveFeatures.splice(idx, 1);
    const newCurrentActiveFeatures = [...activeFeatures, newActiveFeature]; 

    setActiveFeatures(newCurrentActiveFeatures);
    setInactiveFeatures(inactiveFeatures);
  };

  return (
    <div
      className="bg-white absolute top-0 right-0 h-full w-full sm:max-w-md"
    >
      {/** Edit Facilities App Bar */}
      <div
        className={
          "bg-safari-gold sticky top-0 h-14 px-4 shadow-md flex flex-row " +
          "justify-between items-center text-white"
        }
      >
        {/** Title and back action */}
        <div className="flex flex-row items-center">
          <FaTimes size={24} onClick={removeOverlay} />
          <div className="ml-3">
            <h6 className="text-lg">Edit Features</h6>
            <p className="text-xs">Pick features contained in your property</p>
          </div>
        </div>
        {/** Title and back action end */}

        {/** save action button */}
        <button
          className={
            "py-2 px-3 border border-black rounded-md text-black font-semibold text-sm"
          }
          onClick={() => {
            onSave(activeFeatures);
            removeOverlay();
          }}
        >
          <p className="text-sm font-semibold">
            Save
          </p>
        </button>
        {/** End save action button */}
      </div>
      {/** End of facilities app bar */}

      {/** Active Facilities */}
      <div className="p-4">
        <h5 className="text-2xl font-semibold">
          Active Facilities
        </h5>
        <p className="text-sm opacity-70">
          Facilities contained in your property
        </p>
        <hr className="my-2" />

        {/** Chips of active facilities */}
        <div className="flex flex-row justify-between flex-wrap">

          {/* Render all active Facilities */}
          {activeFeatures.map(((caf, idx) => (
            <Chip
              key={`caf_${idx}`}
              label={caf.title}
              active={true}
              onClick={() => {removeActivity(idx)}}
            />
          )))}

        </div>
        {/** End of chip of active facilties */}
      </div>
      {/** End of active facilities */}

      {/** Inactive Facilities */}
      <div className="p-4">
        <h5 className="text-2xl font-semibold">
          Inactive Facilities
        </h5>
        <p className="text-sm opacity-70">Press on a facility to add</p>
        <hr className="my-2" />

        {/** Chips of active facilities */}
        <div className="flex flex-row justify-between flex-wrap">

        {/* Render all inactive facitlities */}
        {inactiveFeatures.map((cif, idx) => (
          <Chip
            key={`cif_${idx}`}
            label={cif.title}
            onClick={() => {addActivity(idx)}}
            active={false}
          />
        ))}

        </div>
        {/** End of chip of active facilties */}
      </div>
      {/** End of inactive facilities */}
      <div className="p-4">
        <h5 className="text-2xl font-semibold">
          New feature
        </h5>
        <p className="text-sm opacity-70">
          Add feature below if its not in the options
        </p>
        <Input
          className="my-3"
          helper="Name of the feature you'd like to add"
          id="facility-title"
          inputLabel="New feature name"
          name="facility_name"
          value={featureName}
          onChange={(e) => {
            setFeatureName(e.target.value);
          }}
        />

        <TextArea
          helper="Small description of the feature"
          id="facility-description"
          inputLabel="Feature description"
          name="facility_description"
          value={featureDescription}
          onChange={(e) => {
            setFeatureDescription(e.target.value);
          }}
        />

        <div className="flex flex-row justify-center mt-3">
          <Button
            disabled={featureDescription === "" || featureName === ""}
            type="button"
            onClick={() => {
              const newActiveFacilities = [...activeFeatures];
              newActiveFacilities.push({
                description: featureDescription,
                title: featureName,
              });

              // clearForm
              setFeatureName("");
              setFeatureDescription("");
              setActiveFeatures(newActiveFacilities);
            }}
          >
            Add Feature
          </Button>
        </div>
      </div>
    </div>
  );
};
