import {FC, useContext, useEffect, useRef, useState} from 'react';
import {Select, Statistic, Table} from 'antd';
import {RouteComponentProps} from 'react-router';

import {fetchAllUsers, SafariUser, updateUserDetails} from '../../../api';
import { SnackbarProvider } from '../../../context/snackbar-provider';

export const Users: FC<RouteComponentProps> = () => {
  const {dismissSnackbar, setIsVisible} = useContext(SnackbarProvider);

  const [users, setUsers] = useState<(SafariUser & {id: string})[]>([]);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  // refs to get the heights
  const statisticsContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchAllUsers((err, allUsers) => {
      if (err) {
        console.error(err);

        return;
      }

      allUsers && setUsers(allUsers);
    });
  }, []);

  const updateUserAuthLevel = (
    userId: string, authLevel: SafariUser["authLevel"],
  ) => {
    setIsUpdating(true);

    updateUserDetails(userId, {authLevel}, (err, isSuccessful) => {
      setIsUpdating(false);

      if (err) {
        console.log(err);
        setIsVisible && setIsVisible({
          fabPresent: false,
          isError: true,
          navRailPresent: true,
          title: "Unable to update auth level",
          action: {
            label: "DISMISS",
            onClick: () => {
              dismissSnackbar && dismissSnackbar();
            },
          },
        });

        return;
      }

      if (isSuccessful) {
        // Update the value in the allUsers list being shown
        const newUsersDetails = [...users];

        for (let i=0; i<users.length; i++) {
          if (users[i].id === userId) {
            // update the user auth level and then break
            newUsersDetails.splice(i, 1, {...users[i], authLevel});
            break;
          }
        }

        setUsers(newUsersDetails);

        setIsVisible && setIsVisible({
          fabPresent: false,
          isError: false,
          navRailPresent: true,
          title: "Successfully updated auth level",
          action: {
            label: "DISMISS",
            onClick: () => {
              dismissSnackbar && dismissSnackbar();
            },
          },
        });
      } else {
        // detail that the updating was a failure
        setIsVisible && setIsVisible({
          fabPresent: false,
          isError: true,
          navRailPresent: true,
          title: "Unable to update auth level",
          action: {
            label: "DISMISS",
            onClick: () => {
              dismissSnackbar && dismissSnackbar();
            },
          },
        });
      }
    });
  };

  const adminCount = users.filter((user) => user.authLevel === "admin").length;
  const partnerCount = users.filter((user) => user.authLevel === "partner")
    .length;
  const userCount = users.filter((user) => user.authLevel === "user").length;

  return (
    <div
      className="w-full h-full flex flex-row justify-center"
    >
      <div
        className="w-full max-w-5xl py-4"
      >
        <div
          className="flex flex-row justify-around w-full mb-4"
          ref={statisticsContainerRef}
        >
          <Statistic
            title="Active admins"
            value={adminCount}
            suffix="admins"
            valueStyle={{
              color: "#cf1322",
            }}
          />
          <Statistic
            title="Partners"
            value={partnerCount}
            suffix="partners"
            valueStyle={{
              color: "#ff5900",
            }}
          />
          <Statistic
            title="Active users"
            value={userCount}
            suffix="users"
            valueStyle={{
              color: "#3f8600",
            }}
          />
        </div>

        <Table
          columns={[
            {
              dataIndex: "fNLName",
              title: "Full name",
              key: "id",
            },
            {
              dataIndex: "email",
              title: "Email",
              key: "id",
            },
            {
              dataIndex: "email",
              title: "Email",
              key: "id",
            },
            {
              dataIndex: "authLevel",
              title: "Auth Level",
              key: "id",
              render: (value, record) => {
                return (
                  <Select
                    disabled={isUpdating}
                    className="w-full"
                    value={record.authLevel}
                    onChange={(val) => {
                      // update the user auth level
                      console.log("Updating ", record.email, record.id);
                      updateUserAuthLevel(record.id, val);
                    }}
                  >
                    {["user", "partner", "admin"].map((authLevel) => (
                      <Select.Option
                        key={authLevel}
                        value={authLevel}
                      >
                        {authLevel}
                      </Select.Option>
                    ))}
                  </Select>
                );
              },
            },
          ]}
          dataSource={users}
          pagination={{pageSize: 5}}
        />
      </div>
    </div>
  );
};
