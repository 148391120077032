import draftToHtml from 'draftjs-to-html';
import {FC, useEffect, useState} from 'react';
import { RouteComponentProps } from 'react-router';

import {Info, ListContent, ParagraphContent, fetchRoute} from '../../../api';

type RendererProps = RouteComponentProps;

export const Renderer: FC<RendererProps> = ({match}) => {
  const [routeInfo, setRouteInfo] = useState<Info>();

  useEffect(() => {
    // This gets the last path that will contain the id of the path in
    // the info collection.
    const path = match.path.split("/").pop();

    // to make sure that the item got from the split is not undefined
    path && fetchRoute(path, (err, info) => {
      if (err) {
        console.log(err);

        return;
      }

      if (info) {
        setRouteInfo(info);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="w-full flex flex-row justify-center sm:ml-4">
      {routeInfo && (
        <div
          className="p-4"
          dangerouslySetInnerHTML={{
            __html: draftToHtml(routeInfo.content),
          }}
        >
        </div>
      )}
    </div>
  );
};

type ParagraphProps = {
  content: ParagraphContent;
};

const Paragraph: FC<ParagraphProps> = ({content}) => {
  return (
    <div className="">
      {content.title && (
        <h3 className="text-2xl font-semibold font-lato my-2">
          {content.title}
        </h3>
      )}
      <div className="sm:flex sm:flex-row">
        {content.image && (
          <div
            className={
              "bg-center bg-cover bg-no-repeat h-52 sm:w-52 w-full mb-3 " +
              "flex-grow-0 flex-shrink-0 mr-3 rounded-md"
            }
            style={{
              backgroundImage: `url("${content.image.url}")`,
            }}
          />
        )}
        <p className="font-merriweather text-base">
          {content.content}
        </p>
      </div>
    </div>
  );
};

type ListProps = {
  content: ListContent;
};

const List: FC<ListProps> = ({content}) => {
  return (
    <ul className="font-merriweather ml-4 list-inside list-disc">
      {content.content.map((item, idx) => (
        <li
          key={`list_item_${idx}`}
          className="text-base"
        >
          {item}
        </li>
      ))}
    </ul>
  );
};
