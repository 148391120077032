import {firestore} from 'firebase';

import {ApiFunc, Coordinates, OnCompleteCallback} from '..';
import {uploadPictures} from '../cdn';
import { getUser, NoUserFoundError } from '../user';

import type {Activity} from './types';
export type {Activity} from './types';

/**
 * This fetches the activity based on the id given to it. After attempting to fetch
 * the callback passed on to the function has the option of sending back
 * different data attributes based on the status of the fetch request from
 * firebase.
 * This function could return an error which may declare that somthing went
 * wrong during the fetch, or it could return the data about the activity based on
 * the  id given to it.
 * @param id this is the unique identifier of the activity in question.
 * @param onFetch this is the callback that will be called once the fetch
 * request is done.
 */
export const fetchActivity = async (
  id: string, onFetch: (err: Error | null, data: Activity | null) => void,
): Promise<void> => {
  try {
    const doc = await firestore().collection("activity").doc(id).get();
    
    const data = ({id: doc.id, ...doc.data()}) as Activity;
  
    onFetch(null, data);
  } catch (err) {
    // there has been an error that has occures fetching the data
    onFetch(
      new Error("An error occured while fetching the activity"),
      null,
    );
  }

};

export const checkActivityAvailability = async (
  activityId: string, checkin: number, onComplete: OnCompleteCallback<boolean>,
): Promise<void> => {
  try {
    const response = await fetch(
      `https://us-central1-safarixpertz.cloudfunctions.net/fetch_activities/${
        activityId
      }?checkin=${checkin}`,
      {
        method: "GET",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
      },
    );

    if (response.status === 200) {
      const {isAvailable} = (await response.json() as {isAvailable: boolean});

      onComplete(null, isAvailable);
    } else {
      const fetchError = new Error("Error fetching rentals");

      onComplete(fetchError, null);
    }
  } catch (err) {
    const fetchErr = new Error("An error occured while fetching");
    onComplete(fetchErr, null);
  }
};

export const fetchActivities: ApiFunc<Activity[]> = async (onComplete) => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);

    return;
  }

  try {
    const snapshot = await firestore().collection("activity")
      .where("owner", "==", user.uid)
      .get();
    const {docs} = snapshot;
    const resultActivities: Activity[] = [];

    docs.forEach((doc) => {
      const activity = ({id: doc.id, ...doc.data()} as Activity);

      resultActivities.push(activity);
    });

    // return all the activities from firebase
    onComplete(null, resultActivities);
  } catch (err) {
    onComplete(
      new Error("An errror occured while fetching the activities"),
      null
    );
  }
};

export const filterActivities = async (
  location: string, coords: Coordinates,
  onComplete: OnCompleteCallback<Activity[]>,
): Promise<void> => {
  try {
    const response = await fetch(
      "https://us-central1-safarixpertz.cloudfunctions.net/fetch_activities",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify({
          location,
          coords,
        }),
      },
    );

    // make sure that the response is successful
    if (response.status === 200) {
      const data = (await response.json()) as Activity[];

      onComplete(null, data);
    } else {
      // an error occured during fetching
      const fetchError = new Error("Error occured fetching results");
      onComplete(fetchError, null);
    }
  } catch (err) {
    const fetchError = new Error("Error occured fetching results");
    onComplete(fetchError, null);
  }
};

export const postActivity = (
  newActivity: Activity,
  onComplete: OnCompleteCallback<Activity>,
): void => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);

    return;
  }

  const activityPictures = newActivity.gallery;

  // upload all the pictures and then get back the feedback
  uploadPictures("activity", activityPictures, (err, uploadedPictures) => {
    if (err) {
      onComplete(err, null);
      return;
    }

    if (uploadedPictures !== null) {
      newActivity.gallery = uploadedPictures;

      // after the pictures are uploaded, upload the activity to the firestore
      firestore()
        .collection("activity")
        .add({...newActivity, owner: user.uid} as Activity)
        .then(async (val) => {
          const snapshot = await val.get();
          const activity =
            ({id: snapshot.id, ...snapshot.data()} as Activity);
          onComplete(null, activity);
        })
        .catch((err) => {
          onComplete(err, null);
        });
    } else {
      // something went wrong as there is no error yet the puctures are still
      // null
      onComplete(
        new Error("No error. Pictures however not uploaded"),
        null,
      );
    }
  });
  
};

export const updateActivity = (
  propertyId: string,
  newActivity: Activity,
  onComplete: OnCompleteCallback<Activity>,
): void => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);

    return;
  }

  const activityPictures = newActivity.gallery;

  // upload all the pictures and then get back the feedback
  uploadPictures("activity", activityPictures, async (err, uploadedPictures) => {
    if (err) {
      onComplete(err, null);
      return;
    }

    if (uploadedPictures !== null) {
      newActivity.gallery = uploadedPictures;

      // after the pictures are uploaded, upload the activity to the firestore
      const docRef = firestore()
        .collection("activity")
        .doc(propertyId);

      await docRef
        .update(newActivity)
      
      docRef.get()
        .then(async (val) => {
          const snapshot = val;
          const activity =
            ({id: snapshot.id, ...snapshot.data()} as Activity);
          onComplete(null, activity);
        })
        .catch((err) => {
          onComplete(err, null);
        });
    } else {
      // something went wrong as there is no error yet the puctures are still
      // null
      onComplete(
        new Error("No error. Pictures however not uploaded"),
        null,
      );
    }
  });
  
};
