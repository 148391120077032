// @ts-check
/* eslint-disable no-undef */
import { firestore } from "firebase";
import {fetchCompulsoryFilters, fetchOptionalFilters} from "../../../../reducers";
import {DateUtil} from "../../../../util";

////////////////////////////////////////////////////////////////////////////////
// JSDoc Types
////////////////////////////////////////////////////////////////////////////////
/**
 * @typedef {import('../../../../reducers/search').SearchState} SearchState
 */

/**
 * This function fetches hotels from the server as based on the filters input
 * by the user.
 * Complulsory Filters
 *
 * -------------------
 * These are th filters that are always present as they are carried forward from
 * the previous screen / origin (i.e. either `trip-planner` or `/s`)
 * 1. `checkin` - this is the Date the user plans to check in
 * 2. `checkout` - this is the Date the user wants to check out
 * 3. `adults` - this is the number of adults that will be accounted for in the
 *               trip.
 * 4. `children` - this is the number of children that will be accounted for in
 *                 the trip.
 * 5. `lat` - this is the latitude coordinate associated with the place listed.
 * 6. `lng` - this is the longitude coordinate associated with the place listed.
 * 7. `place` - this is the location the user wants to go.
 * 8. `filter` - this query parameter is used to highlight all the filters that
 * are to be applied to the search filter that will be used to query the
 * firestore. As such, any new filter added, has to first append `filter`
 * with the value of which filter it is. The filters highlighted by filter can
 * be called `optional filters`.
 *
 * Optional Filters
 * ----------------
 * These are filters used to query hotel results by smaller filters such as:
 * `price`, `star_rating`, `feature` and `them`.
 * 1. `price_lowest` - this is a filter that details which is the lowest price
 * the user wants to query by.
 * 2. `price_highest` - this is a filter that details which is the highest
 * price the user wants to filter by.
 * 3. `star_rating` - this is the rating with which the user wants to filter
 * hotels by.
 * 4. `feature` - this may appear more than once as there are multiple fiels a
 * user may filter by.
 * 5. `theme` - this is the theme that a user would like to filter by.
 *
 * NOTE
 * ----
 * The optional features are only stored in the URL for sharability. At no time
 * during a user session should the `location.href` be update to represent the
 * optional features, these features should only be used if the user wants to
 * copy the filters and the results that they are getting as a shareable link.
 * This is because updating the location url in the browser forces a re-fetch
 * of all the data that has already been fetched inlcuding images and the static
 * files i.e source code.
 * @param {URL} state this is the app state
 * @param {(state: any) => void} updateState this is used to update App state.
 *
 * @async fetches data from firebase asynchronously
 *
 * @returns {Promise<void>}
 */
export default async function fetchHotels(state, updateState) {
  console.info("Fetching Hotels");
  let checkin;
  let checkout;
  let adults;
  let children;
  let lat;
  let lng;
  let place;

  const compulsoryFilters = fetchCompulsoryFilters();

  const url = new URL(location.href);

  // flag is used to track the maximum price based on the hotels that were found
  // to make it accurate in terms of the maximum price recorded on the tab
  let maximumPrice = 0;
  let minimumPrice = 0; // flag to help keep track of the minimum price.

  const optionalFilters = fetchOptionalFilters();


  if (url.searchParams.has("checkin")) {
    checkin = url.searchParams.get("checkin");
  }

  if (url.searchParams.has("checkout")) {
    checkout = url.searchParams.get("checkout");
  }

  if (url.searchParams.has("adults")) {
    adults = Number(url.searchParams.get("adults"));
  } else {
    // if this property is not set, set the adults to `1`
    adults = 1;
  }

  if (url.searchParams.has("children")) {
    children = Number(url.searchParams.get("childern"));
  } else {
    // if there is no children's field, we assume there are no children to be
    // budgeted for.
    children = 0;
  }

  if (url.searchParams.has("lat")) {
    lat = Number(url.searchParams.get("lat"));
  }

  if (url.searchParams.has("lng")) {
    lng = Number(url.searchParams.get("lng"));
  }

  if (url.searchParams.has("place")) {
    // for this field, we do not take the whole field but instead only take the
    // first field before the comma (,) this is because the second qualifier may
    // not be used while the user was entering the of their property.
    const [loc, ] = url.searchParams.get("place").split(",");
    place = compulsoryFilters.place;
  }

  // to query the firestore, we use the blanket filter of locaton and other
  // fine-tune features are applied on the front end side
  
  const searchQuery = firestore()
    .collectionGroup("Rooms")
    .where("location_array", "array-contains", place);
  
  // const querySnapshot = await searchQuery.get();
  // check for filters
  // monitor how React router works

  const checkinDate = new Date(flipDate(checkin));
  const checkoutDate = new Date(flipDate(checkout));

  const ONE_DAY = 1000 * 60 * 60 * 24;
  const availableHotels = {}; // stores all viable hotels
  // number of the days the user wants to stay
  let diffDays =
    Math.round(
      Math.abs(checkoutDate.getTime() - checkinDate.getTime()) / ONE_DAY);

  if (diffDays === 0) {
    diffDays = 1;
  }

  const datasnap = await searchQuery.get();
  datasnap.forEach((doc) => {
    let isAvailable = true;

    const data = doc.data();
    const hotelId = doc.ref.path
      .split("Hotel_Directory/")[1]
      .split("/Rooms")[0];
    const roomId = doc.ref.path.split("Rooms/")[1];
    data["hotelId"] = hotelId;
    data["roomId"] = roomId;
    for (let i=0; i<diffDays; i++) {
      const selectedDay = new Date(checkinDate);
      selectedDay.setDate(selectedDay.getDate() + 1);
      // TODO Look for a better method to access availability
      const tag = `available_${DateUtil.getDayOfTheWeek(selectedDay.getDay())}`;
      const dateAvailable = data[tag];

      if (!dateAvailable) {
        isAvailable = false;
      }

      const monthAvailable =
        `available_${DateUtil.getMonthOfTheYear(selectedDay.getMonth())}`;
      
      if (!monthAvailable) {
        isAvailable = false;
      }

      let midnightTime = Date.UTC(
        selectedDay.getFullYear(),
        selectedDay.getMonth(),
        selectedDay.getDate(),
      );

      const availabilityExceptions = data["availability_exceptions"];

      if (availabilityExceptions) {
        if (availabilityExceptions.includes(midnightTime)) {
          isAvailable = false;
        }
      }
    }

    if (isAvailable) {
      // check if there are already any rooms associated with this hotel in the
      // results array
      if (availableHotels[hotelId] instanceof Array) {
        availableHotels[hotelId].push(data);
      } else {
        // instantiate an instance with an array with only this room
        availableHotels[hotelId] = [data];
      }

    }
  });
  // apply filters that are in the state
  // traverse through all available hotels
  // for (let hotelId in availableHotels) {
  //   // use filters to check through the hotels
  //   for (let filter in optionalFilters) {
  //     // since the filter picks up filters that may be null in the store, first
  //     // check if the filter is available and then deal with the filter if so.
  //     if (optionalFilters[filter]) {
  //       // deal with price
  //       if (filter === "price_lowest") {
  //         availableHotels[hotelId] =
  //           availableHotels[hotelId].filter((hotel) => {
  //             return hotel.price >= optionalFilters.price_lowest;
  //           });
  //       }

  //       if (filter === "price_highest") {
  //         availableHotels[hotelId] =
  //           availableHotels[hotelId].filter((hotel) => {
  //             return hotel.price <= optionalFilters.price_highest;
  //           });
  //       }

  //       if (filter === "star_rating") {
  //         availableHotels[hotelId] =
  //           availableHotels[hotelId].filter((hotel) => {
  //             return hotel.rating >= optionalFilters.star_rating;
  //           });
  //       }

  //       // TODO: Fix the theme to search by array
  //       if (filter === "theme") {
  //         availableHotels[hotelId] =
  //           availableHotels[hotelId].filter((hotel) => {
  //             return hotel.theme === optionalFilters.theme;
  //           });
  //       }

  //       // features filter, since the url parameter feature can be updated a
  //       // couple of times, the filter for feature works by checking for at
  //       // the presence of at least one of the filters specified by the user.
  //       // As such, traverse through all the features in the array and look for
  //       // the features. The hotel with more features ranks first, as compared
  //       // to hotels with lesser features.
  //       if (filter === "feature") {
  //         // populate hotel object with the number of features it has as based
  //         // on what the user is looking for.
  //         availableHotels[hotelId] = availableHotels[hotelId].map((hotel) => {
  //           // traverse all possible features
  //           let count = 0; // keeps track of all present features
  //           optionalFilters.feature.forEach((feature) => {
  //             // The room_features are stored as an array, so checking for the
  //             // room feature is as easy as reading through and looking for the
  //             // picture in the array with an index greater that -1
  //             if (hotel.room_features.indexOf(feature) !== -1) {
  //               count++;
  //             }
  //           });

  //           // update the hotel with the count
  //           hotel.count = count;
  //         });

  //         // sort all the hotels based on the feature count
  //         // sort from largest to smallest
  //         availableHotels[hotelId].sort((a, b) => b - a);
  //       }
  //     }
  //   }
  // }

  // this variable contains the final results as an array of all viable options
  // and their prices.
  const compiledResults = [];
  // after sorting based on all the features, create a pricing model for all the
  // rooms.
  console.log(availableHotels);
  for (let hotelId in availableHotels) {
    availableHotels[hotelId].forEach((hotel) => {
      // since the pricing model is not listed on each room, we use the pricing
      // model listed on the main room that is in the first position.
      const calculatedPrice = calculateHotelPrice(
        availableHotels[hotelId][0],
        adults,
        children,
        hotel,
        diffDays,
      );

      hotel.calcPrice = calculatedPrice;
      // in order to make it easier for the user to actually filter by what they
      // will be expected to pay for the whole hotel stay, we will use the
      // calculated price to give the actual ranges that will be used in the
      // RangeSlider.
      if (maximumPrice < calculatedPrice) {
        maximumPrice = calculatedPrice;
      }

      if (minimumPrice > calculatedPrice) {
        minimumPrice = calculatedPrice;
      }

      compiledResults.push(hotel);
    });
  }
  console.log("compiledResults");
  console.log(compiledResults);

  updateState({
    rawResults: compiledResults,
    searchResults: compiledResults,
    searchItemsLength: compiledResults.length,
    searchDone: true,
    category: "hotel",
    minPrice: minimumPrice,
    maxPrice: maximumPrice,
    rangeMax: optionalFilters.price_highest ?
      optionalFilters.price_highest : maximumPrice,
    rangeMin: optionalFilters.price_lowest ?
      optionalFilters.price_lowest : minimumPrice,
    numberOfDays: diffDays,
    adults,
    city: place,
    center: {lat, lng},
  });
}

/**
 * Changes the date DD-MM-YYYY to YYYY-MM-DD.
 *
 * @param {string} dateString this is the date DD-MM-YYYY
 *
 * @returns {string} this YYYY-MM-DD
 */
const flipDate = (dateString) => {
  const dateArray = dateString.split("-");
  return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
};

/**
 * Given the number of peops expected `(children & adults)`, it calculates and
 * returns the total charge the whole hotel experience will cost.
 * 
 * How it works
 * ------------
 * As detailed in hotel renting, there are multiple pricing models that can be
 * used to assess cost of a room. Each pricing model is listed below:
 * 1. `0` - `per room`, this model simply calculates the cost as a factor of how
 * many rooms will be used to host everyone.
 * 2. `1` - `per person`
 * 3. `2` - `cost shared`
 *
 * @param {0 | 1 | 2 | undefined} pricingModel type of pricing model used by the
 * hotel.
 * @param {number} adultNumber this is the number of adults to be expected at
 * the hotel. 
 * @param {number} childrenNumber this is the number of children expected to be
 * hosted in the hotel.
 * @param {any} room object containing all the room details. 
 * @param {number} noOfDays this is the number of days the hotel is expected to
 * host the visitors.
 *
 * @returns {number} the total charge the whole experience will cost.
 */
const calculateHotelPrice = (
  pricingModel, adultNumber, childrenNumber, room, noOfDays
) => {
  let totalCharge = 0;

  if (Number(pricingModel) === 0) {
    // in this pricing model, the cost is calculated by counting the number of
    // rooms that will be used to fit all the available parties, this is a
    // factor of distributing all the adults and children based on how much is
    // the maximum capacity of each room until they all fit without exceeding
    // capacity of any single room.
    const roomsToFitAdults = Math.ceil(adultNumber / room.adult_number);
    const roomsToFitChildren = Math.ceil(childrenNumber / room.children_number);
    // if the number of rooms to fit adults are less than the number of rooms
    // needed to fit the children, the there needs to be
    const extraRoomsNeeded = (roomsToFitChildren > roomsToFitAdults) ?
      roomsToFitChildren - roomsToFitAdults :
      0;
    
    // calculate total charge
    totalCharge =
      (roomsToFitAdults + extraRoomsNeeded) * room.price * noOfDays;
    
    room.calcPrice = totalCharge;
  } else {
    // the default pricing model in the case that it lacks is: 0
    const roomsToFitAdults = Math.ceil(adultNumber / room.adult_number);
    const roomsToFitChildren = Math.ceil(childrenNumber / room.children_number);
    // if the number of rooms to fit adults are less than the number of rooms
    // needed to fit the children, the there needs to be
    const extraRoomsNeeded = (roomsToFitChildren > roomsToFitAdults) ?
      roomsToFitChildren - roomsToFitAdults :
      0;
    
    // calculate total charge
    totalCharge =
      (roomsToFitAdults + extraRoomsNeeded) * room.price * noOfDays;
    
    room.calcPrice = totalCharge;
  }

  return totalCharge;
}
