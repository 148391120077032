// @ts-check
import {Component} from "react";

import TripView from "./components/trip-view";
import NavigatorView from "./components/navigator-view";
import SearchResults from "./components/results-view";
import ViewProperty from "./components/view-property";
import Checkout from "./components/checkout";
import TransactionObserver from "./components/transaction-observer";

class App extends Component {
  constructor(props) {
    super(props);
    // we look for the default highlighted route to make sure that the user is
    // directed to the correct tabviews if they choose to move to the
    // NavigatorView without interracting with the TripView component.
    const tabView = (/travel/.test(window.location.hash)) ? "0" :
      (/accomodation/.test(window.location.hash)) ? "1" :
      (/catering|eateries/.test(window.location.hash)) ? "2" :
      (/activities/.test(window.location.hash)) ? "3" :
      "0"; // if ther is no hash, the default route is used.

    this.state = {
      tabArray: [
        {
          title: "trip-view",
          active:
            window.location.hash.startsWith("#trip-view") ||
            !window.location.hash,
        },
        {
          title: "navigator-view",
          active: window.location.hash.startsWith("#navigator-view"),
        },
        {
          title: "search-results",
          active: window.location.hash.startsWith("#search-results"),
        },
        {
          title: "view-property",
          active: window.location.hash.startsWith("#view-property"),
        },
        {
          title: "checkout",
          active: window.location.hash.startsWith("#checkout"),
        },
        {
          title: "transaction-observer",
          active: window.location.hash.startsWith("#transaction-observer"),
        },
      ],
      windowHash: "#trip-view-travel",
      tabView,
    };
  }

  /**
   * Callback executed on hash change on the website.
   *
   * @param {HashChangeEvent} e event object 
   */ // eslint-disable-next-line no-unused-vars
  _onHashChange = (e) => {
    console.log("Hash change detected by Main");
    console.log(location.href);
    this.setState({
      tabArray: [
        {
          title: "trip-view",
          active:
            window.location.hash.startsWith("#trip-view") ||
            !window.location.hash,
        },
        {
          title: "navigator-view",
          active: window.location.hash.startsWith("#navigator-view"),
        },
        {
          title: "search-results",
          active: window.location.hash.startsWith("#search-results"),
        },
        {
          title: "view-property",
          active: window.location.hash.startsWith("#view-property"),
        },
        {
          title: "checkout",
          active: window.location.hash.startsWith("#checkout"),
        },
        {
          title: "transaction-observer",
          active: window.location.hash.startsWith("#transaction-observer"),
        },
      ],
    });
  };

  componentDidMount() {
    window.addEventListener("hashchange", this._onHashChange);
  }

  componentWillUnmount() {
    window.removeEventListener("hashchange", this._onHashChange);
  }

  _setSharedState = (newState) => {
    this.setState(newState);
  };

  navigateToSearch = (tabView) => {
    const tabArray = this.state.tabArray.map((tab, idx) => ({
      ...tab,
      active: idx === 1,
    }));
    // this.forceUpdate();
    this.setState({tabArray, tabView});
  };

  render() {
    return (
      <>
        {this.state.tabArray[0].active && (
          <TripView
            sharedState={this.props.sharedState}
            setSharedState={this._setSharedState}
            tripKey={this.props.tripKey}
            navigateToSearch={this.navigateToSearch}
          />
        )}
        {this.state.tabArray[1].active && (
          <NavigatorView
            sharedState={this.props.sharedState}
            setSharedState={this._setSharedState}
            tabView={this.state.tabView}
          />
        )}
        {this.state.tabArray[2].active && (
          <SearchResults sharedState={this.props.sharedState} />
        )}
        {this.state.tabArray[3].active && (
          <ViewProperty sharedState={this.props.sharedState} />
        )}
        {this.state.tabArray[4].active && (
          <Checkout sharedState={this.props.sharedState} />
        )}
        {this.state.tabArray[5].active && (
          <TransactionObserver sharedState={this.props.sharedState} />
        )}
      </>
    );
  }
}

export default App;
