import {FC} from "react";
import { IoIosInformationCircleOutline } from "react-icons/io";

import {Policy} from "../../../api";

export interface PolicyCardProps {
  policy: Policy;
}

export const PolicyCard: FC<PolicyCardProps> = ({policy}) => {
  return (
    <div className="policy">
      <div className="policy__title">
        <IoIosInformationCircleOutline
          className="policy__title__icon"
          size={24}
        />
        <h6 className="policy__title__text">{policy.title}</h6>
      </div>
      <ul className="policy__list">
        {policy.description.map((policyDesc, idx) => (
          <li key={`${policy.title}_${idx}`}>{policyDesc}</li>
        ))}
      </ul>
    </div>
  );
};
