import {FC, useEffect, useState} from 'react';
import type {RouteComponentProps} from 'react-router-dom';
import Lottie from 'react-lottie';

import animation from '../../../../assets/anim/loading-hotel.json';
import {Input, TimePicker} from '../../../../components/input';
import {Tour, fetchTour, checkTourAvailability} from '../../../../api';
import {TourReservation} from '../../../../api/reservation/types';
import {fetchCompulsoryToursFilters} from '../../../../reducers';
import {calculateTourPrice} from '../../../../util/price-calculator';

import {OverlayProvider} from '../../../search-property/context';

import Container, {CheckoutSection, SubPropertySection} from '../../components/container';
import {Card} from '../../../../components/card/property-card';
import {NonBookablePropertyOverlay} from '../../components/nbo';
import {ProceedToCheckout} from '../../components/proceed-to-checkout';
import { DatePicker, InputNumber } from 'antd';
import { Button } from '../../../../components';
import ActivityIndicator from '../../../../components/loading';
import { DateUtil } from '../../../../util';
import { TextInput } from '../../../../components/input/text-input';

type TourProps = RouteComponentProps<{id: string}>;

export const TourProperty: FC<TourProps> = ({match}) => {
  const [activeSubProperty, setActiveSubProperty] = useState(-1);
  const [tour, setTour] = useState<Tour | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorOccured, setErrorOccured] = useState<boolean>(false);

  // get the colpulsory filters that the user used to make the search
  const compulsoryFilters = fetchCompulsoryToursFilters();
  const {place} = compulsoryFilters;
  
  const [adults, setAdults] = useState(
    compulsoryFilters.adults ?
      compulsoryFilters.adults :
      1,
  );
  const [children, setChildren] = useState<number>(
    compulsoryFilters.children ?
      compulsoryFilters.children :
      0,
  );

  const [date, setDate] = useState<Date>(
    compulsoryFilters.date ?
      new Date(
        DateUtil.changeDateFormat(
          compulsoryFilters.date, "DD-MM-YYYY", "YYYY-MM-DD",
        )
      ) :
      new Date()
  );

  const [isAvailable, setIsAvailable] = useState<boolean>(true);

  // input control
  const [time, setTime] = useState<string>("10:00");

  useEffect(() => {
    // fetch the Hotels and after fetching, carry out the on success function
    fetchTour(match.params.id, (err, data) => {
      setIsLoading(false);

      if (err) {
        setErrorOccured(true);
        return;
      }

      // handle the hotel data that has been retrieved by the system
      setTour(data);
    });
  }, [match.params.id]);

  const calculatePrice = (): number => {
    if (!tour) return 0;

    return calculateTourPrice(adults, children, tour);
  };

  const onProceedToCheckout = (): TourReservation => {
    // creates the reservation card and then proceeds to checkout
    const reservation: TourReservation = {
      amount_received: 0,
      category: "tour",
      checkin: new Date().getTime(),
      due_date: new Date().getTime(),
      expected_amount: calculatePrice(),
      property_id: tour?.id ? tour.id : "",
      reservation_date: Date.now(),
      reservation_type: tour ? tour.reservation_policy: "full_amount",
      status: "pending",
      adults,
      children,
    };

    return reservation;
  };

  return (
    <>
      {isLoading && (
        <div className="w-full h-96 flex flex-col items-center justify-center">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animation,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            style={{}}
            height={200}
            width={200}
          />
          <p className="text-base font-semibold">Fetching Hotel Details</p>
        </div>
      )}
      {!isLoading && !errorOccured && tour && (
        <OverlayProvider.Consumer>
          {({setIsOverlay, setOnOverlay, setIsCancellable}) => (
            <Container
              property={tour}
              showDirections={true}
              renderSearchSection={() => {
                return (
                  <>
                    <TextInput
                      disabled={true}
                      value={tour.title}
                      helper="Tour package name"
                      id="tour-name"
                      inputLabel="Tour package"
                      name="tour_package"
                      onChange={(e) => console.log("Should not change")}
                      className="mb-2"
                    />
                    <p className="text-xs mb-1">Start date:</p>
                    <DatePicker
                      className="mb-2"
                      onChange={(val) => {
                        if (val) {
                          setDate(val.toDate());
                        }
                      }}
                    />

                    <p className="text-xs mb-1">Number of adults</p>
                    <InputNumber
                      className="mb-2"
                      defaultValue={adults}
                      value={adults}
                      onChange={(val) => {
                        setAdults(val);
                      }}
                    />

                    <p className="text-xs mb-1">Number of children</p>
                    <InputNumber
                      className="mb-2"
                      defaultValue={children}
                      value={children}
                      onChange={(val) => {
                        setChildren(val);
                      }}
                    />

                    <Button
                      // TODO: Add disabled button check
                      type="button"
                      onClick={() => {
                        setOnOverlay &&
                          setOnOverlay(() => () => (
                            <ActivityIndicator caption="" />
                          ));
                        setIsCancellable && setIsCancellable(false);
                        setIsOverlay && setIsOverlay(true);
                        checkTourAvailability(
                          match.params.id,
                          date.getTime(),
                          (err, isAvailable) => {
                            setIsOverlay && setIsOverlay(false);
                            setOnOverlay && setOnOverlay(null);
                            setIsCancellable && setIsCancellable(true);

                            if (err) {
                              console.error(err);

                              return;
                            }

                            if (isAvailable !== null) {
                              setIsAvailable(isAvailable);
                            }
                          }
                        );
                      }}
                    >
                      Check Availability
                    </Button>
                  </>
                );
              }}
            >
              <SubPropertySection title="Tour Stops">
                {tour.stops.map((tourStop, idx) => (
                  <Card
                    key={`tour_stop_${idx}`}
                    cover={tourStop.gallery[0].url}
                    description={tourStop.description}
                    subtitle={tourStop.location}
                    title={tourStop.title}
                    action={{
                      label: "View Stop",
                      onClick: () => {
                        setActiveSubProperty(0);
                        setOnOverlay && setOnOverlay(() => (
                          <NonBookablePropertyOverlay
                            gallery={tour.stops[activeSubProperty].gallery}
                            description={tour.stops[activeSubProperty].description}
                            facilities={tour.stops[activeSubProperty].facilities}
                            features={tour.stops[activeSubProperty].features}
                            location={tour.location}
                            policies={[]}
                            removeOverlay={() => {
                              setIsOverlay && setIsOverlay(false);
                              setOnOverlay && setOnOverlay(null);
                            }}
                            title={tour.stops[activeSubProperty].title}
                          />
                        ))
                        setIsOverlay && setIsOverlay(true);
                      }
                    }}
                  />
                ))}
              </SubPropertySection>
              <CheckoutSection>
                <ProceedToCheckout
                  onProceedToCheckout={onProceedToCheckout}
                  price={calculatePrice()}
                  isAvailable={isAvailable}
                >
                  <Input
                    disabled={true}
                    error=""
                    inputLabel="Tour"
                    name="tour"
                    onChange={() => console.log("Should not happen")}
                    value={tour.title}
                    helper="Name of the tour package"
                    id="tour-package-name"
                  />
                  <Input
                    disabled={true}
                    error=""
                    inputLabel="Place"
                    name="place"
                    onChange={() => console.log("Should not happen")}
                    value={place ? place: ""}
                    helper="start location of the tour"
                    id="tour-package-start-location"
                  />
                  <Input
                    disabled={true}
                    error=""
                    inputLabel="Start Date"
                    name="checkin"
                    onChange={() => console.log("Should not happen")}
                    value={DateUtil.changeDateFormatFromDate(date, "DD-MM-YYYY")}
                    helper="Date of start"
                    id="tour-start-date"
                  />
                  <TimePicker
                    name="time"
                    value={time}
                    label="Departure time"
                    onChange={(e) => {
                      setTime(e.target.value);
                    }}
                  />
                  <Input
                    disabled={true}
                    error=""
                    inputLabel="Adults"
                    name="adults"
                    onChange={() => console.log("Should not happen")}
                    value={adults ? `${adults}`: ""}
                    helper="Number of adults"
                    id="tour-package-number-of-adults"
                  />
                  <Input
                    disabled={true}
                    error=""
                    inputLabel="Children"
                    name="children"
                    onChange={() => console.log("Should not happen")}
                    value={children ? `${children}`: ""}
                    helper="Number of children"
                    id="tour-package-number-of-children"
                  />
                </ProceedToCheckout>
              </CheckoutSection>
            </Container>
          )}
        </OverlayProvider.Consumer>
      )}
      {!isLoading && !errorOccured && !tour && (
        <></>
      )}
      {!isLoading && errorOccured && (
        <></>
      )}
    </>
  );
};
