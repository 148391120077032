import {Carousel} from 'antd';
import {FC, useEffect, useRef, useState} from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import {fetchProximitySummary, fetchPopulatedCities} from '../../../api';
import {City} from '../../../api/summary/types';
import { DateUtil } from '../../../util';

import regions from "../../../util/regions.json";

const today = new Date();
today.setDate(today.getDate() + 1);

const TOMORROW = new Date(today);
today.setDate(today.getDate() + 1);

const DAY_AFTER = new Date(today);


export const RecommendedCities: FC = () => {
  const [cities, setCities] = useState<[City, number][]>([]);

  const mobileContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // fetch the summary
    fetchProximitySummary((err, result) => {
      if (err) {
        return;
      }

      if (result) {
        const resultSlice = result.slice(0, 5);
        fetchPopulatedCities(resultSlice.map(city => city[0]), (err, cities) => {
          if (err) {
            console.error(err);

            return;
          }

          if (cities) {
            const populatedCities: [City, number][] = [];
            cities.forEach((city, idx) => {
              populatedCities.push([city, resultSlice[idx][1]]);
            });

            setCities(populatedCities);
            console.log("PopulatedCities", populatedCities);
          }
        });
      }
    });
    // 
  }, []);

  return (
    <div
      ref={mobileContainerRef}
      className="display-port mt-3 px-4 md:h-auto lg:mt-4 xl:px-0"
    >
      {/* The carousel is onlyvisible to accomodate mobile devices */}
      <div className="md:hidden">
        <Carousel
          className="md:hidden"
          autoplay
          arrows
          pauseOnFocus
          pauseOnHover
          effect="fade"
          autoplaySpeed={5000}
        >
          {cities.map((city) => (
            <div
              key={city[0].id}
              className="w-full h-52 bg-no-repeat bg-cover bg-center "
              style={{
                backgroundImage: `url("${city[0].gallery[0].url}")`,
              }}
            >
              <CityCard
                city={city[0]}
                count={city[1]}
              />
            </div>
          ))}
        </Carousel>
      </div>

      {cities.length > 0 && (
        <div className="hidden md:grid md:grid-cols-6 md:gap-4 mt-3">
          <div className="col-span-3 bg-red-100 h-56">
            <CityCard
              city={cities[0][0]}
              count={cities[0][1]}
            />
          </div>
          <div className="col-span-3 bg-red-100 h-56">
            <CityCard
              city={cities[1][0]}
              count={cities[1][1]}
            />
          </div>
          <div className="col-span-2 bg-red-100 h-56">
            <CityCard
              city={cities[2][0]}
              count={cities[2][1]}
            />
          </div>
          <div className="col-span-2 bg-red-100 h-56">
            <CityCard
              city={cities[3][0]}
              count={cities[3][1]}
            />
          </div>
          <div className="col-span-2 bg-red-100 h-56">
            <CityCard
              city={cities[4][0]}
              count={cities[4][1]}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const CityCard: FC<{city: City; count: number;}> = ({
  city, count,
}) => {
  const [flagCode, setFlagCode] = useState<string>();

  useEffect(() => {
    let flagCode = "";

    for (const region in regions) {
      type RegionKey = keyof typeof regions;
      for (const country of regions[region as RegionKey]) {
        if (country.name === city.country) {
          flagCode = country.code;

          break;
        }
      }

      if (flagCode) break;
    }

    setFlagCode(flagCode.toLowerCase());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Link
      to={`/search-property/hotel?place=${city.name}&lat=${
        city.coordinates.lat
      }&lng=${city.coordinates.lng}&checkin=${
        DateUtil.changeDateFormatFromDate(TOMORROW, "DD-MM-YYYY")
      }&checkout=${
        DateUtil.changeDateFormatFromDate(DAY_AFTER, "DD-MM-YYYY")
      }&adults=1&children=0`}
    >
      <div
        className={
          "w-full h-full bg-repeat bg-cover bg-center " +
          "bg-gradient-to-t hover:from-black hover:to-transparent " +
          "cursor-pointer"
        }
        style={{
          backgroundImage: `url("${city.gallery[0].url}")`,
        }}
      >
        <div
          className={
            "px-4 pt-4 bg-gradient-to-b from-black to-transparent " +
            "flex flex-row items-center"
          }
        >
          <div>
            <div className="flex flex-row items-center">
              <h6 className="font-lato font-bold text-3xl text-white">
                {city.name}
              </h6>
              {flagCode !== "" && (
                <img
                  className="inline ml-2 mt-1"
                  src={`https://flagcdn.com/16x12/${flagCode}.png`}
                  alt={`${city.country}`}
                />
              )}
            </div>
            <h6 className="font-lato font-bold text-xl text-white">
              {`${count} properties`}
            </h6>
          </div>
          <FaChevronRight size={24} color="white" className="ml-3" />
        </div>
      </div>
    </Link>
  );
};
