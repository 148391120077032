import {FC, useContext} from 'react';
import {FaMapMarkerAlt} from "react-icons/fa";

import {FilterContext, OverlayProvider} from '../../context';

import {MapOverlay} from '../map-overlay';

export const ShowOnMap: FC = () => {
  const {
    setIsOverlay, setOnOverlay,
  } = useContext(OverlayProvider);
  const {results} = useContext(FilterContext);

  return (
    <div
      className="elevate-xs map-background cursor-pointer"
      onClick={() => {
        window.scrollTo({left: 0, top: 0, behavior: "smooth"});

        setOnOverlay && setOnOverlay(() => () => (
          <MapOverlay
            removeOverlay={() => {
              setIsOverlay && setIsOverlay(false);
              setOnOverlay(null);
            }}
            properties={results}
          />
        ));
        setIsOverlay && setIsOverlay(true);
      }}
      style={{
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        marginBottom: 20,
        maxWidth: 256,
      }}
    >
      <div
        style={{
          background: "#ffffff6b",
          backgroundPosition: "center",
          height: "100%",
          width: "100%",
          position: "absolute",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          position: "absolute",
          paddingTop: 15,
        }}
      >
        <FaMapMarkerAlt
          color="#ff6000"
          style={{ alignSelf: "center", fontSize: 30 }}
        />
        <div
          style={{
            fontWeight: "bold",
            fontSize: 16,
            padding: 10,
            color: "#000",
            textAlign: "center",
          }}
        >
          Show on Map
        </div>
      </div>
    </div>
  );
};
