import type {FC} from 'react';
import {FaTimes} from 'react-icons/fa';

import {ReservationCategory, Review} from '../../../../api';

import {UserReview} from './user-review';

type ReviewOverlayProps = {
  title: string;
  reviews: Review[];
  removeOverlay: () => void;
  location: string;
  category: ReservationCategory;
  propertyId: string,
};

export const ReviewOverlay: FC<ReviewOverlayProps> = ({
  category, propertyId, location, reviews, title, removeOverlay,
}) => {
  return (
    <div
      className="absolute top-0 right-0 bottom-0 w-full bg-white sm:max-w-md overflow-y-scroll"
    >
      {/** Review App Bar */}
      <div className="sticky top-0 bg-safari-gold text-white flex flex-row items-center justify-between h-14 px-4 border-b shadow-md">
        <div className="flex flex-row items-center">
          <FaTimes size={24} onClick={removeOverlay} />
          {/** Text Part of the Header */}
          <div className="ml-3">
            <h5 className="text-xl font-semibold">Reviews</h5>
            <p className="text-xs opacity-70">{`All reviews for ${title}`}</p>
          </div>
          {/** End of the text part of the header */}
        </div>
        {/** Drop down section of the header */}
        <select name="sort-by-dropdown" className="bg-safari-gold">
          <option className="bg-white text-black" value="best">Best Rating</option>
          <option className="bg-white text-black" value="worst">Worst Rating</option>
          <option className="bg-white text-black" value="oldest">Oldest Reviews</option>
          <option className="bg-white text-black" value="worst">Newest Reviews</option>
        </select>
        {/** End of the dropdown section of the header */}
      </div>
      {/** End of review App bar */}

      {/** Review Scrollable content */}
      <div className="p-4">
        {reviews.map((review, idx) => (
          <UserReview
            key={`review_from_${review.user}`}
            review={review}
            propertyTitle={title}
            propertyLocation={location}
            idx={idx}
            propertyCategory={category}
            propertyId={propertyId}
          />
        ))}
      </div>
      {/** End of review scrollable content */}
    </div>
  );
};
