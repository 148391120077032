import {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import { HotelRoom } from '../../../../api/hotel/types';
import { Reservation } from '../../../../api/reservation/types';

import {Input} from '../../../../components/input';
import {fetchCompulsoryFilters} from '../../../../reducers';

import {ProceedToCheckout} from "../../components/proceed-to-checkout";

export type HotelCheckoutProps = {
  room: HotelRoom;
  price: number;
  numberOfRooms: number;
  setNumberOfRooms: Dispatch<SetStateAction<number>>;
  onProceedToCheckout: () => Reservation;
};

export const HotelCheckout: FC<HotelCheckoutProps> = ({
  room, price, numberOfRooms, setNumberOfRooms, onProceedToCheckout,
}) => {
  const {checkin, checkout, place} = fetchCompulsoryFilters();

  const [newNumberOfRooms, setNewNumberOfRooms] = useState<number>(numberOfRooms);

  const [newPrice, setNewPrice] = useState<number>(price * numberOfRooms);

  useEffect(() => {
    setNewPrice(newNumberOfRooms * room.price);
    setNumberOfRooms(newNumberOfRooms);
  }, [newNumberOfRooms]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ProceedToCheckout price={newPrice} onProceedToCheckout={onProceedToCheckout}>
      <Input
        disabled={true}
        error=""
        className=""
        inputLabel="Room"
        name="room"
        onChange={() => console.log("Should not happen")}
        value={room.title}
        helper="Room type"
        id="hotel-room-name"
      />
      <Input
        disabled={true}
        error=""
        inputLabel="Location"
        name="place"
        onChange={() => console.log("Should not happen")}
        value={place ? place : ""}
        id="hotel-room-location"
        helper="Location of the hotel"
        className="mt-2"
      />
      <Input
        error=""
        inputLabel="Checkin"
        name="checkin"
        onChange={() => console.log("Should not happen")}
        value={checkin? checkin: ""}
        id="hotel-checkin-date"
        helper="Checkin date to the room"
        className="mt-2"
      />
      <Input
        error=""
        inputLabel="Checkout"
        name="checkout"
        onChange={() => console.log("Should not happen")}
        value={checkout? checkout: ""}
        helper="Checkout date of the stay"
        id="hotel-room-checkout-date"
        className="mt-2"
      />
      <Input
        error={
          (newNumberOfRooms < 1) ?
            "Booked rooms cannot be less than 1" :
            ""
        }
        inputLabel="Number of rooms"
        name="no-of-rooms"
        helper="Number of rooms you'd like to reserve"
        id="no-of-rooms"
        value={`${newNumberOfRooms}`}
        onChange={(e) => {
          setNewNumberOfRooms(Number(e.target.value));
        }}
      />
    </ProceedToCheckout>
  );
};
