import {useState} from "react";
import {FaWindowClose} from "react-icons/fa";

import {MobileFilterContext} from "../mobile-filter-context";

import {SectionHeader} from "./section-header";

export function FeaturesFilter({sharedState, updateState}) {
  const url = new URL(location.href);

  const [activeFeatureFilters, setActiveFeatureFilters] =
    useState(url.searchParams.getAll("theme"));

  const filterByTheme = (feature) => {
    const category = sharedState.category;
    const results = sharedState.rawResults;
    let newResults = [];
    //handle filter removals
    let activeFilters = activeFeatureFilters;
    if (activeFilters.includes(feature)) {
      activeFilters.splice(activeFilters.indexOf(feature), 1);
    } else {
      activeFilters.push(feature);
    }

    url.searchParams.delete("theme");
    // append all themes
    activeFilters.forEach((theme) => {
      url.searchParams.append("theme", theme);
    });

    // ammend url with all changes
    history.replaceState(null, "", url.toString());

    // this.setState({ activeFeatureFilters: activeFilters });
    setActiveFeatureFilters(activeFilters);

    if (activeFilters.length == 0) {
      newResults = results;
      updateState({
        searchResults: newResults,
      });
      return;
    }

    //handle removal
    results.map((item) => {
      let feature_list = [];
      const features = item["room_features"];
      switch (category) {
        case "hotel":
          if (features) {
            feature_list = features;
          }
          if (feature_list.includes(feature)) {
            newResults.push(item);
          }

          break;
      }
    });

    this.props.updateState({
      searchResults: newResults,
    });
  };

  return (
    <div className="relative shadow-md mb-2">
      <SectionHeader
        style={{borderTop: 0}}
      >
        Theme
      </SectionHeader>
      <MobileFilterContext.Consumer>
        {({setVisibleFilter}) => (
          <div
            className="absolute top-2 right-2"
            onClick={() => setVisibleFilter("none")}
          >
            <FaWindowClose size={28} />
          </div>
        )}
      </MobileFilterContext.Consumer>
      <div style={{ padding: 20, paddingTop: 10 }}>
        {sharedState.themes.map((item) => {
          return (
            <div
              style={{ display: "flex", paddingTop: 7, paddingBottom: 7 }}
            >
              <div style={{ flex: 1, textAlign: "left", display: "flex" }}>
                <input
                  type="checkbox"
                  id={item.title}
                  name={item.title}
                  value={item.title}
                  onChange={(event) => {
                    filterByTheme(event.target.value);
                  }}
                  style={{ width: 30 }}
                  checked={
                    activeFeatureFilters.includes(item.title)
                  }
                />
                <label
                  htmlFor={item.title}
                  style={{ height: 0, paddingTop: 3, fontSize: 12 }}
                >
                  {" "}
                  {item.title}{" "}
                </label>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
