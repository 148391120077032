import {FC, useContext, useEffect, useState} from 'react';
import {Badge} from 'antd';
import {FaRegBell} from 'react-icons/fa';

import {fetchAdminNotifications} from '../../../api';
import {Notification} from '../../../api/notification/types';
import {OverlayProvider} from '../../../context';
import { SuNotificationOverlay } from './su-notification-overlay';

export const SuNavBar: FC = () => {
  const {setIsOverlay, setOnOverlay} = useContext(OverlayProvider);

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [stale, setStale] = useState<boolean>(true);

  useEffect(() => {
    if (!stale) return;

    fetchAdminNotifications((err, notifications) => {
      if (err) {
        console.error(err);

        return;
      }

      if (notifications) {
        setNotifications(notifications);
        setStale(false);
      }
    });
  }, [stale]);

  const unreadNotifications = notifications.filter((notif) => !notif.read);

  return (
    <nav
      className="w-full flex flex-row justify-between items-center px-8 border-b"
      style={{
        height: 56,
      }}
    >
      <h4 className="text-xl font-lato font-semibold">Admin Panel</h4>
      <Badge count={unreadNotifications.length}>
        <FaRegBell
          size={24}
          onClick={() => {
            setOnOverlay && setOnOverlay(() => () => (
              <SuNotificationOverlay
                notifications={unreadNotifications}
                removeOverlay={() => {
                  setStale(true);
                  setIsOverlay && setIsOverlay(false);
                  setOnOverlay(null);
                }}
              />
            ));

            setIsOverlay && setIsOverlay(true);
          }}
        />
      </Badge>
    </nav>
  );
};
