// @ts-check
import {useState} from "react";
import {GrClose} from "react-icons/gr";
import {FaChevronLeft, FaChevronRight} from "react-icons/fa";
import useOnclickOutside from "react-cool-onclickoutside";

// eslint-disable-next-line no-unused-vars
const RoomModal = ({roomData, hideFunc, module, sharedState}) => {
  const numberOfPictures = roomData.picture_gallery ?
    roomData.picture_gallery :
    0;
  const [currentPicture, setCurrentPicture] = useState(0);

  const contentContainerRef = useOnclickOutside(hideFunc);

  const _nextPicture = () => {
    // creates a round slider to go through all the images without going out of
    // bounds
    setCurrentPicture((currentPicture + 1) % numberOfPictures);
  };

  const _prevPicture = () => {
    // We perform a complete iteration through numberOfPictures - 1 images,
    // this brings us back to the image before this one.
    setCurrentPicture(
      (currentPicture + numberOfPictures - 1) % numberOfPictures,
    );
  };

  return (
    <div
      className={
        "fixed top-0 left-0 w-screen h-screen xl:h-full xl:w-full " +
        "flex items-center justify-items-center justify-center " +
        "bg-scrim"
      }
      style={{
        zIndex: 5000,
      }}
    >
      <div
        ref={contentContainerRef}
        className={
          "elevate-xs relative flex flex-col lg:flex-row bg-white " +
          "h-screen w-screen sm:w-8/12 sm:h-4/5 md:w-8/12 md:h-3/5"
        }
      >
        <div
          onClick={hideFunc}
          className={
            "absolute top-16 left-4 shadow-md py-2 px-4 bg-gradient-to-tr " +
            "bg-white text-black text-sm flex flex-row " +
            "items-center place-items-center rounded-md md:hidden"
          }
          style={{zIndex: 5001}}
        >
          <GrClose
            className="mr-1 text-white"
            size={20}
            color="white"
            style={{
              color: "white",
            }}
          />
          Close
        </div>
        <div
          className="flex w-full h-1/2 lg:w-1/2 lg:h-full bg-red-200"
          // style={{ flex: 1 }}
        >
          <div
            className="relative h-full w-full"
          >
            <div
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                border: "1px solid #ccc",
                backgroundColor: "#ccc",
                backgroundImage: roomData.picture_gallery
                  ? `url(${
                      roomData.picture_gallery[
                        currentPicture
                      ]
                    })`
                  : "",
                backgroundSize: "cover",
              }}
            />
            <div style={{ position: "absolute" }} />
            <div
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div style={{ display: "flex" }}>
                <div
                  onClick={_nextPicture}
                  style={{
                    padding: 10,
                    background: "#eee",
                    cursor: "pointer",
                  }}
                >
                  <FaChevronLeft style={{ fontSize: "30px" }} />
                </div>
                <div style={{ flex: 1 }} />
                <div
                  onClick={_prevPicture}
                  style={{ padding: 10, background: "#eee" }}
                >
                  <FaChevronRight style={{ fontSize: "30px" }} />
                </div>
              </div>
                </div>
          </div>
        </div>
        <div
          className={
            "w-full h-3/6 lg:w-1/2 lg:h-full bg-autocomplete-hover " +
            "p-4 lg:p-6 overflow-scroll"
          }
          // style={{ flex: 1, padding: 30 }}
        >
          <div
            className={
              "text-3xl font-bold border-b-2 border-black pb-3 mb-4"
            }
          >
            {roomData.title}
          </div>
          <div
            className="text-base mb-5"
          >
            {roomData.description}
          </div>
          <div
            className={
              "text-2xl font-semibold pb-2 border-b-2 border-gray-400 mb-3"
            }
          >
            Capacity
          </div>
          <table className="data-table">
            <tbody>
              <tr>
                <td>Adults</td>
                <td>{roomData.adult_number}</td>
              </tr>
              <tr>
                <td>Children</td>
                <td>{roomData.children_number}</td>
              </tr>
              <tr>
                <td>Beds</td>
                <td>{roomData.room_footage}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          className="elevate"
          onClick={() => {
            hideFunc();
          }}
          style={{
            height: 50,
            width: 50,
            background: "#fff",
            position: "absolute",
            right: 0,
            top: 0,
            marginTop: -34,
            marginRight: -20,
            borderRadius: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <GrClose />
        </div>
      </div>
    </div>
  );
  
}

export default RoomModal;
