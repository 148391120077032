import {FC, useEffect, useState} from "react";
import {BsPencil} from "react-icons/bs";
import {IoIosBed} from "react-icons/io";
import {RouteComponentProps, useHistory} from "react-router";

import {fetchHotel, Hotel} from "../../../../api";
import {OverlayProvider} from "../../../search-property/context";
import {ExternalReservationOverlay, PartnerHeader} from "../../components";

import {PartnerProperty} from "../../components/property";
import {RoomOverlay} from "../../components/room-overlay";

export const HotelView: FC<RouteComponentProps<{id: string}>> = ({match}) => {
  const history = useHistory();

  const [hotel, setHotel] = useState<Hotel | null>(null);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [errFetching, setErrFetching] = useState<boolean>(false);

  useEffect(() => {
    match.params.id && fetchHotel(match.params.id, (err, result) => {
      setIsFetching(false);

      if (err) {
        console.error(err);
        setErrFetching(true);
        return;
      }

      setHotel(result);
    });
  }, [match]);

  return (
    <>
      {hotel && (
        <>
          <PartnerHeader
            HeaderIcon={IoIosBed}
            title={hotel.title}
            actions={[
              {
                ActionIcon: BsPencil,
                alwaysVisible: true,
                label: "Edit Property contact information",
                type: "button",
                onClick: () => {
                  // migrate to the property edit tab
                  history.push(
                    `/property-owner/edit/hotel/${hotel.id}/details`
                  );
                },
              },
            ]}
          />
          <OverlayProvider.Consumer>
            {({setIsOverlay, setOnOverlay}) => (
              <PartnerProperty
                property={hotel}
                propertyType="hotel"
                subProperties={hotel.rooms.map((room) => ({
                  cover: room.gallery[0].url,
                  description: room.description,
                  subtitle: `${room.price}/night`,
                  title: room.title,
                  action: {
                    label: "View Room",
                    onClick: () => {
                      setOnOverlay && setOnOverlay(() => () => (
                        <RoomOverlay
                          gallery={room.gallery}
                          capacity={{
                            adults:
                              room.capacity.adults_only ?
                                room.capacity.adults_only :
                                1,
                            children:
                              room.capacity.children_only ?
                                room.capacity.children_only :
                                1,
                          }}
                          description={room.description}
                          facilities={room.facilities}
                          features={room.features}
                          onRoomEdit={() => console.info("Editting room")}
                          policies={room.room_policy}
                          price={room.price}
                          removeOverlay={() => {
                            setIsOverlay && setIsOverlay(false);
                            setOnOverlay(null);
                          }}
                          title={room.title}
                          coords={hotel.coords}
                        />
                      ));

                      setIsOverlay && setIsOverlay(true);
                    },
                  },
                  overflowActions: [
                    {
                      label: "Make room unavailable",
                      onClick: () => {
                        console.log("Making room unavailable");
                        setOnOverlay && setOnOverlay(() => () => (
                          <ExternalReservationOverlay
                            category="hotel"
                            propertyId={`${hotel.id}:${room.id}`}
                            title={room.title}
                            cover={room.gallery[0]}
                            removeOverlay={() => {
                              setIsOverlay && setIsOverlay(false);
                              setOnOverlay(null);
                            }}
                          />
                        ));

                        setIsOverlay && setIsOverlay(true);
                      },
                    },
                  ],
                }))}
                subPropertySectionTitle="rooms"
              />
            )}
          </OverlayProvider.Consumer>
        </>
      )}
    </>
  );
};
