// @ts-check
/* eslint-disable no-undef */
import {useEffect} from "react";
import Lottie from "react-lottie";
import {Link, useHistory} from 'react-router-dom';

import {generateString} from "../../_system/GeneralMethodology";
import animation from "../../assets/anim/user.json";

import Services from "./Services";
import { auth } from "firebase";

/**
 * This screen is shown to help their account details before they proceed to the
 * next action. This screen is used in two instances:
 * - Upon successful login - this is after the user successfully logs into the
 * account linked to the account that they logged into.
 * - Before Checkout - this is used as a final step to help users confirm that
 * all their details (as per the account are correct) before they proceed to
 * checkout.
 * - Trip planner - this verifies the user details before they proceed to the
 * Trip planner.
 * - Session Page - this is used as the user is verifying the session that they
 * are currently in.
 * - Listing Prperty page - this helps the user confirm their details before
 * they add/list a new property on the website.
 *
 * In all these instances allow the user to logout if they feel that the details
 * shown to them are not representative of their account and would like to link
 * to a different account.
 * 
 * For a Trip Planner Origin
 * -------------------------
 * If the origin dictates that the user wants to use the TripPlanner, the user
 * is directed to either:
 * - Login Screen as they have not yet logged in to the application.
 * - Session page to confirm the user credentials before they proceed.
 *
 * Session Page
 * ------------
 * In this page, the user has the option to create a new trip or go back and
 * edit existing trips.
 * ### Create New Trips
 * If the user decides to create a new Trip, a random string is generated of `9`
 * characters that will be used to identify this particular unique trip under
 * this user.
 * ### EdIt Existing Trip
 * This takes the user to a previous session as storesd on firebase and enables
 * them to continue editting.
 *
 * @param {{
 * origin: string,
 * userData: {username: string, authLevel: string},
 * }} props LoggedIn props.
 *
 * @returns {JSX.Element}
 */
const App = (props) => {
  useEffect(() => {
    console.log(props);
  }, [props]);

  const history = useHistory();

  const createNewTripNGo = () => {
    const tripId = generateString(9);
    // window.location.href = `/trip-planner/${tripId}`;
    history.push(`/trip-planner/${tripId}`);
  }

  return (
    <div style={{ paddingTop: 50 }}>
      <div>
        <div>
          {props.origin === "signin-page" && (
            <div style={{ padding: 50, paddingTop: 0 }}>
              <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: animation,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                height={200}
                width={200}
              />
              <h2 style={{ marginBottom: 10, textAlign: "center" }}>
                Hello {props.userData.username}
              </h2>
              <Link to="/user">
                <div className="orange-but">Go to Dashboard</div>
              </Link>
              <div
                style={{
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <div
                  style={{ marginTop: 17, cursor: "pointer" }}
                  onClick={() => {
                    
                    auth().signOut();
                  }}
                >
                  {props.userData.username} is not you?{" "}
                  <a style={{ color: "#059faa", fontWeight: "bold" }}>
                    Logout
                  </a>
                </div>
              </div>
            </div>
          )}
          {props.origin === "property-checkout" && (
            <div
              style={{
                padding: 50,
                paddingTop: 0,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: animation,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                height={200}
                width={200}
              />
              <h2 style={{ marginBottom: 20, textAlign: "center" }}>
                Hello {props.userData.username}
              </h2>

              <div
                className="orange-but"
                style={{ width: 300, padding: 12 }}
                onClick={() => {
                  window.location.hash = "summary";
                }}
              >
                PROCEED WITH ACCOUNT
              </div>

              <div
                style={{
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                  alignContent: "center",
                  marginTop: 15,
                }}
              >
                <div
                  style={{ marginTop: 17, cursor: "pointer" }}
                  onClick={() => {
                    
                    auth().signOut();
                  }}
                >
                  {props.userData.username} is not you?{" "}
                  <a style={{ color: "#059faa", fontWeight: "bold" }}>
                    Logout
                  </a>
                </div>
              </div>
            </div>
          )}
          {props.origin === "checkout-page" && (
            <div
              style={{
                padding: 50,
                paddingTop: 0,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: animation,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                height={200}
                width={200}
              />
              <h2 style={{ marginBottom: 20, textAlign: "center" }}>
                Hello {props.userData.username}
              </h2>

              <div
                className="orange-but"
                style={{ width: 300, padding: 12 }}
                onClick={() => {
                  window.location.hash = "summary";
                }}
              >
                PROCEED WITH ACCOUNT
              </div>

              <div
                style={{
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                  alignContent: "center",
                  marginTop: 15,
                }}
              >
                <div
                  style={{ marginTop: 17, cursor: "pointer" }}
                  onClick={() => {
                    
                    auth().signOut();
                  }}
                >
                  {props.userData.username} is not you?{" "}
                  <a style={{ color: "#059faa", fontWeight: "bold" }}>
                    Logout
                  </a>
                </div>
              </div>
            </div>
          )}
          {props.origin === "trip-planner" && (
            <div style={{ padding: 50, paddingTop: 0 }}>
              <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: animation,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                height={200}
                width={200}
              />
              <h2 style={{ marginBottom: 20, textAlign: "center" }}>
                Hello {props.userData.username}
              </h2>
              <Link to={"/user/my-trips"}>
                <div
                  className="orange-but"
                  style={{ backgroundColor: "#111", marginBottom: 20 }}
                >
                  EDIT PREVIOUS TRIPS
                </div>
              </Link>
              <a onClick={createNewTripNGo}>
                <div className="orange-but">CREATE NEW TRIP</div>
              </a>
              <div
                style={{
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                  alignContent: "center",
                  marginTop: 15,
                }}
              >
                <div
                  style={{ marginTop: 17, cursor: "pointer" }}
                  onClick={() => {
                    
                    auth().signOut();
                  }}
                >
                  {props.userData.username} is not you?{" "}
                  <a style={{ color: "#059faa", fontWeight: "bold" }}>
                    Logout
                  </a>
                </div>
              </div>
            </div>
          )}
          {props.origin === "session-page" && (
            <div style={{ padding: 50, paddingTop: 0 }}>
              <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: animation,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                height={200}
                width={200}
              />
              <h2 style={{ marginBottom: 10, textAlign: "center" }}>
                Hello {props.userData.username}
              </h2>
              <Link to={props.userData.authLevel}>
                <div className="orange-but">Save Trip</div>
              </Link>
              <div
                style={{
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <div
                  style={{ marginTop: 17, cursor: "pointer" }}
                  onClick={() => {
                    
                    auth().signOut();
                  }}
                >
                  {props.userData.username} is not you?{" "}
                  <a style={{ color: "#059faa", fontWeight: "bold" }}>
                    Logout
                  </a>
                </div>
              </div>
            </div>
          )}
          {props.origin === "list-property-page" && (
            <div style={{ marginTop: -100, textAlign: "initial" }}>
              <h2 style={{ marginBottom: 3, textAlign: "center" }}>
                Hello {props.userData.username}
              </h2>
              <h4 style={{ marginTop: 3, textAlign: "center" }}>
                Select a category to add a property
              </h4>
              <Services />
              <Link to="/property-owner">
                <div
                  className="orange-but"
                  style={{ marginTop: 15, background: "#ff4f5a" }}
                >
                  Go to Dashboard
                </div>
              </Link>
              <div
                style={{
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <div
                  style={{ marginTop: 17, cursor: "pointer" }}
                  onClick={() => {
                    
                    auth().signOut();
                  }}
                >
                  {props.userData.username} is not you?{" "}
                  <a style={{ color: "#059faa", fontWeight: "bold" }}>
                    Logout
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default App;
