import {FC, useContext, useEffect, useState} from 'react';
import {RouteComponentProps, useHistory} from 'react-router-dom';
import {Formik} from 'formik';
import {FaPlusCircle, FaSave, FaUtensils} from 'react-icons/fa';

import {
  Coordinates, Facility, FAQ, Feature, fetchEatery, Picture,
  RefundType, ReservationType, updateProperty,
} from '../../../../../api';
import {MenuItem, Table} from '../../../../../api/eatery/types';
import {Input, TextArea} from '../../../../../components/input';
import {Card} from '../../../../../components';
import {generateId} from '../../../../../util';

import {OverlayProvider} from '../../../../search-property/context';

import {
  EditEateryMenuItemOverlay,
  EditFacilitiesListItem, EditFeaturesListItem, EditRefundPolicyListItem,
  EditReservationPolicyListItem, PartnerHeader, PickImageListItem,
  PropertyLocationAutoComplete,
} from '../../../components';
import {EditFaqListItem} from '../../../components/edit-faq-list-item';
import {UploadingOverlay} from '../../../components/uploading-overlay';


import {validateEateryForm} from './validate-form';
import {NewEateryMenuItemOverlay} from './new-eatery-menu-item-overlay';
import ActivityIndicator from '../../../../../components/loading';
import { SnackbarProvider } from '../../../../../context/snackbar-provider';
import { ConfirmPropertyLocation } from '../../../components/confirm-property-location';
import { PriceChange } from '../../../../../api/hotel/Offer';

type EditEateryProps = RouteComponentProps<{property_id: string}>;

type InitialDraftValues = {
    title: string,
    location: string,
    description: string,
    short_description: string,
    reservation_policy: string,
    refund_policy: string,
    gallery: string,
    facilities: string,
    features: string,
    faqs: string,
    tables: string,
    menu_items: string,
};

export const EditEatery: FC<EditEateryProps> = ({
  match,
}) => {
  const {
    setIsCancellable, setIsOverlay, setOnOverlay,
  } = useContext(OverlayProvider);
  const {
    setIsVisible: setSnackbarVisible, dismissSnackbar,
  } = useContext(SnackbarProvider);

  const history = useHistory();

  const [facilities, setFacilities] = useState<Facility[]>([]);
  const [features, setFeatures] = useState<Feature[]>([]);
  const [faqs, setFaqs] = useState<FAQ[]>([]);
  const [gallery, setGallery] = useState<Picture[]>([]);
  const [coords, setCoords] = useState<Coordinates>({lat: 0, lng: 0});
  const [refundPolicy, setRefundPolicy] = useState<RefundType>("partial_refund");
  const [reservationPolicy, setReservationPolicy] =
    useState<ReservationType>("full_amount");
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [tables, setTables] = useState<Table[]>([]);
  const [offers, setOffers] = useState<PriceChange[]>([]);

  const [isUploading, setIsUploading] = useState<boolean>(false);

  const propertyId = match.params.property_id;
  
  const [initialValues, setInitialValues] =
    useState<null | InitialDraftValues>(null);
  
  const addNewMenuItem = (newMenuItem: MenuItem) => {
    setMenuItems([
      ...menuItems,
      {...newMenuItem, id: generateId(10)},
    ]);
  };

  useEffect(() => {
    setIsCancellable && setIsCancellable(false);

    setOnOverlay && setOnOverlay(() => () => (
      <ActivityIndicator caption="" />
    ));

    setIsOverlay && setIsOverlay(true);

    const removeOverlay = () => {
      setIsCancellable && setIsCancellable(true);

      setOnOverlay && setOnOverlay(null);

      setIsOverlay && setIsOverlay(false);
    };

    fetchEatery(propertyId, (err, eatery) => {
      if (err) {
        console.error(err);
        history.goBack();
        setSnackbarVisible && setSnackbarVisible({
          fabPresent: true,
          isError: true,
          navRailPresent: true,
          title: "Could not fetch property details, try again",
        });
        removeOverlay();
        return;
      }

      if (eatery) {
        setInitialValues({
          description: eatery.description,
          facilities: "",
          faqs: "",
          features: "",
          gallery: "",
          location: eatery.location,
          menu_items: "",
          refund_policy: "",
          reservation_policy: "",
          short_description: eatery.short_description,
          tables: "",
          title: eatery.title,
        });

        setFaqs(eatery.faqs);
        setGallery(eatery.gallery);
        setMenuItems(eatery.menu);
        setRefundPolicy(eatery.refund_policy);
        setReservationPolicy(eatery.reservation_policy);
        setTables(eatery.table);
        setCoords(eatery.coords);
        setOffers(eatery.offers);
      } else {
        history.goBack();
        setSnackbarVisible && setSnackbarVisible({
          fabPresent: true,
          isError: true,
          navRailPresent: true,
          title: "Could not fetch property details, try again",
        });
      }

      removeOverlay();
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isUploading && (
        <OverlayProvider.Consumer>
          {({setIsCancellable, setIsOverlay, setOnOverlay}) => (
            <UploadingOverlay
              caption="Uploading eatery details (this may take a while...)"
              setIsCancellable={setIsCancellable}
              setOnOverlay={setOnOverlay}
              setIsOverlay={setIsOverlay}
            />
          )}
        </OverlayProvider.Consumer>
      )}

      {initialValues !== null && (
        <Formik
          initialValues={initialValues}
          validate={(values) => {
            // validate values
            return validateEateryForm({
              coords,
              description: values.description,
              facilities,
              features,
              faqs,
              gallery,
              location: values.location,
              menu_items: menuItems,
              tables,
              refund_policy: refundPolicy,
              reservation_policy: reservationPolicy,
              short_description: values.short_description,
              title: values.title,
            });
          }}
          onSubmit={(values) => {
            setIsUploading(true);
              // handle the submit
            updateProperty(
              "eatery",
              propertyId,
              {
                coords,
                description: values.description,
                short_description: values.short_description,
                exceptions: [],
                faqs,
                gallery,
                location: values.location,
                menu: menuItems,
                rating: 10,
                refund_policy: refundPolicy,
                reservation_policy: reservationPolicy,
                reviews: [],
                table: tables,
                title: values.title,
                offers,
              },
              (err, result) => {
                setIsUploading(false);
                if (err) {
                  console.error(err);
                  setSnackbarVisible && setSnackbarVisible({
                    fabPresent: false,
                    isError: true,
                    navRailPresent: true,
                    title: "Unable to publish eatery details",
                  });
                  return;
                }
    
                if (result) {
                  history.goBack();
                  setSnackbarVisible && setSnackbarVisible({
                    fabPresent: false,
                    isError: false,
                    navRailPresent: true,
                    title: `Published ${result.title} details`,
                    action: {
                      label: "DISMISS",
                      onClick: () => {
                        dismissSnackbar && dismissSnackbar();
                      },
                    },
                  });
                } else {
                  setSnackbarVisible && setSnackbarVisible({
                    fabPresent: false,
                    isError: true,
                    navRailPresent: true,
                    title: "Unable to publish eatery details",
                  });
                }
              },
            );
          }}
        >
          {({
            values, errors, setErrors, handleChange,
            handleSubmit, handleBlur, validateForm,
          }) => (
            <form onSubmit={handleSubmit} onBlur={handleBlur}>
              <PartnerHeader
                HeaderIcon={FaUtensils}
                title={values.title ? values.title : "New Restaurant/Eatery"}
                actions={[
                  {
                    ActionIcon: FaSave,
                    alwaysVisible: true,
                    type: "submit",
                    disabled: Object.keys(errors).length > 0,
                    label: "ADD NEW RESTAURANT/EATERY",
                    onClick: () => {
                      console.log("Adding New Eatery");
                    },
                  },
                ]}
              />
              <div className="p-4 sm:grid sm:grid-cols-2 gap-4 lg:grid-cols-3 lg:grid-flow-col-dense lg:max-w-5xl">
                {/** Input Title */}
                <div>
                  <Input
                    inputLabel="Eatery's name"
                    name="title"
                    value={values.title}
                    error={errors.title}
                    helper="Name of the eatery"
                    id="eatery-name"
                    onChange={handleChange}
                  />
                </div>
                {/** End of input Title */}
                {/** Input Title */}
                <div className="mt-3 sm:mt-0 lg:col-start-1 col-start-2">
                  {/** Text input */}
                  <PropertyLocationAutoComplete
                    name="location"
                    inputLabel="Conference facility location"
                    onChange={handleChange}
                    selectAddress={(place, coords) => {
                      setIsCancellable && setIsCancellable(false);
                      // setCoords(coords);
                      setOnOverlay && setOnOverlay(() => () => (
                        <ConfirmPropertyLocation
                          initialCoords={coords}
                          onConfirm={(coords) => {
                            setCoords(coords);
                          }}
                          removeOverlay={() => {
                            setIsOverlay && setIsOverlay(false);
                            setIsCancellable && setIsCancellable(true);
                            setOnOverlay(null);
                          }}
                        />
                      ));
                    }}
                    value={values.location}
                    error={errors.location}
                  />
                  {/** End of text input */}
                </div>
                {/** End of input Title */}
            
                {/** Start of Text Area */}
                <div className="mt-3 lg:col-start-1 lg:col-end-3">
                  <TextArea
                    name="description"
                    value={values.description}
                    error={errors.description}
                    helper="Longform description of the eatery"
                    id="eatery-description"
                    inputLabel="Eatery Facility description (long)"
                    onChange={handleChange}
                  />
                </div>
                {/** End of Text Area */}
            
                {/** Start of Text Area */}
                <div className="mt-3 lg:col-start-1 lg:col-end-3">
                  <TextArea
                    name="short_description"
                    value={values.short_description}
                    error={errors.short_description}
                    helper="Brief description of the eatery"
                    id="eatery-short-description"
                    inputLabel="Eatery description (short)"
                    onChange={handleChange}
                  />
                </div>
                {/** End of Text Area */}
            
                {/** Reservation policy card */}
                <EditReservationPolicyListItem
                  reservationPolicy={reservationPolicy}
                  onSave={async (reservationPolicy) => {
                    setReservationPolicy(reservationPolicy);
                    setErrors(await validateForm());
                  }}
                />
                {/** End of Reservation policy card */}
            
                {/** Refund policy card */}
                <EditRefundPolicyListItem
                  refundPolicy={refundPolicy}
                  onSave={async (refundPolicy) => {
                    setRefundPolicy(refundPolicy);
                    setErrors(await validateForm());
                  }}
                />
                {/** End of Refund policy card */}
            
                {/** Pick images overlay */}
                <PickImageListItem
                  gallery={gallery}
                  minImageCount={6}
                  onOverlaySave={async (newImages) => {
                    setGallery(newImages);
                    setErrors(await validateForm());
                  }}
                />
                {/* End of pick images overlay */}
            
                {/** Things liks prices and sub properties are found here */}
                <hr className="my-4 sm:col-start-1 sm:col-end-3 h-auto lg:hidden" />
                {/** Special Section */}
                <div className="shadow-md border sm:col-start-1 sm:col-end-3">
                  {/** Header for special section */}
                  <div className="h-14 flex flex-row justify-between items-center px-4 border-b">
                    <div>
                      <h6 className="text-xl font-semibold">Menu items</h6>
                      <p className="text-xs">Add menu items</p>
                    </div>

                    {/** Edit Button */}
                    <OverlayProvider.Consumer>
                      {({setIsOverlay, setOnOverlay}) => (
                        <button
                          type="button"
                          className="h-9 px-3 border border-red-500 rounded-md shadow-md text-red-500 font-semibold text-sm flex items-center"
                          onClick={() => {
                            setOnOverlay && setOnOverlay(() => () => (
                              <NewEateryMenuItemOverlay
                                onSave={async (menuItem) => {
                                  addNewMenuItem(menuItem);

                                  setErrors(await validateForm());
                                }}
                                removeOverlay={() => {
                                  setIsOverlay && setIsOverlay(false);
                                  setOnOverlay(null);
                                }}
                              />
                            ));
                            setIsOverlay && setIsOverlay(true);
                          }}
                        >
                          Add menu item
                        </button>
                      )}
                    </OverlayProvider.Consumer>
                    {/** End of edit Button */}
                  </div>

                  <div className="p-4 flex flex-row justify-evenly overflow-x-scroll">
                    {/** All Added Sub Properties go here */}
                    {menuItems.map((menuItem, idx) => (
                      <Card
                        key={menuItem.title}
                        cover={menuItem.picture.url}
                        description={menuItem.description}
                        subtitle={`${menuItem.price} USD`}
                        title={menuItem.title}
                        action={{
                          label: "Edit Menu Item",
                          onClick: () => {
                            setOnOverlay && setOnOverlay(() => () => (
                              <EditEateryMenuItemOverlay
                                idx={idx}
                                item={menuItem}
                                menuItems={menuItems}
                                removeOverlay={() => {
                                  setIsOverlay && setIsOverlay(false);
                                  setOnOverlay(null);
                                }}
                                setMenuItems={setMenuItems}
                              />
                            ));

                            setIsOverlay && setIsOverlay(true);
                          }
                        }}
                      />
                    ))}
                  </div>

                  {/** Header for special section */}
                  <div className="h-14 flex flex-row justify-between items-center px-4 border-b">
                    <div>
                      <h6 className="text-xl font-semibold">Tables</h6>
                      <p className="text-xs">Add tables</p>
                    </div>

                    {/** Edit Button */}
                    <button
                      type="button"
                      className="h-9 px-3 border border-red-500 rounded-md shadow-md text-red-500 font-semibold text-sm flex items-center"
                    >
                      Add tables
                    </button>
                    {/** End of edit Button */}
                  </div>

                  <div className="p-4 flex flex-row justify-evenly overflow-x-scroll">
                    {/** All Added Sub Properties go here */}
                    {tables.map((table) => (
                      <Card
                        key={table.type}
                        cover={table.picture.url}
                        subtitle={`Reservation price: ${table.price}`}
                        title={table.title}
                        description={`${table.type} Sits ${table.capacity}`}
                        action={{
                          label: "Edit table details",
                          onClick: () => {
                            console.log("Editing table details");
                          }
                        }}
                      />
                    ))}
                  </div>
                </div>
                {/** Special Section */}
            
                <div className="sm:col-span-2 lg:col-start-1 lg:col-end-4">
                  <h3 className="text-2xl font-semibold sm:col-start-1 sm:col-end-3">Aditional Information</h3>
                  <div className="sm:grid sm:grid-cols-2 sm:gap-4 lg:grid-cols-3">
                    {/** Add FAQs */}
                    <EditFaqListItem
                      faqs={faqs}
                      onSave={async (newFaqs) => {
                        setFaqs(newFaqs);
                        setErrors(await validateForm());
                      }}
                      error={errors.faqs}
                    />
                    {/** End of FAQ */}
            
                    {/** Add Facilities */}
                    <EditFacilitiesListItem
                      category="eatery"
                      facilities={facilities}
                      error={errors.facilities}
                      onSave={async (newFacilities) => {
                        setFacilities(newFacilities);
                        setErrors(await validateForm());
                      }}
                    />
                    {/** End of Add Facilities */}
            
                    {/** Add Features */}
                    <EditFeaturesListItem
                      features={features}
                      category="eatery"
                      error={errors.features}
                      onSave={async (newFeatures) => {
                        setFeatures(newFeatures);
                        setErrors(await validateForm());
                      }}
                    />
                    {/** End of features */}

                    {/** Add Features */}
                    <div className="py-2">
                      <div className="border flex flex-row justify-between shadow-md px-4 py-2 items-center">
                        <div>
                          <h6 className="text-xl font-semibold">Accessibiity Features</h6>
                          <p className="text-xs opacity-70">Press to edit the accessible features in the eatery</p>
                        </div>
                        {/* <div className="h-6 w-6 bg-indigo-300"></div> */}
                        <FaPlusCircle size={24} color="#FF5900" />
                      </div>
                      <p className="mt-1 text-xs text-red-500 pl-2">Must have at least one feature</p>
                    </div>
                    {/** End of features */}
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}
    </>
  );
}
