import {Formik} from 'formik';
import {FC, useContext, useState} from 'react';
import { FaPlus, FaSave } from 'react-icons/fa';
import {RiRoadMapLine} from 'react-icons/ri';
import {RouteComponentProps, useHistory} from 'react-router-dom';

import {
  Coordinates, Facility, FAQ, Feature, Picture, postTour, postTourDraft,
  RefundType, ReservationType,
} from '../../../../../api';
import {TourStop} from '../../../../../api/tour/types';
import {Card} from '../../../../../components';
import {Input, TextArea} from '../../../../../components/input';
import ActivityIndicator from '../../../../../components/loading';
import { SnackbarProvider } from '../../../../../context/snackbar-provider';
import {OverlayProvider} from '../../../../search-property/context';

import {
  EditFacilitiesListItem, EditFeaturesListItem, EditRefundPolicyListItem,
  EditReservationPolicyListItem, EditTourStopOverlay, PartnerHeader, PickImageListItem,
  PropertyLocationAutoComplete,
} from '../../../components';
import { ConfirmPropertyLocation } from '../../../components/confirm-property-location';
import {EditFaqListItem} from '../../../components/edit-faq-list-item';
import {UploadingOverlay} from '../../../components/uploading-overlay';

import {NewTourStopOverlay} from './new-tour-stop-overlay';
import {validateTourForm} from './validate-form';

export const NewTour: FC<RouteComponentProps> = () => {
  const history = useHistory();
  const {
    setIsVisible: setSnackbarVisible, dismissSnackbar,
  } = useContext(SnackbarProvider);
  const {
    setIsCancellable, setIsOverlay, setOnOverlay,
  } = useContext(OverlayProvider);

  const [gallery, setGallery] = useState<Picture[]>([]);
  // coordinates are fetched once the partner autofills the location
  const [coords, setCoords] = useState<Coordinates>({lat: 0, lng: 0});
  const [destinationCoords, setDestinationCoords] = useState<Coordinates>({lat: 0, lng: 0});
  // by default the reservation type should be the user paying the full amount
  const [reservationPolicy, setReservationPolicy] =
    useState<ReservationType>("full_amount");
    // by default the refund policy is to allow for deduction of a cancellation
    // fee
  const [refundPolicy, setRefundPolicy] = useState<RefundType>("partial_refund");
  const [tourStops, setTourStops] = useState<TourStop[]>([]);
  // monitor all the FAQS input by the partner.
  const [faqs, setFaqs] = useState<FAQ[]>([]);
  // Monitor all the facilities that have been input by the partner
  const [facilities, setFacilities] = useState<Facility[]>([]);
  // monitor all the features input by the partner for the property
  const [features, setFeatures] = useState<Feature[]>([]);

  const [isUploading, setIsUploading] = useState<boolean>(false);

  const [isSaving, setIsSaving] = useState<boolean>(false);

  // to allow easy typing, declared in function scope
  const initialValues = {
    title: "",
    location: "",
    destination: "",
    origin: "",
    description: "",
    short_description: "",
    reservation_policy: "",
    refund_policy: "",
    adult_price: "",
    children_price: "",
  };

  const addNewTourStop = (newTourStop: TourStop) => {
    setTourStops([...tourStops, newTourStop]);
  };

  return (
    <>
      {isUploading && (
        <OverlayProvider.Consumer>
          {({setIsCancellable, setIsOverlay, setOnOverlay}) => (
            <UploadingOverlay
              caption="Uploading tour details"
              setIsCancellable={setIsCancellable}
              setIsOverlay={setIsOverlay}
              setOnOverlay={setOnOverlay}
            />
          )}
        </OverlayProvider.Consumer>
      )}
      <Formik
        initialValues={initialValues}
        validate={(values) => {

          return validateTourForm({
            adult_price: values.adult_price,
            children_price: values.children_price,
            coords,
            description: values.description,
            destination: values.destination,
            facilities,
            features,
            gallery,
            location: values.location,
            origin: values.origin,
            refund_policy: refundPolicy,
            reservation_policy: reservationPolicy,
            short_description: values.short_description,
            stops: tourStops,
            title: values.title
          })
        }}
        onSubmit={(values) => {
          setIsUploading(true);
          // handle the submission
          postTour(
            {
              coords,
              description: values.description,
              short_description: values.description,
              destination: values.destination,
              destination_coords: destinationCoords,
              exceptions: [],
              faqs,
              gallery,
              location: values.location,
              origin: values.origin,
              origin_coords: coords,
              price: {
                adult: Number(values.adult_price),
                children: Number(values.children_price),
              },
              rating: 10,
              refund_policy: refundPolicy,
              reservation_policy: reservationPolicy,
              reviews: [],
              stops: tourStops,
              title: values.title,
              offers: [],
            },
            (err, result) => {
              if (err) {
                console.error(err);
                setSnackbarVisible && setSnackbarVisible({
                  fabPresent: false,
                  navRailPresent: true,
                  isError: true,
                  title: "Unable to publish tour details",
                });
  
                return;
              }
  
              setIsUploading(false);

              if (result) {
                setSnackbarVisible && setSnackbarVisible({
                  fabPresent: false,
                  navRailPresent: true,
                  isError: false,
                  title: `Published ${result.title} details`,
                });

                history.replace(`/property-owner/new/tour/${result.id}`);
              } else {
                setSnackbarVisible && setSnackbarVisible({
                  fabPresent: false,
                  navRailPresent: true,
                  isError: true,
                  title: "Unable to publish tour details",
                });
              }
            },
          );
        }}
      >
        {({
          values, errors, handleChange, handleSubmit, handleBlur, validateForm,
          setErrors,
        }) => (
          <form onSubmit={handleSubmit} onBlur={handleBlur}>
            <PartnerHeader
              title={values.title ? values.title : "New Tour Package"}
              HeaderIcon={RiRoadMapLine}
              actions={[
                {
                  ActionIcon: FaSave,
                  alwaysVisible: false,
                  label: "Save Draft",
                  type: "button",
                  disabled: isSaving,
                  onClick: () => {
                    setIsSaving(true);
                    setOnOverlay && setOnOverlay(() => () => (
                      <ActivityIndicator
                        caption=""
                      />
                    ));
                    setIsCancellable && setIsCancellable(false);
                    setIsOverlay && setIsOverlay(true);
                    postTourDraft(
                      null,
                      {
                        coords,
                        description: values.description,
                        short_description: values.description,
                        destination: values.destination,
                        destination_coords: destinationCoords,
                        exceptions: [],
                        faqs,
                        gallery,
                        location: values.location,
                        origin: values.origin,
                        origin_coords: coords,
                        price: {
                          adult: Number(values.adult_price),
                          children: Number(values.children_price),
                        },
                        rating: 10,
                        refund_policy: refundPolicy,
                        reservation_policy: reservationPolicy,
                        reviews: [],
                        stops: tourStops,
                        title: values.title,
                        offers: [],
                      },
                      (err, result) => {
                        if (err) {
                          console.error(err);
                          setSnackbarVisible && setSnackbarVisible({
                            fabPresent: false,
                            navRailPresent: true,
                            isError: true,
                            title: "Unable to save changes to draft",
                          });
          
                          return;
                        }

                        setIsSaving(false);
                        setOnOverlay && setOnOverlay(null);
                        setIsCancellable && setIsCancellable(true);
                        setIsOverlay && setIsOverlay(false);
          
                        if (result) {
                          setSnackbarVisible && setSnackbarVisible({
                            fabPresent: false,
                            navRailPresent: false,
                            isError: false,
                            title: "All changes saved in draft",
                            action: {
                              label: "DISMISS",
                              onClick: () => {
                                dismissSnackbar && dismissSnackbar();
                              },
                            },
                          });

                          history.replace("/property-owner/tour");
                        } else {
                          setSnackbarVisible && setSnackbarVisible({
                            fabPresent: false,
                            navRailPresent: true,
                            isError: true,
                            title: "Unable to save changes to draft",
                          });
                        }
                      },
                    );
                  },
                },
                {
                  label: "ADD NEW TOUR PACKAGE",
                  disabled: Object.keys(errors).length > 0,
                  ActionIcon: FaPlus,
                  alwaysVisible: true,
                  type: "submit",
                  onClick: () => {
                    console.log("Adding new tour package");
                  },
                },
              ]}
            />
            <div
              className="p-4 sm:grid sm:grid-cols-2 gap-4 lg:grid-cols-3 lg:grid-flow-col-dense lg:max-w-5xl">
              {/** Input Title */}
              <div>
                <Input
                  helper="This is the name of the tour package"
                  id="title"
                  name="title"
                  inputLabel="Tour package name"
                  onChange={handleChange}
                  value={values.title}
                  error={errors.title}
                />
              </div>
              {/** End of input Title */}
              {/** Input Title */}
              <div className="mt-3 sm:mt-0 lg:col-start-1 col-start-2">
                {/** Text input */}
                <PropertyLocationAutoComplete
                  name="location"
                  value={values.location}
                  error={errors.location}
                  inputLabel="First stop location"
                  onChange={handleChange}
                  selectAddress={(place, coords) => {
                    setIsCancellable && setIsCancellable(false);
                    // setCoords(coords);
                    setOnOverlay && setOnOverlay(() => () => (
                      <ConfirmPropertyLocation
                        initialCoords={coords}
                        onConfirm={(coords) => {
                          setCoords(coords);
                        }}
                        removeOverlay={() => {
                          setIsOverlay && setIsOverlay(false);
                          setIsCancellable && setIsCancellable(true);
                          setOnOverlay(null);
                        }}
                      />
                    ));
                  }}
                />
                {/** End of text input */}
              </div>
              {/** End of input Title */}

              {/** Input Title */}
              <div className="mt-3 sm:mt-0 lg:col-start-1 col-start-1">
                {/** Text input */}
                <PropertyLocationAutoComplete
                  name="destination"
                  value={values.destination}
                  error={errors.destination}
                  inputLabel="Last stop location"
                  onChange={handleChange}
                  selectAddress={(place, coords) => {
                    setIsCancellable && setIsCancellable(false);
                    // setCoords(coords);
                    setOnOverlay && setOnOverlay(() => () => (
                      <ConfirmPropertyLocation
                        initialCoords={coords}
                        onConfirm={(coords) => {
                          setDestinationCoords(coords);
                        }}
                        removeOverlay={() => {
                          setIsOverlay && setIsOverlay(false);
                          setIsCancellable && setIsCancellable(true);
                          setOnOverlay(null);
                        }}
                      />
                    ));
                  }}
                />
                {/** End of text input */}
              </div>
              {/** End of input Title */}
          
              {/** Start of Text Area */}
              <div className="mt-3 sm:mt-0 lg:col-start-1 lg:col-end-3">
                <TextArea
                  id="tour-description"
                  name="description"
                  value={values.description}
                  helper="longform description of tour package"
                  inputLabel="tour package description(long)"
                  onChange={handleChange}
                  error={errors.description}
                />
              </div>
              {/** End of Text Area */}
          
              {/** Start of Text Area */}
              <div className="mt-3 lg:col-start-1 lg:col-end-3">
                <TextArea
                  helper="Shorter description of the tour package"
                  id="tour-description-short"
                  name="short_description"
                  inputLabel="Tour package description (short)"
                  value={values.short_description}
                  error={errors.short_description}
                  onChange={handleChange}
                />
              </div>
              {/** End of Text Area */}
          
              {/** Reservation policy card */}
              <EditReservationPolicyListItem
                reservationPolicy={reservationPolicy}
                onSave={async (reservationPolicy) => {
                  setReservationPolicy(reservationPolicy);
                  setErrors(await validateForm());
                }}

              />
              {/** End of Reservation policy card */}
          
              {/** Refund policy card */}
              <EditRefundPolicyListItem
                refundPolicy={refundPolicy}
                onSave={async (refundPolicy) => {
                  setRefundPolicy(refundPolicy);
                  setErrors(await validateForm());
                }}
              />
              {/** End of Refund policy card */}
          
              {/** Pick images overlay */}
              <PickImageListItem
                gallery={gallery}
                minImageCount={6}
                onOverlaySave={async (gallery) => {
                  setGallery(gallery);
                  setErrors(await validateForm());
                }}
              />
              {/* End of pick images overlay */}
          
              {/** Things liks prices and sub properties are found here */}
              <hr className="my-4 sm:col-start-1 sm:col-end-3 h-auto lg:hidden" />
              {/** Special Section */}
              <div className="shadow-md border sm:col-start-1 sm:col-end-3">
                {/** Header for special section */}
                <div className="h-14 flex flex-row justify-between items-center px-4 border-b">
                  <div>
                    <h6 className="text-xl font-semibold">Tour Stops</h6>
                    <p className="text-xs">Add stop</p>
                  </div>

                  {/** Edit Button */}
                  <OverlayProvider.Consumer>
                    {({setIsOverlay, setOnOverlay}) => (
                      <button
                        type="button"
                        className="h-9 px-3 border border-red-500 rounded-md shadow-md text-red-500 font-semibold text-sm flex items-center"
                        onClick={(e) => {
                          e.preventDefault();

                          setOnOverlay && setOnOverlay(() => () => (
                            <NewTourStopOverlay
                              onSave={async (newStop) => {
                                addNewTourStop(newStop);
                                setErrors(await validateForm());
                              }}
                              removeOverlay={() => {
                                setIsOverlay && setIsOverlay(false);
                                setOnOverlay(null);
                              }}
                            />
                          ));

                          setIsOverlay && setIsOverlay(true);
                        }}
                      >
                        Add stop
                      </button>
                    )}
                  </OverlayProvider.Consumer>
                  {/** End of edit Button */}
                </div>

                <div className="p-4 flex flex-row justify-evenly overflow-x-scroll">
                  {/** All Added Sub Properties go here */}
                  {tourStops.map((tourStop, idx) => (
                    <Card
                      key={tourStop.title}
                      cover={tourStop.gallery[0].url}
                      description={tourStop.description}
                      subtitle={`Stop located at: ${tourStop.location}`}
                      title={tourStop.title}
                      action={{
                        label: "Edit Tour Stop",
                        onClick: () => {
                          setOnOverlay && setOnOverlay(() => () => (
                            <EditTourStopOverlay
                              idx={idx}
                              item={tourStop}
                              tourStops={tourStops}
                              removeOverlay={() => {
                                setIsOverlay && setIsOverlay(false)
                                setOnOverlay(null);
                              }}
                              setTourStops={setTourStops}
                            />
                          ));

                          setIsOverlay && setIsOverlay(true);
                        },
                      }}
                    />
                  ))}
                </div>

                {/** Header for price Section */}
                <div className="h-14 flex flex-row justify-between items-center px-4 border-t">
                  <div>
                    <h6 className="text-xl font-semibold">Price of tour</h6>
                    <p className="text-xs">
                      Details the cost of the tour for adults and children
                    </p>
                  </div>

                </div>
                <div className="px-4 py-2 sm:flex sm:flex-row sm:items-center sm:justify-between">
                  <div className="max-w-sm">
                    {/** Price input container */}
                    <Input
                      id="adult-price"
                      name="adult_price"
                      helper="Price it costs an adult in the tour"
                      inputLabel="Cost per adult (in USD)"
                      value={values.adult_price}
                      error={errors.adult_price}
                      onChange={handleChange}
                    />
                    {/** Input container */}
                  </div>

                  <div className="max-w-sm">
                    {/** Price input container */}
                    <Input
                      id="children-price"
                      name="children_price"
                      helper="Price it costs an children in the tour"
                      inputLabel="Cost per child (in USD)"
                      value={values.children_price}
                      error={errors.children_price}
                      onChange={handleChange}
                    />
                    {/** Input container */}
                  </div>
                </div>
              </div>
              {/** Special Section */}
          
              <div className="sm:col-span-2 lg:col-start-1 lg:col-end-4">
                <h3 className="text-2xl font-semibold sm:col-start-1 sm:col-end-3">Aditional Information</h3>
                <div className="sm:grid sm:grid-cols-2 sm:gap-4 lg:grid-cols-3">
                  {/** Add FAQs */}
                    <EditFaqListItem
                      faqs={faqs}
                      onSave={async (newFaqs) => {
                        // update the stateful var faqs
                        setFaqs(newFaqs);
                        setErrors(await validateForm());
                      }}
                      error={(!faqs.length) ? "Must have at least one FAQ" : ""}
                    />
                  {/** End of FAQ */}
          
                  {/** Add Facilities */}
                  <EditFacilitiesListItem
                    category="activity"
                    facilities={facilities}
                    onSave={async (newFacilities) => {
                      // set the new features
                      setFacilities(newFacilities);
                      setErrors(await validateForm());
                    }}
                    error={
                      (!features.length) ? "Must have at least one feature" : ""
                    }
                  />
                  {/** End of Add Facilities */}
          
                  {/** Add Features */}
                  <EditFeaturesListItem
                    category="activity"
                    features={features}
                    onSave={async (newFeatures) => {
                      // save the new features
                      setFeatures(newFeatures);
                      setErrors(await validateForm());
                    }}
                    error={
                      (!features.length) ? "Must have at least one feature" : ""
                    }
                  />
                  {/** End of features */}

                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};
