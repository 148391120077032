// @ts-check
import {useReducer} from 'react';

export {
  fetchCompulsoryFlightFilters, fetchOptionalFlightFilters,
} from './flight-filters';
export {
  fetchCompulsoryRentalFilters, fetchOptionalRentalFilters,
} from './rental-filters';
export {
  fetchCompulsoryCruiseFilters, fetchOptionalCruiseFilters,
} from './cruise-filters';
export {
  fetchCompulsoryEateriesFilters, fetchOptionalEateriesFilters,
} from './eateries-filters';
export {
  fetchCompulsoryConferencingFilters, fetchOptionalConferencingFilters,
} from './conferencing-filters';
export {
  fetchCompulsoryCarFilters, fetchOptionalCarFilters,
} from './cars-filters';
export {
  fetchCompulsoryToursFilters, fetchOptionalToursFilters,
} from './tours-filters';
export {
  fetchCompulsoryActivitiesFilters, fetchOptionalActivitiesFilters,
} from './activities-filters';

////////////////////////////////////////////////////////////////////////////////
// JSDoc Types
////////////////////////////////////////////////////////////////////////////////
/**
 * @typedef {{
 * place: ?string,
 * lat: ?number,
 * lng: ?number,
 * checkin: ?string,
 * checkout: ?string,
 * adults: ?number,
 * children: ?number,
 * filter: ?string[],
 * }} CompulsoryFilters
 *
 * @typedef {{
 * price_lowest: ?number,
 * price_highest: ?number,
 * star_rating: ?number,
 * feature: ?string[],
 * theme: ?string,
 * }} OptionalFilters
 *
 * @typedef {{
 * compulsoryFilters: CompulsoryFilters,
 * optionalFilters: OptionalFilters,
 * }} SearchState
 *
 * @typedef {{
 * type: "UPDATE_All_FILTERS" | "UPDATE_OPTIONAL_FILTERS" |
 * "UPDATE_COMPULSORY_FILTERS",
 * value: any,
 * }} SearchStoreAction
 */

/**
 * This function takes in the current URL object and then creates an initial
 * state according to the parameters in the URL.
 *
 * @returns {SearchState} this is the inital state of he DataFetcher.
 */
const generateInitialState = () => {
  /**@type {SearchState} */
  const initialState = {};
  // fetch all compulsory features that have been input
  initialState.compulsoryFilters = fetchCompulsoryFilters();
  initialState.optionalFilters = fetchOptionalFilters();
  console.log(initialState);

  return initialState;
};

/**
 * This function fetches all the compulsory Filters from the current
 * `window.location` Url.
 * 
 * @returns {CompulsoryFilters}
 */
export const fetchCompulsoryFilters = () => {
  const url = new URL(location.href);
  /**@type {CompulsoryFilters} */
  const compulsoryFilters = {};

  const [loc, ] = url.searchParams.get("place").split(",");
  console.log({loc});
  compulsoryFilters.place =
    /[A-Za-z0-9]-_[A-Za-z0-9]/.test(loc) ? loc.replace("-_", ", "): loc;
  
  if (url.searchParams.has("adults")) {
    compulsoryFilters.adults = Number(url.searchParams.get("adults"));
  } else {
    compulsoryFilters.adults = 1;
  }

  if (url.searchParams.has("children")) {
    compulsoryFilters.children = Number(url.searchParams.get("children"));
  } else {
    compulsoryFilters.children = 0;
  }

  compulsoryFilters.checkin = url.searchParams.get("checkin");
  compulsoryFilters.checkout = url.searchParams.get("checkout");
  if (url.searchParams.has("lat")) {
    compulsoryFilters.lat = Number(url.searchParams.get("lat"));
    compulsoryFilters.lng = Number(url.searchParams.get("lng"));
  }
  compulsoryFilters.filter = url.searchParams.getAll("filter");

  return compulsoryFilters;
};

/**
 * This function fetches all the optional features encoded in the URL.
 *
 * @returns {OptionalFilters} the optional filters that have been captured.
 */
export const fetchOptionalFilters = () => {
  const url = new URL(location.href);

  /**@type {OptionalFilters} */
  const optionalFilters = {};

  optionalFilters.feature = url.searchParams.getAll("feature");
  if (url.searchParams.has("price_highest")) {
    optionalFilters.price_highest = Number(url.searchParams.get("price_highest"));
  }

  if (url.searchParams.has("price_lowest")) {
    optionalFilters.price_lowest = Number(url.searchParams.get("price_lowest"));
  }

  optionalFilters.star_rating = Number(url.searchParams.get("star_rating"));
  optionalFilters.theme = url.searchParams.get("theme");

  return optionalFilters;
};

/**
 * DataFetcher store reducer.
 *
 * @param {SearchState} state state of the store
 * @param {SearchStoreAction} action action that is to be applied to store
 *
 * @returns {SearchState} the new store state.
 */
function dataFetcherReducer(state, action) {
  switch (action.type) {
    case "UPDATE_All_FILTERS":
      return {
        ...state,
        compulsoryFilters: fetchCompulsoryFilters(),
        optionalFilters: fetchOptionalFilters(),
      };
    case "UPDATE_COMPULSORY_FILTERS":
      return {
        ...state,
        compulsoryFilters: fetchCompulsoryFilters(),
      };
    case "UPDATE_OPTIONAL_FILTERS":
      return {
        ...state,
        optionalFilters: fetchOptionalFilters(),
      };
    default:
      return state;
  }
}

/**
 * useDataFetcherReducer Hook.
 *
 * @returns {[
 * state: SearchState,
 * {
 *  updateAllFilters: () => void,
 *  updateCompulsoryFilters: () => void,
 *  updateOptionalFilters: () => void,
 * }
 * ]} an array of store state and actions.
 */
export function useSearchReducer() {
  const [state, dispatch] =
    useReducer(dataFetcherReducer, generateInitialState());

  const updateAllFilters = () => {
    dispatch({type: "UPDATE_All_FILTERS", value: 1});
  };

  const updateCompulsoryFilters = () => {
    dispatch({type: "UPDATE_COMPULSORY_FILTERS", value: 0});
  };

  const updateOptionalFilters = () => {
    dispatch({type: "UPDATE_OPTIONAL_FILTERS", value: 0});
  };

  return [
    state,
    {updateAllFilters, updateCompulsoryFilters, updateOptionalFilters},
  ];
}