import {FC} from 'react';
import {RouteComponentProps} from 'react-router';

import {Header} from '../../components';
import {ReservationManager} from '../../context';

import {AppFooter} from '../index/components/app-footer';

import {CheckoutDialog} from './checkout-dialog';
import {NoReservationFound} from './no-reservation-found';

export const PropertyCheckout: FC<RouteComponentProps> = () => {
  return (
    <>
      <Header.LightHeader />
      <ReservationManager.Consumer>
        {({reservations}) => (
          <div className="mt-4">
            {(reservations.length === 0) && (
              <NoReservationFound />
            )}

            {(reservations.length > 0) && (
              <CheckoutDialog />
            )}
          </div>
        )}
      </ReservationManager.Consumer>
      <AppFooter origin="" />
    </>
  );
}
