import {Dispatch, FC, SetStateAction, useContext, useEffect} from 'react';
import { deleteDraftReservation, fetchReservationDrafts } from '../../../api';
import { ReservationSheet } from '../../../components/reservations-sheet';
import { OverlayProvider } from '../../../context';
import { AppStateContext } from '../../../context/app-state-provider';

type DraftedReservationSheetProps = {
  setReminderMounted: Dispatch<SetStateAction<boolean>>;
};

export const DraftedReservationSheet: FC<DraftedReservationSheetProps> = ({
  setReminderMounted,
}) => {
  const {
    setIsOverlay, setOnOverlay,
  } = useContext(OverlayProvider);
  const {actions} = useContext(AppStateContext);

  useEffect(() => {
    fetchReservationDrafts((err, result) => {
      if (err) {
        console.error(err);
        return;
      }

      if (result) {
        if (result.length > 0) {
          setOnOverlay && setOnOverlay(() => () => (
            <ReservationSheet
              reservations={result}
              closeSheet={() => {
                setIsOverlay && setIsOverlay(false);
                setOnOverlay(null);
                // prevent re-opening of the reminder
                actions.setReminderShown();
              }}
              removeReservation={(draftedReseration) => {
                deleteDraftReservation(`${draftedReseration.id}`);
              }}
            />
          ));
          setIsOverlay && setIsOverlay(true);
        }
      }

      setReminderMounted(true);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <></>
  );
};
