import {Component} from "react";
import {FaPowerOff} from "react-icons/fa";
import {Switch, Route, Link} from "react-router-dom";
import {connect} from "react-redux";
import Dashboard from "./components/Dashboard";
import MyTrips from "./components/my-trips";

import {Helmet} from "react-helmet";
import {auth} from "firebase";

class App extends Component {
  componentDidMount() {
    auth().onAuthStateChanged((user) => {
      ////console.log(user)
      if (!user) {
        window.location = "/signin";
        //
      }
    });
  }
  render() {
    const profile =
      "https://firebasebasestorage.googleapis.com/v0/b/safarixpertz.appspot.com/o/user.png?alt=media&token=5466f260-9091-4391-b4b8-322b9938d359";
    return (
      <>
        <Helmet>
          <title>Safarixperts - User</title>
        </Helmet>

        <div style={{ minHeight: "100vh", display: "flex" }}>
          <div style={{ backgroundColor: "#f2f2f2", flex: 1 }}>
            {/* Start of header */}
            <div
              className="flex flex-row items-center justify-center"
              style={{
                height: 56,
                borderBottom: 1,
                borderBottomStyle: "solid",
                borderBottomColor: "#ccc",
                backgroundColor: "#000104",
                position: "sticky",
                top: 0,
              }}
            >
              <div className="admin-display-port px-4 flex flex-row justify-between">
                <div className="">
                  <Link to="/">
                    {/* <p className="logo-text text-white">
                      Safari<span className="text-safari-gold">Xperts</span>
                    </p> */}
                    <p className="font-lato text-2xl font-semibold text-white">
                      Safari<span className="text-safari-gold">Xperts</span>.com
                    </p>
                  </Link>
                </div>
                <div
                  className="items-center"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: 120,
                  }}
                >
                  <div
                    style={{
                      height: 22,
                      width: 22,
                      backgroundImage: "url(" + profile + ")",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      borderRadius: 40,
                      marginTop: 10,
                    }}
                  ></div>
                  <a href="/list-your-property" className="mobile-hidden ml-4">
                    <div
                      className="border-2 rounded-md flex items-center px-3 border-white text-white font-semibold hover:text-safari-gold hover:border-safari-gold"
                      style={{ height: 32 }}
                    >
                      List Your Property
                    </div>
                  </a>
                  <Link to="/">
                    <FaPowerOff
                      className="cursor-pointer text-red-500 hover:text-white"
                      size={24}
                      style={{
                        // fontSize: 15,
                        marginLeft: 20,
                      }}
                      onClick={() => {
                        auth().signOut();
                      }}
                    />
                  </Link>
                </div>
              </div>
            </div>
            {/* End of header */}
            <div className="backTriangle" style={{ left: 0 }}></div>
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div className="admin-display-port">
                <Switch>
                  <Route path="/user/my-trips" component={MyTrips} exact />
                  <Route path="/user" component={Dashboard} exact />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.isSignedIn,
    user: state.user,
    authLevel: state.authLevel,
  };
};

export default connect(mapStateToProps)(App);
/**TODO: ask user to change password */
