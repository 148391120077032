import React from "react";

export type MobileFilter = {
  visibleFilter: "none";
  newSearchFilter: boolean;
  setVisibleFilter: null |
    (
      (visibleFilter: "none" | "facilities" | "features" | "rating" | "price") => void);
  setNewSearchFilterVisible: ((newSearchFilter: boolean) => void) | null;
};

export const MobileFilterContext = React.createContext<MobileFilter>({
  visibleFilter: "none",
  newSearchFilter: false,
  setVisibleFilter: null,
  setNewSearchFilterVisible: null,
});
