import {firestore} from "firebase";
import { Draft } from "..";

import {OnCompleteCallback, Picture} from "../..";
import {uploadPictures} from "../../cdn";
import {Rental} from "../../rental";
import {getUser, NoUserFoundError} from "../../user";

export const postRentalDraft = (
  draftId: string | null, rental: Rental,
  onComplete: OnCompleteCallback<Draft<Rental>>,
): void => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);

    return;
  }

  if (draftId) {
    updateRentalDraft(draftId, rental, onComplete);

    return;
  }

  // we fetch all the pictures from the rental facility itself, and also all
  // the pictues from the rooms themselves.
  // We then create an array (Buffer) that records how many pictures we are
  // uploading for each, this will be used to get back all the pictures in order
  // of so as to re-order them back to their respective places without a hassle.
  //
  // E.g if we have a rental facility with 6 pictures of its own and three
  // rental rooms with pictures of their own, then we can create the buffer
  // as follows:
  // buffer = [6, 6, 7, 8];
  // then an array of pictures to upload is created with a total of
  // (6 + 6 + 7 + 8) images, where:
  // they are ordered based on:
  // rental facility first, rooms based on the index of the room,
  // As such, these pictures can be gotten back by splicing and then pushing
  // back in order of index starting with the rental facility as the first
  // index (0)

  const bufferSizes: number[] = [];
  const imageBuffer: Picture[] = [];

  bufferSizes.push(rental.gallery.length);
  imageBuffer.push(...rental.gallery);

  // push all the pictures for each room
  rental.rooms.forEach((room) => {
    bufferSizes.push(room.gallery.length);
    imageBuffer.push(...room.gallery);
  });


  // after all the images are pushed we can upload them all and then replace
  // after successful upload
  uploadPictures("rental", imageBuffer, async (err, uploadedImages) => {
    if (err) {
      onComplete(err, null);
      return;
    }

    if (uploadedImages) {
      rental.gallery = uploadedImages.splice(0, bufferSizes[0]);

      // starting from the second index, we start assigning the rooms all their
      // images back
      for (let i=1; i<bufferSizes.length; i++) {
        rental.rooms[i - 1].gallery = imageBuffer.splice(0, bufferSizes[i]);
      }

      // after all the images are uploaded we can now get to uploading the
      // confernce data
      try {
        const doc =  await firestore()
          .collection("draft")
          .add({
            category: "rental", draft: rental, uid: user.uid,
          } as Draft<Rental>);

        const docSnapshot = await doc.get();

        onComplete(
          null, ({draft: docSnapshot.data()} as Draft<Rental>),
        );
      } catch (err) {
        const rentalUploadError =
          new Error("Error uploading rental facility details");
        onComplete(rentalUploadError, null);
      }
      
    } else {
      // these images may be null
      const uploadErr = new Error("The images to be uploaded returned null");
      onComplete(uploadErr, null);
    }
  });
};

export const updateRentalDraft = (
  id: string, rental: Rental, onComplete: OnCompleteCallback<Draft<Rental>>,
): void => {
  const user = getUser();

  if (!user) {
    onComplete(new NoUserFoundError(), null);

    return;
  }

  // we fetch all the pictures from the rental facility itself, and also all
  // the pictues from the rooms themselves.
  // We then create an array (Buffer) that records how many pictures we are
  // uploading for each, this will be used to get back all the pictures in order
  // of so as to re-order them back to their respective places without a hassle.
  //
  // E.g if we have a rental facility with 6 pictures of its own and three
  // rental rooms with pictures of their own, then we can create the buffer
  // as follows:
  // buffer = [6, 6, 7, 8];
  // then an array of pictures to upload is created with a total of
  // (6 + 6 + 7 + 8) images, where:
  // they are ordered based on:
  // rental facility first, rooms based on the index of the room,
  // As such, these pictures can be gotten back by splicing and then pushing
  // back in order of index starting with the rental facility as the first
  // index (0)

  const bufferSizes: number[] = [];
  const imageBuffer: Picture[] = [];

  bufferSizes.push(rental.gallery.length);
  imageBuffer.push(...rental.gallery);

  // push all the pictures for each room
  rental.rooms.forEach((room) => {
    bufferSizes.push(room.gallery.length);
    imageBuffer.push(...room.gallery);
  });


  // after all the images are pushed we can upload them all and then replace
  // after successful upload
  uploadPictures("rental", imageBuffer, async (err, uploadedImages) => {
    if (err) {
      onComplete(err, null);
      return;
    }

    if (uploadedImages) {
      rental.gallery = uploadedImages.splice(0, bufferSizes[0]);

      // starting from the second index, we start assigning the rooms all their
      // images back
      for (let i=1; i<bufferSizes.length; i++) {
        rental.rooms[i - 1].gallery = imageBuffer.splice(0, bufferSizes[i]);
      }

      // after all the images are uploaded we can now get to uploading the
      // confernce data
      try {
        await firestore()
          .collection("draft")
          .doc(id)
          .update({
            draft: rental,
          } as Draft<Rental>);

        const docSnapshot = await firestore().collection("draft").doc(id).get();

        onComplete(
          null, ({draft: docSnapshot.data()} as Draft<Rental>),
        );
      } catch (err) {
        const rentalUploadError =
          new Error("Error uploading rental facility details");
        onComplete(rentalUploadError, null);
      }
      
    } else {
      // these images may be null
      const uploadErr = new Error("The images to be uploaded returned null");
      onComplete(uploadErr, null);
    }
  });
};
