import {FC, useContext, useEffect, useState} from 'react';
import type {RouteComponentProps} from 'react-router-dom';
import {DatePicker, InputNumber} from 'antd';
import Lottie from 'react-lottie';

import {Conference, fetchAvailabeConferenceRooms} from '../../../../api';
import animation from '../../../../assets/anim/loading-conference.json';
import {Button, Dropdown} from '../../../../components';
import {Input, TimePicker} from '../../../../components/input';

import {OverlayProvider} from '../../../search-property/context';

import Container, {SubPropertySection} from '../../components/container';
import {Card} from '../../../../components/card/property-card';
import {BookablePropertyOverlay} from '../../components/bookable-property-overlay';
import {ProceedToCheckout} from '../../components/proceed-to-checkout';

import {fetchCompulsoryConferencingFilters} from '../../../../reducers';
import {ConferenceReservation} from '../../../../api/reservation/types';
import {DateUtil} from '../../../../util';

import {ConferencePriceColumn} from './conference-price-column';
import {TextInput} from '../../../../components/input/text-input';
import ActivityIndicator from '../../../../components/loading';
import { AppStateContext } from '../../../../context/app-state-provider';

type ConferenceProps = RouteComponentProps<{id: string}>;

export const ConferenceProperty: FC<ConferenceProps> = ({match}) => {
  const {state} = useContext(AppStateContext);

  const [activeSubProperty, setActiveSubProperty] = useState(-1);
  const [conference, setConference] = useState<Conference | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorOccured, setErrorOccured] = useState<boolean>(false);

  // input control
  const [rate, setRate] = useState<"hr" | "day">("hr");
  const [time, setTime] = useState<string>("10:00");
  const [numHours, setNumHours] = useState<number>(1);
  const [numDays, setNumDays] = useState<number>(1);
  const [attendees, setAttendees] = useState<number>(1);

  const [price, setPrice] = useState<number>(0);

  const {date: conferenceStartDate} = fetchCompulsoryConferencingFilters();

  const [date, setDate] = useState<Date>(
    conferenceStartDate ?
      new Date(DateUtil.changeDateFormat(
        conferenceStartDate, "DD-MM-YYYY", "YYYY-MM-DD",
      )) :
      new Date()
  );

  useEffect(() => {
    const checkinDate = conferenceStartDate ? new Date(
      DateUtil.changeDateFormat(
        conferenceStartDate,
        "DD-MM-YYYY",
        "MM-DD-YYYY",
      ),
    ) : new Date();

    checkinDate.setHours(Number(time.split(":")[0]), 0, 0, 0);
    // fetch the Hotels and after fetching, carry out the on success function
    fetchAvailabeConferenceRooms(match.params.id, checkinDate.getTime(), (err, data) => {
      setIsLoading(false);

      if (err) {
        setErrorOccured(true);
        return;
      }

      // handle the hotel data that has been retrieved by the system
      setConference(data);
    });
  }, [match.params.id, conferenceStartDate, time]);

  const calculatePrice = () => {
    let newPrice = 0;

    if (rate === "hr") {
      if (conference) {
        newPrice = conference.rooms[activeSubProperty].price.hour * numHours;
      }
    } else {
      if (conference) {
        newPrice = conference.rooms[activeSubProperty].price.day * numDays;
      }
    }

    setPrice(newPrice);
  };

  const onProceedToCheckout = (): ConferenceReservation => {
    // creates the reservation card and then proceeds to checkout
    console.log(`Proceeding to checkout with price: ${price}`);

    const checkinDate = conferenceStartDate ? new Date(
      DateUtil.changeDateFormat(
        conferenceStartDate,
        "DD-MM-YYYY",
        "MM-DD-YYYY",
      ),
    ) : new Date();

    checkinDate.setHours(Number(time.split(":")[0]), 0, 0, 0);

    const reservation: ConferenceReservation = {
      amount_received: 0,
      category: "conference",
      checkin: checkinDate.getTime(),
      due_date: checkinDate.getTime(),
      expected_amount: price,
      property_id:
        conference ?
          `${conference.id}:${conference.rooms[activeSubProperty].id}` :
          "",
      reservation_date: Date.now(),
      reservation_type: conference ? conference.reservation_policy : "full_amount",
      status: "pending",
      people: attendees,
      number_of_rooms: 1,
    };

    return reservation;
  };

  const renderCheckoutColumn = () => {
    return (
      <>
        {conference && (
          <ProceedToCheckout
            onProceedToCheckout={onProceedToCheckout}
            price={price}
          >
            <Input
              disabled={true}
              inputLabel="Conference room"
              name="conference-room"
              onChange={() => console.log("IGNORED")}
              value={conference.rooms[activeSubProperty].title}
              helper="Name of the conference room"
              id="conference-room-name"
            />
            <Input
              disabled={true}
              inputLabel="Conference date"
              name="conference-date"
              onChange={() => console.log("IGNORED")}
              value={conferenceStartDate ? conferenceStartDate : ""}
              error=""
              helper="Date of the conference"
              id="conference-room-date"
            />
            <Input
              inputLabel="Number of people attending"
              helper="The number of people attending the conference"
              id="no-of-people"
              name="no-of-people"
              value={`${attendees}`}
              onChange={(e) => {
                setAttendees(Number(e.target.value));
              }}
            />
            <Dropdown
              options={[
                {title: "Hourly rate", value: "hr"},
                {title: "Full-day Rate", value: "day"},
              ]}
              defaultValue={rate}
              label="Rate"
              name="rate"
              onChange={(e) => {
                setRate(e.target.value as "hr" | "day");
                // after the rate is set, we proceed to making sure that the
                // price coresponds
                calculatePrice();
              }}
            />
            {rate === "hr" && (
              <div>
                <TimePicker
                  name="time"
                  onChange={(e) => setTime(e.target.value)}
                  value={time}
                  className=""
                  error=""
                  label="Conference time"
                />
                <Dropdown
                  defaultValue={numHours}
                  label="Number of hours"
                  name="num-hours"
                  onChange={(e) => {
                    setNumHours(Number(e.target.value));
                    // after the number of hours changes we need to calculate
                    // the new rate to be asked from the user.
                    calculatePrice();
                  }}
                  options={[
                    {title: "1 hour", value: 1},
                    {title: "2 hours", value: 2},
                    {title: "3 hours", value: 3},
                    {title: "4 hours", value: 4},
                    {title: "5 hours", value: 5},
                    {title: "6 hours", value: 6},
                    {title: "7 hours", value: 7},
                    {title: "8 hours", value: 8},
                  ]}
                />
              </div>
            )}
            {rate === "day" && (
              <>
                <Input
                  disabled={false}
                  error=""
                  inputLabel="Number of days"
                  name="num-days"
                  onChange={(e) => {
                    setNumDays(Number(e.target.value));
                    // adapt to the new number of days
                    calculatePrice();
                  }}
                  value={`${numDays}`}
                  id="number-of-days-for-conference"
                  helper="Number of days the conference will run for"
                />
              </>
            )}
          </ProceedToCheckout>
        )}
      </>
    );
  };

  const {ACTIVE_CURRENCY} = state;
  const exchangeRate = ACTIVE_CURRENCY !== "USD" ?
    state.CONVERSION_RATES.rates[ACTIVE_CURRENCY] :
    1;


  const renderPriceColumn = () => {
    return (
      <>
        {conference && (
          <ConferencePriceColumn
            price={conference.rooms[activeSubProperty].price}
            capacity={conference.rooms[activeSubProperty].capacity}
          />
        )}
      </>
    );
  };

  return (
    <>
      {isLoading && (
        <div className="w-full h-96 flex flex-col items-center justify-center">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animation,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            style={{}}
            height={200}
            width={200}
          />
          <p className="text-base font-semibold">Fetching Hotel Details</p>
        </div>
      )}
      {!isLoading && !errorOccured && conference && (
        <OverlayProvider.Consumer>
          {({setIsOverlay, setOnOverlay, setIsCancellable}) => (
            <Container
              property={conference}
              showDirections={true}
              renderSearchSection={() => {
                return (
                  <>
                    <TextInput
                      disabled={true}
                      value={conference.title}
                      helper="Name of the facility"
                      id="hotel-name"
                      inputLabel="Conference facility Name"
                      name="facility_name"
                      onChange={(e) => console.log("Should not change")}
                      className="mb-2"
                    />
                    <p className="text-xs mb-1">
                      Choose new check in and checkout dates
                    </p>
                    <DatePicker
                      onChange={(e) => {
                        if (e) {
                          setDate(e.toDate());
                        }
                      }}
                    />
                    <p className="text-xs mb-1">Number of adults</p>
                    <InputNumber
                      className="mb-2 w-full"
                      defaultValue={attendees}
                      value={attendees}
                      onChange={(e) => {
                        setAttendees(e);
                      }}
                    />
                    <div className="flex flex-row justify-center">
                      <Button
                        disabled={
                          DateUtil.changeDateFormatFromDate(
                            date, "DD-MM-YYYY",
                          ) === conferenceStartDate
                        }
                        type="button"
                        onClick={() => {
                          setIsCancellable && setIsCancellable(false);
                          setOnOverlay && setOnOverlay(() => () => (
                            <ActivityIndicator
                              caption=""
                            />
                          ));
                          setIsOverlay && setIsOverlay(true);

                          fetchAvailabeConferenceRooms(
                            match.params.id,
                            date.getTime(),
                            (err, hotel) => {
                              setIsCancellable && setIsCancellable(true);
                              setIsOverlay && setIsOverlay(false);
                              setOnOverlay && setOnOverlay(null);
                              if (err) {
                                console.error(err);

                                return;
                              }

                              // set the hotel details after search
                              setConference(hotel);
                            },
                          )
                        }}
                      >
                        Check Availability
                      </Button>
                    </div>
                  </>
                );
              }}
            >
              <SubPropertySection title="Conference Rooms">
                {conference.rooms.map((room, idx) => (
                  <Card
                    key={`conference_room_${idx}`}
                    cover={room.gallery[0].url}
                    description={room.description}
                    subtitle={
                      `${
                        (room.price.hour * exchangeRate).toFixed(2)
                      } ${ACTIVE_CURRENCY}/hr or ${
                        (room.price.day * exchangeRate).toFixed(2)
                      } ${ACTIVE_CURRENCY}/day`
                    }
                    title={room.title}
                    action={{
                      label: "View Room",
                      onClick: () => {
                        setActiveSubProperty(0);
                        setOnOverlay && setOnOverlay(() => (
                          <BookablePropertyOverlay
                            gallery={conference.rooms[activeSubProperty].gallery}
                            renderCheckoutColumn={renderCheckoutColumn}
                            renderPriceColumn={renderPriceColumn}
                            description={
                              conference.rooms[activeSubProperty].description
                            }
                            facilities={
                              conference.rooms[activeSubProperty].facilities
                            }
                            features={
                              conference.rooms[activeSubProperty].features
                            }
                            location={conference.location}
                            policies={
                              conference.rooms[activeSubProperty].policies
                            }
                            removeOverlay={() => {
                              setIsOverlay && setIsOverlay(false);
                              setOnOverlay && setOnOverlay(null);
                            }}
                            title={conference.rooms[activeSubProperty].title}
                          />
                        ))
                        setIsOverlay && setIsOverlay(true);
                      },
                    }}
                  />
                ))}
              </SubPropertySection>
            </Container>
          )}
        </OverlayProvider.Consumer>
      )}
      {!isLoading && !errorOccured && !conference && (
        <></>
      )}
      {!isLoading && errorOccured && (
        <></>
      )}
    </>
  );
};
