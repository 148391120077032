import {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import {FaUtensils} from 'react-icons/fa';
import {useHistory} from 'react-router';

import {
  Activity as ActivityProperty, Coordinates, Facility, Feature, filterActivities,
  Property,
} from '../../../../api';
import { activityFacility, activityFeatures } from '../../../../api/features';
import {SearchPropertyCard} from '../../../../components/search-property-card';
import {fetchCompulsoryActivitiesFilters} from '../../../../reducers';
import {DistanceUtil} from '../../../../util';
import {calculateActivityPrice} from '../../../../util/price-calculator';
import { rankFilters } from '../../../../util/rank-filters';

import {ContainerTwo} from '../../components/container-two';
import {FilterableProperty, FilterContext} from '../../context';
import {addFacility, filter, removeFacility} from '../../util';

export const Activity: FC = () => {
  const history = useHistory();

  const [filterableProperties, setFilterableProperties] =
    useState<FilterableProperty[]>([]);
  const [viewableResults, setViewableResults] =
    useState<ActivityProperty[]>([]);
  const [activities, setActivities] = useState<ActivityProperty[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(true);

  const [activeFacilities, setActiveFacilities] = useState<Facility[]>([]);
  const [activeFeatures, setActiveFeatures] = useState<Feature[]>([]);

  const [rawResultsStale, setRawResultsStale] = useState<boolean>(false);
  const [filterResultsStale, setFilterResultsStale] = useState<boolean>(false);

  const {place, adults, children, lat, lng} = fetchCompulsoryActivitiesFilters();
  const coords: Coordinates = {
    lat: (lat) ? lat : 0,
    lng: (lng) ? lng : 0,
  };

  useEffect(() => {
    const location = (place) ? place : "";
    const coords: Coordinates = {
      lat: (lat) ? lat : 0,
      lng: (lng) ? lng : 0,
    };

    filterActivities(location, coords, (err, results) => {
      if (err) {
        console.error(err);

        return;
      }

      if (results) {
        setIsFetching(true);
        setActivities(results);
        setViewableResults(results);
        setFilterableProperties(results.map((result) => ({
          ...result,
          price: calculateActivityPrice(
            adults ? adults : 1,
            children ? children : 0,
            result
          ),
        })));
        setRawResultsStale(false);
      }
    });
  }, [rawResultsStale]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const rankedResults = rankFilters(
      activities,
      {
        active: activeFacilities.map((facility) => facility.title),
        get: (activity) => activity.facilities.map((facility) => facility.title),
      },
      {
        active: activeFeatures.map((feature) => feature.title),
        get: (activity) => activity.features.map((feature) => feature.title),
      },
    );

    setViewableResults(rankedResults);
    setFilterResultsStale(false);
  }, [filterResultsStale]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FilterContext.Provider
      value={{
        filterResultsStale,
        rawResults: activities,
        rawResultsStale,
        results: filterableProperties,
        setFilterResultsStale,
        setRawResultsStale,
        setViewableResults: setViewableResults as (viewableResults: Property[]) => void,
        setResults: setActivities as Dispatch<SetStateAction<Property[]>>,
        addFacility: (facility) => {
          addFacility(facility, activeFacilities, setActiveFacilities);
        },
        removeFacility: (facility) => {
          removeFacility(facility, activeFacilities, setActiveFacilities);
        },
      }}
    >
      <ContainerTwo<ActivityProperty>
        HeaderIcon={FaUtensils}
        columnData={[
          {
            title: "Activity facilities",
            data: activityFacility,
            onChange: (activeFacilities) => {
              setActiveFacilities(filter(activeFacilities, activityFacility));
              setFilterResultsStale(true);
            },
          },
          {
            title: "Activity features",
            data: activityFeatures,
            onChange: (activeFeatures) => {
              setActiveFeatures(filter(activeFeatures, activityFeatures));
              setFilterResultsStale(true);
            },
          },
        ]}
        filterOptions={[
          {
            idx: 0,
            onClick: () => console.log("Selecting top solo filters"),
            title: "Top solo picks",
            onFilter: (results) => results,
          },
          {
            idx: 1,
            onClick: () => console.log("Selecting Stars (Highest first)"),
            title: "Stars (Highest first)",
            onFilter: (results) => {
              const parsedResults = (results as Property[]).sort((result, nextResult) => {
                return nextResult.rating - result.rating;
              });

              return parsedResults;
            },
          },
          {
            idx: 2,
            onClick: () => console.log("Selecting stars (Lowest First)"),
            title: "Stars (Lowest first)",
            onFilter: (results) => {
              const parsedResults = (results as Property[]).sort((result, nextResult) => {
                return result.rating - nextResult.rating;
              });

              return parsedResults;
            },
          },
          {
            idx: 3,
            onClick: () => console.log("Selecting by Price (Highest)"),
            title: "Price (Highest)",
            onFilter: (results) => {
              const parsedResults = (results as unknown as ActivityProperty[]).sort((result, nextResult) => {
                return nextResult.price.adult - result.price.adult;
              });

              return parsedResults;
            },
          },
          {
            idx: 4,
            onClick: () => console.log("Select by price (lowest)"),
            title: "Price (Lowest)",
            onFilter: (results) => {
              const parsedResults = (results as unknown as ActivityProperty[]).sort((result, nextResult) => {
                return result.price.adult - nextResult.price.adult;
              });

              return parsedResults;
            },
          },
          {
            idx: 5,
            onClick: () => console.log("Selecting top Reviewed"),
            title: "Top Reviewed",
            onFilter: (results) => {
              const parsedResults = (results as Property[]).sort((result, nextResult) => {
                return nextResult.reviews.length - result.reviews.length;
              });

              return parsedResults;
            },
          }
        ]}
        headerTitle={`Showing results for ${place}`}
        loading={isFetching}
        results={viewableResults}
      >
        {(viewableResults) => viewableResults.map((activity, idx) => (
          <SearchPropertyCard
            key={activity.id}
            action={{
              label: "View Rental",
              onClick: () => {
                history.push(`/property/activity/${activity.id}?${
                  new URL(location.href).searchParams.toString()
                }`)
              },
            }}
            comments={[]}
            cover={activity.gallery[0].url}
            description={activity.description}
            duration={`2 days`}
            idx={idx}
            item={activity}
            location={activity.location}
            origin="search"
            price={activity.price.adult}
            rating={activity.rating}
            style={{}}
            title={activity.title}
            subtitle={
              `${
                DistanceUtil.haversineDistance("km", coords, activity.coords)
                  .toFixed(2)
              } KM from ${place}`
            }
          />
        ))}
      </ContainerTwo>
    </FilterContext.Provider>
  );
};
