import {FC, useEffect, useState} from 'react';
import {DatePicker, Select, Switch, InputNumber} from 'antd';

import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import {Input} from '../../../../components/input';
import {Button} from '../../../../components';
import {Hotel} from '../../../../api';
import {PriceChange} from '../../../../api/hotel/Offer';
import {generateId} from '../../../../util';

type HotelPriceChangeProps = {
  propertyId: string;
  property: Record<string, unknown>;
  removeOverlay: () => void;
  addToCalendar: (newOffer: PriceChange<number[]>) => void;
};


export const HotelPriceChange: FC<HotelPriceChangeProps> = ({
  propertyId,
  property,
  removeOverlay,
  addToCalendar,
}) => {
  const todaysDate = new Date();
  todaysDate.setHours(0, 0, 0, 0);

  const [startDate, setStartDate] = useState<Date | null>(null);

  const [endDate, setEndDate] = useState<Date | null>(null);

  // used to make sure there can be no double additions
  const [activeRoom, setActiveRoom] = useState<number[]>([0]);
  const hotel = property as Hotel;

  const [rates, setRates] = useState<number[]>([0]);
  const [ratesEnabled, setRatesEnabled] = useState<boolean[]>([false]);

  const [priceType, setPriceType] = useState<"discount" | "hike">("discount");
  const [newSubPropPrices, setNewSubPropPrices] =
    useState<number[]>([]);

  useEffect(() => {
    // anytime the rateEnabled state changes, we make sure to reset newPrice
    setNewSubPropPrices([0]);
  }, [ratesEnabled]);

  return (
    <>
      <h6 className="text-xl font-semibold">{hotel.title}</h6>
      <div className="p-4 bg-white">
        <p className="text-xs mb-1">Room type</p>
        <Select
          className="w-full"
          mode="multiple"
          defaultValue={[0]}
          onChange={(val) => {
            setActiveRoom(val);

            // reset
            setNewSubPropPrices(val.map(() => 0));
            setEndDate(null);
            setStartDate(null);
            setRates(val.map(() => 0));
            setRatesEnabled(val.map(() => false));
          }}
        >
          {(property as Hotel).rooms.map((room, idx) => (
            <Select.Option key={`${room.id}`} value={idx}>
              {room.title}
            </Select.Option>
          ))}
        </Select>
        <div className="flex flex-row justify-between mt-3">
          <div>
            <h6 className="text-xs mb-1">Start date</h6>
            <div className="flex flex-row justify-between">
              <DatePicker
                className="flex-shrink-0 flex-grow-0"
                allowClear={false}
                disabledDate={(date) => {
                  return Number(date.format("x")) < todaysDate.getTime();
                }}
                onChange={(date) => {
                  if (date) {
                    setStartDate(date.toDate());
                  }
                }}
              />
            </div>
          </div>

          <div className="">
            <h6 className="text-xs mb-1">End date</h6>
            <div className="flex flex-row justify-between">
              <DatePicker
                className="flex-shrink-0 flex-grow-0"
                allowClear={false}
                disabledDate={(date) => {
                  if (!startDate) {
                    return Number(date.format("x")) < todaysDate.getTime();
                  } else {
                    // we should make sure that the date is more later that the
                    // start date
                    return Number(date.format("x")) < startDate.getTime();
                  }
                }}
                onChange={(date) => {
                  if (date) {
                    setEndDate(date.toDate());
                  }
                }}
              />
            </div>
          </div>
        </div>

        {activeRoom.map((room) => (
          <>
            <h6 className="text-xl font-semibold font-latoa mt-3">
              {hotel.rooms[room].title}
            </h6>
            <Input
              key={`${hotel.rooms[room].id}`}
              className="mt-3"
              disabled={true}
              helper="Normal Price"
              id="no-of-rooms"
              inputLabel="Standard room price"
              name="no-of-rooms"
              value={`$ ${hotel.rooms[room].price}`}
              onChange={(e) => {
                console.log(Number(e.target.value));
              }}
            />
            <p className="text-xs mt-3 mb-1">Select price change type</p>
            <Select
              className="w-full"
              defaultValue="discount"
              onChange={(val) => {
                setPriceType(val);
              }}
            >
              <Select.Option value="hike">Hike price</Select.Option>
              <Select.Option value="discount">Discount price</Select.Option>
            </Select>

            <div className="mt-3">
              <p className="text-xs">Use rate calculator</p>
              <div className="flex flex-row items-center">
                <div className="h-auto">
                  <Switch
                    checked={ratesEnabled[room]}
                    defaultChecked={false}
                    onChange={(val) => {
                      const newerEnabledRates = [...ratesEnabled];
                      // replace with the new rate
                      newerEnabledRates.splice(room, 1, val);
                      setRatesEnabled(newerEnabledRates);
                    }}
                  />
                </div>

                <div>
                  <InputNumber
                    className="w-full flex-grow"
                    disabled={!ratesEnabled}
                    defaultValue={rates[room]}
                    value={rates[room]}
                    onChange={(val) => {
                      let newerPrice = hotel.rooms[room].price;

                      if (priceType === "discount") {
                        newerPrice *= (100 - val) / 100;
                      } else {
                        newerPrice *= (100 + val) / 100;
                      }

                      const newerRates = [...rates];
                      newerRates.splice(room, 1, val);
                      setRates(newerRates);
                      const newerPrices = [...newSubPropPrices];
                      newerPrices.splice(room, 1, newerPrice);

                      setNewSubPropPrices(newerPrices);
                    }}
                  />
                </div>
              </div>
            </div>

            <p className="text-xs mt-3 mb-1">New room price/day</p>
            <InputNumber
              className="w-full"
              disabled={ratesEnabled[room]}
              defaultValue={newSubPropPrices[room]}
              value={newSubPropPrices[room]}
              onChange={(val) => {
                // update daily price
                const newerPrice = val;
                // update the prices with the new price
                const newerPrices = [...newSubPropPrices];
                newerPrices.splice(room, 1, newerPrice);
                setNewSubPropPrices(newerPrices);
              }}
            />
          </>
        ))}

        <div className="flex flex-row pt-3 items-center justify-center">
          <Button
            disabled={
              startDate === null ||
              endDate === null ||
              newSubPropPrices.length === 0 ||
              newSubPropPrices.length === 0
            }
            type="button"
            onClick={() => {
              // just as an insurance, mae an early exit if any of the variables
              // is null
              if (
                !startDate ||
                !endDate ||
                newSubPropPrices.length === 0 ||
                newSubPropPrices.length === 0
              ) {
                return;
              }
              // we post the reservation, and then we remove the overlay
              startDate.setHours(0, 0, 0, 0);
              endDate.setHours(23, 59, 59, 999);

              let propertyId = `${hotel.id}`;

              activeRoom.forEach((room) => {
                // append the id of the room pre-pended by a colon
                propertyId += `:${hotel.rooms[room].id}`;
              });

              addToCalendar({
                endDate: endDate.getTime(),
                id: generateId(10),
                newPrice: newSubPropPrices,
                property_id: propertyId,
                startDate: startDate.getTime(),
                type: priceType,
              } as PriceChange<number[]>);

              setNewSubPropPrices([0]);
              setEndDate(null);
              setStartDate(null);
              setRates([0]);
              setRatesEnabled([false]);
            }}
          >
            Add to calendar
          </Button>
        </div>
      </div>
    </>
  );
};
