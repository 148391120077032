import type {FC} from 'react';

import {Car, Conference, Eatery, Hotel, Property, ReservationCategory} from '../../../../api';
import { PriceChange } from '../../../../api/hotel/Offer';

import {ActivityPriceChange} from './activity-price-change';
import {CarPriceChange} from './car-price-change';
import {ConferencePriceChange} from './conference-reservation';
import {EateryPriceChange} from './eatery-price-change';
import {HotelPriceChange} from './hotel-price-change';
import {RentalPriceChange} from './rental-price-change';
import {TourPriceChange} from './tour-reservation';

type ExternalReservationOverlayProps = {
  category: ReservationCategory;
  propertyId: string;
  property: Record<string, unknown>;
  removeOverlay: () => void;
  addToCalendar: (newOffer: Record<string, unknown>) => void;
};

export type AddToCalendarCallback<T> = (newOffer: PriceChange<T>) => void;

export const SeasonalPriceChange: FC<ExternalReservationOverlayProps> = ({
  category, propertyId, property, removeOverlay, addToCalendar,
}) => {
  console.log("Yes");
  return (
    <div
      className="sm:w-80"
    >
      <div className="max-w-sm sm:max-w-full h-full mx-auto">
        {/* Dialog content based on the category */}
        {category === "activity" && (
          <ActivityPriceChange
            propertyId={propertyId}
            property={property}
            removeOverlay={removeOverlay}
            addToCalendar={addToCalendar}
          />
        )}
        {category === "car" && (
          <CarPriceChange
            propertyId={propertyId}
            property={property}
            removeOverlay={removeOverlay}
            addToCalendar={addToCalendar}
          />
        )}
        {category === "conference" && (
          <ConferencePriceChange
            propertyId={propertyId}
            property={property}
            removeOverlay={removeOverlay}
            addToCalendar={addToCalendar}
          />
        )}
        {category === "eatery" && (
          <EateryPriceChange
            propertyId={propertyId}
            property={property}
            removeOverlay={removeOverlay}
            addToCalendar={addToCalendar}
          />
        )}
        {category === "hotel" && (
          <HotelPriceChange
            propertyId={propertyId}
            property={property}
            removeOverlay={removeOverlay}
            addToCalendar={addToCalendar}
          />
        )}
        {category === "rental" && (
          <RentalPriceChange
            propertyId={propertyId}
            property={property}
            removeOverlay={removeOverlay}
            addToCalendar={addToCalendar}
          />
        )}
        {category === "tour" && (
          <TourPriceChange
            propertyId={propertyId}
            property={property}
            removeOverlay={removeOverlay}
            addToCalendar={addToCalendar}
          />
        )}
      </div>
    </div>
  );
};

export const getPropertyTitle = (
  category: ReservationCategory, propertyId: string,
  property: Record<string, unknown>,
): string => {
  if (category === "hotel" || category === "conference" || category === "eatery") {
    const [, subPropertyId] = propertyId.split(":");
    if (category === "hotel") {
      // since each hotel has a unique id, it is safe to assume that this filter
      // method will yield a tuple with only one member, which is the required
      // sub-property
      const [subProp] = (property as Hotel)
        .rooms
        .filter((room) => (room.id === subPropertyId));
      
      return subProp.title;
    } else if (category === "conference") {
      // since each hotel has a unique id, it is safe to assume that this filter
      // method will yield a tuple with only one member, which is the required
      // sub-property
      const [subProp] = (property as Conference)
        .rooms
        .filter((room) => (room.id === subPropertyId));
      
      return subProp.title;
    } else {
      const [subProp] = (property as Eatery)
        .table
        .filter((t) => (t.id === subPropertyId));
      
      return subProp.title;
    }
  } else {
    if (category === "car") {
      return (property as Car).reg_no;
    }

    return (property as Property).title;
  }
}
