// @ts-check
import AuthContainer from "./AuthContainer";

import travellers_1 from "../../assets/travellers_1.jpg";

import Header from "../index/components/Header";
import {AppFooter} from "../index/components/app-footer";

const App = () => {
  return (
    <>
      <div
        className="flex-div"
        style={{
          width: "100%",
          height: "100%",
          scrollbarWidth: "none",
          justifyContent: "center",
          alignItems: "center",
          background: "#f2f2f2",
        }}
      >
        <Header />
        <div className="display-port">
          <div className="flex-div flex-row signin-container">
            <AuthContainer origin="signin-page" />
            <div
              style={{
                textAlign: "center",
                flex: 1,
                borderLeft: "0px solid #eee",
                marginBottom: 0,
                paddingTop: 10,
              }}
              className="mobile-hidden"
            >
              <img alt="travellers" src={travellers_1} width={520} />
            </div>
          </div>
        </div>
      </div>
      <AppFooter origin="" />
    </>
  );
};

export default App;
