import {FC, useContext, useEffect, useState} from 'react';
import {Route, RouteComponentProps, Switch, useHistory} from 'react-router-dom';
import { fetchUserDetails } from '../../api';
import ActivityIndicator from '../../components/loading';
import { UserProvider } from '../../context';
import { SnackbarProvider } from '../../context/snackbar-provider';

import {SuNavBar, SuNavRail} from './components';
import {
  About, AboutEditor, Content, Overview, Users, Wallet
} from './routes';

type SuperUserRoutesProps = RouteComponentProps;

export const SU: FC<SuperUserRoutesProps> = ({match}) => {
  const history = useHistory();

  const {user} = useContext(UserProvider);
  const {dismissSnackbar, setIsVisible} = useContext(SnackbarProvider);

  const [authenticated, setAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      fetchUserDetails(user.uid, (err, safariUser) => {
        if (err) {
          console.error(err);
          setIsVisible && setIsVisible({
            fabPresent: false,
            isError: true,
            navRailPresent: true,
            title: "Unable to fetch user details",
            action: {
              label: "DISMISS",
              onClick: () => {
                dismissSnackbar && dismissSnackbar();
              },
            },
          });

          return;
        }

        if (safariUser) {
          safariUser.authLevel === "admin" && setAuthenticated(true);
          safariUser.authLevel !== "admin" && setIsVisible && setIsVisible({
            fabPresent: false,
            isError: true,
            navRailPresent: true,
            title: "Unable to authenticate. Redirecting...",
            action: {
              label: "DISMISS",
              onClick: () => {
                dismissSnackbar && dismissSnackbar();
              },
            },
          });
          safariUser.authLevel !== "admin" && history.push("/");
        } else {
          setIsVisible && setIsVisible({
            fabPresent: false,
            isError: true,
            navRailPresent: true,
            title: "Unable to authenticate. Redirecting...",
            action: {
              label: "DISMISS",
              onClick: () => {
                dismissSnackbar && dismissSnackbar();
              },
            },
          });

          history.push("/");
        }
      });
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={
        "absolute top-0 bottom-0 left-0 right-0 flex flex-row " +
        "overflow-y-hidden " +
        `${(!authenticated) ?
          "justify-center items-center " :
          ""}`
      }
    >
      {!authenticated && (
        <>
          <ActivityIndicator
            caption="Authenticating"
          />
        </>
      )}
      {/* This is only rendered if the user is autheticated as an admin */}
      {authenticated && (
        <>
          <SuNavRail />
          <div className="h-full flex-grow">
            <SuNavBar />
            <Switch>
              <Route
                path={`${match.path}/`}
                component={Overview}
                exact={true}
              />
              <Route
                path={`${match.path}/content`}
                component={Content}
              />
              <Route
                path={`${match.path}/users`}
                component={Users}
              />
              <Route
                path={`${match.path}/wallet`}
                component={Wallet}
              />
              <Route
                path={`${match.path}/about`}
                component={About}
                exact={true}
              />
              <Route
                path={`${match.path}/about/edit/:route`}
                component={AboutEditor}
              />
            </Switch>
          </div>
        </>
      )}
    </div>
  );
};
