export const CURRENCY_KEY = "com.safarixperts.CURRENCY";

/**
 * Saves the current active currency short code to the short code to enable
 * user preferences to be remembered in other sessions long after the browser
 * window is closed.
 *
 * @param currencyShortCode the unique short code of the currency to be saved
 * to localstorage.
 * @returns Boolean on whether the operation was successful or not.
 */
export const saveCurrency = (currencyShortCode: string): boolean => {
  try {
    localStorage.setItem(CURRENCY_KEY, currencyShortCode);

    return true;
  } catch (err) {
    console.error(err);

    return false;
  }
};

/**
 * Fetches the user preference for currency from local storage.
 *
 * @returns the current currency stored in local storage, or null if none
 */
export const getCurrency = (): string | null => {
  return localStorage.getItem(CURRENCY_KEY);
}
