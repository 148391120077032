import {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import {FaUtensils} from 'react-icons/fa';
import {useHistory} from 'react-router';

import {
  Coordinates, Facility, Feature, filterTours, Property, Tour as TourProperty,
} from '../../../../api';
import {stopFacilities, stopFeatures } from '../../../../api/features';
import {SearchPropertyCard} from '../../../../components/search-property-card';
import {fetchCompulsoryToursFilters} from '../../../../reducers';
import {DistanceUtil} from '../../../../util';
import {calculateTourPrice} from '../../../../util/price-calculator';
import { rankFilters } from '../../../../util/rank-filters';

import {ContainerTwo} from '../../components/container-two';
import {FilterableProperty, FilterContext} from '../../context';
import {addFacility, filter, removeFacility} from '../../util';

export const Tour: FC = () => {
  const history = useHistory();

  const [filterableProperties, setFilterableProperties] =
    useState<FilterableProperty[]>([]);

  const [tours, setTours] = useState<TourProperty[]>([]);
  const [viewableResults, setViewableResults] = useState<TourProperty[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(true);

  const [activeFacilities, setActiveFacilities] = useState<Facility[]>([]);
  const [activeFeatures, setActiveFeatures] = useState<Feature[]>([]);

  const [rawResultsStale, setRawResultsStale] = useState<boolean>(false);
  const [filterResultsStale, setFilterResultsStale] = useState<boolean>(false);

  const {place, adults, children, lat, lng} = fetchCompulsoryToursFilters();
  const coords: Coordinates = {
    lat: lat ? lat : 0,
    lng: lng ? lng : 0,
  };

  useEffect(() => {
    const location = place ? place : "";
    const coords: Coordinates = {
      lat: lat ? lat : 0,
      lng: lng ? lng : 0,
    };
    filterTours(location, coords, (err, results) => {
      if (err) {
        console.error(err);

        return;
      }

      if (results) {
        setIsFetching(false);
        setTours(results);
        setViewableResults(results);
        setFilterableProperties(results.map((result) => ({
          ...result,
          price: calculateTourPrice(
            adults ? adults : 1,
            children ? children : 0,
            result,
          ),
        })));

        setRawResultsStale(false);
      }
    });
  }, [rawResultsStale]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const getTourFacilities = (tour: TourProperty) => {
      const facilities: string[] = [];

      tour.stops.forEach((stop) =>
        facilities
          .push(...(stop.facilities.map(facilities => facilities.title))));
      
      return facilities;
    };

    const getTourFeatures = (tour: TourProperty) => {
      const features: string[] = [];

      tour.stops.forEach((stop) =>
        features
          .push(...(stop.features.map(feature => feature.title))));
      
      return features;
    };

    const rankedProperties = rankFilters<TourProperty>(
      tours,
      {
        active: activeFacilities.map((facility) => facility.title),
        get: getTourFacilities,
      },
      {
        active: activeFeatures.map((feature) => feature.title),
        get: getTourFeatures,
      },
    );

    setViewableResults(rankedProperties);
    setFilterResultsStale(false);
  }, [filterResultsStale]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FilterContext.Provider
      value={{
        filterResultsStale,
        rawResults: tours,
        rawResultsStale,
        results: filterableProperties,
        setFilterResultsStale,
        setRawResultsStale,
        setResults: setFilterableProperties as Dispatch<SetStateAction<Property[]>>,
        setViewableResults: setViewableResults as Dispatch<SetStateAction<Property[]>>,
        addFacility: (facility) => {
          addFacility(facility, activeFacilities, setActiveFacilities);
        },
        removeFacility: (facility) => {
          removeFacility(facility, activeFacilities, setActiveFacilities);
        },
      }}
    >
      <ContainerTwo<TourProperty>
        HeaderIcon={FaUtensils}
        columnData={[
          {
            title: "Facilities",
            data: stopFacilities,
            onChange: (activeFacilities) => {
              setActiveFacilities(filter(activeFacilities, stopFacilities));
            },
          },
          {
            title: "Features",
            data: stopFeatures,
            onChange: (activeFeatures) => {
              setActiveFeatures(filter(activeFeatures, stopFeatures));
            },
          },
        ]}
        filterOptions={[
          {
            idx: 0,
            onClick: () => console.log("Selecting top solo filters"),
            title: "Top solo picks",
            onFilter: (results) => results,
          },
          {
            idx: 1,
            onClick: () => console.log("Selecting Stars (Highest first)"),
            title: "Stars (Highest first)",
            onFilter: (results) => {
              const parsedResults = (results as Property[]).sort((result, nextResult) => {
                return nextResult.rating - result.rating;
              });

              return parsedResults;
            },
          },
          {
            idx: 2,
            onClick: () => console.log("Selecting stars (Lowest First)"),
            title: "Stars (Lowest first)",
            onFilter: (results) => {
              const parsedResults = (results as Property[]).sort((result, nextResult) => {
                return result.rating - nextResult.rating;
              });

              return parsedResults;
            },
          },
          {
            idx: 3,
            onClick: () => console.log("Selecting by Price (Highest)"),
            title: "Price (Highest)",
            onFilter: (results) => {
              const parsedResults = (results as unknown as TourProperty[]).sort((result, nextResult) => {
                return nextResult.price.adult - result.price.adult;
              });

              return parsedResults;
            },
          },
          {
            idx: 4,
            onClick: () => console.log("Select by price (lowest)"),
            title: "Price (Lowest)",
            onFilter: (results) => {
              const parsedResults = (results as unknown as TourProperty[]).sort((result, nextResult) => {
                return result.price.adult - nextResult.price.adult;
              });

              return parsedResults;
            },
          },
          {
            idx: 5,
            onClick: () => console.log("Selecting top Reviewed"),
            title: "Top Reviewed",
            onFilter: (results) => {
              const parsedResults = (results as Property[]).sort((result, nextResult) => {
                return nextResult.reviews.length - result.reviews.length;
              });

              return parsedResults;
            },
          }
        ]}
        headerTitle={`Showing results for ${place}`}
        loading={isFetching}
        results={viewableResults}
      >
        {(viewableResults) => viewableResults.map((tour, idx) => (
          <SearchPropertyCard
            key={tour.id}
            action={{
              label: "View Rental",
              onClick: () => {
                history.push(`/property/tour/${tour.id}?${
                  new URL(location.href).searchParams.toString()
                }`)
              },
            }}
            comments={tour.reviews}
            cover={tour.gallery[0].url}
            description={tour.description}
            duration={`2 days`}
            idx={idx}
            item={tour}
            location={tour.location}
            origin="search"
            price={tour.price.adult}
            rating={tour.rating}
            style={{}}
            title={tour.title}
            subtitle={
              `${
                DistanceUtil.haversineDistance("km", coords, tour.coords)
                  .toFixed(2)
              } KM from ${place}`
            }
          />
        ))}
      </ContainerTwo>
    </FilterContext.Provider>
  );
};
