import { UPDATE_USER, UPDATE_SIGN_STATUS, UPDATE_SELECTED_HOTEL,UPDATE_TRIP_PLANNER,UPDATE_WEBSITE_ACTIVE,UPDATE_SELECTED_MULTIPLEX,UPDATE_SELECTED_CURRENCY} from './types';

export const updateTheUser = (user) => (
    {
        type: UPDATE_USER,
        data: user
    }
);
export const updateSignedInStatus = (truFlse) => (
    {
        type: UPDATE_SIGN_STATUS,
        data: truFlse
    }
);
export const updateSelectedHotel = (data) => (
    {
        type: UPDATE_SELECTED_HOTEL,
        data: data
    }
);
export const updateTripPlanner = (data) => (
    {
        type: UPDATE_TRIP_PLANNER,
        data: data
    }
);
export const updateWebsiteActive = (data) => (
    {
        type: UPDATE_WEBSITE_ACTIVE,
        data: data
    }
);
export const updateCurrentCurrency = (data) => (
    {
        type: UPDATE_SELECTED_CURRENCY,
        data: data
    }
);
export const updateCurrentMultiplex = (data) => (
    {
        type: UPDATE_SELECTED_MULTIPLEX,
        data: data
    }
);

